import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onGetSelectedFormDataNotClean, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SelectForm from '../../../../components/Forms/SelectForm'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { private_server } from '../../../../variables/config'
import { green } from '@mui/material/colors'
import InputDateForm from '../../../../components/Forms/InputDateForm'
import moment from 'moment'
import useBatches from '../../../../hooks/useBatches'
import useInventories from '../../../../hooks/useInventories'


const EditInventoryModal = props => {

    const { open, onClose, onCompleted, warehouse, product, origin } = props

    const classes = useStyles()
    const inventoryManager = useInventories()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks

    useEffect(() => {
        console.log('Hubo un cambio')
        if (open) {
            //setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitForm(formData, origin)
            _form.min_quantity.isRequired = false
            _form.min_quantity.isVisited = false
            _form.max_quantity.isRequired = false
            _form.max_quantity.isVisited = false
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open, origin])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        /* if(id === 'max_quantity' && temp['max_quantity'].value !== ''){
            temp['min_quantity'].isRequired = true
        }else{
            temp['min_quantity'].isRequired = false 
        }
        if(id === 'min_quantity' && temp['min_quantity'].value !== ''){
            temp['max_quantity'].isRequired = true
        }else{
            temp['max_quantity'].isRequired = false
        } */
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data

        let data2send = onGetSelectedFormData(form, origin)

        
        /* if(parseInt(form.max_quantity.value) !== 0 && parseInt(form.min_quantity.value) !== 0 ){
            data2send.reorder_quantity = Math.ceil((parseInt(form.max_quantity.value) + parseInt(form.min_quantity.value))/2)
        }else{
            delete data2send.min_quantity
            delete data2send.max_quantity
        }

        if(!data2send.max_quantity || data2send.min_quantity) delete data2send.reorder_quantity
       
        
        console.log(data2send) */
        //console.log(data2send)


        const maxChange = data2send.max_quantity !== null && data2send.max_quantity !== undefined
        const minChange = data2send.min_quantity !== null && data2send.min_quantity !== undefined

        data2send.max_quantity = maxChange ? parseInt((data2send.max_quantity??0)) : origin.max_quantity
        data2send.min_quantity = minChange ? parseInt((data2send.min_quantity??0)) : origin.min_quantity

        if(data2send.max_quantity === null) data2send.max_quantity = undefined
        if(data2send.min_quantity === null) data2send.min_quantity = undefined

        const reorderquantity = (data2send.max_quantity + data2send.min_quantity)/2
        if(reorderquantity > 0){
            data2send.reorder_quantity = Math.ceil(reorderquantity)
        }

        console.log(data2send)

        setLoading(true)
        try {
            await inventoryManager.actions.updateInventory(origin?.id_inventory, data2send)
            onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Editar inventario</DisplayText>
                <DisplayText >Favor de ingresar los datos solicitados. </DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.location} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.max_quantity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.min_quantity} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    Guardar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default EditInventoryModal

const useStyles = makeStyles(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    }
}))

const formData = {
    max_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'max_quantity',
            type: 'number',
            fullWidth: true,
            label: 'Cant. máx',
            //placeholder: '0',
            helperText:'Cantidad inválida'
        },
        rules: {
            type: 'numeric',
            min: 1
        }
    },
    min_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'min_quantity',
            type: 'number',
            fullWidth: true,
            label:'Cant. min',
            helperText:'Cantidad inválida'
        },
        rules: {
            type: 'numeric',
            min:1
        }
    },
    location: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'location',
            type: 'text',
            fullWidth: true,
            label: 'Ubicación',
            placeholder: 'Ubicación',
            helperText: 'Este campo debe contener min un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}