import React, {useEffect, useState} from 'react'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string'
import EditModal from './modals/EditModal'
import { translations as translations_esp } from '../../../texts/esp/tables-products-translations'
import { CircularProgress, Grid } from '@mui/material'
import DeleteModal from './modals/DeleteModal'
import AddModal from './modals/AddModal'
import { request_outstandingproducts, request_update_outstandingproduct } from './requests'
import { onGetErrorMessage } from '../../../shared/utility'

const OutStandingProducts = props => {

    const content = translations_esp

    const { history, onUpdateError, products_out_search, products_out_table, onUpdateProductsOutSearch, onUpdateProductsOutTable } = props

    const [sending, setSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])

    const [totalItems, setTotalItems] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'product'})
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        const initModule = async() => { 
            onRequestModelData()   
        }
        initModule()
    }, [products_out_table])


    const onRequestModelData = async() => {  
        try{
            setLoading(true)
            const {limit, offset, order_by, order} = products_out_table
            const param2send = {limit:limit, offset:offset*limit, order:order, currency_id:2}
            let params = `?${queryString.stringify(param2send)}` 
    
            if(products_out_search){
                //let filterArgs = {"AND":[{"field":"search_string","operator":"LIKE","value":`%${search}%`}]}
                const _sku = parseInt(products_out_search) !== NaN ? parseInt(products_out_search) : ''
                let filterArgs = {"OR":[
                    {"field":"search_string","operator":"LIKE","value":`%${products_out_search}%`},
                    {"field":"sku","operator":"LIKE","value":`%${_sku}%`}
                ]}
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
            }
    
            const _users = await request_outstandingproducts(params)
            setProducts(_users.outstanding_data)
            setTotalItems(_users.count)
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
            
        }
        
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    }
  
    /* const onChangeFilter = (tag, value) => { 
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    } */

    const onEdit = (item) =>{
        //console.log(item)
        const {id_outstanding_product} = item
        setSelected(id_outstanding_product)
        setEditModal(true)
    }

    const onDelete = (item) =>{
        //console.log(item)
        const {id_outstanding_product} = item
        setSelected(id_outstanding_product)
        setDeleteModal(true)
    }

    const onUpdateCompleted = async() => {
        onRequestModelData()
        setEditModal(false)
    }

    const onChangeStatus = async (item) => {
        const {id_outstanding_product, op_enabled} = item
        const status = op_enabled === 0 ? 1 : 0
        try{
            setSending(true)
            await request_update_outstandingproduct(id_outstanding_product, {enabled: status})
            onUpdateCompleted()
        }catch(error){
            console.log(error)
        }
        setSending(false)
    }
 
    return(
        <div>
            <AddModal open={add_modal} onClose={() => setAddModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
                content={content.modal} existing_products={products}/>
            <EditModal open={edit_modal} onClose={() => setEditModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
                content={content.modal} id={selected} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
                id={selected}/> 
            <SimpleTable
                data={products} 
                search={products_out_search}
                onChangeSearch={onUpdateProductsOutSearch}
                tableFilter={products_out_table}
                total={totalItems}
                loading={loading}
                sending={sending}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => onUpdateProductsOutTable(data)}
                onSelectedItem={onSelectedItem}
                onEdit={onEdit}
                onDelete={onDelete}
                onChangeStatus={onChangeStatus}
                />      
        </div>
    )
}

export default OutStandingProducts
