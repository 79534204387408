import React from 'react';
import { Badge, Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blueGrey } from '@mui/material/colors';
import cx from 'classnames'


const ToolbarTable = (props) => {

    const {selected, onChange, onAddRegister, menuOptions, variant} = props

    return ( 
        <div style={{background:'#E8EBF3', padding:4, float:'left', borderRadius:5}} >
            <Grid container  >
                {menuOptions.map(item => {
                    return <Grid item key={item.id}>
                        <CustomButton variant={variant} number={item.number} selected={item.id === selected} onClick={() => onChange(item.id)}>{item.label}</CustomButton>
                    </Grid>
                })}
                {/* <Grid item xs/> */}
            </Grid>
        </div>
     );
}

export default ToolbarTable;

const useButtonStyles = makeStyles(theme => ({
    root:{position:'relative'},
    button:{
        //background:'#E8EBF3',
        color:'#52526B',
        //borderRadius:5,
        padding:'8px 24px',
        '&:hover':{background:blueGrey[200]},
        textTransform:'none'
    },
    selected:{
        color:'white',
        background:theme.palette.primary.main,
        //fontWeight:500,
        //padding:'8px 24px',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{background:theme.palette.primary.dark}
        //border:`1px solid ${theme.palette.primary.main}`
    },
    selected_secondary:{
        background:'white',
        color: theme.palette.primary.main,
        '&:hover':{background:'white'}
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const {children, selected, onClick, number, variant} = props



    return(
        <div className={classes.root}>
            <Badge badgeContent={number} color='primary'> 
                <Button onClick={onClick} className={cx({
                    [classes.button]:true,
                    [classes.selected]:selected,
                    [classes.selected_secondary]:selected && variant === 'secondary'
                })}>{children}</Button> 
            </Badge>
           
        </div>
    )
}