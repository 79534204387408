import React, { useState } from 'react';
import axios from 'axios'
import { onGetErrorMessage, onGetFileExtension, updateAuthorizationHeader } from '../../../../../../shared/utility';
import { translations as translations_esp } from '../../../../../../texts/esp/modals-translations';
import { Checkbox, Typography } from '@mui/material';
import ResourceModal from '../../../../../../components/Overlays/ResourceModal';
import { private_server } from '../../../../../../variables/config';

const ChangeImageModal = (props) => {

    const {id, id_image, type, open, onClose, onUpdateCompleted} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [both, setBoth] = useState(true)
    //console.log(id)
    //console.log(id_image)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)

            const _file = file
            let data
            if(!both){
                if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
                else{data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
                //await request_upload_image(id, id_image, _file, data)
                updateAuthorizationHeader(private_server)
                const response = await private_server.post(`/product/${id}/image/${id_image}/file`, data)
                const options = {headers: {'Content-Type': _file.type}}
                delete axios.defaults.headers.common["Authorization"]
                await axios.put(response.data.data.upload_url, _file, options);
                
            }else{
                const options = {headers: {'Content-Type': _file.type}}

                const data2web = {document:"image_web", type:onGetFileExtension(_file.name)}
                //await request_upload_image(id, id_image, _file, data2web)
                updateAuthorizationHeader(private_server)
                const response = await private_server.post(`/product/${id}/image/${id_image}/file`, data2web)
                //const options = {headers: {'Content-Type': _file.type}}
                delete axios.defaults.headers.common["Authorization"]
                await axios.put(response.data.data.upload_url, _file, options);

                const data2mobile = {document:"image_mobile", type:onGetFileExtension(_file.name)}
                //await request_upload_image(id, id_image, _file, data2mobile)
                updateAuthorizationHeader(private_server)
                const response2 = await private_server.post(`/product/${id}/image/${id_image}/file`, data2mobile)
                //const options = {headers: {'Content-Type': _file.type}}
                delete axios.defaults.headers.common["Authorization"]
                await axios.put(response2.data.data.upload_url, _file, options);
            }
            
            
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    const onDelete = async() => {
        setDisabled(true)
        try {
            setError(null)
            setLoading(true)
            let data
            if(type === 'web'){data = '?document=image_web'}
            else{data = '?document=image_mobile'}
            console.log(data)
            //await request_delete_image_file(id, id_image, data)
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/product/${id}/image/${id_image}/file${data}`)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    let message = type === 'web' ? 'Aplicar también en versión mobile' : 'Aplicar también en versión web'

    return ( 
        <ResourceModal open={open} onClose={onClose} loading={loading} error={error} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}>
                <div style={{display:'flex', alignItems:'center'}}>
                    <Checkbox checked={both}  onChange={() => setBoth(!both)}  /> <Typography variant='subtitle2'>{message}</Typography>
                </div>
        </ResourceModal>
     );
}
 
export default ChangeImageModal;