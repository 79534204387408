import { Button } from "@mui/material"
import makeStyles from '@mui/styles/makeStyles';

const UrlButton = ({onClick}) => {

    const classes = useStyles()

    return(
        <Button fullWidth className={classes.root} onClick={onClick}>Autogenerar</Button>
    )
}

export default UrlButton

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.secondary.main,
        color:'white',
        textTransform:'none',
        padding:'14px 12px',
        borderRadius:8,
        width:'100%',
        '&:hover':{
            background:theme.palette.secondary.main
        }
    }
}))
