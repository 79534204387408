import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../../components/Forms/InputForm'
import { private_server } from '../../../../../../variables/config'
import { modalStyles } from '../../../../../../styles/modalStyles'
import InputDateForm from '../../../../../../components/Forms/InputDateForm'
import InputCheckbox from '../../../../../../components/Forms/InputCheckbox'
import moment from 'moment'
//import { request_create_inventory, request_products } from '../requests'




const AddHolidayModal = ({open, onClose, onUpdateCompleted, content, id, catalogs}) => {

    const classes = modalStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    useEffect(() => {
    const onInitModal = async() => {
        if (open) {  
            let _form = onInitEmptyForm(formData, content.form)
            _form.date.value = moment().format('YYYY-MM-DD')
            _form.date.isValid = true
            setForm(_form)         
        }else{
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);        
        }
    }
    onInitModal()
    }, [open])

    
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)
        
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`warehouse/${id}/store/holiday`, data2send)
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
       
    }


    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose}>
            <DisplayText variant='h6' >{content.add.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button} >
                    <ContainedButton color='primary' onClick={onSubmit} loading={loading} disabled={loading}>
                        {content.add.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddHolidayModal

const formData = {
    date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
        },
        rules: {
            type: 'date',
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min:0, max:255
        }
    },
    
    
}
