export const translations = {
	title: 'Configuración',
	menu: [
		{ id: 1, label: 'Roles' },
		{ id: 2, label: 'Permisos' },
	],
	roles: {
		title: 'Rol',
		table: {
			header: [
				{ id: 1, label: 'ID' },
				{ id: 2, label: 'Nombre' },
				{ id: 3, label: 'Ícono' },
				{ id: 4, label: 'Descripción' },
				{ id: 5, label: 'Color' },
				{ id: 6, label: 'Default' },
			],
            default:{
                yes: 'Sí',
                no: 'No'
            },
			nodata: 'No existen roles registrados',
		},

		modal: {
			add_title: 'Crear rol',
			edit_title: 'Editar',
			subtitle: 'Por favor ingresa los datos solicitados',
			button: 'Agregar',
			edit_button: 'Guardar',
			form: {
				name: {
					label: 'Tipo',
					helperText: 'Seleccione al menos una opción',
				},
				color: {
					label: 'Color',
					helperText: 'Seleccione un color',
				},
				description: {
					label: 'Description',
					helperText: 'Este campo debe tener al menos 1 caracter y máximo 255',
				},
			},
		},
	},
	permissions: {
		title: 'Permission',
		table: {
			header: [
				{ id: 1, label: 'Módulo' },
				{ id: 2, label: 'Acceso Completo' },
				{ id: 3, label: 'Creacion' },
				{ id: 4, label: 'Edición' },
				{ id: 5, label: 'Lectura' },
				{ id: 6, label: 'Borrado' },
				{ id: 7, label: 'Público' },
				{ id: 8, label: '' },
			],
			nodata: 'No existen permisos registrados',
		},
		modal: {
			add_title: 'Crear Permiso',
			edit_title: 'Editar',
			subtitle: 'Por favor ingresa los datos solicitados',
			button: 'Agregar',
			edit_button: 'Guardar',
			checks:[
				{
					label: 'Acceso completo',
					key: 'full_access'
				},
				{
					label: 'Creación',
					key: 'create'
				},
				{
					label: 'Edición',
					key: 'update'
				},
				{
					label: 'Lectura',
					key: 'read'
				},
				{
					label: 'Borrado',
					key: 'delete'
				},
				{
					label: 'Público',
					key: 'public'
				}
			],
			form: {
				module_id: {
					label: 'Selecciona un módulo',
					helperText: 'Seleccione al menos una opción',
				},
			},
		},
	},
};
