import React, {useState} from 'react'
import { connect } from 'react-redux';
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import BrandsView from './views/BrandsView/BrandsView/BrandsView'
import CategoriesView from './views/CategoriesView/CategoriesView/CategoriesView'
import SegmentsView from './views/SegmentsView/SegmentsView/SegmentsView'
import SubcategoriesView from './views/SubcategoriesView/SubcategoriesView/SubcategoriesView'
import TagsView from './views/TagsView/TagsView'
import { translations as translations_esp } from '../../texts/esp/catalogs-translations'
import { Hidden } from '@mui/material'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import SubsegmentsView from './views/SubsegmentsView/SubsegmentsView/SubsegmentsView'
import { actionTypes } from '../../store/actions'
import Menu from './views/Menu/Menu';

const Catalogs = (props) => {

    const content = translations_esp

    const {history, view, cat_brands_search, cat_brands_table, cat_categories_search, cat_categories_table, cat_subcategories_search, cat_subcategories_table,
            cat_segments_search, cat_segments_table, cat_subsegments_search, cat_subsegments_table, onUpdateBrandsSearch, onUpdateBrandsTable, onUpdateView,
            onUpdateCategoriesSearch, onUpdateCategoriesTable, onUpdateSubcategoriesSearch, onUpdateSubcategoriesTable, onUpdateSegmentsSearch, onUpdateSegmentsTable,
            onUpdateSubsegmentsSearch, onUpdateSubsegmentsTable} = props

    //const [view, setView] = useState(1)
  
    const menu = content.menu

    const onChangeView = (id) => {
        let urlwithargs = '/catalogs'
        if(id === 1) urlwithargs = '/catalogs/brand'
        if(id === 3) urlwithargs = '/catalogs/category'
        if(id === 4) urlwithargs = '/catalogs/subcategory'
        if(id === 5) urlwithargs = '/catalogs/segment'
        if(id === 5) urlwithargs = '/catalogs/subsegment'
        if(id === 6) urlwithargs = '/catalogs/menu'
        window.history.replaceState(null, null, urlwithargs);
        onUpdateView(id)
    }
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <BrandsView history={history} {...props} />
            break
        case 2:
            contentView = <TagsView />
            break
        case 3:
        contentView = <CategoriesView history={history} {...props} />
            break
        case 4:
            contentView = <SubcategoriesView history={history} {...props} />
            break
        case 5:
            contentView = <SegmentsView history={history} {...props} />
            break
        case 6:
            contentView = <SubsegmentsView history={history} {...props} />
            break
        case 7:
            contentView = <Menu history={history} {...props} />
            break
        default:
            break
    }

    return (
        <Page title={content.title}>
        <Hidden mdDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={onChangeView} />
        </Hidden>
        <div style={{marginTop:20}}>
            {contentView}
        </div>
        </Page>
    );
}

const mapStateToProps = state => {
    return{
        view: state.catalogs_view,
        cat_brands_table: state.cat_brands_table,
        cat_categories_table: state.cat_categories_table,
        cat_subcategories_table: state.cat_subcategories_table,
        cat_segments_table: state.cat_segments_table,
        cat_subsegments_table: state.cat_subsegments_table,
        cat_brands_search: state.cat_brands_search,
        cat_categories_search: state.cat_categories_search,
        cat_subcategories_search: state.cat_subcategories_search,
        cat_segments_search: state.cat_segments_search,
        cat_subsegments_search: state.cat_subsegments_search,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateView: (catalogs_view) => dispatch({type: actionTypes.CHANGE_CATALOGS_VIEW, catalogs_view }),
        onUpdateBrandsTable: (cat_brands_table) => dispatch({type: actionTypes.CHANGE_CAT_BRANDS_TABLE, cat_brands_table }),
        onUpdateCategoriesTable: (cat_categories_table) => dispatch({type: actionTypes.CHANGE_CAT_CATEGORIES_TABLE, cat_categories_table }),
        onUpdateSubcategoriesTable: (cat_subcategories_table) => dispatch({type: actionTypes.CHANGE_CAT_SUBCATEGORIES_TABLE, cat_subcategories_table }),
        onUpdateSegmentsTable: (cat_segments_table) => dispatch({type: actionTypes.CHANGE_CAT_SEGMENTS_TABLE, cat_segments_table }),
        onUpdateSubsegmentsTable: (cat_subsegments_table) => dispatch({type: actionTypes.CHANGE_CAT_SUBSEGMENTS_TABLE, cat_subsegments_table }),
        onUpdateBrandsSearch: (cat_brands_search) => dispatch({type: actionTypes.CHANGE_CAT_BRANDS_SEARCH, cat_brands_search }),
        onUpdateCategoriesSearch: (cat_categories_search) => dispatch({type: actionTypes.CHANGE_CAT_CATEGORIES_SEARCH, cat_categories_search }),
        onUpdateSubcategoriesSearch: (cat_subcategories_search) => dispatch({type: actionTypes.CHANGE_CAT_SUBCATEGORIES_SEARCH, cat_subcategories_search }),
        onUpdateSegmentsSearch: (cat_segments_search) => dispatch({type: actionTypes.CHANGE_CAT_SEGMENTS_SEARCH, cat_segments_search }),
        onUpdateSubsegmentsSearch: (cat_subsegments_search) => dispatch({type: actionTypes.CHANGE_CAT_SUBSEGMENTS_SEARCH, cat_subsegments_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalogs)