import React from 'react'
import { Icon, MenuItem, Select, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { ruleValidation } from '../../../../components/Forms/customFunctions';


const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const useStyles = makeStyles(theme => ({
  select_icon:{
      display:'none',
  },

}))

const CustomSelectForm = props => {

    const classes = useStyles()

    const {data, onChange, startAdornment, endAdornment} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''

    const _options = options ? options : []

    return(
        <div>
            <Select  value={value} variant='outlined' fullWidth
            error={isError}  onChange={onInnerChange} 
            IconComponent={() => <Icon style={{marginRight:32}}>keyboard_arrow_down</Icon>}
            >
                {_options.map(item => {
                    return(
                        <MenuItem key={item.value.toString()} value={item.value}>
                            <div style={{textAlign:'center', fontWeight:600}}>{item.label}</div>
                        </MenuItem>
                    )
                })}
            </Select>
        </div>
    )
}

export default CustomSelectForm