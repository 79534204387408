import React, { useState, useEffect } from 'react';
import { Document, Font, Image, Page, PDFViewer, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../../assets/icons/celu-logo-pdf.png'
import { onGetCurrencyValue, onGetProductItemPrice, onTruncateText } from '../../../../shared/utility';
import JsBarcode  from 'jsbarcode'

import visbyround_extralight from '../fonts/visbyround_extralight.ttf' 
import visbyround_light from '../fonts/visbyround_light.ttf' 
import visbyround_regular from '../fonts/visbyround_regular.ttf' 
import visbyround_medium from '../fonts/visbyround_medium.ttf' 
import visbyround_semibold from '../fonts/visbyround_demibold.ttf' 
import visbyround_bold from '../fonts/visbyround_bold.ttf' 




Font.register({ family: 'Visby Round CF',  fonts:[
    {src:visbyround_light, fontWeight:300},
    {src:visbyround_medium, fontWeight:400},
    {src:visbyround_semibold, fontWeight:500},
    {src:visbyround_bold, fontWeight:600},
] });


const PDFBardcodes = (props) => {

    const {products} = props
    //console.log(products)

    const product_pages = []
    const chunkSize = 8
    for (let i = 0; i < products.length; i += chunkSize) {
        const chunk = products.slice(i, i + chunkSize);
        product_pages.push(chunk)
    }

    //console.log(product_pages)

    return ( 
        <Document>
            {product_pages.map((product_set, number) => {

                const product_lines = []
                const chunkSize = 2
                for (let i = 0; i < product_set.length; i += chunkSize) {
                    const chunk = product_set.slice(i, i + chunkSize);
                    product_lines.push(chunk)
                }

                //console.log(product_lines)

                return(
                    <Page size='A4' style={styles.page} key={`page-${number}`}>
                        {product_lines.map((product_line, line) => {
                            return(
                                <View style={styles.row} key={`line-${number}-${line}`}>
                                    {product_line.map(product => {
                                        return(
                                            <>
                                                <Separator />
                                                <LabelTicket data={product} key={`prod-${product?.id_product}`}/>
                                            </>
                                        )
                                    })}
                                </View>
                            )
                        })}
                        
                    </Page>
                )
            })}
            
        </Document>
     );
}

const styles = StyleSheet.create({
    page:{
        flexDirection: 'column',
        paddingTop:58,
        paddingLeft:32
        //backgroundColor: '#E4E4E4'
    },
    row:{
        //display:'flex',
        paddingTop:8,
        flexDirection:'row',

    }
})


 
export default PDFBardcodes;

const LabelTicket = (props) => {

    const {data} = props

    const MAX_CHARS = 64
    let textData = data?.name
    if(data?.sub_name) textData = `${textData} ${data?.sub_name}`
    if(data?.presentation) textData = `${textData} ${data?.presentation}`
    //console.log(textData)
    let barcode_data = `${data?.sku??''}`
    //let barcode_data = `123465487987`

    const price_data = onGetProductItemPrice(data)
    const final_price = onGetCurrencyValue(price_data.current)

    

    let canvas = document.createElement('canvas');
    JsBarcode(canvas, barcode_data, {displayValue:false});
    const barcode = canvas.toDataURL();

    return(
        <View style={labelStyles.container}>
            <View style={labelStyles.row}>
                <View><Image src={logo} style={labelStyles.logo} /></View>
                <View style={{flex:1}}></View>
                <View><Text style={labelStyles.price}>{final_price}</Text></View>
            </View>
            <View style={labelStyles.product_info}>
                <Text style={labelStyles.name}>{onTruncateText(textData, MAX_CHARS)}</Text>
            </View>
            <View style={{flexDirection:'column', alignItems:'center' }}>
                <Image src={barcode} style={labelStyles.barcode}/>
                <Text style={labelStyles.barcode_text}>{barcode_data}</Text>
            </View>
        </View>
    )
}

const labelStyles = StyleSheet.create({

    container:{
        //flexDirection:'row',
        borderWidth:'0.75pt',
        borderColor:'#F2F2F2',
        borderStyle:'solid',
        width:'2.95in',
        height:'1.97in',
        padding:8,
        fontFamily:'Visby Round CF'
    },
    row:{
        flexDirection:'row',
        alignItems:'center'
    },
    logo:{
        width:'0.92in'
    },
    price:{
        fontSize:'14pt',
        fontWeight:300
    },
    product_info:{
        marginTop:12,
        fontSize:'12pt',
        fontWeight:'bold',
        textAlign:'center',
        //width:'100%',
        height:'0.8in'
        //height:'0.63pt'
    },
    name:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        //borderWidth:'1pt',
        //borderColor:'#333',
        //borderStyle:'solid',
        fontWeight:500,
        lineHeight:'2pt'
    },
    barcode:{
        width:'1.24in',
        height:'0.36in'
    },
    barcode_text:{
        fontSize:'8pt'
    }
})

const Separator = () => {
    return(
        <View style={separatorStyles.container}>

        </View>
    )
}

const separatorStyles = StyleSheet.create({

    container:{
        width:'0.5in',

    },
})
