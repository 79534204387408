import logo_icon from '../../assets/icons/sidebar/celu_logo.svg';
import users_icon from '../../assets/icons/sidebar/users_icon.svg';
import orders_icon from '../../assets/icons/sidebar/orders_icon.svg';
import discounts_icon from '../../assets/icons/sidebar/discounts_icon.svg';
import routes_icon from '../../assets/icons/sidebar/routes_icon.svg';
import doctors_icon from '../../assets/icons/sidebar/doctors_icon.svg';
import patients_icon from '../../assets/icons/sidebar/patients_icon.svg';
import catalogs_icon from '../../assets/icons/sidebar/catalogs_icon.svg';
import products_icon from '../../assets/icons/sidebar/products_icon.svg';
import medications_icon from '../../assets/icons/sidebar/medications_icon.svg';
import banners_icon from '../../assets/icons/sidebar/banners_icon.svg';
import warehouses_icon from '../../assets/icons/sidebar/warehouses_icon.svg';
import carts_icon from '../../assets/icons/sidebar/carts_icon.svg';
import settings_icon from '../../assets/icons/sidebar/settings_icon.svg';
import inventory_icon from '../../assets/icons/sidebar/inventory_icon.svg';
import employer_icon from '../../assets/icons/sidebar/employer_icon.svg';

export const sidebar_settings = {
	logo: logo_icon,
	list: [
		{
			icon: employer_icon,
			name: 'Personal',
			url: '/users',
			permissions: [1],
		},
		{
			icon: orders_icon,
			name: 'Pedidos',
			url: '/orders',
			permissions: [1],
		},
		{
			icon: discounts_icon,
			name: 'Descuentos',
			url: '/discounts',
			permissions: [1],
		},
		{
			icon: routes_icon,
			name: 'Rutas',
			url: '/routes',
			permissions: [1],
		},
		{
			icon: doctors_icon,
			name: 'Doctores',
			url: '/doctors',
			permissions: [1],
		},
		{
			icon: users_icon,
			name: 'Usuarios',
			url: '/patients',
			permissions: [1],
		},
		{
			icon: catalogs_icon,
			name: 'Catálogos',
			url: '/catalogs',
			permissions: [1],
		},
		{
			icon: products_icon,
			name: 'Productos',
			url: '/products',
			permissions: [1],
		},
		{
			icon: inventory_icon,
			name: 'Inventario',
			url: '/inventory',
			permissions: [1],
		},
		{
			icon: medications_icon,
			name: 'Medicamentos',
			url: '/medications',
			permissions: [1],
		},
		{
			icon: banners_icon,
			name: 'Banners',
			url: '/banners',
			permissions: [1],
		},
		{
			icon: warehouses_icon,
			name: 'Sucursales',
			url: '/warehouses',
			permissions: [1],
		},
		{
			icon: carts_icon,
			name: 'Carritos',
			url: '/carts',
			permissions: [1],
		},
		{
			icon: settings_icon,
			name: 'Configuración',
			url: '/settings',
			permissions: [1],
		},
	],
};
