import {
    FormControl,
    FormLabel,
    FormControlLabel,
    Grid,
    RadioGroup,
    Radio,
    Checkbox,
    IconButton,
    Icon,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InputForm from '../../../../../../components/Forms/InputForm'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import InputCheckbox from '../../../../../../components/Forms/InputCheckbox'
import TaxesTable from '../components/TaxesTable'
import TaxCard from '../components/TaxCard'



const Price = ({ onChange, onChangeProd, onChangeCurrency, form, form_product, currency, content, taxes, onAddTax, onEditTax, onDeleteTax, taxes_price, final_price}) => {

    const classes = useStyles()
    let discount_view = null

    switch (form_product.product_discount_type_id.value) {
        case 1:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_discount} onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_price} onChange={onChange} />
                                </Grid>
                            </Grid>
            break;
        case 2:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_paid_quantity} onChange={onChangeProd} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_free_quantity} onChange={onChangeProd} />
                                </Grid>
                            </Grid>
            break;
        case 3:
            discount_view = <Grid container spacing={4}>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form_product.sale_bundle_quantity} onChange={onChangeProd} />
                                </Grid>
                                <Grid item xs={12} md={6} >
                                    <InputForm data={form.sale_bundle_price} onChange={onChange} />
                                </Grid>
                            </Grid>
            break;
        default:
            break;
    }

    return (
        <div >
            <Grid container spacing={4} justifyContent='flex-end'>
                <Grid item xs={12} style={{marginBottom:20}}>
                    <Grid container alignItems='center'>
                        <Grid item ><Typography variant='subtitle1'>{content.title}</Typography></Grid>
                        <Grid item xs><div style={{width:'98%', height:0.5, background:'#DCDCDC', marginLeft:16 }}></div></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={4} justifyContent='center'  >
                        <Grid item md='auto' xs={12}>
                            <FormControl variant="standard" component="fieldset">
                            <FormLabel component="legend">{content.form.currency_id.label}</FormLabel>
                            <RadioGroup value={currency} onChange={onChangeCurrency}>
                                <FormControlLabel
                                value={1} control={<Radio color='primary' />} label={content.formControl.currency_1} />
                                <FormControlLabel value={0} control={<Radio color='primary' />} label={content.formControl.currency_2} />
                            </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item md='auto' xs={12}>
                            <Grid container direction='column' spacing={1} className={classes.middle_container}>
                                <Grid item >
                                    <div  >
                                        <InputForm data={form.price} onChange={onChange} />
                                    </div>
                                </Grid>
                                <Grid item >
                                    <InputCheckbox data={form_product.taxable} onChange={onChangeProd} />
                                </Grid>
                                {/* {Boolean(form_product.taxable.value) ?
                                    <Grid item md={12} >
                                        <TaxesTable 
                                            data={taxes}
                                            onAddTax={onAddTax}
                                            onEditTax={onEditTax}
                                            onDeleteTax={onDeleteTax}
                                        />
                                    </Grid> 
                                : null} */}                    
                            </Grid>
                                        
                        </Grid>
                        <Grid item md='auto' xs={12}>
                            <Grid container  spacing={3}>
                                <Grid item xs={12}>
                                    <Typography style={{color:'#607d8b', fontSize:'0.75em', fontWeight:400, marginLeft:8}} >Precio con impuestos</Typography>
                                    <div className={classes.field} ><Typography variant='subtitle1'>${taxes_price}</Typography></div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography style={{color:'#607d8b', fontSize:'0.75em', fontWeight:400, marginLeft:8}} >Precio final</Typography>
                                    <div className={classes.field} ><Typography variant='subtitle1'>${final_price}</Typography></div>
                                </Grid>
                            </Grid>
                        </Grid> 
                    </Grid>     
                </Grid>
            </Grid>
            {Boolean(form_product.taxable.value) ? <div className={classes.section}>
                <Grid container alignItems='center' style={{marginBottom:20}} >
                    <Grid item ><DisplayText variant='subtitle1'>Impuestos</DisplayText></Grid>
                    <Grid item xs />
                    <Grid item><IconButton className={classes.add_button} onClick={onAddTax} size="large"><Icon>add</Icon></IconButton></Grid>
                </Grid>
                <SimpleCard variant='secondary'>
                    <Grid container justifyContent='center' spacing={4}>
                        {taxes.map(item => <Grid item><TaxCard item={item} onEditTax={onEditTax} onDeleteTax={onDeleteTax} /></Grid>)}
                    </Grid>
                </SimpleCard>
            </div> : null}
            <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item ><InputCheckbox data={form_product.sale} onChange={onChangeProd} /></Grid>
                        <Grid item xs><div className={classes.divider}></div></Grid>
                    </Grid>
                </Grid>
                {Boolean(form_product.sale.value) ?<Grid item xs={12} md={6} >
                     <InputForm data={form_product.sale_expiration_date} onChange={onChangeProd} />
                </Grid> : null}
                {Boolean(form_product.sale.value) ? <Grid item xs={12} md={6} >
                    <SelectForm data={form_product.product_discount_type_id} onChange={onChangeProd} /> 
                </Grid>: null}
                {Boolean(form_product.sale.value) ? <Grid item xs={12} >
                     {discount_view}  
                </Grid> : null}
            </Grid>
            <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item ><InputCheckbox data={form_product.multiple_sale} onChange={onChangeProd} /></Grid>
                        <Grid item xs><div className={classes.divider}></div></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    {Boolean(form_product.multiple_sale.value) ? <InputForm data={form_product.multiple_sale_quantity} onChange={onChangeProd} /> : null}
                </Grid> 
            </Grid>
            <Grid container spacing={3} className={classes.section}>
                <Grid item xs={12}>                
                    <Grid container alignItems='center'>
                        <Grid item ><DisplayText variant='subtitle2'>Facturación</DisplayText></Grid>
                        <Grid item xs><div className={classes.divider}></div></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form_product.sat_unit_key} onChange={onChangeProd} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form_product.sat_product_key} onChange={onChangeProd} />
                </Grid> 
            </Grid>
        </div>
    );

}

export default Price

const useStyles = makeStyles(theme => ({
    divider:{
        width:'100%',
        height:0.5,
        background:'#DCDCDC',
        marginLeft:16     
    },
    section:{
        marginTop:40
    },
    add_button:{
        background:theme.palette.primary.main,
        color:'white',
        width:32,
        height:32,
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    field:{
        background:'#F6F7FB',
        borderRadius:5,
        padding:'16px 8px',
        //maxWidth:140
        //width:'100%'
    },
    middle_container:{
        borderRight:'0.5px solid #E1E5F2',
        borderLeft:'0.5px solid #E1E5F2',
        padding:'0px 40px',
        boxSizing:'border-box',
        height:'100%',
        //margin:'0px 40px'
        //margin:'0px 20px'
    }
}))

