import { Typography } from '@mui/material'
import React, {useState} from 'react'
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard'
import ChangeImageModal from '../modals/ChangeImageModal'
import ImageContainer from './ImageContainer'




const MainCard = ({origin, content, onUpdateCompleted, id}) => {



    //modals
    const [image_modal, setImageModal] = useState(false)
    /* const [main_image, setMainImage] = useState(id_main)

    const onSetMainImage = (img) =>{
        setMainImage(img)
    } */

    //images web - mobile
    /* const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('normal')

    const onChangeView = (id_view) => {
        setImageView(id_view)
        if(id_view === 1) setType('normal')
        if(id_view === 2) setType('external')
    }

    let contentView = <ImageContainer image={origin ? origin.image : null} /> 

    switch(image_view){
        case 1:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={origin ? origin.image : null} /> 
            break
        case 2:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={origin ? origin.external_image : null} /> 
            break
        default:
            break
    } */


    return(
        <SimpleCard>
        <ChangeImageModal open={image_modal} onClose={()=>setImageModal(false)} onUpdateCompleted={onUpdateCompleted} id={id} />   
            <Typography variant='subtitle1' color='primary'>{origin ? origin.name : ''}</Typography>        
            <Typography variant='body2'>{origin ? origin.substance : ''}</Typography>
            <Typography variant='body2'>{origin ? origin.presentation : ''}</Typography>               
            <div>
                <div style={{marginTop:40}}>
                {/* {contentView} */}
                <ImageContainer onClick={()=>setImageModal(true)} image={origin ? origin.image : null} /> 
                </div>
                {/* <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} /> */}
            </div>                              
        </SimpleCard>
    )
}

export default MainCard
