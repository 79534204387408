
import React from 'react'
import { TextField, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { grey, red } from '@mui/material/colors'
import Autocomplete from '@mui/material/Autocomplete';
import DisplayText from '../Texts/DisplayText';
//import DisplayText from '../Texts/DisplayText';


const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[900],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `16px`,
      },
    },
  },
}))(TextField);

const AutocompleteFormOptions = props => {

    ///////////////////////////////////
    const {data, onChange, onInputChange, inputValue} = props //onSearch
    const {isValid, isVisited, value, options, disabled, rules} = data;
    const {id, label,placeholder, helperText} = data.config;

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        temp.value = item.value
        temp.isValid = true
      }else{
        temp.value = null
        temp.isValid = rules ? false : true
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if (data) {
      if (options) selectedOptions = options
      if (value !== null && value !== undefined) {
        const singleEl = options.find(el => el.value === value)
        selectedValue = singleEl ? singleEl : selectedValue
      }
    }
    
    

    let inputlabel = null;
    if(label) inputlabel =  <DisplayText variant='subtitle2'  color={!isValid && isVisited ? 'error' : 'inherit'}>{label}</DisplayText>

    return(
      <div>
        <Autocomplete
        disabled={disabled}
        //value={selectedValue}
        options={options}
        getOptionLabel={option => option.label}
        renderInput={params => (<TextField {...params} variant='outlined' color='primary' label={label} error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helperText : null}/>)}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={inputValue ? inputValue : undefined}
        noOptionsText={<DisplayText>Sin opciones </DisplayText>}
        renderOption={(option, state) => 
          <div>
            <DisplayText variant={state.selected ? 'subtitle1' : 'body1'}>{option.label}</DisplayText>
          </div>
        }/>
      </div>
      
    )
}

export default AutocompleteFormOptions