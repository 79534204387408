

export const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    sub_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sub_name',
            type: 'text',
            fullWidth: true,
            label: 'Sub Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    active_substance: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'active_substance',
            type: 'text',
            fullWidth: true,
            label: 'Principio Activo',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    presentation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'presentation',
            type: 'text',
            fullWidth: true,
            label: 'Presentación',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    administration_route: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'administration_route',
            type: 'text',
            fullWidth: true,
            label: 'Vía de administración',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 255',
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    short_description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'short_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción Corta',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:3,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    pharmaceutical_form_short: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pharmaceutical_form_short',
            type: 'text',
            fullWidth: true,
            label: 'Forma farmacéutica corta',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:3,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 1024',
            rows:4,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 1024
        }
    },
    package: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'package',
            type: 'text',
            fullWidth: true,
            label: 'Empaquetado',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    product_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'product_quantity',
            type: 'tel',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Este campo debe ser mayor a cero',
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'measure',
            type: 'text',
            fullWidth: true,
            label: 'Medida',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    additional_measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_measure',
            type: 'text',
            fullWidth: true,
            label: 'Formulación',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    weight: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'weight',
            type: 'text',
            fullWidth: true,
            label: 'Peso',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    height: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'height',
            type: 'text',
            fullWidth: true,
            label: 'Altura',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    width: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'width',
            type: 'text',
            fullWidth: true,
            label: 'Ancho',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    length: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'length',
            type: 'text',
            fullWidth: true,
            label: 'Largo',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 5',
        },
        rules: {
            type: 'distance',
            min: 0, max: 5
        }
    },
    warranty: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'warranty',
            type: 'text',
            fullWidth: true,
            label: 'Garantía',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:2,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    sat_unit_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sat_unit_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave unitaria sat',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    sat_product_key: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sat_product_key',
            type: 'text',
            fullWidth: true,
            label: 'Clave del producto',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 65536',
            rows:1,
            multiline:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 65536
        }
    },
    requires_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:1, label:'Sí'},{value:0, label:'No'}],
        config: {
            id: 'requires_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Requiere receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    retains_recipe: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:0, label:'No'},{value:1, label:'Sí'},],
        config: {
            id: 'retains_recipe',
            type: 'selected',
            fullWidth: true,
            label: 'Retiene receta',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    brand_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'selected',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    product_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'product_type_id',
            type: 'selected',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    product_subtype_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'product_subtype_id',
            type: 'selected',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    sku: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sku',
            type: 'text',
            fullWidth: true,
            label: 'Sku',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    cofepris: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'cofepris',
            type: 'text',
            fullWidth: true,
            label: 'Cofepris',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    barcode: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'barcode',
            type: 'text',
            fullWidth: true,
            label: 'Barcode',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    search_string: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search_string',
            type: 'text',
            fullWidth: true,
            disabled:true
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    search_metadata: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'search_metadata',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    enabled: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'enabled',
            //type: 'text',
            fullWidth: true,
            label: 'Activo',
            //helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        /* rules: {
            type: 'distance',
            min: 0, max: 255
        } */
    },

    //lo que estaba en price
    taxable: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'taxable',
            fullWidth: true,
            //label: 'Incluir iva en el precio',
        },
    },
    sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale',
            fullWidth: true,
            //label: 'Incluir descuento',
        },
    },
    multiple_sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'multiple_sale',
            //label: 'Promociones por puntos',
        },
    },
    multiple_sale_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'multiple_sale_quantity',
            type: 'text',
            fullWidth: true,
            //label: 'Cantidad',
            //helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_expiration_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_expiration_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de expiración',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'date',
        }
    },
    product_discount_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'product_discount_type_id',
            type: 'selected',
            fullWidth: true,
            label: 'Tipo de descuento',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sale_bundle_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Cantidad de articulos para precio de mayoreo',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_paid_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_paid_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos pagados',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_free_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_free_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos gratis',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
}

export const priceFormData = {
    
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[{value:1, label:'Peso mexicano'},{value:2, label:'Dolar americano'}],
        config: {
            id: 'currency_id',
            type: 'selected',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    price: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'price',
            type: 'text',
            fullWidth: true,
            label: 'Precio',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    }, 
    sale_discount: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'sale_discount',
            type: 'text',
            fullWidth: true,
            label:'Descuento'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_price: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_price',
            type: 'text',
            fullWidth: true,
            disabled:true,
            label:'Precio con descuento'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_price: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_price',
            type: 'text',
            fullWidth: true,
            label:'Precio de mayoreo'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },   
}

//searchs form
export const searchFormData = {
    id_tag: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'id_tag',
            type: 'selected',
            fullWidth: true,
            //label: 'Moneda',
            label:'Buscar...',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    id_category: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'id_category',
            type: 'selected',
            fullWidth: true,
            //label: 'Moneda',
            label:'Buscar...',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    id_sub_category: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'id_sub_category',
            type: 'selected',
            fullWidth: true,
            //label: 'Moneda',
            label:'Buscar...',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    id_segment: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'id_segment',
            type: 'selected',
            fullWidth: true,
            //label: 'Moneda',
            label:'Buscar...',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    id_sub_segment: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'id_sub_segment',
            type: 'selected',
            fullWidth: true,
            //label: 'Moneda',
            label:'Buscar...',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
}

const formDataProduct = {
    taxable: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'taxable',
            fullWidth: true,
            //label: 'Incluir iva en el precio',
        },
    },
    sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sale',
            fullWidth: true,
            //label: 'Incluir descuento',
        },
    },
    multiple_sale: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'multiple_sale',
            //label: 'Promociones por puntos',
        },
    },
    multiple_sale_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'multiple_sale_quantity',
            type: 'text',
            fullWidth: true,
            //label: 'Cantidad',
            //helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_expiration_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sale_expiration_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de expiración',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'date',
        }
    },
    product_discount_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'product_discount_type_id',
            type: 'selected',
            fullWidth: true,
            label: 'Tipo de descuento',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sale_bundle_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Cantidad de articulos para precio de mayoreo',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_paid_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_paid_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos pagados',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
    sale_bundle_free_quantity: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sale_bundle_free_quantity',
            type: 'text',
            fullWidth: true,
            label: 'Articulos gratis',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'numeric',
            min:0
        }
    },
}