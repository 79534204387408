import React from 'react';
import PublicRouters from './routes/router';
import { createBrowserHistory } from "history";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './store/reducer'
import { blueGrey } from '@mui/material/colors';
import { theme } from './theme';


const store = createStore(reducer);


const App = (props) => {

  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <PublicRouters history={createBrowserHistory()}/>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
