import { change_image_error_msg } from "./general-translations";
import user from '../../assets/icons/user.svg'
import product from '../../assets/icons/product.svg'
import discount from '../../assets/icons/discount.svg'

export const translations = {
    title:'Códigos de descuento',
    success_msg:'Acción exitosa',
    menu:[{id:1, label:'Celufarma'}, {id:2, label:'Celudoc'}],
    views:{
        celufarma:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Código'},
                    {id:3, label:'Usos'},
                    {id:4, label:'Expiración'},
                    {id:5, label:'Valor'},    
                    {id:6, label:'Producto'},
                    {id:7, label:'Usuario'},
                    {id:7, label:'Activo'},
                ],
                nodata:'No existen códigos registrados',
                empty:discount,
                user:user,
                product:product
            },
            modal:{
                add:{
                    title:'Nuevo código',
                    button:'Crear'
                },
                edit:{
                    title:'Editar código',
                    button:'Guardar'
                },
                form:{
                    code:{label:'Código', helperText:'Campo requerido'},
                    name:{label:'Nombre', helperText:'Campo requerido'},
                    currency_id:{label:'Moneda', helperText:'Campo requerido'},
                    code_discount_type_id:{label:'Tipo', helperText:'Campo requerido'},
                    discount_value:{label:'Valor', helperText:'Campo requerido'},
                    discount_value:{label:'Valor', helperText:'Campo requerido'},
                    discount_percentage:{label:'Valor', helperText:'Valor de porcentaje inválido'},
                    minimum_purchase:{label:'Compra mínima', helperText:'Campo requerido'},
                    total_uses:{label:'Máx. de usos', helperText:'Campo requerido'},
                    current_uses:{label:'Máx. por usuario', helperText:'Campo requerido'},
                    expiry_date:{label:'Fecha de expiración', helperText:'Campo requerido'},
                    product_id:{label:'Producto', helperText:'Campo requerido'},
                    user_id:{label:'Usuario', helperText:'Campo requerido'},
                    enabled:{label:'Activo', helperText:'Campo requerido'},
                    user_max_uses:{label:'Máx. por usuario', helperText:'Campo requerido'},
                }
            }
        },
        celudoc:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Código'},
                    {id:3, label:'Usos'},
                    {id:4, label:'Expiración'},
                    {id:5, label:'Valor'},    
                    {id:6, label:'Concepto'},
                    {id:7, label:'Usuario'},
                    {id:7, label:'Activo'},
                ],
                nodata:'No existen códigos registrados',
                empty:discount,
                user:user
            },
            modal:{
                add:{
                    title:'Nuevo código',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar código',
                    button:'Guardar'
                },
                form:{
                    code:{label:'Código', helperText:'Campo requerido'},
                    name:{label:'Nombre', helperText:'Campo requerido'},
                    currency_id:{label:'Moneda', helperText:'Campo requerido'},
                    code_discount_type_id:{label:'Tipo', helperText:'Campo requerido'},
                    discount_value:{label:'Valor', helperText:'Campo requerido'},
                    discount_value:{label:'Valor', helperText:'Campo requerido'},
                    discount_percentage:{label:'Valor', helperText:'Campo requerido'},
                    minimum_purchase:{label:'Compra mínima', helperText:'Campo requerido'},
                    total_uses:{label:'Máx. de usos', helperText:'Campo requerido'},
                    current_uses:{label:'Máx. por usuario', helperText:'Campo requerido'},
                    expiry_date:{label:'Fecha de expiración', helperText:'Campo requerido'},
                    payment_concept_id:{label:'Concepto', helperText:'Campo requerido'},
                    user_id:{label:'Usuario', helperText:'Campo requerido'},
                    enabled:{label:'Activo', helperText:'Campo requerido'},
                    user_max_uses:{label:'Máx. por usuario', helperText:'Campo requerido'},
                }
            }
        },
    }
    
}

export default translations

