import { useEffect, useState } from "react"
import { isFormValid, onCleanData, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"



const useMedicationsDetails = ({match, content}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)
   
    const [data, setData] = useState(null)
    const [labs_catalogs, setLabCatalogs] = useState([])

    //form
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //rich texts
    const [pharmaceutical_form, setPharmaceuticalForm] = useState('')
    const [description, setDescription] = useState('')
    const [composition, setComposition] = useState('')
    const [therapeutic_indications, setTherapeuticIndications] = useState('')
    const [pharmaceutical_properties, setPharmaceuticalProperties] = useState('')
    const [contraindications, setContraindications] = useState('')
    const [pregnancy_restrictions, setPregnancyRestrictions] = useState('')
    const [adverse_reactions, setAdverseReactions] = useState('')
    const [medication_interactions, setMedicationInteractions] = useState('')
    const [laboratory_findings, setLaboratoryFindings] = useState('')
    const [warnings, setWarnings] = useState('')
    const [dose, setDose] = useState('')
    const [overdose_management, setOverdoseManagement] = useState('')
    const [storage_recommendations, setStorageRecommendations] = useState('')
    const [protection_captions, setProtectionCaptions] = useState('')

    //substances
    const [substances, setSubstances] = useState([])
    const [sub_selected, setSubSelected] = useState(null)


    const [modals, setModals] = useState({
        change_image:false,
        add_lab:false,
        add_substance:false,
        delete_substance:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`medications/${id}`)
                const labs = await private_server.get(`laboratory/list/`)
                const catalogs = onGetCatalogs(labs.data.data.laboratorys, 'id_laboratory', 'name')
                //console.log(req)
                let _form = onInitEmptyForm(form, content.general_info.form)
                setData(req.data.data.medication)
                const medication = req.data.data.medication
                _form = onInitForm(_form, medication)
                _form.laboratory_id.options = catalogs
                setForm(_form)
                setPharmaceuticalForm(medication.pharmaceutical_form ? medication.pharmaceutical_form : '')
                setDescription(medication.description ? medication.description : '')
                setComposition(medication.composition ? medication.composition : '')
                setTherapeuticIndications(medication.therapeutic_indications ? medication.therapeutic_indications : '')
                setPharmaceuticalProperties(medication.pharmaceutical_properties ? medication.pharmaceutical_properties : '')
                setContraindications(medication.contraindications ? medication.contraindications : '')
                setPregnancyRestrictions(medication.pregnancy_restrictions ? medication.pregnancy_restrictions : '')
                setAdverseReactions(medication.adverse_reactions ? medication.adverse_reactions : '')
                setMedicationInteractions(medication.medication_interactions ? medication.medication_interactions : '')
                setLaboratoryFindings(medication.laboratory_findings ? medication.laboratory_findings : '')
                setWarnings(medication.warnings ? medication.warnings : '')
                setDose(medication.dose ? medication.dose : '')
                setOverdoseManagement(medication.overdose_management ? medication.overdose_management : '')
                setStorageRecommendations(medication.storage_recommendations ? medication.storage_recommendations : '')
                setProtectionCaptions(medication.protection_captions ? medication.protection_captions : '')

                //sustances
                const _substances = await private_server.get(`medicationsubstance/list?order_by=substance_id&filter={"AND":[{"field":"medication_id","operator":"=","value":"${id}"}]}`)
                setSubstances(_substances.data.data.medication_substances)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onMainCardCompleted: async() =>{
            try{
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`medications/${id}`)
                setData(req.data.data.medication)
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm:(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onAddLabCompleted:async() =>{
            try{
                const labs = await private_server.get(`laboratory/list/`)
                const catalogs = onGetCatalogs(labs.data.data.laboratorys, 'id_laboratory', 'name')
                let _form = {...form}
                _form.laboratory_id.options = catalogs
                //_form.laboratory_id.value = catalogs[0]
                setForm(_form)
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onAddSubstanceCompleted:async() =>{
            try{
                const _substances = await private_server.get(`medicationsubstance/list?order_by=substance_id&filter={"AND":[{"field":"medication_id","operator":"=","value":"${id}"}]}`)
                setSubstances(_substances.data.data.medication_substances)
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onDeleteSubstance: (item) =>{
            const {id_medication_substance} = item
            setSubSelected(id_medication_substance)
            actions.onChangeModalStatus('delete_substance', true)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                console.log(errors)
                return
            }
    
            let data2send = onGetSelectedFormData(form, data)
    
            // Rich text data
            data2send.pharmaceutical_form = onCleanData(pharmaceutical_form)
            data2send.description =  onCleanData(description)
            data2send.composition =  onCleanData(composition)
            data2send.therapeutic_indications =  onCleanData(therapeutic_indications)
            data2send.pharmaceutical_properties =  onCleanData(pharmaceutical_properties)
            data2send.contraindications =  onCleanData(contraindications)
            data2send.pregnancy_restrictions =  onCleanData(pregnancy_restrictions)
            data2send.adverse_reactions =  onCleanData(adverse_reactions)
            data2send.medication_interactions =  onCleanData(medication_interactions)
            data2send.laboratory_findings =  onCleanData(laboratory_findings)
            data2send.warnings =  onCleanData(warnings)
            data2send.dose =  onCleanData(dose)
            data2send.overdose_management =  onCleanData(overdose_management)
            data2send.storage_recommendations =  onCleanData(storage_recommendations)
            data2send.protection_captions =  onCleanData(protection_captions)

            console.log(data2send)
    
            //setSending(true)
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                const req = await private_server.patch(`medications/${id}`, data2send)
                actions.onMainCardCompleted()
                setSuccess('Acción exitosa')

                //onUpdateCompleted()
                //onUpdateSuccess(content.success_msg)
                //setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                //setSending(false)
            }
            setSending(false)
        },
        onSetDescription:(temp) => setDescription(temp),
        onSetPharmaceuticalForm:(temp) => setPharmaceuticalForm(temp),
        onSetComposition:(temp) => setComposition(temp),
        onSetTherapeuticIndications:(temp) => setTherapeuticIndications(temp),
        onSetPharmaceuticalProperties:(temp) => setPharmaceuticalProperties(temp),
        onSetContraindications:(temp) => setContraindications(temp),
        onSetPregnancyRestrictions:(temp) => setPregnancyRestrictions(temp),
        onSetAdverseReactions:(temp) => setAdverseReactions(temp),
        onSetMedicationInteractions:(temp) => setMedicationInteractions(temp),
        onSetLaboratoryFindings:(temp) => setLaboratoryFindings(temp),
        onSetWarnings:(temp) => setWarnings(temp),
        onSetDose:(temp) => setDose(temp),
        onSetOverdoseManagement:(temp) => setOverdoseManagement(temp),
        onSetStorageRecommendations:(temp) => setStorageRecommendations(temp),
        onSetProtectionCaptions:(temp) => setProtectionCaptions(temp),
        onClearError:() => setError(null),     
        onClearSuccess:() => setSuccess(null)  
    }

    const system = {loading, sending, error, success}
    const view_data = {data, form, substances, sub_selected, pharmaceutical_form, description, composition, therapeutic_indications, pharmaceutical_properties, contraindications, pregnancy_restrictions,
                        adverse_reactions, medication_interactions, laboratory_findings, warnings, dose, overdose_management, storage_recommendations, protection_captions, labs_catalogs}

    return {system, modals, actions, view_data } 
}

export default useMedicationsDetails

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    presentation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'presentation',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    substance: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'substance',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    external_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_id',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    external_image: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'external_image',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    laboratory_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'laboratory_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
}