import React, {useState} from 'react'
import {
    LinearProgress,
    Button,
    Grid,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
    FormControlLabel,
    TableContainer,
    Card,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFormatNumber } from '../../../../shared/utility'
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import ProductImage from '../../../../components/Structure/Images/ProductImage'
import { translations as translations_esp } from '../../../../texts/esp/products-translations'
//import TableCell from '../../../../components/Structure/Navigation/TableCell'
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import ReactPDF, { pdf, PDFViewer } from '@react-pdf/renderer'
import PDFBardcodes from './PDFBardcodes'
import FileSaver from 'file-saver'
import FilterButton from './FilterButton'
import AvailableStatus from '../../../../components/Structure/DisplayData/AvailableStatus';

const content = translations_esp.table

const header = content.header

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = tableStyles()
    const {data, onAddRegister, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, search, onChangeFilter, sending, onChangeStatus} = props

    const [selects, setSelects] = useState([])

    const onChangeSelect = (event, item) => {
        
        let temp = [...selects]
        if(event.target.checked){
            temp.push(item)
            
        }else{
            //console.log('quitar elemento')
            //console.log(temp.indexOf(item))
            const del_index = temp.indexOf(item)
            temp.splice(del_index, 1)
    
        }
        //console.log(temp)
        setSelects(temp)
    };

    const onDownloadPDF = async() => {
        console.log('Desacargar')

        let data2send = selects?.length > 0 ? [...selects] : data

        try {
            const blob = await pdf(<PDFBardcodes products={data2send}/>).toBlob()
            console.log(blob)
            FileSaver.saveAs(blob,'report.pdf');
        } catch (error) {
            console.log(error)
        }
        
    }

    const onGo2PageProduct = (id_product) => {
        window.open(`/products/${id_product}`)
    }

    const [selected, setSelected] = useState(null)

    const onChageStatusLocal = (item) => {
        onChangeStatus(item)
        setSelected(item?.id_product)
    }

    /* const onDownloadPDF = async() => {
        console.log('Desacargar')
        try {
            const blob = await pdf(<PDFBardcodes products={data}/>).toBlob()
            console.log(blob)
            FileSaver.saveAs(blob,'report.pdf');
        } catch (error) {
            console.log(error)
        }
        
    } */

    const filter_options = [ {value:'name', label:'Alfabeticamente'}, {value:'id_product', label:'ID'}, {value:'enabled', label:'Activo'}]

    

   
    return (
        <div >
            
            <div className={classes.toolbar}>      
                <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                    <Grid item>
                        <Grid container spacing={1} alignItems='center' >
                            <Grid item><IconButton style={{padding:6}} onClick={()=>setSelects([])} size="large"><Icon color='primary' >clear_all</Icon></IconButton></Grid>
                            <Grid item><Typography variant='body2' >Deseleccionar todos los productos</Typography></Grid>
                        </Grid>     
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item>
                        <FilterButton options={filter_options} label='Ordenar por' onChange={(val)=>onChangeFilter(val)} selected={tableFilter.order_by} />
                    </Grid>
                    <Grid item>
                        <RoundedButton  size='small' onClick={onDownloadPDF}>Etiquetas <Icon>file_download</Icon></RoundedButton>
                    </Grid>
                    <Grid item>
                        <SearchBar onChange={onChangeSearch} search={search} onUpdateTableFilter={onUpdateTableFilter} tableFilter={tableFilter} />
                    </Grid>
                    <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                </Grid>            
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 || item.id === 2 ? 'checkbox' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography  className={classes.header} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        //console.log(item)
                                        const {id_product, name, sale, sku, image_web, price, sub_name, presentation, active_substance, product_taxes, taxable, enabled } = item

                                        let taxes = 0

                                        if(taxable && product_taxes.length !== 0){
                                            product_taxes.forEach(el => {
                                                //console.log(parseFloat(el.rate))
                                                taxes = taxes + (parseFloat(el.rate)/100)
                                            });
                                        }

                                        //console.log(taxes)
                                        const final_price = parseFloat(price) + parseFloat(price)*taxes

                                        let temp=[...selects]
                                        //const label_sel = temp_checks.indexOf(item)
                                        //console.log(label_sel)

                                        let _check = false
                                        const el_check = temp.find(el => el.id_product === item.id_product)
                                        //console.log(el_check)
                                        if(el_check) _check = true
                                        if(temp.length === 0) _check = false

                                        return(
                                            <TableRow key={key.toString()} className={classes.row}  >
                                                <TableCell className={classes.cell} ><Checkbox checked={_check}  color='primary' onChange={(event)=>onChangeSelect(event, item)}  /></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{id_product}</DisplayText></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><ProductImage src={image_web} type='TABLE' id={`src-${id_product}`} /></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{sku}</DisplayText></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{name}</DisplayText></TableCell>   
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{sub_name}</DisplayText></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{presentation}</DisplayText></TableCell>
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{active_substance}</DisplayText></TableCell>  
                                                <TableCell className={classes.cell}><AvailableStatus onClick={()=>onChageStatusLocal(item)} status={enabled} loading={sending && selected === id_product}/></TableCell>                     
                                                <TableCell onClick={() => onGo2PageProduct(id_product)} className={classes.cell} ><DisplayText variant='body2'>{onGetFormatNumber(final_price)}</DisplayText></TableCell>     
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? 
            <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div>
            : null}
            {data.length === 0 && !loading? (
                
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata} >
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable