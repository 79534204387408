import React from 'react'
import { CircularProgress, Grid, Icon, Snackbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blue, blueGrey, green } from '@mui/material/colors'
import DisplayText from '../Texts/DisplayText'

const LoadingContainer = ({loading, children, success, onCloseSuccess, sending}) => {

    const classes = useStyles()

    const successContent = <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  autoHideDuration={1500} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>check_circle_outline</Icon></Grid>
        <Grid item><DisplayText variant='body2' style={{color:'white'}}>{success}</DisplayText></Grid>
    </Grid>}/>

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><DisplayText style={{color:'white'}}>Enviando...</DisplayText></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    return (
        <div>
            {sendingContent}
            {successContent}
            {loading ? (
                <div style={{padding:32}}>
                    <Grid container justifyContent='center'><Grid item><CircularProgress /></Grid></Grid>
                </div>
            ) : children}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        margin:'auto',
        padding:16,
        boxSizing:'border-box',
    },
    link:{
        color:blueGrey[500],
        fontWeight:400,
        '&:hover':{
            cursor:'pointer'
        }
    },
    selectedLink:{
        color:blueGrey[700],
        fontWeight:500
    },
    icon:{
        color:blueGrey[500],
        paddingTop:4,
        
    },
    loading:{
        height:`calc(100vh - 400px)`
    },
    success:{
        background:green[700]
    },
    sending:{
        background:blue[700]
    }
}))

export default LoadingContainer