import React from 'react';
import {
    Card,
    Checkbox,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../components/Texts/DisplayText';
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar';
import user_icon from '../../../../assets/icons/users/user_icon.svg';
import { tableStyles } from '../../../../styles/tableStyles';
import NewActionBar from '../../../../components/Actions/NewActionBar';
import { nodata } from '../../../../variables/config';
import FilterButton from './FilterButton';

const SimpleTable = (props) => {
	const classes = tableStyles();
	const { content, data, roles, roleFilter, onAddRegister, search, onChangeStatus, onChangeSearch, total, tableFilter, onUpdateTableFilter, onDelete, loading, onChangeRoleFilter } = props;

	const header = content.table.header;

	return (
        <div>
			<div className={classes.toolbar}>
				<Grid container spacing={2} alignItems='center'>
					<Grid item>
						<FilterButton
							options={[{ value: -1, label: "Todos" }, ...roles]} 
							onChange={onChangeRoleFilter} 
							selected={roleFilter}
						/>
					</Grid>
					<Grid item xs />
					<Grid item>
						<Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
							<Grid item>
								<SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
							</Grid>
							<Grid item>
								<IconButton
                                    className={classes.button}
                                    onClick={onAddRegister}
                                    disabled={roleFilter === -1}
                                    size="large">
									<Icon>add</Icon>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{loading ? (
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<LinearProgress />
					</Grid>
				</Grid>
			) : null}
			<Card className={classes.paper}>
				{data && data.length ? (
					<div>
						<TableContainer className={classes.table_container}>
							<Table stickyHeader className={classes.table}>
								{header ? (
									<TableHead>
										<TableRow>
											{header.map((item) => {
												return (
													<TableCell style={item.id === 1 ? { paddingLeft: 30 } : {}} className={classes.cellHeader} key={item.id.toString()}>
														<DisplayText variant='caption'>{item.label}</DisplayText>
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
								) : null}
								<TableBody>
									{data.map((item, key) => {
										const { module, full_access, create, read, update } = item;

										return (
                                            <TableRow key={key.toString()} className={classes.row}>
												<TableCell className={classes.cell} style={{ paddingLeft: 30 }}>
													<DisplayText variant='caption'>{module ? module : nodata}</DisplayText>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={full_access === 1}
														inputProps={{ 'aria-label': 'full access' }}
														onChange={() => onChangeStatus(item, "full_access")}
														status={full_access}
													/>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={create === 1}
														inputProps={{ 'aria-label': 'create' }}
														onChange={() => onChangeStatus(item, "create")}
														status={create}
													/>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={update === 1}
														inputProps={{ 'aria-label': 'update' }}
														onChange={() => onChangeStatus(item, "update")}
														status={update}
													/>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={read === 1}
														inputProps={{ 'aria-label': 'read' }}
														onChange={() => onChangeStatus(item, "read")}
														status={read}
													/>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={item.delete === 1}
														inputProps={{ 'aria-label': 'delete' }}
														onChange={() => onChangeStatus(item, "delete")}
														status={item.delete}
													/>
												</TableCell>
												<TableCell className={classes.cell}>
													<Checkbox
														checked={item.public === 1}
														inputProps={{ 'aria-label': 'public' }}
														onChange={() => onChangeStatus(item, "public")}
														status={item.public}
													/>
												</TableCell>
												<TableCell className={classes.cell} padding='checkbox'>
													<IconButton
                                                        className={classes.icon_delete}
                                                        onClick={() => onDelete(item.id_permission)}
                                                        size="large">
														<Icon style={{ color: '#D95D71', fontSize: 22 }}>delete</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
                                        );
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				) : null}
			</Card>
			{data && data.length ? (
				<div className={classes.actions}>
					<NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
				</div>
			) : null}
			{data.length === 0 && !loading ? (
				<div className={classes.nodata}>
					<Grid container justifyContent='center' alignItems='center' direction='column'>
						<Grid item>
							<img src={user_icon} alt='' width={180} />
						</Grid>
						<Grid item>
							<DisplayText>{content.nodata}</DisplayText>
						</Grid>
					</Grid>
				</div>
			) : null}
		</div>
    );
};

export default SimpleTable;
