import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableBody,
  IconButton,
  Icon,
  Checkbox,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SelectForm from "../../../../../components/Forms/SelectForm";
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard";
import DisplayText from "../../../../../components/Texts/DisplayText";
import { grey } from "@mui/material/colors";
import { onGetCatalogs, onInitEmptyForm } from "../../../../../shared/utility";
import { request_rolesettings } from "../../requests";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    borderRadius: 16,
    boxShadow: "0 10px 24px 0 rgba(82, 91, 115, .12)",
  },
  cell: {
    borderColor: grey[200],
  },
  row: {
    "&:hover": {
      cursor: "pointer",
      background: grey[100],
    },
  },
  cellHeader: {
    borderColor: grey[300],
    paddingTop: 16,
    paddingBottom: 16,
  },
  button: {
    background: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
  },
  toolbar: {
    marginBottom: 16,
  },
  actions: {
    marginTop: 16,
  },
  table: {
    minWidth: 800,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  nodata: {
    marginTop: 40,
  },
  btnD: {
    display: "flex",
    paddingBottom: "10px",
  },
}));

const UpdateRol = (props) => {
  const classes = useStyles();

  const content = {};
  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)));
  const [error, setError] = useState(" ");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    status: -1,
  });
  const [selected, setSelected] = useState(null);
  const [tableFilter, setTableFilter] = useState({
    limit: 10,
    offset: 0,
    order: 0,
    order_by: "id_role",
  });
  const [search, setSearch] = useState(null);
  const [settings, setSettings] = useState([]);
  const [delete_modal, setDeleteModal] = useState(false);

  const [total, setTotalItems] = useState(10);

  useEffect(() => {
    const initModal = async () => {
      setForm(JSON.parse(JSON.stringify(formData)));
      let _form = onInitEmptyForm(form, content.form);
      const params = onGetParams();
      const _settings = await request_rolesettings(params);
      setSettings(_settings.roles);
      setTotalItems(_settings.count);
      const moduleCatalogs = onGetCatalogs(_settings.roles, "id_role", "name");
      // let _form = onInitEmptyForm(form);
      // console.log(_form);
      _form.role_id.options = moduleCatalogs;
      setForm(_form);
      setError("");
      setLoading(false);

      setDisabled(false);
    };

    initModal();
  }, []);

  const onChange = (data) => {
    let temp = { ...form };
    const id = data.config.id;
    temp[id] = { ...data };
    setForm(temp);
  };

  const onGetParams = () => {
    // setLoading(true);
    const { offset, order_by, order } = tableFilter;
    const params2send = { offset: offset, order_by: order_by, order: order };
    let params = `?${queryString.stringify(params2send)}&limit=10`;

    let filtersArgs = [];

    if (filters.status !== -1)
      filtersArgs.push({
        field: "id_role",
        operator: "=",
        value: `${filters.status}`,
      });

    if (search)
      filtersArgs.push({ field: "module", operator: "=", value: `${search}` });

    if (filtersArgs.length) {
      let finalArgs = { OR: filtersArgs };
      filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs));
      params = `?${queryString.stringify(
        params2send
      )}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}&limit=10`;
    }
    return params;
  };



  const {
    data,
    onAddRegister
  } = props;

  return (
    <SimpleCard>
       <div className={classes.toolbar}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            
         <DisplayText>Roles</DisplayText>
          </Grid>
          <Grid item xs />
          <Grid item>
            
          </Grid>
          <Grid item>
           {onAddRegister ? (

             <Grid item>
                <IconButton size="small" className={classes.button} onClick={onAddRegister}>
                  <Icon>add</Icon>
                </IconButton>
              </Grid>
                ): null}
            
          </Grid>
        </Grid>
      </div>

          <SelectForm data={form.role_id} onChange={onChange} />

          <Paper style={{ marginTop: "20px" }}>
            <div>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <DisplayText>Rol</DisplayText>
                    </TableCell>
                    <TableCell>
                      <DisplayText>Principal</DisplayText>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                {/* Data */}
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="caption">Administrador</Typography>
                    </TableCell>
                    <TableCell className="text-center">
                      <Checkbox />
                    </TableCell>
                    <TableCell className={classes.cell} padding="checkbox">
                      <// onClick={() => onSelectedItem(item, "delete")}
                      IconButton size="large">
                        <Icon>delete</Icon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </Paper>
          {/* {data && data.length ? 
      <div className={classes.actions}>
          <ActionBar
            total={total}
            tableFilter={tableFilter}
            onUpdateTableFilter={onUpdateTableFilter}
          />
        </div>
       : null}

      {data.length === 0 && !loading ? (
        <div className={classes.nodata}>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item>
              <img src={empty} alt="" width={180} />
            </Grid>
            <Grid item>
              <DisplayText>{content.nodata}</DisplayText>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div> */}
        
    </SimpleCard>
  );
};

export default UpdateRol;

export const formData = {
  role_id: {
    value: "",
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options: [],
    config: {
      id: "role_id",
      type: "selected",
      fullWidth: true,
      label: "Selecciona un módulo",
      helperText: "Seleccione Una Opcion",
    },
    rules: {
      type: "select",
    },
  },
};
