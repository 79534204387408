import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import Orders from '../views/Orders/Orders/Orders'
import OrderView from '../views/Orders/OrdersView/OrderView'
import ProductView from '../views/Products/ProductView/ProductView'
import Users from '../views/Users/Users/Users'
import UserView from '../views/Users/UserView/UserView'
import Patients from '../views/Patients/Patients/Patients'
import ProfileView from '../views/ProfileView/ProfileView'
import Catalogs from '../views/Catalogs/Catalogs'
import CategoryView from '../views/Catalogs/views/CategoriesView/CategoryView/CategoryView'
import SubcategoryView from '../views/Catalogs/views/SubcategoriesView/SubategoryView/SubcategoryView'
import SegmentView from '../views/Catalogs/views/SegmentsView/SegmentView/SegmentView'
import ProductsMenu from '../views/Products/ProductsMenu'
import BannersMenu from '../views/Banners/BannersMenu'
import Warehouses from '../views/Warehouse/Warehouses/Warehouses'
import WarehouseView from '../views/Warehouse/WarehouseView/WarehouseView'
import Doctors from '../views/Doctors/Doctors/Doctors'
import DoctorView from '../views/Doctors/DoctorView/DoctorView'
import Carts from '../views/Carts/Carts/Carts'
import CartView from '../views/Carts/CartView/CartView'
import Settings from '../views/SettingsNew/SettingsView'
import RolView from '../views/Settings/Role/RolView/RolView'
import BrandView from '../views/Catalogs/views/BrandsView/BrandView/BrandView'
import SubsegmentView from '../views/Catalogs/views/SubsegmentsView/SubsegmentView/SubsegmentView'
import RoutesView from '../views/Routes/RoutesView/RoutesView'
import RouteView from '../views/Routes/RouteView/RouteView'

import MedicationsView from '../views/Medications/MedicationsView'
import MedicationsDetails from '../views/Medications/views/MedicationsDetails/MedicationsDetails'
import Discounts from '../views/Discounts/Discounts'
import InventoriesVIew from '../views/Inventories/InventoriesVIew/InventoriesVIew'


const PageRouter = props => {
    return(
        <Switch>
            <Route component={UserView} path='/users/:id' exact/>
            <Route component={Users} path='/users' />
            <Route component={Patients} path='/patients' />
            <Route component={DoctorView} path='/doctors/:id' exact />
            <Route component={Doctors} path='/doctors' />
            <Route component={InventoriesVIew} path='/inventory'/>
            <Route component={ProductView} path='/products/:id' exact/>
            <Route component={ProductsMenu} path='/products' />
            <Route component={OrderView} path='/orders/:id' exact/>
            <Route component={Orders} path='/orders' />
            <Route component={CartView} path='/carts/:id' exact/>
            <Route component={Carts} path='/carts' />
            <Route component={ProfileView} path='/profile' />
            <Route component={BrandView} path='/catalogs/brand/:id' exact/> 
            <Route component={CategoryView} path='/catalogs/category/:id' exact/> 
            <Route component={SubcategoryView} path='/catalogs/subcategory/:id' exact/> 
            <Route component={SegmentView} path='/catalogs/segment/:id' exact/> 
            <Route component={SubsegmentView} path='/catalogs/subsegment/:id' exact/> 
            <Route component={Catalogs} path='/catalogs' />  
            <Route component={MedicationsView} path='/medications' exact/>
            <Route component={MedicationsDetails} path='/medications/:id' />
            <Route component={BannersMenu} path='/banners' />
            <Route component={WarehouseView} path='/warehouses/:id' />
            <Route component={Warehouses} path='/warehouses' />        
            <Route component={RolView} path='/settings/:id' exact />
            <Route component={Settings} path='/settings' />
            <Route component={RouteView} path='/routes/:id' exact/>
            <Route component={RoutesView} path='/routes' />
            <Route component={Discounts} path='/discounts' />
            <Redirect from='/' to='/banners'/>
        </Switch>
    )
}

export default PageRouter