import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { styled } from '@mui/material/styles';
import {
    Badge,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    useRadioGroup,
    styled,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blueGrey } from '@mui/material/colors';
import cx from 'classnames'


const ToolbarRadio = (props) => {

    const {selected, onChange, onAddRegister, menuOptions, color} = props

    const handleChange = (event) => {
        onChange(event.target.value.toString())
    };

    return (
        <FormControl variant="standard">
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={selected.toString()}
                onChange={handleChange}
            >
                {menuOptions.map(item => {
                    return <MyFormControlLabel key={item.id} value={item.id.toString()} control={<Radio color='primary' />} label={item.label} />
                })}
            </RadioGroup>
        </FormControl>
    );
}

export default ToolbarRadio;

function MyFormControlLabel(props) {
    const radioGroup = useRadioGroup();
  
    let checked = false;
  
    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }
  
    return <StyledFormControlLabel checked={checked} {...props} />;
  }

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

const StyledFormControlLabel = styled((props) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
      '.MuiFormControlLabel-label': checked && {
        color: theme.palette.primary.main,
      },
    }),
  );