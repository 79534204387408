import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
//import { request_create_warehouse, request_zipinfo } from '../requests'
import { private_server } from '../../../../../../variables/config'


const AddModal = ({open, onClose, onUpdateCompleted, content}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    const [zip_number, setZipNumber] = useState(null)

    //checks
    const [store, setStore] = useState(false)
    const [pickup, setPickup] = useState(false)
    const [delivery, setDelivery] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            let _form = onInitEmptyForm(form, content.form)
            _form.country_id.isValid = true
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }
    onInitModal()
    }, [open])

    useEffect(() => {
        const onSearchZipAttributes = async(value) => {
            if(value === "" || value === null || value === undefined || value.length < 5) return
            try {
                //let zip_info = await request_zipinfo(value)
                updateAuthorizationHeader(private_server)
                let zip_info = await private_server.get(`/address/zipinfo?zip=${value}`)
                //console.log(zip_info)
                zip_info = zip_info.data.data.zip_information
                if(zip_info){
                    const {id_zip_code, value, municipality, municipality_id, state, state_id} = zip_info.zip_code
                    let _form = {...form}
                    _form.state_id.value = state_id
                    _form.state_id.isValid = true
                    _form.state_id.options = [{value:state_id, label:state}]
                    _form.municipality_id.value = municipality_id
                    _form.municipality_id.isValid = true
                    _form.municipality_id.options = [{value:municipality_id, label:municipality}]
                    _form.suburb_id.value = ''
                    _form.suburb_id.options = onGetCatalogs(zip_info.sub_urbs,'id_suburb','name')
                    _form.zip_code.value = value
                    _form.zip_code_id.value = id_zip_code
                    _form.zip_code_id.isValid = true
                    _form.zip_code.isValid = true
                    _form.country_id.value = 1
                    _form.country_id.isValid= true
                    setForm(_form)
                }
            } catch (error) {
                //console.log(error)
            }
        } 
        onSearchZipAttributes(zip_number)
    }, [zip_number])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'zip_code') setZipNumber(data.value)
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.store = store ? 1 : 0
        data2send.delivery = delivery ? 1 : 0
        data2send.pickup = pickup ? 1 : 0
        console.log(data2send)
        
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`warehouse`, data2send)
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
       
    }

    return (
        <SimpleModal maxWidth='sm' open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add.title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.contact_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.contact_phone} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.contact_email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.additional_indications} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle2'>{content.add.title2}</DisplayText>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.zip_code} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <SelectForm data={form.suburb_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.street} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.ext} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.int} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <SelectForm data={form.municipality_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <SelectForm data={form.state_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <SelectForm data={form.country_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.latitude} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.longitude} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle2'>{content.add.title3}</DisplayText>
                        </Grid>
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={store} onChange={()=>setStore(!store)} />}
                                label={content.formControl.store}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={pickup} onChange={()=>setPickup(!pickup)} />}
                                label={content.formControl.pickup}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={delivery} onChange={()=>setDelivery(!delivery)} />}
                                label={content.formControl.delivery}
                            />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={loading}>
                    {content.add.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddModal

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    contact_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    ext: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ext',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    int: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'int',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    additional_indications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_indications',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'zip',
        }
    },
    zip_code_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code_id',
            type: 'text',
            fullWidth: true,
        },
    },
    suburb_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'suburb_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type:'select',
        }
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    country_id: {
        value:1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[{value:1, label:'México'}],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
            disabled:true
        },
        rules: {
            type: 'select',
        }
    },
    latitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'latitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    longitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'longitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
}
