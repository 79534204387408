import React, {useState, useEffect} from 'react'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../shared/utility'
import BannerModal from '../../modals/BannerModal'
import { formData } from '../../modals/data'
import { request_create_storebanner } from '../requests'


const AddModal = ({open, onClose, onUpdateCompleted, content}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }
    onInitModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.enabled = 1
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            await request_create_storebanner(data2send);
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <BannerModal open={open} onClose={onClose} content={content} form={form} onSubmit={onSubmit} onChange={onChange}
        loading={loading} error={error} disabled={disabled} />
    )

}


export default AddModal
