import { Grid } from '@mui/material';
import Page from '../../../../components/Structure/Page/Page';
import MainCard from './components/MainCard';
import useMedicationsDetails from './useMedicationsDetails';
import GeneralInfo from './components/GeneralInfo'
import { translations as translations_esp } from '../../../../texts/esp/medications-details-translations';
import AddLabModal from '../Labs/modals/AddModal';
import SubstancesTable from './components/SubstancesTable';
import AddSubstanceModal from './modals/AddSubstanceModal/AddSubstanceModal';
import DeleteSubstanceModal from './modals/DeleteSubstanceModal';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';


const MedicationsDetails = ({match}) => {

    const content = translations_esp
    const id = match.params.id
    const {system, modals, actions, view_data} = useMedicationsDetails({content, match})

  
    return(
        <Page title={content.title} loading={system.loading} sending={system.sending} error={system.error} onCloseErrorModal={actions.onClearError}  goback
            success={system.success} onCloseSuccess={actions.onClearSuccess} >
            <AddLabModal open={modals.add_lab} onClose={()=>actions.onChangeModalStatus('add_lab', false)} content={content.lab_modal} 
                onUpdateCompleted={actions.onAddLabCompleted}/>
            <AddSubstanceModal open={modals.add_substance} onClose={()=>actions.onChangeModalStatus('add_substance', false)} content={content.substance_modal}
                onUpdateCompleted={actions.onAddSubstanceCompleted} id={id} />
            <DeleteSubstanceModal open={modals.delete_substance} onClose={()=>actions.onChangeModalStatus('delete_substance', false)} 
                onUpdateCompleted={actions.onAddSubstanceCompleted} id={view_data.sub_selected} />
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} xl={3}>
                    <MainCard origin={view_data.data} content={content.main_card} id={id} onUpdateCompleted={actions.onMainCardCompleted} />
                </Grid>
                <Grid item xs={12} md={8} xl={9}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <GeneralInfo {...view_data} actions={actions} content={content.general_info} />
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleCard>
                                <DisplayText variant='subtitle1'>{content.table.title}</DisplayText>         
                                <SubstancesTable 
                                    content={content.table}
                                    data={view_data.substances}                           
                                    onAddRegister={() => actions.onChangeModalStatus('add_substance', true)}
                                    onDeleteItem={actions.onDeleteSubstance}
                                    //onSelectedItem={onEditActive}
                                />
                            </SimpleCard>
                        </Grid>
                    </Grid>            
                </Grid>
            </Grid>
        </Page>
    )
}



export default MedicationsDetails