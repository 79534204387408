import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import Dashboard from '../layouts/Dashboard/Dashboard'
import {connect} from 'react-redux'
import * as actionTypes from '../store/actions'
import SignIn from '../layouts/SignIn/SignIn'



const PublicRouters = props => {

    const {history, client} = props

    return(
        <Router history={history}>
            <Switch>
                <Route component={SignIn} path='/signin'/>
                <Route path={'/'} component={Dashboard}/>
                <Redirect to='/banners'/>
            </Switch>
        </Router>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
    }
}

export default connect(null, mapDispatchToProps)(PublicRouters)