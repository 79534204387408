import { useEffect, useState } from "react"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"



const useMedical = ({match, content, onUpdateError, onUpdateSending, onSuccess, catalogs}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    
    //forms
    const [form1, setForm1] = useState(JSON.parse(JSON.stringify(formData1)))
    const [form2, setForm2] = useState(JSON.parse(JSON.stringify(formData2)))

    const [doctor, setDoctor] = useState(null)
    const [doctor_trans, setDoctorTrans] = useState(null)
    const [language_selected, setLanguageSelected] = useState(1)
    const [description, setDescription] = useState('')
    const [flag, setFlag] = useState(false)
    const [error_bio, setErrorBio] = useState('')
   

    const [modals, setModals] = useState({
    
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onInitDoctorTranslations()
    }, [language_selected])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                //const _doctor = await request_doctor_details(id)
                updateAuthorizationHeader(private_server)
                const _doctor = await private_server.get(`/doctor/${id}/details`)
                console.log(_doctor)
                setDoctor(_doctor.data.data.doctor_details)

               
                //const _doctor_trans = await request_doctor_translations(id, language_selected)
                const _doctor_trans = await private_server.get(`/doctor/${id}/translations/${language_selected}`)
                //console.log(_doctor_trans.data.data.doctor_details_translation)
                setDoctorTrans(_doctor_trans.data.data.doctor_details_translation)
                
                let _form1 = onInitEmptyForm(form1, content.form_medical)
                _form1.speciality_id.options = catalogs.specialities
                _form1.sub_speciality_id.options = catalogs.specialities
                if(_doctor.data.data.doctor_details) _form1 = onInitForm(_form1, _doctor.data.data.doctor_details)
                setForm1(_form1)
                let _form2 = onInitEmptyForm(form2, content.form_medical)
                if(_doctor_trans.data.data.doctor_details_translation) _form2 = onInitForm(_form2, _doctor_trans.data.data.doctor_details_translation )
                _form2.language.options = catalogs.languages
                _form2.language.value = language_selected
                setForm2(_form2)
                if(_doctor_trans.data.data.doctor_details_translation) setDescription(_doctor_trans.data.data.doctor_details_translation.bio)
                setLoading(false)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onInitDoctorTranslations: async() => {
            try {
                //setLoading(true)
                //const _doctor_trans = await request_doctor_translations(id, language_selected)
                updateAuthorizationHeader(private_server)
                const _doctor_trans = await private_server.get(`/doctor/${id}/translations/${language_selected}`)
                setDoctorTrans(_doctor_trans.data.data.doctor_details_translation)
                //console.log(_doctor_trans)
                if(_doctor_trans.data.data.doctor_details_translation === null){
                    setFlag(true)
                    let _form2 = onInitEmptyForm(formData2, content.form_medical)
                    _form2.language.options = catalogs.languages
                    _form2.language.value = language_selected
                    setForm2(_form2)
                    setDescription('')
                }
                else{
                    let _form2 = onInitForm(form2, _doctor_trans.data.data.doctor_details_translation )
                    _form2.language.value = language_selected
                    setForm2(_form2)
                    setDescription(_doctor_trans.data.data.doctor_details_translation.bio)
                }
                //setLoading(false)
            } catch (error) {
                setLoading(false)
                onUpdateError(onGetErrorMessage(error))
                console.log(error)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm1:(data) => {
            let temp = { ...form1 }
            const id = data.config.id
            temp[id] = { ...data }
            setForm1(temp)
        },
        onChangeForm2:(data) => {
            let temp = { ...form2 }
            const id = data.config.id
            temp[id] = { ...data }
            //console.log(temp[id].value)
            if(id === 'language') setLanguageSelected(temp[id].value)
            if(id === 'bio') setDescription(temp[id].bio)
            setForm2(temp)
        },
        onSubmit: async() => {
            const errors = isFormValid(form1) 
            const errors2 = isFormValid(form2)
            //console.log(errors)
            /// Check if the form is valid
            if(errors && errors.length){
                console.log('errors1')
                const new_form = onSetErrorsToForm(form1, errors)
                setForm1(new_form)
                return
            }
    
            if(errors2 && errors2.length){
                //console.log(errors2)
                const new_form = onSetErrorsToForm(form2, errors)
                setForm2(new_form)
                return
            }
    
            if(description === ''){
                setErrorBio(content.bio_error_msg)
                //console.log('hola')
                return
            }
    
            //Extract data
            let data2send_doctor_details = onGetSelectedFormData(form1, doctor)
            console.log(data2send_doctor_details)
    
            let data2send = onGetSelectedFormData(form2, doctor_trans)
            data2send.bio = description
            console.log(data2send)
            //console.log(flag)
    
    
            setSending(true)
            onUpdateSending(true)
            try {
                //await request_update_doctor_details(id, data2send_doctor_details)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/doctor/${id}/details`, data2send_doctor_details)
                if(flag){
                    data2send.language_id = data2send.language
                    delete data2send.language
                    console.log(data2send)         
                    //await request_create_doctor_translations(id, data2send);
                    updateAuthorizationHeader(private_server)
                    await private_server.post(`/doctor/${id}/translations`, data2send)
                }else{
                    delete data2send.language
                    console.log(data2send)
                    //await request_update_doctor_translations(id, doctor_trans.id_doctor_detail_translation, data2send);
                    updateAuthorizationHeader(private_server)
                    await private_server.patch(`/doctor/${id}/translations/${doctor_trans.id_doctor_detail_translation}`, data2send)
                }
                console.log('success')
                onSuccess()
                setSending(false)
                onUpdateSending(false)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
                setSending(false)
                onUpdateSending(false)
            }
        },
        onSetDescription: (temp) => {
            setDescription(temp)
            setErrorBio('')
        }
          
    }

    const system = {loading, sending, error_bio}
    const view_data = {doctor, doctor_trans, form1, form2, description, language_selected}

    return {system, modals, actions, view_data } 
}

export default useMedical

const formData1 = {
    speciality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.specialities],
        options:[],
        config: {
            id: 'speciality_id',
            type: 'select',
            fullWidth: true,
        },
    },
    sub_speciality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.specialities],
        options:[],
        config: {
            id: 'sub_speciality_id',
            type: 'select',
            fullWidth: true,
        },
    },
    license: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'license',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}

const formData2 = {
    language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.languages],
        options:[],
        config: {
            id: 'language',
            type: 'select',
            fullWidth: true,
        },
    },
    qualifications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'qualifications',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    bio: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'bio',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}