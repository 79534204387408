import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import { Typography, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mainServer, USER_TYPES } from '../../../../variables/config';
import { isFormValid, onGetErrorMessage, onGetFormData, onGetNameCatalogs, onInitForm, onSetErrorsToForm, onUpdateAuthorizationHeader } from '../../../../shared/utility';
import { grey } from '@mui/material/colors';
import InputForm from '../../../../components/Forms/InputForm';
import catalogs from '../../../../texts/esp/catalogs';
import RoundedButton from '../../../../components/Actions/RoundedButton';
import CustomSelectForm from '../components/CustomSelectForm';

const DeliveryModal = ({open, onClose, onCompleted, id, user_id}) => {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sending_problem, setSendingProblem] = useState(false)
    const [error, setError] = useState('')
    const [error_problem, setErrorProblem] = useState('')

    const [route_order, setRouteOrder] = useState(null)
    const [order, setOrder] = useState(null)
    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [problem_form, setProblemForm] = useState(JSON.parse(JSON.stringify(form_problem)))

    console.log(id)

    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            try {         
                onUpdateAuthorizationHeader()
                let _route_order = await mainServer.get(`/routeorder/${id}`)
                setRouteOrder(_route_order.data.data.route_order)
                //console.log(_route_order.data.data.route_order)
                let _form = onInitForm(form, _route_order.data.data.route_order )
                setForm(_form)
                let _problem_form = onInitForm(problem_form, _route_order.data.data.route_order )
                setProblemForm(_problem_form)
            } catch (error) {
                console.log(error)
            }  
            setLoading(false)  
        }
        if(open){initModule()}else{
            setForm(form_data)
            setProblemForm(form_problem)
        }
    }, [open])

    const onChangeForm = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(error) setError(null)
        setForm(temp)
    }

    const onChangeProblemForm = (data) => {
        let temp = { ...problem_form }
        const id = data.config.id
        temp[id] = { ...data }
        if(error) setError(null)
        setProblemForm(temp)
    }

    const onSubmit = async() => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)

        setSending(true)
        try{
            onUpdateAuthorizationHeader()
            await mainServer.patch(`/routeorder/${id}`, data2send)
            onCompleted()
            onClose()
        }catch(e){
            console.log(e)
            setError(onGetErrorMessage(e))
        } 
        setSending(false)
    }

    const onSubmitProblem = async() => {

        const errors = isFormValid(problem_form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(problem_form, errors)
            setProblemForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(problem_form)
        console.log(data2send)
        console.log(user_id)

        setSendingProblem(true)
        try{
            onUpdateAuthorizationHeader()
            await mainServer.post(`/routeorder/user/${user_id}/${id}/issue`, data2send)
            onCompleted()
            onClose()
        }catch(e){
            console.log(e)
            setErrorProblem(onGetErrorMessage(e))
        } 
        setSendingProblem(false)
    }
    

    return ( 
        <SimpleModal open={open} onClose={onClose} >
            <div style={{marginTop:16}}>
                <Typography variant='subtitle1'>Entrega</Typography>
                <div className={classes.form}>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <Typography>Notas</Typography>
                            <InputForm data={form.notes} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Comentario</Typography>
                            <InputForm data={form.comments} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginBottom:16}} color='error' align='center' >{error}</Typography>
                            <RoundedButton color='primary' fullWidth onClick={onSubmit} loading={sending} >Guardar</RoundedButton>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <div className={classes.problem_container}>
                        <div className={classes.line} /><Typography variant='subtitle1' style={{margin:'0px 8px'}} >Problema</Typography><div className={classes.line} />
                    </div>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <Typography>Motivo</Typography>
                            <CustomSelectForm data={problem_form.issue_type_id} onChange={onChangeProblemForm} />
                        </Grid>
                        {/* <Grid item xs={12}>
                            <Typography>Problema del pedido</Typography>
                            <CustomSelectForm data={problem_form.order_issue_type_id} onChange={onChangeProblemForm} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <Typography>Detalles</Typography>
                            <InputForm data={problem_form.issue_details} onChange={onChangeProblemForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{marginBottom:16}} color='error' align='center' >{error_problem}</Typography>
                            <RoundedButton color='white_secondary_dark' fullWidth onClick={onSubmitProblem} loading={sending_problem} >¡ Reportar problema</RoundedButton>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        marginTop:32,
        maxHeight:300,
        overflowY:'auto',
        overflowX:'hidden'
    },
    list_item:{
        padding:'12px 4px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:grey[100]
        }
    },
    form:{
        padding:'40px 0px'
    },
    line:{
        width:'100%',
        height:1,
        background:'#D4D4D4'
    },
    problem_container:{
        display:'flex',
        alignItems:'center',
        marginBottom:20
    }
}))

export default DeliveryModal;

const form_data = {
    notes:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config:{
            id:'notes',
            type:'text',
            fullWidth: true,
            helperText:'Campo requerido',
            multiline:true,
            rows:4
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
    comments:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'comments',
            type:'text',
            fullWidth: true,
            helperText:'Campo requerido',
            multiline:true,
            rows:4
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
}

const form_problem = {
    issue_type_id:{
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[...catalogs.route_order_issues],
        config:{
            id:'issue_type_id',
            type:'select',
            fullWidth: true,
            helperText:'Debe seleccionar una opción'
        },
        rules:{
            type:'select',
        }
    },
    /* order_issue_type_id:{
        value: 9,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:catalogs.order_statuses.slice(-2),
        config:{
            id:'order_issue_type_id',
            type:'select',
            fullWidth: true,
            helperText:'Debe seleccionar una opción'
        },
        rules:{
            type:'select',
        }
    }, */
    issue_details:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'issue_details',
            type:'text',
            fullWidth: true,
            helperText:'Campo requerido',
            multiline:true,
            rows:4
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
}