export const ADD_SESSION = 'ADD_SESSION'

export const CHANGE_AUTH_STATUS = 'CHANGE_AUTH_STATUS' 
export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS'
export const UPDATE_APOLLO_CLIENT = 'UPDATE_APOLLO_CLIENT'

/// AUTHENTIFICATION
export const AUTH_RECOVER_PASSWORD_MODAL = 'AUTH_RECOVER_PASSWORD_MODAL'

export const UPDATE_SENDING = 'UPDATE_SENDING'
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS'
export const UPDATE_ERROR = 'UPDATE_ERROR'


export const actionTypes = {
    AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
    AUTH_UPDATE_STATUS: 'AUTH_UPDATE_STATUS' ,

    //views
    CHANGE_PRODUCTS_VIEW: 'CHANGE_PRODUCTS_VIEW',
    CHANGE_CATALOGS_VIEW: 'CHANGE_CATALOGS_VIEW',

    //tables
    CHANGE_PRODUCTS_GENERAL_TABLE: 'CHANGE_PRODUCTS_GENERAL_TABLE',
    CHANGE_PRODUCTS_MOSTSOLD_TABLE: 'CHANGE_PRODUCTS_MOSTSOLD_TABLE',
    CHANGE_PRODUCTS_NEW_TABLE: 'CHANGE_PRODUCTS_NEW_TABLE',
    CHANGE_PRODUCTS_OUT_TABLE: 'CHANGE_PRODUCTS_OUT_TABLE',
    CHANGE_PRODUCTS_SALE_TABLE: 'CHANGE_PRODUCTS_SALE_TABLE',
    CHANGE_CAT_BRANDS_TABLE: 'CHANGE_CAT_BRANDS_TABLE',
    CHANGE_CAT_CATEGORIES_TABLE: 'CHANGE_CAT_CATEGORIES_TABLE',
    CHANGE_CAT_SUBCATEGORIES_TABLE: 'CHANGE_CAT_SUBCATEGORIES_TABLE',
    CHANGE_CAT_SEGMENTS_TABLE: 'CHANGE_CAT_SEGMENTS_TABLE',
    CHANGE_CAT_SUBSEGMENTS_TABLE: 'CHANGE_CAT_SUBSEGMENTS_TABLE',
    CHANGE_USERS_TABLE: 'CHANGE_USERS_TABLE',
    CHANGE_DOCTORS_TABLE: 'CHANGE_DOCTORS_TABLE',
    CHANGE_PATIENTS_TABLE: 'CHANGE_PATIENTS_TABLE',
    CHANGE_WAREHOUSES_LOCAL_TABLE: 'CHANGE_WAREHOUSES_LOCAL_TABLE',
    CHANGE_CARTS_TABLE: 'CHANGE_CARTS_TABLE',
    CHANGE_ROUTES_TABLE: 'CHANGE_ROUTES_TABLE',
    CHANGE_MEDICATIONS_TABLE: 'CHANGE_MEDICATIONS_TABLE',
    CHANGE_ORDERS_TABLE: 'CHANGE_ORDERS_TABLE',


    //searchs
    CHANGE_PRODUCTS_GENERAL_SEARCH: 'CHANGE_PRODUCTS_GENERAL_SEARCH',
    CHANGE_PRODUCTS_MOSTSOLD_SEARCH: 'CHANGE_PRODUCTS_MOSTSOLD_SEARCH',
    CHANGE_PRODUCTS_NEW_SEARCH: 'CHANGE_PRODUCTS_NEW_SEARCH',
    CHANGE_PRODUCTS_OUT_SEARCH: 'CHANGE_PRODUCTS_OUT_SEARCH',
    CHANGE_PRODUCTS_SALE_SEARCH: 'CHANGE_PRODUCTS_SALE_SEARCH',
    CHANGE_CAT_BRANDS_SEARCH: 'CHANGE_CAT_BRANDS_SEARCH',
    CHANGE_CAT_CATEGORIES_SEARCH: 'CHANGE_CAT_CATEGORIES_SEARCH',
    CHANGE_CAT_SUBCATEGORIES_SEARCH: 'CHANGE_CAT_SUBCATEGORIES_SEARCH',
    CHANGE_CAT_SEGMENTS_SEARCH: 'CHANGE_CAT_SEGMENTS_SEARCH',
    CHANGE_CAT_SUBSEGMENTS_SEARCH: 'CHANGE_CAT_SUBSEGMENTS_SEARCH',
    CHANGE_USERS_SEARCH: 'CHANGE_USERS_SEARCH',
    CHANGE_DOCTORS_SEARCH: 'CHANGE_DOCTORS_SEARCH',
    CHANGE_PATIENTS_SEARCH: 'CHANGE_PATIENTS_SEARCH',
    CHANGE_WAREHOUSES_LOCAL_SEARCH: 'CHANGE_WAREHOUSES_LOCAL_SEARCH',
    CHANGE_CARTS_SEARCH: 'CHANGE_CARTS_SEARCH',
    CHANGE_ROUTES_SEARCH: 'CHANGE_ROUTES_SEARCH',
    CHANGE_MEDICATIONS_SEARCH: 'CHANGE_MEDICATIONS_SEARCH',
    CHANGE_ORDERS_SEARCH: 'CHANGE_ORDERS_SEARCH',

    //filters
    CHANGE_ROUTES_FILTERS: 'CHANGE_ROUTES_FILTERS',
    CHANGE_ORDERS_FILTERS: 'CHANGE_ORDERS_FILTERS',

    //dates
    CHANGE_ORDERS_STARTDATE: 'CHANGE_ORDERS_STARTDATE',
    CHANGE_ORDERS_ENDDATE: 'CHANGE_ORDERS_ENDDATE',
    CHANGE_PRODUCTS_GENERAL_FILTER: 'CHANGE_PRODUCTS_GENERAL_FILTER',    
    SYS_UPDATE_DRAWER_STATUS: 'SYS_UPDATE_DRAWER_STATUS',
    SYS_UPDATE_IP_ADDRESS: 'SYS_UPDATE_IP_ADDRESS',
    SYS_UPDATE_DRAWER: 'SYS_UPDATE_DRAWER',
    SYS_UPDATE_BLUR: 'SYS_UPDATE_BLUR',
    SYS_UPDATE_TOKEN:'SYS_UPDATE_TOKEN',
    SYS_UPDATE_SESSION_STATUS:'SYS_UPDATE_SESSION_STATUS',
    SYS_UPDATE_ERROR:'SYS_UPDATE_ERROR',
    SYS_UPDATE_SENDING:'SYS_UPDATE_SENDING',
    SYS_UPDATE_SUCCESS:'SYS_UPDATE_SUCCESS',
}
