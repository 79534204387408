import { useEffect, useState } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../../shared/utility"
import { private_server } from "../../../../../../variables/config"


const useAddSubstanceModal = ({open, onClose, onUpdateCompleted, id, content}) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [options, setOptions] = useState([])

    useEffect(() => {
        if(!open){
            setError(''); 
            setLoading(false);         
        }
        if(open){
            let _form = onInitEmptyForm(formData, content.form)
            setForm(_form)
        }
    }, [open])

    
    const actions = {
        onChangeForm: (data) => {
            //console.log(data)
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onSearchSubstance: async(e) => {
            //console.log(e.target.value)
            if(!e || e.target.value === "" || e.target.value === 0) return
            try {
                let filter = {"AND":[
                    {"field":"name","operator":"LIKE","value":`%${e.target.value}%`},
                ]}
                filter = encodeURIComponent(JSON.stringify(filter))
              updateAuthorizationHeader(private_server)
              const req = await private_server.get(`/substance/list?order_by=id_substance&limit=30&filter=${filter}`)
              console.log(req.data.data.substances)
              const cat = onGetCatalogs(req.data.data.substances, 'id_substance', 'name')
              //console.log(cat)
              setOptions(cat)
          } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
          }
        },
        onSubmit: async () => {
           const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                console.log('hay errores')
                return
            } 
    
            //Extract data
            let data2send = onGetFormData(form)
            data2send.medication_id = id
            console.log(data2send)
            
            setLoading(true)
            try {
                //await request_create_active(id, data2send);
                updateAuthorizationHeader(private_server)
                const req = await private_server.post(`/medicationsubstance`, data2send)
                setLoading(false)
                onUpdateCompleted()
                onClose()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        }
    }

    return {loading, error, form, actions, options} 
}


export default useAddSubstanceModal

const formData = {
    substance_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'substance_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 255
        }
    },  
}