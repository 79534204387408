import { Grid, Chip, Typography } from '@mui/material'
import AutocompleteFormAdd from '../../../../../../components/Forms/AutocompleteFormAdd'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
//import AddTagModal from '../modals/AddTagModal'
import AddTagModal from '../../../../../Catalogs/views/TagsView/modals/AddTagModal'


const Tags = ({modals, actions, content, product_tags, form, options }) => {

    /* const [tag_modal, setTagModal] = useState(false)

    const onDeleteTag = async(id_tag) => {
        console.log(id_tag)
        //await request_delete_product_tag(id_tag)
        updateAuthorizationHeader(private_server)
        await private_server.delete(`/producttag/${id_tag}`)
        onUpdateTagCompleted()
    }

    const onAddProductTag = async (el) => {
        try {
            //await request_create_product_tag({product_id:id, tag_id:el, main:0});
            updateAuthorizationHeader(private_server)
            await private_server.post(`/producttag`, {product_id:id, tag_id:el, main:0})
            onUpdateTagCompleted()
        } catch (error) {
            console.log(error)
        }
    } */

    let tag_form = {...form}
    tag_form.options = options

    const onUpdateCompleted = (id) =>{
        actions.onAddProductTag(id)
        actions.onUpdateTagCompleted()
        actions.onChangeModalStatus('add_tag', false)
    }

    return(
        <div>
            {/* <AddTagModal open={modals.add_tag} onClose={()=>actions.onChangeModalStatus('add_tag', false)} onUpdateCompleted={actions.onUpdateTagCompleted} 
            onAddProductTag={actions.onAddProductTag} content={content.modal}/> */}
            <AddTagModal open={modals.add_tag} onClose={()=>actions.onChangeModalStatus('add_tag', false)} onUpdateCompleted={onUpdateCompleted} />
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <Typography variant='subtitle1'>{content.title}</Typography>                
                </Grid>
                <Grid item xs={12}>
                    {/* <SearchTags onAddTag={()=>setTagModal(true)} existingTags={product_tags} 
                    onAddProductTag={onAddProductTag} content={content.search_bar}/> */}
                    <AutocompleteFormAdd data={tag_form} onChange={actions.onChangeSearchForm} add_register={content.search_bar.add_register}
                        onAdd={()=>actions.onChangeModalStatus('add_tag', true)} onInputChange={actions.onSearchTags} onAdd2={actions.onAddProductTag} />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_tags.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.tag} onDelete={()=>actions.onDeleteTag(item.id_product_tag)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </div>
    )
}

export default Tags