import { useState } from 'react';
import Page from '../../../../components/Structure/Page/Page';
import SimpleTable from './components/SimpleTable';
import AddModal from './modals/AddModal';
import DeleteModal from './modals/DeleteModal';
import useMedications from './useMedications';



const Medications = ({content, history, medications_search, medications_table, onUpdateSearch, onUpdateTable}) => {

    const {system, modals, actions, view_data} = useMedications({content, history, medications_search, medications_table})

  
    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} history={history} content={content.modal}/>
            <DeleteModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} id={view_data.selected} 
                onUpdateCompleted={actions.onInitModule}/>
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={medications_table}
                search={medications_search}
                onUpdateTableFilter={onUpdateTable}
                onChangeSearch={onUpdateSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
            />
        </div>
    )
}



export default Medications