import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { onGetErrorMessage } from '../../../../../shared/utility'
import { actionTypes } from '../../../../../store/actions'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddBrandModal from './modals/AddBrandModal'
import EditBrandModal from './modals/EditBrandModal'
import { request_brands } from './requests'

const BrandsView = (props) => {

    const { onUpdateError, history, cat_brands_search, cat_brands_table, onUpdateBrandsSearch, onUpdateBrandsTable } = props

    //general
    const [loading, setLoading] = useState(false)
    const [brands, setBrands] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [id_selected, setIdSelected] = useState(null)

    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            window.scrollTo({top: 0, behavior: 'smooth'})
            setLoading(true)
            const params = onGetParamsFromState(cat_brands_table, cat_brands_search)
            console.log(params)
            const _brands = await request_brands(params)
            setBrands(_brands.brands_data)
            setTotal(_brands.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
        }}
        initModule()
    }, [ cat_brands_table])

    
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(cat_brands_table, cat_brands_search)
        const _brands = await request_brands(params)
        setBrands(_brands.brands_data)
        setTotal(_brands.count)
        setAddModal(false)
        setEditModal(false)
    }

    const onSelectedItem = (item) => {
        setIdSelected(item.id_brand)
        const {id_brand} = item
        history.push(`/catalogs/brand/${id_brand}`)
    }


    return(
        <div>
            <AddBrandModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <EditBrandModal open={edit_modal} onClose={() => setEditModal(false)} id={id_selected} onUpdateCompleted={onUpdateCompleted} />
            <SimpleTable
                data={brands} 
                tableFilter={cat_brands_table}
                search={cat_brands_search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onUpdateBrandsSearch}
                onUpdateTableFilter={(data) => onUpdateBrandsTable(data)}
                onSelectedItem={onSelectedItem}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(BrandsView)
