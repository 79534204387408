import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import { nodata } from '../../../variables/config'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32,
        whiteSpace:'nowrap'
    },
}))


const OrderStatus = ({status_id}) => {

    const classes = useStyles()
    const status = catalogs.order_statuses.find(el=>el.value === status_id)

    return(
        <div className={classes.root} style={{background: status ? status.color : 'transparent'}}>
            {status ? status.label : nodata}
        </div>
    )
}

export default OrderStatus