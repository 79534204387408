import { Grid } from '@mui/material'
import React from 'react'
import ContainedButton from '../../../components/Actions/ContainedButton'
import InputForm from '../../../components/Forms/InputForm'
import SelectForm from '../../../components/Forms/SelectForm'
import SimpleModal from '../../../components/Overlays/SimpleModal'
import DisplayText from '../../../components/Texts/DisplayText'


const BannerModal = ({open, onClose, form, content, onSubmit, loading, error, onChange, disabled}) => {

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.link} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_1} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_2} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_3} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.text_4} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}

export default BannerModal
