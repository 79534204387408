import { makeStyles } from '@mui/styles';
import React from 'react';

const BatchStatus = ({
    label,
    textColor,
    color
}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root} style={{background:color, color:textColor}}>
            {label}
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        borderRadius:32,
        padding:'4px 8px',
        fontWeight:600
    }
}))
 
export default BatchStatus;