import { Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { private_server } from '../../../../../../variables/config'
import { modalStyles } from '../../../../../../styles/modalStyles'


const AddModal = ({open, onClose, onUpdateCompleted, content, catalogs}) => {

    const classes = modalStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
  
    const [zip_number, setZipNumber] = useState(null)
    const [id, setId] = useState(null)


    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            updateAuthorizationHeader(private_server)
            const req_warehouses = await private_server.get(`warehouse/list`) 
            const cat_warehouses = onGetCatalogs(req_warehouses.data.data.warehouses_data, 'id_warehouse', 'name')

            let _form = onInitEmptyForm(form, content.form)
            _form.shipping_type_id.options = catalogs.shipping_types
            _form.warehouse_id.options = cat_warehouses
            setForm(_form)
        }
    }
    onInitModal()
    }, [open])

    useEffect(() => {
        const onSearchZipAttributes = async(value) => {
            if(value === "" || value === null || value === undefined || value.length < 5) return
            try {
                //let zip_info = await request_zipinfo(value)
                updateAuthorizationHeader(private_server)
                let zip_info = await private_server.get(`/address/zipinfo?zip=${value}`)
                //console.log(zip_info)
                zip_info = zip_info.data.data.zip_information
                if(zip_info){
                    const {id_zip_code, value, municipality, municipality_id, state, state_id} = zip_info.zip_code
                    let _form = {...form}
                    _form.state_id.value = state_id
                    _form.state_id.isValid = true
                    _form.state_id.options = [{value:state_id, label:state}]
                    _form.municipality_id.value = municipality_id
                    _form.municipality_id.isValid = true
                    _form.municipality_id.options = [{value:municipality_id, label:municipality}]
                    _form.zip_code.value = value
                    _form.zip_code.isValid = true
                    _form.zip_code_id.value = id_zip_code
                    _form.zip_code_id.isValid = true  
                    setForm(_form)
                }
            } catch (error) {
                console.log(error)
            }
        } 
        onSearchZipAttributes(zip_number)
    }, [zip_number])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'zip_code') setZipNumber(data.value)
        if(id === 'warehouse_id') setId(temp[id].value)
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.warehouse_id
        console.log(data2send)
        console.log(id)
        
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`warehouse/${id}/coverage`, data2send)
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
       
    }

    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose}>
            <DisplayText variant='h6' >{content.add.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <InputForm data={form.zip_code} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.state_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.municipality_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.warehouse_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.shipping_type_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button}>
                    <ContainedButton color='primary' onClick={onSubmit} loading={loading} disabled={loading}>
                        {content.add.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddModal

const formData = {
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'zip',
        }
    },
    zip_code_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code_id',
            type: 'text',
            fullWidth: true,
        },
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    warehouse_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'warehouse_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    shipping_type_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'shipping_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    
}
