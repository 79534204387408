import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName';
import { DASHBOARD_SETTINGS, nodata } from '../../../../variables/config';
import user from '../../../../assets/icons/user.svg'
import { grey } from '@mui/material/colors';


const MembersRouteCard = ({route, actions}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <div className={classes.list_item}>
                <Typography variant='subtitle2'>Sucursal</Typography>
                <UserAvatarName noimage={user} name={route ? route.warehouse : nodata} labelStyles={{color:grey[600], fontWeight:500}}/>
            </div>

            <div className={classes.list_item}>
                <Typography variant='subtitle2'>Creador</Typography>
                <UserAvatarName noimage={user} name={route ? route.assigner : nodata} labelStyles={{color:grey[600], fontWeight:500}}/>
            </div>

            <div className={classes.list_item}>
                <Typography variant='subtitle2'>Repartidor</Typography>
                <UserAvatarName noimage={user} name={route ? route.delivery_man : nodata} labelStyles={{color:grey[600], fontWeight:500}}/>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        borderRadius:12,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:'32px 24px',
    },
    list_item:{
        marginBottom:24
    }
}))
 
export default MembersRouteCard;