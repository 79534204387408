import React from 'react'
import {
    LinearProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Icon,
    TableContainer,
    Card,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import empty from '../../../../../../assets/empty.png'
import moment from 'moment'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import catalogs from '../../../../../../texts/esp/catalogs'
import PillStatus from '../../../../../../components/Structure/DisplayData/PillStatus'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import { onGetDateFormat } from '../../../../../../shared/utility'



const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        //borderColor:grey[200],
        borderColor:'#EAEAEA'
        //padding:'20px 0px'
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        //borderColor:grey[300],
        borderBottom:'2px solid #EAEAEA',
        paddingTop:16,
        paddingBottom:16,
        background:'transparent',
        color:'#6A6A6A'
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:32
    },
    actions:{
        marginTop:40
    },
    table:{
        minWidth:700
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    delete_button:{
        color:'#D95D71',
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    },
    add_button:{
        color:theme.palette.primary.main,
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onAddRouteorder, onSelectItem} = props

    const header = [
        {id:1, label:'ID'},
        {id:2, label:'Orden'},
        {id:3, label:'Entrega máxima'},
        {id:4, label:'Entrega estimada'},
        {id:5, label:'Inicio'},
        {id:6, label:'Llegada'},
        {id:7, label:'Hora problema'},
        {id:8, label:'Status'},
    ]
   
    return (
        <SimpleCard>
            {/* <div className={classes.toolbar}>
                <Grid container justify='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} /></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div> */}
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <div>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return (
                                                    <TableCell padding="normal"
                                                    //padding={item.id === 1 ? 'checkbox' : 'default'} 
                                                    className={classes.cellHeader} key={item.id.toString()}>
                                                        <DisplayText  >{item.label}</DisplayText>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_route_order, number, started_at, schedule_time, arrived_at, delivered_at, estimated_delivery_at, user, payment_status_id, issue_at, route_order_status_id, order_id } = item

                                        return(
                                            <TableRow key={key.toString()} className={classes.row} onClick={onSelectItem ? ()=>onSelectItem(id_route_order) : null}  >
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{id_route_order}</Typography></TableCell>
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{order_id}</Typography></TableCell>
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(schedule_time, 'DD MMM, h:mm a')}</Typography></TableCell> 
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(estimated_delivery_at,'DD MMM, h:mm a')}</Typography></TableCell> 
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(started_at, 'DD MMM, h:mm a')}</Typography></TableCell>  
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(arrived_at, 'DD MMM, h:mm a')}</Typography></TableCell> 
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(issue_at, 'DD MMM, h:mm a')}</Typography></TableCell>
                                                <TableCell className={classes.cell} ><PillStatus value={route_order_status_id} catalogs={catalogs.route_order_statuses}/></TableCell>                         
                                                            
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </div>
            {/* {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null} */}
            {data.length === 0 && !loading? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </SimpleCard>
    );
}

export default SimpleTable