import { Grid } from "@mui/material"
import ContainedButton from "../../../../../../components/Actions/ContainedButton"
import InputForm from "../../../../../../components/Forms/InputForm"
import SelectForm from "../../../../../../components/Forms/SelectForm"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import DisplayText from "../../../../../../components/Texts/DisplayText"


const GeneralCard = ({form, content, onChange, onSubmit, sending}) => {

    return (
        <SimpleCard>
            <Grid container spacing={4} justifyContent='center'>
                <Grid item xs={12}>
                    <DisplayText >{content.general.title}</DisplayText>
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.name} onChange={onChange} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.contact_name} onChange={onChange} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.contact_phone} onChange={onChange} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.contact_email} onChange={onChange} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.additional_indications} onChange={onChange} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form.map_url} onChange={onChange} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} disabled={sending} onClick={onSubmit}>{content.button}</ContainedButton>
                </Grid>  
            </Grid>
        </SimpleCard>
    );
}

export default GeneralCard