import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"
import queryString from 'query-string'



const useInventory = ({language, content, id}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'id_inventory'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        edit_availability:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = []
    
        if(search)filtersArgs.push({"field":"product","operator":"LIKE","value":`%${search}%`})
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`inventory/warehouse/${id}${params}`)   
                //console.log(req)
                setData(req.data.data.inventories_data)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeAvailabity: async(item, type) =>{
            const { id_inventory } = item

            let count = type === 'add' ? 1 : -1

            try{
                updateAuthorizationHeader(private_server)
                await private_server.post(`/inventory/${id_inventory}/increment`, {increment:count} )
                actions.onInitModule()
            }catch(error){
                console.log(error)
            }
        },
        onChangeSearch: (value) => setSearch(value),
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {appointmentdiscountcode} = item
            setSelected(appointmentdiscountcode)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useInventory