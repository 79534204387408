import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';

const SVGIconOutlined = ({ src, size, color, width, height, className }) => {
	const classes = useStyles();

	const _size = 32;
	const icon_size = size ? size : _size;

	return (
		<div style={{ width: width ? width : icon_size, height: height ? height : icon_size }}>
			<div className={classes.root}>
				<ReactSVG
					src={src}
					beforeInjection={(svg) => {
						svg.classList.add(className)
					}}
				/>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
	},
}));

export default SVGIconOutlined;
