import { Button, Grid } from '@mui/material'
import React from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullname } from '../../../../shared/utility'
import nouser from '../../../../assets/nouser.png'
import UserStatus from '../../../../components/Structure/DisplayData/UserStatus'
import DisplayColorIdData from '../../../../components/Structure/DisplayData/DisplayColorIdData'
import { catalogs } from '../../../../texts/esp/catalogs'

const UserInfoCard = (props) => {

    const {content} = props
    const {image, first_name, fathers_last_name, mothers_last_name, email, mobile, fixed_phone, user_status_id, onChangePicture, onChangePassword} = props

    const last_name = fathers_last_name + ' ' + mothers_last_name
    const fullname = onGetFullname(first_name, last_name)

    let status = catalogs.user_statuses.find(el=>el.value === user_status_id)
   
    return (
        <div>
            <SimpleCard>
                <Grid container justifyContent='center' spacing={2}>
                    <Grid item>
                        <AvatarPicture can_edit nopicture={nouser} onChangePicture={onChangePicture} imgSrc={image}/>
                    </Grid>                
                    <Grid item xs={12}>            
                        <DisplayText variant='subtitle1' align='center'>{fullname}</DisplayText> 
                    </Grid> 
                    <Grid item>
                        <DisplayColorIdData statusColor={user_status_id ? status.color : ''} color='black' data={user_status_id ? status.label : ''}/>  
                    </Grid>     
                    <Grid item xs={12}>
                        <div style={{wordWrap:'break-word', wordBreak:'break-all'}}>
                            <DisplayIconData icon='mail' data={email}/>  
                        </div> 
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayIconData icon='smartphone' data={mobile}/>
                    </Grid>
                    <Grid item>
                        <Button onClick={onChangePassword} variant='text'>{content.change_password}</Button>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    );
}

export default UserInfoCard