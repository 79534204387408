import React from 'react'
import {
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../../../components/Actions/SearchBar'
import ActionBar from '../../../../../../components/Actions/ActionBar'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import ProductImage from '../../../../../../components/Structure/Images/ProductImage'
import { translations as translations_esp } from '../../../../../../texts/esp/segment-translations'
import TableCellLink from '../../../../../../components/Structure/Navigation/TableCellLink'
import { tableStyles } from '../../../../../../styles/tableStyles'

const content = translations_esp.tables.products

const header = content.header

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        //minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = tableStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDeleteItem} = props

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_product, name, image_mobile} = item

                                        return (
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCellLink className={classes.cell} href={`/products/${id_product}`}><ProductImage src={image_mobile} type='TABLE'/></TableCellLink>
                                                <TableCellLink className={classes.cell} href={`/products/${id_product}`}><DisplayText>{id_product}</DisplayText></TableCellLink>
                                                <TableCellLink className={classes.cell} href={`/products/${id_product}`}><DisplayText>{name}</DisplayText></TableCellLink>
                                                <TableCell className={classes.cell} >
                                                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                                                    <IconButton onClick={() => onDeleteItem(item)} size="large"><Icon>delete</Icon></IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}       
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>           
            ) : null}       
        </div>
    );
}

export default SimpleTable