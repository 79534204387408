import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import SelectAutocompleteForm from '../../../../../../components/Forms/SelectAutocompleteForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import { private_server } from '../../../../../../variables/config'
import queryString from 'query-string'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'


const AddModal = props => {

    const { open, onClose, onUpdateCompleted, content, id } = props

    const classes = useStyles()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [options, setOptions] = useState([])
 
    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)


    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchProduct = async(e) =>{
        if(!e || e.target.value === "" || e.target.value === 0) return
        if(error) setError(null)
        try {
            /* const params = {limit:30, }
            const filter = {filter:
                {"OR":[
                    {"field":"name","operator":"LIKE","value":`%${e.target.value}%`},
                    {"field":"code","operator":"LIKE","value":`%${e.target.value}%`},
                ]}           
            } */
            const params = {limit:30}
            let filter = {"AND":[{"field":"name","operator":"LIKE","value":`%${e.target.value}%`}]}
            filter = encodeURIComponent(JSON.stringify(filter))
            const final_params = `?${queryString.stringify(params)}&filter=${filter}`

            updateAuthorizationHeader(private_server)
            const req = await private_server.get(`/product/list${final_params}`,)
            const cat = onGetCatalogs(req.data.data.products, 'id_product', 'name', 'sub_name', 'presentation')
            setOptions(cat)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.product_id = id

        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`alsoseenproduct`, data2send)
            //console.log(medication)
            //const id = medication.data.data.medication_id
            //history.push(`/medications/${id}`)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    let prod_form = {...form.also_seen_product_id}
    prod_form.options = [...options]

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <Typography variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add.title}</Typography>
                {/* <DisplayText >{content.subtitle}</DisplayText> */}
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectAutocompleteForm data={prod_form} onChange={onChange} onInputChange={onSearchProduct} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.add.button}
                </ContainedButton>
                <Typography color='error' align='center'>{error}</Typography>
            </div>
        </SimpleModal>
    )
}

export default AddModal

const useStyles = makeStyles(theme=>({
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    
}))

const formData = {
    also_seen_product_id: {
        value:null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'also_seen_product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {type: 'none'}
    },
  
}

