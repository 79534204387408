import { Card } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:24,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:32,
        
    },
    subroot:{
        background:'#F6F7FB',
        borderRadius:10,
        padding:'48px 16px',
        boxShadow:'none'
    }
}))

const SimpleCard = props => {

    const classes = useStyles()

    return(
        <Card className={cx({
            [classes.root]:true,
            [classes.subroot]:props.variant === 'secondary'
        })} style={props.style}>
            {props.children}
        </Card>
    )
}

export default SimpleCard