import React, { useState } from 'react'
import {
    LinearProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Icon,
    TableContainer,
    Card,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import SearchBar from './SearchBar/SearchBar'
import ActionBar from '../../../../../../components/Actions/ActionBar'
import empty from '../../../../../../assets/icons/route.svg'
import moment from 'moment'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import catalogs from '../../../../../../texts/esp/catalogs'
import { onGetDateFormat } from '../../../../../../shared/utility'
import { tableStyles } from '../../../../../../styles/tableStyles'
import NewActionBar from '../../../../../../components/Actions/NewActionBar'
import { PAYMENT_METHODS, PAYMENT_STATUSES, ROUTE_STATUSES } from '../../../../../../variables/config'
import MonthFilter from '../../../../../../components/Actions/MonthFilter'



const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        //borderColor:grey[200],
        borderColor:'#EAEAEA'
        //padding:'20px 0px'
    },
    row:{
        '&:hover':{
            //cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        //borderColor:grey[300],
        borderBottom:'2px solid #EAEAEA',
        paddingTop:16,
        paddingBottom:16,
        background:'white',
        color:'#6A6A6A'
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:32
    },
    actions:{
        marginTop:40
    },
    table:{
        minWidth:700
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    delete_button:{
        color:'#D95D71',
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    },
    add_button:{
        color:theme.palette.primary.main,
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:40,
        borderRadius:'50%',
        background:'white',
        //boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        border:'3px solid #E8EBF3',
        width:140,
        height:140,
        padding:32,
        boxSizing:'border-box'
        
    },
    table_container:{
        maxHeight:280
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onAddRouteorder, 
        startDate, endDate, onChangeDate} = props

    const header = [
        {id:1, label:'ID'},
        {id:2, label:'Hora de entrega'},
        {id:3, label:'Usuario'},
        {id:4, label:'No. Ruta'},
        {id:5, label:'Tipo de pago'},
        {id:6, label:'Estatus de pago'},
        {id:7, label:''},
    ]

   
    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='center' wrap='nowrap'>         
                    <Grid item><MonthFilter value={startDate} onChange={(value)=>onChangeDate(value,'start_date')} /></Grid>
                    <Grid item>-</Grid>
                    <Grid item><MonthFilter value={endDate} onChange={(value)=>onChangeDate(value,'end_date')} /></Grid>
                    <Grid item xs></Grid>
                    <Grid item><SearchBar search={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} /></Grid>
                    {/* <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid> */}
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <div>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return (
                                                    <TableCell padding="normal"
                                                    //padding={item.id === 1 ? 'checkbox' : 'default'} 
                                                    className={classes.cellHeader} key={item.id.toString()}>
                                                        <DisplayText  >{item.label}</DisplayText>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_order, schedule_time, user, payment_status_id, route_order_id, route_id, store_payment_type_id, route_order_status_id } = item
                                        
                                        const payment_status = catalogs.payment_statuses.find(el => el.value === payment_status_id)
                                        const payment_type = catalogs.store_payment_types.find(el => el.value === store_payment_type_id)
                                        //console.log(payment_status)
                                        let disabled = false
                                        let disabled2 = false

                                        /* if(store_payment_type_id === PAYMENT_METHODS.CASH || store_payment_type_id === PAYMENT_METHODS.TPV){
                                            disabled = false
                                        }else{
                                            if(payment_status_id !== PAYMENT_STATUSES.PAID) disabled = true
                                        }

                                        if(route_order_id !== null){
                                            disabled2 = true
                                            if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled2 = false
                                        }else{
                                            disabled2 = false
                                        } */

                                        /* if(store_payment_type_id === PAYMENT_METHODS.MERCADO_PAGO || store_payment_type_id === PAYMENT_METHODS.CARD || store_payment_type_id === PAYMENT_METHODS.PAYPAL){
                                            disabled = true
                                        }else{
                                            disabled = false
                                            if(route_order_id !== null){
                                                disabled=true
                                                if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled = false
                                            }
                                        } */

                                        if(route_order_id !== null){
                                            disabled=true
                                            if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled = false
                                        }

                                        return (
                                            <TableRow key={key.toString()} className={classes.row}  >
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{id_order}</Typography></TableCell>
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(schedule_time, 'DD MMM, h:mm a')}</Typography></TableCell>   
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{user}</Typography></TableCell>     
                                                <TableCell className={classes.cell} ><Typography variant='subtitle2'>{route_id}</Typography></TableCell> 
                                                <TableCell className={classes.cell} >
                                                    <Typography variant='subtitle2' >{payment_type ? payment_type.label : '-'}</Typography>
                                                </TableCell>    
                                                <TableCell className={classes.cell} >
                                                    {store_payment_type_id ? <Typography variant='subtitle2' style={{color:payment_status.color}}>{payment_status.label}</Typography> : <Typography>-</Typography>}
                                                </TableCell>                                        
                                                <TableCell className={classes.cell} ><IconButton
                                                    disabled={disabled}
                                                    className={classes.add_button}
                                                    onClick={()=>onAddRouteorder(id_order)}
                                                    size="large"><Icon>add</Icon></IconButton></TableCell>                             
                                            </TableRow>
                                        );
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </div>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable