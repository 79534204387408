import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { onGetErrorMessage, onInitEmptyForm, onGetCatalogs, isFormValid, onSetErrorsToForm, onGetFormData } from '../../../../shared/utility';
import { request_add_userrole, request_roles, request_userroles } from '../requests';

const useAddUserRole = ({ content, id, open, onClose, onUpdateCompleted }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(form_data);
	const [main, setMain] = useState(false);

	useEffect(() => {
		if (open) actions.onInitModule();
	}, [open]);

	const unusedRoles = (roles, userroles) => {
		let unused_modules = [];
		roles.map((role) => {
			let found = false;
			userroles.map((userrole) => {
				if (role.id_role === userrole.role_id) {
					found = true;
					return;
				}
			});
			if (!found) unused_modules.push({ id_role: role.id_role, name: role.name });
		});
		return unused_modules;
	};

	const actions = {
		onInitModule: async () => {
			setLoading(true);
			try {
				let _form = onInitEmptyForm(form, content.form);
				const roles = await request_roles();
				const userroles = await request_userroles(id);
				const unused = unusedRoles(roles.roles, userroles.user_roles);
				const catalog = onGetCatalogs(unused, 'id_role', 'name');
				_form.role_id.options = catalog;
				setMain(false);
				setLoading(false);
				setForm(_form);
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onChangeForm: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onChangeMain: () => {
			setMain(!main);
		},
		onSubmit: async () => {
			console.log('test');
			const errors = isFormValid(form);

			if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				return;
			}

			const data2send = onGetFormData(form);

			data2send.user_id = parseInt(id);

			if (main) data2send.main_role = 1;
			else data2send.main_role = 0;

			console.log(data2send);

			setSending(true);
			setLoading(true);

			try {
				await request_add_userrole(data2send);
				setLoading(false);
				onUpdateCompleted();
				onClose();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}

			setSending(false);
		},
	};

	const system = { loading, sending, error };

	return { system, form, actions, main };
};

export default useAddUserRole;

const form_data = {
	role_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		config: {
			id: 'role_id',
			fullWidth: true,
		},
		rules: {
			type: 'select',
		},
	},
};
