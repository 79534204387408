import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ColorPicker } from 'material-ui-color';
import withStyles from '@mui/styles/withStyles';
import ContainedButton from '../../../../components/Actions/ContainedButton';
import DisplayText from '../../../../components/Texts/DisplayText';
import Switch from '@mui/material/Switch';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import AvatarPicture from '../../../../components/Structure/DisplayData/AvatarPicture';
import nouser from '../../../../assets/nouser.png';
import useAddRole from './useAddRole';
import InputFormLabeled from '../../../../components/Forms/InputFormLabeled';
import ColorPickerForm from '../../../../components/Forms/ColorPickerForm';
import ChangeImageModal from './ChangeImageModal';
import { config_data } from '../../../../variables/config';
import useEditRole from './useEditRole';

const useStyles = makeStyles((theme) => ({
	input_label: {
		fontSize: 12,
		fontWeight: 500,
		marginBottom: 5,
	},
	picture_container: {
		width: 138,
		height: 138,
		borderRadius: 5,
		border: '1px solid #ADADAD',
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 55,
		height: 30,
		padding: 0,
		paddingBottom: 2,
		display: 'flex',
	},
	switchBase: {
		padding: 4,
		color: 'lightgray',
		'&$checked': {
			transform: 'translateX(26px)',
			color: 'white',
			'& + $track': {
				opacity: 1,
				backgroundColor: '#96AE76',
				borderColor: '#96AE76',
			},
		},
	},
	thumb: {
		width: 20,
		height: 20,
		boxShadow: 'none',
	},
	track: {
		border: `1px solid lightgray`,
		borderRadius: 32 / 2,
		backgroundColor: 'white',
	},
	checked: {},
}))(Switch);

const EditRoleModal = ({ open, onClose, onUpdateCompleted, onChangePicture, openImg, onCloseImg , content, id_selected}) => {
	let { system, form, actions, img } = useEditRole({ content, onClose, onUpdateCompleted, onCloseImg, id_selected, open });

	const classes = useStyles();

	return (
		<>
			<ChangeImageModal
				open={openImg}
				onClose={onCloseImg}
				onUpdateCompleted={onUpdateCompleted}
				system={system}
				onSelectImg={actions.onGetImgFile}
			/>
			<SimpleModal open={open} onClose={onClose}>
				<DisplayText variant='h6'>{content.edit_title}</DisplayText>

				<div style={{ padding: '30px 0px 24px' }}>
					<Grid container>
						<Grid item container xs={12}>
							<Grid item xs={5} container alignItems='center' justifyContent='center'>
								<div className={classes.picture_container}>
									<AvatarPicture can_edit nopicture={nouser} onChangePicture={onChangePicture} imgSrc={img} directLink />
								</div>
							</Grid>
							<Grid item container xs={7} style={{ paddingLeft: 25 }}>
								<Grid item xs={12} style={{ marginBottom: 15 }}>
									<InputFormLabeled data={form.name} onChange={actions.onChangeForm} />
								</Grid>
								<Grid item xs={6} container>
									<ColorPickerForm data={form.color} onChange={actions.onChangeForm} />
								</Grid>
								<Grid item xs={6} container>
									<div style={{ width: 60, marginLeft: 'auto' }}>
										<Typography className={classes.input_label}>Default</Typography>
										<AntSwitch name='checkedC' />
									</div>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12} style={{ paddingTop: 25 }}>
							<InputFormLabeled data={form.description} onChange={actions.onChangeForm} />
						</Grid>
					</Grid>
				</div>
				<div style={{ width: 162, margin: '0px auto' }}>
					<ContainedButton color='primary' onClick={actions.onSubmit} loading={system.loading}>
						{content.button}
					</ContainedButton>
				</div>
				<DisplayText color='error' align='center'>
					{system.error}
				</DisplayText>
			</SimpleModal>
		</>
	);
};

export default EditRoleModal;
