import { TextField, InputBase, InputLabel, Typography, FormHelperText, MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ruleValidation } from './customFunctions';
import { grey } from '@mui/material/colors';

const CssTextField = withStyles((theme) => ({
	root: {
		'& input': {
			color: theme.palette.text.primary,
			background: 'white',
			fontWeight: 500,
			padding: 15,
			border: '1px solid #ADADAD',
			fontSize: 16,
            borderRadius: 5
		},
		'& textarea': {
			color: theme.palette.text.primary,
            background: 'white',
			padding: 15,
			fontWeight: 500,
			border: '1px solid #939393',
            borderRadius: 5,
		},
		'&.Mui-error': {
			border: '1px solid red',
		},
	},
}))(InputBase);

const useStyles = makeStyles((theme) => ({
	multiline_classes: {
		color: theme.palette.text.primary,
        padding: 0
	},
	inputlabel: {
		marginBottom: 5,
		fontSize: 12,
		fontWeight: 500,
	},

}));

const InputFormLabeled = (props) => {
	const { data, onChange } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	if (!isError) helperText = '';

	return (
		<div>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{interConfig.label}
			</InputLabel>
			<CssTextField value={value} variant='standard' color='secondary' error={isError} {...interConfig} onChange={onInnerChange} />
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
	);
};

export default InputFormLabeled;
