import React, { useState } from 'react';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import { Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import RoundedButton from '../../../../components/Actions/RoundedButton';
import { mainServer } from '../../../../variables/config';
import { onGetErrorMessage, onUpdateAuthorizationHeader } from '../../../../shared/utility';

const WarningModal = ({open, onClose, onCompleted, id_user, id_route}) => {

    const classes = useStyles()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    
    const onSubmit = async () => {
        setLoading(true)
        try{
            onUpdateAuthorizationHeader()
            await mainServer.post(`/route/user/${id_user}/${id_route}/ready`)
            onCompleted()
        }catch(e){
            console.log(e)
            setError(onGetErrorMessage(e))
        }
        setLoading(false)
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} >
            <Typography variant='subtitle1'>¡Aviso!</Typography>
            <div className={classes.container}>
                <div style={{display:'flex', justifyContent:'center'}}>
                    <div className={classes.icon_container}>
                        <Icon className={classes.icon} color='primary' >priority_high</Icon>
                    </div>
                </div>
                <Typography variant='body2' style={{fontWeight:600}}>Una vez avances no se podrán hacer cambios.</Typography>
                <Typography variant='body2' className={classes.message}>Asegúrate de dejarlo en el lugar de colecta.</Typography>
                <RoundedButton onClick={onSubmit} loading={loading}>Aceptar</RoundedButton>
                <Typography style={{marginTop:20, color:'#d32f2f'}}>{error}</Typography>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        textAlign:'center'
    },
    icon_container:{
        borderRadius:'50%',
        width:80,
        height:80,
        background:'rgba(182, 141, 233, 0.12)',
        margin:'20px 0px',
        position:'relative',
    },
    icon:{
        fontSize:70,
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)'
    },
    message:{
        margin:'20px 0px 40px'
    }
}))

export default WarningModal;