import React from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ruleValidation } from './customFunctions'

const useStyles = makeStyles(theme => ({
    margin:{},
    paper:{
        maxHeight:300,
    },
    root:{
        borderRadius:12,
        '& .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root': {
            borderRadius:12,
        }
        //background:'red'
    }
}))

const SelectForm = props => {

    const {data, onChange} = props
    const classes = useStyles()

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited, options} = data
    const {label, helperText, disabled} = config

    return (
        <div>
            <FormControl className={classes.margin} color='primary' fullWidth disabled={disabled ? disabled : false}
            error={isVisited && !isValid}  >
                <InputLabel id="label" style={{background:'white'}}>{label}</InputLabel>
                <Select
                  
                    labelId="label"
                    id="select"
                    value={value}
                    onChange={onInnerChange}
                    className={classes.root}
                    MenuProps={{
                        classes:{paper:classes.paper},
                        variant:'menu'
                    }}>
                    {options.map((item,key)=>{
                        return(
                        <MenuItem value={item.value} key={key.toString()}>{item.label}</MenuItem>
                        )
                    })}
                </Select>
                {isVisited && !isValid ? <FormHelperText error >{helperText}</FormHelperText> : null}
            </FormControl>
        </div>
    );
}

export default SelectForm