import { Grid, Typography } from '@mui/material'
import React, {useState} from 'react'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import ToolbarRadio from '../../../../../../components/Structure/Navigation/ToolbarRadio'
import ToolbarTable from '../../../../../../components/Structure/Navigation/ToolbarTable'
import AddImage from '../components/AddImage'
import ImageBox2 from '../components/ImageBox2'
import ImageBox3 from '../components/ImageBox3'
import ChangeImageModal from '../modals/ChangeImageModal'
import DeleteImageModal from '../modals/DeleteImageModal'
import UploadImageModal from '../modals/UploadImageModal'



const ProductImages = ({id, images, selected_image, content, modals, actions}) =>{

 
    //images web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState('1')
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === '1') setType('web')
        if(id === '2') setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case '1':
            contentView = <Grid container spacing={3}>
                {images.map((item, index)=>{
                    return(
                        <Grid item key={index}>
                            <ImageBox3 id_image={item.id_product_image}
                            image={item.image_web} main={Boolean(item.main)} 
                            onChangeMain={()=>actions.onChangeMainImage(item.id_product_image)} 
                            onEdit={()=>actions.onEditImage(item.id_product_image)}
                            onDelete={()=>actions.onDeleteImage(item.id_product_image)} 
                            content={content}/>
                        </Grid>
                    )
                })}
                <Grid item>
                    <AddImage onClick={()=>actions.onChangeModalStatus('add_image', true)} content={content}/>
                </Grid>
            </Grid>
            break
        case '2':
            contentView = <Grid container spacing={3}>
                {images.map((item, index)=>{
                    return(
                        <Grid item key={index}>
                            <ImageBox3 id_image={item.id_product_image}
                            image={item.image_mobile} main={Boolean(item.main)} 
                            onChangeMain={()=>actions.onChangeMainImage(item.id_product_image)} 
                            onEdit={()=>actions.onEditImage(item.id_product_image)}
                            onDelete={()=>actions.onDeleteImage(item.id_product_image)} 
                            content={content}/>
                        </Grid>
                    )
                })}
                <Grid item>
                    <AddImage onClick={()=>actions.onChangeModalStatus('add_image', true)} content={content}/>
                </Grid>
            </Grid> 
            break
        default:
            break
    }

    

    return(
        <div>
        <DeleteImageModal open={modals.delete_image} onClose={()=>actions.onChangeModalStatus('delete_image', false)} id={id} 
            id_image={selected_image} onUpdateCompleted={actions.onUpdateImagesCompleted}/>
        <UploadImageModal open={modals.add_image} onClose={()=>actions.onChangeModalStatus('add_image', false)} id={id} 
            onUpdateCompleted={actions.onUpdateImagesCompleted} type={type}/>
        <ChangeImageModal open={modals.edit_image} onClose={()=>actions.onChangeModalStatus('edit_image', false)} id={id} 
            id_image={selected_image} type={type} onUpdateCompleted={actions.onUpdateImagesCompleted} />
            <Grid container spacing={4}>
                <Grid item xs={12} style={{marginBottom:20}}>
                    <Grid container alignItems='center'>
                        <Grid item ><Typography variant='subtitle1'>{content.title}</Typography></Grid>
                        <Grid item xs><div style={{width:'98%', height:0.5, background:'#DCDCDC', marginLeft:16 }}></div></Grid>
                    </Grid>
                </Grid>
                <Grid item >
                    {contentView}
                    <div style={{marginTop:24, display:'flex', justifyContent:'center'}}>
                        <ToolbarRadio selected={image_view} menuOptions={menu} onChange={onChangeView} />
                    </div>
                </Grid>      
            </Grid>
        </div>
    )

}

export default ProductImages 