import { updateObject } from '../shared/utility'
import {actionTypes} from './actions'
import moment from 'moment'

const initialState = {
    user:null,
    isauth:false,
    cartItems:0,
    recover_password_modal:false,
    sending:false,
    success:null,
    error:null,
    language:'es',
    products_view:1,
    catalogs_view:1,
    products_general_table:{limit:100, offset:0, order:0, order_by:'name'},
    products_mostsold_table:{limit:100, offset:0, order:0, order_by:'product'},
    products_new_table:{limit:100, offset:0, order:0, order_by:'product'},
    products_out_table:{limit:100, offset:0, order:0, order_by:'product'},
    products_sale_table:{limit:100, offset:0, order:0, order_by:'product'},
    cat_brands_table:{limit:100, offset:0, order:0, order_by:'name'},
    cat_categories_table:{limit:100, offset:0, order:0, order_by:'name'},
    cat_subcategories_table:{limit:100, offset:0, order:0, order_by:'name'},
    cat_segments_table:{limit:100, offset:0, order:0, order_by:'name'},
    cat_subsegments_table:{limit:100, offset:0, order:0, order_by:'name'},
    users_table:{limit:10, offset:0, order:0, order_by:'id_user'},
    doctors_table:{limit:10, offset:0, order:0, order_by:'id_user'},
    patients_table:{limit:10, offset:0, order:0, order_by:'id_user'},
    warehouses_local_table:{limit:100, offset:0, order:0, order_by:'zip_code_id'},
    carts_table:{limit:10, offset:0, order:0, order_by:'id_cart'},
    routes_table:{limit:50, offset:0},
    medications_table:{limit:100, offset:0, order:0, order_by:'name'},
    orders_table:{limit:10, offset:0, order:1, order_by:'ordered_at'},
    products_general_search:null,
    products_mostsold_search:null,
    products_new_search:null,
    products_out_search:null,
    products_sale_search:null,
    cat_brands_search:null,
    cat_categories_search:null,
    cat_subcategories_search:null,
    cat_segments_search:null,
    cat_subsegments_search:null,
    users_search:null,
    doctors_search:null,
    patients_search:null,
    warehouses_local_search:null,
    carts_search:null,
    routes_search:null,
    medications_search:null,
    orders_search:null,
    routes_filters:{status:-1,warehouse:-1},
    orders_filters:{status:-1, payment_status:-1},
    orders_startDate:moment().startOf('month').format('YYYY-MM-DD'),
    orders_endDate:moment().endOf('month').format('YYYY-MM-DD'),
}

const reducer = (state = initialState, action) => {


    switch(action.type){
        case actionTypes.CHANGE_AUTH_STATUS:
            return updateObject(state, {isauth:action.isauth})

        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_RECOVER_PASSWORD_MODAL:
            return updateObject(state, {recover_password_modal:action.recover_password_modal})
        case actionTypes.SYS_UPDATE_SENDING:
            return updateObject(state, {sending:action.sending})
        case actionTypes.SYS_UPDATE_SUCCESS:
            return updateObject(state, {success:action.success})
        case actionTypes.SYS_UPDATE_ERROR:
            return updateObject(state, {error:action.error})

        case actionTypes.CHANGE_PRODUCTS_VIEW:
            return updateObject(state, {products_view:action.products_view})
        case actionTypes.CHANGE_CATALOGS_VIEW:
            return updateObject(state, {catalogs_view:action.catalogs_view})

        case actionTypes.CHANGE_PRODUCTS_GENERAL_TABLE:
            return updateObject(state, {products_general_table:action.products_general_table})
        case actionTypes.CHANGE_PRODUCTS_MOSTSOLD_TABLE:
            return updateObject(state, {products_mostsold_table:action.products_mostsold_table})
        case actionTypes.CHANGE_PRODUCTS_NEW_TABLE:
            return updateObject(state, {products_new_table:action.products_new_table})
        case actionTypes.CHANGE_PRODUCTS_OUT_TABLE:
            return updateObject(state, {products_out_table:action.products_out_table})
        case actionTypes.CHANGE_PRODUCTS_SALE_TABLE:
            return updateObject(state, {products_sale_table:action.products_sale_table})
        case actionTypes.CHANGE_CAT_BRANDS_TABLE:
            return updateObject(state, {cat_brands_table:action.cat_brands_table})
        case actionTypes.CHANGE_CAT_CATEGORIES_TABLE:
            return updateObject(state, {cat_categories_table:action.cat_categories_table})
        case actionTypes.CHANGE_CAT_SUBCATEGORIES_TABLE:
            return updateObject(state, {cat_subcategories_table:action.cat_subcategories_table})
        case actionTypes.CHANGE_CAT_SEGMENTS_TABLE:
            return updateObject(state, {cat_segments_table:action.cat_segments_table})
        case actionTypes.CHANGE_CAT_SUBSEGMENTS_TABLE:
            return updateObject(state, {cat_subsegments_table:action.cat_subsegments_table})
        case actionTypes.CHANGE_USERS_TABLE:
            return updateObject(state, {users_table:action.users_table}) 
        case actionTypes.CHANGE_DOCTORS_TABLE:
            return updateObject(state, {doctors_table:action.doctors_table}) 
        case actionTypes.CHANGE_PATIENTS_TABLE:
            return updateObject(state, {patients_table:action.patients_table}) 
        case actionTypes.CHANGE_WAREHOUSES_LOCAL_TABLE:
            return updateObject(state, {warehouses_local_table:action.warehouses_local_table}) 
        case actionTypes.CHANGE_CARTS_TABLE:
            return updateObject(state, {carts_table:action.carts_table}) 
        case actionTypes.CHANGE_ROUTES_TABLE:
            return updateObject(state, {routes_table:action.routes_table}) 
        case actionTypes.CHANGE_MEDICATIONS_TABLE:
            return updateObject(state, {medications_table:action.medications_table})
        case actionTypes.CHANGE_ORDERS_TABLE:
            return updateObject(state, {orders_table:action.orders_table})

        case actionTypes.CHANGE_PRODUCTS_GENERAL_SEARCH:
            return updateObject(state, {products_general_search:action.products_general_search})
        case actionTypes.CHANGE_PRODUCTS_MOSTSOLD_SEARCH:
            return updateObject(state, {products_mostsold_search:action.products_mostsold_search})
        case actionTypes.CHANGE_PRODUCTS_NEW_SEARCH:
            return updateObject(state, {products_new_search:action.products_new_search})
        case actionTypes.CHANGE_PRODUCTS_OUT_SEARCH:
            return updateObject(state, {products_out_search:action.products_out_search})
        case actionTypes.CHANGE_PRODUCTS_SALE_SEARCH:
            return updateObject(state, {products_sale_search:action.products_sale_search})
        case actionTypes.CHANGE_CAT_BRANDS_SEARCH:
            return updateObject(state, {cat_brands_search:action.cat_brands_search})
        case actionTypes.CHANGE_CAT_CATEGORIES_SEARCH:
            return updateObject(state, {cat_categories_search:action.cat_categories_search})
        case actionTypes.CHANGE_CAT_SUBCATEGORIES_SEARCH:
            return updateObject(state, {cat_subcategories_search:action.cat_subcategories_search})
        case actionTypes.CHANGE_CAT_SEGMENTS_SEARCH:
            return updateObject(state, {cat_segments_search:action.cat_segments_search})
        case actionTypes.CHANGE_CAT_SUBSEGMENTS_SEARCH:
            return updateObject(state, {cat_subsegments_search:action.cat_subsegments_search})
        case actionTypes.CHANGE_USERS_SEARCH:
            return updateObject(state, {users_search:action.users_search}) 
        case actionTypes.CHANGE_DOCTORS_SEARCH:
            return updateObject(state, {doctors_search:action.doctors_search}) 
        case actionTypes.CHANGE_PATIENTS_SEARCH:
            return updateObject(state, {patients_search:action.patients_search}) 
        case actionTypes.CHANGE_WAREHOUSES_LOCAL_SEARCH:
            return updateObject(state, {warehouses_local_search:action.warehouses_local_search}) 
        case actionTypes.CHANGE_CARTS_SEARCH:
            return updateObject(state, {carts_search:action.carts_search}) 
        case actionTypes.CHANGE_ROUTES_SEARCH:
            return updateObject(state, {routes_search:action.routes_search}) 
        case actionTypes.CHANGE_MEDICATIONS_SEARCH:
            return updateObject(state, {medications_search:action.medications_search}) 
        case actionTypes.CHANGE_ORDERS_SEARCH:
            return updateObject(state, {orders_search:action.orders_search})


        case actionTypes.CHANGE_ROUTES_FILTERS:
            return updateObject(state, {routes_filters:action.routes_filters}) 
        case actionTypes.CHANGE_ORDERS_FILTERS:
            return updateObject(state, {orders_filters:action.orders_filters})

        case actionTypes.CHANGE_ORDERS_STARTDATE:
            return updateObject(state, {orders_startDate:action.orders_startDate})
        case actionTypes.CHANGE_ORDERS_ENDDATE:
            return updateObject(state, {orders_endDate:action.orders_endDate})
        case actionTypes.CHANGE_PRODUCTS_GENERAL_FILTER:
            return updateObject(state, {products_general_filter:action.products_general_filter})
        default:
            return state;

    }
}

export default reducer;