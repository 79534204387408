import React, {useState} from 'react'
import { Avatar, Fade } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx'
import { blueGrey } from '@mui/material/colors'
import noproduct from '../../../assets/noproduct.png'
import { config_data } from '../../../variables/config'

const useStyles = makeStyles(theme => ({
    imageContainer:{
        width:500,
        height:500,
        position:'relative',
        margin:'auto',
        marginTop:theme.spacing(4),
        marginBottom:theme.spacing(4),
        [theme.breakpoints.only('md')]: {
            width:400,
            height:400,
        },
    },
    shopContainer:{
        width:120,
        height:120,
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    tableContainer:{
        width:72,
        height:72,
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    modalContainer:{
        width:200,
        height:200,
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:12,
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:200,
            height:200,
        },
    },
    checkoutContainer:{
        width:80,
        height:80,
        border:`1px solid ${blueGrey[100]}`,
        borderRadius:12,
        background:'white',
        boxSizing:'border-box',
        padding:theme.spacing(1),
        position:'relative',
        margin:'auto',
        //marginTop:theme.spacing(2),
        //marginBottom:theme.spacing(2),
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    listContainer:{
        width:'100%',
        height:'100%',
        boxSizing:'border-box',
        position:'relative',
        margin:'auto',
        [theme.breakpoints.only('md')]: {
            width:80,
            height:80,
        },
    },
    autoContainer:{
        width:'100%',
        height:'100%',
        boxSizing:'border-box',
        position:'relative',
        margin:'auto',
    },
    image:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        objectFit:'contain',
        borderRadius:12,
        //background:'red'
    },
    quantity:{
        position:'absolute',
        top:-12,
        right:-12,
        background:theme.palette.primary.main,
        fontSize:14,
        width:24,
        height:24,
        zIndex:4

    },
  
}))

const ProductImage = props => {

    const classes = useStyles()
    const {type, src, quantity, src_temp, id} = props
    const [error, setError] = useState(true)
    

    const onImageLoad = (e) => {
        setError(false)
    }

    const onError = (e) => {
        setError(true)
    }

    let imgSrc

    if(src) imgSrc = src ? `${config_data.S3_SERVER_URL}${src}` : null
    if(src_temp) imgSrc = src_temp

    return(
        <div className={clsx({
            [classes.imageContainer]:true,
            [classes.shopContainer]:type === 'CART',
            [classes.checkoutContainer]:type === 'CHECKOUT',
            [classes.listContainer]:type === 'LIST',
            [classes.tableContainer]:type === 'TABLE',
            [classes.modalContainer]:type === 'MODAL',
            [classes.autoContainer]:type === 'AUTO',
        })}>
            {quantity ? <Avatar className={classes.quantity} color='primary'>{quantity}</Avatar> : null}
            {error ? <img alt='' src={noproduct} className={classes.image}  /> : null}
            <Fade in={!error}><img alt='' src={imgSrc} className={classes.image} onLoad={onImageLoad} onError={onError} key={id} /></Fade>
        </div>
    )
}

export default ProductImage