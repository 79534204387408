import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { DASHBOARD_SETTINGS, mainServer, ROUTE_STATUSES } from '../../../../../variables/config';
import DeliveryModal from '../../modals/DeliveryModal';
import CancelCard from './components/CancelCard';
import SimpleTable from './components/SimpleTable';



const FinalRouteSection = ({ modals, routeorders, actions, form, status, delivery_man_id}) => {


    const classes = useStyles()
    
    const [selected, setSelected] = useState(null)

    const onSelectItem = (_id) => {
        setSelected(_id)
        actions.onUpdateModalStatus('delivery', true)
    }
    

    return ( 
        <div className={classes.root}>
            <DeliveryModal open={modals.delivery} onClose={()=>actions.onUpdateModalStatus('delivery', false)} id={selected} onCompleted={actions.onRefreshRouteOrders} 
                user_id={delivery_man_id} />
                <div className={classes.container}>
                    <div className={classes.table}>
                        <SimpleTable
                            data={routeorders}  
                            onSelectItem={status === ROUTE_STATUSES.CANCELLED ? null : onSelectItem}           
                        />
                    </div>
                    {status === ROUTE_STATUSES.CANCELLED ? null :
                    <div className={classes.cancel_card}>
                        <CancelCard actions={actions} form={form} status={status} />
                    </div>}
                </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        borderRadius:10,
        padding:16
    },
    table:{
        padding:'40px 0px'
    },
    cancel_card:{
        marginTop:40
    }
}))
 
export default FinalRouteSection;