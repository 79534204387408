import { Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PillStatus from "../../../../../../components/Structure/DisplayData/PillStatus"
import { onGetDateFormat } from '../../../../../../shared/utility'



const SpecialDay = ({open, start_time, end_time, catalogs, onDelete}) => {

    const classes = useStyles()

    


    return (
        <div className={classes.root} >
            <Grid container alignItems="center" spacing={1} >
                <Grid item>
                    <div className={classes.date_container} >
                        <div>
                            <Typography className={classes.day} align='center'>{onGetDateFormat(start_time, 'ddd D')}</Typography>
                            <Typography variant="body2">{onGetDateFormat(start_time, 'hh:mm A')}</Typography>
                        </div>
                        <div style={{padding:'20px 16px 0px'}} >-</div>
                        <div>
                            <Typography className={classes.day} align='center'>{onGetDateFormat(end_time, 'ddd D')}</Typography>
                            <Typography variant="body2">{onGetDateFormat(end_time, 'hh:mm A')}</Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.status_container} >
                        <PillStatus catalogs={catalogs.warehouses_open} value={open}  />
                    </div>
                </Grid>
                <Grid item>
                    <IconButton className={classes.delete_button} onClick={onDelete} size="large"><Icon>delete</Icon></IconButton>
                </Grid>
            </Grid>
        </div>
    );
}

export default SpecialDay

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        borderRadius:15,
        padding:'8px 48px',
        marginBottom:4
    },
    delete_button:{
        boxShadow:'0px 0px 2.09633px rgba(120, 120, 120, 0.43)',
        //color:theme.palette.secondary.main
        color:'#D95D71',
        padding:8
    },
    date_container:{
        display:'flex'
    },
    status_container:{
        width:'100%',
        //background:'red',
        display:'flex',
        justifyContent:'center'
    },
    day:{
        color:'#70708E',
        fontSize:'0.875rem',
        fontWeight:400,
        textTransform:'capitalize'
    }
    

}))