import { change_image_error_msg } from "./general-translations";
import medicine from '../../assets/icons/medicine.svg'

export const translations = {
    title:'Medicamentos',
    success_msg:'Acción exitosa',
    menu:[{id:1, label:'Todos'}, {id:2, label:'Sustancias'}, {id:3, label:'Laboratorios'}],
    views:{
        medications:{
            table:{
                header:[
                    {id:0, label:''},
                    {id:1, label:'ID'},
                    {id:2, label:'Nombre'},
                    //{id:3, label:'Sustancia'},
                    //{id:4, label:'Forma farmacéutica'},
                    {id:5, label:'Laboratorio'},    
                    {id:6, label:'Presentación'},
                    {id:7, label:''}
                ],
                nodata:'No existen medicamentos registrados',
                empty:medicine
            },
            modal:{
                add:{
                    title:'Añadir medicamento',
                    button:'Añadir'
                },
                form:{
                    name:{label:'Nombre', helperText:'Campo requerido'},
                    external_id:{label:'ID externo', helperText:'Campo requerido'},
                    presentation:{label:'Presentación', helperText:'Campo requerido'},
                    external_image:{label:'Imagen externa', helperText:'Campo requerido'},
                }
            }
        },
        substances:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Sustancia'},
                    {id:3, label:''},
                ],
                nodata:'No existen sustancias registradas',
                empty:medicine
            },
            modal:{
                add:{
                    title:'Añadir sustancia',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar sustancia',
                    button:'Guardar'
                },
                form:{
                    name:{label:'Nombre', helperText:'Campo requerido'},
                }
            }
        },
        labs:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Laboratorio'},
                    {id:3, label:''},
                ],
                nodata:'No existen laboratorios registrados',
                empty:medicine
            },
            modal:{
                add:{
                    title:'Añadir laboratorio',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar laboratorio',
                    button:'Guardar'
                },
                form:{
                    name:{label:'Nombre', helperText:'Campo requerido'},
                }
            }
        }
    }
    
}

