import makeStyles from '@mui/styles/makeStyles';
import { grey } from "@mui/material/colors";


export const tableStyles = makeStyles(theme => ({
    root:{},
    paper:{
        //borderRadius:16,
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        background:'transparent',
        border:'none',
        boxShadow:'none'
    },
    cell:{
        border:'none',
        borderBottom:'6px solid #f6f7fb',
        '&:first-child':{
            borderRadius:'16px 0px 0px 16px',
        },
        '&:last-child':{
            borderRadius:'0px 16px 16px 0px',
        },
        //background:'red'  
    },
    row:{
        //paddingTop:12,
        //paddingBottom:12,
        //border:'1px solid blue',
        borderRadius:16,
        background:'white',
        //padding:'12px 0px',
        maxHeight:100,
        boxShadow:'0px 0px 15px -3px rgba(219, 219, 219, 0.26)',
        //marginBottom:6,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    no_click_row:{
        //paddingTop:12,
        //paddingBottom:12,
        //border:'1px solid blue',
        borderRadius:16,
        background:'white',
        //padding:'12px 0px',
        height:100,
        boxShadow:'0px 0px 15px -3px rgba(219, 219, 219, 0.26)',
        //marginBottom:6,
        /* '&:hover':{
            cursor:'pointer',
            background:grey[100]
        } */
    },
    cellHeader:{
        //borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
        background:'#f6f7fb',
        border:'none',
        color:'#8C8C9B'
        //borderRadius:16
    },
    header:{
        fontWeight:400
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800,
        /* '& .MuiTable-stickyHeader':{
            borderRadius:16,
            background:'red'
        } */
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40,
        height:'100%',
        minHeight:600,
        //background:'red'
    },
    table_container:{
        maxHeight:600
    },
    map_button:{
        border:`0.7px solid ${theme.palette.primary.main}`,
        borderRadius:4,
        color:theme.palette.primary.main,
        background:'white',
        textTransform:'none',
        padding:'6px 16px'
    },
    delete_button:{
        boxShadow:'0px 0px 2.09633px rgba(120, 120, 120, 0.43)',
        //color:theme.palette.secondary.main
        color:'#D95D71',
        padding:8
    },
    edit_button:{
        boxShadow:'0px 0px 2.09633px rgba(120, 120, 120, 0.43)',
        color:theme.palette.primary.dark,
        padding:8
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:20,
        borderRadius:'50%',
        background:'white',
        //boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        border:'3px solid #E8EBF3',
        width:140,
        height:140,
        padding:32,
        boxSizing:'border-box'
        
    }
}))