import axios from 'axios'
const { mainServer, BASIC_AUTH_HEADER } = require("../../../../../variables/config");

//gets
export const request_brands = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/brand/list${params}`)
    return response.data.data
}

export const request_brand = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/brand/${id}`)
    return response.data.data
}

//posts
export const request_create_brand = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/brand`, data)
    return response.data.data
}

//patchs
export const request_update_brand = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/brand/${id}`, data)
    return response.data.data
}

/* ----------- MEDIA ------------ */

//posts
export const request_upload_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/brand/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}