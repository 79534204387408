import { success_msg } from "./general-translations";

const default_select_helperText = 'Debe elegir una opción'
const default_input_helperText = 'Este campo debe de contener al menos un caracter'

export const translations = {
    title:'Mi información',
    success_msg:success_msg,
    breadcrumbs_label:'Mis datos',
    user_info_card:{
        button:'Cambiar contraseña',
        modal:{
            title:'Cambio de contraseña',
            subtitle:'Por favor ingresa los datos solicitados',
            form:{
                password:{
                    label:'Contraseña',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
                passwordConfirmation:{
                    label:'Confirmar contraseña',
                    helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
                },
            },
            button:'Actualizar'
        }
    },
    cards:{
        general_info:'Información general',
        contact_info:'Información de contacto',
        add_info:'Información adicional',
        button:'Guardar',
        change_password:'Cambiar contraseña',
        personal_info:'Información personal',
        medical_info:'Información médica'
    },
    form:{
        first_name:{
            label:'Nombre',
            helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        fathers_last_name:{
            label:'Apellido paterno',
            helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        mothers_last_name:{
            label:'Apellido materno',
            helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        email:{
            label:'Email',
            helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        user_gender_id:{
            label:'Género',
            helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        //contact info
        email: {
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
        },
        mobile_country_id: {
            label: 'Código',
            helperText: default_select_helperText
        },
        mobile: {
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        }, 
        fixed_phone_country_id: {
            label: 'Código',
            helperText: default_select_helperText
        },
        fixed_phone: {
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },    
        zip: {            
            label: 'Código postal',
            helperText: 'Código postal no válido'        
        },  
        city: {            
            label: 'Ciudad',
            helperText: default_input_helperText        
        }, 
        
        //additional info
        blood_type_id: {
            label: 'Tipo de sangre',
            helperText: default_select_helperText
        },
        civil_status_id: {
            label: 'Estado civil',
            helperText: default_select_helperText
        },
        occupation: {
            label: 'Ocupación',
            helperText: default_input_helperText
        },
        scolarity_id: {
            label: 'Escolaridad',
            helperText: default_select_helperText
        },
        religion: {
            label: 'Religión',
            helperText: default_input_helperText
        },
        ethnic_group: {
            label: 'Grupo étnico',
            helperText: default_input_helperText
        },
        ethnic_language: {
            label: 'Dialecto',
            helperText: default_input_helperText
        },
        currency_id: {
            label: 'Moneda',
            helperText: default_select_helperText
        },
    },
    edit_card:{
        title:'Editar perfil',
        form:{
            first_name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            fathers_last_name:{
                label:'Apellido paterno',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            mothers_last_name:{
                label:'Apellido materno',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            email:{
                label:'Email',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            user_gender_id:{
                label:'Género',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            //contact info
            email: {
                label: 'Dirección de correo',
                helperText: 'Dirección de correo no válido',
            },
            mobile_country_id: {
                label: 'Código',
                helperText: default_select_helperText
            },
            mobile: {
                label: 'Teléfono celular',
                helperText: 'Número de teléfono no válido',
            }, 
            fixed_phone_country_id: {
                label: 'Código',
                helperText: default_select_helperText
            },
            fixed_phone: {
                label: 'Teléfono fijo',
                helperText: 'Número de teléfono no válido',
            },    
            zip: {            
                label: 'Código postal',
                helperText: 'Código postal no válido'        
            },  
            city: {            
                label: 'Ciudad',
                helperText: default_input_helperText        
            }, 
            
            //additional info
            blood_type_id: {
                label: 'Tipo de sangre',
                helperText: default_select_helperText
            },
            civil_status_id: {
                label: 'Estado civil',
                helperText: default_select_helperText
            },
            occupation: {
                label: 'Ocupación',
                helperText: default_input_helperText
            },
            scolarity_id: {
                label: 'Escolaridad',
                helperText: default_select_helperText
            },
            religion: {
                label: 'Religión',
                helperText: default_input_helperText
            },
            ethnic_group: {
                label: 'Grupo étnico',
                helperText: default_input_helperText
            },
            ethnic_language: {
                label: 'Dialecto',
                helperText: default_input_helperText
            },
            currency_id: {
                label: 'Moneda',
                helperText: default_select_helperText
            },
        },
        button:'Guardar',
    }, 
}

