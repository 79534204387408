import { Hidden } from "@mui/material"
import { useState } from "react"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import PageMenuMobile from "../../../../../../components/Structure/Navigation/PageMenuMobile"
import ToolbarTable from "../../../../../../components/Structure/Navigation/ToolbarTable"
import Actives from "./Actives"
import Categorization from "./Categorization"
import Details from "./Details"
import Prescription from "./Prescription"
import Price from "./Price"
import ProductImages from "./ProductImages"

const TabCard = ({content, form_data, actions, view_data, modals, id, search_catalogs}) => {

    const menu = content.tab_card.menu

    const { currency, taxes, form, price_form, actives, images, selected_image, taxes_price, final_price } = view_data

    const [view, setView] = useState(1)
     
    let contentView = null

    switch(view){
        case 1:
            contentView = <Details form={form} onChange={actions.onChangeForm} onUpdateLink={actions.onUpdateLink} />
            break
        case 2:
            contentView = <Prescription form_data={form_data} actions={actions}  />
            break
        case 3:
            contentView = <Price onChange={actions.onChangePrice} onChangeProd={actions.onChangeForm} onChangeCurrency={actions.onChangeCurrency} 
                                form={price_form} form_product={form} currency={currency} taxes={taxes} content={content.price} taxes_price={taxes_price}
                                onAddTax={actions.onAddTax} onEditTax={actions.onEditTax} onDeleteTax={actions.onDeleteTax} final_price={final_price} />
            break
        case 4:
            contentView = <Actives actions={actions} actives={actives} />
            break
        case 5:
            contentView = <ProductImages id={id} images={images} content={content.product_images}
                                actions={actions} modals={modals} selected_image={selected_image} />
            break
        case 6:
            contentView = <Categorization content={content} view_data={view_data} actions={actions} search_catalogs={search_catalogs} id={id} modals={modals} />
            break
        default:
            break
    }

    return (
        <SimpleCard>
            <div style={{justifyContent:'center', display:'flex'}}>
                <Hidden mdDown>
                    <ToolbarTable variant='secondary' selected={view} menuOptions={menu} onChange={(id)=>setView(id)} />
                </Hidden>
                <Hidden mdUp>
                    <PageMenuMobile selected={view} options={menu} onChange={(id)=>setView(id)} />
                </Hidden>
            </div>
            <div style={{marginTop:70, marginBottom:100}}>
                {contentView}
            </div>
        </SimpleCard>
    );
}

export default TabCard