import { Grid, Typography } from "@mui/material"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import PillStatus from "../../../../components/Structure/DisplayData/PillStatus"
import { onGetFormatNumber } from "../../../../shared/utility"
import catalogs from "../../../../texts/esp/catalogs"
import { nodata } from "../../../../variables/config"


const PaymentMethod = ({preferred_store_payment_type, payment_status_id, store_payment_data }) => {

    let card = null
    let payment_type = nodata

    if(store_payment_data){
        payment_type = catalogs.store_payment_types.find(el => el.value === store_payment_data.store_payment_type_id)
        if(store_payment_data.payment_method) card = catalogs.payment_cards.find(el => el.brand === store_payment_data.payment_method.brand)
    }

    return(
        <SimpleCard>
            <div style={{marginBottom:20}}>
                <Typography color='primary'>Método de pago</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs><Typography>Estatus</Typography></Grid>
                        <Grid item ><PillStatus catalogs={catalogs.payment_statuses} value={payment_status_id} /></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs><Typography>Método de pago preferido</Typography></Grid>
                        <Grid item ><Typography>{preferred_store_payment_type}</Typography></Grid>
                    </Grid>
                </Grid>           
                <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent='space-between' alignItems="center">
                        <Grid item >
                            <Typography>{payment_type === nodata ? nodata : payment_type.label}</Typography>
                            {store_payment_data && store_payment_data.store_payment_type_id === 1 ?
                            <Grid item >
                                <Grid container wrap='nowrap' spacing={1} alignItems='center'>
                                    <Grid item><img src={card.logo} width={30} /></Grid>
                                    <Grid item><Typography>**** **** **** {store_payment_data.payment_method.last4}</Typography></Grid>
                                </Grid>                    
                            </Grid> : null}
                        </Grid>                  
                        <Grid item ><Typography>{onGetFormatNumber(store_payment_data ? store_payment_data.amount : '0')}</Typography></Grid>
                    </Grid>
                </Grid>        
            </Grid>
        </SimpleCard>
    )
}

export default PaymentMethod