import React, {useState, useEffect} from 'react'
import {
    createTheme,
    Hidden,
    ThemeProvider,
    StyledEngineProvider,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Topbar from '../Topbar/Topbar';
import { withRouter } from 'react-router-dom';
import SingInModal from '../../../modals/SignInModal/SingInModal';
import ForgottenPasswordModal from '../../../modals/ForgottenPasswordModal/ForgottenPasswordModal';
import { isTokenValid } from '../../../shared/utility';
import { connect } from 'react-redux';
import * as actionTypes from '../../../store/actions';
import SignUpModal from '../../../modals/SignUpModal/SignUpModal';
import { request_profile } from './requests';
import Sidebar from '../Sidebar/Sidebar';
import { theme } from '../../../theme';
import cx from 'classnames';


const useStyles = makeStyles((theme) => ({
	root: {
		background: '#fff', //'#f1f5f8',//'#f8f8f8',//'#f4f6f8',
		display:'inline !important',
	},
	subroot: {
		minHeight: 'calc(100vh - 100px)',
		marginLeft: 290,
		padding: '100px 0px 0px 10px',
		background: '#F6F7FB',
		borderRadius: '60px 0px 0px 60px',
		//background:'red'
		//add by me
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	//Sidebar visual update: Diego
	subrootShifted: {
		marginLeft: 134,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	subrootMobile: {
		marginLeft: 0,
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
}));

const Frame = (props) => {
	const classes = useStyles();
	const [modals, setModals] = useState({
		signin: false,
		signup: false,
		forgottenpassword: false,
	});
	const { isauth, cartItems, history } = props;
	const { onChangeAuthStatus, onUpdateCartItems, onUpdateUser, onUpdateRecoverPasswordModal } = props;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	//added by me
	const [sideBar, setSideBar] = useState(false);

	//Sidebar visual update: Diego
	const [shiftedSideBar, setShiftedSideBar] = useState(false);

	useEffect(() => {
		const initModule = async () => {
			try {
				console.log('Incializar');
				const validToken = isTokenValid();
				onChangeAuthStatus(validToken);
				if (validToken) {
					const user = await request_profile();
					onUpdateUser(user);
				} else {
					history.push('/signin');
				}
			} catch (error) {
				console.log(error);
			}
		};
		initModule();
	}, []);

	const onOpenModal = (item) => {
		console.log(item);
		if (item === 'signin' || item === 'signup' || item === 'forgottenpassword') {
			let temp = { ...modals };
			temp[item] = true;
			setModals(temp);
			return;
		}
		if (item === 'profile') history.push('/profile');
		if (item === 'orders') history.push('/orders');
		if (item === 'forgottenpassword') onUpdateRecoverPasswordModal(true);
	};

	const onCloseModal = (item) => {
		let temp = { ...modals };
		temp[item] = false;
		setModals(temp);
	};

	const onChangeModal = (from, to) => {
		let temp = { ...modals };
		temp[from] = false;
		temp[to] = true;
		setModals(temp);
	};

	const onTriggerSideBar = () => {
		setSideBar(!sideBar);
	};

	const onShiftSideBar = () => {
		setShiftedSideBar(!shiftedSideBar);
	};

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <div className={classes.root}>
                    <SingInModal open={modals.signin} onClose={onCloseModal} onOpenModal={onOpenModal} />
                    <SignUpModal open={modals.signup} onClose={onCloseModal} onOpenModal={onOpenModal} onChangeModal={onChangeModal} />
                    <ForgottenPasswordModal open={modals.forgottenpassword} onClose={onCloseModal} />

                    <Topbar sideBarHandler={onTriggerSideBar} onShiftSideBar={onShiftSideBar} isShifted={shiftedSideBar} isMobile={isMobile} />

                    {!isMobile ? (
                        <Sidebar variant='persistent' open={true} isShifted={shiftedSideBar} />
                    ) : (
                        <Sidebar variant='temporary' open={sideBar} closeSide={onTriggerSideBar} isMobile={isMobile} />
                    )}

                    <div
                        className={cx({
                            [classes.subroot]: true,
                            [classes.subrootShifted]: shiftedSideBar,
                            [classes.subrootMobile]: isMobile,
                        })}
                    >
                        {props.children}
                    </div>
                </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

const mapStateToProps = state => {
    return {
        isauth: state.isauth,
        cartItems: state.cartItems,
        recover_password_modal:state.recover_password_modal,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChangeAuthStatus: (isauth) => dispatch({type: actionTypes.CHANGE_AUTH_STATUS, isauth }),
        onUpdateCartItems: (cartItems) => dispatch({type: actionTypes.UPDATE_CART_ITEMS, cartItems }),
        onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user }),
        onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal }),

    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Frame))