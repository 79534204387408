import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { onGetErrorMessage, updateAuthorizationHeader } from '../../../../../shared/utility'
import { actionTypes } from '../../../../../store/actions'
import { private_server } from '../../../../../variables/config'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddCategoryModal from './modals/AddCategoryModal'
import DeleteModal from './modals/DeleteModal'
import { request_categories } from './requests'

const CategoriesView = (props) => {

    const { history, onUpdateError, cat_categories_search, cat_categories_table, onUpdateCategoriesSearch, onUpdateCategoriesTable} = props
   
    //general
    const [loading, setLoading] = useState(false)
    const [categories, setCategories] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [delete_modal, setDeleteModal] = useState(false)
 
    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        /* const initModule = async() => {try {
            window.scrollTo({top: 0, behavior: 'smooth'})
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _categories = await request_categories(params)
            setCategories(_categories.categories)
            setTotal(_categories.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
        }} */
        initModule()
    }, [cat_categories_table])


    const initModule = async() => {try {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setLoading(true)
        const params = onGetParamsFromState(cat_categories_table, cat_categories_search)
        console.log(params)
        const _categories = await request_categories(params)
        setCategories(_categories.categories)
        setTotal(_categories.count)
        setLoading(false)
    } catch (error) {
        setLoading(false)
        onUpdateError(onGetErrorMessage(error))
    }}

    
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(cat_categories_table, cat_categories_search)
        const _categories = await request_categories(params)
        setCategories(_categories.categories)
        setTotal(_categories.count)
        setAddModal(false)
    }

    const onSelectedItem = (item, type) => {
        const {id_category} = item
        setSelected(id_category)
        if(type === 'edit') history.push(`/catalogs/category/${id_category}`)
        if(type === 'delete') setDeleteModal(true)
    }

    const onChangeStore = async(_id, prev_store) => {
        try{
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/category/${_id}`, {store: prev_store === 1 ? 0 : 1})
            initModule()
        }catch(e){
            console.log(e)
        }
        
    }

    console.log('Mis categorias', categories)

    return(
        <div>
            <AddCategoryModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted}/>
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} id={selected}/>
            <SimpleTable
                data={categories ?? []}
                tableFilter={cat_categories_table}
                search={cat_categories_search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onUpdateCategoriesSearch}
                onUpdateTableFilter={(data) => onUpdateCategoriesTable(data)}
                onSelectedItem={onSelectedItem}
                onChangeStore={onChangeStore}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(CategoriesView)