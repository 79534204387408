import { Grid, Typography } from "@mui/material"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import Categories from "./Categories"
import Segments from "./Segments"
import Subcategories from "./Subcategories"
import Subsegments from "./Subsegments"
import Tags from "./Tags"


const Categorization = ({content, modals, actions, view_data, search_catalogs, id}) => {

    const {product_categories, product_subcategories, product_segments, product_subsegments, product_tags, search_form} = view_data
    const {tags_catalogs, categories_catalogs, subcategories_catalogs, segments_catalogs, subsegments_catalogs, brands_catalogs} = search_catalogs

    //console.log(categories_catalogs)

    return(
        <div >
            <Grid container spacing={3}>
                <Grid item xs={12} style={{marginBottom:20}}>
                    <Grid container alignItems='center'>
                        <Grid item ><Typography variant='subtitle1'>Categorización</Typography></Grid>
                        <Grid item xs><div style={{width:'98%', height:0.5, background:'#DCDCDC', marginLeft:16 }}></div></Grid>
                    </Grid>
                </Grid>
                <Grid item md={6}>
                    <Categories product_categories={product_categories} content={content.categories}
                    actions={actions} modals={modals} form={search_form.id_category} options={categories_catalogs} 
                    />
                </Grid>
                {product_categories && product_categories.length !== 0 ? <Grid item md={6}>
                    <Subcategories product_subcategories={product_subcategories} content={content.subcategories} id={id}
                    actions={actions} modals={modals} form={search_form.id_sub_category} options={subcategories_catalogs}  
                    />
                </Grid> : null}
                {product_subcategories && product_subcategories.length !== 0 ? <Grid item md={6}>
                    <Segments product_segments={product_segments} content={content.segments} id={id} product_subcategories={product_subcategories}
                    actions={actions} modals={modals} form={search_form.id_segment} options={segments_catalogs}
                    />
                </Grid> : null}
                {product_segments && product_segments.length !== 0 ? <Grid item md={6}>
                    <Subsegments product_subsegments={product_subsegments} content={content.subsegments} id={id}
                    actions={actions} modals={modals} form={search_form.id_sub_segment} options={subsegments_catalogs}
                    />
                </Grid> : null}
                <Grid item xs={12}>
                    <Tags product_tags={product_tags} content={content.tags}
                    actions={actions} modals={modals} form={search_form.id_tag} options={tags_catalogs}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default Categorization