import { Grid, Chip, Typography } from '@mui/material'
import AutocompleteFormAdd from '../../../../../../components/Forms/AutocompleteFormAdd'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import AddSubsegmentModal from '../modals/AddSubsegmentModal'



const Subsegments = ({ product_subsegments, actions, modals, form, content, options, id}) => {

    /* const content = translations_esp.subsegments

    const [add_modal, setAddModal] = useState(false)

    const onDeleteItem = async(el) => {
        await request_delete_product_subsegment(el)
        onUpdateCompleted()
    }

    const onAddProductItem = async (el) => {
        try {
            await request_create_product_subsegment({product_id:id, sub_segment_id:el});
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    } */

    let subsegment_form = {...form}
    subsegment_form.options = options

    return(
        <div>
            <AddSubsegmentModal open={modals.add_subsegment} onClose={()=>actions.onChangeModalStatus('add_subsegment', false)} onUpdateCompleted={actions.onUpdateSubsegmentsCompleted} 
            onAddProductItem={actions.onAddProductSubsegment} content={content.modal} id={id} />
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <Typography variant='subtitle1'>{content.title}</Typography>                
                </Grid>
                <Grid item xs={12}>
                    {/* <SearchSubsegments onAddItem={()=>setAddModal(true)} existingItems={product_subsegments} 
                    onAddProductItem={onAddProductItem} content={content.search_bar} 
                    product_segments={product_segments}/> */}
                    <AutocompleteFormAdd data={subsegment_form} onChange={actions.onChangeSearchForm} add_register={content.search_bar.add_register}
                        onAdd={()=>actions.onChangeModalStatus('add_subsegment', true)} onInputChange={actions.onSearchSubsegments} onAdd2={actions.onAddProductSubsegment} />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_subsegments.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.sub_segment} onDelete={()=>actions.onDeleteSubsegment(item.id_product_sub_segment)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </div>
    )
}

export default Subsegments