import { Checkbox, Grid, Typography } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import DisplayText from '../../../../../../components/Texts/DisplayText'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import { isFormValid, onGetCatalogs, onGetFormData, onInitEmptyForm, onSetErrorsToForm, onGetRandomNumber } from '../../../../../../shared/utility'
import { request_create_segment, request_subcategories } from '../requests'
import { translations as translations_esp } from '../../../../../../texts/esp/segments-translations'
import { catalogs } from '../../../../../../texts/esp/catalogs'

const AddSegmentModal = ({open, onClose, onUpdateCompleted}) => {

    const content = translations_esp.modal
    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState('#ffffff')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)
    const [checked, setChecked] = useState(true)
    const [cat, setCat] = useState([])

    useEffect(() => {
    const initModal = async() =>{
        if (!open) {
            let _form = onInitEmptyForm(formData, content.form)
            _form.url.value = ''
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            try{
                const _subcategories = await request_subcategories()
                const catalogs = onGetCatalogs(_subcategories.sub_categories, 'id_sub_category', 'name')
                setCat(catalogs)
                console.log(catalogs)
                let _form = {...form}
                _form.sub_category_id.options = catalogs
                setForm(_form)
            }catch(error){
                console.log(error)
            }
        }
    }
    initModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'name' || id === 'sub_category_id'){
            //console.log('hola')
            //const num = onGetRandomNumber()
            const cate = cat.find(el => temp['sub_category_id'].value === el.value)
            //temp['url'].value = temp['name'].value + '-' + temp['subname'].value + '-' + temp['presentation'].value + onGetRandomNumber()
            temp['url'].value = cate.label.split(" ").join('-').slice(0, -1) + temp['name']?.value.split(" ").join('-') 
        }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        //data2send.color = color
        data2send.store = checked ? 1 : 0
        const subcat = cat.find(el => data2send.sub_category_id === el.value)
        data2send.url = subcat.label.split(" ").join('-').slice(0, -1) + form.name.value.split(" ").join('-')
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            await request_create_segment(data2send);     
            onUpdateCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }
        setDisabled(false)

    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectForm data={form.sub_category_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item><Checkbox color='primary' checked={checked} onChange={(event)=>setChecked(event.target.checked)}  /></Grid>
                                <Grid item><Typography color='textSecondary'>{content.store_label}</Typography></Grid>
                            </Grid>     
                        </Grid>
                        {/* <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='textSecondary'>{content.form.color.label}</DisplayText>
                            <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                        </Grid>
                        
                        <Grid item xs={12}>
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid> */}
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddSegmentModal


const formData = {
    sub_category_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'sub_category_id',
            type: 'selected',
            fullWidth: true,
            label: 'Subcategoría',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },

}