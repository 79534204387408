import { useEffect } from "react"
import { useState } from "react"
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetNameCatalogs, onGetSelectedFormData, onInitForm, onSetErrorsToForm, onUpdateAuthorizationHeader } from "../../../shared/utility"
import { mainServer, private_server, ROUTE_STATUSES, USER_TYPES } from "../../../variables/config"
import queryString from 'query-string'
import catalogs from "../../../texts/esp/catalogs"
import moment from "moment"

const useRoutesView = ({user, history, match, onUpdateError}) => {

    const [loading, setLoading] = useState(false)
    const [rloading, setRloading] = useState(false)
    const [sending_next, setSendingNext] = useState(false)
    const [sending_back, setSendingBack] = useState(false)
    const [error, setError] = useState(false)

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(form_data)))
    const [cancel_form, setCancelForm] = useState(JSON.parse(JSON.stringify(form_cancel_route)))

    const [route, setRoute] = useState(null)
    const [orders, setOrders] = useState([])
    const [routeorders, setRouteorders] = useState([])
    const [ordercarts, setOrdercarts] = useState([])

    const [number, setNumber] = useState(1)

    //orders table
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({limit:10,offset:0})

    const [startDate, setStartDate] = useState(moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'))

    const onConvertDateToUtc = (_date) =>{
        if(!_date) return null
        const momentdata = moment(_date)
        if(!momentdata.isValid()) return null

        return moment(_date).add(6, 'hours').format("YYYY-MM-DD h:mm:ss")
    }


    const [modals, setModals] = useState({
        delivery_man: false,
        terminal:false,
        edit_register:false,
        delete_route:false,
        loading:false,
        warning:false,
        delivery:false
    })

    //console.log(match)

    const routeID = match.params.id
    //console.log(routeID)

    useEffect(() => {
        window.scrollTo(0,0)
        if(user){
            actions.onInitModule()
            actions.onUpdateForm()
        }
    }, [user])
    
    useEffect(() => {
        if(route){
            actions.onInitRouteOrders()
        }
    }, [pagination, route])


    const onGetOrdersParams = () => {
    
        //console.log(route)
        const {limit, offset} = pagination
        const params2send = {limit:limit, offset:offset*limit, order:0, order_by:'schedule_time'}
        let params = `?${queryString.stringify(params2send)}`

        let andFilters = [
            {"OR":[
                //{"field":"order_status_id","operator":"=","value":1},
                {"field":"order_status_id","operator":"=","value":2},
                {"field":"order_status_id","operator":"=","value":9},
                {"field":"shipping_type_id","operator":"=","value":2},
                {"field":"shipping_type_id","operator":"=","value":5},
            ]},
            {"field":"order_type_id","operator":"=","value":1},
            
            {"field":"warehouse_id","operator":"=","value":`${route.warehouse_id}`},
            {"field":"schedule_time","operator":">=","value":`${onConvertDateToUtc(startDate)}`},
            {"field":"schedule_time","operator":"<=","value":`${onConvertDateToUtc(endDate)}`}
        ]

        let filtersArgs = {
            "AND":andFilters
            
        }
    
        if(search){
            //filtersArgs.push({"field":"user","operator":"LIKE","value":`%${search}%`})
            andFilters.push({"field":"id_order","operator":"LIKE","value":`%${search}%`})
        }
            
        /* if(filtersArgs.length){
            filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs))
            params = `?${queryString.stringify(params2send)}&filter=${filtersArgs}`
        } */

        filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs))
        console.log(params, filtersArgs)
        params = `?${queryString.stringify(params2send)}&filter=${filtersArgs}`
    
        return params 
    }


    const actions = {
        onInitModule: async() => {
            setLoading(true)
            try {
                onUpdateAuthorizationHeader()
                const request_route = await mainServer.get(`/route/${routeID}`)
                const _route = request_route.data.data.route
                setRoute(_route)
            } catch (error) {
                onUpdateError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onInitRouteOrders: async() =>{
            setRloading(true)
            try {
                onUpdateAuthorizationHeader()
                const params = onGetOrdersParams()
                const request_orders = await mainServer.get(`/order/list${params}`)
                const _orders = request_orders.data.data
                console.log('Pametros de la orden', queryString.parse(params))
                console.log(_orders)

                const req_routeorders = await mainServer.get(`/routeorder/list?order_by=number&order=0&filter={"AND":[{"field":"route_id","operator":"=","value":${routeID}}]}`)
                const _routeorders = req_routeorders.data.data.route_orders
                setRouteorders(_routeorders)
                console.log(_routeorders)

                //aqui va funcion para filtrat orders
                const opt = _orders.orders.map((item)=>{
                    let ex= true
                    for (let index = 0; index < _routeorders.length; index++) {
                        if (item.id_order === _routeorders[index].order_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })

                /* Promise.all(final.map(async (item, index) => {
                    await actions.onUpdateRouteorder(item.route_order_id, {number:index+1})
                })) */

                //console.log(final)

                setOrders(final)
                setTotal(final.length)
            } catch (error) {
                onUpdateError(onGetErrorMessage(error))
            }
            setRloading(false)
        },
        onChangeDate: (value, id) => {
            let newTableFilter = {...pagination}
            newTableFilter.offset = 0
            setPagination(newTableFilter)
            let aux
            if(id === 'start_date'){
                aux = moment(value)
                console.log()
                if(moment(endDate).diff(aux) > 0) setStartDate(value)
            }
            if(id === 'end_date'){
                aux = moment(value)
                if(aux.diff(moment(startDate)) > 0) setEndDate(value)
            }
        },
        onRefreshRouteOrders: async() =>{
            try {
                const req_routeorders = await mainServer.get(`/routeorder/list?order_by=number&order=0&filter=[{"field":"route_id","operator":"=","value":${routeID}}]`)
                const _routeorders = req_routeorders.data.data.route_orders
                setRouteorders(_routeorders)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
            }
        },
        onUpdateForm: async() => {
            try {
                let _form = {...form}
                _form = onInitForm(_form, user)
                
                setForm(_form)
                console.log(_form)
            } catch (error) {
                console.log(error)
            }
        },
        onChangeForm: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setForm(temp)
        },
        onChangeCancelForm: (data) => {
            let temp = { ...cancel_form }
            const id = data.config.id
            temp[id] = { ...data }
            if(error) setError(null)
            setCancelForm(temp)
        },
        onRefreshData: async() => {
            try {
                onUpdateAuthorizationHeader()
                const request_route = await mainServer.get(`/route/${routeID}`)
                const _route = request_route.data.data.route
                setRoute(_route)
            } catch (error) {
                onUpdateError(onGetErrorMessage(error))
            }
        },
        onUpdateRouteData: async(data) => {
            try{
                onUpdateAuthorizationHeader()
                const req = await mainServer.patch(`/route/${routeID}`, data)
                actions.onRefreshData()
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
        },
        onUpdateRouteStatusNext:async() => {    
            console.log(route.route_status_id)  
            if(route.route_status_id === ROUTE_STATUSES.PENDING){
                setSendingNext(true)
                try{
                    onUpdateAuthorizationHeader()
                    await mainServer.post(`/route/${routeID}/assign`, {delivery_man_id:route.delivery_man_id})
                    actions.onRefreshData()
                }catch(e){
                    console.log(e)
                    onUpdateError(onGetErrorMessage(e))
                }
                setSendingNext(false)
            }
            if(route.route_status_id === ROUTE_STATUSES.ASSIGNED){
                //console.log(route.delivery_man_id)
                setSendingNext(true)
                try{
                    onUpdateAuthorizationHeader()
                    await mainServer.post(`/route/user/${route.delivery_man_id}/${routeID}/prepare`)
                    actions.onRefreshData()
                    //actions.onGetOrdersCarts()
                }catch(e){
                    console.log(e)
                    onUpdateError(onGetErrorMessage(e))
                }
                setSendingNext(false)
            }
            if(route.route_status_id === ROUTE_STATUSES.PREPARING){
                //console.log(route.delivery_man_id)
                actions.onUpdateModalStatus('warning', true)
            }
        },
        onUpdateRouteStatusBack:async() => {      
            if(route.route_status_id === ROUTE_STATUSES.ASSIGNED){
                setSendingBack(true)
                try{
                    onUpdateAuthorizationHeader()
                    await mainServer.patch(`/route/${routeID}`, {route_status_id:ROUTE_STATUSES.PENDING})
                    actions.onRefreshData()
                }catch(e){
                    console.log(e)
                    onUpdateError(onGetErrorMessage(e))
                }
                setSendingBack(false)
            }
            if(route.route_status_id === ROUTE_STATUSES.PREPARING){
                setSendingBack(true)
                try{
                    onUpdateAuthorizationHeader()
                    await mainServer.patch(`/route/${routeID}`, {route_status_id:ROUTE_STATUSES.ASSIGNED})
                    actions.onRefreshData()
                }catch(e){
                    console.log(e)
                    onUpdateError(onGetErrorMessage(e))
                }
                setSendingBack(false)
            }
        },
        onCancelRoute: async() =>{
            const errors = isFormValid(cancel_form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(cancel_form, errors)
                setCancelForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetFormData(cancel_form)
            console.log(data2send)

            try{
                onUpdateAuthorizationHeader()
                await mainServer.post(`/route/user/${route.delivery_man_id}/${routeID}/cancel`, data2send)
                actions.onRefreshData()
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
        },
        onAddRouteorder: async(_order_id) =>{
            //actions.onUpdateModalStatus('loading', true)
            console.log(number)
            try{
                const data2send = {
                    route_id: routeID,
                    order_id:_order_id,
                    number:number
                }
                onUpdateAuthorizationHeader()
                await mainServer.post(`/routeorder`, data2send)
                actions.onInitRouteOrders()
                setNumber(number + 1)
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
            //actions.onUpdateModalStatus('loading', false)
        },
        /* onGetOrdersCarts: async() =>{
            let _ordercarts = []
            console.log('get orders carts')
            try{
                Promise.all(routeorders.map(async (item, index) => {
                    const _order = await mainServer.get(`/order/${item.order_id}`)
                    _ordercarts.push(_order.data.data.order.cart)
                }))
                console.log(_ordercarts)
                setOrdercarts(_ordercarts)
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
        }, */
        onDeleteRouteorder: async(_id) => {
            try{
                onUpdateAuthorizationHeader()
                await mainServer.delete(`/routeorder/${_id}`)
                actions.onInitRouteOrders()
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
        },
        onUpdateRouteorder: async(id, data) => {
            try{
                onUpdateAuthorizationHeader()
                const req = await mainServer.patch(`/routeorder/${id}`, data)
                //actions.onRefreshData()
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }
        },
        onUpdatePagination: (value) => setPagination (value),
        onChangeSearch: (value) => setSearch(value),
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value})

    }


    const system = {loading, sending_next, sending_back, error, rloading }
    const view_data = {route, orders, total, search, pagination, routeorders, ordercarts, cancel_form, endDate, startDate}


    return {system, view_data, actions, modals, form}

}

export default useRoutesView

const form_data = {
    delivery_man_id:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config:{
            id:'delivery_man_id',
            type:'select',
            fullWidth: true,
            placeholder:'Seleccione un usuario'
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
    /*username:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'username',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },*/
}

const form_cancel_route = {
    cancellation_motive_id:{
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[...catalogs.route_order_issues],
        config:{
            id:'cancellation_motive_id',
            type:'select',
            fullWidth: true,
            helperText:'Debe seleccionar una opción'
        },
        rules:{
            type:'select',
        }
    },
    cancellation_motive_details:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'cancellation_motive_details',
            type:'text',
            fullWidth: true,
            helperText:'Campo requerido'
        },
        rules:{
            type:'distance',
            min:1, max:254
        }
    },
}