import email from '../../assets/icons/warehouses/email.svg'
import phone from '../../assets/icons/warehouses/phone.svg'
import truck from '../../assets/icons/warehouses/truck.svg'
import plane from '../../assets/icons/warehouses/plane.svg'
import local from '../../assets/icons/warehouses/local.svg'
import none from '../../assets/icons/warehouses/none.svg'
import agency from '../../assets/icons/warehouses/agency.svg'


export const translations = {
    //title:'Sucursales',
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Nombre'},
            {id:3, label:'Nombre de contacto'},
            {id:4, label:'Email'},
            {id:5, label:'Teléfono'},
            {id:6, label:'Dirección'},
            {id:7, label:''},
        ],
        nodata:'No existen sucursales registradass'
    },
    modal: {
        add_title:'Agregar',
        subtitle:'Por favor ingresa los datos solicitados',
        title2:'Información de la dirección',
        title3:'Servicios',
        button:'Agregar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_name:{
                label:'Nombre de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_email:{
                label:'Email',
                helperText:'Email no válido'
            },
            contact_phone:{
                label:'Telefono de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            street:{
                label:'Calle',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            ext:{
                label:'Número exterior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            int:{
                label:'Número interior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            additional_indications:{
                label:'Indicaciones adicionales',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            zip_code:{
                label:'Código postal',
                helperText:'Código postal no válido'
            },
            municipality_id:{
                label:'Municipio',
                helperText:'Debe seleccionar una opción',
            },
            state_id:{
                label:'Estado',
                helperText:'Debe seleccionar una opción'
            },
            country_id:{
                label:'País',
                helperText:'Debe seleccionar una opción'
            },
            suburb_id:{
                label:'Colonia',
                helperText:'Debe seleccionar una opción'
            },
            latitude:{
                label:'Latitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            longitude:{
                label:'Longitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            store:'Productos en tienda',
            pickup:'Entrega en tienda',
            delivery:'Entrega a domicilio'
        }
    },

    //new
    title:'Sucursales',
    success_msg:'Acción exitosa',
    menu:[{id:1, label:'Locales'}, {id:2, label:'Cobertura'}],
    views:{
        local:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Sucursal'},
                    {id:3, label:'Estado'},
                    {id:4, label:'Nombre de contacto'},
                    {id:5, label:'Email'},    
                    {id:6, label:'Teléfono'},
                    {id:7, label:'Dirección'},
                    //{id:8, label:''},
                ],
                nodata:'No existen sucursales registradas',
                search_bar_label:'Buscar en sucursales...',
                empty:agency,
                email:email,     
                phone:phone,             
            },
            modal:{
                add:{
                    title:'Nueva sucursal',
                    subtitle:'Por favor ingresa los datos solicitados',
                    title2:'Información de la dirección',
                    title3:'Servicios',
                    button:'Crear'
                },
                edit:{
                    title:'Editar código',
                    button:'Guardar'
                },
                form:{
                    name:{label:'Nombre', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    contact_name:{label:'Nombre de contacto', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    contact_email:{label:'Email', helperText:'Email no válido'},
                    contact_phone:{label:'Telefono de contacto', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    street:{label:'Calle', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    ext:{label:'Número exterior', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    int:{label:'Número interior', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    additional_indications:{label:'Indicaciones adicionales', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    zip_code:{label:'Código postal', helperText:'Código postal no válido'},
                    municipality_id:{label:'Municipio', helperText:'Debe seleccionar una opción',},
                    state_id:{label:'Estado', helperText:'Debe seleccionar una opción'},
                    country_id:{label:'País', helperText:'Debe seleccionar una opción'},
                    suburb_id:{label:'Colonia', helperText:'Debe seleccionar una opción'},
                    latitude:{label:'Latitud', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                    longitude:{label:'Longitud', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                },
                formControl:{
                    store:'Productos en tienda',
                    pickup:'Entrega en tienda',
                    delivery:'Entrega a domicilio'
                }
            }
        },
        coverage:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Estado'},
                    {id:3, label:'Ciudad'},
                    {id:4, label:'C.P'},
                    {id:5, label:'Sucursal'},    
                    {id:6, label:'Envío'},
                    {id:7, label:''},
                ],
                nodata:'No existen sucursales registradas',
                search_bar_label:'Buscar en sucursales...',
                empty:agency,
                plane:plane,
                agency:agency,
                truck:truck,
                none:none,
                local:local,
            },
            modal:{
                add:{
                    title:'Nueva cobertura',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar cobertura',
                    button:'Guardar'
                },
                form:{
                    zip_code:{label:'Código postal', helperText:'Código postal no válido'},
                    municipality_id:{label:'Municipio', helperText:'Debe seleccionar una opción',},
                    state_id:{label:'Estado', helperText:'Debe seleccionar una opción'},
                    warehouse_id:{label:'Sucursal', helperText:'Debe seleccionar una opción'},
                    shipping_type_id:{label:'Envío', helperText:'Debe seleccionar una opción'},
                }
            }
        },
    }
}
