import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { isValidDocument } from '../../../../shared/utility'
import { config_data, USER_TYPES } from '../../../../variables/config'
import user_icon from '../../../../assets/icons/users/user_icon.svg'
import role_icon from '../../../../assets/icons/roles/settings_icon.svg'



const useStylesIconCell = makeStyles((theme) => ({
	icon_container: {
        width: 36,
        height: 36,
        background: '#F4F5FE',
        borderRadius: 25,
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
		//
		padding:4,
		boxSizing:'border-box'
    },
    icon:{
        color: theme.palette.primary.main,
		//
		width:'100%',
		height:'100%',
		objectFit:'contain'
    },
	img:{
		width: 36,
		height: 36,
		borderRadius: '25px'
	},
    avatar:{
        width:35,
        height:35,
    },
}));


const CellImg = ({ type, src}) => {
	const classes = useStylesIconCell();

	switch (type) {
		case 'img':
			let img_content = <img src={user_icon} className={classes.icon} />;

			const valid_image = isValidDocument(src);
			if (valid_image) {
				img_content = <Avatar src={`${config_data.S3_SERVER_URL}${src}`} className={classes.avatar} />;
			}

			return <div className={classes.icon_container}>{img_content}</div>;

		case 'role':
			let img_role = <img src={role_icon} className={classes.icon} />;

			const valid_role = isValidDocument(src);
			if (valid_role) {
				img_role = <img src={`${config_data.S3_SERVER_URL}${src}`} className={classes.icon} />;
			}

			return <div className={classes.icon_container}>{img_role}</div>;

		case 'icon':
			return (
				<div className={classes.icon_container}>
					<img src={src} className={classes.icon} />
				</div>
			);
	}
};

export default CellImg