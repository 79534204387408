
import { Grid, LinearProgress } from "@mui/material";
import HolidaysMonth from './components/HolidaysMonth';
import useLocalTime from './useLocalTime';
import SpecialEventsMonth from './components/SpecialEventsMonth';
import AddSpecialDayModal from "./modals/AddSpecialDayModal";
import DeleteSpecialDayModal from "./modals/DeleteSpecialDayModal";
import AddHolidayModal from "./modals/AddHolidayModal";
import DeleteHolidayModal from "./modals/DeleteHolidayModal";
import CalendarComponent from "./components/CalendarComponent";



const LocalTime = ({catalogs, content, id}) => {

    const {system, modals, actions, view_data} = useLocalTime({content, id})

    return(
        <div>
            <AddSpecialDayModal open={modals.add_special_event} onClose={()=>actions.onChangeModalStatus('add_special_event', false)} content={content.special_event_modal} onUpdateCompleted={actions.onInitModule} 
                id={id} catalogs={catalogs}/>
            <AddHolidayModal open={modals.add_holiday} onClose={()=>actions.onChangeModalStatus('add_holiday', false)} content={content.holiday_modal} onUpdateCompleted={actions.onInitHolidays} 
                id={id} />
            <DeleteSpecialDayModal open={modals.delete_special_event} onClose={()=>actions.onChangeModalStatus('delete_special_event', false)} onUpdateCompleted={actions.onInitModule} id={id} 
                special_event_id={view_data.selected} />
            <DeleteHolidayModal open={modals.delete_holiday} onClose={()=>actions.onChangeModalStatus('delete_holiday', false)} onUpdateCompleted={actions.onInitHolidays} id={id} 
                holiday_id={view_data.selected} />
            
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CalendarComponent title={content.attention_schedule} slots={view_data.calendar_data} slot_time={view_data.slot_time} onUpdateSlots={actions.onUpdateSlots} 
                        sending={system.sending} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <SpecialEventsMonth title={content.special_dates} data={view_data.special_events} catalogs={catalogs} 
                    onChangeFilter={actions.onUpdateFilterStatus} filter={view_data.filters.event_year} onAdd={()=>actions.onChangeModalStatus('add_special_event', true)} 
                    onDelete={actions.onDeleteSpecialEvent} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <HolidaysMonth title={content.holidays} data={view_data.holidays} catalogs={catalogs} onAdd={()=>actions.onChangeModalStatus('add_holiday', true)} 
                    onChangeFilter={actions.onUpdateFilterStatus} filter={view_data.filters.holiday_year} onDelete={actions.onDeleteHoliday} />
                </Grid>
            </Grid>
        </div>
    )
}

export default LocalTime