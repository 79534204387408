import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import { TextField, Icon, Typography, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mainServer, USER_TYPES } from '../../../../variables/config';
import { onGetNameCatalogs, onUpdateAuthorizationHeader } from '../../../../shared/utility';
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName';
import { grey } from '@mui/material/colors';
import nouser from '../../../../assets/icons/user.svg'

const DeliverManModal = ({open, onClose, origin, onSubmit, warehouse_id}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState()
    const [options, setOptions] = useState([])
    const [filtered_options, setFilteredOptions] = useState([])
    const [selected, setSelected] = useState(null)

    const onSelectedItem = (data) =>{
        onSubmit(data)
        onClose()
    }

    useEffect(() => {
        const initModule = async() => {
            try {
                setLoading(true)
                let filter = {"AND":[{"field":"warehouse_id","operator":"=", "value":warehouse_id}, 
                                    //{"field":"main_role_id","operator":"=", "value":6}
                                ]}
                onUpdateAuthorizationHeader()
                let _devman_request = await mainServer.get(`/warehouseuser/list?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                console.log(_devman_request.data.data.warehouse_users)
                setOptions([..._devman_request.data.data.warehouse_users])
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        if(open) initModule()
    }, [open])

    useEffect(() => {
        let _options = [...options]
        if(search){
            _options = _options.filter(item => {
                //console.log(item)
                //const fullname = `${item.first_name ? item.first_name : ''} ${item.fathers_last_name ? item.fathers_last_name : ''} ${item.mothers_last_name ? item.mothers_last_name : ''}`
                return item.user.toLowerCase().includes(search)
            })
            
        }
        setFilteredOptions(_options)
    }, [search, options])

    return (
        <SimpleModal open={open} onClose={onClose} >
            <div style={{marginTop:16}}>
                <Typography>Seleccionar repartidor</Typography>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder='Ingrese el nombre del repartidor...'
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    InputProps={{
                        endAdornment:<Icon>search</Icon>
                    }} />
                <div className={classes.container}>
                    {loading ? (
                        <Grid container direction='column' alignItems='center'>
                            <Grid item>
                                <CircularProgress size={40} />
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>Cargando...</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>
                            <Grid container>
                                {filtered_options.map(item => {

                                    //const fullname = `${item.first_name ? item.first_name : ''} ${item.fathers_last_name ? item.fathers_last_name : ''} ${item.mothers_last_name ? item.mothers_last_name : ''}`

                                    return(
                                        <Grid item xs={12}>
                                            <div className={classes.list_item} onClick={()=>onSelectedItem({delivery_man_id:item.user_id})}>
                                                <UserAvatarName name={item.user} image={item.user_image} noimage={nouser} />
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        </SimpleModal>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        marginTop:32,
        maxHeight:300,
        overflowY:'auto',
        overflowX:'hidden'
    },
    list_item:{
        padding:'12px 4px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:grey[100]
        }
    }
}))

export default DeliverManModal;