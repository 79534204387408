import React from 'react'
//import { makeStyles, Button, fade, CircularProgress, Fade, Grow, Grid, alpha } from '@material-ui/core'
//import { blueGrey, grey, red } from '@material-ui/core/colors'
import { Button, fade, CircularProgress, Fade, Grow, Grid, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames'
import { grey, red } from '@mui/material/colors';

const useStyles = makeStyles(theme => ({
    root:{
        background:theme.palette.primary.main,
        fontFamily:theme.typography.fontFamily,
        textTransform:'none',
        padding:'12px 28px',
        borderRadius:32,
        color:'white',
        fontSize:'1rem',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        '&:disabled':{
            background:grey[300],
            color:grey[500],
            cursor:'not-allowed'
        }
    },
    root_small:{
        padding:'8px 20px',
        fontSize:'1rem',
    },
    progress:{
        color:'white',
        marginLeft:8
    },
    primary:{
        background:theme.palette.primary.main,
        '&:hover':{background:theme.palette.primary.dark}
    },
    secondary:{
        background:theme.palette.secondary.main,
        '&:hover':{background:theme.palette.secondary.dark}
    },
    secondary_dark:{
        background:'#D95D71',
        '&:hover':{background:theme.palette.secondary.dark}
    },
    red:{
        background:red[700],
        '&:hover':{background:red[900]}
    },
    grey:{
        background:grey[300],
        '&:hover':{background:grey[400]},
        color:grey[900],
    },
    white:{
        color:grey[900],
        background:'white',
        '&:hover':{background:alpha('#000',0.025)}
    },
    white_primary:{
        color:theme.palette.primary.main,
        border:`1px solid ${theme.palette.primary.main}`,
        background:'white',
        '&:hover':{background:alpha('#000',0.025)}
    },
    white_secondary_dark:{
        color:'#D95D71',
        border:`1px solid #D95D71`,
        background:'white',
        '&:hover':{background:alpha('#000',0.025)}
    },
    loading_container:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        padding:'8px 11px',
        position:'relative'
    },
    progress:{
        color:'white',
        marginTop:2
    },
}))

const ContainedButton = ({loading, children, color, onClick, fullWidth, disabled, size, endIcon, startIcon}) => {

    const classes = useStyles()



    return(
        <div>
            {!loading ? <Grow in timeout={500}>
            <Button onClick={!loading ? onClick : undefined} fullWidth={fullWidth} disabled={disabled} endIcon={endIcon} startIcon={startIcon}
                className={cx({
                    [classes.root]:true, 
                    [classes.root_small]:size==='small',
                    [classes.secondary]: color==='secondary',
                    [classes.red]: color==='red',
                    [classes.white]: color==='white',
                    [classes.white_primary]: color==='white_primary',
                    [classes.grey]: color==='grey',
                    [classes.secondary_dark]: color==='secondary_dark',
                    [classes.white_secondary_dark]: color==='white_secondary_dark',
                })}>
                        {children}
                </Button>
            </Grow> : (
                <Fade in timeout={500}>
                    <Grid container justifyContent='center'>
                        <Grid item>
                            <div className={classes.loading_container}>
                                <CircularProgress size={32} className={classes.progress}/>
                            </div>
                        </Grid>
                    </Grid>
                    
                </Fade>
            )}
        </div>
    )
}

export default ContainedButton