import mp_icon from '../../assets/icons/topbar/user_icon.svg'
import lo_icon from '../../assets/icons/topbar/logout_icon.svg'

export const translations={
    user_button:{
        my_profile:'Mi perfil',
        my_profile_icon: mp_icon,
        log_out:'Cerrar sesión',
        log_out_icon: lo_icon,
        admin:'Administrador',
        doctor:'Doctor',
        patient:'Paciente'
    },
    
}