import React from "react";
import { Drawer, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import UserAvatar from "./components/UserAvatar";
import { isCompatibleURL } from "../../../shared/utility";
import SidebarButton from "./components/SidebarButton";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sidebar_settings } from "../../../texts/esp/sidebar-translations";
import Scrollbars from "react-custom-scrollbars";
import SVGIconOutlined from '../DisplayData/SVGIconOutlined';

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.components.sidebar,
		boxShadow: 'border-box',
		margin: 16,
		borderRadius: 24,
		paddingBottom: 40,
		border: 0,
		[theme.breakpoints.down('sm')]: {
			margin: 0,
			borderRadius: '0px 24px 24px 0px',
			height: '100%',
		},
		overflow: 'hidden',
	},
	drawer: {
		background: 'transparent',
		border: 0,
	},
	subroot: {
		padding: 8,
	},
	container: {
		//border:'1px solid white',
		marginTop: 32,
		overflowY: 'auto',
		overflowX: 'hidden',
		width: 256,
		height: 'calc(100vh - 180px)',

		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	//Sidebar visual update: Diego
	containerShifted: {
		//border:'1px solid white',
		marginTop: 32,
		overflowY: 'auto',
		overflowX: 'hidden',
		width: 100,
		height: 'calc(100vh - 180px)',

		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
}));

const Sidebar = (props) => {
	const classes = useStyles();
	const { history, user } = props;

	const onSelectedURL = (url) => history.push(url);

	return (
		<Drawer
			//className={classes.drawer}
			classes={{
				paper: classes.drawer,
			}}
			style={{ border: 0 }}
			anchor='left'
			variant={props.variant}
			open={props.open}
			onClose={props.closeSide}
		>
			<div className={classes.root}>
				<div className={classes.subroot}>
					<div style={{ marginTop: 16 }}>
						<Grid container justifyContent='center'>
							<Grid item>
								<SVGIconOutlined src={sidebar_settings.logo} size={48} color={'white'} />
							</Grid>
						</Grid>
					</div>

					<div className={props.isShifted || props.isMobile ? classes.containerShifted : classes.container}>
						<Scrollbars height='100%'>
							<div style={{ padding: 8 }}>
								<Grid container direction='column' spacing={1}>
									{sidebar_settings.list.map((item) => {
										let enabled = true;
										if (item.permissions.length && user) {
											const findpermission = item.permissions.find((el) => el === user.user_type_id);

											if (!findpermission) {
												//console.log('------------------')
												//console.log(item)
												//console.log(findpermission)
												enabled = false;
											}
										}
										if (!enabled) return null;
										//console.log(item.name)
										return (
											<Grid item  key={item.url}>
												<SidebarButton {...item} isShifted={props.isShifted} isMobile={props.isMobile} selected={isCompatibleURL(history, item.url)} onSelectedURL={onSelectedURL} />
											</Grid>
										);
									})}
								</Grid>
							</div>
						</Scrollbars>
					</div>
				</div>
			</div>
		</Drawer>
	);
};

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(Sidebar))
