import XLSX from 'xlsx'
import FileSaver from 'file-saver'

export const s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}

export const onGenerateExcelFile = (data, name, settings = {}) => {
    if(!data) return
    let wb = XLSX.utils.book_new();

    const _wbprops = settings?.wb_props

    wb.Props = {
        Title: "Report",
        Subject: "General Data",
        Author: "Celu Health",
        CreatedDate: new Date(),
        ..._wbprops
    };
    wb.SheetNames.push(name);
    const temp = XLSX.utils.aoa_to_sheet(data)
    wb.Sheets[name] = temp;
    let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),`${name}.xlsx`);
}