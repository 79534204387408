import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SelectForm from '../../../../components/Forms/SelectForm'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import { green } from '@mui/material/colors'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputDateForm from '../../../../components/Forms/InputDateForm'
import moment from 'moment'
import useBatches from '../../../../hooks/useBatches'
import InputSelectForm from '../../../../components/Forms/InputSelectForm';
import { private_server } from '../../../../variables/config';


const AddBatchModal = props => {

    const { open, onClose, onCompleted, warehouse, product } = props

    const classes = useStyles()
    const batchManager = useBatches()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks

    useEffect(async() => {
        if (!open) {
            
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            updateAuthorizationHeader(private_server)
            const providers = await private_server.get(`provider/list`)
            const cat = onGetCatalogs(providers.data.data.providers, 'id_provider', 'name')
            let _form = onInitEmptyForm(formData, null)
            _form.provider_id.options = cat
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.warehouse_id = warehouse?.id_warehouse || warehouse?.value
        data2send.product_id = product?.id_product || product?.product_id
        data2send.enabled = 1
        data2send.reception_date = moment(data2send.reception_date).utc().format('YYYY-MM-DD HH:mm:ss')
        data2send.expiry_date = moment(data2send.expiry_date).utc().format('YYYY-MM-DD HH:mm:ss')
        data2send.package = '-'
        console.log(data2send)
        //console.log(data2send)

        setLoading(true)
        try {
            await batchManager.actions.onCreate(data2send)
            onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar lote</DisplayText>
                <DisplayText >Favor de ingresar los datos solicitados. </DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.number} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.initial_quantity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputSelectForm data={form.provider_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.provider_cost} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            {/* <InputDateForm data={form.reception_date} onChange={onChange} /> */}
                            <InputForm data={form.reception_date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.expiry_date} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddBatchModal

const useStyles = makeStyles(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    }
}))

const formData = {
    number: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'number',
            type: 'text',
            fullWidth: true,
            label: 'No. de lote',
            placeholder: 'No. de lote',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    expiry_date: {
        value: moment().add(1,'year').format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'expiry_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de caducidad',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    reception_date: {
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'reception_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de recepción',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    initial_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'initial_quantity',
            type: 'number',
            fullWidth: true,
            label: 'Cantidad recibida',
            placeholder: 'Cantidad',
            helperText: 'Este campo debe de ser mayor a 0'
        },
        rules: {
            type: 'numeric',
            min: 1, max: 9999
        }
    },
    provider_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'provider_id',
            type: 'select',
            fullWidth: true,
            label: 'Proveedor',
            placeholder: 'Cantidad',
            helperText: 'Este campo debe de ser mayor a 0'
        },
        rules: {
            type: 'select',
            //min: 1, max: 9999
        }
    },
    provider_cost: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'provider_cost',
            type: 'number',
            fullWidth: true,
            label: 'Precio de compra',
            placeholder: 'Cantidad',
            helperText: 'Este campo debe de ser mayor a 0'
        },
        rules: {
            type: 'numeric',
            min: 1, max: 9999
        }
    },
}