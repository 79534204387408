import React from 'react';
import {
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetFullname } from '../../../../shared/utility';
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar';
import user_icon from '../../../../assets/icons/users/user_icon.svg';
import { tableStyles } from '../../../../styles/tableStyles';
import NewActionBar from '../../../../components/Actions/NewActionBar';
import { nodata } from '../../../../variables/config';
import CellImg from './CellImg';

const SimpleTable = (props) => {
	const classes = tableStyles();
	const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading } = props;

	const header = content.table.header;

	return (
        <div>
			<div className={classes.toolbar}>
				<Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end'>
					<Grid item>
						<Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
							<Grid item>
								<SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
							</Grid>
							<Grid item>
								<IconButton className={classes.button} onClick={onAddRegister} size="large">
									<Icon>add</Icon>
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</div>
			{loading ? (
				<Grid container justifyContent='center'>
					<Grid item xs={12}>
						<LinearProgress />
					</Grid>
				</Grid>
			) : null}
			<Card className={classes.paper}>
				{data && data.length ? (
					<div>
						<TableContainer className={classes.table_container}>
							<Table stickyHeader className={classes.table}>
								{header ? (
									<TableHead>
										<TableRow>
											{header.map((item) => {
												return (
													<TableCell style={item.id === 1 ? { paddingLeft: 30 } : {}} className={classes.cellHeader} key={item.id.toString()}>
														<DisplayText variant='caption'>{item.label}</DisplayText>
													</TableCell>
												);
											})}
										</TableRow>
									</TableHead>
								) : null}
								<TableBody>
									{data.map((item, key) => {
										const { id_role, name, icon, description, color} = item;

										return (
											<TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(id_role)}>
												<TableCell className={classes.cell} style={{ paddingLeft: 30 }}>
													<DisplayText variant='caption'>{id_role ? id_role : nodata}</DisplayText>
												</TableCell>
												<TableCell className={classes.cell}>
													<Grid container alignItems='center'>
														<DisplayText variant='caption'>{name ? name : nodata}</DisplayText>
													</Grid>
												</TableCell>
												<TableCell className={classes.cell}>
													<Grid container alignItems='center'>
														<CellImg type='role' src={icon} />
													</Grid>
												</TableCell>
												<TableCell className={classes.cell}>
													<Grid container alignItems='center'>
														{/* <CellImg type='role' src={role_icon} /> */}
														<DisplayText variant='caption'>{description ? description : nodata}</DisplayText>
													</Grid>
												</TableCell>
												<TableCell className={classes.cell}>
													<Grid container alignItems='center'>
														<CellImg type='color' src={color}/>
														<DisplayText variant='caption'>{color ? color : nodata}</DisplayText>
													</Grid>
												</TableCell>
												<TableCell className={classes.cell}>
													<Grid container alignItems='center'>
                                                        <CellImg type='status' src={item.default} />
														<DisplayText variant='caption'>{item.default === 1 ? content.table.default.yes : content.table.default.no}</DisplayText>
													</Grid>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</div>
				) : null}
			</Card>
			{data && data.length ? (
				<div className={classes.actions}>
					<NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
				</div>
			) : null}
			{data.length === 0 && !loading ? (
				<div className={classes.nodata}>
					<Grid container justifyContent='center' alignItems='center' direction='column'>
						<Grid item>
							<img src={user_icon} alt='' width={180} />
						</Grid>
						<Grid item>
							<DisplayText>{content.nodata}</DisplayText>
						</Grid>
					</Grid>
				</div>
			) : null}
		</div>
    );
};

export default SimpleTable;
