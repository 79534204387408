import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import DeleteOrderModal from './modals/DeleteOrderModal'
import useOrders from './useOrders'
import system_translations from '../../../texts/system_translations'
import { actionTypes } from '../../../store/actions'


const Orders = props => {

    const { history, language, orders_table, orders_search, orders_filters, orders_startDate, orders_endDate, onUpdateTable,
        onUpdateSearch, onUpdateFilters, onUpdateStartdate, onUpdateEnddate } = props
    const content = system_translations[language].views.orders
    const {system, modals, actions, view_data} = useOrders({history, orders_table, orders_search, orders_filters, orders_startDate, orders_endDate, onUpdateFilters, onUpdateStartdate, 
        onUpdateEnddate, onUpdateTable})
    const {data, total, search, tableFilter, selected} = view_data

    /* const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [orders, setOrders] = useState([])
    
    //table
    const [totalItems, setTotalItems] = useState(10)
    const [search, setSearch] = useState(null)
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'ordered_at',
    })

    //modals
    const [add_order_modal, setAddOrderModal] = useState(false)
    const [delete_order_modal, setDeleteOrderModal] = useState(false)
    const [add_modal, setAddModal] = useState(false)
    const [selected_product, setSelectedProduct] = useState(null)

    //filters
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const [status, setStatus] = useState(-1)

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            await onGetListItems()
        } catch (error) {
            console.log(error)
        }setLoading(false)}  
        
        initModule()
    }, [search, tableFilter, user, startDate, endDate])

    const onGetListItems = async() => {
        try {
            if(user){
                let _orders = []
                const params = onGetParamsFromState(tableFilter, search)
                //params.start_date = startDate
                //params.end_date = endDate
                //nuevo
                //if(status !== -1) params.status_id = status 
                _orders = await request_orders(params)
                console.log(_orders)
                setOrders(_orders.orders)
                setTotalItems(_orders.count)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const onSelectedItem = (item) => {
        const {id_order} = item
        history.push(`/orders/${id_order}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }


    const onDeleteItem = (data) => {
        setSelectedProduct(data)
        setDeleteOrderModal(true)
    }

    const onUpdateCompleted = async() => {
        try {
            await onGetListItems()
            setDeleteOrderModal(false)
        } catch (error) {
            console.log(error)
        }
    }

    const onUpdateOrder = async(id, data) => {
        try {
            setLoading(true)
            //console.log(data)
            //console.log(id)
            await request_update_order(id, data)
            let _users = []
            console.log(user._id)
            const params = onGetParamsFromState(tableFilter, search)
            params.start_date = startDate
            params.end_date = endDate
            //nuevo
            //let params = {limit:tableFilter.limit, offset:tableFilter.offset*tableFilter.limit, order_by:'created_at', order:-1, start_date:startDate, end_date:endDate, query:filter.query, status_id:status}
            if(status !== -1) params.status_id = status
            if(user.user_type_id === USER_TYPES.ADMIN) _users = await request_orders(params)
            if(user.user_type_id === USER_TYPES.OPTICAL) _users = await request_orders({...params, optical_id:user._id})
            if(user.user_type_id === USER_TYPES.OPTOMETRIST) _users = await request_orders({...params, optical_id:user.optical_id}) 
            console.log(_users.data)
            setOrders(_users.data)
            setTotalItems(_users.count)
            //const _order = await request_order(order_id)
            //setOrder(_order)

        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onChangeDate = (value, id) => {
        let aux
        if(id === 'start_date'){
            aux = moment(value)
            console.log()
            if(moment(endDate).diff(aux) > 0) setStartDate(value)
        }
        if(id === 'end_date'){
            aux = moment(value)
            if(aux.diff(moment(startDate)) > 0) setEndDate(value)
        }
    }

    const onGenerateReport = async() => {
        try {
            setSending(true)
            const params = onGetParamsFromState(tableFilter, search)
            params.start_date = startDate
            params.end_date = endDate
            delete params.query; delete params.limit; delete params.offset;
            if(status !== -1) params.status_id = status
            //let _users = []
            let _users = await request_orders_report(params)
            console.log(_users.data)
            const excel_data = onCleanData2Excel(_users.data)
            console.log(excel_data)
            const sheets = [
                {name:'Reporte', data:excel_data}
            ]
    
            let wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Ubelens Report",
                Subject: "Detailed Data",
                Author: "Trionix Technologies",
                CreatedDate: new Date()
            };
    
            for(let i=0; i<sheets.length; i++){
                wb.SheetNames.push(sheets[i].name);
                const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
                wb.Sheets[sheets[i].name] = temp;
            }
    
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'report.xlsx');
        } catch (error) {
            
        }
        setSending(false)

    }


    let can_add = true

    let report_available = false
    if(user && user.user_type_id === USER_TYPES.ADMIN) report_available = true */

    return(
        <Page title={content.title} >
            <DeleteOrderModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} onUpdateCompleted={actions.onInitModule} 
                selected_item={selected} />
            {/* <AddOrderModal history={history} user={user} open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} /> */}
            <SimpleTable
                content={content.table}
                data={data} 
                search={orders_search}
                tableFilter={orders_table}
                total={total}
                loading={system.loading}
                filters={orders_filters}
                onChangeFilters={actions.onChangeFilters}
                corte={view_data.corte}
                endDate={orders_endDate}
                startDate={orders_startDate}
                settings={view_data.settings}
                //onAddRegister = {()=>actions.onChangeModalStatus('add', true)}
                onChangeDate={actions.onChangeDate}
                onChangeSearch={onUpdateSearch}
                onUpdateTableFilter={onUpdateTable}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}   
                onGenerateReport={actions.onGenerateReport} 
                onSettingSelected={actions.onSettingSelected}        
            />
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        language: state.language,
        orders_table: state.orders_table,
        orders_search: state.orders_search,
        orders_filters: state.orders_filters,
        orders_startDate: state.orders_startDate,
        orders_endDate: state.orders_endDate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateTable: (orders_table) => dispatch({type: actionTypes.CHANGE_ORDERS_TABLE, orders_table }),
        onUpdateSearch: (orders_search) => dispatch({type: actionTypes.CHANGE_ORDERS_SEARCH, orders_search }),
        onUpdateFilters : (orders_filters) => dispatch({type: actionTypes.CHANGE_ORDERS_FILTERS, orders_filters }),
        onUpdateStartdate: (orders_startDate) => dispatch({type: actionTypes.CHANGE_ORDERS_STARTDATE, orders_startDate }),
        onUpdateEnddate : (orders_endDate) => dispatch({type: actionTypes.CHANGE_ORDERS_ENDDATE, orders_endDate }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Orders)