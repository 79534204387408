import { Grid } from '@mui/material'
import React from 'react'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import SelectAutocompleteForm from '../../../../../../components/Forms/SelectAutocompleteForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import useAddSubstanceModal from './useAddSubstanceModal'


const AddSubstanceModal = ({open, onClose, onUpdateCompleted, content, id}) => {

    const {loading, error, form, actions, options} = useAddSubstanceModal({open, content, onClose, id, onUpdateCompleted})

    let sub_form = {...form.substance_id}
    sub_form.options = [...options]

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add.title}</DisplayText>
                {/* <DisplayText >Por favor ingresa los datos solicitados</DisplayText> */}
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectAutocompleteForm data={sub_form} onChange={actions.onChangeForm}
                                onInputChange={actions.onSearchSubstance} />
                        </Grid>             
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={actions.onSubmit} loading={loading} disabled={loading}>
                    {content.add.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddSubstanceModal
