import React, { useEffect, useState } from "react";
import {
    Tabs,
    Tab,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Typography,
    TableBody,
    IconButton,
    Icon,
    LinearProgress,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { grey } from "@mui/material/colors";
import catalogs from "../../../../../../texts/esp/catalogs";
import moment from 'moment'
import { onGetDateFormat } from "../../../../../../shared/utility";
//import "./styles.css";

export default function Prueba(props) {

  const classes = useStyles()

  const {data, onDeleteRouteorder, onUpdateRouteorder, loading} = props

  //console.log(data)

    const header = [
        {id:1, label:'ID'},
        {id:2, label:'Hora de entrega'},
        {id:3, label:'Usuario'},
        {id:4, label:'Estatus de pago'},
        {id:5, label:''},
    ]
  
    /* const [items, setItems] = useState([
        { id: 1, label: "One" },
        { id: 2, label: "Two" },
        { id: 3, label: "tres" },
        { id: 4, label: "cuatro" },
    ]); */

    const [items, setItems] = useState([...data]);

    useEffect(async() => {
        //console.log(data)
        Promise.all(data.map(async (item, index) => {
            await onUpdateRouteorder(item.id_route_order, {number:index+1})
        }))
        setItems([...data])
    }, [data])
    

    //console.log(items)

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list]
        const [removed] = result.splice(startIndex, 1);
        //console.log(removed)
        result.splice(endIndex, 0, removed);
    
        return result;
    };
  

    const onDragEnd = async(result) => {
        //console.log(result)
        if (!result.destination) return;
        
        const newItems = reorder(items, result.source.index, result.destination.index);
        setItems(newItems);

        /* newItems.forEach( (el, index) => {
            //console.log(index)
            onUpdateRouteorder(el.id_route_order, {number:index+1})
        }); */
        
        Promise.all(newItems.map(async (item, index) => {
            await onUpdateRouteorder(item.id_route_order, {number:index+1})
        }))

    };

    return (
        <div className={classes.root} >
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            {data && data.length ? (
                <div >
                    <TableContainer className={classes.table_container}>
                        <Table stickyHeader className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return (
                                                <TableCell padding="normal"
                                                //padding={item.id === 1 ? 'checkbox' : 'default'} 
                                                className={classes.cellHeader} key={item.id.toString()}>
                                                    <Typography  >{item.label}</Typography>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            {/* <TableBody> */}
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="list">
                                    {props => (
                                        <TableBody
                                        ref={props.innerRef}
                                        {...props.droppableProps}
                                        >
                                        {items.map(({ id_route_order, estimated_delivery_at, user, store_payment_status_id, schedule_time, order_id}, index) => (
                                            

                                            <Draggable
                                            key={id_route_order}
                                            draggableId={id_route_order.toString()} // must be a string
                                            index={index}
                                            //disableInteractiveElementBlocking={true}
                                            >
                                            {props => (
                                                

                                                <TableRow key={index.toString()} className={classes.row} ref={props.innerRef} {...props.draggableProps} {...props.dragHandleProps}>
                                                    <TableCell className={classes.cell} ><Typography variant='subtitle2'>{order_id}</Typography></TableCell>
                                                    <TableCell className={classes.cell} ><Typography variant='subtitle2'>{onGetDateFormat(schedule_time, 'DD MMM, h:mm a')}</Typography></TableCell>   
                                                    <TableCell className={classes.cell} ><Typography variant='subtitle2'>{user}</Typography></TableCell>         
                                                    <TableCell className={classes.cell} ><PaymentStatus id={store_payment_status_id} /></TableCell>                 
                                                    {/* <TableCell className={classes.cell} >
                                                        {payment_status ? <Typography variant='subtitle2' style={{color:payment_status.color}}>{payment_status.label}</Typography> : <Typography>-</Typography>}
                                                    </TableCell> */}  
                                                    <TableCell className={classes.cell} >
                                                        <IconButton
                                                            className={classes.delete_button}
                                                            onClick={()=>onDeleteRouteorder(id_route_order)}
                                                            size="large"><Icon>delete</Icon></IconButton>
                                                    </TableCell>                             
                                                </TableRow>
                                            )}
                                            </Draggable>
                                        ))}
                                        {props.placeholder}
                                        </TableBody>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                            {/* </TableBody> */}
                        </Table>
                    </TableContainer>
                </div>     
            ): null}
        </div>
    );
}

const PaymentStatus = (props) =>{

    const {id} = props
    const payment_status = catalogs.payment_statuses.find(el => el.value === id)

    return(
        <div>
            {payment_status ? <Typography variant='subtitle2' style={{color:payment_status.color}}>{payment_status.label}</Typography> : <Typography>-</Typography>}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{marginTop:32},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        //borderColor:grey[200],
        borderColor:'#EAEAEA'
        //padding:'20px 0px'
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        //borderColor:grey[300],
        borderBottom:'2px solid #EAEAEA',
        paddingTop:16,
        paddingBottom:16,
        background:'transparent',
        color:'#6A6A6A'
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:32
    },
    actions:{
        marginTop:40
    },
    table:{
        minWidth:700
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    delete_button:{
        color:'#D95D71',
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    },
    add_button:{
        color:theme.palette.primary.main,
        boxShadow:'0px 0px 2px rgba(0, 0, 0, 0.4)',
        width:40,
        height:40
    }
}))