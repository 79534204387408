import React from 'react'
import {
    Card,
    Grid,
    Icon,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { onGetCatalogById, onGetFormatNumber } from '../../../../shared/utility'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/empty.png'
import moment from 'moment-timezone'
import { nodata } from '../../../../variables/texts'
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus'
import {catalogs} from '../../../../texts/esp/catalogs'
import PaymentStatus from '../../../../components/Structure/DisplayData/PaymentStatus'
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'
import FilterButton from './FilterButton'
import RoundedButton from '../../../../components/Actions/RoundedButton'
import MonthFilter from '../../../../components/Actions/MonthFilter'
import MenuSettings from '../../../../components/Actions/MenuSettings'

//const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
//const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

/* const header = [
    {id:1, label:'ID'},
    {id:2, label:'Fecha de pedido'},
    {id:3, label:'Usuario'},
    {id:4, label:'Farmacia'},
    {id:5, label:'Tipo de orden'},
    {id:6, label:'Total'},
    {id:7, label:'Estatus de orden'},
    {id:8, label:'Estatus de pago'},
    //{id:9, label:''},
] */


/* const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    reportButton:{
        background:theme.palette.primary.main,
        color:'white',
        padding:'10px 16px',
        borderRadius:32,
        '&:hover':{
            background:theme.palette.primary.dark,
        }

    }
})) */

const SimpleTable = props => {

    const classes = tableStyles()
    const { data, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, content, filters, onChangeFilters,
    startDate, endDate, onGenerateReport, onChangeDate, settings, onSettingSelected } = props
    const header = content.header
    
    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container  spacing={2} alignItems='flex-end' justifyContent='flex-end'>
                    {/* <Grid item><FilterDate id='start_date' label='Fecha inicial' value={startDate} onChange={onChangeDate}/></Grid>
                    <Grid item><FilterDate id='end_date' label='Fecha final' value={endDate} onChange={onChangeDate}/></Grid> */}
                    <Grid item><MonthFilter value={startDate} onChange={(value)=>onChangeDate(value,'start_date')} /></Grid>
                    <Grid item><Typography style={{marginBottom:8}} >-</Typography></Grid>
                    <Grid item><MonthFilter value={endDate} onChange={(value)=>onChangeDate(value,'end_date')} /></Grid>
                    <Grid item><FilterButton label='Estatus' options={catalogs.order_statuses} selected={filters.status} onChange={(val) => onChangeFilters('status', val)}/></Grid>
                    <Grid item><FilterButton label='Estatus de pago' options={catalogs.payment_statuses} selected={filters.payment_status} onChange={(val) => onChangeFilters('payment_status', val)}/></Grid>
                    <Grid item xs/>
                    {/* {onGenerateReport ? <Grid item><Button onClick={onGenerateReport} className={classes.reportButton}>Reporte<Icon>download</Icon></Button></Grid> : null} */}
                    
                    <Grid item>
                        <MenuSettings options={settings} onItemSelected={onSettingSelected}/>
                    </Grid>
                    <Grid item>
                        <SearchBar search={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
                    </Grid>
                    {/* {onAddRegister ? <Grid item><IconButton className={classes.button} onClick={onAddRegister}><Icon>add</Icon></IconButton></Grid> : null} */}
                </Grid>
            </div>
            {loading ? (
                <div style={{marginBottom:16}}>
                <LinearProgress color='primary'/>
                </div>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography className={classes.header} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {

                                        const {id_order, ordered_at, user, pharmacy, warehouse, order_type_id, order_status_id, payment_total, payment_status_id } = item

                                        const timezone = moment.tz.guess()
                                        let current_date = moment(ordered_at).tz(timezone)
                                        current_date = current_date.isValid() ? current_date.format('DD MMM YY') : nodata

                    
                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{id_order}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{current_date}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{user}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{pharmacy}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{onGetCatalogById(catalogs.order_types, order_type_id)}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{onGetFormatNumber(payment_total)}</Typography></TableCell>
                                                <TableCell className={classes.cell}><OrderStatus status_id={order_status_id}/></TableCell>
                                                <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}}><PaymentStatus status_id={payment_status_id}  /></TableCell>           
                                                {/* <TableCell className={classes.cell}><IconButton disabled={order_status_id !== 1} onClick={() => onDeleteItem(item)}><Icon>delete</Icon></IconButton></TableCell> */}
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable