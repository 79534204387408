import React, { useState, useEffect } from 'react';
import {
    Grid,
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useBatches from '../../../../hooks/useBatches';
import qs from 'query-string'
import LoadingContainer from '../../../../components/Structure/LoadingContainer';
import NotFoundMessage from '../../../../components/Platform/Placeholders/NotFoundMessage';
import RoundedButton from '../../../../components/Actions/RoundedButton';
import AddBatchModal from '../modals/AddBatchModal';
import { onGetCurrencyValue, onGetDateWithTimezone } from '../../../../shared/utility';
import EditBatchModal from '../modals/EditBatchModal';
import DeleteBatchModal from '../modals/DeleteBatchModal';
import ReturnBatchModal from '../modals/ReturnBatchModal';

const header = [
    {id:1, label:'ID'},
    {id:2, label:'No. de lote'},
    {id:3, label:'Fecha de recepción'},
    {id:4, label:'Fecha de caducidad'},
    {id:13, label:'Fecha de compra'},
    {id:5, label:'Cant. inicial'},
    {id:6, label:'Cant. disponible'},
    {id:7, label:'Cant. vendida'},
    {id:8, label:'Activo'},
    {id:9, label:'Proveedor'},
    {id:10, label:'Precio de compra'},
    {id:11, label:'Devoluciones'},
    {id:12, label:'Acciones'},
]

const BatchesTable = ({warehouse, product, inventory_actions}) => {

    const classes = useStyles()

    const batchManager = useBatches()

    // Modal flag variables
    const [add_product, setAddProduct] = useState(false)
    const [edit_product, setEditProduct] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [return_modal, setReturnModal] = useState(false)

    const [selected, setSelected] = useState(null)

    const onSelectectBatch = (data) => {
        setSelected(data)
        setEditProduct(true)
    }

    const onDeleteBatch = (data) => {
        setSelected(data)
        setDeleteModal(true)
    }

    const onReturnBatch = (data) => {
        setSelected(data)
        setReturnModal(true)
    }

    useEffect(() => {
        if(warehouse && product){
            console.log('Solicitar batches')
            const params = onGetParams()
            batchManager.actions.onGetAll(params)
        }
    },[warehouse, product])

    const onGetParams = () => {
        let params = ''
        const pagination = {
            offset:0,
            limit:100,
            order_by:'expiry_date',
            order:1
        }
        const filter = JSON.stringify({"AND":[
            {"field":"product_id","operator":"=","value":product?.product_id},
            {"field":"warehouse_id","operator":"=","value":warehouse?.value},
        ]})
        params = `?${qs.stringify(pagination)}&filter=${filter}`

        return params
    }

    useEffect(() => {
        console.log(batchManager.data.batches)
    }, [batchManager.data.batches])

    const onUpdateCompleted = async() => {
        try {
            const params = onGetParams()
            await batchManager.actions.onGetAll(params)
            await inventory_actions.onUpdateInventory()
        } catch (error) {
            console.log(error)
        }
    }



    const {batches} = batchManager.data


    const contentView = (
        <div >
            <TableContainer className={classes.table_container}>
                <Table stickyHeader className={classes.table}>
                    {header ? (
                        <TableHead>
                            <TableRow>
                                {header.map(item=>{
                                    return(
                                        <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                            <Typography className={classes.header} variant='body2' >{item.label}</Typography>
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    ):null}
                    <TableBody>
                        {batches.map((item,key) => {

                            console.log(item)

                            const expiration_date = onGetDateWithTimezone(item?.expiry_date, 'DD MMM YY')
                            const reception_date = onGetDateWithTimezone(item?.reception_date, 'DD MMM YY')
                            const sale_date = onGetDateWithTimezone(item?.sale_limit_date, 'DD MMM YY')
                            
                            return(
                                <TableRow key={key.toString()} className={classes.row} >
                                    <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}}><Typography variant='body2'>{item?.id_batch}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{item?.number}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{reception_date??'-'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{expiration_date??'-'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{sale_date??'-'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{item?.initial_quantity ?? '0'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{item?.available_quantity ?? '0'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{item?.sold_quantity ?? '0'}</Typography></TableCell>
                                    <TableCell className={classes.cell}><EnabledDisplayer enabled={item?.enabled}  /></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{item?.provider}</Typography></TableCell>
                                    <TableCell className={classes.cell}><Typography variant='body2'>{onGetCurrencyValue(item?.provider_cost)}</Typography></TableCell>                           
                                    <TableCell className={classes.cell} onClick={()=>onReturnBatch(item)} >
                                        <div style={{padding:'4px 16px', borderRadius:24, border:'1px solid #7f7f7f', cursor:'pointer'}} >
                                            <Typography variant='body2' >{item?.consignation_return_quantity ?? '0'}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}}>
                                        <Grid container spacing={2} wrap='nowrap'>
                                            <Grid item>
                                                <IconButton size='small' onClick={()=>onSelectectBatch(item)} ><Icon fontSize='small'>edit</Icon></IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton size='small' onClick={()=>onDeleteBatch(item)} ><Icon fontSize='small'>delete</Icon></IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )



    return ( 
        <div className={classes.root}>
            <AddBatchModal 
                open={add_product} 
                warehouse={warehouse} 
                product={product}
                onClose={() => setAddProduct(false)}
                onCompleted={onUpdateCompleted}/>
            <EditBatchModal 
                open={edit_product} 
                warehouse={warehouse} 
                product={product}
                origin={selected}
                onClose={() => setEditProduct(false)}
                onCompleted={onUpdateCompleted}/>
            <DeleteBatchModal open={delete_modal} onClose={()=>setDeleteModal(false)} id={selected?.id_batch} onCompleted={onUpdateCompleted}/>
            <ReturnBatchModal open={return_modal} onClose={()=>setReturnModal(false)} id={selected?.id_batch} onCompleted={onUpdateCompleted}/>
            <div>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item >
                        <Typography variant='subtitle1' color='textSecondary'>
                            LOTES
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton size='small'  onClick={() => setAddProduct(true)} className={classes.add_btn}><Icon color='inherit' fontSize='small'>add</Icon></IconButton>
                    </Grid>
                </Grid>
            
            </div>
            
            <LoadingContainer loading={batchManager.system.loading}>
                <div>
                    {batches.length === 0 ? (
                        <NotFoundMessage />
                    ):(
                        <div>
                            {contentView}
                        </div>
                    )}
                </div>
            </LoadingContainer>

        </div>
     );
}

const EnabledDisplayer = ({enabled}) =>{
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{width:8, height:8, marginRight:8, borderRadius:'50%', background:enabled ? '#96AE76' : '#D95D71'}}></div>
            <Typography>{enabled ? 'Sí' : 'No'}</Typography>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root:{
        paddingBottom:32
    },
    add_btn:{
        color:'white',
        background:theme.palette.primary.main,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    header:{
        fontSize:'0.875rem'
    },
    cellHeader:{
        //borderColor:grey[300],
        paddingTop:16,
        paddingBottom:0,
        background:'#f6f7fb',
        border:'none',
        color:'#8C8C9B',
        fontSize:14,
        //borderRadius:16
    },
    cell:{
        border:'none',
        borderBottom:'6px solid #f6f7fb',
        background:'white',
    },
    table:{
        minWidth:800,
        /* '& .MuiTable-stickyHeader':{
            borderRadius:16,
            background:'red'
        } */
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    table_container:{
        maxHeight:600
    },
}))

export default BatchesTable;