import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import SVGIcon from '../../Structure/DisplayData/SVGIcon';
import emptySearch from '../../../assets/icons/empty_search.svg'
const NotFoundMessage = () => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container direction='column' spacing={2} alignItems='center'>
                <Grid item>
                    <div className={classes.icon_container}>
                        <div className={classes.icon}>
                            <SVGIcon src={emptySearch} size={48} />
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='subtitle2'>No se encontraron resultados</Typography>
                </Grid>
                <Grid item>
                    <Typography  variant='body2'>Por favor, intenta con otra búsqueda</Typography>
                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:80,
    },
    icon_container:{
        width:100,
        height:100,
        borderRadius:'50%',
        border:'1px solid white',
        background:theme.palette.primary.light,
        position:'relative'
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',

    }

}))

export default NotFoundMessage;