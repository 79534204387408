import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import ContainedButton from '../../../../components/Actions/ContainedButton';
import InputForm from '../../../../components/Forms/InputForm';
import SelectForm from '../../../../components/Forms/SelectForm';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import DisplayText from '../../../../components/Texts/DisplayText';
import { isFormValid, onGetErrorMessage, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility';
import { private_server, USER_TYPES } from '../../../../variables/config';



const AddBillingProfileModal = ({open, onClose, onCompleted, user_id}) => {

    const classes = useStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(async() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
        }else{
            try{
                updateAuthorizationHeader(private_server)
                const tax_systems = await private_server.get(`/billingprofile/taxsystems`)
                const options = tax_systems.data.data.tax_systems.map(item => {
                    return {value:item.id_tax_system, label:item.code + ' - ' + item.name}
                })
                form.tax_system_id.options = options
            }catch(e){
                console.log(e)
                setError(onGetErrorMessage(e))
            }       
        }   
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
        setError(null)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)
        //delete data2send.passwordConfirmation



        setLoading(true)
        try {
            await private_server.post(`/billingprofile/${user_id}`, data2send)
            onCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
      
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>  
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Agregar perfil de facturación</DisplayText>
                <DisplayText >Por favor ingresa los datos solicitados</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.legal_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.tax_id} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.tax_system_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.zip} onChange={onChange} />
                        </Grid>                        
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
     );
}

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:theme.spacing(6),
        paddingBottom:theme.spacing(4),
    },
}))

export default AddBillingProfileModal;

const formData = {
    legal_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'legal_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre legal',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    tax_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'tax_id',
            type: 'text',
            fullWidth: true,
            label: 'RFC',
            helperText: 'RFC no válido'
        },
        rules: {
            type: 'rfc',
        }
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido'
        },
        rules: {
            type: 'email',
        }
    },
    tax_system_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[],
        config: {
            id: 'tax_system_id',
            type: 'select',
            fullWidth: true,
            label: 'Régimen fiscal',
            helperText: 'Debe elegir una opción',
        },
    },
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label:'Código postal',
            helperText: 'Código postal no válido',
        },
        rules: {
            type: 'zip',
        }
    },
}