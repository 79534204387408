import React from 'react';
import { Grid, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';


const OptionSelector = ({value, placeholder, onChange}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}  onClick={onChange}>
            <Grid container alignItems='center' wrap='nowrap'>
                <Grid item xs>
                    {value ? (
                        <div className={classes.value}>
                            {value}
                        </div>
                    ) : (
                        <div className={classes.placeholder}>
                            {placeholder}
                        </div>
                    )}
                </Grid>
                <Grid item>
                    <Icon>
                    arrow_drop_down
                    </Icon>
                </Grid>
            </Grid>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        border:'1px solid #ABABAB',
        cursor:'pointer',
        textAlign:'center',
        fontFamily:theme.typography.fontFamily,
        padding:12,
        borderRadius:4,
        '&:hover':{
            border:`1px solid ${theme.palette.primary.main}`,
        }
    },
    value:{

    },
    placeholder:{
        color:grey[600]
    }
}))
 
export default OptionSelector;