import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"



const useMedications = ({history, medications_search, medications_table}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [medications_table])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = medications_table
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                //let filter = {filter:`[{"field":"name","operator":"LIKE","value":"val"}]`}
                let req
                if(medications_search !== '' && medications_search !== null){
                    req = await private_server.get(`medications/search?search=${medications_search}`, {params:{...params}})
                }else{
                    req = await private_server.get(`medications/list`, {params:{...params}})
                } 
                /* if(search){
                    let filter = [
                        {"field":"name","operator":"LIKE","value":`%${search}%`},
                    ]
                    filter = encodeURIComponent(JSON.stringify(filter))
                    req = await private_server.get(`medications/list?filter=${filter}`, {params:{...params}})
                }else{
                    req = await private_server.get(`medications/list`, {params:{...params}})
                } */
                //console.log(req)
                setData(req.data.data.medications)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onSelectItem: (item) => {
            const {id_medication} = item
            history.push(`/medications/${id_medication}`)
        },
        onDeleteItem: (item) =>{
            const {id_medication} = item
            setSelected(id_medication)
            actions.onChangeModalStatus('delete', true)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useMedications