import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { connect } from 'react-redux'
//import * as actionTypes from '../../../store/actions'
import { actionTypes } from '../../../store/actions'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import ChangeImageModal from './modals/ChangeImageModal'
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import system_translations from '../../../texts/system_translations'
import useDoctorView from './useDoctorView'
import Medical from './views/Medical/Medical'
import General from './views/General/General'

const DoctorView = props => {

    
    const { match, language, onUpdateSending, onUpdateSuccess, onUpdateError} = props
    
    const userID = match.params.id
    const content = system_translations[language].views.user
    const catalogs = system_translations[language].catalogs
    const {system, modals, actions, view_data} = useDoctorView({content, match, onUpdateError, onUpdateSending, onUpdateSuccess})
    

    /* //general
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sending2, setSending2] = useState(false)
    const [sending3, setSending3] = useState(false)
    const [user, setUser] = useState(null)
    
    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //modals 
    const [update_image_modal, setUpdateImageModal] = useState(false)
    const [password_modal, setPasswordModal] = useState(false)

    //checks
    const [indigenous_check, setIndigenousCheck] = useState(false)
    

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _user = await request_user(userID)
            //console.log(_user)
            setUser(_user.user)
            let _form = onInitEmptyForm(form, content.form)
            _form = onInitForm(form, _user.user)
            setForm(_form)
            setIndigenousCheck(Boolean(_user.user.indigenous))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onUpdateCompleted = async() => {
        try {
            const _user = await request_user(userID)
            const _form = onInitForm(form, _user.user)
            setUser(_user.user)
            setForm(_form)
            setUpdateImageModal(false)
            setPasswordModal(false)

        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
    
    const onSubmitGeneral = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending(false)
            onUpdateSending(false)
            //setSucess(content.success_msg)
            onUpdateSuccess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            onUpdateSending(false)
            setSending(false)
        }
    }

    const onSubmitContact = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending2(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending2(false)
            onUpdateSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            setSending2(false)
            onUpdateSending(false)
        }
    }

    const onSubmitAdditional = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, user)
        data2send.indigenous = indigenous_check ? 1 : 0
        if(data2send.indigenous === 0){
            data2send.ethnic_group=''
            data2send.ethnic_language=''
        }
        console.log(data2send)
        setSending3(true)
        onUpdateSending(true)
        try {
            await request_update_user(userID, data2send);
            const _user = await request_user(userID)
            setUser(_user.user)
            setSending3(false)
            onUpdateSending(false)
            //setSucess(content.success_msg)
            onUpdateSuccess(content.success_msg)
        } catch (error) {
            console.log(error)
            //setError(onGetErrorMessage(error))
            onUpdateError(onGetErrorMessage(error))
            setSending3(false)
            onUpdateSending(false)
        }
    }

    const onGetError = (error) => {
        //setError(onGetErrorMessage(error))
        onUpdateError(onGetErrorMessage(error))
    } */

    //views
    const menu = content.menu
    const [view, setView] = useState(1)

    const onChangeView = (id) => {
        setView(id)
    }

    let contentView = null

    switch(view){
        case 1:
            contentView =  <General content={content} origin={view_data.data} catalogs={catalogs} onUpdateCompleted={actions.onUpdateCompleted} 
                                onUpdateSending={onUpdateSending} onUpdateError={onUpdateError} match={match}/>
            break
        case 2:
            contentView = <Medical content={content} onSuccess={()=>onUpdateSuccess(content.success_msg)} match={match}
                            onUpdateError={onUpdateError} onUpdateSending={onUpdateSending} catalogs={catalogs} />
            break
        default:
            break
    }

    
    return(
        <Page title={content.titles.doctor} loading={system.loading} goback >
            <UpdatePasswordModal content={content.modals.update_password} userID={userID} open={modals.change_password} 
                onClose={() => actions.onChangeModalStatus('change_password', false)} onUpdateCompleted ={actions.onInitModule} 
                onUpdateSuccess={onUpdateSuccess} />
            <ChangeImageModal content={content.modals.change_image} id={userID} open={modals.change_image} 
                onClose={() => actions.onChangeModalStatus('change_image', false)} onUpdateCompleted={actions.onInitModule}/>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={4}>
                    <UserInfoCard content={content.cards} {...view_data.data} onChangePicture={() => actions.onChangeModalStatus('change_image', true)} 
                    onChangePassword={() => actions.onChangeModalStatus('change_password', true)} />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
                        </Grid>
                        <Grid item xs={12}>
                            {contentView}
                        </Grid>
                    </Grid>         
                </Grid>
            </Grid>
        </Page>
    )
} 

const mapStateToProps = state => {
    return{
        language: state.language,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.SYS_UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorView)

