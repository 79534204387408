import React, { useState } from 'react';
import {
    Card,
    CircularProgress,
    Grid,
    Button,
    Typography,
    Checkbox,
    FormControlLabel,
    IconButton,
    Hidden,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { formData } from './data';
import InputSignIn from '../../components/Forms/InputSignIn';
import { isFormValid } from '../../components/Forms/customFunctions';
import { request_login } from './requests';
import DisplayText from '../../components/Texts/DisplayText';
import { isTokenValid } from '../../shared/utility';
import { Redirect } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions';
import { withRouter } from 'react-router-dom';
import ForgottenPasswordModal from '../../modals/ForgottenPasswordModal/ForgottenPasswordModal';
import SVGIcon from '../../components/Structure/DisplayData/SVGIcon';
import { translations } from '../../texts/esp/signin_translations';

const useStyles = makeStyles((theme) => ({
	root: {
		position: 'relative',
		width: '100%',
		minHeight: '100vh',
	},
	button: {
		width: 178,
		height: 60,
		textTransform: 'none',
		fontSize: 16,
		fontWeight: 600,
		marginTop: 60,
	},
	left_container: {
		width: 350,
		margin: '15% auto',
		[theme.breakpoints.down('sm')]: {
			width: '85%',
		},
	},
	link: {
		color: theme.palette.primary.main,
		fontSize: 14,
		fontWeight: 600,
		textDecoration: 'underline',
		'&:hover': { cursor: 'pointer', textDecoration: 'none' },
	},
	check_label: {
		fontSize: 14,
		fontWeight: 600,
	},
	social_container: {
		position: 'absolute',
		bottom: 50,
		left: '50%',
		transform: 'translateX(-50%)',
		[theme.breakpoints.down('sm')]: {
			position: 'relative',
			marginTop: 100,
		},
	},
	social_button: {
		border: '1px solid #DEDEDE',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 10,
	},
	social_icon: {
		width: 20,
		height: 20,
	},
	right_container: {
		width: '100%',
		height: '100vh',
	},
	banner: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	banner_text_container: {
		position: 'absolute',
		right: 20,
		bottom: 20,
	},
}));

const SignInView = ({ history, onUpdateRecoverPasswordModal }) => {
	const content = translations;
	const theme = useTheme()

	const classes = useStyles();
	const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)));
	const [isValid, setIsValid] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [check, setCheck] = useState(false);

	const [type, setType] = useState(1);

	const [forgottenPassModal, setForgottenPassModal] = useState(false);

	const onChange = (data) => {
		let temp = { ...form };
		const id = data.config.id;
		temp[id] = { ...data };
		setForm(temp);
		setIsValid(isFormValid(temp));
	};

	const onSubmit = async () => {
		if (!isValid) {
			setError('Llene los cambios solicitados');
			return;
		}
		let data2send = {};
		Object.keys(form).forEach((item) => {
			data2send = { ...data2send, [item]: form[item].value };
		});
		data2send.device = 'web';
		setLoading(true);
		try {
			const response = await request_login(data2send);
			console.log(response);
			if (response.user_type_id !== 2) {
				// User is allowed to enter

				await localStorage.setItem('token_data', JSON.stringify(response));
				history.push('/banners');
			} else {
				setError('Usuario sin permisos');
			}
		} catch (error) {
			if (error.response && error.response.data) {
				setError(error.response.data.error.message);
			} else {
				setError('Hubo un error interno');
			}
		}
		setLoading(false);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
	};

	const isAuth = isTokenValid();
	const redirectContent = isAuth ? <Redirect to='/banners' /> : null;

	return (
        <div className={classes.root}>
			<ForgottenPasswordModal
				open={forgottenPassModal}
				onClose={() => {
					setForgottenPassModal(false);
				}}
			/>
			{redirectContent}
			<Grid container style={{ minHeight: '100vh' }}>
				<Grid item xs={12} md={4} style={{ position: 'relative' }}>
					<div className={classes.left_container}>
						<Grid container direction='column' spacing={2}>
							<Grid item>
								<div style={{ marginBottom: 32 }}>
									<SVGIcon src={logo} width={60} color='#543081' />
								</div>
							</Grid>
							<Grid item>
								{type === 1 ? (
									<Typography variant='h5'>{content.admin.title}</Typography>
								) : (
									<>
										<Typography variant='h5' style={{display: 'inline'}} >{content.doctor.title1}</Typography>
										<Typography variant='h5'style={{display: 'inline', color: theme.palette.primary.main}} >{content.doctor.title2}</Typography>
									</>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={3} style={{ marginTop: 50, marginBottom: 10 }}>
							<Grid item xs={12}>
								<InputSignIn data={form.email} onChange={onChange} onKeyPress={handleKeyPress} />
							</Grid>
							<Grid item xs={12}>
								<InputSignIn data={form.password} onChange={onChange} onKeyPress={handleKeyPress} />
							</Grid>
						</Grid>
						<Grid container alignItems='center'>
							<Grid item xs={6}>
								<FormControlLabel
									classes={{ label: classes.check_label }}
									control={<Checkbox checked={check} onChange={() => setCheck(!check)} name='rememberme' color='secondary' />}
									label={content.remember}
								/>
							</Grid>
							<Grid item xs={6} container justifyContent='flex-end'>
								<div className={classes.link} onClick={(e) => setForgottenPassModal(true)}>
									{content.forgot_psw}
								</div>
							</Grid>
						</Grid>

						<Button variant='contained' color='primary' className={classes.button} onClick={onSubmit}>
							{content.button}
							{loading ? <CircularProgress style={{ width: 24, height: 24, marginLeft: 8, color: 'white' }} /> : null}
						</Button>
						<DisplayText color='error' align='center'>
							{error}
						</DisplayText>
					</div>
					<div className={classes.social_container}>
						<Grid container spacing={1} justifyContent='center'>
							<Grid item>
								<IconButton className={classes.social_button} size="large">
									<img className={classes.social_icon} src={content.icon_fb} />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton className={classes.social_button} size="large">
									<img className={classes.social_icon} src={content.icon_ig} />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton className={classes.social_button} size="large">
									<img className={classes.social_icon} src={content.icon_tw} />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton className={classes.social_button} size="large">
									<img className={classes.social_icon} src={content.icon_li} />
								</IconButton>
							</Grid>
						</Grid>
					</div>
				</Grid>
				<Hidden mdDown>
					<Grid item xs={12} md={8} style={{ position: 'relative', margin: 0 }}>
						<div className={classes.right_container}>
							<img src={type === 1 ? content.admin.banner : content.doctor.banner} className={classes.banner} />
						</div>
						<div className={classes.banner_text_container}>
							<Typography variant='h5' align='right' style={{ color: 'white' }}>
								{type === 1 ? content.admin.banner_title : content.doctor.banner_title}
							</Typography>
							<Typography variant='h6' align='right' style={{ color: 'rgba(255, 255, 255, 0.57)' }}>
								{type === 1 ? content.admin.banner_subtitle : content.doctor.banner_subtitle}
							</Typography>
						</div>
					</Grid>
				</Hidden>
			</Grid>
		</div>
    );
};

const mapStateToProps = (state) => {
	return {
		recover_password_modal: state.recover_password_modal,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onUpdateRecoverPasswordModal: (recover_password_modal) => dispatch({ type: actionTypes.AUTH_RECOVER_PASSWORD_MODAL, recover_password_modal }),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInView));
