import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../../components/Forms/InputForm'
import { private_server } from '../../../../../../variables/config'
import { modalStyles } from '../../../../../../styles/modalStyles'
import AutocompleteFormOptions from '../../../../../../components/Forms/AutocompleteFormOptions'
//import { request_create_inventory, request_products } from '../requests'




const EditModal = ({open, onClose, onUpdateCompleted, content, origin}) => {

    //console.log(origin)

    const classes = modalStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    const [increment, setIncrement] = useState(null)


    useEffect(() => {
    const onInitModal = async() => {
        if (open) {
            let _form = onInitEmptyForm(formData, content.form)
            _form = onInitForm(formData, origin)
            setForm(_form)         
        }else{
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);    
            setIncrement(null)    
        }
    }
    onInitModal()
    }, [open])

    
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'increment'){
            if(Math.abs(parseInt(temp[id].value)) > origin.available && parseInt(temp[id].value) < 0 ){
                temp[id].isValid = false
                temp[id].isRequired = true
            }else{
                temp[id].isValid =true
                setIncrement(parseInt(temp[id].value))
            }
            
        }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        } 

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        delete data2send.increment
       
        console.log(data2send)
        console.log(increment)

        //console.log(origin.id_inventory)
        
        setLoading(true)
        try {
            if(increment){
                updateAuthorizationHeader(private_server)
                await private_server.post(`inventory/${origin.id_inventory}/increment`, {increment:increment})
            }
            if(data2send.location){
                updateAuthorizationHeader(private_server)
                await private_server.patch(`inventory/${origin.id_inventory}`, data2send)
            }
             
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
       
    }


    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose}>
            <DisplayText variant='h6' >{content.edit.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >                                  
                            <InputForm data={form.location} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >                                  
                            <InputForm data={form.increment} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button} >
                    <ContainedButton color='primary' onClick={onSubmit} loading={loading} disabled={loading}>
                        {content.edit.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditModal

const formData = {
    location: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'location',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    increment: {
        value: 0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'increment',
            type: 'numeric',
            fullWidth: true,
        },
        /* rules: {
            type: 'numeric',
            min: 1, max: 500000
        } */
    },
}
