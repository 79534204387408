import { useEffect, useState } from 'react';
import { onGetErrorMessage, updateAuthorizationHeader, onGetCatalogs } from '../../../shared/utility';
import { private_server } from '../../../variables/config';
import queryString from 'query-string';
import axios from 'axios';
import { request_update_permissionsetting } from './requests';

const usePermissions = ({ content, history }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [data, setData] = useState([]);
	const [rolesCatalog, setRolesCatalog] = useState([]);
	const [selected, setSelected] = useState(null)

	const [total, setTotal] = useState(0);
	const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0, order: 0, order_by: 'role_id' });
	const [roleFilter, setRoleFilter] = useState(-1);
	const [search, setSearch] = useState('');

	const [modals, setModals] = useState({
		add: false,
		delete: false,
	});

	useEffect(() => {
		actions.onInitModule();
	}, [tableFilter, roleFilter]);

	const onGetParams = () => {
		const { limit, offset, order_by, order } = tableFilter;
		const params2send = { limit: limit, offset: offset * limit, order_by: order_by, order: order };
		let params = `?${queryString.stringify(params2send)}`;
		let filtersArgs = [];

		if (roleFilter && roleFilter !== -1) {
			filtersArgs.push({
				field: 'role_id',
				operator: '=',
				value: `${roleFilter}`,
			});
			let finalArgs = { AND: filtersArgs };
			params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`;
		}

		if (search) {
			filtersArgs.push({ field: 'module', operator: 'LIKE', value: `%${search}%` });
			let finalArgs = { AND: filtersArgs };
			params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`;
		}

		return params;
	};

	const getFilterData = async () => {
		try {
			setLoading(true);
			updateAuthorizationHeader(private_server);
			let req = await private_server.get(`/role/list`);
			const _catalog = onGetCatalogs(req.data.data.roles, 'id_role', 'name');
			setRolesCatalog(_catalog);
			setLoading(false);
		} catch (error) {
			console.log(error);
			setError(onGetErrorMessage(error));
			setLoading(false);
		}
	};

	const actions = {
		onInitModule: async () => {
			try {
				setLoading(true);
				updateAuthorizationHeader(private_server);
				const params = onGetParams();
				let req = await private_server.get(`/permission/list${params}`);
				setData(req.data.data.permissions);
				setTotal(req.data.data.count);
				setLoading(false);
				getFilterData();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onChangeSearch: (value) => {
			setSearch(value);
		},
		onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
		onChangeModalStatus: (key, status) => {
			let _modals = { ...modals };
			_modals[key] = status;
			setModals(_modals);
		},
		onUpdateRoleFilter: (filter) => {
			setRoleFilter(filter);
		},
		onChangeStatus: async (item, key) => {
			let data2send = {
                full_access: item.full_access,
                create: item.create,
                read: item.read,
                update: item.update,
                delete: item.delete,
                public: item.public
            };
			const { id_permission } = item;
			if (key !== 'full_access') {
				data2send[key] = item[key] === 0 ? 1 : 0;
			} else {
                if(item.full_access === 0){
                    data2send.full_access = 1;
                    data2send.create = 1;
                    data2send.read = 1;
                    data2send.update = 1;
                    data2send.delete = 1;
                    data2send.public = 1;
                }
                else{
                    data2send.full_access = 0;
                    data2send.create = 0;
                    data2send.read = 0;
                    data2send.update = 0;
                    data2send.delete = 0;
                    data2send.public = 0;
                }
			}
			try {
				await request_update_permissionsetting(id_permission, data2send);
				actions.onInitModule();
			} catch (error) {
				console.log(error);
			}
		},
		onDelete: async(id) =>{
			setSelected(id)
			actions.onChangeModalStatus('delete', true)
		}
	};

	const system = { loading, error };
	const view_data = { data, total, tableFilter, search, rolesCatalog, roleFilter, selected };

	return { system, modals, actions, view_data };
};

export default usePermissions;
