import order from '../../assets/icons/order.svg'


export const translations = {
    title:'Pedidos',
    table: {    
        header: [
            {id:1, label:'ID'},
            {id:2, label:'Fecha de pedido'},
            {id:3, label:'Usuario'},
            {id:4, label:'Farmacia'},
            {id:5, label:'Tipo de orden'},
            {id:6, label:'Total'},
            {id:7, label:'Estatus de orden'},
            {id:8, label:'Estatus de pago'},
            //{id:9, label:''},
        ],
        nodata:'No existen pedidos registrados',
        empty:order    
    },

}

export default translations

