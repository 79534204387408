import React from 'react'
import {
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../../components/Texts/DisplayText'
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../../components/Actions/ActionBar'
import empty from '../../../../../assets/empty.png'
import { tableStyles } from '../../../../../styles/tableStyles'
import { onGetDateFormat } from '../../../../../shared/utility'
import UserAvatarName from '../../../../../components/Structure/DisplayData/UserAvatarName'
import NewActionBar from '../../../../../components/Actions/NewActionBar'


/* const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
})) */

const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, onDeleteItem, loading } = props

    const header = content.header

    const onGetDiscountType = (d_value, d_percentage, type, currency) =>{

        let discount = ''

        if(type === 1){
            if(currency === 1) discount = `$ ${parseFloat(d_value).toFixed(2)} USD`
            if(currency === 2) discount = `$ ${parseFloat(d_value).toFixed(2)} MXN`
        }
        if(type === 2){
            discount = `${parseFloat(d_percentage).toFixed(2)} %`
        }

        return discount
    }

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} 
                                label='Buscar en descuentos...'/>
                            </Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>          
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography style={{fontWeight:400}} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_appointment_discount_code, code, name, currency_id, user, current_uses, total_uses, expiry_date, discount_value, discount_percentage, code_discount_type_id,
                                            product, enabled, product_image_web, user_image, payment_concept } = item

                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{id_appointment_discount_code}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' color='primary' >{code}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' > <font style={{color:'#70708E'}}>{current_uses}</font> / {total_uses}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{onGetDateFormat(expiry_date, 'DD/MM/YYYY hh:mm a')}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{onGetDiscountType(discount_value, discount_percentage, code_discount_type_id, currency_id)}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{payment_concept}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName image={user_image} name={user} noimage={content.user} labelStyles={{fontWeight:500, fontSize:'1rem'}} /></TableCell>
                                                <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} onClick={() => onSelectedItem(item)}><EnabledDisplayer enabled={enabled}  /></TableCell>
                                                {/* <TableCell className={classes.cell} padding='checkbox'>
                                                    <IconButton onClick={() => onDeleteItem(item)}><Icon>delete</Icon></IconButton>
                                                </TableCell> */}
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>               
            ) : null}          
        </div>
    );
}

export default SimpleTable

const EnabledDisplayer = ({enabled}) =>{
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{width:8, height:8, marginRight:8, borderRadius:'50%', background:enabled ? '#96AE76' : '#D95D71'}}></div>
            <DisplayText>{enabled ? 'Sí' : 'No'}</DisplayText>
        </div>
    )
}