import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"
import queryString from 'query-string'


const useRoles = ({content, history}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [idSelected, setIdSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:0, order_by:'id_role'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        picture_add:false,
        picture_edit:false,
        edit: false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = ''

        if(search){
            let filterArgs = [{"field":"name","operator":"LIKE","value":`%${search}%`}]
            let finalArgs = {"AND":filterArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
        else{
            params = `?${queryString.stringify(params2send)}`
        }
        return params 
        
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`/role/list${params}`)
                setData(req.data.data.roles)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        /* onDeleteItem: async(item) =>{
            const {id_substance} = item
            setSelected(id_substance)
            actions.onChangeModalStatus('delete', true)
        }, */
        onSelectItem: (id) =>{
            setIdSelected(id)
            actions.onChangeModalStatus('edit', true)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, idSelected}

    return {system, modals, actions, view_data } 
}

export default useRoles