import { change_image_error_msg } from "./general-translations";
import catalogs from '../../assets/icons/catalogs.svg'

export const translations = {
    table: {
        header:[
            {id:1, label:''},
            {id:2, label:'ID'},
            {id:3, label:'Nombre'},
            {id:4, label:'Tipo'},
            {id:5, label:'Orden'},
            {id:6, label:'Activo'},
        ],
        nodata:'No existen menus registrados',
        empty:catalogs
    },
    modal: {
        success_msg:'Acción exitosa',
        add:{
            title:'Nuevo menu',
            button:'Agregar'
        },
        edit:{
            title:'Editar menu',
            button:'Guardar'
        },
        form:{
            menu_element_type_id:{
                label:'Tipo de menu',
                helperText:'Debe seleccionar una opción'
            },
            menu_element_id:{
                label:'Elemento',
                helperText:'Debe seleccionar una opción'
            },
            order:{
                label:'Orden',
                helperText:'Debe seleccionar una opción'
            },
        }
    },
}

