import {
    Card,
    ClickAwayListener,
    Grid,
    Grow,
    Icon,
    IconButton,
    Paper,
    Popper,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import React, { useState, useEffect } from 'react';
import { DASHBOARD_SETTINGS } from '../../variables/config';


const MenuSettings = (props) => {

    const {options, onItemSelected} = props
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerItemSelected = (_option) => {
        setAnchorEl(null)
        onItemSelected(_option)
    }

    let buttonContent = <div>No data</div>

    if(options){
        buttonContent = (
            <div>
                <Grid container>
                    {options.map((item, key) => {

                        const isLast = key === options.length -1
                        return(
                            <Grid item xs={12} key={`menuItem-${item.id}`} >
                                <div className={classes.menuItem} style={isLast ? {border:'none'}:{}}
                                    onClick={() => onInnerItemSelected(item)}
                                >
                                    <Grid container spacing={1} >
                                        {item.icon ?<Grid item>
                                            <Icon fontSize='small' color='primary'>{item.icon}</Icon>
                                        </Grid> : null}
                                        <Grid item xs>
                                            <Typography variant='body2' color='textSecondary'>{item.label}</Typography>
                                        </Grid>
                                    </Grid>

                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        )
    }

    return (
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal
                style={{zIndex:3000}}
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={ {enter: 350, exit: 350} }>
                        <Card className={classes.popper}>
                            {buttonContent}
                        </Card>
                        
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <IconButton
                color='primary'
                onClick={(e) => setAnchorEl(e.currentTarget)}
                size="large">
                <Icon>settings</Icon>
            </IconButton>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    popper:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        borderRadius:12
    },
    menuItem:{
        padding:'12px 8px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background: '#F8F8FE'
        }
    }
}))

export default MenuSettings;