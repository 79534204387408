import React, { useState, useEffect } from 'react'
import { Grid, Checkbox, FormControlLabel} from '@mui/material'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, string2Slug } from '../../../../../shared/utility'
import { useStyles } from './styles'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { request_create_product } from '../../requests'
import SelectForm from '../../../../../components/Forms/SelectForm'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { request_brands } from '../../../ProductView/requests';
import { translations as translations_esp } from '../../../../../texts/esp/products-translations';



const AddUserModal = props => {

    const content = translations_esp.modal

    const { open, onClose, history } = props

    const classes = useStyles()
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks
    const [recipe_check, setRecipeCheck] = useState(false)
    const [retains_check, setRetainsCheck] = useState(false)
    const [sale_check, setSaleCheck] = useState(false)
    const [taxable_check, setTaxableCheck] = useState(false)

    const [brands, setBrands] = useState([])

    useEffect(() => {
        const initModal = async() => {
            //setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);
            const _brands = await request_brands()
            const brandsCatalogs = onGetCatalogs(_brands.brands_data, 'id_brand', 'name')
            let _form = onInitEmptyForm(JSON.parse(JSON.stringify(formData)), content.form)
            _form.brand_id.options = brandsCatalogs
            setForm(_form)
            setDisabled(false)
            console.log('Brands ', _brands)
            setBrands(_brands?.brands_data??[])
        }
        if(open)initModal()
    }, [open])

    const onGetRandomNumber = () => {
        let result = ''
        const characters = '0123456789'
        for (let i = 0; i < 5; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        if(id === 'active_substance' || id === 'measure' || id === 'product_quantity'){
            const measure = temp.measure.value ?? ''
            const product_quantity = temp.product_quantity.value ?? ''
            const active_substance = temp.active_substance.value ?? ''

            let aux = ''

            if(active_substance) aux = `${active_substance}`
            if(product_quantity) aux = `${aux}, ${product_quantity}`
            if(measure) aux = `${aux} ${measure}`
            temp.presentation.value = aux
            temp.presentation.isValid = true
        }
        setForm(temp)
    }

    const onGetURL = (cleandata) => {
        let data = ''
        if(cleandata.brand_id){
            const brandObj = brands.find(el => el.id_brand === cleandata.brand_id)
            if(brandObj) data = `${data}${brandObj.name.trim()}`
        }
        if(cleandata.name) data = `${data} ${cleandata.name.trim()}`
        if(cleandata.sub_name) data = `${data} ${cleandata.sub_name.trim()}`
        if(cleandata.presentation) data = `${data} ${cleandata.presentation.trim()}`
        if(cleandata.sku) data = `${data} ${cleandata.sku.trim()}`
        data = data.trim()
        console.log(data)

        const _slug = string2Slug(data)
        console.log(_slug)
        return _slug
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.enabled = 0
        data2send.requires_recipe = recipe_check ? 1 : 0
        data2send.retains_recipe = retains_check ? 1 : 0
        data2send.taxable = 0
        data2send.sale = 0

        // Hardcoded
        data2send.generic = 0;
        data2send.medication = 1;
        data2send.product_status_id = 1;

        data2send.sat_unit_key = 'H87'
        data2send.sat_product_key = '01010101'
        data2send.url = onGetURL(data2send)

        console.log(data2send)

        
        setLoading(true)
        setDisabled(true)
        try {
            let response = await request_create_product(data2send);
            //console.log(response)
            setLoading(false)
            history.push(`/products/${response.product_id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <DisplayText >{content.subtitle}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputForm data={form.sku} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.sub_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={12} >
                            <InputForm data={form.active_substance} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.package} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.product_quantity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.measure} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.additional_measure} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.brand_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.presentation} onChange={onChange} />
                        </Grid>
                        
                        <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={recipe_check} onChange={()=>setRecipeCheck(!recipe_check)} />}
                                label={content.formControl.requires_recipe}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={retains_check} onChange={()=>setRetainsCheck(!retains_check)} />}
                                label={content.formControl.retains_recipe}
                            />
                        </Grid>                     
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddUserModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    brand_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'brand_id',
            type: 'selected',
            fullWidth: true,
            label: 'Fabricante/Laboratorio',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    sub_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'sub_name',
            type: 'text',
            fullWidth: true,
            label: 'Sub Marca',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    active_substance: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'active_substance',
            type: 'text',
            fullWidth: true,
            label: 'Principio Activo',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    presentation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'presentation',
            type: 'text',
            fullWidth: true,
            label: 'Presentación',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    package: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'package',
            type: 'text',
            fullWidth: true,
            label: 'Empaquetado',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    product_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'product_quantity',
            type: 'tel',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Este campo debe ser mayor a cero',
        },
        rules: {
            type: 'numeric',
            min: 0
        }
    },
    measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'measure',
            type: 'text',
            fullWidth: true,
            label: 'Medida',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    additional_measure: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_measure',
            type: 'text',
            fullWidth: true,
            label: 'Formulación',
            helperText: 'Este campo debe de contener al menos un caracter y máximo 256',
        },
        rules: {
            type: 'distance',
            min: 0, max: 256
        }
    },
    presentation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'presentation',
            type: 'text',
            fullWidth: true,
            label: 'Presentación',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    sku: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'sku',
            type: 'text',
            fullWidth: true,
            label: 'Sku',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
}