import { change_image_error_msg } from "./general-translations";

export const translations = {
    warning_modal: {
        title:'¿Estás seguro?',
        button:'Eliminar',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    picture_modal: {
        selected_image:'Imagen seleccionada',
        cropped_area:'Seleccione el área que desea recortar'
    },
    change_image_modal:{
        title:'Cambiar imagen',
        button:'Guardar',
        error_msg:change_image_error_msg
    },
    upload_image_modal:{
        title:'Subir imagen',
        button:'Guardar',
        error_msg:change_image_error_msg
    }
}

