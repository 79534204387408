import React from 'react'
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { palette } from '../../variables/config'
import { blueGrey } from '@mui/material/colors'
import cx from 'classnames'

const variant = {
    extraSmall:'caption',
    small:'body2',
    medium:'body1',
    large:'h6',
    extraLarge:'h5',
    superLarge:'h4'
}

const useStyles = makeStyles(theme => ({
    root:{
        //fontWeight:400,
        //color:blueGrey[900]
    },
    table_cell:{
        fontWeight:400,
        fontSize:'1rem'
    }
}))


const DisplayText = props => {

    const {size, color, variant} = props
    const classes = useStyles()


    return(
        <Typography {...props} 
        className={cx({
            [classes.table_cell]:variant === 'table_cell'})} >
            {props.children}
        </Typography>
    )
}

export default DisplayText