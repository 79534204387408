import React from 'react'
import {
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../../components/Actions/SearchBar'
import ActionBar from '../../../../../components/Actions/ActionBar'
//import empty from '../../../../../assets/empty.png'
import DisplayText from '../../../../../components/Texts/DisplayText'
import empty from '../../../../../assets/icons/sustance.svg'


//const content = translations_esp.tables.products




const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        //minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:20,
        padding:'50px 0px'
    },
    empty:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:20,
        borderRadius:'50%',
        background:'white',
        //boxShadow:'0px 10px 20px rgba(0, 0, 0, 0.02)',
        border:'3px solid #E8EBF3',
        width:140,
        height:140,
        padding:32,
        boxSizing:'border-box'
        
    }
}))

const SubstancesTable = props => {

    const classes = useStyles()
    const {content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDeleteItem} = props
    const header = content.header

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {/* <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid> */}
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {substance, substance_id} = item

                                    return (
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} ><DisplayText>{substance_id}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText>{substance}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} padding='checkbox'>
                                                <IconButton onClick={() => onDeleteItem(item)} size="large"><Icon>delete</Icon></IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}       
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                {/* <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/> */}
            </div> : null}
            {data.length === 0 && !loading  ? (
                
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen sustancias registradas</DisplayText>
                        </Grid>
                    </Grid>
                </div>  
                        
            ) : null}       
        </div>
    );
}

export default SubstancesTable