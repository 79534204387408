import React from 'react'
import { TextField, Grid, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { grey, red } from '@mui/material/colors'
import Autocomplete from '@mui/material/Autocomplete';


const useStyles = makeStyles(theme => ({
  root:{
    borderRadius:12,
    '& .MuiOutlinedInput-root':{
      borderRadius:12,
      '&.Mui-focused fieldset':{
        borderColor:theme.palette.secondary.main
      }
    },
    
  },
  line:{
    width:8,
    background:'#949494',
    height:0.4,
    margin:'0px 8px'
  },
  option_container:{
    padding:'10px 8px',
    //borderBottom:'1px solid #E8E8E8'
}
}))

const ProductAutocompleteForm = props => {

    const classes = useStyles()
   
    const {onChange, onInputChange, options} = props 
    
    return( 
      <Autocomplete
        disablePortal
        id="products"
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        className={classes.root}
        renderInput={(params) => <TextField {...params} color='secondary' variant='outlined'/>}
        noOptionsText={<Typography variant='body2'>Sin opciones </Typography>}
        renderOption={(props, option) => {
            //console.log(option)
            return(
              <div {...props} >
                  <Typography variant='body2' >{option.label}</Typography>
              </div>
         
        )}
        }
      /> 
    )
}

export default ProductAutocompleteForm