import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import { TextField, Icon, Typography, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { mainServer, USER_TYPES } from '../../../../variables/config';
import { onGetNameCatalogs, onUpdateAuthorizationHeader } from '../../../../shared/utility';
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName';
import { grey } from '@mui/material/colors';

const BankTerminalModal = ({open, onClose, warehouse_id, onSubmit}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState()
    const [options, setOptions] = useState([])
    const [filtered_options, setFilteredOptions] = useState([])
    const [selected, setSelected] = useState(null)

    const onSelectedItem = (data) =>{
        onSubmit(data)
        onClose()
    }

    useEffect(() => {
        const initModule = async() => {
            try {
                setLoading(true)
                let filter = {"AND":[{"field":"warehouse_id","operator":"=","value":`${warehouse_id}`} ]}
                onUpdateAuthorizationHeader()
                //let _request = await mainServer.get(`/bankterminal/list?filter=${encodeURIComponent(JSON.stringify(filter))}`)
                let _request = await mainServer.get(`/bankterminal/list`)
                console.log(_request)
                setOptions([..._request.data.data.bank_terminals])
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        if(open) initModule()
    }, [open])

    useEffect(() => {
        let _options = [...options]
        if(search){
            _options = _options.filter(item => {
                return item.identifier.includes(search)
            })
            
        }
        setFilteredOptions(_options)
    }, [search, options])

    return (
        <SimpleModal open={open} onClose={onClose} >
            <div style={{marginTop:16}}>
                <Typography>Seleccionar terminal</Typography>
                <TextField
                    variant="standard"
                    fullWidth
                    placeholder='Ingrese el nombre de la terminal...'
                    onChange={(e) => setSearch(e.currentTarget.value)}
                    InputProps={{
                        endAdornment:<Icon>search</Icon>
                    }} />
                <div className={classes.container}>
                    {loading ? (
                        <Grid container direction='column' alignItems='center'>
                            <Grid item>
                                <CircularProgress size={40} />
                            </Grid>
                            <Grid item>
                                <Typography variant='body2'>Cargando...</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <div>
                            <Grid container>
                                {filtered_options.map(item => {

                                    //const fullname = `${item.first_name ? item.first_name : ''} ${item.fathers_last_name ? item.fathers_last_name : ''} ${item.mothers_last_name ? item.mothers_last_name : ''}`

                                    return(
                                        <Grid item xs={12} key={item.id_bank_terminal} >
                                            <div className={classes.list_item} onClick={()=>onSelectedItem({bank_terminal_id:item.id_bank_terminal})}>
                                                <Typography>{item.identifier}</Typography>
                                            </div>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    )}
                </div>
            </div>
        </SimpleModal>
    );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        marginTop:32,
        maxHeight:300,
        overflowY:'auto',
        overflowX:'hidden'
    },
    list_item:{
        padding:'12px 4px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:grey[100]
        }
    }
}))

export default BankTerminalModal;