import axios from 'axios'
const { mainServer} = require("../../../variables/config");
//gets
export const request_rolesettings = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/role/list${params}` )
    return response.data.data
}

export const request_rolesetting = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/role/${id}`)
    return response.data.data
}

//posts
export const request_create_rolesetting = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/role`, data )
    // console.log(response)
    return response.data.data
}


//patchs
export const request_update_rolesetting = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/role/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_rolesetting = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/role/${id}`)
    return response.data.data
}
// Images
export const request_upload_role_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/role/${id}/icon`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

//deletes img
export const request_delete_image_file = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    console.log(data)
    let response = await mainServer.delete(`/role/${id}/icon/${data}`)
    return response.data.data
}




// User
export const request_UserAll= async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/user${params}` )
    return response.data.data
}
