import React, {useState} from 'react'
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import { translations as translations_esp } from '../../texts/esp/discounts-translations'
import { Hidden } from '@mui/material'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import Celufarma from './views/Celufarma/Celufarma'
import Celudoc from './views/Celudoc/Celudoc'


const Discounts = (props) => {

    const content = translations_esp

    const {history} = props

    const [view, setView] = useState(1)
  
    const menu = content.menu
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <Celufarma history={history} content={content.views.celufarma} />
            break
        case 2:
            contentView = <Celudoc history={history} content={content.views.celudoc} />
            break
        default:
            break
    }

    return (
        <Page title={content.title}>
        <Hidden mdDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={(id)=>setView(id)} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={(id)=>setView(id)} />
        </Hidden>
        <div style={{marginTop:20}}>
            {contentView}
        </div>
        </Page>
    );
}

export default Discounts