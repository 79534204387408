const { mainServer, BASIC_AUTH_HEADER } = require("../../../variables/config");

//gets
export const request_saleproducts = async(params) => {
    //const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/saleproduct/extended${params}` )
    return response.data.data
}

export const request_saleproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/saleproduct/${id}` )
    return response.data.data
}

export const request_products = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/list` )
    return response.data.data
}

//posts
export const request_create_saleproduct = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/saleproduct`, data)
    return response.data.data
}


//patchs
export const request_update_saleproduct = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/saleproduct/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_saleproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/saleproduct/${id}`)
    return response.data.data
}

