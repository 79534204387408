import { useEffect, useState } from "react"
import { onConvertUTCtoDate, onGetErrorMessage, onGetLineItemDiscount, onGetProductItemPrice, onGetProductName, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"
import queryString from 'query-string'
import moment from 'moment'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import catalogs from "../../../texts/esp/catalogs"


const useOrders = ({history, orders_table, orders_search, orders_filters, orders_startDate, orders_endDate, onUpdateFilters, onUpdateStartdate, onUpdateEnddate, onUpdateTable}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)
    const [corte, setCorte] = useState(false)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'ordered_at'})
    const [search, setSearch] = useState('')
    const [filters, setFilters] = useState({
        status:-1,
        payment_status:-1
    })

    const [modals, setModals] = useState({
        add:false,
        delete:false
    })

    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))

    const [settings, setSettings] = useState([
        {id:'SALES', label:'Ventas', icon: 'point_of_sale', info:'Esta sección permite generar un reporte de las ventas de acuerdo a los filtros seleccionados'},
        {id:'PURCHASE', label:'Recompra', icon: 'dns', info:'Esta sección genera una lista de compra para actualizar inventario, el factor es de 2 por cada unidad vendida'},
    ])

    //console.log(moment().startOf('day').format('YYYY-MM-DD hh:mm:ss'))
    //console.log(moment().endOf('day').format('YYYY-MM-DD hh:mm:ss'))

    useEffect(() => {
        actions.onInitModule()
    }, [orders_table, orders_filters])

    const onConvertDateToUtc = (_date) =>{
        if(!_date) return null
        const momentdata = moment(_date)
        if(!momentdata.isValid()) return null

        return moment(_date).add(6, 'hours').format("YYYY-MM-DD h:mm:ss")
    }

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = orders_table
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`
        
        let filtersArgs = [
            {"field":"ordered_at","operator":">=","value":`${onConvertDateToUtc(orders_startDate)}`},
            {"field":"ordered_at","operator":"<=","value":`${onConvertDateToUtc(orders_endDate)}`}
        ]
      
        if(orders_filters.status !== -1) filtersArgs.push({"field":"order_status_id","operator":"=", "value":`${orders_filters.status}`}) 

        if(orders_filters.payment_status !== -1) filtersArgs.push({"field":"payment_status_id","operator":"=", "value":`${orders_filters.payment_status}`})
    
        if(orders_search)filtersArgs.push({"field":"id_order","operator":"LIKE", "value":`%${orders_search}%`})

        if(filtersArgs.length){
            let finalArgs = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
    
        return params 
    }

    const onGetReportParams = () =>{

        const {order_by, order} = orders_table
        const params2send = {order_by:order_by, order:order,}
        let filtersArgs = [
            {"field":"ordered_at","operator":">=","value":`${onConvertDateToUtc(orders_startDate)}`},
            {"field":"ordered_at","operator":"<=","value":`${onConvertDateToUtc(orders_endDate)}`}
        ]
        if(orders_filters.status !== -1) filtersArgs.push({"field":"order_status_id","operator":"=", "value":`${orders_filters.status}`}) 
        if(orders_filters.payment_status !== -1) filtersArgs.push({"field":"payment_status_id","operator":"=", "value":`${orders_filters.payment_status}`})
        if(orders_search)filtersArgs.push({"field":"id_order","operator":"LIKE", "value":`%${orders_search}%`})
        console.log(filtersArgs)
        const finalArgs = {"AND":filtersArgs}
        const params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`

        return params

    }

    

    const onCleanData2Excel = (_data) => {
  
        let table = [['ID', 'FECHA', 'USUARIO', 'ESTATUS DE ORDEN', 'ESTATUS DE PAGO', 'METODO DE PAGO', 'CANTIDAD PEDIDA', 'SKU', 'PRODUCTO', 'PRECIO POR PIEZA', 'DESCUENTO', 'IMPUESTOS', 'CUPÓN DE DESCUENTO', 'TOTAL']]
        if(_data && _data.length){
          _data.forEach(item => {

            //console.log(item.ordered_at)
            console.log(item)
    
              let status = ''
              if(item.order_status_id){
                  const temp = catalogs.order_statuses.find(el => el.value === item.order_status_id)
                  if(temp) status = temp.label
              }

              let payment_status = ''
              if(item.payment_status_id){
                const temp = catalogs.payment_statuses.find(el => el.value === item.payment_status_id)
                if(temp) payment_status = temp.label
              }

              let payment_type = ''
              if(item.store_payment_type_id){
                const temp = catalogs.store_payment_types.find(el => el.value === item.store_payment_type_id)
                //console.log('Hay metodo de pago', item.store_payment_type_id, temp)
                if(temp) payment_type = temp.label
              }

              if(item.cart && item.cart.line_items.length !== 0){

                item.cart.line_items.forEach((el, ind) => {

                    //console.log(onGetProductItemPrice(el))
                    //const _total = onGetProductItemPrice(el).current

                    const global_discount = parseFloat(item.code_discount) / item.cart.line_items.length

                    table.push([
                        item.id_order,
                        onConvertUTCtoDate(item.ordered_at, 'YYYY-MM-DD HH:mm:ss'),
                        item.user,
                        status,
                        payment_status,
                        payment_type,
                        el.quantity,
                        el.sku,
                        `${el?.name ?? ''} ${el?.sub_name ?? ''} ${el?.presentation ?? ''}`,
                        el.price,
                        onGetLineItemDiscount(el),
                        el.tax,
                        global_discount,
                        parseFloat(el.subtotal) + parseFloat(el.tax) - global_discount
                        //parseFloat(item.payment_total)
                    ])
                    
                });
                
              }
              
    
              
          })
        }
        return table
    }

    const onCleanPurchaseData2Excel = (_data, factor) => {
  
        let table = [['EAN', 'Descripcion','Cantidad']]
        if(_data && _data.length){
          _data.forEach(item => {
            //console.log(item.ordered_at)
                item.cart.line_items.map(el => {
                    table.push([
                        el.sku,
                        onGetProductName(el),
                        el.quantity*factor
                    ])
                })
          })
        }
        return table
    }

    const s2ab = (s) =>{ 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`/order/list${params}`)
                //console.log(req)
                setData(req.data.data.orders)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onSettingSelected: (setting) => {
            console.log(setting)
            switch(setting.id){
                case 'SALES':
                    actions.onGenerateReport()
                    return
                case 'PURCHASE':
                    actions.onGeneratePurchaseReport()
                    return
                default:
                    return
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeFilters: (_key, _value) => {
            //setFilters({...filters, [_key]:_value})
            let temp = {...orders_filters}
            temp[_key] = _value
            onUpdateFilters(temp)
        },
        onChangeDate: (value, id) => {
            //anterior
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            let aux
            if(id === 'start_date'){
                aux = moment(value)
                console.log()
                if(moment(endDate).diff(aux) > 0) setStartDate(value)
            }
            if(id === 'end_date'){
                aux = moment(value)
                if(aux.diff(moment(startDate)) > 0) setEndDate(value)
            } */

            let newTableFilter = {...orders_table}
            newTableFilter.offset = 0
            onUpdateTable(newTableFilter)
            let aux
            if(id === 'start_date'){
                aux = moment(value)
                console.log()
                if(moment(orders_endDate).diff(aux) > 0) onUpdateStartdate(value)
            }
            if(id === 'end_date'){
                aux = moment(value)
                if(aux.diff(moment(orders_startDate)) > 0) onUpdateEnddate(value)
            }

        },
        onDeleteItem: async(item) =>{
            const {id_order} = item
            setSelected(id_order)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            const {id_order} = item
            history.push(`/orders/${id_order}`)
        },
        onGenerateReport: async() => {
            try {
                //setSending(true)
                updateAuthorizationHeader(private_server)
                const params = onGetReportParams()
                console.log('ESTO ES LA DESCARGA DEL CORTE')
                console.log(params)
                let req_orders = await private_server.get(`/order/list${params}`)
                const _orders = req_orders.data.data.orders
                //console.log(_orders)
                if(_orders.length !== 0){setCorte(true)}else{setCorte(false)}    
                
                /* const new_orders = _orders.map(async(item) => {
                    const req_order = await private_server.get(`/order/${item?.id_order}`)
                    const _order = req_order.data.data.order
                    return _order
                }) */

                const new_orders = await Promise.all(_orders.map(async (item) => {
                    //if(item.wholesale !== new_wholesale){
                        let _order = null
                        try{                                        
                            const req_order = await private_server.get(`/order/${item?.id_order}`)
                            _order =  req_order.data.data.order
                            //console.log(_order) 
                        }catch(error){
                            console.log(error)
                        }
                        //setLoading(false)
                    //}
                        return _order
                }))

                console.log(new_orders)
                

                const excel_data = onCleanData2Excel(new_orders)
                console.log(excel_data)
                const sheets = [
                    {name:'Reporte', data:excel_data}
                ]
        
                let wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Corte de pedidos",
                    Subject: "Detailed Data",
                    Author: "Celu Health",
                    CreatedDate: new Date()
                };
        
                for(let i=0; i<sheets.length; i++){
                    wb.SheetNames.push(sheets[i].name);
                    const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
                    wb.Sheets[sheets[i].name] = temp;
                }
        
                let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
                FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),`ventas-${moment().format('YYYYMMDD')}.xlsx`);
            } catch (error) {
                console.log(error)
            }
            //setSending(false)
    
        },
        onGeneratePurchaseReport: async() =>{
            try {
                //setSending(true)
                updateAuthorizationHeader(private_server)
                const params = onGetReportParams()
                console.log('ESTO ES LA DESCARGA DEL COMPRAS')
                console.log(params)
                let _orders = await private_server.get(`/order/list${params}`)
                console.log(_orders.data.data.orders)
                if(_orders.data.data.orders.length !== 0){setCorte(true)}else{setCorte(false)}    
                
                let orderResp = _orders.data.data.orders
                let ordersData = []

                for(let i=0; i<orderResp.length; i++){
                    const _singleOrder = orderResp[i]
                    try {
                        let orderRq = await private_server.get(`/order/${_singleOrder?.id_order}`)
                        ordersData.push(orderRq.data.data.order)

                    } catch (error) {
                        console.log('ERROR:', `/order/${_singleOrder?.id_order}`)
                    }
                }

                console.log(ordersData)


                const excel_data = onCleanPurchaseData2Excel(ordersData, 2)
                console.log(excel_data)
                const sheets = [
                    {name:'Lista de compra', data:excel_data}
                ]
        
                let wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Lista de compra",
                    Subject: "Detailed Data",
                    Author: "Celu Health",
                    CreatedDate: new Date()
                };
        
                for(let i=0; i<sheets.length; i++){
                    wb.SheetNames.push(sheets[i].name);
                    const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
                    wb.Sheets[sheets[i].name] = temp;
                }
        
                let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
                FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),`lista_de_compras-${moment().format('YYYYMMDD')}.xlsx`);
            
            } catch (error) {
                console.log(error)
            }
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected, filters, startDate, endDate, corte, settings}

    return {system, modals, actions, view_data } 
}

export default useOrders