import SimpleTable from './components/SimpleTable';
import AddModal from './modals/AddModal';
import EditModal from './modals/EditModal';
import DeleteModal from './modals/DeleteModal';
import useInventory from './useInventory';




const Inventory = ({content, id}) => {

    const {system, modals, actions, view_data} = useInventory({content, id})

    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} />
            <EditModal open={modals.edit} onClose={()=>actions.onChangeModalStatus('edit', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} 
                origin={view_data.selected} />
            <DeleteModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} onUpdateCompleted={actions.onInitModule} id={view_data.selected} /> 
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={view_data.tableFilter}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
                onChangeAvailabity={actions.onChangeAvailabity}
            />
        </div>
    )
}



export default Inventory