import React from 'react'
import { alpha, Icon, SvgIcon, Tooltip, useTheme, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { blue, blueGrey, indigo, grey } from '@mui/material/colors';
import cx from 'classnames';
import DisplayText from '../../../Texts/DisplayText';
import SVGIconOutlined from '../../DisplayData/SVGIconOutlined';

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: 52,
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		paddingLeft: 10,
		cursor: 'pointer',
		borderRadius: '30px 0px 0px 30px',
		'&:hover':{
			background: '#F8F8FE'
		}
	},
	containerShifted: {
		justifyContent: 'center',
		paddingLeft: 0
	},
	containerSelected: {
		background: '#F8F8FE',

	},
	title: {
		color: '#909090',
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		},
	},
	titleActive: {
		color: theme.palette.primary.main,
	},
	icon: {
		'& *':{
			fill: '#909090'
		}
	},
	iconActive: {
		'& *':{
			fill: theme.palette.primary.main
		}
	},
	iconStroke: {
		'& *':{
			stroke: '#909090'
		}
	},
	iconStrokeActive: {
		'& *':{
			stroke: theme.palette.primary.main
		}
	},
	icon_container:{
		padding: '0px 20px'
	},
	end_adornment:{
		position: 'absolute',
		top:0,
		right:0,
		width: 7,
		height: '100%',
		background: theme.palette.primary.main,
		borderRadius: '5px 0px 0px 5px'
	}
}));

const SidebarButton = (props) => {
	const classes = useStyles();
	const { name, icon, selected, url, onSelectedURL, isShifted, isMobile } = props;
	const theme = useTheme();

	return (
		<CostumTooltip title={name} placement='right-start'>
			<div
				className={cx({
					[classes.container]: true,
					[classes.containerShifted]: isShifted,
					[classes.containerSelected]: selected,
				})}
				onClick={() => onSelectedURL(url)}
			>
				<div className={classes.icon_container}>
					<SVGIconOutlined
						size={23}
						className={
							selected
								? url === '/users' || url === '/settings' || url === '/patients'
									? classes.iconStrokeActive
									: classes.iconActive
								: url === '/users' || url === '/settings' || url === '/patients'
								? classes.iconStroke
								: classes.icon
						}
						src={icon}
					/>
				</div>
				<div className={classes.title_container}>
					{!isShifted && !isMobile && (
						<Typography variant='body1' className={selected ? classes.titleActive : classes.title}>
							{name}
						</Typography>
					)}
				</div>
				{selected && <div className={classes.end_adornment} />}
			</div>
		</CostumTooltip>
	);
};

export default SidebarButton

const CostumTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      //color: 'rgba(0, 0, 0, 0.87)',
      color:grey[900],
      boxShadow: theme.shadows[3],
      fontSize: 16,
    },
}))(Tooltip);
