import React, {useEffect, useState} from 'react'
import SimpleTable from './components/SimpleTable'
import {request_mostsoldproducts, request_update_mostsoldproduct} from './requests'
import queryString from 'query-string'
import EditModal from './modals/EditModal'
import { translations as translations_esp } from '../../../texts/esp/tables-products-translations'
import DeleteModal from './modals/DeleteModal'
import AddModal from './modals/AddModal'
import { onGetErrorMessage } from '../../../shared/utility'


const MostSoldProducts = props => {

    const content = translations_esp

    const { history, onUpdateError, products_mostsold_search, products_mostsold_table, onUpdateProductsMostsoldSearch, onUpdateProductsMostsoldTable } = props

    const [sending, setSending] = useState(false)
    const [loading, setLoading] = useState(false)
    const [products, setProducts] = useState([])

    const [totalItems, setTotalItems] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'product'})
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        const initModule = async() => { 
        onRequestModelData()
        }
        initModule()
    }, [products_mostsold_table])


    const onRequestModelData = async() => { 
        try{
            setLoading(true)
            const {limit, offset, order_by, order} = products_mostsold_table
            const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order, currency_id:2}
            let params = `?${queryString.stringify(param2send)}` 
    
            if(products_mostsold_search){
                /* let filterArgs = [
                    {"field":"search_string","operator":"LIKE","value":`%${search}%`},
                ] */
                const _sku = parseInt(products_mostsold_search) !== NaN ? parseInt(products_mostsold_search) : ''
                console.log(_sku)
                let filterArgs = {"OR":[
                    {"field":"search_string","operator":"LIKE","value":`%${products_mostsold_search}%`},
                    {"field":"sku","operator":"LIKE","value":`%${_sku}%`},
                ]}
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
            }
    
            const _users = await request_mostsoldproducts(params)
            setProducts(_users.most_sold_product_data)
            setTotalItems(_users.count)
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
            
        }
        
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    }

    const onEdit = (item) =>{
        //console.log(item)
        const {id_most_sold_product} = item
        setSelected(id_most_sold_product)
        setEditModal(true)
    }

    const onDelete = (item) =>{
        //console.log(item)
        const {id_most_sold_product} = item
        setSelected(id_most_sold_product)
        setDeleteModal(true)
    }

    const onUpdateCompleted = async() => {
        onRequestModelData()
        setEditModal(false)
    }

    const onChangeStatus = async (item) => {
        const {id_most_sold_product, msp_enabled} = item
        const status = msp_enabled === 1 ? 0 : 1
        //console.log(id_most_sold_product)
        //console.log(status)
        try{
            setSending(true)
            await request_update_mostsoldproduct(id_most_sold_product, {enabled: status})
            onUpdateCompleted()
        }catch(error){
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
        }
        setSending(false)
    }
 
    return(
        <div>
            <AddModal open={add_modal} onClose={() => setAddModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} existing_products={products}/>
            <EditModal open={edit_modal} onClose={() => setEditModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
            content={content.modal} id={selected} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} 
            id={selected}/>
            <SimpleTable
                data={products} 
                search={products_mostsold_search}
                onChangeSearch={onUpdateProductsMostsoldSearch}
                tableFilter={products_mostsold_table}
                total={totalItems}
                loading={loading}
                sending={sending}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => onUpdateProductsMostsoldTable(data)}
                onSelectedItem={onSelectedItem}
                onEdit={onEdit}
                onDelete={onDelete}
                onChangeStatus={onChangeStatus}
                />
        </div>
    )
}

export default MostSoldProducts
