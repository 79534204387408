import React, { useState } from 'react';
import PictureModal from '../../../../../components/Overlays/PictureModal';
import axios from 'axios'
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob, updateAuthorizationHeader } from '../../../../../shared/utility';
import { translations as translations_esp } from '../../../../../texts/esp/modals-translations'
import { private_server } from '../../../../../variables/config';


const ChangeImageModal = (props) => {

    const {id, open, onClose, onUpdateCompleted} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)
            //console.log(file)
            const _file = await onGetImageURL2Blob(file)
            //console.log(_file)
            //await request_upload_image(id, _file, {document:'image', type:onGetFileExtension(_file.name)})
            const data = {document:"image", type:onGetFileExtension(_file.name)}
            updateAuthorizationHeader(private_server)
            const response = await private_server.post(`medications/${id}/image`, data)
            const options = {headers: {'Content-Type': _file.type}}
            delete axios.defaults.headers.common["Authorization"]
            await axios.put(response.data.data.upload_url, _file, options);
            onClose()
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    const onDelete = async() => {
        try {
            setError(null)
            setLoading(true)
            updateAuthorizationHeader(private_server)
            await private_server.delete(`medications/${id}/image?document=image`)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error.response)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} aspect_ratio={1} disable={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;