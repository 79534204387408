import { Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import ActivesTable from "../components/ActivesTable"


const Actives = ({actives, actions}) => {

    const classes = useStyles()

    return (
        <div>       
            <Grid container alignItems='center' style={{marginBottom:20}} >
                <Grid item ><Typography variant='subtitle1'>Sustancias</Typography></Grid>
                <Grid item xs />
                <Grid item>
                    <IconButton
                        className={classes.add_button}
                        onClick={() => actions.onChangeModalStatus('add_active', true)}
                        size="large">
                        <Icon>add</Icon>
                    </IconButton>
                </Grid>
            </Grid>
            <SimpleCard variant='secondary' >
                <ActivesTable 
                    data={actives}                            
                    onAddRegister={() => actions.onChangeModalStatus('add_active', true)}
                    onDeleteItem={actions.onDeleteActive}
                    onSelectedItem={actions.onEditActive}
                    />
            </SimpleCard>
        </div>
    );
}

export default Actives

const useStyles = makeStyles(theme => ({
    
    add_button:{
        background:theme.palette.primary.main,
        color:'white',
        width:32,
        height:32,
        '&:hover':{
            background:theme.palette.primary.main
        }
    }
}))