import React, {useEffect, useState} from 'react'
import { CircularProgress, Grid } from '@mui/material'
import SimpleTable from './components/SimpleTable'
import EditModal from './modals/EditModal'
import DeleteModal from './modals/DeleteModal'
import AddModal from './modals/AddModal'
import queryString from 'query-string'
import { translations as translations_esp } from '../../../texts/esp/tables-banners-translations'
import { request_promotionalbanners, request_update_promotionalbanner } from './requests'


const PromotionalBanners = props => {

    const content = translations_esp

    const { history } = props

    const [loading, setLoading] = useState(false)
    const [banners, setBanners] = useState([])

    const [totalItems, setTotalItems] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'order'})
    const [search, setSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        const initModule = async() => {      
            onRequestModelData()
        }
        initModule()
    }, [tableFilter])


    const onRequestModelData = async() => {  
        try{
            setLoading(true)
            const {limit, offset, order_by, order} = tableFilter
            const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
            let params = `?${queryString.stringify(param2send)}` 
    
            if(search){
                let filterArgs = {"AND":[{"field":"name","operator":"LIKE","value":`%${search}%`}]}
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` 
            }

            const _banners = await request_promotionalbanners(params)
            setBanners(_banners.promotional_banners)
            setTotalItems(_banners.count)
            setLoading(false)
        }catch(e){
            console.log(e)
            setLoading(false)
        }
        
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    /* const onChangeFilter = (tag, value) => { 
        let newFilter = onUpdateFilter(tag, value)
        let newTableFilter = {...tableFilter}
        newTableFilter.offset = 0
        setFilter(newFilter)
        setTableFilter(newTableFilter)
    }

    const onUpdateFilter = (id,value) => {
        let temp = {...filter}
        temp[id] = value
        return temp
    } */

    const onSelectedItem = (item, type) => {
        const {id_promotional_banner} = item
        setSelected(id_promotional_banner)
        if(type === 'edit') setEditModal(true)
        if(type === 'delete') setDeleteModal(true)
        //if(type === 'images') setImagesModal(true)
    }

    
    const onUpdateCompleted = async() => {
        onRequestModelData()
        //setEditModal(false)
        setDeleteModal(false)
    }

 
    const onChangeStatus = async (item, new_status) => {
        const {id_promotional_banner} = item
        try{
            await request_update_promotionalbanner(id_promotional_banner, {enabled: new_status})
            onUpdateCompleted()
        }catch(error){
            console.log(error)
        }
    }

    
 
    return(
        <div>
            <AddModal open={add_modal} onClose={() => setAddModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
                content={content.modal}/>
            <EditModal open={edit_modal} onClose={() => setEditModal(false)} history={history} onUpdateCompleted={onUpdateCompleted}
                content={content.modal} id={selected} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} id={selected}/>
            <SimpleTable
                data={banners} 
                search={search}
                onChangeSearch={onChangeSearch}
                tableFilter={tableFilter}
                total={totalItems}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onSelectedItem={onSelectedItem}
                onChangeStatus={onChangeStatus}
                content={content.table}
                />
        </div>
    )
}

export default PromotionalBanners
