import React, {useEffect, useState} from 'react'
import SimpleTable from './components/SimpleTable'
import AddProductModal from './modals/AddProductModal/AddProductModal'
import {request_products, request_products_search} from './requests'
import queryString from 'query-string'
import { onGetErrorMessage, updateAuthorizationHeader } from '../../../shared/utility'
import { private_server, public_server } from '../../../variables/config'


const Products = props => {

    const { history, onUpdateError, products_general_table, onUpdateProductsGeneralTable, products_general_search, onUpdateProductsGeneralSearch } = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [products, setProducts] = useState([])
    const [addModal, setAddModal] = useState(false)

    const [totalItems, setTotalItems] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState(null)

  

    useEffect(() => {
        onRequestModelData()
    }, [products_general_table])


    const onRequestModelData = async() => {  
        try{
            console.log('refresh')
            setLoading(true)

            const {limit, offset, order_by, order} = products_general_table
            let param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
            let params = `?${queryString.stringify(param2send)}` 
            //let _users = await request_products(params)
            
    
            if(products_general_search){
                //with search route
                //console.log(parseInt(search) !== NaN)
                const _sku = parseInt(products_general_search) === NaN ? '' : parseInt(products_general_search)
                console.log(_sku)
                const _filter = {"OR":[
                    {"field":"search_string","operator":"LIKE","value":`%${products_general_search}%`},
                    {"field":"sku","operator":"LIKE","value":`%${_sku}%`}
                ]}
                //param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                params = `?${queryString.stringify(param2send)}&filter=${encodeURIComponent(JSON.stringify(_filter))}` 
                //_users = await request_products_search(params)
                //_products = await public_server.get(`/product/filter/currency/2/${params}`)
            }
            //console.log(params)
            //updateAuthorizationHeader(public_server)
            //let _products = await public_server.get(`product/filter/currency/2${params}`)
            const _products = await request_products(params)
            //console.log(_products.products)
            setProducts(_products.products)
            //console.log(products)
            setTotalItems(_products.count)
            setLoading(false)
        }catch(error){
            console.log(error)
            setLoading(false)
            onUpdateError(onGetErrorMessage(error))
            
        }  
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onSelectedItem = (item) => {
        const {id_product} = item
        history.push(`/products/${id_product}`)
    }
  
    const onUpdateUsers = async() => {
        onRequestModelData()
        setAddModal(false)
    }

    const onChangeFilter = (val) => {
        let temp = {...products_general_table}
        temp.order_by = val
        onUpdateProductsGeneralTable(temp)
    }

    const onChangeStatus = async (item) => {
        const {id_product, enabled} = item
        const status = enabled === 1 ? 0 : 1
        //console.log(id_most_sold_product)
        //console.log(status)
        try{
            setSending(true)
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/product/${id_product}`, {enabled: status})
            onUpdateUsers()
        }catch(error){
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    return(
        <div>
            <AddProductModal open={addModal} onClose={() => setAddModal(false)} history={history} onRequestUsers={onUpdateUsers}/>
            <SimpleTable
                data={products??[]} 
                search={products_general_search}
                onChangeSearch={onUpdateProductsGeneralSearch}
                tableFilter={products_general_table}
                total={totalItems}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onUpdateTableFilter={(data) => onUpdateProductsGeneralTable(data)}
                onSelectedItem={onSelectedItem}
                onChangeFilter={onChangeFilter}
                onChangeStatus={onChangeStatus}
                sending={sending}
                />
        </div>
    )
}

export default Products
