import { Grid, Typography } from "@mui/material"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { onGetFormatNumber } from "../../../../shared/utility"


const PaymentDetails = ({payment_subtotal, payment_total, code_discount, payment_taxes, shipping_cost}) => {

    return(
        <SimpleCard>
            <div style={{marginBottom:20}}>
                <Typography color='primary'>Detalle de cobro</Typography>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs><Typography>Subtotal productos:</Typography></Grid>
                        <Grid item ><Typography>{onGetFormatNumber(payment_subtotal)}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs><Typography>Impuestos:</Typography></Grid>
                        <Grid item ><Typography>{onGetFormatNumber(payment_taxes)}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs><Typography>Costo de envío:</Typography></Grid>
                        <Grid item ><Typography>{onGetFormatNumber(shipping_cost)}</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs><Typography>Descuento:</Typography></Grid>
                        <Grid item ><Typography>{onGetFormatNumber(code_discount)}</Typography></Grid>
                    </Grid>
                </Grid>   
            </Grid>
            <div style={{borderTop:'0.5px solid #D3D3D3', padding:'20px 0px', marginTop:20}}>
                <Grid container>
                    <Grid item xs><Typography variant='subtitle2'>Total</Typography></Grid>
                    <Grid item ><Typography variant='subtitle2'>{onGetFormatNumber(payment_total)}</Typography></Grid>
                </Grid>
            </div>
        </SimpleCard>
    )
}

export default PaymentDetails