import { useEffect, useState } from "react"
import { onConvertUTCtoDate, onGetCatalogs, onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import queryString from 'query-string'
import XLSX from 'xlsx'
import FileSaver from 'file-saver'
import moment from "moment"
import { private_server } from "../../../../../variables/config"




const useInventorSection = ({history, warehouseManager, inventoryManager}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [warehouses, setWarehouses] = useState([])
    const [warehouse, setWarehouse] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:50, offset:0, order:1, order_by:'id_inventory'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        edit:false
    })

    const [excel_data, setExcelData] = useState([])
    const [download, setDownload] = useState(false)

    const [view, setView] = useState('inventories')

    

    // Request list of warehouses
    useEffect(() => {
        warehouseManager.actions.onGetAll()
    }, [])

    // Update local warehouses options
    useEffect(() => {
        const _options = onGetCatalogs(warehouseManager.data.warehouses, 'id_warehouse', 'name')
        setWarehouses(_options)
        if(!warehouse && _options.length) setWarehouse(_options[0])
    }, [warehouseManager.data.warehouses])

    useEffect(() => {
        if(warehouse){
            console.log('Buscar de nuevo')
            actions.onUpdateInventory()
        }
    }, [warehouse, tableFilter])

    


    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}

        let params = `?${queryString.stringify(params2send)}`
      
        let filterArgs = []

        if(search){
            filterArgs.push(
                {"field":"product","operator":"LIKE","value":`%${search}%`},
                {"field":"sku","operator":"LIKE","value":`%${search}%`},
                )
        }

        if(filterArgs.length){
            let finalArgs = {"OR":filterArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }

        return params 
    }

    const onGetBatchesParams = (_product) => {
        let params = ''
        const pagination = {
            order_by:'expiry_date',
            order:1
        }
        const filter = JSON.stringify({"AND":[
            {"field":"enabled","operator":"=","value":1},
            {"field":"warehouse_id","operator":"=","value":warehouse?.value},
        ]})
        params = `?${queryString.stringify(pagination)}&filter=${filter}`

        return params
    }

    const onCleanData2Excel = (_inventory, _batches) => {
  
        //let table = [['Sku','No. de LOTE','Ubicación','Descripción','Cantidad']]
        let table = [['SKU','LOTE','DESCRIPCIÓN','FECHA DE RECEPCIÓN','FECHA DE CADUCIDAD','PROVEEDOR','CANTIDAD']]
        if(_inventory && _inventory.length){
            
            
            _inventory.forEach(item => {
                let quantity = 0
                
                const product_batches = _batches.filter(batch => batch.product_id === item.product_id)

                if(product_batches && product_batches.length !== 0 ){
                    product_batches.forEach( (ele, ind) => {
                        //console.log(ele.available_quantity)
                        quantity = quantity + parseInt(ele.available_quantity)

                        table.push([
                            item.sku,
                            ele.number,
                            item.product,
                            onConvertUTCtoDate(ele.reception_date, 'YYYY-MM-DD'),
                            onConvertUTCtoDate(ele.expiry_date, 'YYYY-MM-DD'),
                            ele.provider,
                            quantity    
                        ])
                    })
                }else{
                    table.push([
                        item.sku,
                        '',
                        item.product,
                        '',
                        '',
                        '',
                        quantity    
                    ])
                }
                /* if(product_batches && product_batches.length !== 0 && product_batches.length !== 1){
                    //console.log(product_batches)
                    product_batches.forEach( (ele, ind) => {
                        //console.log(ele.available_quantity)
                        quantity = quantity + parseInt(ele.available_quantity)

                        if(ind === 0){
                            table.push([
                                item.sku,
                                `No. de lote ${ele?.number ?? ''},  Proveedor ${ele?.provider ?? ''}, Costo de compra: $${ele?.provider_cost ?? '0'}`,
                                item.location, 
                                item.product,
                                quantity,
                                   
                            ])
                        }else{
                            table.push([
                                '',
                                `No. de lote ${ele?.number ?? ''},  No. de orden ${ele?.purchase_order_id ?? ''}, Costo de compra: $${ele?.provider_cost ?? '0'}`,
                                '', 
                                '',
                                '',
                                   
                            ])
                        }
                        
                    })
                }else{
                    table.push([
                        item.sku,
                        '',
                        //lotes.length > 0 ? lotes : '',
                        item.location, 
                        item.product,
                        //item.sku,
                        quantity,
                           
                    ])
                } */

               
         
            })
                
        }
            
          
        return table
    }

    const s2ab = (s) =>{ 
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;    
    }

    const actions = {
        onUpdateInventory: async() => {
            try {
                setLoading(true)
                const params = onGetParams()
                
                //console.log('params', params)
                const total_products = await inventoryManager.actions.onGetAll(warehouse?.value, params)
                setTotal(total_products)
                setLoading(false)
            } catch (error) {
                console.log(error)
            }
        },
        onUpdateWarehouse: (value) => {
            console.log(value)
            setWarehouse(value)
        },
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                //let req = await private_server.get(`/users/all${params}`)
                //console.log(req)
                const req = await inventoryManager.actions.onGetAll(warehouse?.value, params)
                //console.log(req)
                //setData(req.data.data.inventories_data)
                setTotal(req)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            console.log('Actualizar campo', value)
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        /* onDeleteItem: async(item) =>{
            const {id_substance} = item
            setSelected(id_substance)
            actions.onChangeModalStatus('delete', true)
        }, */
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        },
        onGenerateReport: async() => {
            try {
                setDownloading(true)
                updateAuthorizationHeader(private_server)
                const batches_params = onGetBatchesParams()
                
                let _data = await private_server.get(`/inventory/warehouse/${warehouse?.value}?order_by=location&order=0`)

                const filter1 = _data.data.data.inventories_data.filter(el => el.location !== null)
                const filter2 = filter1.filter(el => el.location !== '')
                //console.log(filter2)

                let _batches = await private_server.get(`/batch/list${batches_params}`)
                //console.log(_data.data.data.inventories_data)         
                const excel_data = onCleanData2Excel(filter2, _batches.data.data.batches)
              
                //console.log(excel_data)
                //console.log(excel_data.length)

                const sheets = [
                    {name:'Reporte', data:excel_data}
                ]
                
                console.log(sheets)
        
                let wb = XLSX.utils.book_new();
                wb.Props = {
                    Title: "Inventario",
                    Subject: "Detailed Data",
                    Author: "Trionix Technologies",
                    CreatedDate: new Date()
                };
        
                for(let i=0; i<sheets.length; i++){
                    wb.SheetNames.push(sheets[i].name);
                    let temp = XLSX.utils.aoa_to_sheet(sheets[i].data)          
                    //console.log(temp)
                    
                    wb.Sheets[sheets[i].name] = temp;

                    //const ws = wb.Sheets[sheets[i].name]

                    /* let counter = 0
                    for(let j=0; j<sheets[i].data.length; j++){
                        
                        console.log(sheets[i].data[j][1])
                        if(sheets[i].data[j][1].length > 0 && j>0 ){
                            counter++;
                            for(let k=0; k<sheets[i].data[j][1].length; k+=3){
                                counter+=3;
                                console.log(counter)
                               
                                
                                XLSX.utils.sheet_add_aoa(wb, [
                                    [sheets[i].data[j][0], sheets[i].data[j][1][0], sheets[i].data[j][2], sheets[i].data[j][3], sheets[i].data[j][4]],                             // <-- Write 1 to cell B3
                                    ['', sheets[i].data[j][1][1], '', '', ''],                                // <-- Do nothing in row 4
                                    ['', sheets[i].data[j][1][2], '', '', ""]  // <-- Write "abc" to cell E5
                                ], { origin:"A1"})
                                
                            }
                            //XLSX.utils.sheet_add_aoa(ws, [[sheets[i].data[j][1][0]]], {origin:`B${2+j}`})
                        }
                    } */
                    //console.log(ws)

                }
                
                console.log(wb)
        
                let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
                FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),`inventario_${moment().format('YYYYMMDD')}.xlsx`);
            } catch (error) {
                console.log(error)
            }
            setDownloading(false)
    
        }
    }

    const system = {loading, sending, error, downloading}
    const view_data = {data, total, tableFilter, search, selected, warehouses, warehouse, excel_data, download}

    return {system, modals, actions, view_data } 
}

export default useInventorSection