import React from 'react'
import {
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components/Texts/DisplayText'


//const content = translations_esp.tables.products

const header = [
    {id:1, label:'Tipo de impuesto'},
    {id:2, label:'Tasa (%)'},
    {id:3, label:''},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        width:12,
        height:12,
        fontSize:6
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        //minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:12,
        padding:'20px 0px'
    }
}))

const TaxesTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onDeleteItem, onAddTax, 
        onEditTax, onDeleteTax} = props

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            {/* <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid> */}
                            <Grid item><IconButton className={classes.button} onClick={onAddTax} size="large"><Icon style={{fontSize:14}}>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle1' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {tax_type, rate} = item

                                    return (
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell}  ><DisplayText>{tax_type}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}  ><DisplayText>{rate}</DisplayText></TableCell>      
                                            <TableCell padding='checkbox' className={classes.cell} >
                                                <Grid container wrap='nowrap'>
                                                    <Grid item><IconButton onClick={()=>onEditTax(item)} style={{padding:6}} size="large"><Icon>edit</Icon></IconButton></Grid>
                                                    <Grid item><IconButton onClick={()=>onDeleteTax(item)} style={{padding:6}} size="large"><Icon>delete</Icon></IconButton></Grid>
                                                </Grid>
                                            </TableCell>                                 
                                        </TableRow>
                                    );
                                })}       
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                {/* <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/> */}
            </div> : null}
            {data.length === 0 && !loading  ? (
                
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        {/* <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid> */}
                        <Grid item style={{padding:'0px 12px', textAlign:'center'}}>
                            <Typography variant='caption' >no existen impuestos asociados a este producto</Typography>
                        </Grid>
                    </Grid>
                </div>  
                        
            ) : null}       
        </div>
    );
}

export default TaxesTable