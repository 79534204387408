import { CircularProgress, Grid, Typography } from "@mui/material"
import ContainedButton from "../../../../../components/Actions/ContainedButton"
import InputForm from "../../../../../components/Forms/InputForm"
import RichTextForm from "../../../../../components/Forms/RichTextForm"
import SelectForm from "../../../../../components/Forms/SelectForm"
import SimpleCard from "../../../../../components/Structure/Cards/SimpleCard"
import useMedical from "./useMedical"


const Medical = ({content, match, onUpdateError, onUpdateSending, onSuccess, catalogs}) => {

    const {system, actions, view_data} = useMedical({content, onUpdateError, onUpdateSending, onSuccess, match, catalogs})
    const {form1, form2} = view_data

    return (
        <SimpleCard>
            {system.loading ? <div style={{textAlign:'center'}}><CircularProgress /></div> :
            <Grid container spacing={4} >
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{content.cards.medical_info}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectForm data={form1.speciality_id} onChange={actions.onChangeForm1} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form1.sub_speciality_id} onChange={actions.onChangeForm1} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form1.license} onChange={actions.onChangeForm1} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='subtitle1'>{content.cards.personal_info}</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <SelectForm data={form2.language} onChange={actions.onChangeForm2} />
                </Grid>
                <Grid item xs={12} >
                    <InputForm data={form2.qualifications} onChange={actions.onChangeForm2} />
                </Grid>
                <Grid item xs={12} >
                    {/* <Typography variant='body2' color='textSecondary'>{content.form_medical.bio.label}</Typography>
                    <div style={{height:'300px', overflowY:'scroll', marginTop:'4px'}}>
                        <CKEditor
                            className='ck-editor__editable' 
                            editor={ClassicEditor}
                            data={description}
                            onChange={(e,editor) => {
                                const data = editor.getData()
                                setDescription(data)
                                if(data){setErrorBio('')}
                                else{setErrorBio(content.bio_error_msg)}
                            }}
                            config={{toolbar:{removeItems:['imageUpload' , 'insertMedia' ]}}}                                              
                        />
                    </div>
                    <Typography style={{color:'red', fontSize:14}} >{error_bio}</Typography> */}
                    <RichTextForm label={content.form_medical.bio.label} value={view_data.description} 
                        onChange={(value)=>actions.onSetDescription(value)} />
                    <Typography style={{color:'red', fontSize:14}} >{system.error_bio}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justifyContent='flex-end'>
                        <Grid item>
                            <ContainedButton color='primary' loading={system.sending} onClick={actions.onSubmit}>{content.cards.button}</ContainedButton>
                        </Grid>
                    </Grid>
                </Grid>                           
            </Grid>} 
        </SimpleCard>
    );
}

export default Medical