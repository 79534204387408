import { CircularProgress, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Page/Page';
import { actionTypes } from '../../../store/actions';
import Header from './components/Header';
import MembersRouteCard from './components/MembersRouteCard';
import RouteStatusCard from './components/RouteStatusCard';
import DeleteRouteModal from './modals/DeleteRouteModal';
import useRouteView from './useRouteView';
import AssignedRouteSection from './views/AssignedRouteSection/AssignedRouteSection';
import FinalRouteSection from './views/FinalRouteSection/FinalRouteSection';
import PendingRouteSection from './views/PendingRouteSection/PendingRouteSection';
import PreparingRouteSection from './views/PreparingRouteSection/PreparingRouteSection';

const RoutesView = ({user, history, match, onUpdateError}) => {

    const routeID = match.params.id
    const {system, view_data, actions,modals, form} = useRouteView({user, history, match, onUpdateError})

    const route_status = view_data.route ? view_data.route.route_status_id : 0
    let routeView = null

    console.log(route_status)

    switch(route_status){
        case 1:
            routeView = <PendingRouteSection route={view_data.route} form={form} modals={modals} actions={actions} sending={system.sending_next} />
            break
        case 2:
            routeView = <AssignedRouteSection {...view_data} form={form} modals={modals} actions={actions} {...system}/>
            break
        case 3:
            routeView = <PreparingRouteSection routeorders={view_data.routeorders} actions={actions} {...system} onUpdateError={onUpdateError} route={view_data.route}
                id_route={routeID} modals={modals} />
            break
        case 4:
            routeView = <FinalRouteSection routeorders={view_data.routeorders} actions={actions} form={view_data.cancel_form} modals={modals} status={route_status} 
                            delivery_man_id={view_data.route ? view_data.route.delivery_man_id : null}/>
            break
        case 5:
            routeView = <FinalRouteSection routeorders={view_data.routeorders} actions={actions} form={view_data.cancel_form} modals={modals} status={route_status} 
                            delivery_man_id={view_data.route ? view_data.route.delivery_man_id : null}/>
            break
        case 6:
            routeView = <FinalRouteSection routeorders={view_data.routeorders} actions={actions} form={view_data.cancel_form} modals={modals} status={route_status} 
                            delivery_man_id={view_data.route ? view_data.route.delivery_man_id : null}/>
            break
        case 7:
            routeView = <FinalRouteSection routeorders={view_data.routeorders} actions={actions} form={view_data.cancel_form} modals={modals} status={route_status}
                            delivery_man_id={view_data.route ? view_data.route.delivery_man_id : null} />
            break
        default:
            break
    }

    return ( 
        <Page title='Detalle de ruta' goback>
            <div>
                <DeleteRouteModal open={modals.delete_route} onClose={()=>actions.onUpdateModalStatus('delete_route', false)} onUpdateCompleted={()=>{history.push('/routes')}} id={routeID} />
                <Header route={view_data.route} actions={actions} status={route_status} />
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <MembersRouteCard route={view_data.route} actions={actions}/>
                            </Grid>
                            <Grid item xs={12}>
                                <RouteStatusCard route={view_data.route} actions={actions}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        {system.rloading && route_status !==2 ? <div style={{width:'100%', textAlign:'center'}}><CircularProgress /></div> :
                        routeView}
                    </Grid>
                </Grid>
            </div>
        </Page>
        
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
        //onUpdateCart: (cart) => dispatch({type:actionTypes.ECO_UPDATE_CART, cart})
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RoutesView);