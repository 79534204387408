
import React from 'react'
import { TextField, Grid, Typography, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { grey, red } from '@mui/material/colors'
import Autocomplete from '@mui/material/Autocomplete';
import DisplayText from '../Texts/DisplayText';
//import DisplayText from '../Texts/DisplayText';


const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[900],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `16px`,
      },
    },
  },
}))(TextField);

const AutocompleteFormAdd = props => {

    ///////////////////////////////////
    const {data, onChange, onInputChange, onAdd, add_register, onAdd2 } = props //onSearch
    const {isValid, isVisited, value, options, disabled, rules} = data;
    const {id, label,placeholder, helperText} = data.config;

    const classes = useStyles()

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        temp.value = item.value
        temp.isValid = true
        if(item.type === 'add'){
          onAdd()
          return
        }
        if(onAdd2){
          onAdd2(item.value)
          temp.value = null
        }
      }else{    
        temp.value = null
        temp.isValid = rules ? false : true
         
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if(data) {
      if(options) selectedOptions = options
      if(add_register) selectedOptions = [add_register, ...options]
      if(value !== null && value !== undefined ){
        const singleEl = options.find(el => el.value === value) 
        selectedValue = singleEl ? singleEl : selectedValue
      }
    }
    
    

    let inputlabel = null;
    if(label) inputlabel =  <DisplayText variant='subtitle2'  color={!isValid && isVisited ? 'error' : 'inherit'}>{label}</DisplayText>

    return(
      <div>
        <Autocomplete
        disabled={disabled}
        value={selectedValue}
        options={selectedOptions}
        getOptionLabel={option => option.label ?? option}
        renderInput={params => (<TextField {...params} variant='outlined' color='primary' label={label} error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helperText : null}/>)}
        onChange={onInnerChange}
        onInputChange={onInputChange}
        noOptionsText={<DisplayText>Sin opciones </DisplayText>}
        renderOption={(props, option) => {
          if(option.type === 'add'){
              return(
                  <React.Fragment>
                      <div {...props} >
                          <Grid container alignItems='center'>
                              <Grid item><Icon color='primary' className={classes.add_icon}>add</Icon></Grid>
                              <Grid item xs><DisplayText>{option.label}</DisplayText></Grid>
                          </Grid>
                          
                      </div>
                  </React.Fragment>
              )
          }
          return (
              <React.Fragment>
                  <div {...props} >
                      <DisplayText>{option.label}</DisplayText>
                  </div>
              </React.Fragment>
          )
        }}
        />
      </div>
      
    )
}

const useStyles = makeStyles(theme => ({
  add_icon:{
      background:theme.palette.primary.main,
      color:'white',
      borderRadius:'50%',
      marginTop:6, marginRight:8
  }
}))

export default AutocompleteFormAdd