import React, { useState, useEffect } from 'react';
//import { DASHBOARD_SETTINGS, private_server, public_server } from '../config';
import { updateAuthorizationHeader, onGetErrorMessage } from '../shared/utility';
import { private_server, public_server, DASHBOARD_SETTINGS } from '../variables/config';


const useBatches = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [batches, setBatches] = useState([])
    const [batch, setBatch] = useState([])
    const [total, setTotal] = useState(0)
    

    const actions = {
        onGetAll: async(params) => {
            setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                let url = `/batch/list`
                if(params) url += `${params}`
                const request = await private_server.get(url)
                const _total = request.data.data.count
                console.log(request.data.data.batches)
                setBatches(request.data.data.batches)
                setTotal(_total)
                
            } catch (error) {
                console.log(error)
                const _e = onGetErrorMessage(error)
                setError(_e.message)
                
            }
            setLoading(false)
        },
        onGetAllV2: async(params) => {
            let result = {
                data:[],
                count:0
            }
            updateAuthorizationHeader(private_server)
            const request = await private_server.get('/batch/list', {params:params})
            result.data = request.data.data.batches
            result.count = request.data.data.count
            return result
        },
        onCreate: async(_data) => {
            setSending(true)
            try {
                updateAuthorizationHeader(private_server)
                let url = `/batch`
                await private_server.post(url, _data)
                
            } catch (error) {
                //console.log(error)
                //const _e = onGetErrorMessage(error)
                //setError(_e.message)
                setLoading(false)
                throw error
                
            }
            setSending(false)
        },
        onEdit: async (_id, _data) => {
            try {
                updateAuthorizationHeader(private_server)
                let url = `/batch/${_id}`
                await private_server.patch(url, _data)
                
            } catch (error) {
                //console.log(error)
                //const _e = onGetErrorMessage(error)
                //setError(_e.message)
                //setLoading(false)
                throw error
                
            }
        },
        onEditOverride: async (_id, _data) => {
            try {
                updateAuthorizationHeader(private_server)
                let url = `/batch/${_id}/override`
                await private_server.patch(url, _data)
                
            } catch (error) {
                //setLoading(false)
                throw error
                
            }
        },
    }

    const data = {batches, batch, total}
    const system = {loading, sending, error}

    return {
        system, data, actions
    }
}
 
export default useBatches;