import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import AddModal from './modals/AddModal'
import system_translations from '../../../texts/system_translations'
import useDoctors from './useDoctors'
import { actionTypes } from '../../../store/actions'


const Doctors = props => {

    const { history, user, language, doctors_search, doctors_table, onUpdateSearch, onUpdateTable } = props
    const content = system_translations[language].views.users
    const catalogs = system_translations[language].catalogs
    const {system, modals, actions, view_data} = useDoctors({history, doctors_search, doctors_table})
    const {data, total, search, tableFilter, selected} = view_data
    

    /* const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])

    //modals
    const [addModal, setAddModal] = useState(false)
 
    //table
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_user',
    })
    const [total, setTotal] = useState(10)
    const [search, setSearch] = useState(null)
    


    useEffect(() => {
        const initModule = async() => {try {
            if(!user) return
            console.log(user)
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            console.log(params)
            const _pacients = await request_doctors(params)
            setPatients(_pacients.users)
            setTotal(_pacients.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [user, tableFilter])

    
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/doctors/${id_user}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _pacients = await request_doctors(params)
        setPatients(_pacients.users)
        setTotal(_pacients.count)
        setAddModal(false)
    }

    let can_create = true */

    return(
        <Page title={content.titles.doctors} >
            <AddModal history={history} user={user} open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} 
                onUpdateCompleted={actions.onInitModule} content={content.modal} catalogs={catalogs} />
            <SimpleTable
                loading={system.loading}
                content={content.tables.doctors}
                data={data} 
                search={doctors_search}
                tableFilter={doctors_table}
                total={total}   
                //onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onChangeSearch={onUpdateSearch}
                onUpdateTableFilter={onUpdateTable}
                onSelectedItem={actions.onSelectItem}    
                />
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        language: state.language,
        user: state.user,
        doctors_table: state.doctors_table,
        doctors_search: state.doctors_search
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateTable: (doctors_table) => dispatch({type: actionTypes.CHANGE_DOCTORS_TABLE, doctors_table }),
        onUpdateSearch: (doctors_search) => dispatch({type: actionTypes.CHANGE_DOCTORS_SEARCH, doctors_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors)