import React, { useState, useEffect } from 'react';
import SimpleModal from '../../../../components/Modals/SimpleModal';
import { TextField, Icon, Typography, Grid, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';

const LoadingModal = ({open, onClose, message}) => {

    const classes = useStyles()
    

    return ( 
        <SimpleModal open={open}  >
            
            <div style={{width:'100%', textAlign:'center', marginBottom:20}}>
                <CircularProgress />
            </div>
            <Typography align='center'>{message}</Typography>
        </SimpleModal>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    container:{
        marginTop:32,
        maxHeight:300,
        overflowY:'auto',
        overflowX:'hidden'
    },
    list_item:{
        padding:'12px 4px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:grey[100]
        }
    }
}))

export default LoadingModal;