import React from 'react'
import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { palette } from '../../variables/config'
import { blueGrey } from '@mui/material/colors'

const variant = {
    small:'body2',
    medium:'body1',
    large:'h6',
    extraLarge:'h5',
    superLarge:'h4',
}

const useStyles = makeStyles(theme => ({
    root:{
        fontWeight:500,
        color:blueGrey[900]
    }
}))


const Heading = props => {

    const {size, color} = props
    const classes = useStyles()



    return(
        <Typography className={classes.root} variant={size ? variant[size] : 'body1'} style={{color: color ? palette.texts[color] : undefined }} {...props}>
            {props.children}
        </Typography>
    )
}

export default Heading