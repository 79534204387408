import { Button, Grid, Icon, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import RoundedButton from "../../../../components/Actions/Buttons/RoundedButton"
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { onGetDateFormat, onGetFullAddress, onGetJSONObj } from "../../../../shared/utility"
import RouteStatusCard from "./RouteStatusCard"


const DeliveryCard = ({order_type_id, require_qr, require_passphrase, qr_code, passphrase, route_order_status_id, user, address_data, schedule_time,
                        route_order_id, warehouse, warehouse_address, pickup_time}) => {

    const classes = useStyles()

    //console.log(warehouse_address?.map_url)

    let security = 'Sin seguridad'
    if(require_passphrase) security='Palabra clave'
    if(require_qr) security='Código qr'

    let security_view = null

    if(passphrase){
        security_view = <div className={classes.phrase}>
                            <Typography variant='h6'>{passphrase}</Typography>
                        </div>
    }

    if(qr_code){
        security_view = <div className={classes.phrase}>
                            <Typography variant='h6'>dasfdasdf</Typography>
                        </div>
    }


    // delivery data
    let delivery_view = null

    if(order_type_id === 2){
        delivery_view = <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4}><Typography variant="subtitle2">Entregado a :</Typography></Grid>
                                    <Grid item xs><Typography>{user}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4}><Typography variant="subtitle2">Sucursal:</Typography></Grid>
                                    <Grid item xs><Typography>{warehouse}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4}><Typography variant="subtitle2">Dirección:</Typography></Grid>
                                    <Grid item xs><Typography>{onGetFullAddress(warehouse_address)}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={4}><Typography variant="subtitle2">Fecha de entrega:</Typography></Grid>
                                    <Grid item xs><Typography>{onGetDateFormat(pickup_time, 'DD/MM/YYYY, hh:mm a')}</Typography></Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button href={warehouse_address ? warehouse_address.map_url : ''} target='_blank'  className={classes.button}>Ver mapa</Button>
                            </Grid>
                        </Grid>
    }

    if(order_type_id === 1){

        if(route_order_id){
            delivery_view = <Grid container spacing={3}>
                                <Grid item xs={4} >                
                                    <RouteStatusCard status={route_order_status_id} />                         
                                </Grid>
                                <Grid item xs={8} >
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}><Typography variant="subtitle2">Entregado a:</Typography></Grid>
                                                <Grid item xs><Typography>{user}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}><Typography variant="subtitle2">Dirección:</Typography></Grid>
                                                <Grid item xs><Typography>{onGetFullAddress(onGetJSONObj(address_data))}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}><Typography variant="subtitle2">Fecha de entrega:</Typography></Grid>
                                                <Grid item xs><Typography>{onGetDateFormat(schedule_time, 'DD/MM/YYYY, hh:mm a')}</Typography></Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4}><Typography variant="subtitle2">Seguridad:</Typography></Grid>
                                                <Grid item xs>
                                                    <Typography>{security}</Typography>
                                                    <Grid container>
                                                        <Grid item>{security_view}</Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid item xs={12} justifyContent="center">
                                            <Grid container>
                                                <Grid item>{security_view}</Grid>
                                            </Grid>
                                        </Grid> */}
                                    </Grid>           
                                </Grid>                      
                            </Grid>
        }else{
            delivery_view = <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="subtitle2">Entregado a :</Typography></Grid>
                                        <Grid item xs><Typography>{user}</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="subtitle2">Dirección:</Typography></Grid>
                                        <Grid item xs><Typography>{onGetFullAddress(onGetJSONObj(address_data))}</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="subtitle2">Fecha de entrega:</Typography></Grid>
                                        <Grid item xs><Typography>{onGetDateFormat(schedule_time, 'DD/MM/YYYY, hh:mm a')}</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={4}><Typography variant="subtitle2">Seguridad:</Typography></Grid>
                                        <Grid item xs>
                                            <Typography>{security}</Typography>
                                            <Grid container>
                                                <Grid item>{security_view}</Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item xs={12} justifyContent="center">
                                    <Grid container>
                                        <Grid item>{security_view}</Grid>
                                    </Grid>
                                </Grid> */}
                            </Grid>
        }
        
    }

    

    return(
        <SimpleCard>
            <div className={classes.header}>
                <Typography color='primary'>Entrega</Typography>
                <Icon color='primary'>{order_type_id === 2 ? 'house' : 'local_shipping'}</Icon>
            </div>
            {delivery_view}
        </SimpleCard>
    )
}

export default DeliveryCard

const useStyles = makeStyles(theme => ({
    header:{
        marginBottom:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between'
    },
    button_container:{
        height:'100%',
        display:'flex',
        alignContent:'center',
        justifyContent:'center',
        alignItems:'center',
        //background:'red'
    },
    button:{
        color:theme.palette.primary.main,
        textTransform:'none',
        padding:'8px 24px',
        borderRadius:16,
        border:'0.5px solid #1B0934'
    },
    phrase:{
        border:'1px solid rgba(217, 93, 113, 0.4)',
        borderRadius:15,
        padding:'8px 16px',
        color:'#D95D71',
        textTransform:'uppercase',
        textAlign:'center',
        marginTop:20,
        //width:'100%'
    }
}))