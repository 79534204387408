import { Card, CircularProgress, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTheme from '@mui/styles/useTheme';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import { onGetErrorMessage, onUpdateAuthorizationHeader } from '../../../../../shared/utility';
import { DASHBOARD_SETTINGS, mainServer } from '../../../../../variables/config';
import LoadingModal from '../../modals/LoadingModal';
import WarningModal from '../../modals/WarningModal';
import OrderCard from './components/OrderCard';


const PreparingRouteSection = ({ modals, routeorders, sending_back, sending_next, actions, onUpdateError, route, id_route}) => {

    const classes = useStyles()
    const theme = useTheme()

    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState([])
    
    const [checked_all, setCheckedAll] = useState(false)
    const [checked_carts, setCheckedCarts] = useState([])
    

    useEffect(async() => {
        setLoading(true)
        let _ordercarts = []
        let _checks = []
        //console.log(routeorders)
        
        onUpdateAuthorizationHeader()
        /* Promise.all(routeorders.map(async (item, index) => {  
            try{
                //console.log(item.order_id)
                const _order = await mainServer.get(`/order/477`)
                //_ordercarts.push(_order.data.data.order.cart)
                _ordercarts.push('hola')
                console.log(_ordercarts)
            }catch(e){
                console.log(e)
                onUpdateError(onGetErrorMessage(e))
            }            
        })) */
        for (let index = 0; index < routeorders.length; index++){
            const id = routeorders[index].order_id
            const _order = await mainServer.get(`/order/${id}`)
            _ordercarts.push(_order.data.data.order.cart)
            //_ordercarts.push('hola')
            _checks.push(false)
            //console.log(_ordercarts)
        }
        console.log(_ordercarts)
        setItems(_ordercarts)
        setCheckedCarts(_checks)
        setLoading(false)
        
    }, [])
    
    const onUpdateChecks = (_index, _value) => {
        let temp = [...checked_carts]
        temp[_index] = _value
        setCheckedCarts(temp)
    }

    let isAllChecked = checked_carts.includes(false)
    

    return ( 
        <div className={classes.root}>
            <WarningModal open={modals.warning} onClose={()=>actions.onUpdateModalStatus('warning', false)} onCompleted={actions.onRefreshData} 
                id_user={route.delivery_man_id} id_route={id_route} />
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Typography variant='subtitle1' align='center'>
                        Prepara el pedido
                    </Typography>
                </div>
                <div className={classes.container}>
                    {loading ? <div style={{textAlign:'center'}}><CircularProgress /> </div> : 
                    <Grid container spacing={4}>
                        {items.map((item, index) => {
                            return(
                                <Grid item xs={12} key={index.toString()}>
                                    <OrderCard items={item.line_items} id={item.order_id} onCheck={onUpdateChecks} index={index}/>
                                </Grid>
                            )      
                        })}   
                    </Grid>}
                </div>
                <div className={classes.submit_container}>
                    <Grid container spacing={4} justifyContent='center'>
                        <Grid item><RoundedButton loading={sending_back} color='white_primary' onClick={actions.onUpdateRouteStatusBack}>Regresar</RoundedButton></Grid>
                        <Grid item><RoundedButton loading={sending_next} onClick={actions.onUpdateRouteStatusNext} disabled={isAllChecked} >Continuar</RoundedButton></Grid>
                    </Grid>            
                </div>
            </Card>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        borderRadius:10,
        padding:16
    },
    header:{
        padding:16,
        paddingTop:8,
        borderBottom:'1px solid #EAEAEA',
        marginBottom:16
    },
    table:{
        padding:'40px 0px'
    },
    icon_container:{
        background:'rgba(202, 203, 251, 0.1)',
        position:'relative',
        width:100, height:100,
        borderRadius:'50%',
        marginBottom:16
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        
    },
    form:{
        width:250,
        margin:'auto'
    },
    payment_container:{
        margin:'40px 0px'
    },
    submit_container:{
        marginTop:40,
        //textAlign:'center',
        marginBottom:20
    }
}))
 
export default PreparingRouteSection;