import { Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { indigo } from '@mui/material/colors'
import React from 'react'
import { isValidDocument } from '../../../../shared/utility'
import { config_data, USER_TYPES } from '../../../../variables/config'



const UserAvatar = ({first_name, last_name, user_type_id, business_name, image, topbar}) => {

    const useStyles = makeStyles(theme => ({
        avatar:{
            width:54,
            height:54,
            //border:'1p sli',
            //background:indigo[500],
            background: theme.palette.secondary.main,
            fontWeight:500
        },
    }))

    const classes = useStyles()

    let acronym =  `${first_name ? first_name[0].toUpperCase() : ''}${last_name ? last_name[0].toUpperCase() : ''}`
    
    let avatar_content = (
        <Avatar className={classes.avatar}>
            {acronym}
        </Avatar>
    )

    const valid_image = isValidDocument(image)
    if(valid_image){
        avatar_content = <Avatar src={`${config_data.S3_SERVER_URL}${image}`} className={classes.avatar}/>
    }

    return(
        <div>
            {avatar_content}
        </div>
    )
}

export default UserAvatar