import React from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { nodata } from '../../../variables/texts'
import DisplayText from '../../Texts/DisplayText'
//import { blueGrey } from '@mui/material/colors'

const useStyles = makeStyles(theme => ({
    icon:{
        color:theme.palette.primary.main,
        //border:`1px solid ${blueGrey[100]}`,
        borderRadius:'50%',
        padding:4    
    },
    status:{
        backgroundColor:'blue',
        borderRadius:'50%',
        width:10,
        height:10,
        margin:'8px 0px'
    }
}))

const DisplayColorIdData = ({statusColor, data, color}) => {

    const classes = useStyles()

    return(
        <Grid container spacing={1} wrap='nowrap'>
            <Grid item>
                <div className={classes.status} style={{backgroundColor: statusColor ? `${statusColor}` : 'transparent' }}/>
            </Grid>
            <Grid item xs>
                <DisplayText color={color}>{data ? data : nodata}</DisplayText>
            </Grid>
        </Grid>
    )
}

export default DisplayColorIdData