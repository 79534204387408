import moment from 'moment'
import { ERROR_CODES } from '../texts/esp/errorCodes';
import { mainServer, nodata } from '../variables/config';
import 'moment/locale/es';
import { PRODUCT_DISCOUNT_TYPES } from '../variables/enums';

moment.locale('es')

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const getFileExtension = (filename) => filename.split('.').pop();

export const onGetFullname = (first_name, last_name) => {
    //if(!first_name) return 'N/D'
    return `${first_name ? first_name : ''} ${last_name ? last_name : ''}`
}

export const isValidDocument = (filename) => filename && filename !== "0" && filename !== 0 && filename !== ""

export const onGetCompleteAddress = (data) => {
    if(!data) return '-'
    return `
    ${data.address1 ? data.address1 : ''}, 
    ${data.address2 ? data.address2 : ''}, 
    ${data.city ? data.city : ''}, 
    ${data.province ? data.province : ''}, 
    ${data.zip ? data.zip : ''}`
}

export const onGetFullAddress = (data) => {
    if(!data) return '-'
    return `
    Calle ${data.street ? data.street : ''}, ${data.suburb ? data.suburb : ''}, ${data.municipality ? data.municipality : ''}, ${data.state ? data.state : ''}`
}

export const onGetFormatNumber = (number) => {
    let result = ''
    if(!number) return '$0'
    let temp = parseFloat(number).toFixed(0)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$${result}`
    return result
}

export const isFormValid = (form) => {
    let errors = []
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const isFilterFormValid = (form, filter) => {
    let errors = []
    if(form && filter && filter.length){
        filter.forEach(item => {
            if(!form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const onSanitizeSlug = (data) => {
    if(!data) return ''
    let _data = data.trim()
    const regex = /(^[a-z0-9-]+$)/
    const result = regex.replace(_data, '');
    return result
}

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}

export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}

    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]: onCleanData(form[item].value)}
            }else{
                if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:onCleanData(form[item].value)}
            }
        })
    }
    return temp
}

export const onGetSelectedFormDataNotClean = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormDataNumber = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(!origin){
                temp = {...temp, [item]: parseFloat(form[item].value)}
            }else{
                if(form[item].isValid && parseFloat(form[item].value) !== parseFloat(origin[item])) temp = {...temp, [item]: parseFloat(form[item].value)}
            }
        })
    }
    return temp
}

export const onInitForm = (form, origin) => {
    let response = {...form}
    if(!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
    })
    return response
}

export const onInitEmptyForm = (form, translations) => {
    let response = {...form}
    Object.keys(form).forEach(item => {
        if(translations && translations[item]) response[item].config = {...response[item].config, ...translations[item]}
    })
    return response
}

export const s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
}


export const isTokenValid = () => {
    let isValid = false

    const userInfo = JSON.parse(localStorage.getItem('token_data'))
    if(userInfo){
        const currentTime = moment().unix()
        const serverTime = userInfo.exp
        isValid = currentTime < serverTime
    }
    return isValid
}

export const onGetUser = () => {
    let result = null

    const userInfo = JSON.parse(localStorage.getItem('token_data'))
    if(userInfo) result = userInfo
    return result
}

export const isCompatibleURL = (history, path) => history.location.pathname.split('/')[1] === path.split('/')[1]

export const isCartAvailable = async() => {
    let nItems = 0

    const cartID = localStorage.getItem('cartID')
    if(cartID){
        //console.log(cartID)
        //const currentTime = moment().utc()
        //const serverTime = moment(userInfo.expiresAt).utc()
        //isValid = currentTime.diff(serverTime)/(1000*60*60) < 0
    }
    return nItems
}

export const onGetCatalogs = (options, keyValue, keyLabel, keyLabel2, keyLabel3) => {
    if(!options) return []

    /* return options.map(item => {
        return{
            value: item[keyValue], label:item[keyLabel] ? item[keyLabel] : ''
        }
    }) */

    const sort_options = options.map(item => {

        //console.log(item)
        const label1 = item[keyLabel] || ''
        const label2 = item[keyLabel2] || ''
        const label3 = item[keyLabel3] || ''

        return{
            //value: item[keyValue], label:item[keyLabel] && item[keyLabel2] ? item[keyLabel] + ' ' + item[keyLabel2] : item[keyLabel] 
            value: item[keyValue], label:label1 + ' ' + label2 + ' ' + label3
        }
    })

    return sort_options.sort(function(a,b){return a.label.localeCompare(b.label)})
}

export const onGetNameCatalogs = (options, keyValue, keyLabel1, keyLabel2) => {
    if(!options) return []
    return options.map(item => {
        return{
            value: item[keyValue], label:`${item[keyLabel1] ? item[keyLabel1] : ''} ${item[keyLabel2] ? item[keyLabel2] : ''}`
        }
    })
}

export const onGetCatalogById = (catalogs, value) => {
    let res = '-'
    if(catalogs && value){
        catalogs.forEach(element => {
            if( element.value === value ) res = element.label
        });
    }
    return res
}

const monthsName = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']

export const onGetMonthsCatalog = (year) => {
    let response = []
    for(let i=0;i<12;i++){
        const startDate = moment([year, i])
        const endDate = moment(startDate).endOf('month')
        response.push({
            value:i+1,
            label:monthsName[i],
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD')
        })
    }
    return response
}

export const onGetYearsCatalog = () => {
    const current_year = moment().format('Y')
    let response = []
    for(let i=0;i<5;i++){
        response.push({value:parseInt(current_year) + i, label:parseInt(current_year) + i})
    }
    return response
}

export const onGetCreditCardFormat = (data) => {
    let value = data.split(' ').join('')
    if(value.length <=16) return value.match(/.{1,4}/g).join(' ');
    return value.slice(0,-1).match(/.{1,4}/g).join(' ');
}

export const onGetExpirationFormat = (data) => {
    let value = data.split('/').join('')
    if(value.length <=4) return value.match(/.{1,2}/g).join('/');
    return value.slice(0,-1).match(/.{1,2}/g).join('/');
}

export const isExpDateIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{4}$/g)) return true
    return false
}

export const isCCVIsValid = (data) => {
    if(data.split('/').join('').match(/^[0-9]{3,4}$/g)) return true
    return false
}

export const onGetJSONData = (data) => {
    if(!data) return {}
    let temp = {}
    Object.keys(data).forEach(item => {
        if(data[item].isValid && data[item].isVisited) temp = {...temp, [item]: data[item].value}
    })
    return temp
}

export const onGetFileExtension = (filename) => filename.split('.').pop();

export const onGetErrorMessage = (error) => {
    let message = 'Hubo un error'
    if(!error) return ''
    if(error.response){
        if(error.response.data){
            if(error.response.data.error && error.response.data.error.code){
                const _error = ERROR_CODES[error.response.data.error.code]
                if(_error) message = _error
            }
        }
    }
    return message
}

export const onUpdateDocumentList = (documents, origin) => {
    if(!origin) return documents
    const _new_documents = documents.map(item => {
        let temp = {...item}
        const valid = isValidDocument(origin[item.id])
        temp.status = valid ? true : false
        return temp
    })
    return _new_documents
}

export const onGetImageURL2Blob = async(url) => {
    let blob = await fetch(url).then(r => r.blob());
    console.log(blob)
    let temp = blob.type.split('/');
    let ext = "."+temp[temp.length-1];
    let newfile = new File([blob], "image"+ext, {type:blob.type});
    return newfile;
}


const onGetManufacturer = (provider) => {

    let manufacturer = ''

    if(provider === 'Alcon' || provider === 'Bausch & Lomb') manufacturer = 'ManufacturerA'
    if(provider === 'CooperVision') manufacturer = 'ManufacturerB'
    if(provider === 'ITAL-LENT') manufacturer = 'ManufacturerC'
 
    return manufacturer
}

const onGetZone = (city) => {

    let zone = ''

    const mainCities = ['Bucaramanga', 'Bogotá', 'Cali', 'Medellin', 'Santa Marta', 'Cartagena', 'Sincelejo', 'Valledupar', 'Monteria']
    
    if (mainCities.includes(city)){
        zone = 'secondary'
    }else{
        if (city === 'Barranquilla'){
            zone = 'primary'
        }
        else{
            zone = 'tertiary'
        }
    }

    return zone
}

const onGetVisualCondition = (tags, title) => {

    let category = ''

    if(tags){
        if(tags.includes('MIOPIA')) category='sphere'
        if(tags.includes('ASTIGMATISMO')) category='toric'
        if(tags.includes('PRESBICIA')) category='multifocal'
        if(tags.includes('LENTES COSMETICOS')) category='cosmetic' 
        if(tags.includes('COSMOPROTÉSICO')) category='cosmoprotestic' 
    }

    if(title === 'BIOFINITY TORIC XR') category='toric_xr'
    if(title.includes('HIDROGEL')) category='hidrogel' 
    if(title.includes('sIHY')) category='sihy' 
    
    return category

}

export const onEstimateDeliveryDate = (json, provider, city, tags, title) => {

    const manufacturer = onGetManufacturer(provider)
    const zone = onGetZone(city)
    const visual_condition = onGetVisualCondition(tags, title)

    console.log(manufacturer)
    console.log(zone)
    console.log(visual_condition)

    //console.log(json)
    let deliveryDate
    if(manufacturer !=='' && zone !=='' && visual_condition !=='') {
        deliveryDate =  json[manufacturer][zone][visual_condition]
    }else{
        deliveryDate = -1
    }

    return deliveryDate

}


export const onUpdateAuthorizationHeader = () => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    if(credentials){
        mainServer.defaults.headers.common['Authorization'] = credentials.token
    }
}

export const onGetDateFormat = (data, format) => {
    if(!data) return nodata
    const momentdata = moment(data)
    if(!momentdata.isValid()) return nodata
    
    //if(format) return momentdata.utc().format(format)
    //return momentdata.utc().format('YYYY-MM-DD')
    //convert to local
    if(format) return moment.utc(data).local().format(format)
    return moment.utc(data).format('YYYY-MM-DD')
}

export const onCleanData = (data) => {
    if(data === undefined || data === null) return undefined
    if(data === "") return "@null!!"
    return data
}

export const onCleanNullsFormData = (data) => {

    let response = {...data}
    console.log(data)
    Object.keys(data).forEach(item => {
        console.log(item)
        const value = data[item]
        console.log(value)
        if(value === undefined || value === null || value === "") response[item] = "@null!!"
    })
    return response
}


///////////////////// SERVER HANDLERS ///////////////////////////
export const updateAuthorizationHeader = (server) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    if(!logData){
        console.log('Token not valid')
        return
    }
    //server.defaults.headers.common['Authorization'] = `Bearer ${logData.token}`
    server.defaults.headers.common['Authorization'] = logData.token
}

/// Slots dates ////

export const onUpdateSlots2UTC = (slots, period) => {
    let _slots = []
    if(!slots) return _slots

    slots.forEach(item => {
        const temp = moment(item)
        if(temp.isValid()){
            _slots.push({
                weekday:temp.day(),
                start_time:temp.format('HH:mm'),
                end_time:temp.add(period, 'minute').format('HH:mm'),
            })
        }
    })
    _slots.pop()

    return _slots
}

export const onUpdateSlots2Calendar = (slots, period) => {
    let _slots = []
    if(!slots) return _slots

    const current_date = moment()
    //console.log(slots)
    slots.forEach(item => {
        const temp = moment(item)
        if(temp.isValid()){
            const diff = current_date.day() - item.weekday
            //console.log(`Diferencia en dias`, diff)
            let slot_date = ''
            if(diff < 0){
                slot_date = moment().add(Math.abs(diff), 'days').format('YYYY-MM-DD')
            }else{
                //console.log(diff)
                slot_date = moment().subtract(Math.abs(diff), 'days').format('YYYY-MM-DD')
            }

            const _sd = new Date(`${slot_date}T${item.start_time}`)
            const _ed = new Date(`${slot_date}T${item.end_time}`)

            _slots.push({
                id:item.id_warehouse_store_timetable,
                title:'',
                start: _sd,
                end:_ed
            })
        }
    })

    return _slots
}

export const onUpdateSlots2CalendarDelivery = (slots, period) => {
    let _slots = []
    if(!slots) return _slots

    const current_date = moment()
    //console.log(slots)
    slots.forEach(item => {
        const temp = moment(item)
        if(temp.isValid()){
            const diff = current_date.day() - item.weekday
            //console.log(`Diferencia en dias`, diff)
            let slot_date = ''
            if(diff < 0){
                slot_date = moment().add(Math.abs(diff), 'days').format('YYYY-MM-DD')
            }else{
                //console.log(diff)
                slot_date = moment().subtract(Math.abs(diff), 'days').format('YYYY-MM-DD')
            }

            const _sd = new Date(`${slot_date}T${item.start_time}`)
            const _ed = new Date(`${slot_date}T${item.end_time}`)

            _slots.push({
                id:item.id_warehouse_delivery_timetable,
                title:'',
                start: _sd,
                end:_ed
            })
        }
    })

    return _slots
}

export const onGetDateWithTimezone = (data, format) => {
    // This function returns a moment object
    if(!data) return null 
    if(!moment(data).isValid()) return null 
    if(!format) return moment.utc(data)
    return moment(data).format(format)
}

export const onGetDateWithTimezonev2 = (data, format) => {
    // This function returns a moment object
    if(!data) return null 
    //console.log(moment.utc(data).format())
    if(!format) return moment(moment.utc(data).format())
    return moment(moment.utc(data).format()).format(format)
}

export const onTruncateText = (data, length) => {
    let result

    if(!data) return ''
    if(data.length < length) return data
    result = data.substring(0, length - 3) + "..."
    return result
}

export const onGetProductItemPrice = (product) => {
    let result = {
        current:product?.price,
        previous:null,
        free:0,
    }

    const is_product_tax = product?.taxable === 1

    const tax_lines = is_product_tax ? product?.product_taxes : product?.tax_lines
    const product_quantity = parseInt(product?.quantity || product.product_quantity)

    const _product_discount_type_id = product?.product_discount_type_id || product?.sale_discount_type_id
    if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.DISCOUNT){

        result.current = parseFloat(product?.sale_price)
        result.previous = parseFloat(product?.price)
        result.free = 0

    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE){
        if(product_quantity >= product.sale_bundle_quantity){
            result.current = product?.sale_bundle_price
            result.previous = product?.price
            result.free = 0
        }
    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){
        //const freeItems = product?.sale_bundle_included_free
        result.current = product?.price
        result.previous = null
        result.free = product?.sale_bundle_included_free
    }


    if(tax_lines?.length){
        console.log(tax_lines)
        let current_tax_sum = 0
        let previous_tax_sum = 0
        if(is_product_tax){
            tax_lines.forEach(item => {
                current_tax_sum += result.current*(parseFloat(item?.rate??0.00)/100)
                previous_tax_sum += result.previous*(parseFloat(item?.rate??0.00)/100)
            })
        }else{
            tax_lines.forEach(item => {
                current_tax_sum += result.current*(parseFloat(item?.rate??0.00))
                previous_tax_sum += result.previous*(parseFloat(item?.rate??0.00))
            })
        }
        
        result.current = parseFloat(result.current) + current_tax_sum
        result.previous = parseFloat(result.previous) + previous_tax_sum
    }

    console.log(result)

    

    return result

}

export const onGetLineItemDiscount = (product) => {

    let result = 0

    const _product_discount_type_id = product?.product_discount_type_id || product?.sale_discount_type_id
    if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.DISCOUNT){

        result = parseFloat(product?.sale_discount)*parseInt(product?.quantity)
        

    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE){
        if(product?.quantity >= product.sale_bundle_quantity){
            
            result = (parseFloat(product?.price)*parseInt(product?.quantity)) - (parseFloat(product?.sale_bundle_price)*parseInt(product?.quantity))
        }
    }else if(product?.sale === 1 && _product_discount_type_id === PRODUCT_DISCOUNT_TYPES.BUNDLE_QXQ){
        
        result = parseInt(product?.sale_bundle_included_free) * parseInt(product?.price)
    }

    return result

}

export const onGetCurrencyValue = (number, precision) => {
    let result = ''
    if(!number) return '$0'
    let temp = parseFloat(number).toFixed(precision??2)
    result = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    result = `$${result}`
    return result
}

export const onConvertDateToUtc = (_date) =>{
    if(!_date) return null
    const momentdata = moment(_date)
    if(!momentdata.isValid()) return null

    return moment(_date).utc().format("YYYY-MM-DD HH:mm:ss")
}

export const onConvertUTCtoDate = (_date, format) =>{
    if(!_date) return null
    const momentdata = moment(_date)
    if(!momentdata.isValid()) return null

    let date = _date.split(' ')
    if(date.length<2) return null

    let newDate = [date[0], 'T', date[1], 'Z']
    newDate = newDate.join('')

    const _format = format ?? "YYYY-MM-DD HH:mm:ss"

    if(format) return moment(newDate).format(_format)
    return moment(newDate)

}

export const onGetProductName = (product, args) => {
    let result = ''
    let _args = args ?? ['name', 'sub_name', 'presentation']
    if(!product) return result

    _args.forEach(item => {
        let temp = product[item]
        if(item) result = `${result} ${temp}`
    })
    return result.trim()
}

export const onGetRandomNumber = () => {
    let result = ''
    const characters = '0123456789'
    for (let i = 0; i < 5; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result
}

export const string2Slug = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
	str = str.toLowerCase();

	// remove accents, swap ñ for n, etc
	var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
	var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";

	for (var i=0, l=from.length ; i<l ; i++)
	{
		str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
	}

	str = str.replace('.', '-') // replace a dot by a dash 
		.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
		.replace(/\s+/g, '-') // collapse whitespace and replace by a dash
		.replace(/-+/g, '-') // collapse dashes
		.replace( /\//g, '' ); // collapse all forward-slashes

	return str;
}

export const onGetJSONObj = (obj) => {
    if(!obj) return {}
    let result = {}
    try {
      result = JSON.parse(obj)
    } catch (error) {
      //console.log('Invalid JSON')
    }
  
    return result
  
  }




