import { Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import { private_server, public_server } from '../../../../../../variables/config'


const EditTaxModal = ({open, onClose, onUpdateCompleted, content, id, origin}) => {

    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //console.log(origin)

    useEffect(async() => {
        if (!open) {
            //let _form = onInitEmptyForm(form, null)
            //setForm(formData)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            updateAuthorizationHeader(private_server)
            //const _taxes = await private_server.get(`/taxline/types`)
            //const taxes_catalogs = onGetCatalogs(_taxes.data.data.tax_types, 'id_tax_type', 'name')
            let _form = onInitForm(form, origin)
            //_form.tax_type_id.options = taxes_catalogs
            setForm(_form)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            //await request_create_active(id, data2send);
            updateAuthorizationHeader(private_server)
            //await private_server.patch(`/product/${id}`, {taxable:1})
            await private_server.patch(`/product/${id}/tax/${origin?.id_product_tax}`, data2send)
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Editar impuesto</DisplayText>
                {/* <DisplayText >Por favor ingresa los datos solicitados</DisplayText> */}
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} >
                            <SelectForm data={form.tax_type_id} onChange={onChange} />
                        </Grid> */}
                        <Grid item xs={12}>
                            <InputForm data={form.rate} onChange={onChange} />
                        </Grid>                
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    Guardar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditTaxModal


const formData = {
    /* tax_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'tax_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de impuesto',
            helperText: 'Debe seleccionar una opción'
        },
        rules: {
            type: 'select',
            //min: 1, max: 255
        }
    }, */
    rate: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'rate',
            type: 'number',
            fullWidth: true,
            label: 'Tasa (%)',
            helperText: 'Tasa no válida'
        },
        rules: {
            type: 'numeric',
            min: 0, max: 100
        }
    },
    
}