import React, {useState} from 'react'
import { connect } from 'react-redux';
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import Page from '../../../components/Structure/Page/Page'
import { translations as translations_esp } from '../../../texts/esp/warehouses-translations'
import { Hidden } from '@mui/material'
import Local from './views/Local/Local'
import PageMenuMobile from '../../../components/Structure/Navigation/PageMenuMobile'
import Coverage from './views/Coverage/Coverage'
import { actionTypes } from '../../../store/actions'



const Warehouses = (props) => {

    const content = translations_esp

    const {history, warehouses_local_search, warehouses_local_table, onUpdateSearch, onUpdateTable} = props

    const [view, setView] = useState(1)
  
    const menu = content.menu
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <Local history={history} content={content.views.local} {...props} />
            break
        case 2:
            contentView = <Coverage content={content.views.coverage} />
            break
        default:
            break
    }

    return (
        <Page title={content.title}>
        <Hidden mdDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={(id)=>setView(id)} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={(id)=>setView(id)} />
        </Hidden>
        <div style={{marginTop:20}}>
            {contentView}
        </div>
        </Page>
    );
}

const mapStateToProps = state => {
    return{
        warehouses_local_table: state.warehouses_local_table,
        warehouses_local_search: state.warehouses_local_search
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateTable: (warehouses_local_table) => dispatch({type: actionTypes.CHANGE_WAREHOUSES_LOCAL_TABLE, warehouses_local_table }),
        onUpdateSearch: (warehouses_local_search) => dispatch({type: actionTypes.CHANGE_WAREHOUSES_LOCAL_SEARCH, warehouses_local_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Warehouses)