import product from '../../assets/icons/product.svg'

export const translations = {
    title:'Productos',
    table: {
        header:[
            {id:1, label:''},
            {id:2, label:'ID'},
            {id:3, label:'Marca'},
            {id:4, label:'Submarca'},
            {id:5, label:'Presentación'},
            {id:6, label:'Orden'},
            {id:7, label:'Estatus'},
            {id:8, label:''},
        ],
        nodata:'No existen productos registrados',
        empty:product
    },
    modal: {
        add_title:'Agregar',
        edit_title:'Editar',
        subtitle:'Por favor ingresa los datos solicitados',
        button:'Guardar',
        add_button:'Agregar',
        form:{
            product_id:{
                label:'Productos',
                helperText:'Seleccione al menos una opción'
            },
            name:{
                label:'Titulo',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripcion',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            order:{
                label:'Orden',
                helperText:'Seleccione al menos una opción'
            },
        }
    },
}
