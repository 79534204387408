import { useEffect, useState } from "react"
import { onGetCatalogs, onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"
import queryString from 'query-string'



const useUsers = ({history, id}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)
    const [roles, setRoles] = useState([])

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'id_warehouse_user'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = [{"field":"warehouse_id","operator":"=","value":id}]
    
        if(search)filtersArgs.push({"field":"user","operator":"LIKE","value":`%${search}%`})
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`warehouseuser/list${params}`)   
                console.log(req)
                setData(req.data.data.warehouse_users)
                setTotal(req.data.data.count)
                const req_roles = await private_server.get(`role/list`)
                const roles_catalogs = req_roles.data.data.roles.map(item => {
                    return{
                        value: item.id_role, label:item.name, icon:item.icon, color:item.color
                    }
                })
                setRoles(roles_catalogs)
                //console.log(roles_catalogs)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {id_warehouse_user} = item
            setSelected(id_warehouse_user)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            //setSelected(item)
            //actions.onChangeModalStatus('edit', true)
            const {id_warehouse} = item
            history.push(`/warehouses/${id_warehouse}`)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected, roles}

    return {system, modals, actions, view_data } 
}

export default useUsers