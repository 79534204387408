import makeStyles from '@mui/styles/makeStyles';


export const modalStyles = makeStyles(theme=>({
    form:{
        paddingTop:30,
        paddingBottom:50,
    },
    button:{
        textAlign:'center'
    }
    
}))