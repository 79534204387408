import { Card, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTheme from '@mui/styles/useTheme';
import React, { useState, useEffect } from 'react';
import { DASHBOARD_SETTINGS } from '../../../../../variables/config';
import current_icon from '../../../../../assets/icons/delivery_man.svg'
import SVGIcon from '../../../../../components/Structure/DisplayData/SVGIcon';
import OptionSelector from '../../components/OptionSelector';
import DeliverManModal from '../../modals/DeliverManModal';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import BankTerminalModal from '../../modals/BankTerminalModal';

const PendingRouteSection = ({route, form, actions, modals, sending}) => {

    const classes = useStyles()
    const theme = useTheme()

    return ( 
        <div className={classes.root}>
            <DeliverManModal open={modals.delivery_man} warehouse_id={route.warehouse_id} onClose={()=>actions.onUpdateModalStatus('delivery_man', false)} onSubmit={actions.onUpdateRouteData} />
            <BankTerminalModal open={modals.terminal} onClose={()=>actions.onUpdateModalStatus('terminal', false)} warehouse_id={route.warehouse_id} 
                onSubmit={actions.onUpdateRouteData} />
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Typography variant='subtitle1' align='center'>
                        Asignar repartidor
                    </Typography>
                </div>
                <div className={classes.container}>
                    <div className={classes.profile}>
                        <div className={classes.icon_container}>
                            <div className={classes.icon}><SVGIcon src={current_icon} size={48} color={theme.palette.primary.main} /></div>
                        </div>
                        <div className={classes.form}>
                            <OptionSelector value={route.delivery_man} placeholder={'Seleccionar un usuario'} onChange={()=>actions.onUpdateModalStatus('delivery_man', true)} />
                        </div>
                    </div>
                    {/* <div className={classes.payment_container}>
                        <Typography>
                            El pedido requiere pago, preferido: 
                        </Typography>
                        <div>
                        </div>
                    </div> */}
                    <div className={classes.payment_container}>
                        <Typography>
                            Elige la terminal del repartidor: 
                        </Typography>
                        <div className={classes.form}>
                            <OptionSelector value={route.bank_terminal_identifier} placeholder={'Seleccionar una terminal'} onChange={()=>actions.onUpdateModalStatus('terminal', true)}/>
                        </div>
                    </div>
                    <div className={classes.submit_container}>
                        <RoundedButton onClick={actions.onUpdateRouteStatusNext} loading={sending} >Continuar</RoundedButton>
                    </div>
                </div>
            </Card>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        borderRadius:10,
        padding:16
    },
    header:{
        padding:16,
        paddingTop:8,
        borderBottom:'1px solid #EAEAEA',
        marginBottom:16
    },
    profile:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center'
    },
    icon_container:{
        background:'rgba(202, 203, 251, 0.1)',
        position:'relative',
        width:100, height:100,
        borderRadius:'50%',
        //marginBottom:16
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        
    },
    form:{
        width:250,
        margin:'auto',
        marginTop:40
    },
    payment_container:{
        margin:'40px 0px',
        borderTop:'1px solid #EAEAEA',
        paddingTop:40
    },
    submit_container:{
        marginTop:70,
        textAlign:'center',
        marginBottom:20
    }
}))
 
export default PendingRouteSection;