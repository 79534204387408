import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Page from "../../../../components/Structure/Page/Page";

import queryString from "query-string";
import UpdateRol from "./components/UpdateRol";
import UpdatePassword from "./modal/UpdatePassword";
import UserInfoCard from "./components/UserInfoCard";
import { translations as translations_esp } from "../../../../texts/esp/user-translations";
import useUserView from "./useUserView";
import AddRolModal from "../modals/AddRolModal";
import { onUpdateAuthorizationHeader } from "../../../../shared/utility";
import { mainServer } from "../../../../variables/config";
import ChangeImageModal from "./modal/ChangeImageModal";

const RolView = (props) => {
  const content = translations_esp;

  const { match, onUpdateSending, onUpdateSuccess, onUpdateError, history } =
    props;
  const userID = match.params.id;
  const { system, modals, actions, view_data } = useUserView({
    content,
    match,
    onUpdateSending,
    onUpdateSuccess,
    onUpdateError,
  });

  // State
  const [add_modal, setAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [totalItems, setTotalItems] = useState(10);
  const [tableFilter, setTableFilter] = useState({
    limit: 10,
    offset: 0,
    order: 0,
    order_by: "user_id",
  });
  const [filters, setFilters] = useState({
    status: -1,
  });
  const [search, setSearch] = useState(null);

  //Get UserRoles

  useEffect(() => {
    const initDatos = async () => {
      try {
        window.scrollTo(0, 0);
        setLoading(true);

        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    initDatos();
  });
  // Params
  const onGetParams = () => {
    // setLoading(true);
    const { limit, offset, order_by, order } = tableFilter;
    const params2send = { offset: offset, order_by: order_by, order: order };
    let params = `?${queryString.stringify(params2send)}&limit=${limit}`;

    let filtersArgs = [];

    if (filters.status !== -1)
      filtersArgs.push({
        field: "user_id",
        operator: "=",
        value: `${filters.status}`,
      });

    if (search)
      filtersArgs.push({
        field: "role",
        operator: "LIKE",
        value: `%${search}%`,
      });
    if (filtersArgs.length) {
      let finalArgs = { AND: filtersArgs };
      filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs));
      params = `?${queryString.stringify(
        params2send
      )}&filter=${encodeURIComponent(
        JSON.stringify(finalArgs)
      )}&limit=${limit}`;
    }
    return params;
  };

  const onUpdateCompleted = async () => {
    getDatosUser();
  };

  const getDatosUser = async () => {
    try {
      setLoading(true);
      onUpdateAuthorizationHeader();
      const params = onGetParams();
      const _settings = await mainServer.get(`/userrole/list/me${params}`);
      setSettings(_settings.data.data.user_roles);
      setTotalItems(_settings.data.data.count);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  console.log(settings);

  return (
    <Page title="Mi información" goback>
      <ChangeImageModal
        content={content.modals.change_image}
        id={userID}
        open={modals.change_image}
        onClose={() => actions.onChangeModalStatus("change_image", false)}
        onUpdateCompleted={actions.onInitModule}
      />
      <UpdatePassword
        content={content.modals.update_password}
        userID={userID}
        open={modals.change_password}
        onClose={() => actions.onChangeModalStatus("change_password", false)}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <UserInfoCard
            content={content.cards}
            {...view_data.data}
            onChangePicture={() =>
              actions.onChangeModalStatus("change_image", true)
            }
            onChangePassword={() =>
              actions.onChangeModalStatus("change_password", true)
            }
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} lg={4}>
          <AddRolModal
            open={add_modal}
            onClose={() => setAddModal(false)}
            history={history}
            // onUpdateCompleted={onUpdateCompleted}
          />

          <UpdateRol
            data={getDatosUser}
            onAddRegister={() => setAddModal(true)}
            content={content.cards}
            {...view_data.data}
            onChangePicture={() =>
              actions.onChangeModalStatus("change_image", true)
            }
          />
        </Grid>
      </Grid>
    </Page>
  );
};

export default RolView;
