import { mainServer } from '../../../variables/config'
import axios from 'axios'

//gets
export const request_user = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/users/${id}`)
    return response.data.data
}

export const request_doctor_details = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/doctor/${id}/details`)
    return response.data.data
}

export const request_doctor_translations = async(id, language_id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/doctor/${id}/translations/${language_id}`)
    return response.data.data
}

//patchs
export const request_update_user = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/users/${id}`, data)
    return response.data.data
}

export const request_update_doctor_details = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/doctor/${id}/details`, data)
    return response.data.data
}

export const request_update_doctor_translations = async(id, translation_id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/doctor/${id}/translations/${translation_id}`, data)
    return response.data.data
}

export const request_change_password = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    await mainServer.patch(`/user/${id}/changepassword`, data)
}

//posts
export const request_create_doctor_translations = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/doctor/${id}/translations`, data)
    return response.data.data
}

/*------ MEDIA ------*/
export const request_upload_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/users/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

export const request_delete_image = async(id) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.delete(`/users/image/${id}`)
}
  