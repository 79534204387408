import { Card, Checkbox, Grid, Icon, IconButton, Radio, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useState } from "react";
import ProductImage from "../../../../../../components/Structure/Images/ProductImage"
import Scrollbars from "react-custom-scrollbars";

const OrderCard = ({items, id, onCheck, index}) => {

    const classes = useStyles()

    let _checks = items.map(() => false)
    //console.log(_checks)

    const [checked, setChecked] = useState(_checks);
    const [button_click, setButtonClick] = useState(false)

    const handleChange = (event, _index) => {
        //console.log(event.target.checked)
        /* setChecked(event.target.checked);
        if(event.target.checked){
            onCheck(index, true)
        }else{onCheck(index, false)} */
        
        let temp = [...checked]
        temp[_index] = event.target.checked
        if(!event.target.checked) setButtonClick(false)
        let no_all_checked = temp.includes(false)
        if(!no_all_checked){
            onCheck(index, true)
        }else{
            onCheck(index, false)
        }
        setChecked(temp)
    };

    const onClickCheckButton = () =>{

        //console.log(button_click)
        if(!button_click){
            const _checks = items.map(() => true)
            setChecked(_checks)
            onCheck(index, true)
        }else{
            const _unchecks = items.map(() => false)
            setChecked(_unchecks)
            onCheck(index, false)
        }
        setButtonClick(!button_click)
    }

    

    const renderThumb = (style, props) => {
        
        const thumbStyle = {
            backgroundColor: `#543081`,
            borderRadius:10
        };
        return (
            <div
                style={{ ...style, ...thumbStyle }}
                {...props}/>
        );
    }

    let no_all_checked = checked.includes(false)
    //if(!no_all_checked) onCheck(index, true)

    return (
        <Card className={classes.root}>
            <div className={classes.header}>
                <Typography color='primary'><font style={{color:'#B3B3B3'}}>Pedido /</font> #{id}</Typography>
                <IconButton onClick={onClickCheckButton} size="large"><Icon style={{color:no_all_checked ? '#B3B3B3' : '#96AE76'}}>check_circle_outline</Icon></IconButton>
            </div>
            <div className={classes.container}>
                <Scrollbars height='100%' renderThumbVertical={renderThumb}>
                    <Grid container >
                        {items ? items.map((item, key)=>{
                            return(
                                <Grid item xs={12} key={key.toString()}>
                                    <div className={items.length === 1 ? classes.body_one : classes.body}>
                                        <Grid container alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Checkbox
                                                    //{...label}
                                                    checked={checked[key]}
                                                    onChange={(event)=>handleChange(event, key)}
                                                    icon={<Icon color='primary'>radio_button_unchecked</Icon>}
                                                    checkedIcon={<Icon color='primary'>radio_button_checked</Icon>}
                                                />
                                            </Grid>
                                            <Grid item><ProductImage src={item.main_product_image_web} type='TABLE' id={`src-${item.product_id}`}/> </Grid>
                                            <Grid item xs><Typography>{item.product}</Typography></Grid>
                                            <Grid item><Typography variant="subtitle2">x{item.quantity}</Typography></Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            )
                        }) : null}
                    </Grid>
                </Scrollbars>
            </div>
        </Card>
    );
}

export default OrderCard

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:10,
        border:'1px solid #C0C0C0',
        //height:300
    },
    header:{
        padding:20,
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px solid #C0C0C0'
    },
    body:{
        margin:'0px 40px',
        padding:'10px 0px',
        borderBottom:'1px solid #CFCFCF'
    }, 
    body_one:{
        margin:'0px 40px',
        padding:'10px 0px',
        //borderBottom:'1px solid #CFCFCF'
    },
    container:{
        height:186,
        overflowY:'auto',
        padding:'12px 40px',
    }
}))