import { Grid, Chip, Typography } from '@mui/material'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import AddCategoryModal from '../modals/AddCategoryModal'
import AutocompleteFormAdd from '../../../../../../components/Forms/AutocompleteFormAdd'

const Categories = ({product_categories, modals, actions, content, form, options }) => {

    /* const content = translations_esp.categories

    const [add_modal, setAddModal] = useState(false)

    const onDeleteItem = async(el) => {
        await request_delete_product_category(el)
        onUpdateCompleted()
    }

    const onAddProductItem = async (el) => {
        try {
            await request_create_product_category({product_id:id, category_id:el});
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    } */

    let category_form = {...form}
    category_form.options = options

    return(
        <div>
        <AddCategoryModal open={modals.add_category} onClose={()=>actions.onChangeModalStatus('add_category', false)} onUpdateCompleted={actions.onUpdateCategoriesCompleted} 
        onAddProductItem={actions.onAddProductCategory} content={content.modal}/>
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <Typography variant='subtitle1'>{content.title}</Typography>                
                </Grid>
                <Grid item xs={12}>
                    {/* <SearchCategories onAddItem={()=>setAddModal(true)} existingItems={product_categories} 
                    onAddProductItem={onAddProductItem} content={content.search_bar} /> */}
                    <AutocompleteFormAdd data={category_form} onChange={actions.onChangeSearchForm} add_register={content.search_bar.add_register}
                        onAdd={()=>actions.onChangeModalStatus('add_category', true)} onInputChange={actions.onSearchCategories} onAdd2={actions.onAddProductCategory} />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_categories.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.category} onDelete={()=>actions.onDeleteCategory(item.id_product_category)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </div>
    )
}

export default Categories