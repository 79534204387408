import React, {useEffect, useState} from 'react'
import { updateAuthorizationHeader } from '../../../../../shared/utility'
import { private_server } from '../../../../../variables/config'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import AddSegmentModal from './modals/AddSegmentModal'
import DeleteModal from './modals/DeleteModal'
import { request_segments } from './requests'

const SegmentsView = (props) => {

    const {history, cat_segments_search, cat_segments_table, onUpdateSegmentsSearch, onUpdateSegmentsTable } = props

    //general
    const [loading, setLoading] = useState(false)
    const [segments, setSegments] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [delete_modal, setDeleteModal] = useState(false)
 
    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState(null)

    useEffect(() => {
        
        initModule()
    }, [cat_segments_table])

    const initModule = async() => {try {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setLoading(true)
        const params = onGetParamsFromState(cat_segments_table, cat_segments_search)
        console.log(params)
        const _segments = await request_segments(params)
        setSegments(_segments.segments)
        setTotal(_segments.count)
        setLoading(false)
    } catch (error) {
        setLoading(false)
    }}

    
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(cat_segments_table, cat_segments_search)
        const _segments = await request_segments(params)
        setSegments(_segments.segments)
        setTotal(_segments.count)
        setAddModal(false)
    }

    const onSelectedItem = (item, type) => {
        const {id_segment} = item
        setSelected(id_segment)
        console.log(id_segment)
        if(type === 'edit') history.push(`/catalogs/segment/${id_segment}`)
        if(type === 'delete') setDeleteModal(true)
    }

    const onChangeStore = async(_id, prev_store) => {
        try{
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/segment/${_id}`, {store: prev_store === 1 ? 0 : 1})
            initModule()
        }catch(e){
            console.log(e)
        }    
    }

    return(
        <div>
            <AddSegmentModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} id={selected}/>
            <SimpleTable
                data={segments} 
                tableFilter={cat_segments_table}
                search={cat_segments_search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onUpdateSegmentsSearch}
                onUpdateTableFilter={(data) => onUpdateSegmentsTable(data)}
                onSelectedItem={onSelectedItem}
                onChangeStore={onChangeStore}
            />
        </div>
    )
}

export default SegmentsView