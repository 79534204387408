import React, {useState} from 'react'
import {
    LinearProgress,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Icon,
    TableContainer,
    Card,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DisplayText from '../../../../components/Texts/DisplayText'
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/empty.png'
import { translations as translations_esp } from '../../../../texts/esp/tables-products-translations'
import AvailableStatus from '../../../../components/Structure/DisplayData/AvailableStatus'
import ProductImage from '../../../../components/Structure/Images/ProductImage'
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'

const content = translations_esp.table

const header = content.header

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = tableStyles()
    const {data, onAddRegister, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, 
        loading, onEdit, onDelete, onChangeStatus, sending, search} = props

    const [selected, setSelected] = useState(null)

    const onChageStatusLocal = (item) => {
        onChangeStatus(item)
        setSelected(item?.product_id)
    }

   
    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <SearchBar search={search} onChange={onChangeSearch} onUpdateTableFilter={onUpdateTableFilter} tableFilter={tableFilter} />
                            </Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress color='primary'/></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 || item.id === 2 ? 'checkbox' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography className={classes.header} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {product_id, product, sub_name, presentation, image_mobile, order, msp_enabled} = item

                                        return (
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}} onClick={()=>onSelectedItem(item)}><ProductImage src={image_mobile} type='TABLE' id={`src-${product_id}`}/></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} padding='checkbox'><DisplayText variant='body2'>{product_id}</DisplayText></TableCell>          
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)}><DisplayText variant='body2'>{product}</DisplayText></TableCell>   
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)}><DisplayText variant='body2'>{sub_name}</DisplayText></TableCell>                         
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)}><DisplayText variant='body2'>{presentation}</DisplayText></TableCell> 
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)}><DisplayText variant='body2'>{order}</DisplayText></TableCell>   
                                                <TableCell className={classes.cell}><AvailableStatus onClick={()=>onChageStatusLocal(item)} status={msp_enabled} loading={sending && selected === product_id}/></TableCell> 
                                                <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} padding='checkbox'>
                                                    <Grid container spacing={1} wrap='nowrap'>
                                                        <Grid item><IconButton onClick={()=>onEdit(item)} size="large"><Icon>edit</Icon></IconButton></Grid>
                                                        <Grid item><IconButton onClick={()=>onDelete(item)} size="large"><Icon>delete</Icon></IconButton></Grid>
                                                    </Grid>
                                                </TableCell>                           
                                            </TableRow>
                                        );
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable