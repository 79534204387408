import { Grid, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import React, {useState} from 'react'
import WarningModal from '../../../../../../modals/WarningModal'
import { onGetErrorMessage, updateAuthorizationHeader } from '../../../../../../shared/utility';
import { private_server } from '../../../../../../variables/config';


const useStyles = makeStyles(theme => ({
    paper:{
        borderRadius:32, 
        padding:theme.spacing(5)
    },
    root:{
        paddingTop:32,
        paddingBottom:0
    },
    icon:{
        color:'white',
        background:red[700],
        borderRadius:'50%',
        fontSize:54,
        padding:8
    },
    message:{
        paddingTop:16,
        paddingBottom:16,
        maxWidth:300
    }
}))

const DeleteActiveModal = (props) => {

    const classes = useStyles()
    const {open, onClose, id, id_active, onUpdateCompleted} = props

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [disabled, setDisabled] = useState(false)

    const onDeleteImage = async() => {
        setDisabled(true)
        setLoading(true)
        try {
            //const _product = await request_delete_active(id, id_active)
            updateAuthorizationHeader(private_server)
            await private_server.delete(`/product/${id}/activesubstance/${id_active}`)
            onUpdateCompleted()
            onClose()
            setLoading(false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return(
        <WarningModal open={open} onClose={onClose} error={error} loading={loading} onSubmit={onDeleteImage} disabled={disabled}/>
    )
}

export default DeleteActiveModal
