import { Grid } from "@mui/material"
import ContainedButton from "../../../../../../components/Actions/ContainedButton"
import InputForm from "../../../../../../components/Forms/InputForm"
import SelectForm from "../../../../../../components/Forms/SelectForm"
import InputCheckbox from "../../../../../../components/Forms/InputCheckbox"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import DisplayText from "../../../../../../components/Texts/DisplayText"


const AddressCard = ({form, content, onChange, onSubmit, sending}) => {

    return (
        <SimpleCard>
            <Grid container spacing={4} justifyContent='center'>
                <Grid item xs={12}>
                    <DisplayText>{content.address.title}</DisplayText>
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.latitude} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.longitude} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.zip_code} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form.suburb_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.street} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.ext} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputForm data={form.int} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form.municipality_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form.state_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <SelectForm data={form.country_id} onChange={onChange} />
                </Grid>
                <Grid item xs={12}>
                    <DisplayText >{content.address.services}</DisplayText>
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputCheckbox data={form.store} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputCheckbox data={form.pickup} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputCheckbox data={form.delivery} onChange={onChange} />
                </Grid>
                <Grid item xs={12} md={6} >
                    <InputCheckbox data={form.appointment} onChange={onChange} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item>
                    <ContainedButton color='primary' loading={sending} disabled={sending} onClick={onSubmit}>{content.button}</ContainedButton>
                </Grid>  
            </Grid>
        </SimpleCard>
    );
}

export default AddressCard