
export const translations = {
    title:'Detalles del carrito',
    details_card:{
        details:'Detalles',
        user:'Cliente',
        warehouse:'Sucursal',
        payment_status:'Estatus de pago',
        currency:'Moneda',
        purchase_summary:'Resumen de compra',
        subtotal:'Subtotal',
        taxes:'Impuestos',
        discount:'Descuento',
        shipping_cost:'Envío',
        total:'Total'
    }
}

