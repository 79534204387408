import { Typography, Grid, Checkbox, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import RoundedButton from '../../../../components/Actions/Buttons/RoundedButton';
import SelectFormLabeled from '../../../../components/Forms/SelectFormLabeled';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import useAddUserRole from './useAddUserRole';

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 55,
		height: 30,
		padding: 0,
		paddingBottom: 2,
		display: 'flex',
	},
	switchBase: {
		padding: 4,
		color: 'lightgray',
		'&$checked': {
			transform: 'translateX(26px)',
			color: 'white',
			'& + $track': {
				opacity: 1,
				backgroundColor: '#96AE76',
				borderColor: '#96AE76',
			},
		},
	},
	thumb: {
		width: 20,
		height: 20,
		boxShadow: 'none',
	},
	track: {
		border: `1px solid lightgray`,
		borderRadius: 32 / 2,
		backgroundColor: 'white',
	},
	checked: {},
}))(Switch);

const AddUserRoleModal = ({ content, open, onClose, id, onUpdateCompleted }) => {
	const classes = useStyles();
	const { system, form, actions, main } = useAddUserRole({ content, id, open, onClose, onUpdateCompleted });

	return (
		<SimpleModal open={open} onClose={onClose} maxWidth='sm'>
			<Typography style={{ marginBottom: 50 }} variant='h5'>
				{content.title}
			</Typography>
			<Grid container>
				<Grid item container alignItems='center' justifyContent='center' xs={12} style={{marginBottom: 50}}>
					<Grid item xs={9}>
						<SelectFormLabeled data={form.role_id} onChange={actions.onChangeForm} />
					</Grid>
					<Grid item container justifyContent='flex-end' xs={3}>
						<div>
							<Typography className={classes.input_label}>{content.switch_label}</Typography>
							<AntSwitch checked={main} inputProps={{ 'aria-label': 'main role' }} onClick={actions.onChangeMain} />
						</div>
					</Grid>
				</Grid>

				<Grid item container xs={12}>
					<RoundedButton fullWidth={true} loading={system.loading} onClick={actions.onSubmit}>
						{content.button}
					</RoundedButton>
				</Grid>
			</Grid>
		</SimpleModal>
	);
};

const useStyles = makeStyles((theme) => ({
	input_label: {
		fontSize: 12,
		fontWeight: 500,
		marginBottom: 5,
	},
}));

export default AddUserRoleModal;
