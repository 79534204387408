import React, {useState, useEffect} from 'react'
import WarningModal from '../../../../modals/WarningModal'
import { onGetErrorMessage } from '../../../../shared/utility'
import { request_delete_storebanner } from '../requests'


const DeleteModal = props => {
 
    const { id, open, onClose, onUpdateCompleted } = props
  
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setError('')
        setLoading(false)
        setDisabled(false)
    }, [open])

    const onSubmit = async() => {
        setLoading(true)
        setDisabled(true)
        try {
            //console.log(id)
            await request_delete_storebanner(id)          
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return(
        <WarningModal open={open} onClose={onClose} error={error} loading={loading} onSubmit={onSubmit} disabled={disabled}/>
    )
}

export default DeleteModal