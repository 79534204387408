import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SelectForm from '../../../../components/Forms/SelectForm'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import { private_server } from '../../../../variables/config'
import { green } from '@mui/material/colors'


const AddModal = props => {

    const { open, onClose, onUpdateCompleted, history, content, catalogs } = props

    const classes = useStyles()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [form_medical, setFormMedical] = useState(JSON.parse(JSON.stringify(formMedical)))

    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks
    const [indigenous_check, setIndigenousCheck] = useState(false)

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setFormMedical(JSON.parse(JSON.stringify(formMedical)))
            let _form = onInitEmptyForm(form, content.form)
            _form.user_gender_id.options = catalogs.genders
            setForm(_form)
            let _form2 = onInitEmptyForm(form_medical, content.formMedical)
            _form2.language_id.options = catalogs.languages
            setFormMedical(_form2)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'password') temp['passwordConfirmation'].value2 = temp[id].value
        setForm(temp)
    }

    const onChangeFormMedical = (data) => {
        let temp = { ...form_medical }
        const id = data.config.id
        temp[id] = { ...data }
        setFormMedical(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirmation
        data2send.timezone = "America/Mexico_City"
        data2send.language_id = 1
        data2send.user_type_id = 2
        //data2send.mobile_country_id = 1
        data2send.currency_id = 2
        data2send.indigenous = indigenous_check ? 1 : 0

        //console.log(data2send)

        let datamedical2send = onGetFormData(form_medical)
        console.log(datamedical2send)

        setLoading(true)
        setDisabled(true)
        try {
            //const _newUser = await request_create_user(data2send);
            updateAuthorizationHeader(private_server)
            const _newUser = await private_server.post(`/users`, data2send)
            //await request_create_doctor_translations(_newUser.data.data.user_id, datamedical2send)
            await private_server.post(`/doctor/${_newUser.data.data.user_id}/translations`, datamedical2send)
            onUpdateCompleted()
            history.push(`/doctors/${_newUser.data.data.user_id}`)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.titles.doctors}</DisplayText>
                <DisplayText >{content.subtitle}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.fathers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.mothers_last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <FormControlLabel
                                    control={<Checkbox checked={indigenous_check} onChange={()=>setIndigenousCheck(!indigenous_check)} />}
                                    label={content.formControl.indigenous}
                                />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectForm data={form.user_gender_id} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.zip} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.email} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.password} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.passwordConfirmation} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>{content.title_medical}</DisplayText>
                        </Grid> 
                        <Grid item xs={12}>
                            <SelectForm data={form_medical.language_id} onChange={onChangeFormMedical} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form_medical.qualifications} onChange={onChangeFormMedical} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form_medical.bio} onChange={onChangeFormMedical} />
                        </Grid>  
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddModal

const useStyles = makeStyles(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    }
}))

const formMedical = {
    language_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        //options:[...catalogs.languages],
        options:[],
        config: {
            id: 'language_id',
            type: 'select',
            fullWidth: true,
        },
    },
    qualifications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'qualifications',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    bio: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'bio',
            type: 'text',
            fullWidth: true,
            multiline:true,
            rows:4
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}

const formData = {
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        //options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
        },
        rules: {
            type: 'email',
        }
    },
    password: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'password',
            type: 'password',
            fullWidth: true,
            label: 'Contraseña',
            helperText: 'Debe contener 6 a 20 caracteres',
            autoComplete:'new-password'
        },
        rules: {
            type: 'distance',
            min:6, max:20
        }
    },
    passwordConfirmation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'passwordConfirmation',
            type: 'password',
            fullWidth: true,
            label: 'Validar contraseña',
            helperText: 'Las contraseñas no coinciden'
        },
        rules: {
            type: 'equals',
        }
    },
    pin: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pin',
            type: 'text',
            fullWidth: true,
            label: 'PIN',
            helperText: 'El pin debe estar conformado por 4 números',
        },
        rules: {
            type: 'distance',
            min: 4, max: 4
        }
    },
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'Código postal no válido'
        },
        rules: {
            type: 'zip',
        }
    },    
}