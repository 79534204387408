import { success_msg } from "./general-translations"
import agency from '../../assets/icons/warehouses/agency.svg'
import user from '../../assets/icons/user.svg'
import product from '../../assets/icons/product.svg'
import map from '../../assets/map.png'
import email from '../../assets/icons/warehouses/email.svg'
import phone from '../../assets/icons/warehouses/phone.svg'
import settings from '../../assets/icons/settings.svg'

export const translations = {
    title:'Detalle de Sucursal',
    success_msg:success_msg,
    menu:[{id:1, label:'Información'}, {id:2, label:'Inventario'}, {id:3, label:'Usuarios'}, {id:4, label:'Horario local'}, {id:5, label:'Horario de entrega'}],
    main_card:{
        img:agency,
        map:map,
        map_button:'Ver mapa'
    },
    views:{
        info:{
            button:'Guardar',
            form:{
                name:{label:'Nombre', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                contact_name:{label:'Nombre de contacto', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                contact_email:{label:'Email', helperText:'Email no válido'},
                contact_phone:{label:'Telefono de contacto', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                street:{label:'Calle', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                ext:{label:'Número exterior', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                int:{label:'Número interior', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                additional_indications:{label:'Indicaciones adicionales', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                zip_code:{label:'Código postal', helperText:'Debe seleccionar una opción'},
                municipality_id:{label:'Municipio', helperText:'Debe seleccionar una opción',},
                state_id:{label:'Estado', helperText:'Debe seleccionar una opción'},
                country_id:{label:'País', helperText:'Debe seleccionar una opción'},
                suburb_id:{label:'Colonia', helperText:'Debe seleccionar una opción'},
                map_url:{label:'URL del mapa', helperText:'Url inválida'},
                latitude:{label:'Latitud', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                longitude:{label:'Longitud', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'},
                store:{label:'Productos en tienda'},
                pickup:{label:'Entrega en tienda'},
                delivery:{label:'Entrega a domicilio'},
                appointment:{label:'Consultorio'},
            },
            general:{
                title:'General'  
            },
            address:{
                title:'Dirección',
                services:'Servicios'
            }
        },
        inventory:{
            table:{
                header:[
                    {id:1, label:'ID producto'},
                    {id:2, label:'Producto'},
                    {id:3, label:'Cantidad'},
                    {id:4, label:'Ubicación'},    
                    {id:5, label:''},
                ],
                nodata:'No existen productos registrados',
                search_bar_label:'Buscar en inventario...',
                empty:product,            
            },
            modal:{
                add:{
                    title:'Agregar producto',
                    button:'Agregar'
                },
                edit:{
                    title:'Editar',
                    button:'Guardar'
                },
                form:{
                    product_id:{label:'Producto', helperText: 'Debe elegir una opción'},
                    available:{label:'Cantidad', helperText:'No admite números negativos'},
                    increment:{label:'Cantidad a incrementar/decrementar', helperText:'Cantidad no válida'},
                    location:{label:'Ubicación', helperText:'Este campo debe tener al menos 1 caracter y máximo 255'}
                },
            }
        },
        users:{
            table:{
                header:[
                    {id:1, label:'ID'},
                    {id:2, label:'Nombre'},
                    {id:3, label:'Rol principal'},
                    {id:4, label:'Email'},    
                    {id:5, label:'Celular'},
                    {id:6, label:''},
                ],
                nodata:'No existen usuarios registrados',
                search_bar_label:'Buscar en usuarios...',
                empty:user,     
                email:email,     
                phone:phone,    
                role:settings   
            },
            modal:{
                add:{
                    title:'Agregar usuario',
                    button:'Agregar'
                },
                edit:{
                    title:'Editar',
                    button:'Guardar'
                },
                form:{
                    user_id:{label:'Usuario', helperText: 'Debe elegir una opción'},
                },
            }
        },
        schedules:{
            attention_schedule:'Horarios de atención',
            special_dates:'Fechas especiales',
            holidays:'Días festivos',
            special_event_modal:{
                add:{
                    title:'Añadir fecha especial',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar',
                    button:'Guardar'
                },
                form:{
                    date:{label:'Selecciona la fecha', helperText: 'Debe elegir una opción'},
                    starttime:{label:'Fecha de inicio', helperText: 'Hora no válida'},
                    endtime:{label:'Fecha de fin', helperText: 'La hora debe ser mayor a la hora de inicio'},
                    open:{label:'Estatus', helperText: 'Debe elegir una opción'},
                },
            },
            holiday_modal:{
                add:{
                    title:'Añadir día festivo',
                    button:'Añadir'
                },
                edit:{
                    title:'Editar',
                    button:'Guardar'
                },
                form:{
                    date:{label:'Selecciona la fecha', helperText: 'Debe elegir una opción'},
                    name:{label:'Nombre', helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'},
                },
            }
        }
    },


    //old view
    edit_card:{
        title:'Información general',
        title_secondary:'Información de la dirección',
        button:'Guardar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_name:{
                label:'Nombre de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_email:{
                label:'Email',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            contact_phone:{
                label:'Telefono de contacto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            street:{
                label:'Calle',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            ext:{
                label:'Número exterior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            int:{
                label:'Número interior',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            additional_indications:{
                label:'Indicaciones adicionales',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            zip_code:{
                label:'Código postal',
                helperText:'Debe seleccionar una opción'
            },
            municipality_id:{
                label:'Municipio',
                helperText:'Debe seleccionar una opción',
            },
            state_id:{
                label:'Estado',
                helperText:'Debe seleccionar una opción'
            },
            country_id:{
                label:'País',
                helperText:'Debe seleccionar una opción'
            },
            suburb_id:{
                label:'Colonia',
                helperText:'Debe seleccionar una opción'
            },
            map_url:{
                label:'URL del mapa',
                helperText:'Debe contener una url'
            },
            latitude:{
                label:'Latitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            longitude:{
                label:'Longitud',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            store:'Productos en tienda',
            pickup:'Entrega en tienda',
            delivery:'Entrega a domicilio'
        }
    },
    inventory:{
        table: {
            header:[
                {id:1, label:'ID'},
                {id:2, label:'ID del producto'},
                {id:3, label:'Producto'},
                {id:4, label:'Cantidad'},
                {id:4, label:''},
            ],
            nodata:'No existen productos registrados'
        },
        modal:{
            add_title:'Agregar',
            edit_title:'Editar cantidad',
            button:'Agregar',
            form:{
                product_id:{
                    label:'Producto',
                    helperText: 'Debe elegir una opción'
                },
                available:{
                    label:'Productos disponibles',
                    helperText:'No admite números negativos'
                }
            },
        }
    },

    
    
}
