import SimpleTable from './components/SimpleTable';
import AddModal from './modals/AddModal';
import DeleteModal from './modals/DeleteModal';
//import EditModal from './modals/EditModal';
import useUsers from './useUsers';




const Users = ({content, history, id}) => {

    const {system, modals, actions, view_data} = useUsers({history, id})

  
    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} id={id} />
            <DeleteModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} onUpdateCompleted={actions.onInitModule} id={view_data.selected} />
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={view_data.tableFilter}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
                roles={view_data.roles}
            />
        </div>
    )
}



export default Users