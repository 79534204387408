import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"



const useSubstances = ({language, content}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const {limit, offset, order_by, order} = tableFilter
                const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
                let req
                if(search !== ''){
                    let filter = {filter:`{"AND":[{"field":"name","operator":"LIKE","value":"%${search}%"}]}`}
                    req = await private_server.get(`substance/list`, {params:{...params, ...filter}})
                }else{
                    req = await private_server.get(`substance/list`, {params:{...params}})
                }
                //console.log(req)
                setData(req.data.data.substances)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {id_substance} = item
            setSelected(id_substance)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useSubstances