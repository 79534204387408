import React from 'react';
import { nodata } from '../../../variables/config';
import { makeStyles } from '@mui/styles';

const PillStatus = ({value, bold, variant, catalogs, text_styles}) => {

    const classes = useStyles()
    const status = catalogs.find(el=>el.value === value)

    return ( 
        <div className={classes.root} >
            <div className={classes.status} style={{background: status ? status.color : undefined}}></div>
            {status ? status.label : nodata}
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.fontFamily,
        fontWeight:500,
        fontSize:'1rem',
        //color:'white',
        display:'flex',
        alignItems:'center',
        background:'transparent'
    },
    status:{
        width:10,
        height:10,
        borderRadius:'50%',
        marginRight:8
    }
}))

export default PillStatus

{/* <div className={classes.root} style={{background: status ? status.color : undefined}}>
            {status ? status.label : nodata}
        </div> */}