import SimpleTable from './components/SimpleTable';
import AddRoleModal from './modals/AddRoleModal';
import EditRoleModal from './modals/EditRoleModal';
import useRoles from './useRoles';

const Roles = (props) => {
	const { history, content } = props;
	const { system, modals, actions, view_data } = useRoles({ content, history });

	return (
		<>
			<EditRoleModal
				content={content.roles.modal}
				open={modals.edit}
				openImg={modals.picture_edit} 
				onClose={() => actions.onChangeModalStatus('edit', false)}
				onCloseImg={() => actions.onChangeModalStatus('picture_edit', false)}
				history={history}
				onChangePicture={() => actions.onChangeModalStatus('picture_edit', true)}
				onUpdateCompleted={actions.onInitModule}
				id_selected={view_data.idSelected}
			/>
			<AddRoleModal
				content={content.roles.modal}
				open={modals.add}
				openImg={modals.picture_add} 
				onClose={() => actions.onChangeModalStatus('add', false)}
				onCloseImg={() => actions.onChangeModalStatus('picture_add', false)}
				history={history}
				onChangePicture={() => actions.onChangeModalStatus('picture_add', true)}
				onUpdateCompleted={actions.onInitModule}
			/>
			<SimpleTable
				data={view_data.data}
				search={view_data.search}
				tableFilter={view_data.tableFilter}
				total={view_data.total}
				loading={system.loading}
				onAddRegister={() => actions.onChangeModalStatus('add', true)}
				onChangeSearch={actions.onChangeSearch}
				onUpdateTableFilter={actions.onChangeTableFilter}
				onSelectedItem={actions.onSelectItem}
				content={content.roles}
			/>
		</>
	);
};

export default Roles;
