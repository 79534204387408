import React from 'react'
import { Grid, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { nodata } from '../../../variables/texts'
import DisplayText from '../../Texts/DisplayText'
import { blueGrey } from '@mui/material/colors'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    icon:{
        color:theme.palette.primary.main,
        //border:`1px solid ${blueGrey[100]}`,
        borderRadius:'50%',
        padding:8, 
        background:theme.palette.primary.light,
        //width:30,
        //height:30 
    },
    icon_outlined:{
        background:'white',
        boxShadow:'0px 0px 4.02939px rgba(118, 118, 118, 0.43)'
    },
    svg_container:{
        background:theme.palette.primary.light,
        width:40,
        height:40,
        position:'relative',
        //padding:12,
        
        borderRadius:'50%'
    },
    svg:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        width:'60%',
        height:'60%',
        
    },
}))

const DisplayIconData = ({icon, data, svg, catalogs, id, variant}) => {

    const classes = useStyles()

    let selected = null

    if(catalogs && id) selected = catalogs.find(el=>el.value === id)

    let svg_selected = svg
    let data_selected = data

    if(selected){
        svg_selected = selected.icon
        data_selected = selected.label
    }

    return(
        <Grid container spacing={1} wrap='nowrap' alignItems='center' >
            <Grid item>
                {icon ? <Icon className={cx({[classes.icon]:true, [classes.icon_outlined]:variant === 'outlined'})}>{icon}</Icon> :
                <div className={classes.svg_container}><img src={svg_selected} className={classes.svg} /></div>}
            </Grid>
            <Grid item xs>
                <DisplayText variant='body2' >{data_selected ? data_selected : nodata}</DisplayText>
            </Grid>
        </Grid>
    )
}

export default DisplayIconData