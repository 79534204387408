import React from 'react'
import { Grid, Icon, InputBase } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blueGrey } from '@mui/material/colors'
import useSearchBar from './useSearchBar'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        //boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        border:'1px solid #E6E6E6',
        padding:'8px 16px',
        borderRadius:10,
        width:400,
        [theme.breakpoints.down('md')]: {
			width: '100%'
		},
        //minWidth:255
    },
    icon:{
        //color:blueGrey[500],
        color:theme.palette.primary.main

    }
}))

const SearchBar = props => {

    const classes = useStyles()

    const { onUpdateTableFilter, tableFilter, onChange, search, label} = props
    const {inputRef, actions} = useSearchBar({ search, onUpdateTableFilter, tableFilter, onChange })


    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                
                <Grid item xs>
                    <InputBase value={search} placeholder={label ? label : 'Buscar...'} onChange={actions.onUpdateSearchValue} 
                        onKeyUp={actions.onStartCountDown} onKeyDown={actions.onStopCountDown} 
                        ref={inputRef}/>
                </Grid>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar