import makeStyles from '@mui/styles/makeStyles';
import { grey } from "@mui/material/colors";


export const viewTableStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16,
        //borderRadius:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:400,
        /* '& .MuiTable-stickyHeader':{
            borderRadius:16,
            background:'red'
        } */
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    table_container:{
        maxHeight:600
    }
}))