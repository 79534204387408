import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"
import queryString from 'query-string'



const useDoctors = ({history, doctors_search, doctors_table}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_user'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [doctors_table])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = doctors_table
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        /* let params = `?${queryString.stringify(params2send)}`
        let filterArgs = [
            {"field":"user_type_id","operator":"=","value":"1"},
            {"field":"main_role_id","operator":"=","value":"2"},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
    
        if(search){
            let filterArgs = [
                {"field":"user_type_id","operator":"=","value":"1"},
                {"field":"main_role_id","operator":"=","value":"2"},
                {"field":"first_name","operator":"LIKE","value":`%${search}%`},
            ]
            filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
        } */

        //new filters
        let filterArgs = [
            {"field":"main_role_id","operator":"=","value":2},
        ]

        if(doctors_search){
            filterArgs.push({"field":"first_name","operator":"LIKE","value":`%${doctors_search}%`})
        }

        let finalArgs = {"AND":filterArgs}
        let params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`/users/all${params}`)
                //console.log(req)
                setData(req.data.data.users)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        /* onDeleteItem: async(item) =>{
            const {id_substance} = item
            setSelected(id_substance)
            actions.onChangeModalStatus('delete', true)
        }, */
        onSelectItem: async(item) =>{
            const {id_user} = item
            history.push(`/doctors/${id_user}`)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useDoctors