import React, { Fragment, useState } from 'react'
import {
    Card,
    CircularProgress,
    Collapse,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'
import ProductImage from '../../../../components/Structure/Images/ProductImage'
import inventory from '../../../../assets/icons/inventory.svg';
import { onGetDateWithTimezone, onGetDateWithTimezonev2 } from '../../../../shared/utility';
import { amber, grey } from '@mui/material/colors';
import { green, red } from '@material-ui/core/colors';
import moment from 'moment'
import BatchStatus from '../../../../components/Platform/BatchStatus';
const MasterBatchTable = props => {

    const classes = tableStyles()
    const { header, data,  total, tableFilter, onUpdateTableFilter, onSelectedItem, loading,
    } = props

    return (
        <div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography className={classes.header} variant='body2' >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_user, first_name, fathers_last_name, mothers_last_name, email, user_type, id_invetory, image_web, image_mobile, sku} = item

                                        const expiry_date = onGetDateWithTimezonev2(item?.expiry_date)
                                        const semaphore = onGetSemaphoreValue(expiry_date)
                                        


                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.id_batch}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.order_reference??'-'}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.sku??'-'}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.product}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.initial_quantity??0}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.number}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{item?.provider??'-'}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><Typography variant='body2'>{expiry_date ? expiry_date.format('DD MMM YY') : '-'}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} >
                                                    <BatchStatus {...semaphore}/>
                                                </TableCell>
                                            </TableRow>
                                            
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={inventory} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>No existen inventarios registrados</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default MasterBatchTable

const onGetSemaphoreValue = (date) => {
    let result = {
        label:'No definido',
        textColor: grey[700],
        color:grey[300],
    }
    if(!date) return result

    const currentDate = moment()
    const diff = date.diff(currentDate)
    
    if(diff <=0){
        //console.log('NEGATIVO')
        //console.log('Diff', diff, currentDate.format('DD MMM YY'), date.format('DD MMM YY'))
        result = {
            label:'Vencido',
            textColor: 'white',
            color:grey[900],
        }
    }else if(diff < 3*30*24*60*60*1000 && diff > 0){
        //console.log('RED')
        result = {
            label:'Urgente',
            textColor: red[800],
            color:red[100],
        }
    }else if(diff >= 3*30*24*60*60*1000 && diff < 6*30*24*60*60*1000){
        //console.log('RED')
        result = {
            label:'Moderado',
            textColor: amber[800],
            color:amber[100],
        }
    }else{
        result = {
            label:'Estable',
            textColor: green[800],
            color:green[100],
        }
    }
    return result
}