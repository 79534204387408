import { Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FileSaver from 'file-saver'
import axios from 'axios'
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import { isFormValid, onGetDateFormat, onGetFormData, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"
import moment from "moment"
import { useEffect, useState } from "react"
import RoundedButton from "../../../../components/Actions/RoundedButton"
import SelectForm from "../../../../components/Forms/SelectForm"
import AddBillingProfileModal from "../modals/AddBillingProfileModal"


const BillingCard = ({store_payment_invoice_id, order_status_id, paid_at, store_payment_id, user_id, modals, actions}) => {

    const classes = useStyles()

    const [invoice, setInvoice] = useState(null)
 
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    useEffect(async() => {
        if(Boolean(store_payment_invoice_id)){
            try{
                updateAuthorizationHeader(private_server)
                const _invoice = await private_server.get(`/storepaymentinvoice/${store_payment_invoice_id}`)
                setInvoice(_invoice.data.data.store_payment_invoice)
                const _profiles = await private_server.get(`/billingprofile/list`)
                const profiles_catalogs = _profiles.data.data.billing_profiles.map(item => {
                    return {value:item.id_billing_profile, label:item.tax_id + ' - ' + item.user}
                })
                form.billing_profile_id.options = profiles_catalogs
                
            }catch(e){
                console.log(e)
            }
        }
    }, [])

    const onCompleted = async() => {
        try{
            updateAuthorizationHeader(private_server)
            const _profiles = await private_server.get(`/billingprofile/list`)
            const profiles_catalogs = _profiles.data.data.billing_profiles.map(item => {
                return {value:item.id_billing_profile, label:item.tax_id + ' - ' + item.user}
            })
            form.billing_profile_id.options = profiles_catalogs
            
        }catch(e){
            console.log(e)
        }
    }

    const onDownloadInvoice = async(type) =>{
        if(type==='pdf'){
            const _invoice = await private_server.get(`/storepaymentinvoice/${store_payment_invoice_id}/download?type=pdf`)
            //const _invoice = await private_server.get(`/storepaymentinvoice/155/download?type=pdf`)
            const response = await axios.get(_invoice.data.data.path, {responseType: 'blob'})   
            FileSaver.saveAs(response.data, `factura.pdf`)
        }
        if(type==='xml'){
            const _invoice = await private_server.get(`/storepaymentinvoice/${store_payment_invoice_id}/download?type=xml`)
            //const _invoice = await private_server.get(`/storepaymentinvoice/155/download?type=xml`)
            const response = await axios.get(_invoice.data.data.path, {responseType: 'blob'})   
            FileSaver.saveAs(response.data, `factura.xml`)
        }
    }

    const onBilling = async() =>{

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        data2send.store_payment_id = store_payment_id

        try{
            await private_server.post(`/storepaymentinvoice/${user_id}`, data2send)
            actions.onInitModule()
        }catch(e){
            console.log(e)
        }
        
    }

    const onChangeForm = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    let view 

    if(!Boolean(store_payment_invoice_id)){

        view = <Typography>Este pedido podrá ser facturado al momento de la entrega</Typography>
        
        if(onGetDateFormat(paid_at, 'MM') === moment().format('MM') && order_status_id === 4 ){
            // se puede facturar
            view = <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant='caption' align="center" style={{color:'#787878'}}>Elige un perfil de facturación o agrega uno nuevo</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.billing_profile_id} onChange={onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3} alignItems='center' justifyContent="center">
                                <Grid item >
                                    <RoundedButton color='white_primary' onClick={()=>actions.onChangeModalStatus('add_billing_profile', true)}
                                    endIcon={<Icon className={classes.add_icon}>add</Icon>} >
                                    Agregar perfil
                                    </RoundedButton>
                                </Grid>
                                <Grid item ><RoundedButton onClick={onBilling} >Facturar</RoundedButton></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
        }
        if(onGetDateFormat(paid_at, 'MM') < moment().format('MM')){
            // el pedido esta fuera de la fecha de facturacio
            view = <Typography>Este pedido esta fuera de la fecha de facturación</Typography>
        }

    }

    if(invoice){
        view = <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Typography color='primary'>{invoice.tax_id}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">{invoice.legal_name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>{invoice.email}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item ><Typography variant='subtitle1'>Fecha:</Typography></Grid>
                                    <Grid item ><Typography>{onGetDateFormat(invoice.invoiced_at, 'DD/MM/YYYY, hh:mm a')}</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}><IconButton
                                className={classes.button}
                                onClick={()=>onDownloadInvoice('pdf')}
                                size="large"><Icon>file_download</Icon></IconButton></Grid>
                            <Grid item xs={12}><IconButton
                                className={classes.button}
                                onClick={()=>onDownloadInvoice('xml')}
                                size="large"><Typography variant='caption'>xml</Typography></IconButton></Grid>
                        </Grid>
                    </Grid>
                </Grid>
    }


    return(
        <SimpleCard>
            <AddBillingProfileModal open={modals.add_billing_profile} onClose={()=>actions.onChangeModalStatus('add_billing_profile', false)} 
                user_id={user_id} onCompleted={onCompleted} />
            <div style={{marginBottom:20}}>
                <Typography color='primary'>Facturación</Typography>
            </div>
            {view}
        </SimpleCard>
    )
}

export default BillingCard

const useStyles = makeStyles(theme => ({
    button:{
        color:theme.palette.primary.main,
        boxShadow:'0px 1px 4px rgba(0, 0, 0, 0.14)',
        background:'rgba(255, 255, 255, 0.37)'
    },
    add_icon:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        padding:4,
        //width:24,
        //height:24,
        color:'white'
    }
}))

const formData = {
    billing_profile_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'billing_profile_id',
            type: 'select',
            fullWidth: true,
            helperText: 'Debe seleccionar al menos una opción'
        },
        rules: {
            type: 'select',
        }
    },
}