import route from '../../assets/icons/route.svg'


export const translations = {
    title:'Rutas',
    table: {    
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Creador'},
            {id:3, label:'Repartidor'},
            {id:4, label:'# Pedidos'},
            {id:5, label:'Inicio estimado'},
            {id:6, label:'Inicio'},
            {id:7, label:'Fin'},
            {id:8, label:'Estatus'},
        ],
        nodata:'No existen rutas registradas',
        empty:route    
    },

}

export default translations

