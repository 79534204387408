
export const PRODUCT_DISCOUNT_TYPES = {
    DISCOUNT:1,
    BUNDLE_QXQ:2,
    BUNDLE:3
}

export const ORDER_TYPES = {
    DELIVERY:1,
    PICKUP:2,
    COUNTER:3
}

export const PAYMENT_METHODS = {
    CREDIT_CARD:1,
    PAYPAL:2,
    MERCADO:3,
    CASH:4,
    TPV:5
}

export const ORDER_PAYMENT_STATUS = {
    PENDING:1,
    DECLINED:2,
    EXPIRED:3,
    PAID:4,
    REFUNDED:5,
    PARTIALLY_REFUNDED:6,
    CHARGED_BACK:7,
    PRE_AUTHORIZED:8,
    VOIDED:9
}

export const SHIPPING_TYPES = {
    CELUENVIO:2,
    LOCAL:3, 
    NATIONAL:4,
    NONE:1
}

