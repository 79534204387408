import React, {useState} from 'react'
import { Grid, Icon, Popover } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { blueGrey, grey } from '@mui/material/colors'
import DisplayText from '../../../../components/Texts/DisplayText'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'10px 24px',
        borderRadius:32,
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:blueGrey[500],

    },
    subtitle:{
        paddingLeft:12,
        paddingBottom:4,
        color:blueGrey[400]
    },
    popper:{
        marginTop:8,
        padding:'16px 8px',
        paddingLeft:0,
        borderRadius:32,
        width:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    }
}))

const FilterButton = props => {
    const classes = useStyles()
    const {label, options, selected, onChange} = props

    const [anchorEl, setAnchorEl] = useState(null)

    let selectedItem = options.find(el => el.value === selected)


    const onInnerChange = (id) =>{
        setAnchorEl(null)
        onChange(id)
    }


    return(
        <div>
            <Popover classes={{paper:classes.popper}} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={(e) => setAnchorEl(null)} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}} transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.container} >
                    {options.map((item,key)=>{
                        return(
                            <div key={key.toString()} className={cx({
                                [classes.item]:true,
                                [classes.selected]: selected === item.value
                            })} onClick={() => onInnerChange(item.value)}>
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            </Popover>
                <DisplayText variant='subtitle2' className={classes.subtitle}>{label}</DisplayText>
            <div className={classes.root} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                    <Grid item>
                        <DisplayText>{selectedItem ? selectedItem.label : null}</DisplayText>
                    </Grid>
                    <Grid item>
                        <Icon className={classes.icon} fontSize='default'>keyboard_arrow_down</Icon>
                    </Grid>
                </Grid>
            </div>
        </div>
        
    )
}

export default FilterButton