import { Button, Checkbox, Grid, Typography } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import { connect } from 'react-redux'
import { actionTypes } from '../../../../../store/actions';
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard'
import Page from '../../../../../components/Structure/Page/Page'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { isFormValid, onCleanNullsFormData, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, string2Slug } from '../../../../../shared/utility'
import { request_category, request_products_categories, request_subcategories, request_update_category } from './requests'
import ImageContainer from './components/ImageContainer'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState, onGetSubcategoriesParams } from './custom_functions'
import AddProductCategoryModal from './modals/AddProductCategoryModal'
import DeleteProductCategoryModal from './modals/DeleteProductCategoryModal'
import ChangeImageModal from './modals/ChangeImageModal'
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable'
import SimpleTableSubcategories from './components/SimpleTableSubcategories'
import { translations as translations_esp } from '../../../../../texts/esp/category-translations'
import DeleteSubcategoryModal from './modals/DeleteSubcategoryModal'
import AddSubcategoryModal from './modals/AddSubcategoryModal'
import SelectForm from '../../../../../components/Forms/SelectForm'
import { catalogs } from '../../../../../texts/esp/catalogs'
import CheckForm from '../../../../../components/Forms/CheckForm';
import UrlButton from '../../../../../components/Actions/UrlButton';

const CategoryView = (props) => {

    const content = translations_esp

    const {match, history} = props
    //global props
    const { onUpdateSending , onUpdateError, onUpdateSuccess } = props

    const id = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState(null)
    const [category, setCategory] = useState(null)
    const [productsCategory, setProductsCategory] = useState([])
    const [subcategories, setSubcategories] = useState([])

    //tables
    /*products*/
    const [table_loading, setTableLoading] = useState(false)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:0, order_by:'id_product'})
    const [search, setSearch] = useState(null)

    /*subcategories*/
    const [sub_table_loading, setSubTableLoading] = useState(false)
    const [sub_total, setSubTotal] = useState(0)
    const [sub_tableFilter, setSubTableFilter] = useState({limit:10, offset:0, order:0, order_by:'category_id'})
    const [sub_search, setSubSearch] = useState(null)

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [delete_modal, setDeleteModal] = useState(false)
    const [image_modal, setImageModal] = useState(false)
    const [id_selected, setIdSelected] = useState(null)
    const [add_sub_modal, setAddSubModal] = useState(false)
    const [delete_sub_modal, setDeleteSubModal] = useState(false)

    const [checked, setChecked] = useState(true)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _category = await request_category(id)
            setCategory(_category.category_data)
            setColor(_category.category_data.color)
            setChecked(_category.category_data.store)
            //setSubTotal(_category.category_data.sub_categories.length)
            let _form = onInitEmptyForm(form, content.edit_card.form)
            _form = onInitForm(_form, _category.category_data)
            setForm(_form)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const initModule = async() => {try {
            setSubTableLoading(true)
            const subcategories_params = onGetSubcategoriesParams(sub_tableFilter, sub_search)
            //console.log(subcategories_params)
            const _subcategories = await request_subcategories(id, subcategories_params)
            //console.log(_subcategories)
            setSubcategories(_subcategories.sub_categories)
            setSubTotal(_subcategories.count)
            setSubTableLoading(false)
        } catch (error) {
            setSubTableLoading(false)
            console.log(error)
        }}
        initModule()
    }, [sub_tableFilter])

    useEffect(() => {
        const initModule = async() => {try {
            setTableLoading(true)
            const products_params = onGetParamsFromState(tableFilter, search, id)
            //console.log(products_params)
            const _products = await request_products_categories(products_params)
            //console.log(_products)
            setProductsCategory(_products.products)
            setTotal(_products.count)
            setTableLoading(false)
        } catch (error) {
            setTableLoading(false)
            console.log(error)
        }}
        initModule()
    }, [tableFilter])


    const onChange = (data) => {
        //console.log(data)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onChangeSubSearch = (val) => {
        setSubSearch(val)
        //setSubTableFilter({...sub_tableFilter, offset:0})
    }

    /* const onSelectedItem = (item) => {
        const {product_id} = item
        history.push(`/products/${product_id}`)
    } */

    const onDeleteItem = (item) => {
        const {id_product} = item
        setIdSelected(id_product)
        setDeleteModal(true)
    }

    const onUpdateCompleted = async() => {
        const products_params = onGetParamsFromState(tableFilter, search, id)
        const _products = await request_products_categories(products_params)
        setProductsCategory(_products.products)
        setAddModal(false)
        setDeleteModal(false)
    }

    const onUpdateImageCompleted = async() => {
        setLoading(true)
        const _category = await request_category(id)
        setCategory(_category.category_data)
        setColor(_category.category_data.color)
        //setSubTotal(_category.category_data.sub_categories.length)
        
        let _form = onInitForm(form, _category.category_data)
        setForm(_form)
        setLoading(false)
    }

    const onUpdateSubcategoriesCompleted = async() => {
        setSubTableLoading(true)
        const subcategories_params = onGetSubcategoriesParams(sub_tableFilter, sub_search)
        //console.log(subcategories_params)
        const _subcategories = await request_subcategories(id, subcategories_params)
        //console.log(_subcategories)
        setSubcategories(_subcategories.sub_categories)
        setSubTotal(_subcategories.count)
        setSubTableLoading(false)
        setAddSubModal(false)
        setDeleteSubModal(false)
    }

    const onDeleteSubcategory = (item) => {
        const {id_sub_category} = item
        setIdSelected(id_sub_category)
        setDeleteSubModal(true)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, category)
        data2send.color = color
        data2send.store = checked ? 1 : 0
        data2send = onCleanNullsFormData(data2send)
        console.log(data2send)

        
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_category(id, data2send);
            setSending(false)
            //onUpdateImageCompleted()
            onUpdateSuccess(content.success_msg)
            //setSucess('Acción exitosa')
        } catch (error) {
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
            //setError('Hubo un error')
            setSending(false)
        }
        onUpdateSending(false)
    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    const onUpdateLink = () => {
        //console.log('--------UPDATE LINK------------')
        //console.log(form)

        const cleandata = onGetFormData(form)

        let data = ''
        
        if(cleandata.name) data = `${data} ${cleandata.name.trim()}`

        data = data.trim()
        console.log(data)
        console.log(string2Slug(data))

        const _slug = string2Slug(data)
        if(_slug) {
            let _form = {...form}
            _form.url.value = _slug
            _form.url.isValid = true
            _form.url.isVisited = true
            setForm(_form)
        }

    }

    //image web - mobile
    const menu = content.edit_card.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image={category ? category.image_web : null} onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image={category ? category.image_mobile : null} onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }

    //tables
    const tables_menu = content.tables.menu
    const [table_view, setTableView] = useState(1)

    const onChangeTableView = (id) => {
        setTableView(id)
    }
    
    let tableView = null

    switch(table_view){
        case 1:
            tableView = <SimpleTable
                            data={productsCategory} 
                            tableFilter={tableFilter}
                            search={search}
                            total={total}
                            loading={table_loading}
                            onAddRegister={() => setAddModal(true)}
                            onChangeSearch={onChangeSearch}
                            onUpdateTableFilter={(data) => setTableFilter(data)}
                            //onSelectedItem={onSelectedItem}
                            onDeleteItem={onDeleteItem}
                            />                    
            break
        case 2:
            tableView = <SimpleTableSubcategories
                            data={subcategories} 
                            tableFilter={sub_tableFilter}
                            total={sub_total}
                            loading={sub_table_loading}
                            search={sub_search}
                            onChangeSearch={onChangeSubSearch}
                            onAddRegister={() => setAddSubModal(true)}
                            onUpdateTableFilter={(data) => setSubTableFilter(data)}
                            onDeleteItem={onDeleteSubcategory}
                            /> 
                            
            break
        default:
            break
    }

    return (
        <Page title={content.title} loading={loading} goback success={success} onCloseSuccess={() => setSucess(null)}>
            <AddProductCategoryModal open={add_modal} onClose={()=>setAddModal(false)} id={id} onUpdateCompleted={onUpdateCompleted}  />
            <DeleteProductCategoryModal open={delete_modal} onClose={()=>setDeleteModal(false)} id={id_selected} onUpdateCompleted={onUpdateCompleted} />
            <ChangeImageModal id={id} open={image_modal} onClose={() => setImageModal(false)} type={type} onUpdateCompleted ={onUpdateImageCompleted}/>
            <AddSubcategoryModal open={add_sub_modal} onClose={()=>setAddSubModal(false)} id={id} 
            onUpdateCompleted={onUpdateSubcategoriesCompleted}  />
            <DeleteSubcategoryModal open={delete_sub_modal} onClose={()=>setDeleteSubModal(false)} id={id_selected} 
            onUpdateCompleted={onUpdateSubcategoriesCompleted} />
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <SimpleCard>
                        <Grid container spacing={4} justifyContent='flex-end'>
                            <Grid item xs={12}>                          
                                {/* {contentView}                            
                                <div style={{marginTop:12}}>
                                <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                                </div> */}
                                <Grid container justifyContent='center'>
                                    <Grid item style={{width:400}}>
                                        {contentView}
                                        <div style={{marginTop:12, display:'flex', justifyContent:'center'}}>
                                        <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >
                                <InputForm data={form.name} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12} >
                                <Grid container spacing={1}>
                                    <Grid item xs><InputForm data={form.url} onChange={onChange} /></Grid>
                                    <Grid item><UrlButton onClick={onUpdateLink} /></Grid>
                                </Grid> 
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectForm data={form.order} onChange={onChange} />
                            </Grid>                          
                            <Grid item xs={12} md={6}>
                                <DisplayText variant='body2' color='textSecondary'>{content.edit_card.form.color.label}</DisplayText>
                                <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                            </Grid>                         
                            <Grid item xs={12} >
                                <InputForm data={form.description} onChange={onChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item><Checkbox color='primary' checked={checked} onChange={(event)=>setChecked(event.target.checked)}  /></Grid>
                                    <Grid item><Typography color='textSecondary'>{content.edit_card.form.store.label}</Typography></Grid>
                                </Grid> 
                            </Grid>
                            <Grid item>
                                <ContainedButton color='primary' loading={sending} onClick={onSubmit}>{content.edit_card.button}</ContainedButton>
                            </Grid>  
                        </Grid>
                    </SimpleCard>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <div >
                    <ToolbarTable selected={table_view} menuOptions={tables_menu} onChange={onChangeTableView} />
                    </div>
                    {tableView} 
                </Grid>
            </Grid>
        </Page>
    );

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.SYS_UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(CategoryView)

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 0 caracter y máximo 255',
            multiline:true,
            rows:3
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}