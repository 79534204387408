import React, { useState } from 'react';
import PictureModal from '../../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../shared/utility';
import { request_upload_tag_image, request_delete_tag_image } from '../requests';
import { translations as translations_esp } from '../../../../../texts/esp/modals-translations'

const UploadImageModal = (props) => {

    const { open, onClose, type, onUpdateDataImage} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)
            const _file = await onGetImageURL2Blob(file)
            //console.log(_file)
            let data
            if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
            if(type === 'mobile'){data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
            onUpdateDataImage(data, _file, file)
            //await request_upload_tag_image(id, _file, data)
            onClose()
            //onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
        setDisabled(false)
    }

    

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} aspect_ratio={1} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit}/>
     );
}
 
export default UploadImageModal;