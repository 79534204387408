
export const success_msg = 'Acción exitosa'
export const change_image_error_msg= 'Debe adjuntar/seleccionar una imagen para continuar'
export const edit='EDITAR'
export const upload_image_modal_mgs='Subir document'
export const file_view={
    click:'De click o arrastre aquí o',
    find_a_file:'Encuentre un archivo'
}
export const input_file={
    max_size:'Tamaño máximo del archivo: 25MB',
    modify_file:'Si desea modificar el archivo de click o arrastre uno nuevo en el recuadro',
    click:'De click o arrastre aquí o',
    find_a_file:'Encuentre un archivo'
}
