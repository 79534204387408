import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { catalogs } from '../../../../texts/esp/catalogs';
import { DASHBOARD_SETTINGS, nodata } from '../../../../variables/config';
import cx from 'classnames'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon';
import { useEffect } from 'react';


const StatusCard = ({order, actions}) => {

    const classes = useStyles()
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if(order) setSelected(order.order_status_id)
        //console.log(route)
    }, [order])

    
    const items = catalogs.order_statuses.filter(el => el.value !== -1)

    return ( 
        <div className={classes.root}>
            {/* <Typography variant='subtitle1'>Estatus</Typography> */}
            <div className={classes.list_container}>
                {/* <div className={classes.line}/> */}
                <div style={{zIndex:5}}>
                    <Grid container spacing={1}>
                        {items.map((item, index)=>{
                            return (
                            <Grid item key={index.toString()}>
                                <div className={classes.element}>
                                    {index === items.length-1 ? null : <div className={classes.line}/>}
                                    <div className={cx({
                                        [classes.icon_container]:true,
                                        [classes.icon_container_selected]: item.value === selected
                                    })}>
                                        <div className={classes.icon}>
                                            <SVGIcon src={item.icon} width={24} height={24} color={item.value === selected ? 'white' : '#BABABA'}/>
                                        </div>
                                    </div>
                                    <Typography variant='body2' style={{marginTop:12}}>{item.label}</Typography>
                                </div>
                            </Grid> 
                            )
                        })}
                    </Grid>
                </div>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        borderRadius:12,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:'24px 24px',
        
    },
    list_container:{
        position:'relative',
        marginTop:24,
        //display:'flex',
        //background:'green'

    },
    element:{
        //display:'flex',
        //alignItems:'center',
        marginBottom:16,
        paddingRight:16,
        position:'relative',
        width:120
        //background:'red'
    },
    icon_container:{
        width:42,
        height:42,
        borderRadius:'50%',
        border:'1px solid #ABABAB',
        background:'white',
        zIndex:2,
        position:'relative'
    },
    icon_container_selected:{
        background:theme.palette.primary.main,
        border:`1px solid ${theme.palette.primary.main}`,
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
    },
    line:{
        height:1,
        width:'100%',
        background:'#ABABAB',
        position:'absolute',
        top:20, //left:20,
        zIndex:0
    }
}))
 
export default StatusCard;