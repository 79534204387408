import React, { useState} from 'react'
import { Hidden } from '@mui/material'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import { translations as translations_esp } from '../../../texts/esp/product-translations'
import { actionTypes } from '../../../store/actions'
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import PageMenuMobile from '../../../components/Structure/Navigation/PageMenuMobile'
import General from './views/General/General'
import RelatedProducts from './views/RelatedProducts/RelatedProducts'
import AlsoSeenProducts from './views/AlsoSeenProducts/AlsoSeenProducts'
import PurchasedWithProduct from './views/FreqPurchasedWithProduct/PurchasedWithProduct'


const ProductView = props => {

    const { match, onUpdateSending, onUpdateSuccess, onUpdateError } = props
    const content = translations_esp
    
    const [view, setView] = useState(1)

    let contentView = null

    switch(view){
        case 1:
            contentView = <General content={content.general} {...props} />
            break
        case 2:
            contentView = <RelatedProducts content={content.related_products} match={match} />
            break
        case 3:
            contentView = <AlsoSeenProducts content={content.also_seen_products} match={match}/>
            break
        case 4:
            contentView = <PurchasedWithProduct content={content.freq_purchased_with_product} match={match}/>
            break
        default:
            break
    }
  
    return (
        <Page title={content.title} goback >
            <Hidden mdDown>
                <ToolbarTable selected={view} menuOptions={content.menu} onChange={(id)=>setView(id)} />
            </Hidden>
            <Hidden mdUp>
                <PageMenuMobile selected={view} options={content.menu} onChange={(id)=>setView(id)} />
            </Hidden>
            <div style={{marginTop:80}}>
                {contentView}
            </div>
        </Page>
    );
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.SYS_UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(ProductView) 

