import { Hidden } from '@mui/material'
import React, {useState} from 'react'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import { translations as translations_esp } from '../../texts/esp/settings-translations'
import Permissions from './Permissions/Permissions'

import Roles from './Roles/Roles'



const SettingsMenu = (props) => {

    const content = translations_esp

    const {history} = props

    const [view, setView] = useState(1)
  
    const menu = content.menu
   

    const onChangeView = (id) => {
        setView(id)
    }
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <Roles history={history} content={content} />
            break
        case 2:
            contentView = <Permissions history={history} content={content} />
            break
        default:
            break
    }

    return (
        <Page title={content.title}>
        <Hidden mdDown>            
            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={onChangeView} />
        </Hidden>
        <div style={{marginTop:12}}>
            {contentView}
        </div>
        </Page>
    );
}

export default SettingsMenu