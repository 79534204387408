import React, { useState, useEffect } from 'react';
//import { DASHBOARD_SETTINGS, private_server, public_server } from '../config';
import { updateAuthorizationHeader, onGetErrorMessage } from '../shared/utility';
import { private_server, public_server, DASHBOARD_SETTINGS } from '../variables/config';


const useWarehouses = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    

    const actions = {
        onGetAll: async() => {
            setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                //const filter_ware = JSON.stringify({"AND":[{"field":"pickup","operator":"=","value":1}]})
                const warehouses_request = await private_server.get(`/warehouse/list?limit=100&offset=0&order=1&order_by=name`)
                const _warehouses = warehouses_request.data.data.warehouses_data
                setWarehouses(_warehouses)
            } catch (error) {
                console.log(error)
                const _e = onGetErrorMessage(error)
                setError(_e.message)
            }
            setLoading(false)
        },
        onCheckAddressDeliveryAvailable: async(id_address) => {
            setLoading(true)
            let response = null
            try {
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/warehouse/address/${id_address}/${DASHBOARD_SETTINGS.CURRENCY_ID}`)
                response = request.data.data.warehouse
            } catch (error) {
                console.log(error)
                setError('No hay disponibilidad para esta dirección')
            }
            setLoading(false)
            return response
        },
        onCheckNextDeliverySlotTime: async(id_address) => {
            setLoading(true)
            let response = null
            try {
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/warehouse/address/${id_address}/delivery/schedule/next`)
                response = request.data.data.warehouse_delivery_schedule_slot
            } catch (error) {
                console.log(error)
                setError('No hay disponibilidad para esta dirección')
            }
            setLoading(false)
            return response
        },
        onGetScheduleTimesAvailable: async(id_address, params)  => {
            setLoading(true)
            let response = []
            try {
                updateAuthorizationHeader(private_server)
                const request = await private_server.get(`/warehouse/address/${id_address}/delivery/schedule`, {params: params})
                response = request.data.data.warehouse_delivery_schedule_slots
            } catch (error) {
                console.log(error)
                setError('No hay disponibilidad de horarios para esta direccion')
            }
            setLoading(false)
            return response
        },
        onCheckNextStoreSlotTime: async(id_warehouse) => {
            setLoading(true)
            let response = null
            try {
                const request = await public_server.get(`/warehouse/${id_warehouse}/store/schedule/next`)
                response = request.data.data.warehouse_store_schedule_slot
            } catch (error) {
                console.log(error)
                setError('No hay disponibilidad para este warehouse')
            }
            setLoading(false)
            return response
        },
        onGetStoreScheduleTimesAvailable: async(id_warehouse, params)  => {
            setLoading(true)
            let response = []
            try {
                const request = await public_server.get(`/warehouse/${id_warehouse}/store/schedule`, {params: params})
                response = request.data.data.warehouse_store_schedule_slots
            } catch (error) {
                console.log(error)
                setError('No hay disponibilidad de horarios para esta direccion')
            }
            setLoading(false)
            return response
        },
    }

    const data = {warehouses}
    const system = {loading, sending, error}

    return {
        system, data, actions
    }
}
 
export default useWarehouses;