import { Hidden } from '@mui/material'
import React, {useState} from 'react'
import { connect } from 'react-redux'
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile'
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable'
import Page from '../../components/Structure/Page/Page'
import { actionTypes } from '../../store/actions'
//import * as actionTypes from '../../store/actions'
import { translations as translations_esp } from '../../texts/esp/products_menu-translations'
import FaveProducts from './FaveProducts/FaveProducts'
import MostSoldProducts from './MostSoldProducts/MostSoldProducts'
import NewProducts from './NewProducts/NewProducts'
import OutStandingProducts from './OutStandingProducts/OutStandingProducts'
import Products from './Products/Products'
import SaleProducts from './SaleProducts/SaleProducts'
import VisitedProducts from './VisitedProducts/VisitedProducts'

const ProductsMenu = (props) => {

    const content = translations_esp

    const { history, onUpdateError, view, onUpdateView, products_general_table, onUpdateProductsGeneralTable,
    products_general_search, onUpdateProductsGeneralSearch, products_mostsold_search, products_mostsold_table, onUpdateProductsMostsoldSearch, onUpdateProductsMostsoldTable,
    products_new_search, products_new_table, onUpdateProductsNewSearch, onUpdateProductsNewTable, products_out_search, products_out_table, onUpdateProductsOutSearch, 
    onUpdateProductsOutTable, products_sale_search, products_sale_table, onUpdateProductsSaleSearch, onUpdateProductsSaleTable} = props

    //const [view, setView] = useState(1)
  
    const menu = content.menu

    const onChangeView = (id) => {
        /* let urlwithargs = '/catalogs'
        if(id === 3) urlwithargs = '/catalogs/category'
        if(id === 4) urlwithargs = '/catalogs/subcategory'
        if(id === 5) urlwithargs = '/catalogs/segment'
        window.history.replaceState(null, null, urlwithargs); */ 
        //setView(id)
        onUpdateView(id)
        console.log(id)
    }
        
    let contentView = null

    switch(view){
        case 1:
            contentView = <Products {...props}  />
            break
        case 2:
            contentView = <FaveProducts {...props} />
            break
        case 3:
            contentView = <VisitedProducts {...props} />
            break
        case 4:
            contentView = <MostSoldProducts {...props} />
            break
        case 5:
            contentView = <NewProducts {...props} />
            break
        case 6:
            contentView = <OutStandingProducts {...props} />
            break
        case 7:
            contentView = <SaleProducts {...props} />
            break
        default:
            break
    }

    return (
        <Page title={content.title}>
        <Hidden mdDown>
            <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
        </Hidden>
        <Hidden mdUp>
            <PageMenuMobile selected={view} options={menu} onChange={onChangeView} />
        </Hidden>
        <div style={{marginTop:12}}>
            {contentView}
        </div>
        </Page>
    );
}

const mapStateToProps = state => {
    return{
        view: state.products_view,
        products_general_table: state.products_general_table,
        products_mostsold_table: state.products_mostsold_table,
        products_new_table: state.products_new_table,
        products_out_table: state.products_out_table,
        products_sale_table: state.products_sale_table,
        products_general_search: state.products_general_search,
        products_mostsold_search: state.products_mostsold_search,
        products_new_search: state.products_new_search,
        products_out_search: state.products_out_search,
        products_sale_search: state.products_sale_search,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateView: (products_view) => dispatch({type: actionTypes.CHANGE_PRODUCTS_VIEW, products_view }),
        onUpdateProductsGeneralTable: (products_general_table) => dispatch({type: actionTypes.CHANGE_PRODUCTS_GENERAL_TABLE, products_general_table }),
        onUpdateProductsMostsoldTable: (products_mostsold_table) => dispatch({type: actionTypes.CHANGE_PRODUCTS_MOSTSOLD_TABLE, products_mostsold_table }),
        onUpdateProductsNewTable: (products_new_table) => dispatch({type: actionTypes.CHANGE_PRODUCTS_NEW_TABLE, products_new_table }),
        onUpdateProductsOutTable: (products_out_table) => dispatch({type: actionTypes.CHANGE_PRODUCTS_OUT_TABLE, products_out_table }),
        onUpdateProductsSaleTable: (products_sale_table) => dispatch({type: actionTypes.CHANGE_PRODUCTS_SALE_TABLE, products_sale_table }),
        onUpdateProductsGeneralSearch: (products_general_search) => dispatch({type: actionTypes.CHANGE_PRODUCTS_GENERAL_SEARCH, products_general_search }),
        onUpdateProductsMostsoldSearch: (products_mostsold_search) => dispatch({type: actionTypes.CHANGE_PRODUCTS_MOSTSOLD_SEARCH, products_mostsold_search }),
        onUpdateProductsNewSearch: (products_new_search) => dispatch({type: actionTypes.CHANGE_PRODUCTS_NEW_SEARCH, products_new_search }),
        onUpdateProductsOutSearch: (products_out_search) => dispatch({type: actionTypes.CHANGE_PRODUCTS_OUT_SEARCH, products_out_search }),
        onUpdateProductsSaleSearch: (products_sale_search) => dispatch({type: actionTypes.CHANGE_PRODUCTS_SALE_SEARCH, products_sale_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsMenu)