import WarningModal from '../../../../modals/WarningModal'
import useDeletePermission from './useDeletePermission'


const DeletePermissionModal = props => {
 
    const { id, open, onClose, onUpdateCompleted } = props
  
    const {system, actions} = useDeletePermission({ id, open, onClose, onUpdateCompleted })

    return(
        <WarningModal open={open} onClose={onClose} error={system.error} loading={system.loading} onSubmit={actions.onSubmit} disabled={system.disabled}/>
    )
}

export default DeletePermissionModal