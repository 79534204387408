import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../../components/Forms/InputForm'
import { private_server } from '../../../../../../variables/config'
import { modalStyles } from '../../../../../../styles/modalStyles'
import AutocompleteFormOptions from '../../../../../../components/Forms/AutocompleteFormOptions'
//import { request_create_inventory, request_products } from '../requests'




const AddModal = ({open, onClose, onUpdateCompleted, content, id}) => {

    const classes = modalStyles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    const [products, setProducts] = useState([])
   

    useEffect(() => {
    const onInitModal = async() => {
        if (open) {
            let _form = onInitEmptyForm(formData, content.form)
            setForm(_form)         
        }else{
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false);        
        }
    }
    onInitModal()
    }, [open])

    
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChange2 = (e, item) =>{
        if(item){
            let temp = { ...form }
            temp['product_id'].value = item.value
            temp['product_id'].isValid = true
            temp['product_id'].isVisited = true
        }
    }

    const onSearchProduct = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0) return
        if(error) setError(null)
        try {
            const req = await private_server.get(`product/search?limit=30&offset=0&search=${e.target.value}`)
            const cat = req.data.data.products.map(item => {
                return {value: item.id_product, label: item.name}})
            setProducts(cat)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log(errors)
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.warehouse_id = id
        //if(product) data2send.product_id = product
        console.log(data2send)
        
        setLoading(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`/inventory`, data2send)
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
       
    }

    let product_form = {...form.product_id}
    product_form.options = [...products]

    return (
        <SimpleModal maxWidth='xs' open={open} onClose={onClose}>
            <DisplayText variant='h6' >{content.add.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <AutocompleteFormOptions data={product_form} onChange={onChange2} onInputChange={onSearchProduct} />
                        </Grid>
                        <Grid item xs={12} >                                  
                            <InputForm data={form.available} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button} >
                    <ContainedButton color='primary' onClick={onSubmit} loading={loading} disabled={loading}>
                        {content.add.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddModal

const formData = {
    product_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    available: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'available',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 1, max: 500000
        }
    },
}
