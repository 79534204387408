import axios from 'axios'
import { BASIC_AUTH_HEADER, mainServer } from '../../../variables/config'

//gets
export const request_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/${id}`)
    return response.data.data
}

export const request_product_price = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/${id}/prices`)
    return response.data.data
}

export const request_product_images = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/${id}/images`)
    return response.data.data
}

/*------- BRANDS -------*/
export const request_brands = async() => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/brand/list`)
    return response.data.data
}

/*------- CATEGORIES -------*/
export const request_categories = async() => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/category/list`)
    return response.data.data
}

export const request_categories_by_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/productcategory/product/${id}`)
    return response.data.data
}

/*------- SEGMENTS -------*/
export const request_segments = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/segment/list${params}`)
    return response.data.data
}

export const request_segments_by_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/productsegment/product/${id}`)
    return response.data.data
}

/*------- SUBCATEGORIES -------*/
export const request_subcategories = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/subcategory/list${params}`)
    return response.data.data
}

export const request_subcategories_by_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/productsubcategory/product/${id}`)
    return response.data.data
}

/*------- SUBSEGMENTS -------*/
export const request_subsegments = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/subsegment/list${params}`)
    return response.data.data
}

export const request_subsegments_by_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/productsubsegment/product/${id}`)
    return response.data.data
}

/*------- TAGS -------*/
export const request_tags_by_product = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/producttag/product/${id}`)
    return response.data.data
}

export const request_tags = async() => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/tag/list`)
    return response.data.data
}

/*-------------- ACTIVES ----------------*/
export const request_actives = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/${id}/activesubstances`)
    return response.data.data
}


//patchs
export const request_update_product = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}`, data)
    return response.data.data
}

export const request_update_main_image = async(id, id_image) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}/image/${id_image}`, {main: 1})
    return response.data.data
}

export const request_update_product_prices = async(id, id_price, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}/price/${id_price}`, data)
    return response.data.data
}

export const request_update_active = async(id, id_active, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}/activesubstance/${id_active}`, data)
    return response.data.data
}


//posts
export const request_create_product_prices = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/product/${id}/price`, data)
    return response.data.data
}

/*------- BRANDS -------*/
export const request_create_brand = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/brand`, data)
    return response.data.data
}

/*------- CATEGORIES -------*/
export const request_create_category = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/category`, data)
    return response.data.data
}

export const request_create_product_category = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/productcategory`, data)
    return response.data.data
}

/*------- SEGMENTS -------*/
export const request_create_segment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/segment`, data)
    return response.data.data
}

export const request_create_product_segment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/productsegment`, data)
    return response.data.data
}

/*------- SUBCATEGORIES -------*/
export const request_create_subcategory = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/subcategory`, data)
    return response.data.data
}

export const request_create_product_subcategory = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/productsubcategory`, data)
    return response.data.data
}

/*------- SUBEGMENTS -------*/
export const request_create_subsegment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/subsegment`, data)
    return response.data.data
}

export const request_create_product_subsegment = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/productsubsegment`, data)
    return response.data.data
}

/*------- TAGS -------*/
export const request_create_tag = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/tag`, data)
    return response.data.data
}

export const request_create_product_tag = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/producttag`, data)
    return response.data.data
}

/*------- ACTIVES -------*/
export const request_create_active = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/product/${id}/activesubstance`, data)
    return response.data.data
}


//deletes
/*------- CATEGORIES -------*/
export const request_delete_product_category = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/productcategory/${id}`)
    return response.data.data
}

/*------- SEGMENTS -------*/
export const request_delete_product_segment = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/productsegment/${id}`)
    return response.data.data
}

/*------- SUBCATEGORIES -------*/
export const request_delete_product_subcategory = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/productsubcategory/${id}`)
    return response.data.data
}

/*------- SUBSEGMENTS -------*/
export const request_delete_product_subsegment = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/productsubsegment/${id}`)
    return response.data.data
}

/*------- TAGS -------*/
export const request_delete_product_tag = async (id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/producttag/${id}`)
    return response.data.data
}

/*------- ACTIVE -------*/
export const request_delete_active = async (id, id_active) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/product/${id}/activesubstance/${id_active}`)
    return response.data.data
}


/******************** MEDIA ********************/
//posts
export const request_create_image = async (id, image_name, main) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/product/${id}/image`, {main:main ? 1 : 0, visible:1, name: image_name})
    return response.data.data
}

export const request_upload_image = async(id, id_image, file, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    let response = await mainServer.post(`/product/${id}/image/${id_image}/file`, data)
    console.log(response.data.data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    return response.data.data.product   
}

//deletes
export const request_delete_image = async (id, id_image) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/product/${id}/image/${id_image}`)
    return response.data.data
} 

export const request_delete_image_file = async (id, id_image, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    console.log(data)
    let response = await mainServer.delete(`/product/${id}/image/${id_image}/file${data}`)
    //return response.data.data
}