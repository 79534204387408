import { useEffect, useState } from "react";
import { onGetErrorMessage } from "../../../../shared/utility";
import { request_delete_permissionsetting } from "../requests";

const useDeletePermission = (props) => {
	const { id, open, onClose, onUpdateCompleted } = props;

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		setError('');
		setLoading(false);
		setDisabled(false);
	}, [open]);

	const actions = {
		onSubmit: async () => {
			setLoading(true);
			setDisabled(true);
			try {
				await request_delete_permissionsetting(id);
				onUpdateCompleted();
				onClose();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
			}
            setLoading(false);
			setDisabled(false);
		},
	};

	const system = { loading, disabled, error };

	return { system, actions };
};

export default useDeletePermission