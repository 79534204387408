import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"



const useDoctorView = ({match, content, onUpdateError, onUpdateSending, onUpdateSuccess}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
   
    const [data, setData] = useState(null)
   
    const [modals, setModals] = useState({
        change_image:false,
        change_password:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`/users/${id}`)
                setData(req.data.data.user)
                setLoading(false)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onUpdateCompleted: async() =>{
            onUpdateSending(true)
            try {          
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`/users/${id}`)
                setData(req.data.data.user)     
                onUpdateSuccess(content.success_msg)         
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
            }
            onUpdateSending(false)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        }, 
    }

    const system = {loading}
    const view_data = {data}

    return {system, modals, actions, view_data } 
}

export default useDoctorView

