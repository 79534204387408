import { Button, Checkbox, FormControlLabel, Grid, Icon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect} from 'react'
import InputForm from '../../../../../../components/Forms/InputForm'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import './style.css'
import RichTextForm from '../../../../../../components/Forms/RichTextForm'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'


const GeneralInfo = ({content, form, onChange, pharmaceutical_form, details, indications, contraindications, pregnancy_and_lactation, precautions_and_warnings,
                        for_adult, for_children, recipe_check, retains_check, ingredients, usage, setIngredients, setUsage,
                        setFarmaceuticalForm, setContraindications, setDetails, setIndications, setPregnancyAndLactation, setPrecautionsAndWarnings,
                        setRecipeCheck, setRetainsCheck, setForAdult, setForChildren, generic, medication, setGeneric, setMedication, actions, system, presentation }) => {


    useEffect(() => {window.scrollTo(0,0)}, [])

    //console.log(details)
    const classes = useStyles()
    

    return(
        <SimpleCard>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' style={{marginBottom:56}}>
                        <Grid item xs>
                            <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                        </Grid>
                        <Grid item >          
                            <ContainedButton color='primary' onClick={actions.onSubmit} loading={system.sending} startIcon={<Icon>save</Icon>}>{content.button}</ContainedButton>                 
                        </Grid>
                    </Grid>             
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} justifyContent='center'>
                        
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.sub_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.active_substance} onChange={onChange} />
                        </Grid>
                        <Grid item xs={0} md={6} />
                        <Grid item xs={12} md={3} >
                            <InputForm data={form.package} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <InputForm data={form.product_quantity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <InputForm data={form.measure} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={3} >
                            <InputForm data={form.additional_measure} onChange={onChange} />
                        </Grid>

                        
                        
                        {/*
                        
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.active_substance} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <InputForm data={form.package} onChange={onChange} />
                        </Grid>*/}
                        
                        

                        {/*<Grid item xs={12}>
                            <Typography variant='caption'>Presentación</Typography>
                            <Typography variant='body2'>{presentation ? presentation : 'Sin información'}</Typography>
</Grid>*/}

                        <Grid item xs={12}>
                            <InputForm data={form.presentation} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} style={{marginBottom:50}}>
                            <InputForm data={form.short_description} onChange={onChange} />
                        </Grid>
                        <Grid item style={{borderRight:'1px solid #E1E5F2', paddingRight:40, marginRight:40}}>
                            <Grid container spacing={3} direction='column'>
                                <Grid item  >
                                    <FormControlLabel
                                        control={<Checkbox style={{color:'#96AE76'}} checked={for_adult} onChange={()=>setForAdult(!for_adult)} />}
                                        label='Adulto'
                                    />
                                </Grid>
                                <Grid item  >                                  
                                    <FormControlLabel
                                        control={<Checkbox style={{color:'#96AE76'}} checked={for_children} onChange={()=>setForChildren(!for_children)} />}
                                        label='Niño'
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item >
                            <Grid container spacing={3} direction='column'>
                                <Grid item xs={6} >
                                    <FormControlLabel
                                        control={<Checkbox style={{color:'#96AE76'}} checked={recipe_check} onChange={()=>setRecipeCheck(!recipe_check)} />}
                                        label={'¿Requiere receta?'} className={classes.checkbox_label}
                                    />
                                </Grid>
                                <Grid item xs={6} >                                  
                                    <FormControlLabel
                                        control={<Checkbox style={{color:'#96AE76'}} checked={retains_check} onChange={()=>setRetainsCheck(!retains_check)} />}
                                        label={'¿Retiene receta?'} className={classes.checkbox_label}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>  
                        
                        {/* <Grid item xs={6} >
                            <FormControlLabel
                                control={<Checkbox checked={generic} onChange={()=>setGeneric(!generic)} />}
                                label={'¿Farma?'}
                            />
                        </Grid>
                        <Grid item xs={6} >                                  
                            <FormControlLabel
                                control={<Checkbox checked={medication} onChange={()=>setMedication(!medication)} />}
                                label={'¿Es medicamento?'}
                            />
                        </Grid> 
                        <Grid item xs={12} style={{marginTop:50}}>
                            <Grid container spacing={2} alignItems='center' >
                                <Grid item xs><InputForm data={form.url} onChange={onChange} /></Grid>
                                <Grid item ><Button href={`celu.co/${form.url.value}`} target='_blank' className={classes.button} fullWidth  >Visitar</Button></Grid>
                            </Grid>                      
                        </Grid>              
                        <Grid item xs={12} >
                            <InputForm data={form.search_metadata} onChange={onChange} />
                        </Grid> 
                        <Grid item xs={12} >
                            <InputForm data={form.search_string} onChange={onChange} />
                        </Grid>      */}                                
                        {/* <Grid item xs={12}>
                            <DisplayText variant='subtitle2'>{content.subtitles.billing}</DisplayText>
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.sat_unit_key} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6} >
                            <InputForm data={form.sat_product_key} onChange={onChange} />
                        </Grid> */}               
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default GeneralInfo

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.main,
        color:'white',
        textTransform:'none',
        padding:'14px 24px',
        borderRadius:8,
        width:'100%',
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    checkbox_label:{
        '& .MuiTypography-body1-272':{
            whiteSpace:'nowrap'
        }
    }
}))

