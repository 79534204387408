import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import DisplayText from '../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { private_server, public_server } from '../../../../../variables/config'
import SelectForm from '../../../../../components/Forms/SelectForm';
import catalogs from '../../../../../texts/esp/catalogs';


const EditModal = props => {

    const { open, onClose, onUpdateCompleted, content, origin } = props

    const classes = useStyles()

    //console.log(origin)

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
 
    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    const [categories, setCategories] = useState([])
    const [subcategories, setSubcategories] = useState([])
    const [segments, setSegments] = useState([])
    const [subsegments, setSubsegments] = useState([])

    const [opt, setOpt] = useState([])


    useEffect(async() => {
        if (open) {
            try {
                setLoading(true)
                updateAuthorizationHeader(public_server)
                const _categories = await public_server.get(`category/list`) 
                const _subcategories = await public_server.get(`subcategory/list`)
                const _segments = await public_server.get(`segment/list`)
                const _subsegments = await public_server.get(`subsegment/list`)  
                //console.log(_categories)
                setCategories(onGetCatalogs(_categories.data.data.categories, 'id_category', 'name'))
                setSubcategories(onGetCatalogs(_subcategories.data.data.sub_categories, 'id_sub_category', 'name'))
                setSegments(onGetCatalogs(_segments.data.data.segments, 'id_segment', 'name'))
                setSubsegments(onGetCatalogs(_subsegments.data.data.sub_segments, 'id_sub_segment', 'name'))
                setLoading(false)

                let _form = onInitEmptyForm(formData, content.form)
                _form = onInitForm(_form, origin)
                _form.menu_element_type_id.options = catalogs.menu_elements_types
                if(origin.menu_element_type_id === 1 ) _form.menu_element_id.options = onGetCatalogs(_categories.data.data.categories, 'id_category', 'name')
                if(origin.menu_element_type_id === 2 ) _form.menu_element_id.options = onGetCatalogs(_subcategories.data.data.sub_categories, 'id_sub_category', 'name')
                if(origin.menu_element_type_id === 3 ) _form.menu_element_id.options = onGetCatalogs(_segments.data.data.segments, 'id_segment', 'name')
                if(origin.menu_element_type_id === 4 ) _form.menu_element_id.options = onGetCatalogs(_subsegments.data.data.sub_segments, 'id_sub_segment', 'name')
                setForm(_form)
            } catch (error) {
                console.log(error)         
            }  
        }else{
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if(id === 'menu_element_type_id'){
            if(temp['menu_element_type_id'].value === 1) {
                temp['menu_element_id'].options = categories
            }
            if(temp['menu_element_type_id'].value === 2){
                temp['menu_element_id'].options = subcategories
            }
            if(temp['menu_element_type_id'].value === 3){
                temp['menu_element_id'].options = segments
            }
            if(temp['menu_element_type_id'].value === 4){
                temp['menu_element_id'].options = subsegments
            }
        }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        
        setLoading(true)
        setDisabled(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`menuelement/${origin.id_menu_element}`, data2send)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.edit.title}</DisplayText>
                {/* <DisplayText >{content.subtitle}</DisplayText> */}
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectForm data={form.menu_element_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.menu_element_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.edit.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default EditModal

const useStyles = makeStyles(theme=>({
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    
}))

const formData = {
    menu_element_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'menu_element_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    menu_element_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'menu_element_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}

