import SimpleTable from './components/SimpleTable';
import usePermissions from './usePermissions';
import AddPermissionModdal from './modals/AddPermissionModal'
import DeletePermissionModal from './modals/DeletePermissionModal';

const Permissions = (props) => {
	const { history, content } = props;
	const { system, modals, actions, view_data } = usePermissions({ content, history });

	return (
		<>
			<AddPermissionModdal
				content={content.permissions.modal}
				open={modals.add}
				onClose={() => actions.onChangeModalStatus('add', false)}
				history={history}
				onUpdateCompleted={actions.onInitModule}
				selected_role_id={view_data.roleFilter}
			/>
			<DeletePermissionModal open={modals.delete} onClose={() => actions.onChangeModalStatus('delete', false)} onUpdateCompleted={actions.onInitModule} id={view_data.selected} />
			<SimpleTable
				data={view_data.data}
				search={view_data.search}
				tableFilter={view_data.tableFilter}
				total={view_data.total}
				loading={system.loading}
				onAddRegister={() => actions.onChangeModalStatus('add', true)}
				onChangeSearch={actions.onChangeSearch}
				onUpdateTableFilter={actions.onChangeTableFilter}
				onDelete={actions.onDelete}
				content={content.permissions}
				onChangeRoleFilter={actions.onUpdateRoleFilter}
				roles={view_data.rolesCatalog}
				roleFilter={view_data.roleFilter}
				onChangeStatus={actions.onChangeStatus}
			/>
		</>
	);
};

export default Permissions;
