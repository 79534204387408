import { Hidden } from '@mui/material';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import PageMenuMobile from '../../components/Structure/Navigation/PageMenuMobile';
import ToolbarTable from '../../components/Structure/Navigation/ToolbarTable';
import Page from '../../components/Structure/Page/Page';
import { actionTypes } from '../../store/actions';
import { translations as translations_esp } from '../../texts/esp/medications-translations'
import Labs from './views/Labs/Labs';
import Medications from './views/Medications/Medications';
import Substances from './views/Substances/Substances';


const MedicationsView = (props) => {

    const {history, medications_search, medications_table, onUpdateSearch, onUpdateTable} = props

    const content = translations_esp

    const [view, setView] = useState(1)

    let contentView = null

    switch(view){
        case 1:
            contentView = <Medications content={content.views.medications} history={history} {...props} />
            break
        case 2:
            contentView = <Substances content={content.views.substances}/>
            break
        case 3:
            contentView = <Labs content={content.views.labs}/>
            break
        default:
            break
    }
  
    return (
        <Page title={content.title}  >
            <Hidden mdDown>
                <ToolbarTable selected={view} menuOptions={content.menu} onChange={(id)=>setView(id)} />
            </Hidden>
            <Hidden mdUp>
                <PageMenuMobile selected={view} options={content.menu} onChange={(id)=>setView(id)} />
            </Hidden>
            <div>
                {contentView}
            </div>
        </Page>
    );
}



const mapStateToProps = state => {
    return{
        medications_table: state.medications_table,
        medications_search: state.medications_search
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateTable: (medications_table) => dispatch({type: actionTypes.CHANGE_MEDICATIONS_TABLE, medications_table }),
        onUpdateSearch: (medications_search) => dispatch({type: actionTypes.CHANGE_MEDICATIONS_SEARCH, medications_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MedicationsView)