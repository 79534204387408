import { Grid } from '@mui/material'
import React from 'react'
import InputForm from '../../../../../components/Forms/InputForm'
import DisplayText from '../../../../../components/Texts/DisplayText'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import RichTextForm from '../../../../../components/Forms/RichTextForm'
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard'
import AutocompleteFormAdd from '../../../../../components/Forms/AutocompleteFormAdd'



const GeneralInfo = ({content, pharmaceutical_form, description, composition, therapeutic_indications, pharmaceutical_properties, contraindications, pregnancy_restrictions,
    adverse_reactions, medication_interactions, laboratory_findings, warnings, dose, overdose_management, storage_recommendations, protection_captions, form, sending, actions}) => {

    //useEffect(() => {window.scrollTo(0,0)}, [])

    return (
        <SimpleCard>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container alignItems='center' style={{marginBottom:16}}>
                        <Grid item xs>
                            <DisplayText variant='subtitle1'>{content.title}</DisplayText>
                        </Grid>
                        {/* <Grid item >          
                            <ContainedButton color='primary' onClick={onSubmit} loading={sending}>{content.button}</ContainedButton>                 
                        </Grid> */}
                    </Grid>               
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} justifyContent='flex-end'>
                        <Grid item xs={12} md={6}>
                            <InputForm data={form.name} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AutocompleteFormAdd data={form.laboratory_id} onChange={actions.onChangeForm} add_register={content.search_bar.add_register}
                            onAdd={()=>actions.onChangeModalStatus('add_lab', true)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.external_id} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.external_image} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.presentation} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.substance} onChange={actions.onChangeForm} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.pharmaceutical_form} value={pharmaceutical_form} 
                            onChange={(value)=>actions.onSetPharmaceuticalForm(value)} />
                        </Grid>                    
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.description} value={description} 
                            onChange={(value)=>actions.onSetDescription(value)} />
                        </Grid>                        
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.composition} value={composition} 
                            onChange={(value)=>actions.onSetComposition(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.therapeutic_indications} value={therapeutic_indications} 
                            onChange={(value)=>actions.onSetTherapeuticIndications(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.pharmaceutical_properties} value={pharmaceutical_properties} 
                            onChange={(value)=>actions.onSetPharmaceuticalProperties(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.contraindications} value={contraindications} 
                            onChange={(value)=>actions.onSetContraindications(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.pregnancy_restrictions} value={pregnancy_restrictions} 
                            onChange={(value)=>actions.onSetPregnancyRestrictions(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.adverse_reactions} value={adverse_reactions} 
                            onChange={(value)=>actions.onSetAdverseReactions(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.medication_interactions} value={medication_interactions} 
                            onChange={(value)=>actions.onSetMedicationInteractions(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.laboratory_findings} value={laboratory_findings} 
                            onChange={(value)=>actions.onSetLaboratoryFindings(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.warnings} value={warnings} 
                            onChange={(value)=>actions.onSetWarnings(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.dose} value={dose} 
                            onChange={(value)=>actions.onSetDose(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.overdose_management} value={overdose_management} 
                            onChange={(value)=>actions.onSetOverdoseManagement(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.storage_recommendations} value={storage_recommendations} 
                            onChange={(value)=>actions.onSetStorageRecommendations(value)} />
                        </Grid>
                        <Grid item xs={12} >
                            <RichTextForm label={content.rich_texts.protection_captions} value={protection_captions} 
                            onChange={(value)=>actions.onSetProtectionCaptions(value)} />
                        </Grid>             
                        <Grid item >          
                            <ContainedButton color='primary' onClick={actions.onSubmit} loading={sending}>{content.button}</ContainedButton>                 
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    );
}

export default GeneralInfo

