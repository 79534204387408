import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../shared/utility'
import DisplayText from '../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { private_server } from '../../../../../variables/config'


const AddModal = props => {

    const { open, onClose, onUpdateCompleted, content } = props

    const classes = useStyles()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
 
    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)


    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        
        setLoading(true)
        setDisabled(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.post(`substance`, data2send)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add.title}</DisplayText>
                {/* <DisplayText >{content.subtitle}</DisplayText> */}
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.add.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default AddModal

const useStyles = makeStyles(theme=>({
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    
}))

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
}

