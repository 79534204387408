import { Icon, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { nodata, ROUTE_STATUSES } from '../../../../variables/config';
import StatusButton from './StatusButton';


const Header = ({route, actions, status}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <div className={classes.container}>
                <Typography className={classes.title}>Ruta / </Typography>  
                <Typography className={classes.number}>{route ? route.id_route : nodata}</Typography>
                <div style={{flex:1}}></div>
                {/* <StatusButton status_id={route ? route.route_status_id : null} onChange={actions.onUpdateRouteStatus}/> */}
                <IconButton className={classes.button} disabled={status !== ROUTE_STATUSES.PENDING} onClick={()=>actions.onUpdateModalStatus('delete_route', true)} ><Icon>delete</Icon></IconButton>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        marginBottom:20
    },
    title:{
        color:'#B3B3B3',
        fontSize:24
    },
    number:{
        marginLeft:8,
        color:theme.palette.primary.dark,
        fontSize:24
    },
    container:{
        display:'flex',
        alignItems:'center'

    },
    button:{
        background:theme.palette.primary.dark,
        color:'white',
        marginLeft:16,
        '&:hover':{
            background:theme.palette.primary.dark,
        }
        
    }
}))
 
export default Header;