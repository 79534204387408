import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader, onGetCatalogs, onGetSelectedFormData, onInitForm } from '../../../../../../shared/utility'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import { private_server } from '../../../../../../variables/config'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import { modalStyles } from '../../../../../../styles/modalStyles'




const EditModal = props => {

    const { open, onClose, onUpdateCompleted, content, origin, catalogs } = props

    //console.log(origin)

    const classes = modalStyles()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
 
    //general
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(' ')
   

    useEffect(async() => {
        if (open) {
            setLoading(true)
            updateAuthorizationHeader(private_server)
            const req_warehouses = await private_server.get(`warehouse/list`) 
            const cat_warehouses = onGetCatalogs(req_warehouses.data.data.warehouses_data, 'id_warehouse', 'name')
            
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            _form = onInitForm(form, origin)
            _form.warehouse_id.options = cat_warehouses
            _form.shipping_type_id.options = catalogs.shipping_types
            setForm(_form)
            setLoading(false)
        }else{
            setError(''); setLoading(false);
            setForm(JSON.parse(JSON.stringify(formData)))
        }
    }, [open])

    const onChange = (data) => {
        //console.log(data)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)
        
        setSending(true) 
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`warehouse/${origin.id_warehouse_coverage}/coverage`, data2send)
            onUpdateCompleted()
            setSending(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setSending(false)
        }

    }


    
    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} loading_height={300} >
            <div >
                <DisplayText variant='h6' >{content.edit.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <SelectForm data={form.warehouse_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.shipping_type_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.button}>
                    <ContainedButton color='primary' onClick={onSubmit} loading={sending} disabled={sending}>
                        {content.edit.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default EditModal


const formData = {
    warehouse_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'warehouse_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    shipping_type_id: {
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'shipping_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
}

