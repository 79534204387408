import React, { useState, useEffect } from 'react';
//import { DASHBOARD_SETTINGS, private_server, public_server } from '../config';
import { updateAuthorizationHeader, onGetErrorMessage } from '../shared/utility';
import { private_server, public_server, DASHBOARD_SETTINGS } from '../variables/config';


const useInventories = () => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)
    const [products, setProducts] = useState([])
    
    

    const actions = {
        onGetAll: async(warehouseid, params) => {
            let total = 0
            setLoading(true)
            try {
                updateAuthorizationHeader(private_server)
                //const filter_ware = JSON.stringify({"AND":[{"field":"pickup","operator":"=","value":1}]})
                let url = `/inventory/warehouse/${warehouseid}`
                if(params) url += `${params}`
                const products_request = await private_server.get(url)
                total = products_request.data.data.count
                console.log(products_request.data.data.inventories_data)
                //const _products = products_request.data.data.products
                setProducts(products_request.data.data.inventories_data)
                
            } catch (error) {
                console.log(error)
                const _e = onGetErrorMessage(error)
                setError(_e.message)
                
            }
            setLoading(false)
            return total
        },
        updateInventory: async(_id, data2send) => {
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`inventory/${_id}`, data2send)
                
            } catch (error) {
                console.log(error)
                const _e = onGetErrorMessage(error)
                setError(_e.message)
                
            }
        }
    }

    const data = {products}
    const system = {loading, sending, error}

    return {
        system, data, actions
    }
}
 
export default useInventories;