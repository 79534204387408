import React, { useState } from 'react';
import PictureModal from '../../../../../../components/Overlays/PictureModal';
import { onGetErrorMessage, onGetFileExtension, onGetImageURL2Blob } from '../../../../../../shared/utility';
import { request_upload_subcategory_image } from '../requests';
import { translations as translations_esp } from '../../../../../../texts/esp/modals-translations'

const ChangeImageModal = (props) => {

    const {id, open, onClose, type, onUpdateCompleted} = props
    const content = translations_esp.change_image_modal
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onSubmit = async(file) => {
        if(!file){
            setError(content.error_msg)
            return
        }
        setError(null)
        setDisabled(true)
        try {
            setLoading(true)
            const _file = await onGetImageURL2Blob(file)
            //console.log(_file)
            let data
            if(type === 'web'){data = {document:"image_web", type:onGetFileExtension(_file.name)}}
            else{data = {document:"image_mobile", type:onGetFileExtension(_file.name)}}
            await request_upload_subcategory_image(id, _file, data)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setDisabled(false)
        setLoading(false)
    }

    const onDelete = async() => {
        /* try {
            setError(null)
            setLoading(true)
            await request_delete_image(id)
            onUpdateCompleted()
        } catch (error) {
            console.log(error.response)
            setError(onGetErrorMessage(error))
        }
        setLoading(false) */
    }

    return ( 
        <PictureModal open={open} onClose={onClose} loading={loading} error={error} aspect_ratio={1} disabled={disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSubmit} onDeleteFile={onDelete}/>
     );
}
 
export default ChangeImageModal;