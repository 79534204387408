import React from 'react';
import {
    TextField,
    InputBase,
    InputLabel,
    Typography,
    FormHelperText,
    MenuItem,
    Select,
    Icon,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { ruleValidation } from './customFunctions';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';

const CssTextField = withStyles((theme) => ({
	root: {
		maxHeight: 300,
		'&.Mui-error': {
			border: '1px solid red',
		},
	},
	input: {
		fontWeight: 500,
		padding: '12px 15px',
		border: '1px solid #919191',
		fontSize: 18,
		borderRadius: 5,
	},
}))(InputBase);

const CustomIcon = withStyles((theme) => ({
	root: {
		paddingRight: 20,
		color: theme.palette.primary.main,
		fontSize: 20,
	},
}))(ExpandMore);

const useStyles = makeStyles((theme) => ({
	inputlabel: {
		color: '#646464',
		marginBottom: 5,
		fontSize: 14,
		fontWeight: 500,
	},
	paper: {
		maxHeight: 300,
	},
}));

const SelectFormLabeled = (props) => {
	const { data, onChange } = props;
	const classes = useStyles();

	const onInnerChange = (event) => {
		const response = event.target.value;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited, isRequired, options, overrideStyle } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	const _options = options ? options : [];

	if (!isError) helperText = '';

	return (
        <div>
			{interConfig.label && (
				<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
					{interConfig.label}
				</InputLabel>
			)}
			<Select
                variant="standard"
                MenuProps={{
					classes: { paper: classes.paper },
					variant: 'selectedMenu',
				}}
                IconComponent={CustomIcon}
                value={value}
                onChange={onInnerChange}
                {...interConfig}
                input={<CssTextField error={isError} />}>
				{_options.map((item, i) => {
					return (
						<MenuItem key={i} value={item.value} className={classes.menu_item}>
							{item.label}
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
    );
};

export default SelectFormLabeled;
