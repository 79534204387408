import { useEffect, useState } from "react"
import { private_server, public_server } from "../../../../../variables/config"
import { formData, priceFormData, searchFormData } from './sections/data'
import { catalogs } from "../../../../../texts/esp/catalogs"
import moment from "moment"
import { isFormValid, onCleanData, onGetCatalogs, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onGetSelectedFormDataNumber, onInitEmptyForm, onInitForm, onSanitizeSlug, onSetErrorsToForm, string2Slug, updateAuthorizationHeader } from "../../../../../shared/utility"




const useGeneral = ({history, match, content}) => {

    const id = match.params.id
    const ID = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [price_form, setPriceForm] = useState(JSON.parse(JSON.stringify(priceFormData)))
    const [search_form, setSearchForm] = useState(JSON.parse(JSON.stringify(searchFormData)))
    const [presentation, setPresentation] = useState('')
    const [productName, setProductName] = useState('')
    const [brands, setBrands] = useState([])
    const [images, setImages] = useState([])
    const [prices, setPrices] = useState([])
    const [product, setProduct] = useState(null)
    const [product_categories, setProductCategories] = useState([])
    const [product_segments, setProductSegments] = useState([])
    const [product_subcategories, setProductSubcategories] = useState([])
    const [product_subsegments, setProductSubsegments] = useState([])
    const [product_tags, setProductTags] = useState([])
    const [actives, setActives] = useState([]);
    const [taxes, setTaxes] = useState([])
  
    //active
    const [id_active, setIdActive] = useState(null);
    const [active, setActive] = useState(null);

    //taxes
    const [tax, setTax] = useState(null)

    //images
    const [selected_image, setSelectedImage] = useState(null)

    //price
    const [currency, setCurrency] = useState(1)

    //rich texts
    const [pharmaceutical_form, setFarmaceuticalForm] = useState('')
    const [details, setDetails] = useState('')
    const [indications, setIndications] = useState('')
    const [contraindications, setContraindications] = useState('')
    const [pregnancy_and_lactation, setPregnancyAndLactation] = useState('')
    const [precautions_and_warnings, setPrecautionsAndWarnings] = useState('')
    const [ingredients, setIngredients] = useState('')
    const [usage, setUsage] = useState('')
    const [dosis, setDosis] = useState('')
    const [pharmaceutical_properties, setPharmaceuticalProperties] = useState('')
    const [composition, setComposition] = useState('')
    const [adverse_reactions, setAdverseReactions] = useState('')
    const [drug_interactions, setDrugInteractions] = useState('')
    const [overdosage, setOverdosage] = useState('')
    const [protection_labels, setProtectionLabels] = useState('')

    //boolean data
    const [for_adult, setForAdult] = useState(false)
    const [for_children, setForChildren] = useState(false)
    const [recipe_check, setRecipeCheck] = useState(false)
    const [retains_check, setRetainsCheck] = useState(false)
    const [generic, setGeneric] = useState(false)
    const [medication, setMedication] = useState(false)

    //catalogs
    const [tags_catalogs, setTagsCatalogs] = useState([])
    const [categories_catalogs, setCategoriesCatalogs] = useState([])
    const [subcategories_catalogs, setSubcategoriesCatalogs] = useState([])
    const [segments_catalogs, setSegmentsCatalogs] = useState([])
    const [subsegments_catalogs, setSubsegmentsCatalogs] = useState([])
    const [brands_catalogs, setBrandsCatalogs] = useState([])
    const [subtypes, setSubtypes] = useState([])
  
    //
    const [taxes_price, setTaxesPrice] = useState(0)
    const [final_price, setFinalPrice] = useState(0)


    const [modals, setModals] = useState({
        add_active:false,
        delete_active:false,
        edit_active:false,
        add_image:false,
        edit_image:false,
        delete_image:false,
        add_tag:false,
        add_category:false,
        add_subcategory:false,
        add_segment:false,
        add_subsegment:false,
        add_tax:false,
        edit_tax:false,
        delete_tax:false,
    })

    useEffect(async() => {
        await actions.onInitModule()
        await actions.onInitCatalogs()
    }, [])

    useEffect(() => {
        actions.onUpdateProductCompleted()
        //actions.onInitCatalogs()
    }, [currency])

    useEffect(() => {
        const _substance = form.active_substance.value ?? ''
        const product_quantity = form.product_quantity.value ?? ''
        const measure = form.measure.value ?? ''

        const name = form.name.value ?? ''
        const subname = form.sub_name.value ?? ''

        let _presentation = _substance
        const _measure = product_quantity > 1 ? `${measure}s` : measure

        if(product_quantity) _presentation = `${_presentation}, ${product_quantity}`
        if(measure) _presentation = `${_presentation} ${_measure.toLowerCase()}`

        let productname = name ?? ''
        if(subname) productname = `${productname} ${subname}`


        //console.log(_additional_measure, product_quantity, measure)

        //temp.presentation.value = `${_additional_measure} con ${product_quantity} ${measure}`
        //temp.presentation.isValid = Boolean(temp.presentation.value)
        setPresentation(form.presentation.value)
        setProductName(productname)
        /*if(id === 'active_substance' || id === 'product_quantity' || id === 'measure'){
            console.log(id, data)
            const activesubtance = id === 'active_substance' ? data.value : (form.active_substance.value ?? '')
            const product_quantity = id === 'product_quantity' ? data.value : (form.product_quantity.value ?? '')
            const measure = id === 'active_sumeasurebstance' ? data.value : (form.measure.value ?? '')

            let _presentation = activesubtance
            if(product_quantity) _presentation = `${_presentation} con ${product_quantity}`
            if(measure) _presentation = `${_presentation} ${measure}`
            //console.log(activesubtance, product_quantity, measure)

            //temp.presentation.value = `${activesubtance} con ${product_quantity} ${measure}`
            //temp.presentation.isValid = Boolean(temp.presentation.value)
            setPresentation(`${activesubtance} con ${product_quantity} ${measure}`)
        }*/
    }, [form])

    

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                //requests
                //const _price = await request_product_price(id)
                updateAuthorizationHeader(private_server)
                const _price = await private_server.get(`/product/${id}/prices`)
                console.log('Debug simple price', _price.data.data)
                setPrices(_price.data.data.product_prices) 

                const _taxes = await public_server.get(`/product/${id}/taxes`)
                //console.log(_taxes.data.data.product_taxes)
                setTaxes(_taxes.data.data.product_taxes)
    
                //const _product = await request_product(id)
                const _product = await private_server.get(`/product/${id}`)
                console.log('Debug: prod', _product)
                setProduct(_product.data.data.product)

                const _prod_types = await private_server.get(`/product/producttypes`)
                console.log('Proudcto types', _prod_types.data.data.product_types)
                const _prod_subtypes = await private_server.get(`/product/productsubtypes`)
                console.log('Proudcto subtypes', _prod_subtypes.data.data.discount_types)
                setSubtypes(_prod_subtypes.data.data.discount_types)
              
                //init forms
                const _prod =_product.data.data.product
                //console.log(_prod)
                const _brandCat = [{value:_prod.brand_id, label:_prod.brand}]
                if(_prod) setBrandsCatalogs(_brandCat)
                //general
                let _form = onInitEmptyForm(form, content.general_info.form_info.form)    
                _form = onInitForm(_form, _prod)
                _form.product_discount_type_id.options = catalogs.discount_types
                _form.sale_expiration_date.value = moment().format('YYYY-MM-DD')
                _form.sale.value = _prod.sale
                _form.taxable.value = _prod.taxable
                _form.multiple_sale.value = _prod.multiple_sale
                _form.product_type_id.options = onGetCatalogs(_prod_types.data.data.product_types, 'id_product_type', 'name')
                _form.product_subtype_id.options = onGetCatalogs(_prod_subtypes.data.data.discount_types, 'id_product_subtype', 'name')
                _form.brand_id.options = [..._brandCat]

                //const activesubtance = form.active_substance.value ?? ''
                //const product_quantity = form.product_quantity.value ?? ''
                //const measure = form.measure.value ?? ''
//
                //let _presentation = activesubtance
                //if(product_quantity) _presentation = `${_presentation} con ${product_quantity}`
                //if(measure) _presentation = `${_presentation} ${measure}`
                //_form.

                setForm(_form)
                /////// Rich text data
                //setDescription(_prod.description ? _prod.description : '')
                setFarmaceuticalForm(_prod.pharmaceutical_form ? _prod.pharmaceutical_form : '')
                setDetails(_prod.details ? _prod.details : '')
                setIndications(_prod.indications ? _prod.indications : '')
                setContraindications(_prod.contraindications ? _prod.contraindications : '')
                setPregnancyAndLactation(_prod.pregnancy_and_lactation ? _prod.pregnancy_and_lactation : '')
                setPrecautionsAndWarnings(_prod.precautions_and_warnings ? _prod.precautions_and_warnings : '')
                setIngredients(_prod.ingredients ? _prod.ingredients : '')
                setUsage(_prod.usage ? _prod.usage : '')
                setDosis(_prod.dosis ? _prod.dosis : '')
                setPharmaceuticalProperties(_prod.pharmaceutical_properties ? _prod.pharmaceutical_properties : '')
                setComposition(_prod.composition ? _prod.composition : '')
                setAdverseReactions(_prod.adverse_reactions ? _prod.adverse_reactions : '')
                setDrugInteractions(_prod.drug_interactions ? _prod.drug_interactions : '')
                setOverdosage(_prod.overdosage ? _prod.overdosage : '')
                setProtectionLabels(_prod.protection_labels ? _prod.protection_labels : '')

                ///////
                setRecipeCheck(Boolean(_prod.requires_recipe))
                setRetainsCheck(Boolean(_prod.retains_recipe))
                setForChildren(Boolean(_prod.for_children))
                setForAdult(Boolean(_prod.for_adult))
                setGeneric(Boolean(_prod.generic))
                setMedication(Boolean(_prod.medication))
    
                //price
                let _price_form = onInitEmptyForm(price_form, content.general_info.form_info.price_form)  
                console.log('Debug pricing', _price.data.data, currency)
                _price_form = onInitForm(_price_form, _price.data.data.product_prices[currency])
                //console.log(product)
                if(Boolean(_prod && _prod.taxable)){
                    console.log(_price_form.price.value)
                    let final_taxes = 0
                    if(_taxes.data.data.product_taxes.length !== 0){
                        _taxes.data.data.product_taxes.forEach(el => {
                            console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        })
                    }

                    //console.log(final_taxes)
                    //cambie 1.16 por 1+final_taxes
                    //if(parseFloat(_price_form.price.value) !== 0) _price_form.price.value = (parseFloat(_price_form.price.value) * (1+final_taxes)).toFixed(2)
                    setTaxesPrice((parseFloat(_price_form.price.value) * (1+final_taxes)).toFixed(3))
                    if(parseFloat(_price_form.sale_price.value) !== 0) _price_form.sale_price.value = parseFloat((_price_form.sale_price.value*(1+final_taxes))).toFixed(2)
                    //if(parseFloat(_price_form.sale_discount.value) !== 0) _price_form.sale_discount.value = parseFloat((_price_form.sale_discount.value*(1+final_taxes))).toFixed(2)
                    if(parseFloat(_price_form.sale_bundle_price.value) !== 0) _price_form.sale_bundle_price.value = (parseFloat(_price_form.sale_bundle_price.value) * (1+final_taxes)).toFixed(2)     
                    
                    if(_prod.sale === 1 && _prod.product_discount_type_id === 1){
                        setFinalPrice(parseFloat((_price_form.price.value - _price_form.sale_discount.value)*(1+final_taxes)).toFixed(3))
                    }else{
                        setFinalPrice((parseFloat(_price_form.price.value)*(1+final_taxes)).toFixed(3))
                    }
                }else{
                    setTaxesPrice(parseFloat(_price_form.price.value))
                    if(_prod.sale === 1 && _prod.product_discount_type_id === 1){
                        setFinalPrice((parseFloat(_price_form.price.value)-_price_form.sale_discount.value).toFixed(3))
                    }else{
                        setFinalPrice((parseFloat(_price_form.price.value)))
                    }
                }
                setPriceForm(_price_form)
  
                const _brands = await public_server.get(`/brand/list`)
                setBrands(_brands.data.data.brands_data)

                const _images = await private_server.get(`/product/${id}/images`)
                //console.log(_images)
                setImages(_images.data.data.product_images)  

                const _product_categories = await private_server.get(`/productcategory/product/${id}`)
                setProductCategories(_product_categories.data.data.product_category_data)

                const _product_segments = await private_server.get(`/productsegment/product/${id}`)
                setProductSegments(_product_segments.data.data.product_segment_data)

                const _product_subcategories = await private_server.get(`/productsubcategory/product/${id}`)
                setProductSubcategories(_product_subcategories.data.data.product_category_data)
                console.log(_product_subcategories.data.data.product_category_data)

                const _product_subsegments = await private_server.get(`/productsubsegment/product/${id}`)
                setProductSubsegments(_product_subsegments.data.data.product_sub_segment_data)

                const _product_tags = await private_server.get(`/producttag/product/${id}`)
                setProductTags(_product_tags.data.data.product_tag_data)

                const _actives = await private_server.get(`/product/${id}/activesubstances`)
                setActives(_actives.data.data.product_active_substances)

                setLoading(false)
            } catch (error) {
                setLoading(false)
                setError(onGetErrorMessage(error))
                console.log(error)
            }
        },
        onUpdateLink: async() => {
            //console.log('--------UPDATE LINK------------')
            //console.log(form)

            const cleandata = onGetFormData(form)

            let data = ''
            if(cleandata.brand_id){
                const brandObj = brands.find(el => el.id_brand === cleandata.brand_id)
                if(brandObj) data = `${data}${brandObj.name.trim()}`
            }
            if(cleandata.name) data = `${data} ${cleandata.name.trim()}`
            if(cleandata.sub_name) data = `${data} ${cleandata.sub_name.trim()}`
            if(cleandata.presentation) data = `${data} ${cleandata.presentation.trim()}`
            data = data.trim()
            console.log(data)
            console.log(string2Slug(data))

            const _slug = string2Slug(data)
            if(_slug) {
                let _form = {...form}
                _form.url.value = _slug
                _form.url.isValid = true
                _form.url.isVisited = true
                setForm(_form)
            }

        },
        onInitCatalogs: async() =>{
            try{
                updateAuthorizationHeader(public_server)

                const _brands = await public_server.get(`/brand/list?limit=50`)
                setBrandsCatalogs(onGetCatalogs(_brands.data.data.brands_data, 'id_brand', 'name'))

                //const _tags = await public_server.get(`/tag/list?limit=50`)
                //setTagsCatalogs(onGetCatalogs(_tags.data.data.tags_data, 'id_tag', 'name'))
                actions.onInitTagsCatalogs()

                //let _categories = await public_server.get(`/category/list?limit=50`) 
                //setCategoriesCatalogs(onGetCatalogs(_categories.data.data.categories, 'id_category', 'name'))
                actions.onInitCategoriesCatalogs()
                
                //let _subcategories = await public_server.get(`/subcategory/list?limit=50`) 
                //setSubcategoriesCatalogs(onGetCatalogs(_subcategories.data.data.sub_categories, 'id_sub_category', 'name')) 
                actions.onInitSubcategoriesCatalogs()
                
                //let _segments = await public_server.get(`/segment/list?limit=50`)
                //setSegmentsCatalogs(onGetCatalogs(_segments.data.data.segments, 'id_segment', 'name'))
                actions.onInitSegmentsCatalogs()

                //let _subsegments = await public_server.get(`/subsegment/list?limit=50`)
                //setSubsegmentsCatalogs(onGetCatalogs(_subsegments.data.data.sub_segments, 'id_sub_segment', 'name'))
                actions.onInitSubsegmentsCatalogs()

            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            }  
        },
        onInitTagsCatalogs: async() => {
            updateAuthorizationHeader(public_server)
            let _tags = await public_server.get(`/tag/list`)
            const tags = [..._tags.data.data.tags_data]    
            const req_product_tags = await private_server.get(`/producttag/product/${id}`)
            const _product_tags = req_product_tags.data.data.product_tag_data  
            const opt = tags.map((item)=>{
                let ex= true
                for (let index = 0; index < _product_tags.length; index++) {
                    if (item.id_tag === _product_tags[index].tag_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const tagsCatalogs = onGetCatalogs(final, 'id_tag', 'name')
            setTagsCatalogs(tagsCatalogs)
        },
        onInitCategoriesCatalogs: async() => {
            console.log('holiiiiiiiii')
            updateAuthorizationHeader(public_server)
            let _categories = await public_server.get(`/category/list?limit=50`)
            const req_product_categories = await private_server.get(`/productcategory/product/${id}`)
            const categories = [..._categories.data.data.categories]   
            const _product_categories = req_product_categories.data.data.product_categories

            const opt = categories.map((item)=>{
                let ex= true
                for (let index = 0; index < _product_categories.length; index++) {
                    if (item.id_category === _product_categories[index].category_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const catalogs = onGetCatalogs(final, 'id_category', 'name')
            setCategoriesCatalogs(catalogs)
            //console.log(catalogs)
        },
        onInitSubcategoriesCatalogs: async() => {
            updateAuthorizationHeader(public_server)
            const req_product_categories = await private_server.get(`/productcategory/product/${id}`)
            const _product_categories = req_product_categories.data.data.product_categories
            const categories = _product_categories.map((item)=>{
                return {"field":"category_id","operator":"=","value":`${item.category_id}`}
            })
            // console.log('hola')
            //console.log(categories)
            let filter = {"OR":categories}
            filter = encodeURIComponent(JSON.stringify(filter))
            let params = `?&filter=${filter}`
            if(categories.length === 0) params = ''
            //let _subcategories = await request_subcategories(params)
            updateAuthorizationHeader(public_server)
            let _subcategories = await public_server.get(`/subcategory/list${params}`)
            //console.log(_subcategories)
            const subcategories = [..._subcategories.data.data.sub_categories]   
            //console.log(categories)   
            const req_product_subcategories = await private_server.get(`/productsubcategory/product/${id}`)
            const _product_subcategories = req_product_subcategories.data.data.product_sub_categories
            const opt = subcategories.map((item)=>{
                let ex= true
                for (let index = 0; index < _product_subcategories.length; index++) {
                    if (item.id_sub_category === _product_subcategories[index].sub_category_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const catalogs = onGetCatalogs(final, 'id_sub_category', 'name')
            //console.log(catalogs)
            setSubcategoriesCatalogs(catalogs)
        },
        onInitSegmentsCatalogs: async() => {
            updateAuthorizationHeader(public_server)
            //console.log(product_subcategories)
            const req_product_subcategories = await private_server.get(`/productsubcategory/product/${id}`)
            const _product_subcategories = req_product_subcategories.data.data.product_sub_categories
            const subcategories = _product_subcategories.map((item)=>{
                return {"field":"sub_category_id","operator":"=","value":`${item.sub_category_id}`}
            })
            // console.log('hola')
            //console.log(subcategories)
            let params = ''
            if(subcategories?.length){
                let filter = {"OR":subcategories}
                filter = encodeURIComponent(JSON.stringify(filter))
                const params = `?&filter=${filter}`
            }
            
            //let _segments = await request_segments(params)
            updateAuthorizationHeader(public_server)
            let _segments = await public_server.get(`/segment/list${params}`)
            const segments = [..._segments.data.data.segments] 
            
            //console.log(segments)
            const req_product_segments = await private_server.get(`/productsegment/product/${id}`)
            console.log('Debug segments:', req_product_segments.data.data)
            const _product_segments = req_product_segments.data.data.product_segments
            //console.log(categories)    
            const opt = segments.map((item)=>{
                let ex= true
                for (let index = 0; index < _product_segments.length; index++) {
                    if (item.id_segment === _product_segments[index].segment_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const catalogs = onGetCatalogs(final, 'id_segment', 'name')
            //console.log(catalogs)
            setSegmentsCatalogs(catalogs)
        },
        onInitSubsegmentsCatalogs: async() => {
            updateAuthorizationHeader(public_server)
            const req_product_segments = await private_server.get(`/productsegment/product/${id}`)
            const _product_segments = req_product_segments.data.data.product_segments
            const segments = _product_segments.map((item)=>{
                return {"field":"segment_id","operator":"=","value":`${item.segment_id}`}
            })
            // console.log('hola')
            //console.log(segments)
            let params = ''
            if(segments.length){
                let filter = {"OR":segments}
                filter = encodeURIComponent(JSON.stringify(filter))
                params = `?&filter=${filter}`
            }
            
            //let _subsegments = await request_subsegments(params)
            let _subsegments = await public_server.get(`/subsegment/list${params}`)
            const subsegments = [..._subsegments.data.data.sub_segments]   
            //console.log(categories)  
            const req_product_subsegments = await private_server.get(`/productsubsegment/product/${id}`)
            console.log('DEGUG:', req_product_subsegments.data.data)
            const _product_subsegments = req_product_subsegments.data.data.product_sub_segments 
            const opt = subsegments.map((item)=>{
                let ex= true
                for (let index = 0; index < _product_subsegments.length; index++) {
                    if (item.id_sub_segment === _product_subsegments[index].sub_segment_id) ex = false
                }
                if(ex){
                    return item
                }else{
                    return null
                }
            })
            let final = []
            opt.forEach(el => {
                if(el !== null) final.push(el)
            })
            const catalogs = onGetCatalogs(final, 'id_sub_segment', 'name')
            setSubsegmentsCatalogs(catalogs)
        },
        onUpdateBrandsCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _brands = await public_server.get(`/brand/list`)
                setBrands(_brands.data.data.brands_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            }     
        },
        onUpdateActivesCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _actives = await private_server.get(`/product/${id}/activesubstances`)
                setActives(_actives.data.data.product_active_substances)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },  
        onUpdateCategoriesCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _product_categories = await private_server.get(`/productcategory/product/${id}`)
                setProductCategories(_product_categories.data.data.product_category_data)
                //console.log(_product_categories.data.data.product_category_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },
        onUpdateImagesCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _images = await private_server.get(`/product/${id}/images`)
                setImages(_images.data.data.product_images)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },
        onUpdateProductCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _price = await private_server.get(`/product/${id}/prices`)
                setPrices(_price.data.data.product_prices) 
                const _product = await private_server.get(`/product/${id}`)
                setProduct(_product.data.data.product)
                const _taxes = await public_server.get(`/product/${id}/taxes`)
                setTaxes(_taxes.data.data.product_taxes)

                let _price_form = onInitForm(price_form, _price.data.data.product_prices[currency])
                //console.log(product)
                console.log(_product.data.data.product.taxable)
                if(Boolean(_product.data.data && _product.data.data.product.taxable)){

                    let final_taxes = 0
                    if(_taxes.data.data.product_taxes.length !== 0){
                        _taxes.data.data.product_taxes.forEach(el => {
                            console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        })
                    }
                    //console.log(_price_form.price.value)
                    //if(parseFloat(_price_form.price.value) !== 0) _price_form.price.value = (parseFloat(_price_form.price.value) * (1+final_taxes)).toFixed(2)
                    setTaxesPrice((parseFloat(_price_form.price.value) * (1+final_taxes)).toFixed(3))
                    //if(parseFloat(_price_form.sale_price.value) !== 0) _price_form.sale_price.value = parseFloat(_price_form.price.value) - parseFloat(_price_form.sale_discount.value)
                    if(parseFloat(_price_form.sale_price.value) !== 0) _price_form.sale_price.value = parseFloat((_price_form.sale_price.value*(1+final_taxes))).toFixed(2)
                    if(parseFloat(_price_form.sale_bundle_price.value) !== 0) _price_form.sale_bundle_price.value = (parseFloat(_price_form.sale_bundle_price.value) * (1+final_taxes)).toFixed(2)    
                    if(_product.data.data.product.sale === 1 && _product.data.data.product.product_discount_type_id === 1){
                        setFinalPrice(((parseFloat(_price_form.price.value)-_price_form.sale_discount.value)*(1+final_taxes)).toFixed(3))
                    }else{
                        setFinalPrice((parseFloat(_price_form.price.value)*(1+final_taxes)).toFixed(3))
                    }         
                }else{
                    setTaxesPrice(parseFloat(_price_form.price.value))
                    if(_product.data.data.product.sale === 1 && _product.data.data.product.product_discount_type_id === 1){
                        setFinalPrice(((parseFloat(_price_form.price.value)-_price_form.sale_discount.value)).toFixed(3))
                    }else{
                        setFinalPrice((parseFloat(_price_form.price.value)).toFixed(3))
                    }
                }
                setPriceForm(_price_form)
            }catch{
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        }, 
        onUpdateSegmentsCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _product_segments = await private_server.get(`/productsegment/product/${id}`)
                setProductSegments(_product_segments.data.data.product_segment_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },   
        onUpdateSubcategoriesCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _product_subcategories = await private_server.get(`/productsubcategory/product/${id}`)
                setProductSubcategories(_product_subcategories.data.data.product_category_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },
        onUpdateSubsegmentsCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _product_subsegments = await private_server.get(`/productsubsegment/product/${id}`)
                setProductSubsegments(_product_subsegments.data.data.product_sub_segment_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },
        onUpdateTagCompleted : async() => {
            try{
                updateAuthorizationHeader(private_server)
                const _product_tags = await private_server.get(`/producttag/product/${id}`)
                setProductTags(_product_tags.data.data.product_tag_data)
            }catch(error){
                setError(onGetErrorMessage(error))
                console.log(error)
            } 
        },
        onDeleteActive : (value) => {
            setIdActive(value)
            actions.onChangeModalStatus('delete_active', true)
    
        },
        onEditActive : (item) => {
            setIdActive(item.id_product_active_substance)
            setActive(item)
            actions.onChangeModalStatus('edit_active', true)
    
        },
        onAddTax:async() =>{

            let price_data2send = onGetSelectedFormDataNumber(price_form, prices[currency])
            delete price_data2send.currency_id

            let price_id = prices[currency].id_product_price.toString()

            

            if(form.sale.value === 1 ){
                if(form.product_discount_type_id.value === 1){
                   
                    price_data2send.sale_discount = parseFloat(price_form.sale_discount.value)
                    //price_data2send.sale_price = parseFloat(price_form.sale_price.value)
                    
                    if(form.taxable.value === 1 && taxes.length !== 0){
                        let final_taxes = 0
                        taxes.forEach(el => {
                            //console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        });
                        price_data2send.sale_price = parseFloat((price_form.sale_price.value/(1+final_taxes)).toFixed(3))
                        console.log('con impuestos')
                    }else{
                        console.log('sin impuestos')
                        price_data2send.sale_price = parseFloat(price_form.sale_price.value)
                    }
                }
                if(form.product_discount_type_id.value === 2){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                    delete price_data2send.sale_bundle_price
                }
                if(form.product_discount_type_id.value === 3){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                }
            }else{
                delete price_data2send.sale_price
            }

            console.log(price_data2send)

            try {
                updateAuthorizationHeader(private_server)
                if(prices.length === 0){
                    price_data2send.currency_id = currency === 0 ? 1 : 2
                    await private_server.patch(`product/${id}/price`, price_data2send)
                }else{
                    await private_server.patch(`/product/${id}/price/${price_id}`, price_data2send)
                }
                
                //actions.onUpdateProductCompleted()
                actions.onChangeModalStatus('add_tax', true)
                //setSuccess(content.success_msg)
                //setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                //setSending(false)
            }

            //actions.onChangeModalStatus('add_tax', true)
        },
        onEditTax: async(item) => {
            let price_data2send = onGetSelectedFormDataNumber(price_form, prices[currency])
            delete price_data2send.currency_id

            let price_id = prices[currency].id_product_price.toString()

            /* if(form.taxable.value === 1 ){

                let final_taxes = 0
                if(taxes.length !== 0){
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });

                    price_data2send.price = parseFloat((parseFloat(price_form.price.value)/(1+final_taxes)).toFixed(3))
                    price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3))
                    price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value) / (1+final_taxes)).toFixed(3)) 
                }            
            }else{
                price_data2send.price = parseFloat((parseFloat(price_form.price.value)).toFixed(3))
                price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3)) 
                price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value)).toFixed(3))
            } */

            if(form.sale.value === 1 ){
                if(form.product_discount_type_id.value === 1){
                   
                    price_data2send.sale_discount = parseFloat(price_form.sale_discount.value)
                    
                    if(form.taxable.value === 1 && taxes.length !== 0){
                        let final_taxes = 0
                        taxes.forEach(el => {
                            //console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        });
                        price_data2send.sale_price = parseFloat((price_form.sale_price.value/(1+final_taxes)).toFixed(3))
                    }else{
                        price_data2send.sale_price = parseFloat(price_form.sale_price.value)
                    }
                }
                if(form.product_discount_type_id.value === 2){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                    delete price_data2send.sale_bundle_price
                }
                if(form.product_discount_type_id.value === 3){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                }
            }else{
                delete price_data2send.sale_price
            }

            try {
                updateAuthorizationHeader(private_server)
                if(prices.length === 0){
                    price_data2send.currency_id = currency === 0 ? 1 : 2
                    await private_server.patch(`product/${id}/price`, price_data2send)
                }else{
                    await private_server.patch(`/product/${id}/price/${price_id}`, price_data2send)
                }
                
                //actions.onUpdateProductCompleted()
                setTax(item)
                actions.onChangeModalStatus('edit_tax', true)
                //setSuccess(content.success_msg)
                //setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                //setSending(false)
            }

            //setTax(item)
            //actions.onChangeModalStatus('edit_tax', true)
            
        },
        onDeleteTax: async (item) => {
            let price_data2send = onGetSelectedFormDataNumber(price_form, prices[currency])
            delete price_data2send.currency_id

            let price_id = prices[currency].id_product_price.toString()

            /* if(form.taxable.value === 1 ){

                let final_taxes = 0
                if(taxes.length !== 0){
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });

                    price_data2send.price = parseFloat((parseFloat(price_form.price.value)/(1+final_taxes)).toFixed(3))
                    price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3))
                    price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value) / (1+final_taxes)).toFixed(3)) 
                }            
            }else{
                price_data2send.price = parseFloat((parseFloat(price_form.price.value)).toFixed(3))
                price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3)) 
                price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value)).toFixed(3))
            } */

            if(form.sale.value === 1 ){
                if(form.product_discount_type_id.value === 1){
                   
                    price_data2send.sale_discount = parseFloat(price_form.sale_discount.value)
                    
                    if(form.taxable.value === 1 && taxes.length !== 0){
                        let final_taxes = 0
                        taxes.forEach(el => {
                            //console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        });
                        price_data2send.sale_price = parseFloat((price_form.sale_price.value/(1+final_taxes)).toFixed(3))
                        
                    }else{
                       
                        price_data2send.sale_price = parseFloat(price_form.sale_price.value)
                    }
                }
                if(form.product_discount_type_id.value === 2){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                    delete price_data2send.sale_bundle_price
                }
                if(form.product_discount_type_id.value === 3){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                }
            }else{
                delete price_data2send.sale_price
            }

            try {
                updateAuthorizationHeader(private_server)
                if(prices.length === 0){
                    price_data2send.currency_id = currency === 0 ? 1 : 2
                    await private_server.patch(`product/${id}/price`, price_data2send)
                }else{
                    await private_server.patch(`/product/${id}/price/${price_id}`, price_data2send)
                }
                
                //actions.onUpdateProductCompleted()
                setTax(item)
                actions.onChangeModalStatus('delete_tax', true)
                //setSuccess(content.success_msg)
                //setSending(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                //setSending(false)
            }

            //setTax(item)
            //actions.onChangeModalStatus('delete_tax', true)
            
            
        },
        onChangeMainImage: async(id_image) => {
            //await request_update_main_image(id, id_image)
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/product/${id}/image/${id_image}`, {main: 1})
            actions.onUpdateImagesCompleted()
            actions.onUpdateProductCompleted()
        },
        onDeleteImage: (id_image) => {
            setSelectedImage(id_image)
            actions.onChangeModalStatus('delete_image', true)
        },
        onEditImage: (id_image) => {
            setSelectedImage(id_image)
            actions.onChangeModalStatus('edit_image', true)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm: async(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            /* if(id === 'brand_id'){
                console.log(temp[id].value)
                try{
                    await private_server.patch(`/product/${ID}`, {brand_id:temp[id].value})
                    //onUpdateCompleted()
                    actions.onUpdateProductCompleted()
                    setSuccess(content.success_msg)
                }catch(error){
                    console.log(error)
                    setError(onGetErrorMessage(error))
                }
            } */
            if(id === 'taxable' || id === 'product_discount_type_id' || id === 'sale' ){
                let temp_price = {...price_form}

                let final_taxes = 0

                if(taxes.length !== 0){
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });


                    if(temp['taxable'].value === 1){
                        /* temp_price.price.value = (parseFloat(price_form.price.value)*(1+final_taxes)).toFixed(3)
                        temp_price.sale_price.value = (temp_price.price.value - parseFloat(price_form.sale_discount.value)).toFixed(3)
                        temp_price.sale_bundle_price.value = (parseFloat(price_form.sale_bundle_price.value)*(1+final_taxes)).toFixed(3) */
                        setTaxesPrice((parseFloat(price_form.price.value)*(1+final_taxes)).toFixed(3))
                        temp_price.sale_price.value = (temp_price.price.value - parseFloat(price_form.sale_discount.value)).toFixed(3)
                        temp_price.sale_bundle_price.value = (parseFloat(price_form.sale_bundle_price.value)*(1+final_taxes)).toFixed(3)

                        if(temp['sale'].value === 1 && temp['product_discount_type_id'].value === 1){
                            setFinalPrice(((parseFloat(price_form.price.value)-temp_price.sale_discount.value)*(1+final_taxes)).toFixed(3))
                            price_form.sale_price.value = ((parseFloat(price_form.price.value)-temp_price.sale_discount.value)*(1+final_taxes)).toFixed(3)
                        }else{
                            setFinalPrice((parseFloat(price_form.price.value)*(1+final_taxes)).toFixed(3))
                            price_form.sale_price.value = (parseFloat(price_form.price.value)*(1+final_taxes)).toFixed(3)
                        }

                    }else{
                        /* temp_price.price.value = (parseFloat(price_form.price.value)/(1+final_taxes)).toFixed(3)
                        temp_price.sale_price.value = (temp_price.price.value - parseFloat(price_form.sale_discount.value)).toFixed(3)
                        temp_price.sale_bundle_price.value = (parseFloat(price_form.sale_bundle_price.value)/(1+final_taxes)).toFixed(3) */
                        setTaxesPrice((parseFloat(price_form.price.value)))
                        temp_price.sale_price.value = (temp_price.price.value - parseFloat(price_form.sale_discount.value)).toFixed(3)
                        temp_price.sale_bundle_price.value = (parseFloat(price_form.sale_bundle_price.value)/(1+final_taxes)).toFixed(3)

                        if(temp['sale'].value === 1 && temp['product_discount_type_id'].value === 1){
                            setFinalPrice((parseFloat(price_form.price.value)-temp_price.sale_discount.value).toFixed(3))
                            price_form.sale_price.value = (parseFloat(price_form.price.value)-temp_price.sale_discount.value).toFixed(3)
                        }else{
                            setFinalPrice((parseFloat(price_form.price.value)))
                            price_form.sale_price.value = (parseFloat(price_form.price.value))
                        }
                    }
                    setPriceForm(temp_price) 
                }


                
            }
            console.log(form)
            if(id === 'product_quantity' || id === 'measure' || id === 'active_substance'){
                const product_quantity = temp.product_quantity.value ?? ''
                const measure = temp.measure.value ?? ''
                let _actives_subtances = temp.active_substance.value ?? '' 

                let aux = ''

                if(_actives_subtances) aux = `${_actives_subtances}`
                if(product_quantity) aux = `${aux}, ${product_quantity}`
                if(measure) aux = `${aux} ${measure}`
                temp.presentation.value = aux

            }

            if(id === 'product_type_id'){
                console.log('Buscar nuevo catalogo')
                let _subtypescat = subtypes.filter(el => el.product_type_id == data.value)
                _subtypescat = onGetCatalogs(_subtypescat, 'id_product_subtype', 'name')
                console.log('subtypes cat', _subtypescat)
                temp.product_subtype_id.value = null
                temp.product_subtype_id.isValid = false
                temp.product_subtype_id.isVisited = false
                temp.product_subtype_id.options = _subtypescat

            }
            


            setForm(temp)
        },
        onChangeSearchForm: (data) => {
            //console.log(data)
            let temp = { ...search_form }
            const id = data.config.id
            temp[id] = { ...data }       
            setSearchForm(temp)
        },
        onChangePrice: (data) => {
            let temp = { ...price_form }
            
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'sale_discount'){ 
                

                if(form.taxable.value === 1 && taxes.length !== 0){
                    let final_taxes = 0
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });
                    temp['sale_price'].value = ((temp['price'].value - temp['sale_discount'].value)*(1+final_taxes)).toFixed(3)
                    setFinalPrice(((temp['price'].value - temp['sale_discount'].value)*(1+final_taxes)).toFixed(3))
                }else{
                    temp['sale_price'].value = temp['price'].value - temp['sale_discount'].value
                    setFinalPrice(temp['price'].value - temp['sale_discount'].value)
                }

                if(parseFloat(temp['sale_discount'].value) > parseFloat(temp['price'].value)){
                    temp['sale_discount'].isValid = false     
                }
                if(parseFloat(temp['sale_discount'].value) < parseFloat(temp['price'].value)){
                    temp['sale_discount'].isValid = true     
                }
                //console.log(temp['sale_discount'].value)
            }

            if(id === 'price'){
                console.log('change price')
               
                if(form.taxable.value === 1 && taxes.length !== 0 ){
                    let final_taxes = 0
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });
                    //temp['sale_price'].value = ((temp['price'].value - temp['sale_discount'].value)*(1+final_taxes)).toFixed(3)
                    //console.log('hola')
                    setTaxesPrice(parseFloat(temp['price'].value*(1+final_taxes)).toFixed(3))
                    setFinalPrice(parseFloat((temp['price'].value)*(1+final_taxes)).toFixed(3))
                    if(form.sale.value === 1 && form.product_discount_type_id.value === 1){
                        setFinalPrice(parseFloat((temp['price'].value - temp['sale_discount'].value)*(1+final_taxes)).toFixed(3))
                        temp['sale_price'].value = ((temp['price'].value - temp['sale_discount'].value)*(1+final_taxes)).toFixed(3)
                    }
                }else{
                    //temp['sale_price'].value = temp['price'].value - temp['sale_discount'].value
                    console.log(temp['price'].value)
                    setFinalPrice((parseFloat(temp['price'].value)).toFixed(3))
                    if(form.sale.value === 1 && form.product_discount_type_id.value === 1){
                        setFinalPrice(parseFloat((temp['price'].value - temp['sale_discount'].value)).toFixed(3))
                        temp['sale_price'].value = ((temp['price'].value - temp['sale_discount'].value)).toFixed(3)
                    }
                }
            }
            
            setPriceForm(temp)
        },
        onChangeCurrency: (event) => {
            setCurrency(parseInt(event.target.value))
            let temp = JSON.parse(JSON.stringify(priceFormData))
            let _form = onInitForm(temp, prices[parseInt(event.target.value)])
            setPriceForm(_form)
        },
        //searchs
        onSearchBrands: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)
                //let _tags = await request_tags()
                updateAuthorizationHeader(public_server)
                let _brands = await public_server.get(`/brand/list`)
                const catalogs = onGetCatalogs(_brands.data.data.brands_data, 'id_brand', 'name')
                setBrandsCatalogs(catalogs)
                //setTagsCatalogs([content.tags.search_bar.add_register, ...tagsCatalogs])    
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onSearchTags: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)
                //let _tags = await request_tags()
                updateAuthorizationHeader(public_server)
                let _tags = await public_server.get(`/tag/list`)
                const tags = [..._tags.data.data.tags_data]       
                const opt = tags.map((item)=>{
                    let ex= true
                    for (let index = 0; index < product_tags.length; index++) {
                        if (item.id_tag === product_tags[index].tag_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                const tagsCatalogs = onGetCatalogs(final, 'id_tag', 'name')
                setTagsCatalogs(tagsCatalogs)
                //setTagsCatalogs([content.tags.search_bar.add_register, ...tagsCatalogs])    
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            //setLoading(false)
        },
        onSearchCategories: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)
                //let _categories = await request_categories()
                updateAuthorizationHeader(public_server)
                let _categories = await public_server.get(`/category/list`)
                const categories = [..._categories.data.data.categories]   
                //console.log(categories)    
                const opt = categories.map((item)=>{
                    let ex= true
                    for (let index = 0; index < product_categories.length; index++) {
                        if (item.id_category === product_categories[index].category_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                const catalogs = onGetCatalogs(final, 'id_category', 'name')
                setCategoriesCatalogs(catalogs)
                //setOptions([add_register, ...catalogs])    
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            //setLoading(false)
        },
        onSearchSubcategories: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)
                const categories = product_categories.map((item)=>{
                    return {"field":"category_id","operator":"=","value":`${item.category_id}`}
                })
                // console.log('hola')
                console.log(categories)
                let filter = {"OR":categories}
                filter = encodeURIComponent(JSON.stringify(filter))
                let params = `?&filter=${filter}`
                if(categories.length === 0) params = ''
                //let _subcategories = await request_subcategories(params)
                updateAuthorizationHeader(public_server)
                let _subcategories = await public_server.get(`/subcategory/list${params}`)
                //console.log(_subcategories)
                const subcategories = [..._subcategories.data.data.sub_categories]   
                //console.log(categories)    
                const opt = subcategories.map((item)=>{
                    let ex= true
                    for (let index = 0; index < product_subcategories.length; index++) {
                        if (item.id_sub_category === product_subcategories[index].sub_category_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                const catalogs = onGetCatalogs(final, 'id_sub_category', 'name')
                //setOptions([add_register, ...catalogs])  
                setSubcategoriesCatalogs(catalogs)  
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            //setLoading(false)
        },
        onSearchSegments: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)
                const subcategories = product_subcategories.map((item)=>{
                    return {"field":"sub_category_id","operator":"=","value":`${item.sub_category_id}`}
                })
                // console.log('hola')
                // console.log(categories)
                let filter = {"OR":subcategories}
                filter = encodeURIComponent(JSON.stringify(filter))
                const params = `?&filter=${filter}`
                //let _segments = await request_segments(params)
                updateAuthorizationHeader(public_server)
                let _segments = await public_server.get(`/segment/list${params}`)
                const segments = [..._segments.data.data.segments]   
                //console.log(categories)    
                const opt = segments.map((item)=>{
                    let ex= true
                    for (let index = 0; index < product_segments.length; index++) {
                        if (item.id_segment === product_segments[index].segment_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                const catalogs = onGetCatalogs(final, 'id_segment', 'name')
                setSegmentsCatalogs(catalogs)
                //setOptions([add_register, ...catalogs])    
            } catch (error) {
                console.log(error)
            }
            //setLoading(false)
        },
        onSearchSubsegments: async(e) => {
            if(!e || e.target.value === 0) return
            try {
                //setLoading(true)        
                const segments = product_segments.map((item)=>{
                    return {"field":"segment_id","operator":"=","value":`${item.segment_id}`}
                })
                // console.log('hola')
                //console.log(segments)
                let filter = {"OR":segments}
                filter = encodeURIComponent(JSON.stringify(filter))
                const params = `?&filter=${filter}`
                //let _subsegments = await request_subsegments(params)
                updateAuthorizationHeader(public_server)
                let _subsegments = await public_server.get(`/subsegment/list${params}`)
                const subsegments = [..._subsegments.data.data.sub_segments]   
                //console.log(subsegments)    
                const opt = subsegments.map((item)=>{
                    let ex= true
                    for (let index = 0; index < product_subsegments.length; index++) {
                        if (item.id_sub_segment === product_subsegments[index].sub_segment_id) ex = false
                    }
                    if(ex){
                        return item
                    }else{
                        return null
                    }
                })
                let final = []
                opt.forEach(el => {
                    if(el !== null) final.push(el)
                })
                const catalogs = onGetCatalogs(final, 'id_sub_segment', 'name')
                //console.log(catalogs)
                setSubsegmentsCatalogs(catalogs)
                //setOptions([add_register, ...catalogs])    
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            //setLoading(false)
        },

        //tags
        onDeleteTag: async(id_tag) => {
            //console.log(id_tag)
            try{
                //await request_delete_product_tag(id_tag)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/producttag/${id_tag}`)
                actions.onUpdateTagCompleted()
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }     
        },   
        onAddProductTag: async (el) => {
            try {
                //await request_create_product_tag({product_id:id, tag_id:el, main:0});
                updateAuthorizationHeader(private_server)
                await private_server.post(`/producttag`, {product_id:id, tag_id:el, main:0})
                actions.onUpdateTagCompleted()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        //categories
        onDeleteCategory: async(el) => {
            try{
                //await request_delete_product_category(el)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/productcategory/${el}`)
                await actions.onUpdateCategoriesCompleted() 
                actions.onInitCategoriesCatalogs()
                actions.onInitSubcategoriesCatalogs()
                actions.onInitSegmentsCatalogs()
                actions.onInitSubsegmentsCatalogs()
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }   
        },
        onAddProductCategory: async (el) => {
            try {
                //await request_create_product_category({product_id:id, category_id:el});
                updateAuthorizationHeader(private_server)
                await private_server.post(`/productcategory`, {product_id:id, category_id:el})
                actions.onUpdateCategoriesCompleted()
                actions.onInitSubcategoriesCatalogs()
                actions.onInitSegmentsCatalogs()
                actions.onInitSubsegmentsCatalogs()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        //subcategories
        onDeleteSubcategory: async(el) => {
            try{
                //await request_delete_product_subcategory(el)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/productsubcategory/${el}`)
                actions.onUpdateSubcategoriesCompleted()
                actions.onInitSubcategoriesCatalogs()
                actions.onInitSegmentsCatalogs()
                actions.onInitSubsegmentsCatalogs()
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }      
        },
        onAddProductSubcategory: async (el) => {
            try {
                //await request_create_product_subcategory({product_id:id, sub_category_id:el});
                updateAuthorizationHeader(private_server)
                await private_server.post(`/productsubcategory`, {product_id:id, sub_category_id:el})
                actions.onUpdateSubcategoriesCompleted()
                actions.onInitSegmentsCatalogs()
                actions.onInitSubsegmentsCatalogs()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        //segments
        onDeleteSegment: async(el) => {
            try{
                //await request_delete_product_segment(el)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/productsegment/${el}`)
                actions.onUpdateSegmentsCompleted()
                actions.onInitSegmentsCatalogs()
                actions.onInitSubsegmentsCatalogs()
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onAddProductSegment: async (el) => {
            try {
                //await request_create_product_segment({product_id:id, segment_id:el});
                updateAuthorizationHeader(private_server)
                await private_server.post(`/productsegment`, {product_id:id, segment_id:el})
                actions.onUpdateSegmentsCompleted()
                actions.onInitSubsegmentsCatalogs()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        //subsegments
        onDeleteSubsegment: async(el) => {
            try{
                //await request_delete_product_subsegment(el)
                updateAuthorizationHeader(private_server)
                await private_server.delete(`/productsubsegment/${el}`)
                actions.onUpdateSubsegmentsCompleted()
                actions.onInitSubsegmentsCatalogs()
            }catch(error){
                console.log(error)
                setError(onGetErrorMessage(error))
            }         
        },
        onAddProductSubsegment: async (el) => {
            try {
                //await request_create_product_subsegment({product_id:id, sub_segment_id:el});
                updateAuthorizationHeader(private_server)
                await private_server.post(`/productsubsegment`, {product_id:id, sub_segment_id:el})
                actions.onUpdateSubsegmentsCompleted()
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onSubmit: async () => {

            //console.log(form)
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                console.log(errors)
                return
            }
    
            let data2send = onGetSelectedFormData(form, product)
            
            //console.log(data2send)
    
            // Boolean data
            data2send.requires_recipe = recipe_check ? 1 : 0
            data2send.retains_recipe = retains_check ? 1 : 0
            data2send.for_adult = for_adult ? 1 : 0
            data2send.for_children = for_children ? 1 : 0
            data2send.generic = generic ? 1 : 0
            data2send.medication = medication ? 1 : 0
    
            // Rich text data
            data2send.pharmaceutical_form = onCleanData(pharmaceutical_form)
            data2send.details =  onCleanData(details)
            data2send.indications =  onCleanData(indications)
            data2send.contraindications =  onCleanData(contraindications)
            data2send.pregnancy_and_lactation =  onCleanData(pregnancy_and_lactation)
            data2send.precautions_and_warnings =  onCleanData(precautions_and_warnings)
            data2send.ingredients = onCleanData(ingredients)
            data2send.usage = onCleanData(usage)
            data2send.dosis = onCleanData(dosis)
            data2send.pharmaceutical_properties = onCleanData(pharmaceutical_properties)
            data2send.composition = onCleanData(composition)
            data2send.adverse_reactions = onCleanData(adverse_reactions)
            data2send.drug_interactions = onCleanData(drug_interactions)
            data2send.overdosage = onCleanData(overdosage)
            data2send.protection_labels = onCleanData(protection_labels)
            //data2send.presentation = presentation

            //console.log('PRODUCT:', 'MAIN DATA',data2send)
            //prices errors and data2send
    
            const price_errors = isFormValid(price_form)
            /// Check if the form is valid
            if(price_errors && price_errors.length){
                const new_form = onSetErrorsToForm(price_form, errors)
                setPriceForm(new_form)
                console.log(price_errors)
                return
            }
    
            //Extract data
            //console.log(prices[currency])
            //console.log(price_form)
            console.log('Debug:',prices)
            let price_data2send = onGetSelectedFormDataNumber(price_form, prices[currency])
            //console.log(price_data2send)
            //if(price_data2send) price_data2send.sale_price = price_form.sale_price.value
            delete price_data2send.currency_id

           
            //console.log(price_data2send)
    
            //from prices but is form
            /* if(form.taxable.value === 1 ){

                let final_taxes = 0

                if(taxes.length !== 0){
                    taxes.forEach(el => {
                        //console.log(parseFloat(el.rate))
                        final_taxes = final_taxes + (parseFloat(el.rate)/100)
                    });

                    console.log(final_taxes)

                    price_data2send.price = parseFloat((parseFloat(price_form.price.value)/(1+final_taxes)).toFixed(3))
                    price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3))
                    price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value) / (1+final_taxes)).toFixed(3)) 
                }
          
            }else{

                price_data2send.price = parseFloat((parseFloat(price_form.price.value)).toFixed(3))
                price_data2send.sale_price = parseFloat(parseFloat(price_data2send.price - parseFloat(price_form.sale_discount.value)).toFixed(3)) 
                price_data2send.sale_bundle_price = parseFloat((parseFloat(price_form.sale_bundle_price.value)).toFixed(3))
            } */
            
            //console.log(price_data2send)
            //console.log('PRODUCT:', 'PRICE DATA 1',price_data2send)
            if(form.sale.value === 1 ){
                //console.log('hola')
                //productdata2send.sale = 1
                if(form.product_discount_type_id.value === 1){
                    const dataprice = onGetFormData(price_form)
                    delete data2send.sale_bundle_free_quantity
                    delete data2send.sale_bundle_free_quantity
                    delete data2send.sale_bundle_quantity
                    //delete price_data2send.sale_bundle_price

                    let _currentPrice = parseFloat(dataprice.price)
                    let _salePrice = parseFloat(dataprice.sale_price)
                    let _saleDiscount = parseFloat(dataprice.sale_discount)

                    price_data2send.sale_discount = _saleDiscount

                    if(form.taxable.value === 1 && taxes.length !== 0){

                        let final_taxes = 0
                        taxes.forEach(el => {
                            //console.log(parseFloat(el.rate))
                            final_taxes = final_taxes + (parseFloat(el.rate)/100)
                        });

                        const sale_price_without_taxes = (_salePrice/(1+final_taxes))

                        const calculatedPrice = sale_price_without_taxes + _saleDiscount
                        const diff = _currentPrice - calculatedPrice
                        const adjustedSalePrice = sale_price_without_taxes + diff

                        price_data2send.sale_price = adjustedSalePrice
                        //price_data2send.sale_price = parseFloat((price_form.sale_price.value/(1+final_taxes)).toFixed(3))
                    }else{
                        price_data2send.sale_price = parseFloat(price_form.sale_price.value)
                    }
                    
                    console.log(price_data2send.sale_price)
                    //console.log('hola')
                    //price_data2send.sale_price = price_form.sale_price.value
                    //console.log(price_form.sale_price)
                }
                if(form.product_discount_type_id.value === 2){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                    delete data2send.sale_bundle_quantity
                    delete price_data2send.sale_bundle_price
                }
                if(form.product_discount_type_id.value === 3){
                    delete price_data2send.sale_discount
                    delete price_data2send.sale_price
                    delete data2send.sale_bundle_paid_quantity
                    delete data2send.sale_bundle_free_quantity
                }
            }else{
                delete price_data2send.sale_price
                delete price_data2send.sale_discount
            }
    
            let price_id = prices[currency].id_product_price.toString()
            console.log('PRODUCT:', 'PRICE DATA 2',price_data2send)
            //console.log(data2send)
            //console.log(price_data2send)
            //console.log(price_id)
    
            setSending(true)
            //onUpdateSending(true)
            try {
                updateAuthorizationHeader(private_server)
                if(prices.length === 0){
                    //console.log('create price')
                    price_data2send.currency_id = currency === 0 ? 1 : 2
                    //console.log(data2send)
                    //await request_create_product_prices(id, price_data2send);
                    await private_server.patch(`product/${id}/price`, price_data2send)
                }else{
                    //console.log('update price')
                    //console.log(data2send)
                    //await request_update_product_prices(id, price_id, price_data2send);
                    await private_server.patch(`/product/${id}/price/${price_id}`, price_data2send)
                    //setSending(false)
                }
                //await request_update_product(id, data2send);
                await private_server.patch(`/product/${id}`, data2send)
                //onUpdateCompleted()
                actions.onUpdateProductCompleted()
                setSuccess(content.success_msg)
                setSending(false)
            } catch (error) {
                console.log(error)
                console.log('PRODUCT ERROR:',error?.response?.data)
                setError(onGetErrorMessage(error))
                setSending(false)
            }
            //onUpdateSending(false)
        },
        onGoNextProduct: () => {
            let next_id  = parseInt(id) + 1
            history.push(`/products/${next_id}`)
            actions.onInitModule()
        },
        onGoBackProduct: () => {
            let back_id  = parseInt(id) - 1
            history.push(`/products/${back_id}`)
            actions.onInitModule()
        },
        onSetFarmaceuticalForm: (temp) => setFarmaceuticalForm(temp),
        onSetDetails: (temp) => setDetails(temp),
        onSetIndications: (temp) => setIndications(temp),
        onSetContraindications: (temp) => setContraindications(temp),
        onSetPregnancyAndLactation: (temp) => setPregnancyAndLactation(temp),
        onSetPrecautionsAndWarnings: (temp) => setPrecautionsAndWarnings(temp),
        onSetIngredients: (temp) => setIngredients(temp),
        onSetUsage: (temp) => setUsage(temp),
        onSetForAdult: (temp) => setForAdult(temp),
        onSetForChildren: (temp) => setForChildren(temp),
        onSetRecipeCheck: (temp) => setRecipeCheck(temp),
        onSetRetainsCheck: (temp) => setRetainsCheck(temp),
        onSetGeneric: (temp) => setGeneric(temp),
        onSetDosis: (temp) => setDosis(temp),
        onSetPharmaceuticalProperties: (temp) => setPharmaceuticalProperties(temp),
        onSetComposition: (temp) => setComposition(temp),
        onSetAdverseReactions: (temp) => setAdverseReactions(temp),
        onSetDrugInteractions: (temp) => setDrugInteractions(temp),
        onSetOverdosage: (temp) => setOverdosage(temp),
        onSetProtectionLabels: (temp) => setProtectionLabels(temp),
        onClearError: () => setError(null),
        onClearSuccess: () => setSuccess(null)
    }


    const system = {loading, sending, error, success}
    const form_data = {pharmaceutical_form, details, indications, contraindications, pregnancy_and_lactation, precautions_and_warnings, for_adult, for_children, 
        recipe_check, retains_check, ingredients, usage, generic, medication, dosis, pharmaceutical_properties, composition, adverse_reactions, drug_interactions,
        overdosage, protection_labels}
    const view_data = {form, currency, price_form, brands, images, prices, taxes, product, product_categories, product_subcategories, product_segments, product_subsegments, 
        product_tags, actives, active, id_active, selected_image, search_form, tax, taxes_price, final_price, presentation, productName}
    const search_catalogs = {tags_catalogs, categories_catalogs, subcategories_catalogs, segments_catalogs, subsegments_catalogs, brands_catalogs}

    return {system, modals, actions, view_data, form_data, search_catalogs } 
}

export default useGeneral