import { Checkbox, Grid, Typography } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { ColorPicker } from 'material-ui-color';
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../../components/Forms/InputForm'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../../../shared/utility'
import { private_server, public_server } from '../../../../../../variables/config'
import { catalogs } from '../../../../../../texts/esp/catalogs';



const AddSubcategoryModal = ({open, onClose, onUpdateCompleted, onAddProductItem, content, id}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)
    const [color, setColor] = useState('#ffffff')
    const [checked, setChecked] = useState(true)
    const [cat, setCat] = useState([])

    useEffect(() => {
    const initModal = async() =>{
        if (!open) {
            let _form = onInitEmptyForm(formData, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            try{
                //const _categories = await request_categories()
                updateAuthorizationHeader(public_server)
                //añadir filtro para que solo pueda añadir de las subacategorias de las categorias asignadas
                const req_product_categories = await private_server.get(`/productcategory/product/${id}`)
                const _product_categories = req_product_categories.data.data.product_category_data
                const categories = _product_categories.map((item)=>{
                    return {"field":"id_category","operator":"=","value":`${item.category_id}`}
                })
                // console.log('hola')
                console.log(categories)
                let filter = {"OR":categories}
                filter = encodeURIComponent(JSON.stringify(filter))
                let params = `?&filter=${filter}`
                const _categories = await public_server.get(`/category/list${params}`)
                const catalogs = onGetCatalogs(_categories.data.data.categories, 'id_category', 'name')
                setCat(catalogs)
                let _form = {...form}
                _form.category_id.options = catalogs
                setForm(_form)
            }catch(error){
                console.log(error)
            }
        }
    }
    initModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        //data2send.color = color
        data2send.store = checked ? 1 : 0
        const cate = cat.find(el => data2send.category_id === el.value)
        data2send.url = cate.label.split(" ").join('-').slice(0, -1) + form.name.value.split(" ").join('-')
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            //const new_subcategory = await request_create_subcategory(data2send);
            //console.log(new_subcategory)
            updateAuthorizationHeader(private_server)
            const new_subcategory = await private_server.post(`/subcategory`, data2send)
            onAddProductItem(new_subcategory.data.data.sub_category_id)        
            onUpdateCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <Typography variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.title}</Typography>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectForm data={form.category_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item><Checkbox color='primary' checked={checked} onChange={(event)=>setChecked(event.target.checked)}  /></Grid>
                                <Grid item><Typography color='textSecondary'>{content.store_label}</Typography></Grid>
                            </Grid>     
                        </Grid>
                        {/* <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' color='textSecondary'>{content.form.color.label}</Typography>
                            <ColorPicker value={color} disableAlpha onChange={onChangeColor} disablePlainColor/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid> */}
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <Typography color='error' align='center'>{error}</Typography>
        </SimpleModal>
    )

}


export default AddSubcategoryModal


const formData = {
    category_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'category_id',
            type: 'selected',
            fullWidth: true,
            label: 'Categoría',
            helperText: 'Seleccione al menos una opción',
        },
        rules: {
            type: 'select',
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    },
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },

}