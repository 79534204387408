import { change_image_error_msg } from "./general-translations";

const helper_text= 'Este campo debe contener min un caracter'

export const translations = {
    title:'Detalles del medicamento',
    success_msg:'Acción exitosa',
    main_card:{
        menu:[{id:1, label:'Imagen'}, {id:2, label:'Externa'}],
    },
    general_info:{
        title:'Información general',
        button:'Guardar',
        form:{
            name:{label:'Nombre', helperText:'Campo requerido'},
            external_id:{label:'ID externo', helperText:helper_text},
            external_image:{label:'Imagen externa', helperText:helper_text},
            presentation:{label:'Presentación', helperText:helper_text},
            laboratory_id:{label:'Laboratorio', helperText:helper_text},
            substance:{label:'Sustancias', helperText:helper_text},
        },
        search_bar:{
            title:'Laboratorio',
            add_register: {value:-1, label:'Agregar Laboratorio', type:'add'}
        },
        rich_texts:{
            presentation:'Presentación',
            description:'Descripción',
            composition:'Composición',
            pharmaceutical_form:'Forma farmacéutica',
            therapeutic_indications:'Indicaciones terapéuticas',
            pharmaceutical_properties:'Propiedades farmacéuticas',
            contraindications:'Contraindicaciones',
            pregnancy_restrictions:'Restricciones de uso durante el embarazo y la lactancia',
            adverse_reactions:'Reacciones adversas',
            medication_interactions:'Interacciones medicamentosas',
            laboratory_findings:'Hallazgos de laboratorio',
            warnings:'Advertencias',
            dose:'Dosis',
            overdose_management:'Manejo de sobredosificación',
            storage_recommendations:'Recomendaciones sobre almacenamiento',
            protection_captions:'Leyendas de protección'
        }
        
    },
    lab_modal:{
        add:{
            title:'Añadir laboratorio',
            button:'Añadir'
        },
        edit:{
            title:'Editar laboratorio',
            button:'Guardar'
        },
        form:{
            name:{label:'Nombre', helperText:'Campo requerido'},
        }
    },
    substance_modal:{
        add:{
            title:'Añadir sustancia',
            button:'Añadir'
        },
        edit:{
            title:'Editar sustancia',
            button:'Guardar'
        },
        form:{
            substance_id:{label:'Sustancia', helperText:'Campo requerido'},
        }
    },
    table:{
        title:'Sustancias',
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Sustancia'},
            {id:3, label:''},
        ],
        nodata:'No existen sustancias registradas'
    },
    
    
    
}

