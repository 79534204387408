import moment from "moment"
import { useEffect, useState } from "react"
import useBatches from "../../../../../hooks/useBatches"
import useProviders from "../../../../../hooks/useProviders"
import { onGenerateExcelFile } from "../../../../../shared/documents"
import { onGetCatalogs, onGetDateWithTimezonev2 } from "../../../../../shared/utility"


const useBatchSection = (props) => {

    const {user} = props

    const providerManager = useProviders()
    const batchManager = useBatches()

    const [loading, setLoading] = useState(false)
    const [ready, setReady] = useState(false)
    const [error, setError] = useState()

    const [batches, setBatches] = useState()
    const [providers, setProviders] = useState([])

    const [filter, setFilter] = useState({
        value:'',
        provider_id:-1,
        start_date:moment().subtract(1,'year').format('YYYY-MM-DD'),
        end_date:moment().endOf('d').add(5,'year').format('YYYY-MM-DD')
    })

    const [pagination, setPagination] = useState({limit:50, offset:0, order:0, order_by:'expiry_date'})
    const [total, setTotal] = useState()
    

    useEffect(() => {
        if(user) actions.onInitModule()
    }, [user])

    useEffect(() => {
        if(user && pagination && filter) actions.onRefreshData()
    }, [user, pagination, filter])

    const actions = {
        onInitModule: async() => {
            try {
                const _providers = await providerManager.actions.onGetAll()
                const providersOptions = onGetCatalogs(_providers.data, 'id_provider', 'name')
                console.log(providersOptions)
                setProviders([{id:-1, value:-1, label:'Todos'},...providersOptions])
                const params = actions.onGetParams()
                const _batches = await batchManager.actions.onGetAllV2(params)
                setBatches(_batches.data)
                setTotal(_batches.count)
                setReady(true)
            } catch (error) {
                
            }
        },
        onRefreshData: async() => {
            setLoading(true)
            try {
                const params = actions.onGetParams()
                const _batches = await batchManager.actions.onGetAllV2(params)
                setBatches(_batches.data)
                setTotal(_batches.count)
            } catch (error) {
                
            }
            setLoading(false)
        },
        onDownloadReport: async() => {
            setLoading(true)

            const start_date = filter.start_date.replace(/-/g, "")
            const end_date = filter.end_date.replace(/-/g, "")

            try {
                const params = actions.onGetParams()
                delete params.limit
                delete params.offset
                //)
                const _batches = await batchManager.actions.onGetAllV2(params)
                console.log(_batches.data)
                //setBatches(_batches.data)

                let data2send = [['Fecha','No Factura', 'UPC', 'Descripción del producto', 'Cantidad', 'Lote', 'Fecha de vencimiento']]
                for(let i=0; i<_batches.data.length; i++){
                    const temp = _batches.data[i]

                    const recDate = onGetDateWithTimezonev2(temp?.reception_date, 'DD-MMM-YY')  
                    const expDate = onGetDateWithTimezonev2(temp?.expiry_date, 'DD-MMM-YY')  

                    data2send.push([
                        recDate,
                        temp.order_reference,
                        temp.sku,
                        temp.product, 
                        temp.initial_quantity,
                        temp.number,
                        expDate,
                    ])
                }

                await onGenerateExcelFile(data2send, `Lotes-${start_date}-${end_date}`)

            } catch (error) {
                
            }

            setLoading(false)
        },
        onUpdateFilter: (value, key) => {
            console.log(value, key)
            
            let _filter = {...filter}
            _filter[key] = value
            setFilter(_filter)
            let _pagination = {...pagination}
            _pagination.offset = 0
            setPagination(_pagination)

        },
        onUpdatePagination: (val) => setPagination(val),
        onGetParams: () => {
            let _params = {...pagination}
            _params.offset = pagination.offset*pagination.limit
            let ANDparams = []

            if(filter.start_date) ANDparams.push({"field":"expiry_date","operator":">=","value":filter.start_date})
            if(filter.end_date) ANDparams.push({"field":"expiry_date","operator":"<=","value":filter.end_date})
            if(filter.provider_id > 0) ANDparams.push({"field":"provider_id","operator":"=","value":filter.provider_id})
            if(ANDparams.length) _params.filter = JSON.stringify({AND:ANDparams})
            console.log(_params)
            return _params
        }
    }


    const system = {loading, ready, error, ready}
    const data = {batches, providers, filter, total, pagination}
     
    return {system, data, actions}
}

export default useBatchSection