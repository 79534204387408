import { Typography, Grid, Checkbox, Icon, IconButton, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { SystemUpdate } from '@mui/icons-material';
import SelectFormLabeled from '../../../../components/Forms/SelectFormLabeled';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import WarningModal from '../../../../modals/WarningModal';
import AddUserRoleModal from '../modals/AddUserRoleModal';
import useUserRoles from '../useUserRoles';

const RolesInfoCard = ({ content, id }) => {
	const classes = useStyles();
	const { system, form, actions, data, modals } = useUserRoles({ content, id });

	return <>
        <AddUserRoleModal onUpdateCompleted={actions.onInitModule} content={content.modal} id={id} open={modals.add} onClose={() => actions.onChangeModalStatus('add', false)} />
        <WarningModal open={modals.delete} onClose={() => actions.onChangeModalStatus('delete', false)} error={system.error} loading={system.loading} onSubmit={actions.onDelete}/>
        <SimpleCard style={{ marginTop: 45 }}>
            <Grid container alignItems='center' style={{ marginBottom: 35 }}>
                <Grid item xs={10}>
                    <Typography variant='h6'>{content.title}</Typography>
                </Grid>
                <Grid item container justifyContent='flex-end' xs={2}>
                    <IconButton
                        className={classes.icon_add}
                        onClick={() => actions.onChangeModalStatus('add', true)}
                        size="large">
                        <Icon>add</Icon>
                    </IconButton>
                </Grid>
            </Grid>
            {system.loading && (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                </Grid>
            )}
            <Grid container className={classes.table_container}>
                <Grid item container alignItems='center' xs={12} style={{ marginBottom: 25 }}>
                    <Grid item xs={6}>
                        <Typography variant='body1'>{content.table_role}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography variant='body1'>{content.table_main}</Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Grid>
                {data.map((item, i) => {
                    const { role, main_role, id_user_role } = item;
                    return (
                        <Grid className={classes.table_row} item container alignItems='center' xs={12} key={i}>
                            <Grid item xs={6}>
                                <Typography variant='body1'>{role}</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Checkbox checked={main_role === 1} inputProps={{ 'aria-label': 'main role' }} onClick={() => actions.onSetMain(id_user_role, main_role)} />
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    className={classes.icon_delete}
                                    onClick={() => actions.onClickDeleteIcon(id_user_role)}
                                    size="large">
                                    <Icon style={{ color: '#D95D71', fontSize: 22 }}>delete</Icon>
                                </IconButton>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
            {system.error && <Typography style={{ color: 'red' }}>{system.error}</Typography>}
        </SimpleCard>
    </>;
};

const useStyles = makeStyles((theme) => ({
	table_container: {
		boxShadow: 'inset 0px 0px 5px rgba(0, 0, 0, 0.17)',
		borderRadius: 5,
		padding: '25px 30px',
	},
	table_row: {
		borderBottom: '1px solid #EAEAEA',
	},
	icon_delete: {
		width: 37,
		height: 37,
	},
	icon_add: {
		width: 40,
		height: 40,
		color: theme.palette.primary.main,
		boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.17)',
	},
}));

export default RolesInfoCard;
