import { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
	isFormValid,
	onGetErrorMessage,
	onGetFormData,
	onGetSelectedFormData,
	onInitForm,
	onInitEmptyForm,
	onSetErrorsToForm,
	onSetTokenSession,
	updateAuthorizationHeader,
	onGetCatalogs,
} from '../../../../shared/utility';
import { request_create_permissionsetting, request_permissions_modules } from '../requests';
import { private_server } from '../../../../variables/config';

const useAddPermission = ({ language, content, onClose, onUpdateCompleted, selected_role_id, open }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(form_data);

	//Cambiar
	const errors_txt = { INCOMPLETE_FORM: 'Llene los campos faltantes' };

	useEffect(() => {
		actions.onInitModule();
	}, [open]);

	const onGetParams = () => {
		const params2send = { limit: 200, offset: 0, order_by: 'role_id', order: 0 };
		let params = `?${queryString.stringify(params2send)}`;
		let filtersArgs = [];

		if (selected_role_id && selected_role_id !== -1) {
			filtersArgs.push({
				field: 'role_id',
				operator: '=',
				value: `${selected_role_id}`,
			});
			let finalArgs = { AND: filtersArgs };
			params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`;
		}

		return params;
	};

	const fillOptions = (all_modules, role_permissions) => {
		let unused_modules = [];
		all_modules.map((module) => {
			let found = false;
			role_permissions.map((role_module) => {
				if (module.id_module === role_module.module_id) {
					found = true;
					return;
				}
			});
			if (!found) unused_modules.push({ id_module: module.id_module, name_module: module.name });
		});
		return unused_modules;
	};

	const actions = {
		onInitModule: async () => {
			if (!open) {
				let _form = onInitEmptyForm(form, content.form);
				setForm(_form);
				setError('');
				setLoading(false);
			} else {
				let _form = onInitEmptyForm(form, content.form);

				const modules = await request_permissions_modules();
				const params = onGetParams();
				const role_permissions = await private_server.get(`/permission/list${params}`);
				const catalog = onGetCatalogs(fillOptions(modules.modules, role_permissions.data.data.permissions), 'id_module', 'name_module');
				_form.module_id.options = catalog;

				setForm(_form);
			}
		},
		onChangeForm: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onKeyPress: (event) => {
			if (event.key === 'Enter') {
				actions.onSubmit();
			}
		},
		onSubmit: async (checks) => {
			const errors = isFormValid(form);

			if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

			const data2send = onGetFormData(form);

			data2send.role_id = selected_role_id;

			for (const key in checks) {
				data2send[key] = checks[key];
			}

			console.log(data2send);

			setSending(true);
			setLoading(true);

			try {
				await request_create_permissionsetting(data2send);
				setLoading(false);
				onUpdateCompleted();
				onClose();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}

			setSending(false);
		},
	};

	const system = { loading, sending, error };

	return { system, form, actions };
};

export default useAddPermission;

const form_data = {
	module_id: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		options: [],
		config: {
			id: 'module_id',
			type: 'selected',
			fullWidth: true,
		},
		rules: {
			type: 'select',
		},
	},
};
