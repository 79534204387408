import React, {useState, useEffect} from 'react'
import { Grid } from '@mui/material'
import Page from '../../../components/Structure/Page/Page'
import OverviewCart from './components/OverviewCart'
import ProductsContainer from './components/ProductsContainer'
import { onGetErrorMessage } from '../../../shared/utility'
import { request_cart } from './requests'
import { translations as translations_esp } from '../../../texts/esp/cart-translations'

const CartView = (props) => {

    const content = translations_esp
    const { match } = props
    const id = match.params.id

    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [cart, setCart] = useState(null)
    
    
    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _cart = await request_cart(id)
            setCart(_cart.cart_data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
            setError(onGetErrorMessage(error))
        }}
        initModule()
    }, [])

    const onUpdateCompleted = async() =>{
        try{
            const _cart = await request_cart(id)
            setCart(_cart.cart_data)
        }catch(error){
            console.log(error)
            setError(onGetErrorMessage(error))
        }
    }


    return(
        <Page title={content.title} loading={loading} goback error={error} onCloseErrorModal={()=>setError(null)}>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                    <OverviewCart cart={cart} content={content.details_card} />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <ProductsContainer cart={cart} onUpdateCompleted={onUpdateCompleted}/>
                </Grid>
            </Grid>
        </Page>
    )

}

export default CartView