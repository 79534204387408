import { Avatar, Fade, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react'
import nouser from '../../../assets/nouser2.png'
import { isValidDocument } from '../../../shared/utility'
import { config_data, nodata } from '../../../variables/config'
import DisplayText from '../../Texts/DisplayText'
import cx from 'classnames'



const useStyles = makeStyles(theme => ({
    avatar:{
        objectFit:'contain'
    },
    noimage:{
        padding:8,
        background:theme.palette.primary.light,
        boxSizing:'border-box',
        /* width:24,
        height:24,
        objectFit:'contain' */
    },
    square:{
        borderRadius:5
    },
    noimage_square:{
        padding:8,
        background:theme.palette.primary.light,
        boxSizing:'border-box',
        borderRadius:5
    },
    fallback:{
        /* width:'100%',
        height:'100%', */
        width:24,
        height:24,
        padding:8,
        background:theme.palette.primary.light,
        boxSizing:'border-box',
        objectFit:'contain'
    }
}))

const UserAvatarName = props => {

    const classes = useStyles()
    const {image, name, labelStyles, noimage, variant} = props

    let selectedImage
    
    const [error, setError] = useState(false)

    const onLoad = () => {
        console.log('Imagen cargada')
        setError(false)
    }

    const onError = (e) =>{
        console.log(e)
        console.log('Hubo un error')
        setError(true)
    }

    //console.log(error)
    

    const isValid = isValidDocument(image) 
    selectedImage = isValid ? `${config_data.S3_SERVER_URL}${image}` : noimage
   

    return(
        <div>
            <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                <Grid item>
                    {/* <Avatar src={selectedImage} alt='' className={isValid ? classes.avatar : classes.noimage} variant={variant ? variant : undefined} /> */}
                    {!error ? <Avatar src={selectedImage} alt='' imgProps={{ onError:onError, onLoad:onLoad}}  className={cx({
                        [classes.avatar]:true, 
                        [classes.noimage]:!isValid,
                        [classes.square]: variant === 'square',
                        [classes.noimage_square]: variant === 'square' && !isValid
                        })} ><img src={noimage} className={classes.fallback} /></Avatar> :
                    <Avatar src={noimage} alt='' className={variant === 'square' ? classes.noimage_square : classes.noimage}  /> }     
                </Grid>
                {name ? <Grid item>
                    <DisplayText variant='body2' style={{...labelStyles}}>{name ? name : nodata}</DisplayText>
                </Grid> : null}
            </Grid>
        </div>
    )
}

export default UserAvatarName