import { connect } from 'react-redux'
import SimpleTable from '../../components/SimpleTable'
import system_translations from '../../../../../texts/system_translations'
import useInventorySection from './useInventorySection'
import useInventories from '../../../../../hooks/useInventories'
import EditInventoryModal from '../../modals/EditInventoryModal'
import useWarehouses from '../../../../../hooks/useWarehouses'


const InventorSection = props => {

    const { history, user, language } = props
    const warehouseManager = useWarehouses()
    const inventoryManager = useInventories()

    const content = system_translations[language].views.users
    const catalogs = system_translations[language].catalogs

    
    const {system, modals, actions, view_data} = useInventorySection({history, warehouseManager, inventoryManager})

    const {data, total, search, tableFilter, selected} = view_data


    return(
        <div>
            {/*<AddModal history={history} user={user} open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} 
                onUpdateCompleted={actions.onInitModule} content={content.modal} catalogs={catalogs} />*/}
            <EditInventoryModal  open={modals.edit} onClose={()=>actions.onChangeModalStatus('edit', false)} onCompleted={actions.onUpdateInventory}  
                origin={selected}/>
            {<SimpleTable
                loading={system.loading}
                header={[
                    {id:9999, label:''},
                    {id:1, label:'ID'},
                    {id:1, label:''},
                    {id:9, label:'Sku'},
                    {id:2, label:'Producto'},
                    {id:3, label:'EAN'},
                    {id:4, label:'Ubicación'},
                    {id:5, label:'Cantidad'},
                    {id:6, label:'Cant. max'},
                    {id:7, label:'Cant. min'},
                    {id:8, label:'P. reorden'},
                ]}
                warehouses={view_data?.warehouses ?? []}
                onChangeWarehouseMenu={actions?.onUpdateWarehouse}
                warehouse={view_data?.warehouse}
                content={content.tables.InventorSection}
                data={inventoryManager?.data?.products} 
                //data={view_data.data}
                search={search}
                tableFilter={tableFilter}
                total={total}   
                inventory_actions={actions}
                //onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onChangeSearch={actions.onChangeSearch}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onSelectedItem={actions.onSelectItem}    
                onGenerateReport={actions.onGenerateReport}
                downloading={system.downloading}
        />}
        </div>
    )
}

const mapStateToProps = state => {
    return{
        language: state.language,
        user: state.user
    }
}

export default connect(mapStateToProps)(InventorSection)