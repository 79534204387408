import React, {useState} from 'react'
import { alpha, Fade, Grid, Icon, Checkbox, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import cx from 'classnames'
import { grey } from '@mui/material/colors'
import { config_data } from '../../../../../../variables/config'

const useStyles = makeStyles(theme => ({
    root:{
        //width:'100%',
        //paddingTop:'100%',
        position:'relative',
        width:200,
        height:200,
        borderRadius:12,
        padding:'100px 50px',
        border:'1.06143px solid #D1D1D1',
        borderRadius:21,
        
        
    },
    container:{
        position:'absolute',
        width:'100%',
        height:'100%',
        top:0,
        left:0,
        /* '&:hover':{
            cursor:'pointer'
        } */
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    button:{
        background:alpha('#000', 0.30),
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        zIndex:2,
        borderRadius:12
    },
    iconContainer:{
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:3,
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:'white',
        fontSize:40,
    },
    imageContainer:{
        position:'relative',
        width:200,
        height:200,
        borderRadius:12
    },
    image:{
        width:'100%',
        height:'100%',
        objectFit:'contain',
        borderRadius:12
    },
    checkContainer:{
        position:'absolute',
        bottom:0,
        right:0,
        zIndex:10,
        color:'white',
        width:'100%'
    },
    deleteIcon:{
        color:'white',
        '&:hover':{
            color: grey[500]
        }
    },
    main_indicator:{

    },
    id_container:{
        position:'absolute',
        bottom:'10%',
        left:'50%',
        transform:'translate(-50%, 0px)'
    }
}))

const WhiteCheckbox = withStyles({
    root: {
      color: 'white',
      '&$checked': {
        color: 'whitw',
      },
      '&:hover':{
        color: grey[500]
    }
    },
    checked: {},
  })((props) => <Checkbox color="default" {...props} />);

const ImageContainer = props => {

    const classes = useStyles()
    const {image, onEdit, onDelete, main, onChangeMain, id_image, content} = props
    const [hover, setHover] = useState(null)

    const imgSrc = image ? `${config_data.S3_SERVER_URL}${image}` : null

    return (
        <div >
        <div className={classes.root}>
            <div className={cx({
                [classes.container]:true
            })} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <Fade in={hover}>
                    <div className={classes.button} >
                        <div className={classes.iconContainer} onClick={onEdit}>
                            <Grid container direction='column' alignItems='center' spacing={1}>
                                <Grid item>
                                    <Icon className={classes.icon}>camera_alt</Icon>
                                </Grid>
                                <Grid item>
                                    <Typography variant='body1' style={{color:'white', fontWeight:500}}>EDITAR</Typography>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.checkContainer}>
                            <Grid container alignItems='center' justifyContent='space-between' >
                                <Grid item>
                                    <IconButton size='small' onClick={onDelete}>
                                        <Icon className={classes.deleteIcon}>delete</Icon>
                                    </IconButton>
                                </Grid>
                                <Grid item> 
                                    <Grid container alignItems='center'>
                                        <Grid item>
                                            <Typography style={{fontSize:'0.5rem'}}>{content.main_check_text1}</Typography>
                                            <Typography style={{fontSize:'0.5rem'}}>{content.main_check_text2}</Typography>
                                        </Grid>
                                        <Grid item><WhiteCheckbox checked={main} onChange={onChangeMain} /></Grid>
                                    </Grid>                                                                            
                                </Grid>                            
                            </Grid> 
                        </div>
                    </div>
                </Fade>
                <div className={classes.imageContainer}>
                    <img src={imgSrc} className={classes.image} />             
                </div>
                
            </div>
            <div className={classes.id_container} >
                <Typography align='center' variant='body2'>ID: {id_image}</Typography>
            </div>
        </div>
        
        </div>
    );
}

export default ImageContainer

