import React, {useState, useEffect} from 'react'
import AddSubcategoryModal from './modals/AddSubcategoryModal'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import { request_subcategories } from './requests'
import DeleteModal from './modals/DeleteModal'
import { updateAuthorizationHeader } from '../../../../../shared/utility'
import { private_server } from '../../../../../variables/config'

const SubcategoriesView = (props) => {

    const {history, cat_subcategories_search, cat_subcategories_table, onUpdateSubcategoriesSearch, onUpdateSubcategoriesTable} = props

    //general
    const [loading, setLoading] = useState(false)
    const [subcategories, setSubcategories] = useState([])

    //modals
    const [add_modal, setAddModal] = useState(false)
    const [selected, setSelected] = useState(null)
    const [delete_modal, setDeleteModal] = useState(false)
 
    //table
    const [total, setTotal] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        initModule()
    }, [cat_subcategories_table])

    const initModule = async() => {try {
        window.scrollTo({top: 0, behavior: 'smooth'})
        setLoading(true)
        const params = onGetParamsFromState(cat_subcategories_table, cat_subcategories_search)
        //console.log(params)
        const _subcategories = await request_subcategories(params)
        console.log(_subcategories)
        setSubcategories(_subcategories.sub_categories)
        setTotal(_subcategories.count)
        setLoading(false)
    } catch (error) {
        console.log(error)
        setLoading(false)
    }}

    
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onUpdateCompleted = async() => {
        const params = onGetParamsFromState(cat_subcategories_table, cat_subcategories_search)
        const _subcategories = await request_subcategories('')
        setSubcategories(_subcategories.sub_categories)
        setTotal(_subcategories.count)
        setAddModal(false)
    }


    const onSelectedItem = (item, type) => {
        const {id_sub_category} = item
        setSelected(id_sub_category)
        if(type === 'edit') history.push(`/catalogs/subcategory/${id_sub_category}`)
        if(type === 'delete') setDeleteModal(true)
    }

    const onChangeStore = async(_id, prev_store) => {
        try{
            updateAuthorizationHeader(private_server)
            await private_server.patch(`/subcategory/${_id}`, {store: prev_store === 1 ? 0 : 1})
            initModule()
        }catch(e){
            console.log(e)
        }    
    }


    return(
        <div>
            <AddSubcategoryModal open={add_modal} onClose={() => setAddModal(false)} onUpdateCompleted={onUpdateCompleted} />
            <DeleteModal open={delete_modal} onClose={() => setDeleteModal(false)} onUpdateCompleted={onUpdateCompleted} id={selected}/>
            <SimpleTable
                data={subcategories} 
                tableFilter={cat_subcategories_table}
                search={cat_subcategories_search}
                total={total}
                loading={loading}
                onAddRegister={() => setAddModal(true)}
                onChangeSearch={onUpdateSubcategoriesSearch}
                onUpdateTableFilter={(data) => onUpdateSubcategoriesTable(data)}
                onSelectedItem={onSelectedItem}
                onChangeStore={onChangeStore}
            />
        </div>
    )
}

export default SubcategoriesView