import React, { useState, useEffect, useRef } from 'react';
import { Card, CircularProgress } from '@mui/material';
import { GoogleMap, Marker, useJsApiLoader, } from '@react-google-maps/api'
import marker from '../../../../assets/markers/location_red.svg'
//import { catalogs } from '../../../../../texts/esp/catalogs';

const containerStyle = {
    width: '100%',
    height: '350px'
};
  

const GOOGLE_API_KEY = 'AIzaSyCszA9IN38jH0_hWA5LR56dZDngbd90x1o'


const GoogleMapSection = ({ address, onUpdateMapPoint}) => {

     const [center, setCenter] = useState({lat:19.4326, lng:-99.1332})
     const [position, setPosition] = useState({lat:19.4326, lng:-99.1332})
     const [zoom, setZoom] = useState(13)
     const googleMapRef = useRef()


     const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_API_KEY // ,
        // ...otherOptions
    })

    useEffect(() => {
        window.scrollTo(0,0)
        console.log(googleMapRef.current)
    }, [])

    useEffect(() => {
        const onCalculatePoint = async() => {
            if(address){
                let _center = {
                    lat:parseFloat(address.latitude ? address.latitude : 19.4326),
                    lng:parseFloat(address.longitude ? address.longitude : -99.1332)
                }
                let _position = {
                    lat:parseFloat(address.latitude ? address.latitude : 19.4326),
                    lng:parseFloat(address.longitude ? address.longitude : -99.1332)
                }

                setCenter(_center)
                setPosition(_position)
            }
        }
        onCalculatePoint()
    }, [address])

    /* const onPointSelected = (data) => {
        console.log(data)
        onUpdateMapPoint(data)
    } */


    let pointer = null

    if(address){
        pointer = <Marker 
                    icon={{
                        url:marker,
                        scaledSize: new window.google.maps.Size(32, 37)
                    }}
                    position={{lat:parseFloat(address.latitude), lng:parseFloat(address.longitude)}}
                    //onClick={() => onPointSelected(item)}
                />
    
    }

    const google_map_content = 
        <GoogleMap 
            ref={googleMapRef}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}>
            <>
                {pointer}
            </>
        </GoogleMap>


    return ( 
        <Card style={{borderRadius:20}}>
            <div >
                {isLoaded ? google_map_content : <CircularProgress size={54}/> }
            </div>
        </Card>
     );
}
 
export default GoogleMapSection;