import SimpleTable from './components/SimpleTable';
import useCoverage from './useCoverage';
import AddModal from './modals/AddModal';
import EditModal from './modals/EditModal';
import catalogs from '../../../../../texts/esp/catalogs';




const Coverage = ({content}) => {

    const {system, modals, actions, view_data} = useCoverage({content})

  
    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} 
                catalogs={catalogs} />
            <EditModal open={modals.edit} onClose={()=>actions.onChangeModalStatus('edit', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} 
                origin={view_data.selected} catalogs={catalogs} />
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={view_data.tableFilter}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
                catalogs={catalogs}
                onUpdateFilterStatus={actions.onChangeFiltersStatus}
                filters={view_data.filters}
                warehouses={view_data.warehouses}
            />
        </div>
    )
}



export default Coverage