import {
    TextField,
    InputBase,
    InputLabel,
    Typography,
    FormHelperText,
    MenuItem,
    Container,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ruleValidation } from './customFunctions';
import { ColorPicker } from 'material-ui-color';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
	inputlabel: {
		marginBottom: 5,
		fontSize: 12,
		fontWeight: 500,
	},
	container: {
		position: 'relative',
	},
    picker_container:{
        marginLeft: -6,
        '& div':{
            fontSize: 16,
            fontWeight: 500,
            fontFamily: theme.typography.fontFamily,
            display: 'flex',
            alignItems: 'center'
        }
    }
}));

const ColorPickerForm = (props) => {
	const { data, onChange } = props;
	const classes = useStyles();

	useEffect(() => {
		let temp = { ...data };
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	}, []);

	const onInnerChange = (color) => {
		const response = `#${color.hex}`;
		let temp = { ...data };
		temp.value = response;
		temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
		temp.isVisited = true;
		onChange(temp);
	};

	const { config, value, isValid, isVisited } = data;

	const isError = isVisited && !isValid;

	let interConfig = { ...config };
	let helperText = interConfig.helperText;
	delete interConfig['helperText'];

	if (!isError) helperText = '';

	return (
		<div className={classes.container}>
			<InputLabel className={classes.inputlabel} htmlFor={interConfig.id}>
				{interConfig.label}
			</InputLabel>
			<div className={classes.picker_container}>
				<ColorPicker value={value} onChange={onInnerChange} disableAlpha id={interConfig.id} disableTextfield/>
			</div>
			<FormHelperText error={isError}>{helperText}</FormHelperText>
		</div>
	);
};

export default ColorPickerForm;
