import { Grid, Switch, Typography } from '@mui/material'
import React, {useState} from 'react'
import { Image, Text, View, StyleSheet } from '@react-pdf/renderer';
import JsBarcode  from 'jsbarcode'
import AutocompleteFormAdd from '../../../../../../components/Forms/AutocompleteFormAdd'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import ToolbarTable from '../../../../../../components/Structure/Navigation/ToolbarTable'
import { onGetCatalogs, onGetCurrencyValue, onGetProductItemPrice, onTruncateText } from '../../../../../../shared/utility'
import ImageContainer from '../components/ImageContainer'
//import SearchBrands from '../forms/SearchBrands'
import AddBrandModal from '../modals/AddBrandModal'
import UploadImageModal from '../modals/UploadImageModal'
import InputSwitch from '../../../../../../components/Forms/InputSwitch';
//import JsBarcode  from 'jsbarcode'


const MainCard = ({id, id_main, product, brands, onUpdateBrandsCompleted, onUpdateProduct, onUpdateImagesCompleted, onUpdateSending, content, form, options,
                    presentation, productName, actions}) => {

    //const content = translations_esp.main_card

    //console.log(product)

    const brandsCatalogs = brands ? onGetCatalogs(brands, 'id_brand', 'name') : []
    const brand = (product && product?.brand_id) ? {value: product.brand_id, label: product.brand} : null
    //console.log(brand)


    //modals
    const [brand_modal, setBrandModal] = useState(false)
    const [image_modal, setImageModal] = useState(false)

    const [main_image, setMainImage] = useState(id_main)

    const onSetMainImage = (img) =>{
        setMainImage(img)
    }

    //images web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id_view) => {
        setImageView(id_view)
        if(id_view === 1) setType('web')
        if(id_view === 2) setType('mobile')
    }

    let contentView = <ImageContainer image={product ? product.image_web : null} hidden={true}/> 

    switch(image_view){
        case 1:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={product ? product.image_web : null} hidden={true} /> 
            break
        case 2:
            contentView = <ImageContainer onClick={()=>setImageModal(true)} image={product ? product.image_mobile : null}  hidden={true}/> 
            break
        default:
            break
    }

    const onUpdateCompleted = () =>{
        onUpdateProduct()
        onUpdateImagesCompleted()
    }

    let brand_form = {...form}
    brand_form.options = options

    if(brand){
        const exist = options.find(el => el.value === brand.value)
        if(!exist) brand_form.options = [brand, ...options]
    }else{
        brand_form.options = [...options]
    }
    

    let barcodeImage = null

    if(product?.sku){
        let canvas = document.createElement('canvas');
        JsBarcode(canvas, product?.sku, {displayValue:false});
        const barcode = canvas.toDataURL();

        barcodeImage = (
            <div>
                <Grid container direction='column' alignItems='center' justifyContent='center'>
                    <Grid item>
                        <img src={barcode} alt='' width={250} height={73} />
                    </Grid>
                    <Grid item>
                        <Typography variant='caption'>{product?.sku}</Typography>
                    </Grid>
                </Grid>
                
            </div>
        )
    }
    

    return(
        <SimpleCard>
        <AddBrandModal open={brand_modal} onClose={()=>setBrandModal(false)} onUpdateCompleted={onUpdateBrandsCompleted}
        content={content.modal}/>
        <UploadImageModal open={image_modal} onClose={()=>setImageModal(false)} onUpdateCompleted={onUpdateCompleted}
        type={type} id={id} main={true} onSetMainImage={onSetMainImage} main_image={main_image}/>                         
            <div >
                <div style={{display:'flex', justifyContent:'flex-end', marginBottom:20}} >
                    <InputSwitch data={form.enabled} onChange={actions.onChangeForm} direction='row' />  
                </div>
                <div style={{marginBottom:28}}>
                    {contentView}
                    <div>
                        <Typography variant='subtitle2' style={{fontWeight:500}}>{productName}</Typography>
                        <Typography variant='body2'>{presentation}</Typography>
                    </div>
                    <div style={{margin:'40px 0px'}}>
                        {barcodeImage}
                    </div>
                </div>
                <div style={{justifyContent:'center', display:'flex'}} >
                    <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                </div>
            </div>  
            {/* <Grid container direction='column' spacing={4}>
                <Grid item>

                </Grid>
                <Grid item>
                    <div>
                    {contentView}
                    </div>
                </Grid>
                <Grid item>
                    <div>
                    {barcodeImage}
                    </div>
                </Grid>
                <Grid item>
                    <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                </Grid>
            </Grid> */}                          
        </SimpleCard>
    )
}

export default MainCard


const LabelTicket = (props) => {

    const {data} = props

    const MAX_CHARS = 64
    let textData = data?.name
    if(data?.sub_name) textData = `${textData} ${data?.sub_name}`
    if(data?.presentation) textData = `${textData} ${data?.presentation}`
    //console.log(textData)
    let barcode_data = `${data?.sku??''}`
    //let barcode_data = `123465487987`

    //const price_data = onGetProductItemPrice(data)
    //const final_price = onGetCurrencyValue(price_data.current)

    

    let canvas = document.createElement('canvas');
    //JsBarcode(canvas, barcode_data, {displayValue:false});
    const barcode = canvas.toDataURL();

    console.log(barcode)

    return(
        <View style={labelStyles.container}>
            {/* <View style={labelStyles.row}>
                <View><Image src={logo} style={labelStyles.logo} /></View>
                <View style={{flex:1}}></View>
                <View><Text style={labelStyles.price}>{final_price}</Text></View>
            </View> */}
            {/* <View style={labelStyles.product_info}>
                <Text style={labelStyles.name}>{onTruncateText(textData, MAX_CHARS)}</Text>
            </View> */}
            <View style={{flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
                {/* <Image id='img' src={barcode} style={labelStyles.barcode}/> */}
                <Text style={labelStyles.barcode_text}>{barcode_data}</Text>
            </View>
        </View>
    )
}

const labelStyles = StyleSheet.create({

    container:{
        //flexDirection:'row',
        borderWidth:'0.75pt',
        borderColor:'#F2F2F2',
        borderStyle:'solid',
        width:'100%',
        height:'1.97in',
        //height:'2.4in',
        padding:8,
        fontFamily:'Visby Round CF'
    },
    row:{
        flexDirection:'row',
        alignItems:'center'
    },
    logo:{
        width:'0.92in'
    },
    price:{
        fontSize:'14pt',
        fontWeight:300
    },
    product_info:{
        marginTop:12,
        fontSize:'12pt',
        fontWeight:'bold',
        textAlign:'center',
        //width:'100%',
        height:'0.8in'
        //height:'0.63pt'
    },
    name:{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        //borderWidth:'1pt',
        //borderColor:'#333',
        //borderStyle:'solid',
        fontWeight:500,
        lineHeight:'2pt'
    },
    barcode:{
        width:'1.24in',
        height:'0.36in'
    },
    barcode_text:{
        fontSize:'8pt'
    }
})