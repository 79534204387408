import icon_fb from '../../assets/icons/social/facebook.svg'
import icon_ig from '../../assets/icons/social/instagram.svg'
import icon_tw from '../../assets/icons/social/twitter.svg'
import icon_li from '../../assets/icons/social/linkedin.svg'
import banner_admin from '../../assets/banners/login_admin.png'
import banner_doctor from '../../assets/banners/login_doctor.png'
import logo from '../../assets/logo.svg'

export const translations = {
    logo: logo,
    icon_fb: icon_fb,
    icon_tw: icon_tw,
    icon_ig: icon_ig,
    icon_li: icon_li,
    button: 'Inicia sesión',
    forgot_psw: '¿Olvidaste tu contraseña?',
    remember: 'Recordarme',
    admin:{
        title: 'Inicia sesión',
        banner: banner_admin,
        banner_title: 'Reimagina tu salud',
        banner_subtitle: 'Administrador',
    },
    doctor:{
        title1: '¡Bienvenido,',
        title2: ' doctor!',
        banner: banner_doctor,
        banner_title: 'Reimagina tu salud',
        banner_subtitle: 'Doctor'
    }
} 