import { useEffect } from "react"
import { useState } from "react"
import { onGetCatalogs, onUpdateAuthorizationHeader } from "../../../shared/utility"
import { mainServer } from "../../../variables/config"
import queryString from 'query-string'

const useRoutesView = ({user, routes_table, routes_search, onUpdateFilters, routes_filters}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(false)

    const [routes, setRoutes] = useState([])

    const [warehouses, setWarehouses] = useState([])

    const [total, setTotal] = useState(0)
    const [filter, setFilter] = useState({query:''})
    const [filters, setFilters] = useState({
        status:-1,
        warehouse:-1
    })
    const [search, setSearch] = useState('')
    const [pagination, setPagination] = useState({limit:50,offset:0})

    const [modals, setModals] = useState({
        add_register: false,
        edit_register:false,
        delete_register:false
    })

    useEffect(() => {
        window.scrollTo(0,0)
        actions.onInitModule()
    }, [user, routes_table, routes_filters])


    const onGetParams = () => {
    
        const {limit, offset} = routes_table
        const params2send = {limit:limit, offset:offset*limit, order_by:'id_route', order:1}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = []

        if(routes_filters.status !== -1) filtersArgs.push({"field":"route_status_id","operator":"=", "value":`${routes_filters.status}`}) 

        if(routes_filters.warehouse !== -1) filtersArgs.push({"field":"warehouse_id","operator":"=", "value":`${routes_filters.warehouse}`}) 
    
        if(routes_search)filtersArgs.push({"field":"delivery_man","operator":"LIKE","value":`%${routes_search}%`})
            
        if(filtersArgs.length){
            let finalArgs = {"AND":filtersArgs}
            //filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs))
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        }
    
        return params 
    }


    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                onUpdateAuthorizationHeader()
                const req_warehouses = await mainServer.get(`/warehouse/list`)
                const cat_warehouses = onGetCatalogs(req_warehouses.data.data.warehouses_data, 'id_warehouse', 'name')
                setWarehouses(cat_warehouses)
                const params = onGetParams()
                const request_routes = await mainServer.get(`/route/list${params}`)
                setRoutes(request_routes.data.data.routes)
                setTotal(request_routes.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setLoading(false)
            }
        },
        onRefreshData: async() => {
            try {
                onUpdateAuthorizationHeader()
                const request_routes = await mainServer.get(`/route/list`)
                setRoutes(request_routes.data.data.routes)
                setTotal(request_routes.data.data.count)
            } catch (error) {
                
            }
        },
        onChangeSearch:(value) =>setSearch(value),
        onUpdatePagination:(value) => setPagination(value),
        onUpdateFilterStatus: (_key, _value) => {
            //setFilters({...filters, [_key]:_value})
            let temp = {...routes_filters}
            temp[_key] = _value
            onUpdateFilters(temp)
        },
        onUpdateModalStatus: (_key, _value) => setModals({...modals, [_key]:_value}),
    }


    const system = {loading, sending, error}
    const view_data = {routes, total, filter, pagination, search, filters, warehouses}


    return {system, view_data, actions, modals}

}

export default useRoutesView