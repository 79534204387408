import React, { useState } from 'react';
import { Grid, Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components/Texts/DisplayText';
import { onGetCatalogById, onGetFormatNumber } from '../../../../shared/utility';
import { grey } from '@mui/material/colors';
import { catalogs } from '../../../../texts/esp/catalogs';


const OverviewCart = ({cart, content}) => {

    const classes = useStyles()

    return ( 
        <SimpleCard>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DisplayText  variant='subtitle1' >{content.details}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <DisplayText color='textSecondary' >{cart ? cart.ordered_date : '-'}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <DataInfoDetails label={content.user} data={cart ? cart.user_id === null ? 'Sin propietario' : cart.user : '-'} />
                </Grid>
                <Grid item xs={12}>
                    <DataInfoDetails label={content.warehouse} data={cart ? cart.warehouse !== null ? cart.warehouse : '-' : '-'} />
                </Grid> 
                <Grid item xs={12}>
                    <DataInfoDetails label={content.payment_status} data={cart ? onGetCatalogById(catalogs.payment_statuses, cart.payment_status_id): '-'} />
                </Grid>
                <Grid item xs={12}>
                    <DataInfoDetails label={content.currency} data={cart ? onGetCatalogById(catalogs.currency, cart.currency_id): '-'} />
                </Grid> 
                <Grid item xs={12}>
                    <div className={classes.divider}/>
                </Grid>
                <Grid item xs={12}>
                    <DisplayText  variant='subtitle1' >{content.purchase_summary}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <DataInfo label={content.subtotal} data={onGetFormatNumber(cart ? cart.payment_subtotal : null)}/>
                </Grid>
                <Grid item xs={12}>
                    <DataInfo label={content.discount} data={`-${onGetFormatNumber(cart ? cart.code_discount: null)}`}/>
                </Grid>
                <Grid item xs={12}>
                    <DataInfo label={content.taxes} data={onGetFormatNumber(cart ? cart.payment_taxes: null)}/>
                </Grid>
                <Grid item xs={12}>
                    <DataInfo label={content.shipping_cost} data={onGetFormatNumber(cart ? cart.shipping_cost: null)}/>
                </Grid>
                <Grid item xs={12}>
                    <DataInfo label={content.total} data={onGetFormatNumber(cart ? cart.payment_total: null)} bold/>
                </Grid>   
            </Grid>
        </SimpleCard>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    divider:{
        margin:'24px 0px',
        borderTop:`1px solid ${grey[300]}`
    }
}))

export default OverviewCart;

const DataInfo = ({label, data, bold}) => {
    return(
        <Grid container wrap='nowrap'>
            <Grid item xs>
                <DisplayText color='textSecondary' variant={bold ? 'subtitle1' : 'body1'}>{label}</DisplayText>
            </Grid>
            <Grid item >
                <DisplayText  variant={bold ? 'subtitle1' : 'body1'}>{data}</DisplayText>
            </Grid>
        </Grid>
    )
}

const DataInfoDetails = ({label, data, bold}) => {
    return(
        <Grid container wrap='nowrap' spacing={1}>
            <Grid item >
                <DisplayText color='primary' variant={bold ? 'subtitle1' : 'body1'}>{label + ':'}</DisplayText>
            </Grid>
            <Grid item >
                <DisplayText  variant={bold ? 'subtitle1' : 'body1'}>{data}</DisplayText>
            </Grid>
        </Grid>
    )
}