import { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
	isFormValid,
	onGetErrorMessage,
	onGetFormData,
	onGetSelectedFormData,
	onInitForm,
	onInitEmptyForm,
	onSetErrorsToForm,
	onSetTokenSession,
	updateAuthorizationHeader,
	onGetCatalogs,
} from '../../../shared/utility';
import { private_server } from '../../../variables/config';
import { request_delete_userrole, request_set_mainrole, request_userroles } from './requests';

const useUserRoles = ({ content, id }) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);

	const [data, setData] = useState([]);

	const [modals, setModals] = useState({
		add: false,
		delete: false
	});

	const [selected, setSelected] = useState(null)

	useEffect(() => {
		actions.onInitModule();
	}, []);

	const actions = {
		onInitModule: async () => {
			setLoading(true);
			try {
				const userroles = await request_userroles(id);
				setData(userroles.user_roles);
				console.log(userroles.user_roles);
				setLoading(false);
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onDelete: async () => {
			setLoading(true);
			try {
				await request_delete_userrole(selected);
				setLoading(false);
				actions.onInitModule();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
			setSelected(null);
			actions.onChangeModalStatus('delete', false)
		},
		onChangeModalStatus: (key, status) => {
			let _modals = { ...modals };
			_modals[key] = status;
			setModals(_modals);
		},
		onSetMain: async (userrole_id, prev_state) => {
			setLoading(true);
			try {
				const data2send = { main_role: prev_state === 1 ? 0 : 1 };
				await request_set_mainrole(id, userrole_id, data2send);
				setLoading(false);
				actions.onInitModule();
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onClickDeleteIcon: (id) => {
			setSelected(id);
			actions.onChangeModalStatus('delete', true)
		},
	};

	const system = { loading, sending, error };

	return { system, actions, data, modals };
};

export default useUserRoles;

