import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SimpleCard from "../../../../components/Structure/Cards/SimpleCard"
import ProductImage from "../../../../components/Structure/Images/ProductImage"
import icon_recipe from '../../../../assets/orders/Group.png'
import { onGetFormatNumber } from "../../../../shared/utility"


const ProductsCard = ({cart, requires_recipe, retains_recipe, recipe_front, recipe_back, id_order }) =>{

    const classes = useStyles()

    let items = []
    if(cart) items = cart.line_items

    return(
        <SimpleCard>
            <div className={classes.header}>
                <Typography color='primary'>Resumen de productos</Typography>
                <Typography color='primary'>({items.length})</Typography>
            </div>
            <Grid container>
                {items.map((item) => {
                    return(
                        <Grid item xs={12}>
                            <div className={classes.row}>
                                <Grid container spacing={2} alignItems='center'>
                                    <Grid item><ProductImage src={item.main_product_image_web} type='TABLE' id={`src-${item.product_id}`} /></Grid>
                                    <Grid item xs><Typography>{item.name}</Typography></Grid>
                                    <Grid item>
                                        <Grid container>
                                            <Grid xs={12}>
                                                <div style={{width:'100%', textAlign:'end'}} ><Typography variant='caption' style={{color:'#ABABAB'}}>x{item.quantity}</Typography></div>
                                            </Grid>
                                            <Grid xs={12}>
                                                <div style={{width:'100%', textAlign:'end'}} ><Typography variant='subtitle2'>{onGetFormatNumber(item.subtotal)}</Typography></div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
            {Boolean(requires_recipe) ? <div className={classes.recipe}>
                <div className={classes.icon_container}>
                    <img src={icon_recipe} className={classes.icon} />
                </div>
                <Typography style={{marginLeft:8}}>Este pedido requiere receta</Typography>
            </div> : null}
            {Boolean(retains_recipe) ? <div className={classes.recipe}>
                <div className={classes.icon_container}>
                    <img src={icon_recipe} className={classes.icon} />
                </div>
                <Typography style={{marginLeft:8}}>Este pedido retiene la receta</Typography>
            </div> : null}
            {Boolean(requires_recipe) || Boolean(retains_recipe) ? 
                <div style={{marginTop:20}}>
                    <Grid container spacing={3} justifyContent='center'>
                        <Grid item>
                            <ProductImage type='MODAL' src={recipe_front} id={`src-${id_order}`} />
                        </Grid>
                        <Grid item>
                            <ProductImage type='MODAL' src={recipe_back} id={`src-${id_order}`} />
                        </Grid>
                    </Grid>
                </div>
            : null}
        </SimpleCard>
    )
}

export default ProductsCard

const useStyles = makeStyles(theme => ({
    header:{
        marginBottom:20,
        display:'flex',
        justifyContent:'space-between'
    },
    row:{
        borderBottom:'1px solid #EEEEEE',
        padding:'20px 0px'
    },
    recipe:{
        color:'#D95D71',
        textAlign:'center',
        paddingTop:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    icon:{
        width:'100%'
    },
    icon_container:{
        width:14
    }
}))