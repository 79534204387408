import React from 'react'
import { TextField } from '@mui/material'
import withStyles from '@mui/styles/withStyles';
import { ruleValidation } from './customFunctions'

const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        //color: 'green',
      },
      '& .MuiInput-underline:after': {
        //borderBottomColor: 'green',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius:12,
        '& fieldset': {
          //borderColor: 'red',
        },
        '&:hover fieldset': {
          //borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          //borderColor: 'green',
        },
      },
    },
  })(TextField);

const InputForm = props => {

    const {data, onChange, imgIcon, onKeyPress} = props

    const onInnerChange = event => {
        const response = event.target.value;
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    } 

    const {config, value, isValid, isVisited} = data

    const isError = isVisited && !isValid
    let interConfig = {...config}
    if(!isError) interConfig.helperText = ''


    return(
        <div>
            <CssTextField variant='outlined' value={value} onKeyPress={onKeyPress}
            error={isError} {...interConfig} onChange={onInnerChange} 
            InputProps={
                {
                    endAdornment:imgIcon
                }}
            />
        </div>
    )
}

export default InputForm