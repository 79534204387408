import { Grid } from '@mui/material'
import { connect } from 'react-redux'
import * as actionTypes from '../../../store/actions'
import Page from '../../../components/Structure/Page/Page'
import UserInfoCard from './components/UserInfoCard'
import { catalogs } from '../../../texts/esp/catalogs'
import UpdatePasswordModal from './modals/UpdatePasswordModal'
import ChangeImageModal from './modals/ChangeImageModal'
import GeneralInfo from './components/GeneralInfo'
import ContactInfo from './components/ContactInfo'
import AdditionalInfo from './components/AdditionalInfo'
import { translations as translations_esp } from '../../../texts/esp/user-translations'
import useUserView from './useUserView'
import RolesInfoCard from './components/RolesInfoCard'

const UserView = props => {

    const content = translations_esp
    const { match, onUpdateSending, onUpdateSuccess, onUpdateError } = props
    const userID = match.params.id
    const {system, modals, actions, view_data} = useUserView({content, match, onUpdateSending, onUpdateSuccess, onUpdateError})
    
    return(
        <Page title={content.titles.user} loading={system.loading} goback 
        //success={success} onCloseSuccess={() => setSucess(null)} 
        //error={error} onCloseErrorModal={()=>setError(null)}
        >
            <UpdatePasswordModal content={content.modals.update_password} userID={userID} open={modals.change_password} 
            onClose={() => actions.onChangeModalStatus('change_password', false)} onUpdateCompleted ={actions.onInitModule} onUpdateSuccess={onUpdateSuccess}/>
            <ChangeImageModal content={content.modals.change_image} id={userID} open={modals.change_image} 
            onClose={() => actions.onChangeModalStatus('change_image', false)} onUpdateCompleted ={actions.onInitModule}/>
            <Grid container spacing={3}>
                <Grid item xs={12} lg={5}>
                        <UserInfoCard content={content.cards} {...view_data.data} onChangePicture={() => actions.onChangeModalStatus('change_image', true)} 
                        onChangePassword={() => actions.onChangeModalStatus('change_password', true)} />
                        <RolesInfoCard content={content.roles_info_card} id={userID}/>
                </Grid>
                <Grid item xs={12} lg={7}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <GeneralInfo content={content.cards} form={view_data.form} onChange={actions.onChangeForm} sending={system.sending} 
                            onSubmit={actions.onSubmitGeneral}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ContactInfo content={content.cards} form={view_data.form} onChange={actions.onChangeForm} sending={system.sending2} 
                            onSubmit={actions.onSubmitContact}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AdditionalInfo content={content.cards} form={view_data.form} onChange={actions.onChangeForm} sending={system.sending3} 
                            onSubmit={actions.onSubmitAdditional}
                            check={view_data.indigenous_check} onChangeCheck={()=>actions.onSetIndigenousCheck(!view_data.indigenous_check)}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Page>
    )
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(UserView)

const formData = {
    /*------ general info ------*/
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    curp: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'curp',
            type: 'text',
            fullWidth: true,
            label: 'CURP',
            helperText: 'CURP no válida'
        },
        rules: {
            type: 'distance',
            min: 18, max: 18
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    
    /*------ contact info ------*/
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    mobile_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    fixed_phone_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'fixed_phone_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    fixed_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fixed_phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'No es un código postal válido'
        },
        rules: {
            type: 'zip',
            //min: 1, max: 256
        }
    },
    city: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'city',
            type: 'text',
            fullWidth: true,
            label: 'Ciudad',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },

    /*------ additional info ------*/
    blood_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.blood_types],
        config: {
            id: 'blood_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de sangre',
            helperText: 'Debe elegir una opción',
        },
    },
    civil_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.civil_statuses],
        config: {
            id: 'civil_status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estado civil',
            helperText: 'Debe elegir una opción',
        },
    },
    occupation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'occupation',
            type: 'text',
            fullWidth: true,
            label: 'Ocupación',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    scolarity_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.scolarities],
        config: {
            id: 'scolarity_id',
            type: 'select',
            fullWidth: true,
            label: 'Escolaridad',
            helperText: 'Debe elegir una opción',
        },
    },
    religion: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'religion',
            type: 'text',
            fullWidth: true,
            label: 'Religión',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_group: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_group',
            type: 'text',
            fullWidth: true,
            label: 'Grupo étnico',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_language',
            type: 'text',
            fullWidth: true,
            label: 'Dialecto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.currency],
        config: {
            id: 'currency_id',
            type: 'select',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Debe elegir una opción',
        },

    },
}