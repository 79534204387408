import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader, onGetCatalogs, onGetSelectedFormData, onInitForm, onGetDateFormat, onConvertDateToUtc } from '../../../../../shared/utility'
import DisplayText from '../../../../../components/Texts/DisplayText'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import { private_server } from '../../../../../variables/config'
import SelectForm from '../../../../../components/Forms/SelectForm'
import SelectAutocompleteForm from '../../../../../components/Forms/SelectAutocompleteForm'
import InputCheckbox from '../../../../../components/Forms/InputCheckbox'
import catalogs from '../../../../../texts/esp/catalogs'
import moment from 'moment'
import ProductAutocompleteForm from '../../../../../components/Forms/ProductAutocomplete'
import InputSelect from '../../../../../components/Forms/InputSelect'
import AutocompleteFormOptions from '../../../../../components/Forms/AutocompleteFormOptions'
import InputSwitch from '../../../../../components/Forms/InputSwitch'



const EditModal = props => {

    const { open, onClose, onUpdateCompleted, content, origin } = props

    const classes = useStyles()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
 
    //general
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    const [product, setProduct] = useState(null)
    const [product_label, setProductLabel] = useState(null)
    const [products, setProducts] = useState([])
    const [input, setInput] = useState(null)


    useEffect(async() => {
        if (open) {
            setLoading(true)
            const req_users = await private_server.get(`/users/all?filter={"AND":[{"field":"user_type_id","operator":"=","value":"2"}]}`)
            //console.log(req_users.data.data.users)
            if(origin && origin.product_id){
                setProducts([{value:origin.product_id, label:origin.product}])
                setProductLabel(origin.product)
            }

            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, content.form)
            _form = onInitForm(form, origin)
            _form.currency_id.options = catalogs.currency
            _form.code_discount_type_id.options = catalogs.code_discount_types
            _form.user_id.options = onGetCatalogs(req_users.data.data.users, 'id_user', 'first_name', 'fathers_last_name')
            _form.expiry_date.value = onGetDateFormat(origin.expiry_date,'YYYY-MM-DD') 
            setForm(_form)
            setLoading(false)
        }else{
            setError(''); setLoading(false);
            setDisabled(false)
            setForm(JSON.parse(JSON.stringify(formData)))
        }
    }, [open])

    const onSearchProduct = async(e, inp) => {
        if(!e || inp === "" || inp === 0) return
        if(error) setError(null)
        setInput(inp)
        console.log(inp)
        try {
            const req = await private_server.get(`product/search?limit=30&offset=0&search=${input}`)
            console.log(req)
            const cat = req.data.data.products.map(item => {
                return {value: item.id_product, label: item.name + ' ' + item.sub_name ? item.sub_name : '' + ' ' + item.presentation ? item.presentation : '' }})
            setProducts(cat)
        } catch (error) {
            console.log(error)
        }
    }


    const onChange = (data) => {
        //console.log(data)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }


    const onChange2 = (e, item) =>{
        if(item){
            //console.log(item)
            setProduct(item.value)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, origin)
        if(product) data2send.product_id = product
        const temp = onConvertDateToUtc(data2send.expiry_date)
        if(data2send.expiry_date){
            const validated = moment(data2send.expiry_date).isValid()
            if(validated) data2send.expiry_date = moment(data2send.expiry_date).format('YYYY-MM-DD')
        }

        //data2send.expiry_date = 
        
        //console.log(data2send, temp)
        
        setSending(true) 
        try {
            updateAuthorizationHeader(private_server)
            await private_server.patch(`discountcode/${origin.id_discount_code}`, data2send)
            onUpdateCompleted()
            setSending(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setSending(false)
        }

    }

    if(form.code_discount_type_id.value === 1){
        form.discount_value.isRequired = true
    }else{ form.discount_value.isRequired = false }
    if(form.code_discount_type_id.value === 2){
        form.discount_percentage.isRequired = true
    }else{ form.discount_percentage.isRequired = false }

    let product_form = {...form.product_id}
    product_form.options = [...products]

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs' loading={loading} loading_height={700} >
            <div >
                <DisplayText variant='h6' >{content.edit.title}</DisplayText>
                {/* <DisplayText >{content.subtitle}</DisplayText> */}
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <Grid container justifyContent='flex-end'>
                                <Grid item><InputSwitch data={form.enabled} onChange={onChange} direction='row' /></Grid>
                            </Grid>                     
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.code} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <SelectForm data={form.currency_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <SelectForm data={form.code_discount_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            {form.code_discount_type_id.value === 1 ? <InputForm data={form.discount_value} onChange={onChange} /> :
                            <InputForm data={form.discount_percentage} onChange={onChange} />}
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.minimum_purchase} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.total_uses} onChange={onChange} />
                        </Grid>
                        <Grid item xs={6} >
                            <InputForm data={form.current_uses} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.expiry_date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectAutocompleteForm data={product_form} onChange={onChange} onInputChange={onSearchProduct} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectAutocompleteForm data={form.user_id} onChange={onChange} />
                        </Grid>
                        {/* <Grid item xs={12} >
                            <InputCheckbox data={form.enabled} onChange={onChange} />
                        </Grid> */}
                    </Grid>
                </div>
                <div style={{textAlign:'center'}}>
                    <ContainedButton color='primary'  onClick={onSubmit} loading={sending} disabled={sending}>
                        {content.edit.button}
                    </ContainedButton>
                </div>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default EditModal

const useStyles = makeStyles(theme=>({
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    
}))

const formData = {
    code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    discount_value: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'discount_value',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'price',
            //min: 1, max: 256
        }
    },
    discount_percentage: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'discount_percentage',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'numeric',
            min: 0, max: 100
        }
    },
    currency_id: {
        value: 2,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'currency_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    minimum_purchase: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'minimum_purchase',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'price',
            //min: 1, max: 256
        }
    },
    total_uses: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'total_uses',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'quantity',
            //min: 1, max: 256
        }
    },
    current_uses: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'current_uses',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'quantity',
            //min: 1, max: 256
        }
    },
    code_discount_type_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        config: {
            id: 'code_discount_type_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    expiry_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        config: {
            id: 'expiry_date',
            type: 'date',
            fullWidth: true,
        },
        rules: {
            type: 'date',
            //min: 1, max: 256
        }
    },
    product_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'product_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    user_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'user_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type: 'select',
            //min: 1, max: 256
        }
    },
    enabled: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'enabled',
            //type: 'ch',
            fullWidth: true,
        }
    },
}

