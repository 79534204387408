import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import './calendarStyles.css'

import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";

import { Typography, Grid, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { onUpdateSlots2Calendar, onUpdateSlots2CalendarDelivery, onUpdateSlots2UTC } from '../../../../../../shared/utility';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const CalendarComponent = ({slots, slot_time, onUpdateSlots, title, sending}) => {

    const classes = useStyles()
    const [events, setEvents] = useState([])

    useEffect(() => {
        
        const data = onUpdateSlots2CalendarDelivery(slots)
        //console.log(data)
        setEvents(data)
    },[slots])

    const onEventDrop = (data) => {
        console.log('Something was dropped')
        console.log(data)
    }

    const onEventResize = () => {

    }

    const newEvent = (event) => {
        
        //const selected_slots = event.slots;
        const selected_slots = onUpdateSlots2UTC(event.slots, slot_time)
        const _slots2add = []
        const _slots2delete = []

        /* console.log(selected_slots)
        console.log(slots)
        console.log(slots[0].start_time.slice(0, -3)) */

        selected_slots.forEach(item => {
            const element = slots.find(el => (el.start_time.slice(0, -3) === item.start_time) && (el.weekday === item.weekday))
            console.log(element)
            if(element){
                //_slots2delete.push(item)
                _slots2delete.push({...item, id:element.id_warehouse_delivery_timetable})
            }else{
                //console.log('Agregar dat')
                _slots2add.push(item)
            }
        })

        onUpdateSlots(_slots2add, _slots2delete)
    }



    return (
        <div>
            <div className={classes.topbar} >
                <Typography variant='h6'>{title}</Typography>
            </div>
            {sending ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <div className={classes.card}>   
                <Calendar
                    defaultDate={moment().toDate()}
                    defaultView="week"
                    events={events}
                    localizer={localizer}
                    onEventResize={onEventResize}
                    onSelectSlot={newEvent}
                    resizable
                    selectable
                    step={slot_time}
                    timeslots={2}
                    style={{ height: "100%", border:'none', fontFamily:[
                        'Visby Round CF',
                        'Montserrat',
                        'Work Sans',
                    ].join(','),}}
                    eventPropGetter={(event, start, end, isSelected) => ({
                        event,
                        start,
                        end,
                        isSelected,
                        style: { backgroundColor: "#EAEBFF", color:'#60607E', borderRadius:10, border:'none'}
                    })}
                    /* dayPropGetter={() => ({
                        style: { fontFamily:[
                            'Visby Round CF',
                            'Montserrat',
                            'Work Sans',
                        ].join(',')}
                    })} */
                    /* slotPropGetter={() => ({
                        style: { border:'none', 
                        }
                    })} */
                    toolbar={false}
                    
                />
            </div>
        </div>
    );
}

const Topbar = () => {
    return <div>hola</div>
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        background:'white',
        boxShadow:'0px 6px 25px rgba(144, 144, 144, 0.07)',
        //boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        padding:'40px 24px',
        borderRadius:20,
        height:'calc(100vh - 300px)'
    },
    topbar:{
        padding:'40px 0px 24px'
    },
    calendar_days:{

    }
}))

export default CalendarComponent;