import { Grid } from "@mui/material"
import { useState } from "react"
import AutocompleteFormAdd from "../../../../../../components/Forms/AutocompleteFormAdd"
import InputSelectForm from "../../../../../../components/Forms/InputSelectForm"
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import AddBrandModal from "../modals/AddBrandModal"


const SecondaryCard = ({form, actions, product, content, onUpdateBrandsCompleted, options}) => {

    const brand = (product && product?.brand_id) ? {value: product.brand_id, label: product.brand} : null

    const [brand_modal, setBrandModal] = useState(false)

    let brand_form = {...form.brand_id}
    brand_form.options = options

    if(brand){
        const exist = options.find(el => el.value === brand.value)
        if(!exist) brand_form.options = [brand, ...options]
    }else{
        brand_form.options = [...options]
    }

    return(
        <SimpleCard>
            <Grid container spacing={4}>
                <AddBrandModal open={brand_modal} onClose={()=>setBrandModal(false)} onUpdateCompleted={onUpdateBrandsCompleted}
                    content={content.modal}/>
                <Grid item xs={12}>  
                    <AutocompleteFormAdd data={brand_form} onChange={actions.onChangeForm} add_register={content.search_bar.add_register}
                        onAdd={()=>setBrandModal(true)} onInputChange={actions.onSearchBrands} />      
                </Grid>
                <Grid item xs={12}>  
                    <InputSelectForm data={form.product_type_id} onChange={actions.onChangeForm} />      
                </Grid>
                <Grid item xs={12}>  
                    <InputSelectForm data={form.product_subtype_id} disabled={!Boolean(form.product_type_id.value) || (form.product_subtype_id.options.length === 0)} onChange={actions.onChangeForm} />      
                </Grid>
            </Grid>
        </SimpleCard>
    )

}

export default SecondaryCard