const { mainServer} = require("../../../variables/config");

//gets
export const request_permissions_modules = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/permission/modules`)
    return response.data.data
}

export const request_permissionsettings = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/permission/list${params}` )
    return response.data.data
}

export const request_permissionsetting = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/permission/${id}` )
    return response.data.data
}

//posts
export const request_create_permissionsetting = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/permission`, data )
    return response.data.data
}


//patchs
export const request_update_permissionsetting = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/permission/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_permissionsetting = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/permission/${id}`)
    return response.data.data
}
