import React, {useState, useEffect} from 'react'
import { Grid, IconButton, Icon, AppBar, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import cx from 'classnames';
import { withRouter } from 'react-router-dom'
import UserButton from './components/UserButton/UserButtonAvatar'
import { blueGrey } from '@mui/material/colors'
import { connect } from 'react-redux'


const useStyles = makeStyles((theme) => ({
	root: {
		padding: '16px 0px 16px 286px',
		marginRight: 16,
		color: blueGrey[900],
		background: '#fdfdfe',
		//add by me
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	//Sidebar visual update: Diego
	rootShifted: {
		paddingLeft: 134,
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	rootMobile: {
		paddingLeft: 10,
		transition: theme.transitions.create('padding', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	appbar: {
		boxShadow: 'none',
		background: '#fff',
		padding: '0px 16px',
	},
	welcome: {
		fontWeight: 500,
	},
	name: {
		fontWeight: 500,
		color: blueGrey[700],
	},
	menu: {
		width: 42,
		height: 42,
		background: '#fff',
		boxShadow: '0px 0px 4px rgba(189, 189, 189, 0.25)'
	},
}));

const Topbar = (props) => {
	const { user, history } = props;
	const classes = useStyles();

	const onItemSelected = (url) => {
		history.push(url);
	};

	const onLogOut = () => {
		localStorage.clear();
		history.push('/signin');
	};

	const handleSidebar = () => {
		if (props.isMobile) props.sideBarHandler();
		else props.onShiftSideBar();
	};

	return (
        <AppBar className={classes.appbar}>
			<div className={cx({
						[classes.root]: true,
						[classes.rootShifted]: props.isShifted,
						[classes.rootMobile]: props.isMobile
					})}>
				<Grid container wrap='nowrap' alignItems='center'>
					<Grid item>
						<IconButton
                            color='primary'
                            aria-label='open drawer'
                            edge='end'
                            onClick={handleSidebar}
                            className={classes.menu}
                            size="large">
							<Icon style={{width: 24, height: 24}}>menu</Icon>
						</IconButton>
					</Grid>
					<Grid item xs>
						{/*<Grid container spacing={1}>
                             <Grid item>
                                 <DisplayText variant='h4' color='primary' className={classes.welcome}>Bienvenid@,</DisplayText>
                             </Grid>
                             <Grid item>
                                <DisplayText variant='h4' className={classes.name}>{user ? user.first_name : ''}</DisplayText>
                             </Grid>
                         </Grid>*/}
					</Grid>
					<Grid item>
						<UserButton onItemSelected={onItemSelected} onLogOut={onLogOut} user={user} />
					</Grid>
				</Grid>
			</div>
		</AppBar>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(Topbar))
