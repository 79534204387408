
import React from 'react'
import { TextField, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { grey, red } from '@mui/material/colors'
import Autocomplete from '@mui/material/Autocomplete';
import DisplayText from '../Texts/DisplayText';
//import DisplayText from '../Texts/DisplayText';


const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      //color: 'green',
    },
    '& .MuiInput-underline:after': {
      //borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius:12,
      '& fieldset': {
        //borderColor: 'red',
      },
      '&:hover fieldset': {
        //borderColor: 'yellow',
      },
      '&.Mui-focused fieldset': {
        //borderColor: 'green',
      },
    },
  },
})(TextField);

const SelectAutocompleteForm = props => {

    ///////////////////////////////////
    const {data, onChange, onInputChange } = props //onSearch
    const {isValid, isVisited, value, options, disabled, rules} = data;
    const {id, label,placeholder, helperText} = data.config;

    ///////////////////  STATE //////////////////////

    //////////////  FUNCTIONS  /////////////////

    //console.log(data)

    const onInnerChange = (e, item) => {
      let temp = {...data}
      if(item){
        //console.log(item)
        temp.value = item.value
        temp.isValid = true
      }else{
        temp.value = null
        temp.isValid = rules ? false : true
      }
      temp.isVisited = true
      onChange(temp)
    }

    ///////////////////// INTERNAL VARIABLES  /////////////////////////////
    let selectedOptions = []
    let selectedValue = null
    if (data) {
      if (options) selectedOptions = options
      if (value !== null && value !== undefined) {
        const singleEl = options.find(el => el.value === value)
        selectedValue = singleEl ? singleEl : selectedValue      
      }
    }
    
    

    let inputlabel = null;
    if(label) inputlabel =  <DisplayText variant='subtitle2'  color={!isValid && isVisited ? 'error' : 'inherit'}>{label}</DisplayText>

    return(
      <div>
        <Autocomplete
        disabled={disabled}
        value={selectedValue}
        options={selectedOptions}
        getOptionLabel={option => option.label}
        renderInput={params => (<CssTextField {...params} variant='outlined' color='primary' label={label} error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helperText : null}/>)}
        onChange={onInnerChange}
        onInputChange={onInputChange}
        noOptionsText={<DisplayText variant='body2' >Sin opciones </DisplayText>}
        renderOption={(props, option) => 
          <React.Fragment>
              <div {...props} >
                  <DisplayText variant='body2' >{option.label}</DisplayText>
              </div>
          </React.Fragment>
        }/>
      </div>
      
    )
}

export default SelectAutocompleteForm