import { Grid, Icon, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'


const useStyles = makeStyles(theme => ({
    root:{
        width:300,
        height:400,
        border:`1px dashed ${theme.palette.primary.main}`,
        borderRadius:21,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer'
        //padding:'8px 16px',
        //boxSizing:'border-box'
    },
    button:{
        background:theme.palette.primary.main,
        color:'white'
    }
    
}))


const AddImage = ({onClick, content}) =>{

    //const content = translations_esp.product_images
    const classes  = useStyles()

    return (
        <div className={classes.root} onClick={onClick}>
            <Grid container justifyContent='center'>
                <Grid item>
                    <IconButton className={classes.button} size="large"><Icon >add</Icon></IconButton>              
                </Grid>
                <Grid item xs={12}>
                    <div style={{textAlign:'center', marginTop:12}}>
                        <Typography variant='body2' color='primary'>{content.add_image_button}</Typography>
                    </div>
                </Grid>
                
            </Grid>
        </div>
    );
}

export default AddImage