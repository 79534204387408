import useGeneral from './useGeneral';
import { Button, Grid, Icon, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MainCard from './sections/MainCard';
import SimpleCard from '../../../../../components/Structure/Cards/SimpleCard';
import ContainedButton from '../../../../../components/Actions/ContainedButton';
import GeneralInfo from './sections/GeneralInfo'
import Price from './sections/Price'
import ActivesTable from './components/ActivesTable';
import AddActiveModal from './modals/AddActiveModal';
import EditActiveModal from './modals/EditActiveModal';
import DeleteActiveModal from './modals/DeleteActiveModal';
import ProductImages from './sections/ProductImages';
import Tags from './sections/Tags'
import Categories from './sections/Categories';
import Subcategories from './sections/Subcategories';
import Segments from './sections/Segments';
import Subsegments from './sections/Subsegments';
import Page from '../../../../../components/Structure/Page/Page';
import InputCheckbox from '../../../../../components/Forms/InputCheckbox';
import AddTaxModal from './modals/AddTaxModal'
import EditTaxModal from './modals/EditTaxModal';
import DeleteTaxModal from './modals/DeleteTaxModal';
import TabCard from './sections/TabCard';
import SecondaryCard from './sections/SecondaryCard';


const General = ({content, history, match, onUpdateError}) => {

    const classes = useStyles()

    const id = match.params.id
    const {system, modals, actions, view_data, form_data, search_catalogs} = useGeneral({content, history, match, onUpdateError})
    const {form, currency, price_form, brands, images, prices, product, product_categories, product_subcategories, product_segments, product_subsegments, 
        product_tags, actives, active, id_active, selected_image, search_form, taxes, tax, product_types_catalogs, product_subtypes_catalogs, presentation,
        productName} = view_data
    const {pharmaceutical_form, details, indications, contraindications, pregnancy_and_lactation, precautions_and_warnings, for_adult, for_children, 
        recipe_check, retains_check, ingredients, usage, generic, medication} = form_data
    const {tags_catalogs, categories_catalogs, subcategories_catalogs, segments_catalogs, subsegments_catalogs, brands_catalogs} = search_catalogs

    //console.log(product_subcategories)

    return (
        <Page loading={system.loading} error={system.error} onCloseErrorModal={actions.onClearError} sending={system.sending} nopadding
            success={system.success} onCloseSuccess={actions.onClearSuccess}>
            <AddActiveModal open={modals.add_active} onClose={()=>actions.onChangeModalStatus('add_active', false)} onUpdateCompleted={actions.onUpdateActivesCompleted} id={id}/>
            <EditActiveModal open={modals.edit_active} onClose={()=>actions.onChangeModalStatus('edit_active', false)} onUpdateCompleted={actions.onUpdateActivesCompleted} 
            id={id} id_active={view_data.id_active} origin={view_data.active}/>
            <DeleteActiveModal open={modals.delete_active} onClose={()=>actions.onChangeModalStatus('delete_active', false)} onUpdateCompleted={actions.onUpdateActivesCompleted} 
            id={id} id_active={view_data.id_active} />
            <AddTaxModal open={modals.add_tax} onClose={()=>actions.onChangeModalStatus('add_tax', false)} onUpdateCompleted={actions.onUpdateProductCompleted} id={id}/>
            <EditTaxModal open={modals.edit_tax} onClose={()=>actions.onChangeModalStatus('edit_tax', false)} onUpdateCompleted={actions.onUpdateProductCompleted} id={id} origin={tax} />
            <DeleteTaxModal open={modals.delete_tax} onClose={()=>actions.onChangeModalStatus('delete_tax', false)} onUpdateCompleted={actions.onUpdateProductCompleted} id={id} origin={tax} />
            <Grid container spacing={3}>
                {/* <Grid item xs={12} style={{marginTop:20}} >
                    <Grid container spacing={3} justifyContent='space-between' alignItems='center' >
                        <Grid item >          
                            <InputCheckbox data={form.enabled} onChange={actions.onChangeForm} />                 
                        </Grid>
                        <Grid item >          
                            <ContainedButton color='primary' onClick={actions.onSubmit} loading={system.sending} startIcon={<Icon>save</Icon>}>{content.button}</ContainedButton>                 
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs={12}>
                    <Grid container justifyContent='flex-end' spacing={3}>
                        <Grid item><IconButton
                            onClick={actions.onGoBackProduct}
                            className={classes.square_button}
                            size="large"><Icon>arrow_back_ios</Icon></IconButton></Grid>
                        <Grid item><IconButton
                            onClick={actions.onGoNextProduct}
                            className={classes.square_button}
                            size="large"><Icon>arrow_forward_ios</Icon></IconButton></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <MainCard id={id} product={product} brands={brands}  presentation={presentation} productName={productName}
                            onUpdateBrandsCompleted={actions.onUpdateBrandsCompleted} 
                            onUpdateImagesCompleted={actions.onUpdateImagesCompleted}
                            onUpdateProduct={actions.onUpdateProduct} 
                            id_main={product ? product.product_image_id : null}
                            onUpdateSending={actions.onUpdateSending}
                            content={content.main_card}
                            form={form} actions={actions} options={brands_catalogs}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SecondaryCard brands={brands} form={form} actions={actions} product={product} options={brands_catalogs}
                            onUpdateBrandsCompleted={actions.onUpdateBrandsCompleted} content={content.main_card}  />
                        </Grid>                              
                    </Grid>
                </Grid>
                <Grid item xs={12} md={8} xl={9}>  
                    <GeneralInfo  presentation={presentation}
                    form={form} onChange={actions.onChangeForm} pharmaceutical_form={pharmaceutical_form} details={details} 
                    indications={indications} contraindications={contraindications} pregnancy_and_lactation={pregnancy_and_lactation} 
                    precautions_and_warnings={precautions_and_warnings} for_adult={for_adult} for_children={for_children} 
                    ingredients={ingredients} usage={usage} setIngredients={actions.onSetIngredients} setUsage={actions.onSetUsage}
                    recipe_check={recipe_check} retains_check={retains_check} setFarmaceuticalForm={actions.onSetFarmaceuticalForm} 
                    setContraindications={actions.onSetContraindications} setDetails={actions.onSetDetails} setIndications={actions.onSetIndications} 
                    setPregnancyAndLactation={actions.onSetPregnancyAndLactation} setPrecautionsAndWarnings={actions.onSetPrecautionsAndWarnings}
                    setRecipeCheck={actions.onSetRecipeCheck} setRetainsCheck={actions.onSetRetainsCheck} setForAdult={actions.onSetForAdult} setForChildren={actions.onSetForChildren}
                    content={content.general_info} generic={generic} medication={medication} setGeneric={actions.onSetGeneric} setMedication={actions.onSetMedication}
                    actions={actions} system={system} 
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop:20}} >
                    <TabCard content={content} form_data={form_data} actions={actions} view_data={view_data} id={id} modals={modals} 
                        search_catalogs={search_catalogs}/>
                </Grid>
            </Grid>
        </Page>
    );
}



export default General

const useStyles = makeStyles(theme => ({
    square_button:{
        borderRadius:10,
        boxShadow:'0px 4px 8px rgba(102, 102, 102, 0.1)',
        background:'white'
    }
}))