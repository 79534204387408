import axios from 'axios'
const { mainServer, BASIC_AUTH_HEADER } = require("../../../variables/config");

//gets
export const request_telemedicinebanners = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/telemedicinebanner/list${params}` )
    return response.data.data
}

export const request_telemedicinebanner = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/telemedicinebanner/${id}` )
    return response.data.data
}

//posts
export const request_create_telemedicinebanner = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/telemedicinebanner`, data)
    return response.data.data
}


//patchs
export const request_update_telemedicinebanner = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/telemedicinebanner/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_telemedicinebanner = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/telemedicinebanner/${id}`)
    return response.data.data
}

/*--------MEDIA--------*/
//posts
export const request_upload_telemedicinebanner_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/telemedicinebanner/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

//deletes
export const request_delete_image_file = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    console.log(data)
    let response = await mainServer.delete(`/telemedicinebanner/${id}/image/${data}`)
    return response.data.data
}

