import { useEffect, useState } from "react"
import { isFormValid, onCleanData, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"
import { catalogs } from "../../../texts/esp/catalogs"



const useWarehouseView = ({match, content, onUpdateError, onUpdateSuccess}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [warehouse, setWarehouse] = useState(null)
    const [zip_number, setZipNumber] = useState(null)


    const [modals, setModals] = useState({
        change_image:false,
        change_password:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    useEffect(() => {
        actions.onSearchZipAttributes(zip_number)
    }, [zip_number])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`warehouse/${id}`)
                setWarehouse(req.data.data.warehouse)
                let _form = onInitEmptyForm(form, content.views.info.form)
                setForm(_form)
                _form = onInitForm(form, req.data.data.warehouse)
                const _zip_code = req.data.data.warehouse.zip_code
                setZipNumber(_zip_code)
                const zipcode = await private_server.get(`/address/zipinfo?zip=${_zip_code}`)
                //console.log(zipcode.zip_information.zip_code)
                const suburbsCatalogs = onGetCatalogs(zipcode.data.data.zip_information.sub_urbs, 'id_suburb', 'name') 
                //console.log(suburbsCatalogs)
                _form.suburb_id.options = suburbsCatalogs
                const states = await private_server.get(`/address/states` )
                const statesCatalogs = onGetCatalogs(states.data.data.states, 'id_state', 'name')
                const municipalities = await private_server.get(`/address/municipalities` )
                const municipalitiesCatalogs = onGetCatalogs(municipalities.data.data.municipalities, 'id_municipality', 'name')
                //console.log(municipalitiesCatalogs)
                _form.state_id.options = statesCatalogs
                _form.municipality_id.options = municipalitiesCatalogs
                setForm(_form)
                /* setStore(Boolean(_warehouse.warehouse_data.store))
                setPickup(Boolean(_warehouse.warehouse_data.pickup))
                setDelivery(Boolean(_warehouse.warehouse_data.delivery)) */
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onSearchZipAttributes: async(value) => {
            if(value === "" || value === null || value === undefined || value.length < 5) return
            try {
                updateAuthorizationHeader(private_server)
                let zip_info = await private_server.get(`/address/zipinfo?zip=${value}`)
                zip_info = zip_info.data.data.zip_information
                if(zip_info){
                    const {value, id_zip_code, municipality, municipality_id, state, state_id} = zip_info.zip_code
                    let _form = {...form}
                    _form.state_id.value = state_id
                    _form.state_id.isValid = true
                    _form.state_id.options = [{value:state_id, label:state}]
                    _form.municipality_id.value = municipality_id
                    _form.municipality_id.isValid = true
                    _form.municipality_id.options = [{value:municipality_id, label:municipality}]    
                    _form.suburb_id.options = onGetCatalogs(zip_info.sub_urbs,'id_suburb','name')
                    _form.suburb_id.value = ''
                    _form.suburb_id.isValid = false
                    _form.zip_code.value = value
                    _form.zip_code.isValid = true
                    _form.zip_code_id.value = id_zip_code
                    _form.zip_code_id.isValid = true
                    setForm(_form)
                }
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm:(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            if(id === 'zip_code') setZipNumber(data.value)
            setForm(temp)
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                console.log(errors)
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetSelectedFormData(form, warehouse)
            if(form.contact_name.value === '') data2send.contact_name = onCleanData(form.contact_name.value)
            if(form.contact_phone.value === '') data2send.contact_phone = onCleanData(form.contact_phone.value)
            if(form.additional_indications.value === '') data2send.additional_indications = onCleanData(form.additional_indications.value)
            //address
            if(form.longitude.value === '') data2send.longitude = onCleanData(form.longitude.value)
            if(form.latitude.value === '') data2send.latitude = onCleanData(form.latitude.value)
            if(form.ext.value === '') data2send.ext = onCleanData(form.ext.value)
            if(form.int.value === '') data2send.int = onCleanData(form.int.value)
            /* data2send.store = store ? 1 : 0
            data2send.pickup = pickup ? 1 : 0
            data2send.delivery = delivery ? 1 : 0 */
            console.log(data2send)
            
            setSending(true)
            //onUpdateSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/warehouse/${id}`, data2send )
                actions.onInitModule()
                setSending(false)
                //onUpdateSending(false)
                onUpdateSuccess(content.success_msg)
                //setSucess('Acción exitosa')
            } catch (error) {
                console.log(error)
                //setError('Hubo un error')
                onUpdateError(onGetErrorMessage(error))
                setSending(false)
                //onUpdateSending(false)
            }
        },
        onClearSuccess:() => setSucess(null),
        onClearError:() => setError(null)        
    }

    const system = {loading, sending, success, error}
    const view_data = {form, warehouse, zip_number}

    return {system, modals, actions, view_data } 
}

export default useWarehouseView

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    contact_email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    contact_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 10
        }
    },
    street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    ext: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ext',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    int: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'int',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    additional_indications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_indications',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 0, max: 255
        }
    },
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'zip',
        }
    },
    zip_code_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code_id',
            type: 'text',
            fullWidth: true,
        },
    },
    /* street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    suburb_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'suburb_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type:'select',
        }
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    country_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[{value:1, label:'México'}],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
            disabled:true
        },
        rules: {
            type: 'select',
        }
    },
    map_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'map_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'link',
        }
    },
    latitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'latitude',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    longitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'longitude',
            type: 'number',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    store: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'store',
            type: 'text',
            fullWidth: true,
        },
        /* rules: {
            type: 'distance',
            min: 1, max: 255
        } */
    },
    pickup: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'pickup',
            type: 'text',
            fullWidth: true,
        },
        /* rules: {
            type: 'distance',
            min: 1, max: 255
        } */
    },
    delivery: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'delivery',
            type: 'text',
            fullWidth: true,
        },
        /* rules: {
            type: 'distance',
            min: 1, max: 255
        } */
    },
    appointment: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'appointment',
            type: 'text',
            fullWidth: true,
        },
        /* rules: {
            type: 'distance',
            min: 1, max: 255
        } */
    },
}