import SimpleTable from './components/SimpleTable';
import AddModal from './modals/AddModal';
//import DeleteModal from './modals/DeleteModal';
//import EditModal from './modals/EditModal';
import useLocal from './useLocal';




const Local = ({content, history, warehouses_local_search, warehouses_local_table, onUpdateSearch, onUpdateTable}) => {

    const {system, modals, actions, view_data} = useLocal({history, warehouses_local_search, warehouses_local_table,})

  
    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} content={content.modal} onUpdateCompleted={actions.onInitModule} />
            {/* <DeleteModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} onUpdateCompleted={actions.onInitModule} id={view_data.selected} /> */}
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={warehouses_local_table}
                search={warehouses_local_search}
                onUpdateTableFilter={onUpdateTable}
                onChangeSearch={onUpdateSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
            />
        </div>
    )
}



export default Local