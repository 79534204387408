import PictureModal from '../../../../components/Overlays/PictureModal';
import { translations as translations_esp } from '../../../../texts/esp/modals-translations'

const ChangeImageModal = ({open, onClose, onSelectImg, system}) => {
    const content = translations_esp.upload_image_modal;

    return ( 
        <PictureModal open={open} onClose={onClose} loading={system.loading} error={system.error} disabled={system.disabled}
            title={content.title} btnLabel={content.button} onChangeFile={onSelectImg} onDeleteFile={onClose}/>
     );
}
 
export default ChangeImageModal;