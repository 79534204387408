import axios from 'axios'
const { mainServer, BASIC_AUTH_HEADER } = require("../../../variables/config");

//gets
export const request_storebanners = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/storebanner/list${params}` )
    return response.data.data
}

export const request_storebanner = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/storebanner/${id}` )
    return response.data.data
}

export const request_products = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/list` )
    return response.data.data
}

//posts
export const request_create_storebanner = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/storebanner`, data)
    return response.data.data
}


//patchs
export const request_update_storebanner = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/storebanner/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_storebanner = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/storebanner/${id}`)
    return response.data.data
}

/*--------MEDIA--------*/
//posts
export const request_upload_storebanner_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/storebanner/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

//deletes
export const request_delete_image_file = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    console.log(data)
    let response = await mainServer.delete(`/storebanner/${id}/image/${data}`)
    return response.data.data
}

