import errors from '../../variables/errors.json'

//export const ERROR_CODES = JSON.parse(errors)
export const ERROR_CODES = {
    101:'Request has no authorization header',
    102:'Token expirado',
    103:'Token no válido',
    104:'Credenciales inválidas',
    105:'Usuario no autorizado para ejecutar esta acción',
    106:'Correo y/o contraseña incorrectos',
    107:'Error en autorización',
    108:'Correo no confirmado',
    201:'La información enviada es inválida',
    301:'Error con el servidor o base de datos',
    302:'Error al descifrar la contraseña',
    303:'Error al cifrar la contraseña',
    304:'No se pudo subir la imagen',
    305:'No se pudo subir el archivo',
    306:'Error al listar archivos',
    307:'No se pudo eliminar el archivo',
    308:'Error al descargar archivo',
    309:'Archivo faltante',
    310:'Error al enviar correo',
    311:'Error al generar pdf',
    312:'Pago rechazado',
    402:'El correo electrónico ya está en uso',
    418:'Ya existe un registro similar',
    401:'Idioma no encontrado',
    402:'Usuario no encontrado',
    403:'Tipo de usuario no encontrado',
    404:'Este correo electrónico, ya existe',
    405:'Este número telefónico, ya existe',
    406:'Género no encontrado',
    407:'Dirección no encontrada',
    408:'La dirección no pertenece',
    409:'País no encontrado',
    410:'Diagnóstico no encontrado',
    411:'El diagnóstico no pertenece',
    412:'La cita no pertenece',
    413:'Cita no encontrada',
    414:'Doctor no encontrado',
    415:'Esta cita ya pertenece a un usuario',
    416:'Especialidad no encontrada',
    417:'Subespecialidad no encontrada',
    418:'Traducción de detalles del doctor no encontrada',
    419:'Casilla de doctor no encontrada',
    420:'Disponibilidad duplicada',
    421:'No se encontró la disponibilidad del doctor',
    422:'La disponibilidad del doctor no esta disponible',
    423:'Medicamento no encontrado',
    424:'No se encontró la traducción del medicamento',
    425:'Paciente no encontrado',
    426:'Condición de tabaco no encontrada',
    427:'Condición de alcohol no encontrada',
    428:'Tipo de casa encontrado',
    429:'Inmunización no encontrada',
    430:'Historia médica no encontrada',
    431:'Tipo de alergia no encontrado',
    432:'Estatus de alergia no encontrado',
    433:'Severidad de la alergia no encontrada',
    434:'Alergia no encontrada',
    435:'La alergia no pertecene al usuario seleccionado',
    436:'Receta no encontrada',
    437:'La receta no pertecene al usuario provisto',
    438:'Cita no encontrada',
    439:'Recetas de los medicamentos no encontradas',
    440:'Ruta de administración no encontrada',
    441:'Medicación alternativa no encontrada',
    442:'Ruta de administración alternativa no encontrada',
    443:'Disponibilidad ya no disponible',
    444:'Doctor con disponibilidad reservada',
    445:'Disponibilidad no reservada',
    446:'Pago no encontrado',
    447:'Disponibilidad del doctor ya no disponible',
    448:'Disponibilidad casi saturada',
    449:'Moneda no encontrada',
    450:'El pago no pertenece al usuario provisto',
    451:'Ya existe una traducción para este idioma',
    452:'Lada no encontrada',
    453:'Concepto de pago no encontrado',
    454:'Estatus de pago no encontrado',
    455:'Tarjeta no asociada con el cliente',
    456:'El banco emisor de la tarjeta se negó a procesar este cargo',
    457:'Tarjeta con fondos insuficientes',
    458:'El cargo se tokenizó hace más de 10 minutos (MSI). Intentar otra vez',
    459:'Pago repetido en los últimos minutos',
    460:'Correo electrónico no confirmado ya utilizado',
    461:'Celular no confirmado ya utilizado',
    462:'Correo electrónico ya confirmado',
    463:'Correo electrónico ya en uso',
    464:'Celular ya en uso',
    465:'Código inválido',
    466:'Celular ya confirmado',
    467:'El correo electrónico no se ha confirmado aún',
    468:'El celucar no se ha confirmado aún',
    469:'Error al descargar archivo',
    470:'Archivos multimedia de chat no encontrados',
    471:'Error al subir documento',
    472:'Los archivos multimedia no pertenecen al usuario provisto',
    473:'Error al eliminar documento',
    474:'Error interno del servidor: Actualmente nuestro servidor se encuentra inactivo, intente de nuevo dentro de unos minutos',
    475:'Documentación del paciente no encontrada',
    476:'La documentación del paciente no pertecence al usuario provisto',
    477:'Imagen de paciente no encontrada',
    478:'La imagen del paciente no pertecene al usuario provisto',
    479:'Correo electrónico o número de celular faltantes',
    480:'Idioma no encontrado en las traducciones',
    481:'Doctores del paciente no encontrados',
    482:'El doctor no pertenece',
    483:'El paciente no pertecene',
    484:'Signo vital no encontrado',
    485:'El signo vital no pertenece',
    486:'No se encontraron datos antropométricos',
    487:'Los datos antropométricos no pertenecen',
    488:'No se encontraron datos de exploración física',
    489:'Los datos de exploración física no pertenecen',
    490:'Diagnóstico general no encontrado',
    491:'No se encontraron los datos de interrogación del sistema',
    492:'Los datos de interrogación del sistema, no pertenecen',
    493:'Para finalizar una cita debes agregar, al menos, un diagnóstico',
    494:'Cita concluida',
    495:'Para terminar la cita, debe haber una receta',
    496:'Especialidad no encontrada',
    497:'Tipo de pago no encontrado',
    498:'No se encontró la disponibilidad del doctor',
    499:'La disponibilidad del doctor se encuentra reservada',
    500:'No se encontró la solicitud de estudio',
    501:'La solicitud de estudio no pertenece al usuario provisto',
    502:'No se encontró el tipo de estudio',
    503:'Se necesita id de fuente para realizar esta acción',
    504:'El pedido no ha sido aprovado aún',
    505:'El pedido no pertenece al pago realizado',
    506:'La order de pago aún no ha sido aprovada por el cliente',
    507:'La order de pago aún no ha sido aprovada por el cliente',
    508:'Este pedido ha sido anulado previamente y no se puede anular de nuevo.',
    509:'Se detectó una transacción duplicada',
    510:'No se pudo realizar la acción solicitada, validación comercial fallida',
    511:'La cantidad especificada no coincide con el desglose',
    512:'La función de autorización y captura no está habilitada para el comerciante. Asegúrese de que el destinatario de los fondos sea una cuenta comercial verificada',
    513:'Las validaciones de cuenta fallaron para el usuario',
    514:'La cantidad de autorización especificada superó el límite permitido',
    515:'No se encontró el token del acuerdo de facturación solicitado',
    516:'El valor del pago debe ser mayor a 0',
    517:'No se admite el procesamiento de este tipo de tarjeta. Use otro tipo de tarjeta',
    518:'La longitud del código de seguridad no es válida para el tipo de tarjeta especificado',
    519:'El país especificado requiere una ciudad, especifique una ciudad y vuelva a intentar la solicitud',
    520:'La transacción no se puede procesar debido a una posible infracción de cumplimiento',
    521:'Para continuar con esta transacción, el cliente debe dar su consentimiento',
    522:'El tipo de moneda no es compatible con los pagos directos con tarjeta para este tipo de tarjeta.',
    523:'El tipo de moneda no es compatible con los pagos directos con tarjeta en este país',
    524:'El valor del campo no debe tener más de dos decimales',
    525:'Esta transacción requiere que el beneficiario y el cliente residan en el mismo país.',
    526:'El procesador o el banco rechazó el instrumento de financiación presentado.',
    527:'Ha ocurrido un error interno del servidor.',
    528:'Ha ocurrido un error interno del servidor.',
    529:'Para continuar con esta transacción, el cliente debe dar su consentimiento.',
    530:'El código del país es inválido',
    531:'El tipo de moneda es inválido o no está soportado.',
    532:'La identificación del cliente no es válida.',
    533:'El ID de recurso especificado no existe.',
    534:'Ha superado el número máximo de intentos de pago.',
    535:'Debe ser menor o igual a 9999999,99. Vuelva a intentar la solicitud con un valor diferente.',
    536:'Para comprobar que su aplicación tiene permisos suficientes, inicie sesión en el Portal para desarrolladores de PayPal.',
    537:'El pedido ya estaba autorizado y solo puede crear una autorización para un pedido.',
    538:'El pedido ya se capturó y solo puede capturar un pago por pedido.',
    539:'El pedido no puede ser modificado una vez que ha sido completado.',
    540:'El pedido está anulado o completado y, por lo tanto, no se puede autorizar.',
    541:'El pedido ha expirado y, por lo tanto, no se puede autorizar.',
    542:'Por favor, guarde el pedido.',
    543:'La cuenta del beneficiario especificada no es válida.',
    544:'La cuenta del beneficiario está bloqueada o cerrada.',
    545:'La cuenta mercantil está restringida.',
    546:'La configuración de fraude para este vendedor es tal que este pago no se puede ejecutar.',
    547:'La cuenta del cliente está bloqueada o cerrada.',
    548:'La cuenta del cliente está restringida',
    549:'El cliente no puede pagar esta transacción.',
    550:'El cliente no ha proporcionado el consentimiento adecuado para continuar con esta transacción.',
    551:'País del cliente no soportado', 
    552:'El país especificado requiere un código postal',
    553:'La transacción falló. Redirija al cliente para que seleccione otra fuente de financiación.',
    554:'La transacción fue bloqueada por la configuración de Protección contra fraudes del beneficiario.',
    555:'La transacción fue rechazada.',
    556:'El beneficiario no tiene una cuenta con PayPal. Su configuración actual requiere que el beneficiario tenga una cuenta verificada con PayPal antes de que pueda procesar transacciones a su nombre.',
    557:'El beneficiario no ha verificado su cuenta con PayPal. Su configuración actual requiere que el beneficiario tenga una cuenta con PayPal antes de que pueda procesar transacciones a su nombre.',
    558:'El beneficiario no tiene el consentimiento adecuado para permitir que la persona que llama a la API procese este tipo de transacción en su nombre. Su configuración actual requiere que el beneficiario brinde su consentimiento antes de que esta transacción pueda procesarse con éxito.',
    559:'Error de PayPal desconocido.',
    560:'Disponibilidad ya no disponible',
    561:'La disponibilidad no ha sido reservada por el usuario provisto.',
    562:'El pago provisto ya ha sido aplicado.',
    563:'No es posible agendar en esta fecha.',
    564:'Disponibilidad de horario ya liberado.',
    565:'No es posible cancelar en menos de 24 horas.',
    566:'La cita ya ha sido cancelada.',
    567:'Especialidad no encontrada',
    568:'Concepto de pago no encontrado.',
    569:'Pago personalizado de usuarios no válido.',
    570:'El método de pago proporcionado no pertenece al usuario proporcionado.',
    571:'Este método de pago se adjuntó previamente a un cliente o se separó de un cliente',
    572:'Este método de pago debe guardarse para un cliente antes de que pueda actualizarse.',
    573:'El método de pago proporcionado no pertenece al cliente proporcionado.',
    574:'Error al procesar pago.',
    575:'Esta tarjeta fue rechazada porque se reportó como extraviada.',
    576:'Esta tarjeta fue rechazada porque se reportó como robada.',
    577:'Esta tarjeta ha expirado.',
    578:'El código de seguridad de esta tarjeta es incorrecto.',
    579:'Un error ocurrió mientras se procesaba esta tarjeta. Intente otra vez dentro de un momento.',
    580:'Este cargo se considera fraudulento.',
    581:'La receta de la cita debe tener medicamentos.',
    582:'Cualquier medicamento recetado debe tener medicación.',
    583:'Es necesario indicar el motivo por el que no se pudo realizar la llamada.',
    584:'Recordatorio de cita no encontrado.',
    585:'Debe haber definido, al menos, un nombre de producto o nombre de la sustancia.',
    586:'No debería haber un diagnóstico si no se pudo realizar la cita.',
    587:'No debería haber receta si no se pudo realizar la cita.',
    588:'Intento de pago no válido.',
    589:'Pago sin estatus pendiente.',
    590:'Disponibilidad de médico reservada por otro usuario.',
    591:'La disponibilidad del médico ya no está reservada.',
    592:'Debe haber un diagnóstico.',
    593:'Los tokens de Google caducaron, actualícelos para que podamos comenzar su proceso de sincronización.',
    594:'Los tokens de Outlook caducaron, actualícelos para que podamos comenzar su proceso de sincronización.',
    595:'Farmacia no encontrada.',
    596:'Este doctor ya tiene una farmacia.',
    597:'Url no encontrada.',
    598:'Sucursal no encontrada.',
    599:'Código postal no encontrado.',
    600:'Suburbio no encontrado.',
    601:'Municipio no encontrado.',
    602:'Estado no encontrado.',
    603:'País no encontrado.',
    604:'Código postal sin cobertura.',
    605:'No se encontró cobertura para la sucursal.',
    606:'Inventario no encontrado.',
    607:'Producto no encontrado.',
    608:'No disponible.',
    609:'Código postal no encontrado.',
    610:'Producto visitado no encontrado.',
    611:'Marca no encontrada.',
    612:'Producto de medicación no encontrado.',
    613:'Producto comprado con frecuencia no encontrado.',
    614:'Producto frecuente no encontrado.',
    615:'Producto también visto no encontrado.',
    616:'Producto relacionado no encontrado.',
    617:'No se ha encontrado el historial de venta múltiple.',
    618:'Categoría no encontrada.',
    619:'La url ya esta en uso.',
    620:'Descuento no encontrado.',
    621:'No se encontró el precio del producto.',
    622:'Moneda ya agregada para el producto.',
    623:'Imagen del producto no encontrada.',
    624:'Documento no encontrado.',
    625:'Segmento no encontrado.',
    626:'Subcategoría no encontrada',
    627:'El producto ya se encuentra asociado a un segmento.',
    628:'Tag no encontrada.',
    629:'El producto ya se encuentra asociado a una tag.',
    630:'El producto es igual al producto frecuente.',
    631:'Ya existe la combinación.',
    632:'El producto es igual a producto también visto.',
    633:'El producto es igual a producto relacionado.',
    634:'Producto-categoría no encontrado.',
    635:'El producto ya se encuentra asociado a una categoría.',
    636:'Producto-tag no encontrado.',
    637:'El producto ya se encuentra asociado a una tag.',
    638:'Producto-segmento no encontrado.',
    639:'El producto ya se encuentra asociado a un segmento.',
    640:'Producto-subcategoría no encontrado.',
    641:'El producto ya se encuentra asociado a una subcategoría.',
    642:'Producto favorito no encontrado.',
    643:'Prodcuto favorito no pertenece al usuario proporcionado.',
    644:'El producto ya es un producto favorito.',
    645:'Banner de tienda no encontrado.',
    646:'Banner de telemedicina no encontrado.',
    647:'Producto más vendido no encontrado.',
    648:'Producto sobresaliente no encontrado.',
    649:'Producto en descuento no encontrado.',
    650:'Nuevo producto no encontrado.',
    651:'Marca relevante no encontrada.',
    652:'Paquete relevante no encontrado.',
    653:'Paquete de producto no encontrado.',
    654:'Paquete no encontrado.',
    655:'Paquede de imagen no encontrado.',
    656:'El producto ya pertenece a la categoría de productos más vendidos.',
    657:'El producto ya pertenece a la categoría de productos sobresalientes.',
    658:'El producto ya pertenece a la categoría de productos en oferta.',
    659:'El producto ya pertenece a la categoría de nuevos productos.',
    660:'La marca ya pertenece a la categoría de marcas relevantes.',
    661:'El paquete ya pertenece a la categoría de paquetes relevantes.',
    662:'El nombre ya ha sido tomado.',
    663:'El paquete ya pertenece a la categoría de paquetes.',
    664:'El segmento ya ha sido asociado a una subcategoría.',
    665:'No se encontró el id del tipo de envío.',
    666:'No se encontró el id del costo de envío.',
    667:'El costo de envío ya existe.',
    668:'Carrito no encontrado.',
    669:'Tipo de código de descuento no encontrado.',
    670:'Este código ya está en uso.',
    671:'Código de descuento no encontrado.',
    672:'Carrito no encontrado.',
    673:'El carrito no pertenece al usuario seleccionado.',
    674:'Tipo de impuesto no encontrado.',
    675:'El carrito ya tiene el mismo tipo de impuesto.',
    676:'El usuario ya tiene un carrito ordenado.',
    677:'El usuario debe ser nulo para realizar esta acción.',
    678:'El carrito ya ha sido ordenado.',
    679:'Línea de pedido no encontrada.',
    680:'Línea de impuesto no encontrada.',
    681:'La línea de pedido no pertenece al carrito proporcionado.',
    682:'Línea de pedido no encontrada.',
    683:'La línea de impuestos no pertenece al carrito proporcionado.',
    684:'Tipo de descuento de venta no encontrado.',
    685:'El carrito ya tiene el mismo producto.',
    686:'La línea de pedido pertenece a un pedido.',
    687:'La línea de pedido no pertenece al usuario seleccionado.',
    688:'El artículo de línea ya tiene el mismo tipo de impuesto',
    689:'El carrito ya tiene ese tipo de moneda.',
    690:'Este código de descuento no puede ser usado con este tipo de moneda.',
    691:'El carrito ya tiene propietario.',
    692:'Pedido no encontrado.',
    693:'El pedido no pertenece al usuario seleccionado.',
    694:'No hay cobertura para la zona con este código postal.',
    695:'Pago en tienda no encontrado.',
    696:'Estatus de pedido no encontrado.',
    697:'Dirección de envío no encontrada.',
    698:'El carrito ya está relacionado con un pedido.',
    699:'Pago en tienda no encontrado.',
    700:'El pago en tienda no pertenece al usuario proporcionado.',
    701:'El pedido ya ha sido pagado.',
    702:'El pedido aún no ha sido pagado.',
    703:'El pedido ya ha sido enviado.',
    704:'Pedido en tienda no encontrado.',
    705:'El pago proporcionado no pertenece a este pedido.',
    706:'El pedido ya tiene un pago válido.',
    707:'No se encontró una sucursal en esta dirección.',
    708:'Referencia externa aprobada no válida',
    709:'Pago no aprovado.',
    710:'No hay artículos en el carrito seleccionado.',
    711:'El pedido necesita contener un id de sucursal.',
    712:'El usuario no tiene una dirección principal.',
    713:'El usuario ya tiene una dirección principal.',
    714:'El usuario ya tiene una dirección de nacimiento.',
    715:'El usuario aún no tiene una dirección de nacimiento.',
    716:'El id del motivo de la cancelación de la cita no existe.',
    717:'El código de descuento ha excedido sus usos.',
    718:'El carrito necesita un producto determinado, por lo que puede tener este descuento.',
    719:'El carrito no aplica porque su pago es menor que la compra mínima.',
    720:'Este es un código de descuento expirado.',
    721:'El usuario de este carrito no cuenta con lo necesario para obtener este descuento.',
    722:'El usuario no tiene una dirección de nacimiento.',
    723:'El usuario ya tiene una dirección de nacimiento.',
    724:'El usuario ya tiene una dirección principal.',
    725:'El usuario aún no tiene una dirección principal.',
    726:'Información adicional no encontrada.',
    727:'Pago de membresía no encontrado.',
    728:'El pago de membresía no pertenece al usuario seleccionado.',
    729:'El usuario adicional no pertenece.',
    730:'No hay suficiente inventario de un producto.',
    731:'El usuario proporcionado no es parte de una cuenta adicional.',
    732:'El código de descuento ya no está habilitado.',
    733:'La moneda no corresponde.',
    734:'No se encontró el grupo familiar.',
    735:'El usuario ya pertenece a un grupo familiar.',
    736:'Solo el propietario del grupo familiar puede editarlo.',
    737:'Invitación familiar no encontrada.',
    738:'Miembro de familia no encontrado.',
    739:'El usuario no pertenece al grupo familiar proporcionado.',
    740:'Miembro de familia no encontrado.',
    741:'Invitación familiar no encontrada.',
    742:'La invitación no pertenece al grupo familiar proporcionado.',
    743:'Este miembro del grupo familiar no puede invitar a alguien al grupo.',
    744:'Invitante no encontrado.',
    745:'La invitación familiar no pertenece al usuario proporcionado.',
    746:'La invitación del grupo familiar ya ha sido respondida.',
    747:'Membresía no encontrada.',
    748:'La membresía no pertenece al usuario seleccionado.',
    749:'Tipo de membresía no encontrado.',
    750:'Estatus de membresía no encontrado.',
    751:'El usuario ya tiene membresía.',
    752:'El pago no pertenece al usuario seleccionado.',
    753:'La membresía debe suspenderse.',
    754:'La membresía ya ha sido suspendida.',
    755:'Esta membresía no ha sido suspendida.',
    756:'Parece que hay un problema con el método de pago, por favor intenta de nuevo dentro de unos minutos.',
    757:'Se requiere el id del usuario adicional o de un miembro del grupo familiar para continuar.',
    758:'Usuario relacionado no encontrado.',
    759:'Los usuarios proporcionados no son usuarios relacionados.',
    760:'El propietario de la membresía es el único que puede agregar usuarios a esta membresía.',
    761:'Esta membresía no puede aceptar más miembros.',
    762:'El usuario ya pertenece a una membresía.',
    763:'Miembro de la membresía no encontrado.',
    764:'El usuario no es miembro de la membresía seleccionada.',
    765:'La membresía no cubre este tipo de especialidad.',
    766:'El usuario necesita permiso para editar el perfil de otros.',
    767:'Usuario adicional no encontrado.',
    768:'Disponibilidad de horario duplicada.',
    769:'Tipo de sangre no encontrado.',
    770:'El usuario necesita un móvil para confirmar.',
    771:'Tipo de cita no encontrado.',
    772:'El doctor no coincide con la especialidad.',
    773:'La promoción de venta múltiple ha cambiado.',
    774:'Debido a otra compra; sus productos gratuitos incluidos ya se han canjeado.',
    775:'Un producto del carrito tenía una oferta que actualmente ha caducado.',
    776:'Un producto del carrito tiene una oferta que actualmente no está disponible.',
    777:'El pago no ha sido realizado aún.',
    778:'Código de descuento de la cita no encontrado.',
    779:'El concepto de pago no corresponde.',
    780:'Código de descuento de la cita no encontrado.',
    781:'El código de descuento de la cita está siendo usado.',
    782:'El código de descuento esta siendo usado.',
    783:'El concepto de pago no corresponde.',
    784:'"Usuario de este pago no cuenta con lo necesario para obtener este descuento.',
    785:'Para aplicar este descuento se necesita un cierto concepto de pago.',
    786:'Preferencia de facturación no encontrada.',
    787:'No se encontró el perfil de facturación de la cita.',
    788:'No se encontró el perfil de facturación de la farmacia.',
    789:'No se encontró el perfil de facturación de la membresía.',
    790:'No se encontró el perfil de facturación.',
    791:'El perfil de facturación no pertenece.',
    792:'Id de impuesto inválido.',
    793:'No se encontró el perfil de facturación.',
    794:'Factura de pago no encontrada.',
    795:'La factura de pago no pertenece.',
    796:'Factura de pago en tienda no encontrado.',
    797:'La factura de pago en tienda no pertenece.',
    798:'La factura de pago de membresía no pertenece.',
    799:'Factura de pago de membresía no encontrada.',
    800:'Falta el código o el token de acceso de Google.',
    801:'Ya existe una factura creada para el pago proporcionado.',
    802:'Membresía no disponible.',
    803:'No se encontró el registro de frecuencia cardíaca.',
    804:'El registro de frecuencia cardíaca no pertenece al usuario seleccionado.',
    805:'No se encontró el registro del dispositivo.',
    806:'Manual de registro no encontrado.',
    807:'Debe tener al menos un dispositivo o registro manual.',
    808:'No se encontró el registro de frecuencia respiratoria.',
    809:'La frecuencia respiratoria no pertenece al usuario seleccionado.',
    810:'No se encontró el registro de glucosa en sangre.',
    811:'El registro de glucosa en sangre no pertenece al usuario seleccionado.',
    812:'Registro de temperatura no encontrado.',
    813:'El registro de temperatura no pertenece al usuario seleccionado.',
    814:'No se encontró el registro de saturación de oxígeno.',
    815:'El registro de saturación de oxígeno no pertenece al usuario seleccionado.',
    816:'No se encontró el registro de presión sistólica.',
    817:'El registro de presión sistólica no pertenece al usuario seleccionado.',
    818:'Registro de peso no encontrado.',
    819:'El registro de peso no pertenece al usuario seleccionado.',
    820:'No se encontró el registro de presión diastólica.',
    821:'El registro de presión diastólica no pertenece al usuario seleccionado.',
    822:'Registro de altura no encontrado.',
    823:'El registro de altura no pertenece al usuario seleccionado.',
    824:'Registro de IMC no encontrado.',
    825:'El registro de IMC no pertenece al usuario seleccionado.',
    826:'No se encontró el registro de circunferencia de la cabeza.',
    827:'El registro de circunferencia de la cabeza no pertenece al usuario seleccionado.',
    828:'No se encontró el registro de la circunferencia de cintura.',
    829:'El registro de circunferencia de cintura no pertenece al usuario seleccionado.',
    830:'No se encontró el registro del perímetro de la cadera.',
    831:'El registro de perímetro de cadera no pertenece al usuario seleccionado.',
    832:'No se encontró el registro de grasa corporal.',
    833:'El registro de grasa corporal no pertenece al usuario seleccionado.',
    834:'No se encontró el registro de masa muscular.',
    835:'El registro de masa muscular no pertenece al usuario seleccionado.',
    836:'No se encontró el registro del metabolismo basal.',
    837:'El registro de metabolismo basal no pertenece al usuario seleccionado.',
    838:'No se encontró el registro de grasa visceral.',
    839:'El registro de grasa visceral no pertenece al usuario seleccionado.',
    840:'No se encontró el registro de índice de cintura y cadera.',
    841:'El registro de índice de cintura y cadera no pertenece al usuario seleccionado.',
    842:'Alergia del usuario no encontrada.',
    843:'La alergia no pertenece al usuario seleccionado.',
    844:'La alergia ya existe.',
    845:'Ya existe el registro de vacunación infantil.',
    846:'El registro de vacunación infantil no existe.',
    847:'Ya existe el registro de vacunación para adultos.',
    848:'El registro de vacunación para adultos no existe.',
    849:'El registro de exploración física ya existe.',
    850:'El registro de exploración física no existe.',
    851:'El registro de exploración del aparato ya existe.',
    852:'El registro de exploración del aparato no existe.',
    853:'Hospitalización no encontrada.',
    854:'La hospitalización no pertenece al usuario proporcionado.',
    855:'Diagnóstico no encontrado.',
    856:'Tipo de hospitalización no encontrado.',
    857:'Procedimiento no encontrado.',
    858:'Documento de hospitalización no encontrado.',
    859:'El documento de hospitalización no pertenece al usuario seleccionado.',
    860:'La historia familiar hereditaria ya existe.',
    861:'La historia familiar hereditaria no existe.',
    862:'Ya existe historia no patológica.',
    863:'No existe historia no patológica.',
    864:'Cita manual no encontrada.',
    865:'La cita manual no pertenece al usuario seleccionado.',
    866:'No se encontró el manual de diagnóstico.',
    867:'El manual de diagnóstico no pertenece al usuario seleccionado.',
    868:'No se encontró otra vacuna infantil.',
    869:'Otra vacuna infantil no pertenece al usuario seleccionado.',
    870:'No se encontró otra vacuna para adultos.',
    871:'Otra vacuna para adultos no pertenece al usuario seleccionado.',
    872:'Transfusión no encontrada.',
    873:'La transfusión no pertenece al usuario seleccionado.',
    874:'Documento de transfusión no encontrado.',
    875:'El documento de transfusión no pertenece al usuario seleccionado.',
    876:'Estudio de usuario no encontrado.',
    877:'El estudio de usuario no pertenece al usuario seleccionado.',
    878:'No se encontró el estudio de laboratorio.',
    879:'Documento de estudio no encontrado.',
    880:'El documento de estudio no pertenece al usuario seleccionado.',
    881:'No se encontró la discapacidad del usuario.',
    882:'La discapacidad del usuario no pertenece al usuario seleccionado.',
    883:'Discapacidad no encontrada.',
    884:'Documento de discapacidad no encontrado.',
    885:'El documento de discapacidad no pertenece al usuario seleccionado.',
    886:'Solicitud de estudio no encontrada.',
    887:'La solicitud de estudio no pertenece al usuario seleccionado.',
    888:'Anticonceptivo no encontrado.',
    889:'El anticonceptivo no pertenece al usuario seleccionado.',
    890:'Registro de dispositivo no encontrado.',
    891:'El registro de dispositivo no pertenece al usuario seleccionado.',
    892:'Dispositivo no encontrado.',
    893:'Registro manual no encontrado.',
    894:'El registro manual no pertenece al usuario seleccionado.',
    895:'La historia personal patológica ya existe.',
    896:'La historia personal patológica no existe.',
    897:'Receta manual no encontrada.',
    898:'La receta manual no pertenece.',
    899:'Unidad de dosis no encontrada.',
    900:'Unidad de dosis alternativa no encontrada.',
    901:'Unidad de frecuencia no encontrada.',
    902:'Unidad de frecuencia alterna no encontrada.',
    903:'Unidad de duración no encontrada.',
    904:'Unidad de duración alternativa no encontrada.',
    905:'Receta manual de medicación no encontrada.',
    906:'Estado civil no encontrado.',
    907:'Escolaridad no encontrada.',
    908:'Aplicación de usuario no encontrada.',
    909:'La aplicación de usuario no pertenece.',
    910:'Aplicación no encontrada.',
    911:'Registro de lista no encontrado.',
    912:'El registro de lista no pertenece al usuario seleccionado.',
    913:'Producto listado no encontrado.',
    914:'El producto ya está en la lista.',
    915:'Ruta no encontrada.',
    916:'La ruta no pertenece al usuario seleccionado.',
    917:'Asignador no encontrado.',
    918:'Estatus de ruta no encontrado.',
    919:'Repartidor no encontrado.',
    920:'La ruta necesita estar en estado pendiente antes de ser asignada.',
    921:'La ruta debe asignarse antes de que pueda encontrarse en el estatus: en preparación.',
    922:'La ruta debe estar en el estatus listo antes de configurarse en tránsito.',
    923:'La ruta debe estar en el estatus tránsito antes de configurarse como completada.',
    924:'Para finalizar un pedido, el pedido debe tener órdenes de ruta.',
    925:'El orden de ruta debe tener estado de tránsito.',
    926:'No se encontró la ruta del pedido.',
    927:'No se encontró el estatus del pedido de ruta.',
    928:'Seguimiento de ruta no encontrado.',
    929:'Sucursal de usuario no encontrada.',
    930:'No se encontró la ruta del pedido.',
    931:'Estatus del pedido de ruta no encontrado.',
    932:'El pedido debe tener un estatus pendiente o de emisión.',
    933:'La orden de ruta debe tener un estado pendiente.',
    934:'El pedido debe tener un estatus pendiente.',
    935:'La ruta del pedido debe tener estatus de tránsito',
    936:'El pedido debe tener un estatus de envío.',
    937:'El id del tipo de pago en tienda es requerido.',
    938:'La lista debe tener productos de lista para poder agregarlos al carrito.',
    939:'Registro no encontrado',
    940:'Acción de registro no encontrada.',
    941:'Modelo de registro no encontrado.',
    942:'El producto ya tiene una imagen.',
    943:'Coincidencia no encontrada.',
    944:'Alternativa no encontrada.',
    945:'Disponibilidad de horario no encontrada.',
    946:'Ya existe vacante.',
    947:'La imagen del paquete no pertenece al paquete seleccionado.',
    948:'La ruta debe estar en el estatus de preparación antes de establecerse en lista.',
    949:'El código ya ha sido usado.',
    950:'Motivo de cancelación no encontrado.',
    951:'Problema no encontrado.',
    952:'La fecha de entrega estimada no ha sido definida aún.',
    953:'La ruta del pedido debe tener el estatus en destino.',
    954:'Se debe definir la firma del pedido.',
    955:'El pedido necesita receta para ser completado.',
    956:'Deber ser una dirección de envío.',
    957:'Dirección no cubierta para entrega.',
    958:'El horario de programación debe ser una media hora exacta.',
    959:'La hora programada debe estar a 2 horas de la hora actual.',
    960:'La sucursal seleccionada no tiene disponible la opción de entrega.',
    961:'La hora de recogida debe ser una media hora exacta.',
    962:'La hora de recogida debe estar a 2 horas de la hora actual.',
    963:'El pedido no se puede eliminar debido a su estatus.',
    964:'Imagen del pedido no encontrada.',
    965:'El usuario ha superado el número máximo de usos del código.',
    966:'Problema con la ruta del pedido no encontrado.',
    967:'Motivo de suspensión no encontrado.',
    968:'Banner promocional no encontrado.',
    969:'Publicación de blog no encontrada.',
    970:'Categoría de blog encontrada.',
    971:'Tipo de publicación no encontrado.',
    972:'Publicación de producto no encontrado.',
    973:'El producto de la publicación no pertenece a la publicación de blog seleccionada.',
    974:'El tag de la publicación no pertenece a la publicación de blog seleccionada.',
    975:'Tag de publicación no encontrado.',
    976:'el tag no se ha agregado a la publicación del blog.',
    977:'Url no encontrada.',
    978:'La url ya ha sido usada.',
    979:'Terminal bancaria no encontrada.',
    980:'Modelo de terminal bancario no encontrado.',
    981:'No se encontró el uso de la terminal bancaria.',
    982:'El precio de oferta no corresponde.',
    983:'El precio de oferta debe ser definido.',
    984:'El descuento deber ser definido.'
}