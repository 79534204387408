import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import OrderStatus from '../../../../components/Structure/DisplayData/OrderStatus';
import PaymentStatus from '../../../../components/Structure/DisplayData/PaymentStatus';
import DisplayText from '../../../../components/Texts/DisplayText';
import { nodata } from '../../../../variables/texts';
import moment from 'moment-timezone'
import RoundedButton from '../../../../components/Actions/RoundedButton';


const HeaderContainer = ({ order, onCancel }) => {
    
    
    return ( 
        <div>
            <Grid container spacing={3} alignItems='center'>
                <Grid item>
                    <DisplayText variant='h6'>{`# ${order ? order.id_order : nodata}`}</DisplayText>
                </Grid> 
                <Grid item>
                    <RoundedButton onClick={onCancel} color='secondary_dark' disabled={!(order && order.order_status_id <= 2)} >Cancelar</RoundedButton>
                </Grid>
            </Grid>
        </div>
     );
}

 
export default HeaderContainer;
