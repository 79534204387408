import { Grid, Icon, Popover, Typography } from '@material-ui/core';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState, useEffect } from 'react';
import { catalogs } from '../../../../texts/esp/catalogs';
import { DASHBOARD_SETTINGS, nodata } from '../../../../variables/config';


const StatusButton = ({status_id, onChange}) => {

    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerSelectedItem = (data) => {
        setAnchorEl(null)
        onChange(data)
    }

    const onInnerClose = () => {
        setAnchorEl(null)
    }


    let _status_data = {color:grey[300], label:nodata}

    const _status = catalogs.route_statuses.find(el => el.value === status_id)
    if(_status) _status_data = {..._status}

    

    return ( 
        <div>
            <div className={classes.button} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <Grid container alignItems='center'>
                    <Grid item>
                        <Icon className={classes.icon} style={{color:_status_data.color}}>
                            brightness_1
                        </Icon>
                    </Grid>
                    <Grid item>
                        <Typography variant='subtitle2'>{_status_data.label}</Typography>
                    </Grid>
                </Grid>
            </div>
            <Popover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={onInnerClose} classes={{paper:classes.paper}}
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}}
                transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                    <div>
                        {catalogs.route_statuses.map(item =>
                            <div className={classes.option} onClick={() => onInnerSelectedItem(item)}>
                                <Icon className={classes.icon} style={{color:item.color}}>brightness_1</Icon>
                                <Typography variant='body2'>{item.label}</Typography>
                            </div>    
                        )}
                    </div>
            </Popover>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    icon:{
        fontSize:14,
        marginRight:2,
        marginTop:6
    },
    button:{
        background:'white',
        padding:'8px 16px',
        borderRadius:6,
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        cursor:'pointer'
    },
    option:{
        display:'flex',
        alignItems:'center',
        padding:'8px 12px',
        borderBottom:DASHBOARD_SETTINGS.DIVIDER_LINE,
        cursor:'pointer',
        '&:hover':{
            background:grey[200],
        }
    },
    paper:{
        borderRadius:12, marginTop:8
    }
}))
 
export default StatusButton;