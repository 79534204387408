import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { catalogs } from '../../../../texts/esp/catalogs';
import cx from 'classnames'
import SVGIcon from '../../../../components/Structure/DisplayData/SVGIcon';
import { useEffect } from 'react';

const RouteStatusCard = ({status}) => {

    const classes = useStyles()
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        if(status) setSelected(status)
        //console.log(route)
    }, [status])

    return ( 
        <div className={classes.root}>
            {/* <Typography variant='subtitle1'>Estatus</Typography> */}
            <div className={classes.list_container}>
                <div className={classes.vertical_line}/>
                <div style={{zIndex:5}}>
                    {catalogs.route_order_statuses.map(item=>{
                        return <div className={classes.element}>
                            <div className={cx({
                                [classes.icon_container]:true,
                                [classes.icon_container_selected]: item.value === selected
                            })}>
                                <div className={classes.icon}>
                                    <SVGIcon src={item.icon} width={24} height={24} color={item.value === selected ? 'white' : '#BABABA'}/>
                                </div>
                            </div>
                            <Typography variant='body2' style={{marginLeft:12}}>{item.label}</Typography>
                        </div>
                    })}
                </div>
            </div>
            
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
      
        
    },
    list_container:{
        position:'relative',
        //marginTop:24,

    },
    element:{
        display:'flex',
        alignItems:'center',
        marginBottom:16
    },
    icon_container:{
        width:42,
        height:42,
        borderRadius:'50%',
        border:'1px solid #ABABAB',
        background:'white',
        zIndex:2,
        position:'relative'
    },
    icon_container_selected:{
        background:theme.palette.primary.main,
        border:`1px solid ${theme.palette.primary.main}`,
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
    },
    vertical_line:{
        height:'100%',
        width:1,
        background:'#ABABAB',
        position:'absolute',
        top:0, left:20,
        zIndex:0
    }
}))
 
export default RouteStatusCard;