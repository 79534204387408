import React, { Fragment, useState } from 'react'
import {
    Card,
    CircularProgress,
    Collapse,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullname } from '../../../../shared/utility'
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../components/Actions/ActionBar'
import empty from '../../../../assets/empty.png'
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'
import ProductImage from '../../../../components/Structure/Images/ProductImage'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BatchesTable from './BatchesTable'
import inventory from '../../../../assets/icons/inventory.svg';
import RoundedButton from '../../../../components/Actions/RoundedButton'
import WarehouseMenu from './WarehouseMenu';

const SimpleTable = props => {

    const classes = tableStyles()
    const { header, content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, warehouse,
    inventory_actions, onGenerateReport, downloading, onChangeWarehouseMenu, warehouses} = props

    const [selected, setSelected] = useState(null)
    const [open, setOpen] = useState(false)

    const onSelected = (data) => {
        console.log(data)
        if(selected){
            //There is something
            if(JSON.stringify(selected) === JSON.stringify(selected)){
                console.log('Cerrar')
                setSelected(null)
            }else{
                console.log('Abrir')
                setSelected(data)
            }
        }else{
            console.log('Abrir')
            setSelected(data)
        }
        
    }
    return (
        <div>
            <div className={classes.toolbar}>
                
                <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                    <Grid item >
                    <WarehouseMenu
                            options={warehouses} 
                            selected={warehouse} 
                            onChange={onChangeWarehouseMenu}
                        />
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item>
                        <RoundedButton size='small' onClick={onGenerateReport} loading={downloading}  >Descargar inventario <Icon>file_download</Icon></RoundedButton>
                    </Grid>
                    <Grid item>
                        <SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
                    </Grid>
                    {onAddRegister ? <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid> : null}
                </Grid>
                    
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography className={classes.header} variant='body2' >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_user, first_name, fathers_last_name, mothers_last_name, email, user_type, id_invetory, image_web, image_mobile, sku} = item

                                        const last_name = onGetFullname (fathers_last_name, mothers_last_name)
                                        const fullname = onGetFullname(first_name, last_name)

                                        const open = selected?.product_id === item?.product_id

                                        return(
                                            <Fragment>
                                                <TableRow key={key.toString()} className={classes.row} >
                                                    <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}}>
                                                        <IconButton aria-label="expand row" size="small" onClick={() => onSelected(item)}>
                                                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                        </IconButton> 
                                                    </TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.product_id}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><ProductImage src={image_web || image_mobile} type='TABLE' id={`src-${item?.product_id}`} /></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.sku}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.product}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.sku}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.location}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.available ?? '0'}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.max_quantity ?? '0'}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2'>{item?.min_quantity ?? '0'}</DisplayText></TableCell>
                                                    <TableCell className={classes.cell} onClick={()=>onSelectedItem(item)} ><DisplayText variant='body2' style={{borderRadius:'0px 16px 16px 0px'}}>{item?.reorder_quantity ?? '0'}</DisplayText></TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, border:0 }} colSpan={13}>
                                                        <Collapse in={open} timeout="auto" unmountOnExit>
                                                            <div>
                                                                <BatchesTable warehouse={warehouse} product={item} inventory_actions={inventory_actions} />
                                                            </div>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            </Fragment>
                                            
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={inventory} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>No existen inventarios registrados</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable