import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"
import queryString from 'query-string'
import moment from "moment"



const useDeliveryTime = ({language, content, id}) => {

    //console.log( moment().format('Y'))

    const [filters, setFilters] = useState({
        event_year: parseInt(moment().format('Y')),
        holiday_year: parseInt(moment().format('Y'))
    })

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [selected, setSelected] = useState(null)

    const [special_events, setSpecialEvents] = useState([])
    const [holidays, setHolidays] = useState([])

    const [calendar_data, setCalendarData] = useState([])
    const [slot_time, setSlotTime] = useState(60)

    const [modals, setModals] = useState({
        add_special_event:false,
        add_holiday:false,
        delete_special_event:false,
        delete_holiday:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [filters.event_year])

    useEffect(() => {
        actions.onInitHolidays()
    }, [filters.holiday_year])

    useEffect(() => {
        actions.onInitCalendar()
    }, [])

    const onGetParams = () => {

        const params2send = {order_by:'start_time', order:0}
        let params = `?${queryString.stringify(params2send)}` 

        let filtersArgs = [{"field":"start_time","operator":">=","value":`${moment().year(filters.event_year).startOf('year').utc().format('YYYY-MM-DD HH:mm:ss')}`},
                            {"field":"start_time","operator":"<=","value":`${moment().year(filters.event_year).endOf('year').utc().format('YYYY-MM-DD HH:mm:ss')}`} ]
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const onGetParamsHolidays = () => {
    
        const params2send = {order_by:'date', order:0}
        let params = `?${queryString.stringify(params2send)}` 

        let filtersArgs = [{"field":"date","operator":">=","value":`${moment().year(filters.holiday_year).startOf('year').utc().format('YYYY-MM-DD HH:mm:ss')}`},
                            {"field":"date","operator":"<=","value":`${moment().year(filters.holiday_year).endOf('year').utc().format('YYYY-MM-DD HH:mm:ss')}`}]
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`warehouse/${id}/delivery/specialevent${params}`)   
                setSpecialEvents(req.data.data.warehouse_delivery_special_events)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onInitHolidays: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParamsHolidays()
                const req_holidays = await private_server.get(`warehouse/${id}/delivery/holiday${params}`)
                setHolidays(req_holidays.data.data.warehouse_delivery_holidays)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onInitCalendar: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`warehouse/${id}/delivery/timetable`)
                setCalendarData(req.data.data.warehouse_delivery_time_tables)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onUpdateSlots:async(slots2create, slots2delete) => {
            if(sending) return
            console.log('Actualizar slots')
            setSending(true)
            updateAuthorizationHeader(private_server)

            //try {
                if(slots2create && slots2create.length){
                    //console.log('add')
                    //console.log(slots2create)
                    Promise.all(slots2create.map(async (item) => {
                        //if(item.wholesale !== new_wholesale){
                            try{        
                                //console.log(item)                                
                                await private_server.post(`/warehouse/${id}/delivery/timetable`, item)
                                //console.log(new_el) 
                            }catch(error){
                                console.log(error)
                                setError(onGetErrorMessage(error))
                            }
                            //setLoading(false)
                        //}
                    }))
                    
                }
            /* } catch (error) {
                console.log(error)
            } */
            try {
                if(slots2delete && slots2delete.length){
                    console.log('delete')
                    for(let i=0; i<slots2delete.length; i++){
                        const data2send = slots2delete[i]
                        const _id = data2send.id
                        delete data2send.id
                        console.log(data2send)
                        await private_server.delete(`/warehouse/${id}/delivery/timetable/${_id}`, {params:data2send})
                    }
                }
            } catch (error) {
                console.log(error)
            }
            await actions.onInitCalendar()
            setSending(false)
        },
        onUpdateFilterStatus: (_key, _value) => setFilters({...filters, [_key]:_value}),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteSpecialEvent: async(item) =>{
            //console.log(item)
            const {id_warehouse_delivery_special_event} = item
            setSelected(id_warehouse_delivery_special_event)
            actions.onChangeModalStatus('delete_special_event', true)
        },
        onDeleteHoliday: async(item) =>{
            //console.log(item)
            const {id_warehouse_delivery_holiday} = item
            setSelected(id_warehouse_delivery_holiday)
            actions.onChangeModalStatus('delete_holiday', true)
        },
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        }
    }

    const system = {loading, sending, error}
    const view_data = { selected, special_events, filters, holidays, calendar_data, slot_time}

    return {system, modals, actions, view_data } 
}

export default useDeliveryTime