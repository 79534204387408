import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import { onGetParamsFromState } from './custom_functions'
import { request_patients } from './requests'
import { translations as translations_esp } from '../../../texts/esp/users-translations'
import { actionTypes } from '../../../store/actions'

const Patients = props => {

    const content = translations_esp
    const { history, user, patients_search, patients_table, onUpdateSearch, onUpdateTable } = props

    const [loading, setLoading] = useState(false)
    const [patients, setPatients] = useState([])
    //const [addModal, setAddModal] = useState(false)
    const [total, setTotal] = useState(10)
   
    //table
    const [tableFilter, setTableFilter] = useState({
        limit:10,
        offset:0,
        order:1,
        order_by:'id_user',
    })
    const [search, setSearch] = useState(null)
    

    useEffect(() => {
        const initModule = async() => {try {
            //console.log('pacientes')
            if(!user) return
            console.log(user)
            setLoading(true)
            const params = onGetParamsFromState(patients_table, patients_search)
            console.log(params)
            const _pacients = await request_patients(params)
            console.log(_pacients)
            setPatients(_pacients.users)
            setTotal(_pacients.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [patients_table])

    
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/users/${id_user}`)
    }
  
    const onChangeSearch = (val) => {
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    let can_create = false
    //if(user && (user.user_type_id === 1)) can_create = true

    return(
        <Page title={content.titles.patients} >
            <SimpleTable
                data={patients} 
                search={patients_search}
                tableFilter={patients_table}
                total={total}
                loading={loading}
                //onAddRegister={can_create ? () => setAddModal(true) : null}
                onChangeSearch={onUpdateSearch}
                onUpdateTableFilter={(data) => onUpdateTable(data)}
                onSelectedItem={onSelectedItem}
                content={content.tables.patients}/>
        </Page>
    )
}

const mapStateToProps = state => {
    return{
        user: state.user,
        patients_table: state.patients_table,
        patients_search: state.patients_search
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateTable: (patients_table) => dispatch({type: actionTypes.CHANGE_PATIENTS_TABLE, patients_table }),
        onUpdateSearch: (patients_search) => dispatch({type: actionTypes.CHANGE_PATIENTS_SEARCH, patients_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients)