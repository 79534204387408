import { mainServer } from '../../../variables/config'
import axios from 'axios'

//gets
export const request_cart = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/cart/${id}`)
    return response.data.data
}

//patchs
export const request_update_user = async(id,data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/users/${id}`, data)
    return response.data.data
}

export const request_update_lineitem = async( cart_id, lineitem_id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/lineitem/${cart_id}/${lineitem_id}`, data)
    return response.data.data
}

export const request_update_public_lineitem = async( cart_id, lineitem_id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/lineitem/public/${cart_id}/${lineitem_id}`, data)
    return response.data.data
}

//posts
export const request_create_doctor_translations = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/doctor/${id}/translations`, data)
    return response.data.data
}

//deletes
export const request_delete_lineitem = async( cart_id, lineitem_id ) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/lineitem/${cart_id}/${lineitem_id}`)
    return response.data.data
}

export const request_delete_public_lineitem = async( cart_id, lineitem_id ) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/lineitem/public/${cart_id}/${lineitem_id}`)
    return response.data.data
}
  