import product from '../../assets/icons/product.svg'

export const translations = {
    title:'Productos',
    table: {
        header:[
            {id:1, label:'ID'},
            {id:2, label:'Producto'},
            {id:3, label:'Visitado por:'},
            {id:4, label:'Fecha'},
        ],
        nodata:'No existen productos registrados',
        empty:product
    },
    modal: {
        title:'Agregar producto',
        subtitle:'Por favor ingresa los datos solicitados',
        button:'Guardar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Seleccione al menos una opción'
            },
            brand_id:{
                label:'Marca',
                helperText:'Seleccione al menos una opción'
            },
            short_description:{
                label:'Descripción corta',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            url:{
                label:'Url',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            details:{
                label:'Detalles',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            weight:{
                label:'Peso',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            height:{
                label:'Altura',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            width:{
                label:'Ancho',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            length:{
                label:'Largo',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sat_unit_key:{
                label:'Clave unitaria',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sat_product_key:{
                label:'Clave del producto',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            sku:{
                label:'SKU',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            barcode:{
                label:'Barcode',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
        formControl:{
            taxable:'Tiene impuestos',
            sale:'Oferta',
            requires_recipe:'Requiere receta',
            retains_recipe:'Retiene receta'
        }
    },
}
