import { mainServer, BASIC_AUTH_HEADER } from '../../variables/config';

export const request_login = async(data) => {
  mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
  const response = await mainServer.post(`/login`, data)
  return response.data.data
}

export const request_profile = async(data) => {
  const credentials = JSON.parse(localStorage.getItem('token_data'))
  mainServer.defaults.headers.common['Authorization'] = credentials.token
  const response = await mainServer.get(`/users/me`)
  return response.data.data
}