export const translations = {
  title: "Rol",
  table: {
    header: [
      { id: 1, label: "ID" },
      { id: 2, label: "Nombre" },
      { id: 3, label: "Ícono" },
      { id: 4, label: "Descripción" },
      { id: 5, label: "Color" },
      { id: 6, label: "Default" },
      { id: 7, label: "" },
    ],
    nodata: "No existen roles registrados",
  },

  modal: {
    add_title: "Crear Rol",
    edit_title: "Editar",
    subtitle: "Por favor ingresa los datos solicitados",
    button: "Agregar",
    edit_button: "Guardar",
    form: {
      name: {
        label: "Tipo",
        helperText:'Seleccione al menos una opción'
      },
      color: {
        label: "color",
        helperText: "Seleccione un color",
      },
      // defaul: {
      //   label: "default",
      //   helperText: "",
      // },
      description: {
        label: "description",
        helperText: "Este campo debe tener al menos 1 caracter y máximo 255",
      },
      

    },
  },
};

export const translationss = {
  title: "Permission",
  table: {
    header: [
      { id: 1, label: "Modulo" },
      { id: 2, label: "Acceso Completo" },
      { id: 3, label: "Creacion" },
      { id: 4, label: "Edición" },
      { id: 5, label: "Lectura" },
      { id: 6, label: "Borrado" },
      { id: 7, label: "Público" },
      { id: 8, label: "" },
    ],
    nodata: "No existen permisos registrados",
  },
    modal: {
      add_title: "Crear Permiso",
      edit_title: "Editar",
      subtitle: "Por favor ingresa los datos solicitados",
      button: "Agregar",
      edit_button: "Guardar",
      form: {
        name: {
          label: "Tipo",
          helperText:'Seleccione al menos una opción'
        },        
        
  
      },
      formControl:{
        fAcces:'Acceso completo',
        
    }
    },
  };