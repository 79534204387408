import React from 'react'
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../../../components/Texts/DisplayText'
import SearchBar from '../../../../../../components/Actions/SearchBar/SearchBar'
import empty from '../../../../../../assets/empty.png'
import { tableStyles } from '../../../../../../styles/tableStyles'
import { onGetDateFormat } from '../../../../../../shared/utility'
import UserAvatarName from '../../../../../../components/Structure/DisplayData/UserAvatarName'
import NewActionBar from '../../../../../../components/Actions/NewActionBar'
import DisplayIconData from '../../../../../../components/Structure/DisplayData/DisplayIconData'



const SimpleTable = props => {

    const classes = tableStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, onDeleteItem, loading } = props

    const header = content.header

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <SearchBar search={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} 
                                label={content.search_bar_label}/>
                            </Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>          
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography style={{fontWeight:400}} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const { id_warehouse, name, state, contact_name, contact_email, contact_phone, street, ext, int, map_url } = item

                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{id_warehouse}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'  >{name}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{state}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2' >{contact_name}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayIconData svg={content.email} data={contact_email} /></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayIconData svg={content.phone} data={contact_phone} /></TableCell>
                                                <TableCell className={classes.cell} ><Button href={map_url} target='_blank' className={classes.map_button} >Ver mapa</Button></TableCell>
                                                {/* <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} padding='checkbox'>
                                                    <IconButton className={classes.delete_button} onClick={() => onDeleteItem(item)}><Icon>delete</Icon></IconButton>
                                                </TableCell> */}
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>               
            ) : null}          
        </div>
    );
}

export default SimpleTable

const EnabledDisplayer = ({enabled}) =>{
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{width:8, height:8, marginRight:8, borderRadius:'50%', background:enabled ? '#96AE76' : '#D95D71'}}></div>
            <DisplayText>{enabled ? 'Sí' : 'No'}</DisplayText>
        </div>
    )
}