import {
    CircularProgress,
    Grid,
    Icon,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import { useEffect, useState } from 'react'
import RoundedButton from '../../../../../../components/Actions/RoundedButton'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import catalogs from '../../../../../../texts/esp/catalogs'
import InputForm from '../../../../../../components/Forms/InputForm'
import CustomSelectForm from '../../../components/CustomSelectForm'
import { ROUTE_STATUSES } from '../../../../../../variables/config'


const CancelCard = ({onSelectedItem, actions, form, status }) => {

    const classes = useStyles()


    return(
        <SimpleCard>
            <Typography variant='subtitle1'>Cancelación</Typography>
            <div className={classes.root}>
                <Grid container spacing={5}>
                    <Grid item md={5} xs={12}>
                        <Typography>Motivo</Typography>
                        {/* <Select  fullWidth select variant='outlined'
                            onChange={(e) => setSearch(e.currentTarget.value)}    
                            IconComponent={() => <Icon style={{marginRight:32}}>keyboard_arrow_down</Icon>}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            classes={{icon:classes.select_icon}}
                            defaultValue={catalogs.route_order_issues[0].value}
                        >
                            {catalogs.route_order_issues.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    <div style={{textAlign:'center', fontWeight:600}}>{option.label}</div>
                                </MenuItem>
                            ))}
                        </Select> */}
                        <CustomSelectForm data={form.cancellation_motive_id} onChange={actions.onChangeCancelForm} />
                    </Grid>
                    <Grid item md={7} xs={12}>
                        <Typography>Detalles</Typography>
                        <InputForm data={form.cancellation_motive_details} onChange={actions.onChangeCancelForm} />
                    </Grid>
                </Grid>
                <div className={classes.bottom}>
                    <RoundedButton color='secondary_dark' onClick={actions.onCancelRoute} 
                    disabled={form.cancellation_motive_details.value === ''}>
                        Cancelar ruta
                    </RoundedButton>
                </div> 
            </div>
        </SimpleCard>
    )
}

export default CancelCard

const useStyles = makeStyles(theme => ({
    root:{
        margin:'40px 0px 20px'
    },
    container:{
        marginTop:32,
        maxHeight:300,
        overflowY:'auto',
        overflowX:'hidden'
    },
    list_item:{
        padding:'12px 4px',
        borderBottom:`1px solid ${grey[300]}`,
        cursor:'pointer',
        '&:hover':{
            background:grey[100]
        }
    },
    bottom:{
        marginTop:60,
        textAlign:'center'
    },
    select_icon:{
        display:'none',
    },
 
}))