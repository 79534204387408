import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    link: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'link',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    category_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'category_id',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: catalogs.order_levels,
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    enabled: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config: {
            id: 'enabled',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'select',
        }
    },
    text_1: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'text_1',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    text_2: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'text_2',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    text_3: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'text_3',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    text_4: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'text_4',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    
}