import React, {useState, useEffect} from 'react';
import { isFormValid } from '../../../../components/Forms/customFunctions';
import InputForm from '../../../../components/Forms/InputForm';
import { onSetErrorsToForm } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';

const QuantityButton = ({value, onChange}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [isValid, setIsValid] = useState(false)

    //let input_form = {...formData.quantity}
    useEffect(() => {
        if(value){
            let _form = {...form}
            _form.quantity.value = value
            setForm(_form)
        }
    }, [])
    

    const onInnerChange = (data) => { 
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        setIsValid(isFormValid(temp))
        /* if(isFormValid(form)){
            onChange(data.value)
        }else{
            onSetErrorsToForm(form, ['quantity'])
        } */
        
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            if(!isValid){onSetErrorsToForm(form, ['quantity'])
                return
            }      
            onChange(form.quantity.value)
        }
    }

    return ( 
        <div style={{width:100}}>
            <InputForm data={form.quantity} onChange={onInnerChange} onKeyPress={handleKeyPress} />
        </div>
     );
}
 
export default QuantityButton;

const formData = {
    quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'quantity',
            type: 'text',
            fullWidth: true,
            label: 'Cantidad',
            helperText: 'Cantidad no válida',
        },
        rules: {
            type: 'quantity',
        }
    },
}