import { Grid, FormControlLabel, Checkbox, Button, Icon } from '@mui/material'
import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { actionTypes } from '../../../store/actions'
import Page from '../../../components/Structure/Page/Page'
import ToolbarTable from '../../../components/Structure/Navigation/ToolbarTable'
import { translations as translations_esp } from '../../../texts/esp/warehouse-translations'
import MainCard from './components/MainCard'
import Information from './views/Info/Information'
import useWarehouseView from './useWarehouseView'
import Inventory from './views/Inventory/Inventory'
import Users from './views/Users/Users'
import LocalTime from './views/LocalTime/LocalTime'
import catalogs from '../../../texts/esp/catalogs'
import DeliveryTime from './views/DeliveryTime/DeliveryTime'



const WarehouseView = ({match, history, onUpdateSending, onUpdateSuccess, onUpdateError}) => {

    const content = translations_esp
    const id = match.params.id
    const {system, modals, actions, view_data} = useWarehouseView({history, match, content, onUpdateError, onUpdateSuccess})

    /* //general
    const [loading, setLoading] = useState(false)
    const [success, setSucess] = useState(null)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [warehouse, setWarehouse] = useState(null)
    const [zip_number, setZipNumber] = useState(null)

    //checks
    const [store, setStore] = useState(false)
    const [pickup, setPickup] = useState(false)
    const [delivery, setDelivery] = useState(false)
   

    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const _warehouse = await request_warehouse(id)
            setWarehouse(_warehouse.warehouse)
            let _form = onInitEmptyForm(form, content.edit_card.form)
            setForm(_form)
            _form = onInitForm(form, _warehouse.warehouse_data)
            setZipNumber(_warehouse.warehouse_data.zip_code)
            const zipcode = await request_zipinfo(_warehouse.warehouse_data.zip_code.toString())
            //console.log(zipcode.zip_information.zip_code)
            const suburbsCatalogs = onGetCatalogs(zipcode.zip_information.sub_urbs, 'id_suburb', 'name') 
            //console.log(suburbsCatalogs)
            _form.suburb_id.options = suburbsCatalogs
            const states = await request_states()
            const statesCatalogs = onGetCatalogs(states.states, 'id_state', 'name')
            const municipalities = await request_municipalities()
            const municipalitiesCatalogs = onGetCatalogs(municipalities.municipalities, 'id_municipality', 'name')
            //console.log(municipalitiesCatalogs)
            _form.state_id.options = statesCatalogs
            _form.municipality_id.options = municipalitiesCatalogs
            setForm(_form)
            setStore(Boolean(_warehouse.warehouse_data.store))
            setPickup(Boolean(_warehouse.warehouse_data.pickup))
            setDelivery(Boolean(_warehouse.warehouse_data.delivery))
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }}
        initModule()
    }, [])

    useEffect(() => {
        const onSearchZipAttributes = async(value) => {
            if(value === "" || value === null || value === undefined || value.length < 5) return
            try {
                let zip_info = await request_zipinfo(value)
                zip_info = zip_info.zip_information
                if(zip_info){
                    const {value, id_zip_code, municipality, municipality_id, state, state_id} = zip_info.zip_code
                    let _form = {...form}
                    _form.state_id.value = state_id
                    _form.state_id.isValid = true
                    _form.state_id.options = [{value:state_id, label:state}]
                    _form.municipality_id.value = municipality_id
                    _form.municipality_id.isValid = true
                    _form.municipality_id.options = [{value:municipality_id, label:municipality}]
                    _form.suburb_id.value = ''
                    _form.suburb_id.options = onGetCatalogs(zip_info.sub_urbs,'id_suburb','name')
                    _form.zip_code.value = value
                    _form.zip_code.isValid = true
                    _form.zip_code_id.value = id_zip_code
                    _form.zip_code_id.isValid = true
                    setForm(_form)
                }
            } catch (error) {
                //console.log(error)
            }
        } 
        onSearchZipAttributes(zip_number)
    }, [zip_number])

  
    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
        if(id === 'zip_code') setZipNumber(data.value)
    }

    
    const onUpdateCompleted = async() => {
        const _warehouse = await request_warehouse(id)
        setWarehouse(_warehouse.warehouse_data)
    }


    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, warehouse)
        data2send.store = store ? 1 : 0
        data2send.pickup = pickup ? 1 : 0
        data2send.delivery = delivery ? 1 : 0
        console.log(data2send)
        
        setSending(true)
        onUpdateSending(true)
        try {
            await request_update_warehouse(id, data2send);
            onUpdateCompleted()
            setSending(false)
            onUpdateSending(false)
            onUpdateSuccess(content.success_msg)
            //setSucess('Acción exitosa')
        } catch (error) {
            console.log(error)
            //setError('Hubo un error')
            onUpdateError(onGetErrorMessage(error))
            setSending(false)
            onUpdateSending(false)
        }
    } */

    //views
    const menu = content.menu
    const [view, setView] = useState(1)

    const onChangeView = (id) => {
        setView(id)
    }

    let contentView = null

    switch(view){
        case 1:
            contentView = <Information form={view_data.form} content={content.views.info} onChange={actions.onChangeForm} sending={system.sending} 
                            onSubmit={actions.onSubmit}  />
            break
        case 2:
            contentView = <Inventory id={id} content={content.views.inventory} history={history}/>
            break
        case 3:
            contentView = <Users id={id} content={content.views.users} history={history}/>
            break
        case 4:
            contentView = <LocalTime id={id} catalogs={catalogs} content={content.views.schedules} history={history}/>
            break
        case 5:
            contentView = <DeliveryTime id={id} catalogs={catalogs} content={content.views.schedules} history={history}/>
            break
        default:
            break
    }


    return(
        <Page title={content.title} loading={system.loading} goback success={system.success} onCloseSuccess={actions.onClearSuccess}
        error={system.error} onCloseErrorModal={actions.onClearError}>
            <Grid container spacing={3} justifyContent='flex-end' >
                <Grid item>
                    <Button  style={{background:'#543081', color:'white', boxShadow:'0px 6px 10px rgba(65, 65, 65, 0.15)', borderRadius:30, padding:'10px 30px', textTransform:'none'}} 
                    startIcon={<Icon>delete</Icon>}>Borrar</Button>
                </Grid>
                <Grid item xs={12}>
                    <MainCard data={view_data.warehouse} content={content.main_card} />
                </Grid>
                <Grid item xs={12}>
                    <div style={{margin:'40px 0px 40px'}}>
                        <ToolbarTable selected={view} menuOptions={menu} onChange={onChangeView} />
                    </div>
                    {contentView}
                </Grid>
            </Grid>
        </Page>
    )

}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateSending: (sending) => dispatch({type: actionTypes.SYS_UPDATE_SENDING, sending }),
        onUpdateSuccess: (success) => dispatch({type: actionTypes.SYS_UPDATE_SUCCESS, success }),
        onUpdateError: (error) => dispatch({type: actionTypes.SYS_UPDATE_ERROR, error }),
    }
}

export default connect(null, mapDispatchToProps)(WarehouseView) 

const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_name',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    contact_email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_email',
            type: 'email',
            fullWidth: true,
        },
        rules: {
            type: 'email',
        }
    },
    contact_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'contact_phone',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    ext: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ext',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    int: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'int',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    additional_indications: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'additional_indications',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    zip_code: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'zip',
        }
    },
    zip_code_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip_code_id',
            type: 'text',
            fullWidth: true,
        },
    },
    /* street: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'street',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
    suburb_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'suburb_id',
            type: 'select',
            fullWidth: true,
        },
        rules: {
            type:'select',
        }
    },
    municipality_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'municipality_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    state_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'state_id',
            type: 'select',
            fullWidth: true,
            disabled:true,
        },
        rules: {
            type: 'select',
        }
    },
    country_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        options:[{value:1, label:'México'}],
        config: {
            id: 'country_id',
            type: 'select',
            fullWidth: true,
            disabled:true
        },
        rules: {
            type: 'select',
        }
    },
    map_url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'map_url',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    latitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'latitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    longitude: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'longitude',
            type: 'text',
            fullWidth: true,
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
}