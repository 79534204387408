import { Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import moment from "moment"
import PillStatus from "../../../../../../components/Structure/DisplayData/PillStatus"
import { onGetDateFormat } from '../../../../../../shared/utility'



const Holiday = ({date, name, onDelete}) => {

    const classes = useStyles()

    


    return (
        <div className={classes.root} >
            <Grid container alignItems="center" spacing={1} >
                <Grid item>
                    <div className={classes.date_container} >
                        {/* <Typography className={classes.day} align='center'>{onGetDateFormat(date, 'ddd')}</Typography>
                        <Typography variant="body2" color='primary' >{onGetDateFormat(date, 'D')}</Typography> */}
                        <Typography className={classes.day} align='center'>{moment(date).format('ddd')}</Typography>
                        <Typography variant="body2" color='primary' >{moment(date).format('D')}</Typography>
                    </div>
                </Grid>
                <Grid item xs>
                    <div className={classes.name_container} >
                        <Typography variant="body2" style={{color:'#70708E'}} >{name}</Typography>
                    </div>
                </Grid>
                <Grid item>
                    <IconButton className={classes.delete_button} onClick={onDelete} size="large"><Icon>delete</Icon></IconButton>
                </Grid>
            </Grid>
        </div>
    );
}

export default Holiday

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        borderRadius:15,
        padding:'8px 48px',
        marginBottom:4
    },
    delete_button:{
        boxShadow:'0px 0px 2.09633px rgba(120, 120, 120, 0.43)',
        //color:theme.palette.secondary.main
        color:'#D95D71',
        padding:8
    },
    date_container:{
        border:'1px solid #DDDDDD',
        borderRadius:15,
        //padding:'8px 16px',
        textAlign:'center',
        width:70,
        padding:'10px 0px'
    },
    name_container:{
        padding:'4px 20px',
        borderLeft:'1px solid #DAD7DE',
        marginLeft:20
    },
    day:{
        color:'#70708E',
        fontSize:'0.875rem',
        fontWeight:400,
        textTransform:'capitalize'
    }
    

}))