import { CircularProgress, Fade, Grid, Grow } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import { catalogs } from '../../../texts/esp/catalogs'
import cx from 'classnames'
import { blueGrey, green, red } from '@mui/material/colors'

const useStyles = makeStyles(theme => ({
    root:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32,
        cursor:'pointer'
    },
    loading:{
        padding:'8px',
        borderRadius:32,
    },
    active:{
        background:green[700]
    },
    inactive:{
        background:red[700]
    },
    progress:{
        color:'white'
    }
    
}))


const AvailableStatus = ({status, onClick, loading}) => {

    const classes = useStyles()

    return(
        <div>
            {!loading ? <Grow in timeout={100}>
            <div onClick={onClick} className={cx({
                [classes.root]:true,
                [classes.active]:status === 1,
                [classes.inactive]:status === 0
            })}>
                <Grid container alignItems='center'>
                    <Grid item>{status === 1 ? 'Activo' : 'Inactivo'}</Grid>
                </Grid>
            </div>
            </Grow> : (
                <Fade in timeout={100}>
                    <div onClick={onClick} className={cx({
                        [classes.loading]:true,
                        [classes.active]:status === 1,
                        [classes.inactive]:status === 0
                    })}>
                        <Grid container justifyContent='center'>
                            <Grid item> 
                                <CircularProgress size={16} className={classes.progress}/>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            )}
        </div>
    )
}

export default AvailableStatus

{/* <div onClick={onClick} className={cx({
            [classes.root]:true,
            [classes.active]:status === 1,
            [classes.inactive]:status === 0
        })}>
            <Grid container alignItems='center'>
                <Grid item>{status === 1 ? 'Activo' : 'Inactivo'}</Grid>
                <Grid item>{loading ? (<CircularProgress style={{ width: 14, height: 14, marginLeft: 8, color: 'white' }} />) : null}</Grid>
            </Grid> */}