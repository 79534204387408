import { blueGrey, red } from "@mui/material/colors";
import axios from 'axios'


/*--------- CONFIG ---------*/

export const mainServer = axios.create({
    baseURL: "https://devapi.celu.co",
    headers: {'Content-Type': 'application/json'},
})
export const config_data = {
    S3_SERVER_URL: 'https://devassets.celu.co/'
}

export const USERNAME_API = 'trionix'
export const PASSWORD_API = 'qnJVws4V6amWvZS6f25DJC7cjmLpQzBz'
export const BASIC_AUTH_HEADER = `Basic dHJpb25peDpxbkpWd3M0VjZhbVd2WlM2ZjI1REpDN2NqbUxwUXpCeg==`

export const DASHBOARD_SETTINGS = {
    BOXSHADOW:'0px 12px 18px rgba(0, 0, 0, 0.1)',
    BOXSHADOW_PRIMARY:'0px 6px 14px rgba(202, 203, 251, 0.87)',
    DIVIDER_LINE:'1px solid #B3B3B3',
    COUNTRY_CODE:1,
    CURRENCY_ID:2,
    TOPBAR:{
        TRANSPARENT_MODE:'TRANSPARENT_MODE',
        WHITE_MODE:'WHITE_MODE',
        HEIGHT:72
    },
    PROFILE_DRAWER:{
        WIDTH:220,
        //MWIDTH:200,
        //SWIDTH:260
    }
}
//new
export const private_server = axios.create({
    baseURL: "https://devapi.celu.co",
    headers: {
        'Content-Type': 'application/json',
    },
})

export const public_server = axios.create({
    baseURL: "https://devapi.celu.co",
    headers: {
        'Content-Type': 'application/json',
        'Authorization':BASIC_AUTH_HEADER
    },
})



/*-----------THEME------------*/

const primary_color = '#2A368B'
const secondary_color = '#0291ff'

export const palette = {
    layout:{
        primary:primary_color,
        secondary:secondary_color,
    },
    texts:{
        primary:primary_color,
        secondary:secondary_color,
        white:'#FFF',
        bluegrey_light:blueGrey[300],
        bluegrey:blueGrey[500],
        bluegrey_dark:blueGrey[700],
        bluegrey_super_dark:blueGrey[900],
        red:red[700]
    }
}


/*-------- GENERAL -----------*/

export const USER_TYPES = {
    ADMIN:1,
    DOCTOR:2, 
    PATIENT:3,
    PHARMACY_ASSISTANT:4,
    EMPLOYEE:5
}

export const ORDER_STATUS_TYPES = {
    PENDING:1,
    CONFIRMED:2,
    PREPARING:3,
    SENDING:4,
    DELIVERED:5,
    COMPLETED:6,
    CANCELLED:7
}

export const PAYMENT_STATUSES = {
    PENDING:1,
    DECLINED:2,
    EXPIRED:3,
    PAID:4,
    REFUNDED:5,
    PARTIALLY_REFUNDED:6,
    CHARGED_BACK:7,
    PREAUTHORIZED:8,
    VOIDED:9
}

export const ROUTE_STATUSES = {
    PENDING:1,
    ASSIGNED:2,
    PREPARING:3,
    READY:4,
    IN_TRANSIT:5,
    COMPLETED:6,
    CANCELLED:7
}

export const PAYMENT_METHODS = {
    CARD:1,
    PAYPAL:2,
    MERCADO_PAGO:3,
    CASH:4,
    TPV:5
}

export const nodata = '-'