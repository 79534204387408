
import React from 'react'
import { TextField, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { grey, red } from '@mui/material/colors'
import Autocomplete from '@mui/material/Autocomplete';
import DisplayText from '../Texts/DisplayText';


const CssTextField = withStyles(theme => ({
  root: {
    paddingTop: 6,
    '& input': {
      color: grey[900],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `16px`,
      },
    },
  },
}))(TextField);

const InputSelect = props => {

  ///////////////////////////////////
  const { data, onChange, onInputChange, inputValue, onClose, color, disabled} = props //onSearch
  const { isValid, isVisited, value, options, rules } = data;
  const { id, label, placeholder, helper } = data.config;

  ///////////////////  STATE //////////////////////

  //////////////  FUNCTIONS  /////////////////

  const onInnerChange = (e, item) => {
    let temp = { ...data }
    if (item) {
      temp.value = item.value
      temp.isValid = true
    } else {
      temp.value = null
      temp.isValid = rules ? false : true
    }
    temp.isVisited = true
    onChange(temp)
  }

  ///////////////////// INTERNAL VARIABLES  /////////////////////////////
  let selectedOptions = []
  let selectedValue = null
  if (data) {
    if (options) selectedOptions = options
    if (value !== null && value !== undefined) {
      const singleEl = options.find(el => el.value === value)
      selectedValue = singleEl ? singleEl : selectedValue
    }
  }



  let inputlabel = null;
  if (label) inputlabel = <DisplayText variant='subtitle2' color={color ? color : (!isValid && isVisited ? 'error' : 'inherit')}>{label}</DisplayText>

  return (
    <div>
      {inputlabel}
      <Autocomplete
        disabled={data.disabled || disabled}
        value={selectedValue}
        options={selectedOptions}
        getOptionLabel={option => option.label}
        renderInput={params => (<CssTextField {...params} variant='outlined' error={!isValid && isVisited} placeholder={placeholder}
          helperText={(!isValid && isVisited) ? helper : null} />)}
        onChange={onInnerChange}
        onClose={onClose ? () => onClose(data) : undefined}
        inputValue={inputValue !== undefined ? inputValue : undefined}
        onInputChange={onInputChange}
        noOptionsText={<DisplayText>Sin opciones </DisplayText>}
        renderOption={(option, state) =>
          <div>
            <DisplayText variant={state.selected ? 'subtitle1' : 'body1'}>{option.label}</DisplayText>
          </div>
        } />
    </div>

  )
}

export default InputSelect