//es
import orders_es from './esp/orders-translations'
import users_es from './esp/users-translations'
import user_es from './esp/user-translations'
import catalogs_es from './esp/catalogs'
import discounts_es from './esp/discounts-translations'
import routes_es from './esp/routes-translations'


const system_translations = {
    es:{
        //errors: front_end_errors_es,
        //general:general_es,
        catalogs:catalogs_es,
        layouts:{
            //signin: sigin_layout_es,
            //signup: sigup_layout_es
        },
        views:{
            orders:orders_es,
            users:users_es,
            user:user_es,
            discounts:discounts_es,
            routes:routes_es
        },
        modals:{

        },
    },
    eng:{
        
    }
}

export default system_translations