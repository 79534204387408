import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server, public_server } from "../../../../variables/config"
import queryString from 'query-string'


const useMenu = ({language, content, menu_search}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'name'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        /* let filtersArgs = []

        if(menu_search){
            filtersArgs.push({"field":"delivery_man","operator":"LIKE","value":`%${routes_search}%`})
        }
            
        if(filtersArgs.length){
            let finalArgs = {"AND":filtersArgs}
            //filtersArgs = encodeURIComponent(JSON.stringify(filtersArgs))
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalArgs))}`
        } */
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(public_server)
                const params = onGetParams()
                const req = await public_server.get(`menuelement/list` )
                console.log(req)
                setData(req.data.data.menu_elements)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {id_menu_element} = item
            setSelected(id_menu_element)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        },
        onChangeEnabled: async(item)=>{
            const {id_menu_element, enabled} = item
            const status = enabled === 0 ? 1 : 0
            try{
                //setLoading(true)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`menuelement/${id_menu_element}`, {enabled:status} )
                actions.onInitModule()
            }catch(error){
                console.log(error)
                //onUpdateError(onGetErrorMessage(error))
            }
            //setLoading(false)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useMenu