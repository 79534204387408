import { Grid, Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import ContainedButton from '../../../../components/Actions/ContainedButton';
import DisplayText from '../../../../components/Texts/DisplayText';
import SimpleModal from '../../../../components/Overlays/SimpleModal';
import { useState } from 'react';
import useAddPermission from './useAddPermission';
import SelectFormLabeled from '../../../../components/Forms/SelectFormLabeled';

const useStyles = makeStyles((theme) => ({
	permissions_container: {
		padding: '0px 25px',
		border: '1px solid #919191',
		marginTop: 34,
		borderRadius: 5,
	},
	permission_row: {
		borderBottom: '1px solid #EAEAEA',
		minHeight: 66,
	},
}));

const AddPermissionModal = ({ open, onClose, onUpdateCompleted, content, selected_role_id, history }) => {
	let { system, form, actions } = useAddPermission({ content, onClose, onUpdateCompleted, selected_role_id, open });

	const classes = useStyles();

	const initial_state = {
		full_access: 0,
		create: 0,
		update: 0,
		read: 0,
		delete: 0,
		public: 0,
	};

	const [stateChecks, setStateChecks] = useState(initial_state);

	const onCheck = (key) => {
		let temp = { ...stateChecks };

		if (key === 'full_access') {
			if (stateChecks.full_access === 0)
				temp = {
					full_access: 1,
					create: 1,
					update: 1,
					read: 1,
					delete: 1,
					public: 1,
				};
			else
				temp = {
					full_access: 0,
					create: 0,
					update: 0,
					read: 0,
					delete: 0,
					public: 0,
				};
		}
		else{
			if (temp[key] === 0) temp[key] = 1;
			else temp[key] = 0;
		}

		setStateChecks(temp);
	};

	return (
		<SimpleModal open={open} onClose={onClose}>
			<DisplayText variant='h6'>{content.add_title}</DisplayText>

			<div style={{ padding: '30px 0px 24px' }}>
				<Grid container>
					<Grid item xs={12}>
						<SelectFormLabeled data={form.module_id} onChange={actions.onChangeForm} />
					</Grid>
					<Grid item container alignItems='center' xs={12} className={classes.permissions_container}>
						{content.checks.map((item) => (
							<Grid item xs={12} container alignItems='center' key={item.key} className={classes.permission_row}>
								<Grid item xs={11}>
									<DisplayText className={classes.optDiv}>{item.label}</DisplayText>
								</Grid>
								<Grid item xs={1}>
									<FormControlLabel control={<Checkbox checked={stateChecks[item.key] === 1} onChange={() => onCheck(item.key)} />} />
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</div>
			<div style={{ width: 162, margin: '0px auto' }}>
				<ContainedButton color='primary' onClick={() => actions.onSubmit(stateChecks)} loading={system.loading}>
					{content.button}
				</ContainedButton>
			</div>
			<DisplayText color='error' align='center'>
				{system.error}
			</DisplayText>
		</SimpleModal>
	);
};

export default AddPermissionModal;
