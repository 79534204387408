import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server, public_server } from "../../../../../variables/config"
import queryString from 'query-string'



const useRelatedProducts = ({history, match}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'product_id'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [search, tableFilter])

    const onGetParams = () =>{
        const {limit, offset, order_by, order} = tableFilter
        const params = {limit:limit, offset:offset*limit, order_by:order_by, order:order, currency_id:2}
        let final_params = `?${queryString.stringify(params)}`

        if(search){
            let filter = [{"field":"search_string","operator":"LIKE","value":`%${search}%`}]
            filter = encodeURIComponent(JSON.stringify(filter))
            final_params = `?${queryString.stringify(params)}&filter=${filter}` 
        }

        return final_params
        
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(public_server)
                const params = onGetParams()
                let req = await public_server.get(`relatedproduct/product/${id}/extended${params}`)
                //console.log(req)
                setData(req.data.data.related_product_data)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter)
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        /* onSelectItem: (item) => {
            const {id_medication} = item
            history.push(`/medications/${id_medication}`)
        }, */
        onDeleteItem: (item) =>{
            const {id_related_product} = item
            setSelected(id_related_product)
            actions.onChangeModalStatus('delete', true)
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useRelatedProducts