import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Page from '../../../components/Structure/Page/Page';
import SimpleTable from './components/SimpleTable';
import AddRouteModal from './modals/AddRouteModal/AddRouteModal';
import useRoutesView from './useRoutesView';
import system_translations from '../../../texts/system_translations'
import { actionTypes } from '../../../store/actions';

const RoutesView = ({user, history, language, routes_table, routes_search, onUpdateSearch, onUpdateTable, routes_filters, onUpdateFilters}) => {

    const content = system_translations[language].views.routes
    const catalogs = system_translations[language].catalogs
    const {system, view_data, actions,modals} = useRoutesView({user, routes_table, routes_search, onUpdateFilters, routes_filters})

    return ( 
        <Page title={content.title}>
            <AddRouteModal open={modals.add_register} onSubmit={actions.onSubmit} actions={actions} user={user}
                onClose={() => actions.onUpdateModalStatus('add_register', false)} history={history} />
            <SimpleTable
                content={content.table}
                loading={system.loading || system.sending}
                data={view_data.routes} 
                filter={view_data.filter}
                tableFilter={routes_table}
                total={view_data.total} 
                onSelectedItem={(item) => history.push(`/routes/${item.id_route}`)}
                onAddRegister={() => actions.onUpdateModalStatus('add_register', true)}
                onUpdateTableFilter={onUpdateTable}
                search={routes_search}
                onChangeSearch={onUpdateSearch}
                onChangeFilter={actions.onChangeFilter}
                catalogs={catalogs}
                warehouses={view_data.warehouses}
                onUpdateFilterStatus={actions.onUpdateFilterStatus}
                filters={routes_filters} />
        </Page>
        
     );
}

const mapStateToProps = (state) => {
    return {
        user:state.user,
        language:state.language,
        routes_table: state.routes_table,
        routes_search: state.routes_search,
        routes_filters: state.routes_filters
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onUpdateTable: (routes_table) => dispatch({type: actionTypes.CHANGE_ROUTES_TABLE, routes_table }),
        onUpdateSearch: (routes_search) => dispatch({type: actionTypes.CHANGE_ROUTES_SEARCH, routes_search }),
        onUpdateFilters : (routes_filters) => dispatch({type: actionTypes.CHANGE_ROUTES_FILTERS, routes_filters }),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RoutesView);