import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react'
import SimpleCard from '../../../../components/Structure/Cards/SimpleCard'
import wareimage from '../../../../assets/warehouse.png'
import DisplayText from '../../../../components/Texts/DisplayText'
import { onGetFullAddress } from '../../../../shared/utility'
import MapView from './MapView.jsx'
import DisplayIconData from '../../../../components/Structure/DisplayData/DisplayIconData'
import InputForm from '../../../../components/Forms/InputForm'
import { nodata } from '../../../../variables/config'
import GoogleMapSection from './GoogleMapSection'

const MainCard = ({data, content}) => {

    const classes = useStyles()

    console.log(data)

    const address_info = {
        street: data ? data.street : null, 
        suburb: data ? data.suburb : null, 
        municipality: data ? data.municipality : null, 
        state: data ? data.state : null
    }

    return(
        <div className={classes.main_container}>
        <SimpleCard style={{padding:0}} >
            <Grid container spacing={3}>
                <Grid item md={7} xs={12}>
                    <div className={classes.root}>
                        <Grid container style={{height:'100%'}} >
                            <Grid item xs={12} md={6}>
                                <div className={classes.center_container}>
                                    <div style={{textAlign:'center'}} >
                                        <div className={classes.img_container}>
                                            <img src={content.img} alt='' className={classes.img} />
                                        </div>
                                        <DisplayText>{data ? data.name : nodata}</DisplayText>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className={classes.center_container}>
                                    <div>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <DisplayIconData data={data ? data.contact_name : nodata} icon='person' variant='outlined' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DisplayIconData data={data ? data.contact_email : nodata} icon='email' variant='outlined' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DisplayIconData data={data ? data.contact_phone : nodata} icon='phone' variant='outlined' />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <DisplayIconData data={data ? onGetFullAddress(address_info) : nodata} icon='pin_drop' variant='outlined' />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>    
                </Grid>
                {/* <Grid item md={5} xs={12}>
                    <div className={classes.map_root}>
                        <div style={{position:'relative'}}>
                            <div className={classes.map_mask} />
                            <Button className={classes.map_button} href={data ? data.map_url : '/' } target='_blank' >{content.map_button}</Button>
                            <img src={content.map} className={classes.map} />
                        </div>
                    </div>
                </Grid> */}
                <Grid item xs={12} md={5}>
                    <div className={classes.map_root}>
                        {/* <MapView data={data} /> */}
                        <GoogleMapSection address={data} />
                        {/* <Button className={classes.map_button} href={data ? data.map_url : '/' } target='_blank' >{content.map_button}</Button> */}
                    </div>
                </Grid>
            </Grid>
        </SimpleCard>
        </div>
    )
}

export default MainCard

const useStyles = makeStyles(theme => ({
    main_container:{
        //height:400,
        //background:'red'
    },
    root:{
        //borderRight:'5px solid rgba(222, 222, 222, 0.17)',
        padding:'0px 60px',
        //background:'red',
        height:'100%',
        [theme.breakpoints.down('lg')]:{
            padding:'20px 60px',
            height:'auto'
        }
    },
    img:{
        //width:80,
        //marginBottom:40
        width:'100%',
        height:'100%',
        //objectFit:'contain'
    },
    img_container:{
        marginBottom:20,
        margin:'auto',
        borderRadius:'50%',
        //background:'#F7F8FC',
        width:170,
        height:170,
        padding:40,
        boxSizing:'border-box',
        //background:theme.palette.primary.light
        background:'#F4F5FE'
    },
    center_container:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        height:'100%',
        //background:'red'
        //textAlign:'center'
    },
    map_root:{
        padding:'20px 60px',
        position:'relative',
        borderLeft:'5px solid rgba(222, 222, 222, 0.17)',
        
        //height:'100%'
        [theme.breakpoints.down('lg')]:{
            height:'100%'
        }
    },
    map_mask:{
        position:'absolute',
        height:'100%',
        width:'100%',
        background:'rgba(0, 0, 0, 0.37)',
        borderRadius:12.3
    },
    map:{
        width:'100%',
        //height:'100%',
        height:280,
        objectFit:'cover',
        borderRadius:12.3
        //boxSizing:'content-box'
    },
    map_button:{
        background:'rgba(255, 255, 255, 0.14)',
        border:'0.918357px solid #FFFFFF',
        boxShadow:'0px 0px 13.7754px rgba(84, 48, 129, 0.34)',
        borderRadius:4.6,
        textTransform:'none',
        padding:'8px 20px',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        zIndex:3,
        color:'white'
    }
}))

