import axios from "axios";
const { mainServer, BASIC_AUTH_HEADER } = require("../../../../variables/config");


//gets
export const request_tags = async(params) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/tag/list${params}`)
    return response.data.data
}

export const request_tag = async(id) => {
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/tag/${id}`)
    return response.data.data
}

//posts
export const request_create_tag = async (data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/tag`, data)
    return response.data.data
}

//patchs
export const request_update_tag = async (id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/tag/${id}`, data)
    return response.data.data
}

/*----------MEDIA-------- */
//posts
export const request_upload_tag_image = async(id, file, data) => {
    const logData = JSON.parse(localStorage.getItem('token_data'));
    mainServer.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.post(`/tag/${id}/image`, data)
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.upload_url, file, options);
}

//deletes
export const request_delete_tag_image= async (id, type) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/tag/${id}/image?document=${type}`)
    return response.data.data
}