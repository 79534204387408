import { useState } from 'react';
import SimpleTable from './components/SimpleTable';
import AddModal from './modals/AddModal';
import DeleteModal from './modals/DeleteModal';
import useAlsoSeenProducts from './useAlsoSeenProducts';




const AlsoSeenProducts = ({content, history, match, }) => {

    const id = match.params.id
    const {system, modals, actions, view_data} = useAlsoSeenProducts({content, history, match})

  
    return(
        <div  >
            <AddModal open={modals.add} onClose={()=>actions.onChangeModalStatus('add', false)} history={history} content={content.modal}
                id={id} onUpdateCompleted={actions.onInitModule}/>
            <DeleteModal open={modals.delete} onClose={()=>actions.onChangeModalStatus('delete', false)} id={view_data.selected} 
                onUpdateCompleted={actions.onInitModule}/>
            <SimpleTable
                content={content.table}
                loading={system.loading}
                data={view_data.data}
                total={view_data.total}
                tableFilter={view_data.tableFilter}
                search={view_data.search}
                onUpdateTableFilter={actions.onChangeTableFilter}
                onChangeSearch={actions.onChangeSearch}
                onAddRegister={()=>actions.onChangeModalStatus('add', true)}
                //onSelectedItem={actions.onSelectItem}
                onDeleteItem={actions.onDeleteItem}
            />
        </div>
    )
}



export default AlsoSeenProducts