import { Grid } from "@mui/material"
import AddressCard from "./components/AddressCard"
import GeneralCard from "./components/GeneralCard"


const Information = ({form, content, onChange, sending, onSubmit}) => {

    return(
        <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
                <GeneralCard form={form} content={content} onChange={onChange} sending={sending} onSubmit={onSubmit} />
            </Grid>
            <Grid item md={6} xs={12}>
                <AddressCard form={form} content={content} onChange={onChange} sending={sending} onSubmit={onSubmit} />
            </Grid>
        </Grid>
    )
}

export default Information