import React from 'react'
import {
    Button,
    CircularProgress,
    Fab,
    Grid,
    Icon,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    TableContainer,
    LinearProgress,
    Card,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { onFormatNumber, onGetDateFormat, onGetFullname, onGetTimeDuration } from '../../../../shared/utility'
import { blueGrey, grey } from '@mui/material/colors'
import SearchBar from '../../../../components/Actions/SearchBar/SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import empty from '../../../../assets/empty.png'
import GenericStatus from '../../../../components/Structure/DisplayData/GenericStatus'
import { catalogs } from '../../../../texts/esp/catalogs'
import UserAvatarName from '../../../../components/Structure/DisplayData/UserAvatarName'
import PillStatus from '../../../../components/Structure/DisplayData/PillStatus'
import { tableStyles } from '../../../../styles/tableStyles'
import NewActionBar from '../../../../components/Actions/NewActionBar'
import user from '../../../../assets/icons/user.svg'


const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

/* const header = [
    {id:1, label:'ID'},
    {id:2, label:'Creador'},
    {id:3, label:'Repartidor'},
    {id:4, label:'# Pedidos'},
    {id:5, label:'Inicio estimado'},
    {id:6, label:'Inicio'},
    {id:7, label:'Fin'},
    {id:8, label:'Estatus'},
] */


/* const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    addIcon:{
        background:theme.palette.primary.main,
        borderRadius:'50%',
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16,
        //border:'1px solid red'
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
})) */

const SimpleTable = props => {

    const classes = tableStyles()
    const {data, content, task_type, filter, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, year, 
        onRemoveItem, onChangeSearch, onAddRegister, search, catalogs, filters, onUpdateFilterStatus, warehouses} = props

    const header = content.header

    //console.log(data)
    const all_option = [{value:-1, label:'Todos'}]

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} alignItems='center' > 
                    
                    {/* <Grid item><SearchBar value={filter.query} onChange={onChangeSearch}/></Grid> */}
                    <Grid item>
                        <FilterButton options={[...all_option, ...warehouses]} selected={filters.warehouse} 
                            onChange={(value)=>onUpdateFilterStatus('warehouse', value)}/>
                    </Grid>
                    <Grid item>
                        <FilterButton options={[...all_option, ...catalogs.route_statuses]} selected={filters.status} 
                            onChange={(value)=>onUpdateFilterStatus('status', value)} color />
                    </Grid>
                    <Grid item xs/>
                    <Grid item>
                        <SearchBar search={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
                    </Grid>
                    {/* <Grid item> <IconButton className={classes.addIcon} color='primary' onClick={onAddRegister}><Icon >add</Icon></IconButton> </Grid> */}
                    <Grid item> <IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon >add</Icon></IconButton> </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography style={{fontWeight:400}} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_route, delivery_man, delivery_man_image, assigner, assigner_image, orders_quantity, 
                                            shipped_date, completed_date, estimated_start_date, route_status_id} = item

                                        const _shipped_date = onGetDateFormat(shipped_date, 'DD/MM/YYYY')
                                        const _shipped_date_hour = onGetDateFormat(shipped_date, 'HH:mm')
                                        const _completed_date = onGetDateFormat(completed_date, 'DD/MM/YYYY')
                                        const _completed_date_hour = onGetDateFormat(completed_date, 'HH:mm')
                                        const _estimated_start_date = onGetDateFormat(estimated_start_date, 'DD/MM/YYYY')
                                        const _estimated_start_date_hour = onGetDateFormat(estimated_start_date, 'HH:mm')

                                        return(
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{id_route}</Typography></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName noimage={user} name={assigner} image={assigner_image}/></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName noimage={user} name={delivery_man} image={delivery_man_image}/></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><Typography variant='body2'>{orders_quantity}</Typography></TableCell>

                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                                    <Typography variant='caption'>{_estimated_start_date}</Typography>
                                                    <Typography variant='caption' style={{marginLeft:8}}>{`${_estimated_start_date_hour}hrs`}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                                    <Typography variant='caption'>{_completed_date}</Typography>
                                                    <Typography variant='caption' style={{marginLeft:8}}>{`${_completed_date_hour}hrs`}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}>
                                                    <Typography variant='caption'>{_shipped_date}</Typography>
                                                    <Typography variant='caption' style={{marginLeft:8}}>{`${_shipped_date_hour}hrs`}</Typography>
                                                </TableCell>
                                                <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} onClick={() => onSelectedItem(item)}>
                                                    <PillStatus value={route_status_id} catalogs={catalogs.route_statuses}/>
                                                </TableCell>

                                                {/*<TableCell className={classes.cell}><IconButton size='small' onClick={() => onRemoveItem(item)}><Icon fontSize='small'>delete</Icon></IconButton></TableCell>*/}
                                            </TableRow>
                                        )
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <Typography>{content.nodata}</Typography>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    );
}

export default SimpleTable