import { Card, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useTheme from '@mui/styles/useTheme';
//import { makeStyles, useTheme } from '@material-ui/core/node_modules/@material-ui/styles';
import React, { useState, useEffect } from 'react';
import { DASHBOARD_SETTINGS } from '../../../../../variables/config';
import SimpleTable from './components/SimpleTable';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import LoadingModal from '../../modals/LoadingModal';
import Prueba from './components/prueba';
import GoogleMapSection from './components/GoogleMapSection';

const AssignedRouteSection = ({ orders, total, pagination, actions, modals, sending_back, sending_next, rloading, search, routeorders, endDate, startDate}) => {

    const classes = useStyles()
    const theme = useTheme()

    console.log(routeorders)

    return ( 
        <div className={classes.root}>
            <LoadingModal open={modals.loading} message='Añadiendo pedido a la ruta...' />
            <Card className={classes.card}>
                <div className={classes.header}>
                    <Typography variant='subtitle1' align='center'>
                        Añadir pedido
                    </Typography>
                </div>
                <div className={classes.container}>
                    <div className={classes.table}>
                        <Typography color='primary'>Pedidos</Typography>
                        <SimpleTable
                            loading={rloading}
                            data={orders}
                            total={total}
                            tableFilter={pagination}
                            onUpdateTableFilter={actions.onUpdatePagination}
                            search={search}
                            onChangeSearch={actions.onChangeSearch}
                            onAddRouteorder={actions.onAddRouteorder}
                            startDate={startDate}
                            endDate={endDate}
                            onChangeDate={actions.onChangeDate}
                        />
                    </div>
                    <div style={{margin:'20px 0px 40px'}} >
                        <GoogleMapSection 
                            routeorders={routeorders} orders={orders} 
                            onAddRouteorder={actions.onAddRouteorder} 
                            onDeleteRouteorder={actions.onDeleteRouteorder}
                        />
                    </div>
                    <div className={classes.table}>
                        <Typography color='primary'>Pedidos a entregar</Typography>
                        <Prueba 
                            loading={rloading}
                            data={routeorders} 
                            onDeleteRouteorder={actions.onDeleteRouteorder}
                            onUpdateRouteorder={actions.onUpdateRouteorder}
                        />
                    </div>
                    <div className={classes.submit_container}>
                        <Grid container spacing={4} justifyContent='center'>
                            <Grid item><RoundedButton loading={sending_back} color='white_primary' onClick={actions.onUpdateRouteStatusBack}>Regresar</RoundedButton></Grid>
                            <Grid item><RoundedButton loading={sending_next} onClick={actions.onUpdateRouteStatusNext} >Continuar</RoundedButton></Grid>
                        </Grid>            
                    </div>
                </div>
            </Card>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{

    },
    card:{
        boxShadow:DASHBOARD_SETTINGS.BOXSHADOW,
        borderRadius:10,
        padding:16
    },
    header:{
        padding:16,
        paddingTop:8,
        borderBottom:'1px solid #EAEAEA',
        marginBottom:16
    },
    table:{
        padding:'40px 0px'
    },
    icon_container:{
        background:'rgba(202, 203, 251, 0.1)',
        position:'relative',
        width:100, height:100,
        borderRadius:'50%',
        marginBottom:16
    },
    icon:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        
    },
    form:{
        width:250,
        margin:'auto'
    },
    payment_container:{
        margin:'40px 0px'
    },
    submit_container:{
        marginTop:40,
        //textAlign:'center',
        marginBottom:20
    }
}))
 
export default AssignedRouteSection;