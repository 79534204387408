import { createTheme, adaptV4Theme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";

export const theme = createTheme(adaptV4Theme({
	palette: {
		primary: { light: '#EBEBFF', main: '#543081', dark: '#1B0934' }, //#1A237E //0847a7
		secondary: { main: '#B68DE9', /* main:'#D95D71', */ light: '#FCECED' /*lightBlue[700]*/ }, //#2196f3 //4466f2
		tertiary: { main: '#96AE76' },
		layout: { background: '#F1F1F2' },
		components: { sidebar: '#FFFFFF', topbar: '#E5E5E5' },
		text: {
			// primary: blueGrey[900],
			primary: '#151515',
			secondary: blueGrey[500],
		},
	},
	typography: {
		useNextVariants: true,
		fontFamily: ['Visby Round CF', 'Montserrat', 'Work Sans'].join(','),
	},
	overrides: {
		MuiTypography: {
			h1: {
				fontSize: '4.5rem',
				fontWeight: 500,
			},
			h2: {
				fontSize: '3.75rem',
			},
			h3: {
				fontSize: '3rem',
			},
			h4: {
				fontWeight: 600,
				fontSize: '2.250rem',
			},
			h5: {
				fontSize: '1.875rem',
				fontWeight: 600,
			},
			h6: {
				fontSize: '1.5rem',
				fontWeight: 500,
			},
			subtitle1: {
				fontSize: '1.125rem',
				fontWeight: 500,
			},
			subtitle2: {
				fontSize: '1rem',
				fontWeight: 500,
			},
			body1: {
				fontSize: '1.125rem',
				fontWeight: 300,
			},
			body2: {
				fontSize: '1rem',
				fontWeight: 300,
			},
			caption: {
				fontSize: '0.875rem',
				fontWeight: 500,
			},
			button: {
				fontSize: '1.125rem',
			},
		},
	},
}));