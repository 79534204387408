
export const translations = {
    title:'Detalles del pedido',
    client_info:'Datos del cliente',
    summary:{
        title:'Resumen',
        subtotal:'Subtotal',
        taxes:'Impuestos',
        discount:'Descuento',
        shipping_cost:'Envío',
        total:'Total',
        payment_type:'Forma de pago'
    },
    delivery:{
        title:'Entrega',
        address:'Dirección:',
        schedule_time:'Fecha de entrega agendada:',
        warehouse:'Sucursal:'
    },
    list_products:{
        title:'Lista de productos',
        noproducts:'No existen productos'
    }

}

