const { mainServer, BASIC_AUTH_HEADER } = require("../../../variables/config");

//gets
export const request_newproducts = async(params) => {
    //const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = BASIC_AUTH_HEADER
    const response = await mainServer.get(`/newproduct/extended${params}` )
    return response.data.data
}

export const request_newproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/newproduct/${id}` )
    return response.data.data
}

export const request_products = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/list` )
    return response.data.data
}

//posts
export const request_create_newproduct = async(data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/newproduct`, data)
    return response.data.data
}


//patchs
export const request_update_newproduct = async(id, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.patch(`/newproduct/${id}`, data )
    return response.data.data
}

//deletes
export const request_delete_newproduct = async(id) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.delete(`/newproduct/${id}`)
    return response.data.data
}

