import { Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputForm from '../../../../components/Forms/InputForm'
import SelectForm from '../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../components/Texts/DisplayText'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../../shared/utility'
import { request_newproduct, request_update_newproduct } from '../requests'

const EditModal = ({open, onClose, onUpdateCompleted, content, id}) => {

    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
    const onInitModal = async() => {
        if (!open) {
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }else{
            const product = await request_newproduct(id)
            let _form = onInitForm(form, product.newproduct_data)
            setForm(_form)
        }
    }
    onInitModal()
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)
        console.log(id)

        setLoading(true)
        setDisabled(true)
        try {
            await request_update_newproduct(id, data2send);
            setLoading(false)
            onUpdateCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.edit_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.title} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <SelectForm data={form.order} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default EditModal


const formData = {
    title: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'title',
            type: 'text',
            fullWidth: true,
            label: 'Título',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    order: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[{value:1, label:'1'}, {value:2, label:'2'}, {value:3, label:'3'}, {value:4, label:'4'}, {value:5, label:'5'},
        {value:6, label:'6'}, {value:7, label:'7'}, {value:8, label:'8'}, {value:9, label:'9'}, {value:10, label:'10'},
        {value:11, label:'11'}, {value:12, label:'12'}, {value:13, label:'13'}, {value:14, label:'14'}, {value:15, label:'15'},
        {value:16, label:'16'}, {value:17, label:'17'}, {value:18, label:'18'}, {value:19, label:'19'}, {value:20, label:'20'}],
        config: {
            id: 'order',
            type: 'text',
            fullWidth: true,
            label: 'Orden',
            helperText: 'Debe elegir una opción'
        },
        rules: {
            type: 'select',
        }
    },
}