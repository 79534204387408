import React from 'react'
import { CircularProgress, Dialog, Grow, Icon, IconButton, useMediaQuery, useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} timeout={500} />;
});

const useStyles = makeStyles(theme => ({
    root:{
        //position:'relative'
    },
    paper:{
        borderRadius:32, 
        //padding:theme.spacing(7),
        padding:theme.spacing(5),
        boxSizing:'border-box',
        [theme.breakpoints.down('md')]:{
            borderRadius:0, 
            padding:theme.spacing(3),
            paddingTop:theme.spacing(7)
        }
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        //padding:theme.spacing(4),
        boxSizing:'border-box'
    },
    loading_container:{
        height:400,
        width:'100%',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}))



const SimpleModal = ({open, maxWidth, children, onClose, loading, loading_height}) => {

    const classes = useStyles()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Dialog open={open} maxWidth={maxWidth ? maxWidth :'xs'} scroll='body' fullScreen={isMobile}
        fullWidth classes={{ paper: classes.paper }} className={classes.root}
            TransitionComponent={Transition}>
            <div className={classes.buttonContainer}><IconButton onClick={onClose} size="large"><Icon >close</Icon></IconButton></div>
            {/* <div className={classes.container}>
                {children}
            </div> */}
            {loading ? 
            <div className={classes.loading_container} style={{height: loading_height ? loading_height : 400}}>
                <CircularProgress size={32} />
            </div> 
            : 
            <div className={classes.container}>
                {children}
            </div>}    
        </Dialog>
    );
}

export default SimpleModal