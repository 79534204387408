import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Icon,
    IconButton,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SimpleCard from "../../../../../../components/Structure/Cards/SimpleCard"
import DisplayText from "../../../../../../components/Texts/DisplayText"
import FilterButton from "../../../../../../components/Actions/Buttons/FilterButton"
import { onGetMonthsCatalog, onGetYearsCatalog } from "../../../../../../shared/utility"
import SpecialDay from "./SpecialDay"
import moment from "moment"



const SpecialEventsMonth = ({title, children, catalogs, data, onChangeFilter, filter, onAdd, onDelete}) => {

    const classes = useStyles()

    //console.log(data)
    const current_month = parseInt(moment().format('M'))
    //console.log(current_month)

    const years = onGetYearsCatalog()
    //console.log(years)

    return (
        <div>
            <DisplayText variant='h6'>{title}</DisplayText>
            <Grid container alignItems="center" justifyContent="space-between" className={classes.topbar} >
                <Grid item><FilterButton options={years} onChange={(val)=>onChangeFilter('event_year', val)} selected={filter}  /></Grid>
                <Grid item><IconButton className={classes.add_button} onClick={onAdd} size="large"><Icon>add</Icon></IconButton></Grid>
            </Grid>
            <SimpleCard style={{padding:'8px 0px'}} >
                <Grid container direction='column'>
                    {onGetMonthsCatalog().map((item, ind)=>{
                        return(
                            <Grid item >
                                <Accordion className={classes.accordion} defaultExpanded={current_month === item.value} >
                                    <AccordionSummary
                                        expandIcon={<Icon>expand_more</Icon>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className={classes.accordion_summary}
                                    >
                                        <div style={{width:'100%', textAlign:'center'}} ><Typography>{item.label}</Typography></div>  
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        <div className={classes.summary_card}>
                                            {data && data.length !== 0 ? data.map((event, indx) => {
                                                //console.log(moment(item.start_time).format('M'))
                                                if(parseInt(moment(event.start_time).local().format('M')) === item.value){
                                                    return(
                                                        <SpecialDay catalogs={catalogs} {...event} onDelete={()=>onDelete(event)} />
                                                    )
                                                }
                                                
                                            })                         
                                            : null}
                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )
                    })}
                </Grid>
            </SimpleCard>
        </div>
    );

}

export default SpecialEventsMonth

const useStyles = makeStyles(theme => ({
    topbar:{
        margin:'20px 0px 10px',
        
    },
    add_button:{
        width:44,
        height:44,
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        },
        filter:'drop-shadow(0px 4px 5px rgba(37, 37, 37, 0.25))'
    },
    heading:{
        textAlign:'center'
    },
    accordion:{
        //background:'green',
        boxShadow:'none',
        marginBottom:4,
        borderRadius:'20px 20px 0px 0px',
        '& .MuiPaper-elevation1-62':{
            boxShadow:'none',
            borderRadius:'20px 20px 0px 0px',
        }
    },
    accordion_summary:{
        //background:'green',
        //borderRadius:'20px 20px 0px 0px',
        paddingRight:24,
        boxShadow:'0px 5px 8px -4px rgba(64, 64, 64, 0.07)',
        //boxShadow:'none',
        '& .Mui-expanded': {
            color:theme.palette.primary.main,
            
            //background:'red'
        },
    },
    summary_card:{
        background:'#F6F7FB',
        borderRadius:15,
        padding:'20px 8px',
        width:'100%'
    }

}))