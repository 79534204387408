import { Grid, Chip, Typography } from '@mui/material'
import AutocompleteFormAdd from '../../../../../../components/Forms/AutocompleteFormAdd'
import SimpleCard from '../../../../../../components/Structure/Cards/SimpleCard'
import AddSegmentModal from '../modals/AddSegmentModal'



const Segments = ({product_segments, actions, modals, form, options, content, product_subcategories}) => {

    /* const content = translations_esp.segments

    const [add_modal, setAddModal] = useState(false)

    const onDeleteItem = async(el) => {
        await request_delete_product_segment(el)
        onUpdateCompleted()
    }

    const onAddProductItem = async (el) => {
        try {
            await request_create_product_segment({product_id:id, segment_id:el});
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
        }
    } */

    let segment_form = {...form}
    segment_form.options = options

    return(
        <div>
            <AddSegmentModal open={modals.add_segment} onClose={()=>actions.onChangeModalStatus('add_segment', false)} onUpdateCompleted={actions.onUpdateSegmentsCompleted} 
            onAddProductItem={actions.onAddProductSegment} content={content.modal} product_subcategories={product_subcategories} />
            <Grid container spacing={2}>
                <Grid item xs={12}>                 
                    <Typography variant='subtitle1'>{content.title}</Typography>                
                </Grid>
                <Grid item xs={12}>
                    {/* <SearchSegments onAddItem={()=>setAddModal(true)} existingItems={product_segments} 
                    onAddProductItem={onAddProductItem} content={content.search_bar} 
                    product_subcategories={product_subcategories}/> */}
                    <AutocompleteFormAdd data={segment_form} onChange={actions.onChangeSearchForm} add_register={content.search_bar.add_register}
                        onAdd={()=>actions.onChangeModalStatus('add_segment', true)} onInputChange={actions.onSearchSegments} onAdd2={actions.onAddProductSegment} />
                </Grid>
                <Grid item xs={12} >
                    <Grid container spacing={1}>
                        {product_segments.map((item, index)=>{
                            return(
                                <Grid item key={index}>
                                    <Chip label={item.segment} onDelete={()=>actions.onDeleteSegment(item.id_product_segment)} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>      
        </div>
    )
}

export default Segments