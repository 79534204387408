const { mainServer } = require("../../../variables/config");

export const request_visitedproducts = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/visitedproduct/list${params}` )
    return response.data.data
}


export const request_create_product = async(id_user, data) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.post(`/visitedproduct/user/${id_user}`, data)
    return response.data.data
}

export const request_products = async() => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/product/list` )
    return response.data.data
}