import { useRef, useState } from "react"


const useSearchBar = ({ search , onUpdateTableFilter, tableFilter, onChange}) => {


    const inputRef = useRef()
    const timerRef = useRef()

    const actions = {
        onStartCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
            console.log('timepueo')
            timerRef.current = setTimeout(actions.onRequestSearch, 1000);
        },
        onStopCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
        },
        onRequestSearch: async() => {    
            if(search && search.length < 4) return
            try {
                if(timerRef.current) clearTimeout(timerRef.current)
                let temp = {...tableFilter}
                temp.offset = 0
                onUpdateTableFilter(temp)    
            } catch (error) {
                console.log('Hubo un error')
                console.log(error)  
            }
                    
        },
        onUpdateSearchValue: (val) => {
            onChange(val.currentTarget.value)
        },

    }

    return {inputRef, actions}
}

export default useSearchBar