import { Grid, Typography } from "@mui/material"
import RichTextForm from "../../../../../../components/Forms/RichTextForm"

const Prescription = ({actions, form_data}) => {

    const {onSetDetails, onSetIngredients, onSetUsage, onSetIndications, onSetContraindications, onSetPrecautionsAndWarnings, onSetFarmaceuticalForm, onSetPregnancyAndLactation,
        onSetDosis, onSetPharmaceuticalProperties, onSetComposition, onSetAdverseReactions, onSetDrugInteractions, onSetOverdosage, onSetProtectionLabels } = actions
    const {details, ingredients, usage, indications, contraindications, precautions_and_warnings, pregnancy_and_lactation, pharmaceutical_form, dosis, pharmaceutical_properties, 
        composition, adverse_reactions, drug_interactions, overdosage, protection_labels} = form_data

    return(
        <Grid container spacing={4} >
            <Grid item xs={12} style={{marginBottom:20}}>
                <Grid container alignItems='center'>
                    <Grid item ><Typography variant='subtitle1'>Prescripción</Typography></Grid>
                    <Grid item xs><div style={{width:'98%', height:0.5, background:'#DCDCDC', marginLeft:16 }}></div></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Información del producto / detalles' value={details} 
                onChange={(value)=>onSetDetails(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Ingredientes' value={ingredients} 
                onChange={(value)=>onSetIngredients(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Indicaciones Terapéuticas' value={indications} 
                onChange={(value)=>onSetIndications(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Dosis' value={dosis} 
                onChange={(value)=>onSetDosis(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Composición' value={composition} 
                onChange={(value)=>onSetComposition(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Propiedades' value={pharmaceutical_properties} 
                onChange={(value)=>onSetPharmaceuticalProperties(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Contraindicaciones' value={contraindications} 
                onChange={(value)=>onSetContraindications(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Embarazo y Lactancia' value={pregnancy_and_lactation} 
                onChange={(value)=>onSetPregnancyAndLactation(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Reacciones adversas' value={adverse_reactions} 
                onChange={(value)=>onSetAdverseReactions(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Interacciones y medicamentos' value={drug_interactions} 
                onChange={(value)=>onSetDrugInteractions(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Precauciones y Advertencias' value={precautions_and_warnings} 
                onChange={(value)=>onSetPrecautionsAndWarnings(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Sobredosificación o ingesta accidental' value={overdosage} 
                onChange={(value)=>onSetOverdosage(value)} />
            </Grid>
            <Grid item xs={12} >
                <RichTextForm label='Leyendas de protección' value={protection_labels} 
                onChange={(value)=>onSetProtectionLabels(value)} />
            </Grid>
            {/* <Grid item xs={12} >
                <RichTextForm label='Forma farmacéutica y formulación' value={pharmaceutical_form} 
                onChange={(value)=>setFarmaceuticalForm(value)} />
            </Grid> 
            <Grid item xs={12} >
                <RichTextForm label='Modo de empleo' value={usage} 
                onChange={(value)=>setUsage(value)} />
            </Grid> */}
        </Grid>
    )
}

export default Prescription