import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ColorPicker } from "material-ui-color";
import withStyles from '@mui/styles/withStyles';
import ContainedButton from "../../../../components/Actions/ContainedButton";
import { translations as translations_esp } from "../../../../texts/esp/tables-settings-transtalions";
import DisplayText from "../../../../components/Texts/DisplayText";
import {
  isFormValid,
  onGetErrorMessage,
  onGetFormData,
  onInitEmptyForm,
  onSetErrorsToForm,
} from "../../../../shared/utility";
import { request_create_rolesetting } from "../requests";
import Switch from "@mui/material/Switch";
import InputForm from "../../../../components/Forms/InputForm";
import SimpleModal from "../../../../components/Overlays/SimpleModal";
import AvatarPicture from "../../../../components/Structure/DisplayData/AvatarPicture";
import nouser from "../../../../assets/nouser.png";

const AddRolModal = ({
  open,
  onClose,
  onUpdateCompleted,
  id,
  onChangePicture,
  image,
}) => {
  const useStyles = makeStyles((theme) => ({
    imgR: {
      width: "138px",
      height: "138px",
      border: "1px solid black",
    },
    cont1: {
      display: "flex",
    },
    btnOn: {
      float: "right",
    },
    imgIcon: {
      width: "110px",
      height: "110px",
      padding: "15px 15px",
    },
    esta: {
      textDecoration: "none",
    },
  }));

  const AntSwitch = withStyles((theme) => ({
    root: {
      width: "55.54px",
      height: "25px",
      marginTop: "5px",
      padding: "2px 2px",
      display: "flex",
      marginLeft: "5px",
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      "&$checked": {
        transform: "translateX(31px)",
        color: theme.palette.common.white,
        "& + $track": {
          opacity: 1,
          backgroundColor: "#96AE76",
          borderColor: "#96AE76",
        },
      },
    },
    thumb: {
      width: 18,
      height: 18,
      marginTop: "2.5px",
      boxShadow: "none",
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "10px",
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    checked: {},
  }))(Switch);

  // const userID = match.params.id;

  const default_color = "#ffffff";

  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)));
  const [color, setColor] = useState(default_color);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(" ");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!open) {
      let _form = onInitEmptyForm(form, content.form);
      setForm(_form);
      setError("");
      setLoading(false);
      setDisabled(false);
    }
  }, [open]);

  const onChange = (data) => {
    let temp = { ...form };
    const id = data.config.id;
    temp[id] = { ...data };
    setForm(temp);
  };

  const onSubmit = async () => {
    const errors = isFormValid(form);
    /// Check if the form is valid
    if (errors && errors.length) {
      const new_form = onSetErrorsToForm(form, errors);
      setForm(new_form);
      console.log("hay errores");
      return;
    }

    //Extract data
    let data2send = onGetFormData(form);
    data2send.color = color;

    setLoading(true);
    setDisabled(true);
    try {
      await request_create_rolesetting(data2send);
      setLoading(false);
      onUpdateCompleted();
      onClose();
    } catch (error) {
      console.log(error);
      setError(onGetErrorMessage(error));
      setLoading(false);
    }
    setDisabled(false);
  };

  const onChangeColor = (data) => {
    if (data && data.css) {
      setColor(data.css.backgroundColor);
    } else {
      setColor(data);
    }
    //console.log(data)
  };

  const [state, setState] = React.useState({
    checkedC: true,
  });
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const classes = useStyles();

  const content = translations_esp;
  console.log(form);

  return (
    <SimpleModal open={open} onClose={onClose}>
      <DisplayText variant="h6" color="primary" style={{ fontWeight: 300 }}>
        {content.modal.add_title}
      </DisplayText>

      <div style={{ padding: "30px 0px 24px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputForm data={form.name} onChange={onChange} />
            <br />
            <div align="center">
              <Grid item xs={4}>
                <AvatarPicture
                  can_edit
                  nopicture={nouser}
                  onChangePicture={onChangePicture}
                  imgSrc={image}
                />
              </Grid>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={5} alignItems="right">
                <Typography variant="subtitle" style={{ fontWeight: 100 }}>
                  color
                </Typography>

                <ColorPicker
                  value={color}
                  disableAlpha
                  onChange={onChangeColor}
                  disablePlainColor
                  data={form.color}
                />
              </Grid>

              <Grid item xs={6} alignItems="right">
                <div className={classes.btnOn}>
                  <Typography variant="subtitle" style={{ fontWeight: 100 }}>
                    default
                  </Typography>

                  <AntSwitch
                    checked={state.checkedC}
                    onChange={handleChange}
                    name="checkedC"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <InputForm data={form.description} onChange={onChange} />
          </Grid>
        </Grid>
      </div>

      <ContainedButton
        color="primary"
        fullWidth
        onClick={onSubmit}
        loading={loading}
        disabled={disabled}
      >
        {content.modal.button}
      </ContainedButton>
      <DisplayText color="error" align="center">
        {error}
      </DisplayText>
    </SimpleModal>
  );
};

export default AddRolModal;

export const formData = {
  name: {
    value: "",
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    // options:[{value:1, label:'Doctor', image: A}, {value:2, label:'Administrtor', values: Adm}, {value:3, label:'Asistente', values: Asis}, {value:4, label:'Usuario', values: Usu}],
    config: {
      id: "name",
      type: "text",
      fullWidth: true,
      label: "Nombre",
      helperText: "Ingrese un nombre valido",
    },
    rules: {
      type: "distance",
    },
  },
  description: {
    value: "",
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: "description",
      type: "text",
      label: "Descripción",
      helperText: "Este campo debe tener al menos 1 caracter y máximo 255",
      fullWidth: true,
      multiline: true,
      rows: 4,
    },
    rules: {
      type: "distance",
      min: 1,
      max: 255,
    },
  },

  // icon: {
  //   value: "",
  //   error: false,
  //   isVisited: false,
  //   isRequired: true,
  //   isValid: false,
  //   config: {
  //     id: "icon",
  //     type: "text",
  //     label: "Icon",
  //     helperText: "Este campo debe tener al menos 1 caracter y máximo 255",
  //     fullWidth: true,
  //   },
  //   rules: {
  //     type: "distance",
  //     min: 1,
  //     max: 255,
  //   },
  // },
  color: {
    value: "",
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: "color",
      type: "color",
      fullWidth: true,
    },
    rules: {
      type: "distance",
      min: 1,
      max: 255,
    },
  },

  // defaul: {
  //     value: '',
  //     error: false,
  //     isVisited: false,
  //     isRequired: false,
  //     isValid: false,
  //     config: {
  //         id: 'defaul',
  //         type: 'text',
  //         fullWidth: true,
  //     },
  //     rules: {
  //         type: 'distance',
  //         min: 1, max: 255
  //     }
  // }
};
