import { Card, ClickAwayListener, Grid, Grow, Icon, Popper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useState} from 'react'
import {grey, blueGrey} from '@mui/material/colors'
import { catalogs } from '../../../texts/esp/catalogs'
import DisplayText from '../../Texts/DisplayText'


const StatusMenu = ({status_id, onClick, item}) =>{

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState()


    const options = catalogs.general_statues
    const status = catalogs.general_statues.find(el=>el.value === status_id)

    const onOpenMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    const onInnerItemSelected = (identifier) => {
        setAnchorEl(null)
        //console.log(identifier)
        onClick(item, identifier)
    }

    
    const buttonContent = <Card className={classes.card}>
        <div className={classes.menu}>
            <Grid container>
                {options.map(item => {
                    return(
                        <Grid item xs={12}>
                            <OptionComponent {...item} key={item.value.toString()} onItemSelected={onInnerItemSelected}/>
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    </Card>

    return(
        <div>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal style={{zIndex:10}}>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={350}>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>           
            <div onClick={(e) => onOpenMenu(e)} style={{cursor:'pointer'}}>
                <div className={classes.root_status} style={{background: status ? status.color : 'transparent'}}>
                    {status ? status.label : '-'}
                </div>
            </div>          
        </div>
    )

}

const useStyles = makeStyles(theme=>({
    root_status:{
        fontFamily:theme.typography.fontFamily,
        fontSize:14,
        color:'white',
        display:'inline-block',
        padding:'8px 16px',
        borderRadius:32,
        whiteSpace:'nowrap'
    },
    card:{marginTop:8, borderRadius:16, boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',},
    menu:{marginTop:10, marginBottom:10, width:188, zIndex:4},
    iconButton:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover':{
            background:'white',
        }
    }
}))

export default StatusMenu

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding:theme.spacing(1),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {value, color, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(value)}>
           <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item><Icon style={{marginTop:-8, fontSize:14, color: color ? color : grey[300]}}>brightness_1</Icon></Grid>
                <Grid item><DisplayText variant='body2'>{label}</DisplayText></Grid>
            </Grid>
        </div>
    )
}