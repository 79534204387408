import { updateAuthorizationHeader } from '../shared/utility';
import { private_server } from '../variables/config';


const useProviders = () => {

    const actions = {
        onGetAll: async(params) => {
            updateAuthorizationHeader(private_server)
            let result = {
                data:[],
                count:0
            }
            const __params = {
                order:0,
                order_by:'name'
            }
            const _params = params ?? __params
            const request = await private_server.get(`/provider/list`, {params:{..._params}})
            result.data = request.data.data.providers
            result.count = request.data.data.count
            return result
        },
        
    }

    return {
        actions
    }
}
 
export default useProviders;