import { Avatar, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { isValidDocument } from '../../../../shared/utility';
import { config_data, USER_TYPES } from '../../../../variables/config';
import { ruleValidation } from '../../../../components/Forms/customFunctions';
import user_icon from '../../../../assets/icons/users/user_icon.svg';
import config_icon from '../../../../assets/icons/roles/settings_icon.svg';

const useStylesIconCell = makeStyles((theme) => ({
	icon_container: {
		width: 36,
		height: 36,
		background: '#F4F5FE',
		borderRadius: 25,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	icon_img: {
		width: '100%',
		height: '100%',
		borderRadius: 25,
		color: theme.palette.primary.main,
	},
	avatar: {
		width: 36,
		height: 36,
	},
	color: {
		width: 9,
		height: 9,
		borderRadius: 2,
		marginRight: 10,
	},
	color_invalid: {
		width: 9,
		height: 9,
		borderRadius: 2,
		background:
			'linear-gradient(to top left, rgba(139,0,0,0) 0%,	rgba(139,0,0,0) calc(50% - 0.8px), rgba(139,0,0,1) 50%,	rgba(139,0,0,0) calc(50% + 0.8px), rgba(139,0,0,0) 100%)',
		marginRight: 10,
		border: `1px solid darkred`
	},
	status :{
		width: 7,
		height: 7,
		borderRadius: 20,
		marginRight: 10
	}
}));

const CellImg = ({ type, src }) => {
	const classes = useStylesIconCell();

	switch (type) {
		case 'img':
			let img_content = <img src={user_icon} className={classes.icon} />;

			const valid_image = isValidDocument(src);
			if (valid_image) {
				img_content = <Avatar src={`${config_data.S3_SERVER_URL}${src}`} className={classes.avatar} />;
			}

			return <div className={classes.icon_container}>{img_content}</div>;

		case 'role':
			let img_role = <img src={config_icon} className={classes.icon} />;

			const valid_role = isValidDocument(src);
			if (valid_role) {
				img_role = <img src={`${config_data.S3_SERVER_URL}${src}`} className={classes.icon_img} />;
			}

			return <div className={classes.icon_container}>{img_role}</div>;

		case 'icon':
			return (
				<div className={classes.icon_container}>
					<img src={src} className={classes.icon} />
				</div>
			);

		case 'color':
			const validColor = ruleValidation(src, { type: 'color' });
			if (validColor) return <div className={classes.color} style={{ background: src }} />;
			else return <div className={classes.color_invalid} />;

		case 'status':
			if(src === 1)
				return <div className={classes.status} style={{background: '#96AE76'}}/>
			else
				return <div className={classes.status} style={{background: '#D95D71'}}/>
	}
};

export default CellImg;
