const { mainServer } = require("../../../variables/config");

export const request_carts = async(params) => {
    const credentials = JSON.parse(localStorage.getItem('token_data'))
    mainServer.defaults.headers.common['Authorization'] = credentials.token
    const response = await mainServer.get(`/cart/list${params}`)
    return response.data.data
}



