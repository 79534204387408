import { Grid } from '@mui/material'
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../../../components/Actions/ContainedButton'
import SelectForm from '../../../../../../components/Forms/SelectForm'
import SimpleModal from '../../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { isFormValid, onGetCatalogs, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../../shared/utility'
import { request_create_product_subsegment, request_products } from '../requests'
import { translations as translations_esp } from '../../../../../../texts/esp/subsegment-translations'
import { onGetAddProductParams } from '../custom_functions'
import SelectAutocompleteForm from '../../../../../../components/Forms/SelectAutocompleteForm'

const AddProductSubsegmentModal = ({open, onClose, id, onUpdateCompleted, id_segment}) => {

    const content = translations_esp.tables.products.add_modal
    
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        const initModule = async() => {
            if(!open){
                let _form = onInitEmptyForm(form, content.form)
                setForm(_form)
                setError(''); setLoading(false);
                setDisabled(false)
            }
            if(open){
                const params = onGetAddProductParams(id_segment)
                const _products = await request_products(params)
                const productsCatalogs = onGetCatalogs(_products.products, 'id_product', 'name')
                const _form = {...form}
                _form.product_id.options = productsCatalogs
                setForm(_form)
            }
        }
        initModule()
        }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        const product = onGetFormData(form)
        //console.log(product)

        const data2send = {product_id: product.product_id, sub_segment_id:id}
        console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            await request_create_product_subsegment(data2send);     
            onUpdateCompleted()
            setLoading(false)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <SelectAutocompleteForm data={form.product_id} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <div style={{marginBottom:30}}>
                    <DisplayText variant='body2'>{content.caption}</DisplayText>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddProductSubsegmentModal


const formData = {
    product_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'product_id',
            type: 'select',
            fullWidth: true,
            label: 'Productos',
            helperText: 'Debe elegir una opción',
        },
    },
}