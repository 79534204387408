import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm, updateAuthorizationHeader } from '../../../../shared/utility'
import DisplayText from '../../../../components/Texts/DisplayText'
import SelectForm from '../../../../components/Forms/SelectForm'
import InputForm from '../../../../components/Forms/InputForm'
import SimpleModal from '../../../../components/Overlays/SimpleModal'
import { green } from '@mui/material/colors'
import ContainedButton from '../../../../components/Actions/ContainedButton'
import InputDateForm from '../../../../components/Forms/InputDateForm'
import moment from 'moment'
import useBatches from '../../../../hooks/useBatches'


const ReturnBatchModal = props => {

    const { open, onClose, onCompleted, id } = props

    

    const classes = useStyles()
    const batchManager = useBatches()

    //forms
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //general
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //checks

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            let _form = onInitEmptyForm(form, null)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        if(data2send.consignation_return_quantity) data2send.consignation_return_quantity = parseInt(data2send.consignation_return_quantity)
        console.log(data2send)
        //console.log(data2send)

        setLoading(true)
        try {
            await batchManager.actions.onEditOverride(id, data2send)
            onCompleted()
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div >
                <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>Editar devoluciones</DisplayText>
                <DisplayText >Favor de ingresar los datos solicitados. </DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <InputForm data={form.consignation_return_quantity} onChange={onChange} />
                        </Grid>
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={loading}>
                    Agregar
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
            </div>
        </SimpleModal>
    )
}

export default ReturnBatchModal

const useStyles = makeStyles(theme=>({
    root:{
        position:'relative'
    },
    paper:{
        borderRadius:32, 
        padding:theme.spacing(3)
    },
    buttonContainer:{
        position:'absolute',
        top:16,
        right:16
    },
    container:{
        padding:theme.spacing(4),
    },
    form:{
        paddingTop:theme.spacing(2),
        paddingBottom:theme.spacing(4),
    },
    linkContainer:{
        marginTop:16,
        marginBottom:16
    },
    link:{
        color:theme.palette.secondary.main,
        fontWeight:500,
        fontFamily:theme.typography.fontFamily,
        textAlign:'center',
        '&:hover':{cursor:'pointer', color:theme.palette.secondary.dark,}
    },
    successIcon:{
        fontSize:64,
        color:green[700],
        border:`2px solid ${green[700]}`,
        borderRadius:'50%',
        marginTop:32,
        marginBottom:32
    }
}))

const formData = {
    consignation_return_quantity: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'consignation_return_quantity',
            type: 'number',
            fullWidth: true,
            label: 'No. de devoluciones',
            placeholder: 'No. de devoluciones',
            helperText: 'Cantidad no válida'
        },
        rules: {
            type: 'quantity',
            //min: 1, max: 256
        }
    },
    
}