import React, {useState, useEffect} from 'react'
import { private_server } from '../../../../../../variables/config'
import WarningModal from '../../../../../../modals/WarningModal'
import { onGetErrorMessage, updateAuthorizationHeader } from '../../../../../../shared/utility'




const DeleteModal = props => {
 
    const { id, open, onClose, onUpdateCompleted } = props
  
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        setError('')
        setLoading(false)
        setDisabled(false)
    }, [open])

    const onSubmit = async() => {
        setLoading(true)
        setDisabled(true)
        try {
            updateAuthorizationHeader(private_server)
            await private_server.delete(`warehouseuser/${id}`)
            onUpdateCompleted()
            setLoading(false)
            onClose()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)
    }

    return(
        <WarningModal open={open} onClose={onClose} error={error} loading={loading} onSubmit={onSubmit} disabled={disabled}/>
    )
}

export default DeleteModal