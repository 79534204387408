import { useEffect, useState } from "react"
import { isFormValid, onCleanData, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../shared/utility"
import { private_server } from "../../../variables/config"
import { catalogs } from "../../../texts/esp/catalogs"



const useOrderView = ({match, content, onUpdateSending, onUpdateSuccess, onUpdateError}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [order, setOrder] = useState(null)
    const [warehouse_address, setWarehouseAddress] = useState(null)

    //form
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [modals, setModals] = useState({
        add_billing_profile:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const _order = await private_server.get(`/order/${id}`)
                console.log(_order)
                setOrder(_order.data.data.order)
                if(_order.data.data.order && _order.data.data.order.order_type_id === 2){
                    //const warehouse = await private_server.get(`/warehouse/address/${id}`)
                    const warehouse = await private_server.get(`/warehouse/address/${_order.data.data.order.warehouse_id}/2`)
                    setWarehouseAddress(warehouse.data.data.warehouse)
                }
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onCancel:async () =>{
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                await private_server.post(`/order/${id}/cancel`)
                const _order = await private_server.get(`/order/${id}`)
                console.log(_order)
                setOrder(_order.data.data.order)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onSubmit: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            /* let data2send = onGetSelectedFormData(form)
            
            console.log(data2send)
            setSending(true)
            onUpdateSending(true)
            try {
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/users/${id}`, data2send)
                const req = await private_server.get(`users/${id}`)
                setSending(false)
                //setSucess(content.success_msg)
                onUpdateSuccess(content.success_msg)
                onUpdateSending(false)
            } catch (error) {
                console.log(error)
                //setError(onGetErrorMessage(error))
                onUpdateError(onGetErrorMessage(error))
                setSending(false)
                onUpdateSending(false)
            } */
        },
        onClearError:() => setError(null)        
    }

    const system = {loading, sending, error}
    const view_data = {order, form, warehouse_address}

    return {system, modals, actions, view_data } 
}

export default useOrderView

const formData = {
    /*------ general info ------*/
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    curp: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'curp',
            type: 'text',
            fullWidth: true,
            label: 'CURP',
            helperText: 'CURP no válida'
        },
        rules: {
            type: 'distance',
            min: 18, max: 18
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.genders],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    
    /*------ contact info ------*/
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    mobile_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    fixed_phone_country_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.countries],
        config: {
            id: 'fixed_phone_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    fixed_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fixed_phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'No es un código postal válido'
        },
        rules: {
            type: 'zip',
            //min: 1, max: 256
        }
    },
    city: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'city',
            type: 'text',
            fullWidth: true,
            label: 'Ciudad',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },

    /*------ additional info ------*/
    blood_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.blood_types],
        config: {
            id: 'blood_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de sangre',
            helperText: 'Debe elegir una opción',
        },
    },
    civil_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.civil_statuses],
        config: {
            id: 'civil_status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estado civil',
            helperText: 'Debe elegir una opción',
        },
    },
    occupation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'occupation',
            type: 'text',
            fullWidth: true,
            label: 'Ocupación',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    scolarity_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[...catalogs.scolarities],
        config: {
            id: 'scolarity_id',
            type: 'select',
            fullWidth: true,
            label: 'Escolaridad',
            helperText: 'Debe elegir una opción',
        },
    },
    religion: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'religion',
            type: 'text',
            fullWidth: true,
            label: 'Religión',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_group: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_group',
            type: 'text',
            fullWidth: true,
            label: 'Grupo étnico',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_language',
            type: 'text',
            fullWidth: true,
            label: 'Dialecto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[...catalogs.currency],
        config: {
            id: 'currency_id',
            type: 'select',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Debe elegir una opción',
        },

    },
}