import { useEffect, useState } from "react"
import { onGetCatalogs, onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"
import queryString from 'query-string'



const useCoverage = ({language, content}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'shipping_type_id'})
    const [search, setSearch] = useState('')

    const [warehouses, setWarehouses] = useState([])

    const [filters, setFilters] = useState({
        shipping:-1,
        warehouse:-1
    })

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [tableFilter, filters])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = tableFilter
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = []

        if(filters.shipping !== -1) filtersArgs.push({"field":"shipping_type_id","operator":"=", "value":`${filters.shipping}`}) 

        if(filters.warehouse !== -1) filtersArgs.push({"field":"id_warehouse_coverage","operator":"=", "value":`${filters.warehouse}`}) 
    
        //if(search)filtersArgs.push({"field":"name","operator":"LIKE","value":`%${search}%`})
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req_warehouses = await private_server.get(`/warehouse/list`)
                const cat_warehouses = onGetCatalogs(req_warehouses.data.data.warehouses_data, 'id_warehouse', 'name')
                setWarehouses(cat_warehouses)
                const params = onGetParams()
                let req = await private_server.get(`warehouse/coverages/list${params}`)   
                console.log(req)
                setData(req.data.data.warehouse_coverages_data)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {appointmentdiscountcode} = item
            setSelected(appointmentdiscountcode)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            setSelected(item)
            actions.onChangeModalStatus('edit', true)
        },
        onChangeFiltersStatus:(_key, _value) => setFilters({...filters, [_key]:_value})
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected, filters, warehouses}

    return {system, modals, actions, view_data } 
}

export default useCoverage