import React from 'react'
import {
    Card,
    Checkbox,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors'
import SearchBar from '../../../../../../components/Actions/SearchBar/SearchBar'
import ActionBar from '../../../../../../components/Actions/ActionBar'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components/Texts/DisplayText'
import { translations as translations_esp } from '../../../../../../texts/esp/subsegments-translations'
import { nodata } from '../../../../../../variables/config'
import { tableStyles } from '../../../../../../styles/tableStyles'
import NewActionBar from '../../../../../../components/Actions/NewActionBar'
import UserAvatarName from '../../../../../../components/Structure/DisplayData/UserAvatarName';
import product from '../../../../../../assets/icons/product.svg'
const content = translations_esp.table
const header = content.header

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:16,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:800
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    link:{
        textDecoration:'none',
        color:'black',
        background:'red',
        width:'100%'
    }
}))

const SimpleTable = props => {

    const classes = tableStyles()
    const {data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, onChangeStore} = props

    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <SearchBar search={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} />
                            </Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography className={classes.header} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const {id_sub_segment, segment, order, name, description, color, store} = item

                                        return (
                                            <TableRow key={key.toString()} className={classes.row} >
                                                <TableCell onClick={() => onSelectedItem(item, 'edit')}  style={{borderRadius:'16px 0px 0px 16px'}}className={classes.cell}><DisplayText variant='body2'>{id_sub_segment ? id_sub_segment : nodata}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><UserAvatarName image={item?.image_web || item?.image_mobile} name={null} variant='square' noimage={product} labelStyles={{fontWeight:500, fontSize:'1rem'}} /></TableCell>

                                                <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText variant='body2'>{name ? name : nodata}</DisplayText></TableCell>
                                                <TableCell  className={classes.cell}  >
                                                    <Checkbox color='primary' checked={Boolean(store)} onChange={()=>onChangeStore(id_sub_segment, store)}  />
                                                </TableCell>
                                                <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText variant='body2'>{order ? order : nodata}</DisplayText></TableCell>
                                                <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText variant='body2'>{segment ? segment : nodata}</DisplayText></TableCell>                        
                                                <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText variant='body2'>{description ? description : nodata}</DisplayText></TableCell>
                                                <TableCell onClick={() => onSelectedItem(item, 'edit')} className={classes.cell}><DisplayText variant='body2'>{color ? color : nodata}</DisplayText></TableCell>
                                                <TableCell  className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} padding='checkbox'>
                                                    <IconButton onClick={() => onSelectedItem(item, 'delete')} size="large"><Icon>delete</Icon></IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}       
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div >
                    <Grid container justifyContent='center' alignItems='center' direction='column' className={classes.nodata}>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>           
            ) : null}       
        </div>
    );
}

export default SimpleTable