import { useState } from 'react'
import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import {makeStyles} from '@material-ui/styles'
import { Tab, Tabs } from '@mui/material'
import InventorSection from './sections/InventorySection/InventorSection'
import BatchSection from './sections/BatchSection/BatchSection'


const InventoriesVIew = props => {

    const classes = useStyles()

    const [menu, setMenu] = useState('inventories')

    const onChange = (e, value) => {
        //console.log(e, value)
        if(value !== menu) setMenu(value)
    }

    let contentView = menu === 'inventories' 
       ? <InventorSection {...props} />
       : <BatchSection {...props} />
    


    return(
        <Page title='Inventarios' >
            <Tabs value={menu} onChange={onChange} >
                <Tab value='inventories' label='Inventarios'/>
                <Tab value='lotes' label='Lotes'/>
            </Tabs>
            <div className={classes.maincontent}>
                {contentView}
            </div>
            
        </Page>
    )
}

const useStyles = makeStyles(theme => ({
    maincontent:{
        padding:'32px 0px',
        background:'transparent'
    }
}))

const mapStateToProps = state => {
    return{
        language: state.language,
        user: state.user
    }
}

export default connect(mapStateToProps)(InventoriesVIew)