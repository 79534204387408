import { CircularProgress, Grid } from "@mui/material"
import Page from "../../../../../components/Structure/Page/Page"
import AdditionalInfo from "./components/AdditionalInfo"
import ContactInfo from "./components/ContactInfo"
import GeneralInfo from "./components/GeneralInfo"
import useGeneral from "./useGeneral"


const General = props => {

    const { content, onUpdateSending, onUpdateSuccess, onUpdateError, match, catalogs, origin, onUpdateCompleted} = props

    //console.log(catalogs)
    
    const {system, actions, view_data} = useGeneral({content, match, onUpdateError, onUpdateSending, onUpdateSuccess, catalogs, origin, onUpdateCompleted})
    const {sending, sending2, sending3} = system
    const {form, indigenous_check} = view_data
    
    return(
        <div >
            {system.loading ? <div style={{textAlign:'center'}}><CircularProgress /></div> :
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <GeneralInfo content={content.cards} form={form} onChange={actions.onChangeForm} sending={sending} onSubmit={actions.onSubmitGeneral}/>
                </Grid>
                <Grid item xs={12}>
                    <ContactInfo content={content.cards} form={form} onChange={actions.onChangeForm} sending={sending2} onSubmit={actions.onSubmitContact}/>
                </Grid>
                <Grid item xs={12}>
                    <AdditionalInfo content={content.cards} form={form} onChange={actions.onChangeForm} sending={sending3} onSubmit={actions.onSubmitAdditional}
                    check={indigenous_check} onChangeCheck={()=>actions.onSetIndigenousCheck(!indigenous_check)}/>
                </Grid>
            </Grid>}
        </div>
    )
} 

export default General