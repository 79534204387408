import { useEffect, useState } from "react"
import { isFormValid, onCleanData, onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"
import { catalogs } from "../../../../texts/esp/catalogs"



const useUserView = ({match, content, onUpdateSending, onUpdateSuccess, onUpdateError}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sending2, setSending2] = useState(false)
    const [sending3, setSending3] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState(null)


    //form
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //checks


    const [modals, setModals] = useState({
        change_image:false,
        change_password:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const req = await private_server.get(`/users/${id}`)
                setData(req.data.data.user)                           
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm:(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        
     
        onClearError:() => setError(null)        
    }

    const system = {loading, sending, sending2, sending3, error}
    const view_data = {data,form}

    return {system, modals, actions, view_data } 
}

export default useUserView

const formData = {

    password:{
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'password',
          type:'password',
          fullWidth: true,
          label:'Contraseña',
          helperText:'Debe contener 6 a 20 caracteres, incluyendo al menos 1 dígito, 1 mayúscula y 1 minúscula'
        },
        rules:{
          type:'none',
        }
    },
}