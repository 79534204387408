import { useEffect, useState } from "react"
import { onGetErrorMessage, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"
import queryString from 'query-string'



const useLocal = ({history, warehouses_local_search, warehouses_local_table}) => {

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
   
    const [data, setData] = useState([])
    const [selected, setSelected] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:100, offset:0, order:0, order_by:'zip_code_id'})
    const [search, setSearch] = useState('')

    const [modals, setModals] = useState({
        add:false,
        edit:false,
        delete:false
    })

    useEffect(() => {
        actions.onInitModule()
    }, [warehouses_local_table])

    const onGetParams = () => {
    
        const {limit, offset, order_by, order} = warehouses_local_table
        const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(params2send)}`

        let filtersArgs = []
    
        if(warehouses_local_search)filtersArgs.push({"field":"name","operator":"LIKE","value":`%${warehouses_local_search}%`})
            
        if(filtersArgs.length){
            let finalFilter = {"AND":filtersArgs}
            params = `?${queryString.stringify(params2send)}&filter=${encodeURIComponent(JSON.stringify(finalFilter))}`
        }
    
        return params 
    }

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                updateAuthorizationHeader(private_server)
                const params = onGetParams()
                let req = await private_server.get(`warehouse/list${params}`)   
                console.log(req)
                setData(req.data.data.warehouses_data)
                setTotal(req.data.data.count)
                setLoading(false)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeSearch: (value)=>{
            /* let newTableFilter = {...tableFilter}
            newTableFilter.offset = 0
            setTableFilter(newTableFilter) */
            setSearch(value)
        },
        onChangeTableFilter: (newTableFilter) => setTableFilter(newTableFilter),
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onDeleteItem: async(item) =>{
            const {appointmentdiscountcode} = item
            setSelected(appointmentdiscountcode)
            actions.onChangeModalStatus('delete', true)
        },
        onSelectItem: async(item) =>{
            //setSelected(item)
            //actions.onChangeModalStatus('edit', true)
            const {id_warehouse} = item
            history.push(`/warehouses/${id_warehouse}`)
        }
    }

    const system = {loading, sending, error}
    const view_data = {data, total, tableFilter, search, selected}

    return {system, modals, actions, view_data } 
}

export default useLocal