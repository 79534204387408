import { change_image_error_msg } from "./general-translations";
import banner from '../../assets/icons/banner.svg'

export const translations = {
    title:'Banners',
    table: {
        header:[
            {id:1, label:'Web'},
            {id:1, label:'Mobile'},
            {id:3, label:'ID'},
            {id:4, label:'Nombre'},
            {id:5, label:'Link'},
            {id:6, label:'Orden'},
            {id:7, label:'Estatus'},
            {id:8, label:''},
        ],
        nodata:'No existen banners registrados',
        empty:banner
    },
    modal: {
        menu:[{id:1, label:'WEB'}, {id:2, label:'MOBILE'}],
        add_title:'Agregar',
        edit_title:'Editar',
        subtitle:'Por favor ingresa los datos solicitados',
        button:'Agregar',
        edit_button:'Guardar',
        form:{
            name:{
                label:'Nombre',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            link:{
                label:'Link',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            description:{
                label:'Descripcion',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            category_id:{
                label:'Categoría asociada',
                helperText:'Seleccione al menos una opción'
            },
            order:{
                label:'Orden',
                helperText:'Seleccione al menos una opción'
            },
            enabled:{
                label:'Estatus',
                helperText:'Seleccione al menos una opción'
            },
            text_1:{
                label:'Texto 1',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            text_2:{
                label:'Texto 2',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            text_3:{
                label:'Texto 3',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
            text_4:{
                label:'Texto 4',
                helperText:'Este campo debe tener al menos 1 caracter y máximo 255'
            },
        },
    },
   
    
}
