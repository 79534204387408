import React, { useState, useEffect } from 'react';
import { Card, Grid, Icon, Popover, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {DASHBOARD_SETTINGS} from '../../../../variables/config'
import cx from 'classnames'
import { grey } from '@mui/material/colors';

const WarehouseMenu = (props) => {

    const classes = useStyles()
    const {options, selected, onChange} = props

    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerChange = (data) =>{
        setAnchorEl(null)
        onChange(data)
    }

    return ( 
        <div>
            
            <Typography variant='caption' color='textSecondary'>Sucursal</Typography>
            <Card className={classes.card} onClick={(e) => setAnchorEl(e.currentTarget)}>
                <div>   
                    
                    <Grid container alignItems='center' wrap='nowrap' spacing={1}>
                        <Grid item>
                            <Typography className={classes.name}>{selected?.label??'Seleccione una opción'}</Typography>
                        </Grid>
                        <Grid item>
                            <Icon className={classes.arrow_icon}>expand_more</Icon>
                        </Grid>
                    </Grid>
                    
                </div>
            </Card>
            <Popover 
                classes={{paper:classes.popper}} 
                open={Boolean(anchorEl)} 
                anchorEl={anchorEl} 
                onClose={(e) => setAnchorEl(null)} 
                anchorOrigin={{vertical: 'bottom',horizontal: 'left'}} 
                transformOrigin={{vertical: 'top',horizontal: 'left'}} 
            >
                <div className={classes.container} >
                    {options?.map((item,key)=>{
                        return(
                            <div key={key.toString()} className={cx({
                                [classes.item]:true,
                                [classes.selected]: selected?.value === item.value
                            })} onClick={() => onInnerChange(item)}>
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            </Popover>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    card:{
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        padding:'4px 16px',
        paddingRight:8,
        borderRadius:10,
        cursor:'pointer'
    },
    name:{
        
    },
    arrow_icon:{
        marginTop:8
    },
    popper:{
        boxShadow: DASHBOARD_SETTINGS.BOXSHADOW,
        marginTop:8,
        padding:'0px 0px',
        paddingLeft:0,
        borderRadius:12,
        //width:200,
        minWidth:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        fontSize:'1.125rem',
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    },
}))

export default WarehouseMenu;