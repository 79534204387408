import { Grid, Icon, IconButton, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DisplayText from "../../../../../../components/Texts/DisplayText"


const TaxCard = ({item, onEditTax, onDeleteTax}) => {

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container wrap='nowrap'>
                <Grid item xs={10}>
                    <Grid container>
                        <Grid xs={12} className={classes.header} >
                            <Grid container>
                                <Grid xs={6}><Typography variant='subtitle1'>Tipo de impuesto</Typography></Grid>
                                <Grid xs={6}><Typography variant='subtitle1'>Tasa (%)</Typography></Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12}>
                            <Grid container>
                                <Grid xs={6}><Typography>{item?.tax_type}</Typography></Grid>
                                <Grid xs={6}><Typography>{item?.rate}</Typography></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2} className={classes.buttons} >
                    <Grid container spacing={2} direction='column' justifyContent="center" alignItems="center" >
                        <Grid item>
                            <IconButton
                                className={classes.edit_button}
                                onClick={()=>onEditTax(item)}
                                size="large"><Icon>edit</Icon></IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                className={classes.delete_button}
                                onClick={()=>onDeleteTax(item)}
                                size="large"><Icon>delete</Icon></IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export default TaxCard

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0px 6px 25px rgba(118, 118, 118, 0.05)',
        borderRadius:20,
        padding:32
    },
    header:{
        borderBottom:'0.5px solid #B1B1B1',
        paddingBottom:16,
        marginBottom:16
    },
    delete_button:{
        boxShadow:'0px 0px 1.80742px rgba(120, 120, 120, 0.43)',
        color:'#D95D71'
    },
    edit_button:{
        boxShadow:'0px 0px 1.80742px rgba(120, 120, 120, 0.43)',
        color:'#1B0934'
    },
    buttons:{
        marginLeft:8,
        marginTop:16
    }
    
}))