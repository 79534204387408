import queryString from 'query-string'

export const onGetParamsFromState = (tableFilter, search, id) => {
    
    const {limit, offset, order_by, order} = tableFilter
    const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
    let params = `?${queryString.stringify(params2send)}`

    let filterArgs = {"AND":[
        {"field":"category_id","operator":"=","value":`${id}`}
    ]}
    filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
    params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`

    if(search){
        filterArgs = {"AND":[
                            {"field":"category_id","operator":"=","value":`${id}`},
                            {"field":"name","operator":"LIKE","value":`%${search}%`}
                        ]}
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
    }

    return params
}

export const onGetSubcategoriesParams = (tableFilter, search) => {
    
    const {limit, offset, order_by, order} = tableFilter
    const params2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
    let params = `?${queryString.stringify(params2send)}`

    if(search){
        /* let filterArgs = [
            {"field":"name","operator":"LIKE","value":`%${search}%`},
        ] */
        let filterArgs = {"AND":[{"field":"name","operator":"LIKE","value":`%${search}%`}]}
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        params = `?${queryString.stringify(params2send)}&filter=${filterArgs}`
    }

    return params
}
