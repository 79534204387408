import { useEffect, useState } from "react"
import { isFormValid, onGetErrorMessage, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm, updateAuthorizationHeader } from "../../../../../shared/utility"
import { private_server } from "../../../../../variables/config"



const useGeneral = ({match, content, onUpdateError, onUpdateCompleted, origin, catalogs, onUpdateSending}) => {

    const id = match.params.id

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [sending2, setSending2] = useState(false)
    const [sending3, setSending3] = useState(false)
  
    //form
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    //checks
    const [indigenous_check, setIndigenousCheck] = useState(false)

    const [modals, setModals] = useState({
        change_image:false,
        change_password:false,
    })

    useEffect(() => {
        actions.onInitModule()
    }, [])

    const actions = {
        onInitModule: async() => {
            try {
                setLoading(true)
                //updateAuthorizationHeader(private_server)
                //const req = await private_server.get(`/users/${id}`)
                //setData(req.data.data.user)
                console.log(origin)
                let _form = onInitEmptyForm(form, content.form)      
                _form = onInitForm(form, origin)
                _form.user_gender_id.options = catalogs.genders
                _form.scolarity_id.options =  catalogs.scolarities
                _form.fixed_phone_country_id.options = catalogs.countries
                _form.blood_type_id.options = catalogs.blood_types
                _form.currency_id.options = catalogs.currency
                _form.civil_status_id.options = catalogs.civil_statuses
                _form.mobile_country_id.options = catalogs.countries
                setForm(_form)
                if(origin) setIndigenousCheck(Boolean(origin.indigenous))
                setLoading(false)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
                setLoading(false)
            }
        },
        onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
        onChangeForm:(data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
        },
        onSubmitGeneral: async () => {

            form.city.isRequired = false
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                console.log(errors)
                return
            }

            
      
            let data2send = onGetSelectedFormData(form, origin)
            data2send.indigenous = indigenous_check ? 1 : 0
            if(data2send.indigenous === 0){
                delete data2send.ethnic_group
                delete data2send.ethnic_language
            }
            console.log(data2send)
            setSending(true)
            onUpdateSending(true)
            try {
                //await request_update_user(userID, data2send);
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/users/${id}`, data2send)
                //const req = await private_server.get(`/users/${id}`)
                //setData(req.data.data.user)
                onUpdateCompleted()
                setSending(false)
                onUpdateSending(false)
                //onUpdateSuccess(content.success_msg)
            } catch (error) {
                console.log(error)
                onUpdateError(onGetErrorMessage(error))
                onUpdateSending(false)
                setSending(false)
            }
        },
        onSubmitContact: async () => {
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetSelectedFormData(form, origin)
            data2send.indigenous = indigenous_check ? 1 : 0
            if(data2send.indigenous === 0){
                delete data2send.ethnic_group
                delete data2send.ethnic_language
            }
            if(!data2send.mobile) delete data2send.mobile_country_id
            if(!data2send.fixed_phone) delete data2send.fixed_phone_country_id
            console.log(data2send)
            setSending2(true)
            onUpdateSending(true)
            try {
                //await request_update_user(userID, data2send);
                //const _user = await request_user(userID)
                //setUser(_user.user)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/users/${id}`, data2send)
                //const req = await private_server.get(`/users/${id}`)
                //setData(req.data.data.user)
                onUpdateCompleted()
                setSending2(false)
                onUpdateSending(false)
                //onUpdateSuccess(content.success_msg)
                //setSucess(content.success_msg)
            } catch (error) {
                console.log(error)
                //setError(onGetErrorMessage(error))
                onUpdateError(onGetErrorMessage(error))
                setSending2(false)
                onUpdateSending(false)
            }
        },
        onSubmitAdditional: async () => {
            form.city.isRequired = false
            const errors = isFormValid(form)
            /// Check if the form is valid
            if(errors && errors.length){
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }
    
            //Extract data
            let data2send = onGetSelectedFormData(form, origin)
            data2send.indigenous = indigenous_check ? 1 : 0
            if(data2send.indigenous === 0){
                delete data2send.ethnic_group
                delete data2send.ethnic_language
            }
            console.log(data2send)
            setSending3(true)
            onUpdateSending(true)
            try {
                //await request_update_user(userID, data2send);
                //const _user = await request_user(userID)
                //setUser(_user.user)
                updateAuthorizationHeader(private_server)
                await private_server.patch(`/users/${id}`, data2send)
                onUpdateCompleted()
                setSending3(false)
                onUpdateSending(false)
                //setSucess(content.success_msg)
                //onUpdateSuccess(content.success_msg)
            } catch (error) {
                console.log(error)
                //setError(onGetErrorMessage(error))
                onUpdateError(onGetErrorMessage(error))
                setSending3(false)
                onUpdateSending(false)
            }
        },
        onSetIndigenousCheck: (temp) => setIndigenousCheck(temp)
          
    }

    const system = {loading, sending, sending2, sending3}
    const view_data = { form, indigenous_check }

    return {system, modals, actions, view_data } 
}

export default useGeneral

const formData = {
    /*------ general info ------*/
    first_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre(s)',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    fathers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'fathers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido paterno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    mothers_last_name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'mothers_last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido materno',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    curp: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'curp',
            type: 'text',
            fullWidth: true,
            label: 'CURP',
            helperText: 'CURP no válida'
        },
        rules: {
            type: 'distance',
            min: 18, max: 18
        }
    },
    birth_date: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label:'Fecha de nacimiento',
            helperText: 'Este campo debe contener una fecha válida'
        },
        rules: {
            type: 'date',
        }
    },
    user_gender_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        //options:[...catalogs.genders],
        options:[],
        config: {
            id: 'user_gender_id',
            type: 'select',
            fullWidth: true,
            label: 'Género',
            helperText: 'Debe elegir una opción',
        },
    },
    
    /*------ contact info ------*/
    email: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'email',
            type: 'email',
            fullWidth: true,
            label: 'Dirección de correo',
            helperText: 'Dirección de correo no válido',
            disabled:true,
        },
        rules: {
            type: 'email',
        }
    },
    mobile_country_id: {
        value:1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        options:[],
        //options:[...catalogs.countries],
        config: {
            id: 'mobile_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    mobile: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'mobile',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono celular',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    fixed_phone_country_id: {
        value:1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: true,
        //options:[...catalogs.countries],
        options:[],
        config: {
            id: 'fixed_phone_country_id',
            type: 'select',
            fullWidth: true,
            label: 'Código',
            helperText: 'Debe elegir una opción',
        },

    },
    fixed_phone: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'fixed_phone',
            type: 'number',
            fullWidth: true,
            label: 'Teléfono fijo',
            helperText: 'Número de teléfono no válido',
        },
        rules: {
            type: 'phone',
        }
    }, 
    zip: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'zip',
            type: 'text',
            fullWidth: true,
            label: 'Código postal',
            helperText: 'No es un código postal válido'
        },
        rules: {
            type: 'zip',
            //min: 1, max: 256
        }
    },
    city: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'city',
            type: 'text',
            fullWidth: true,
            label: 'Ciudad',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },

    /*------ additional info ------*/
    blood_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.blood_types],
        options:[],
        config: {
            id: 'blood_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de sangre',
            helperText: 'Debe elegir una opción',
        },
    },
    civil_status_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.civil_statuses],
        options:[],
        config: {
            id: 'civil_status_id',
            type: 'select',
            fullWidth: true,
            label: 'Estado civil',
            helperText: 'Debe elegir una opción',
        },
    },
    occupation: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'occupation',
            type: 'text',
            fullWidth: true,
            label: 'Ocupación',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    scolarity_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        //options:[...catalogs.scolarities],
        options:[],
        config: {
            id: 'scolarity_id',
            type: 'select',
            fullWidth: true,
            label: 'Escolaridad',
            helperText: 'Debe elegir una opción',
        },
    },
    religion: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'religion',
            type: 'text',
            fullWidth: true,
            label: 'Religión',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_group: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_group',
            type: 'text',
            fullWidth: true,
            label: 'Grupo étnico',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    ethnic_language: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'ethnic_language',
            type: 'text',
            fullWidth: true,
            label: 'Dialecto',
            helperText: 'Este campo debe de contener al menos un caracter'
        },
        rules: {
            type: 'distance',
            min: 1, max: 256
        }
    },
    currency_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        //options:[...catalogs.currency],
        options:[],
        config: {
            id: 'currency_id',
            type: 'select',
            fullWidth: true,
            label: 'Moneda',
            helperText: 'Debe elegir una opción',
        },

    },
}