import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Card,
    CircularProgress,
    Fade,
    Grid,
    Icon,
    IconButton,
    Paper,
    Popover,
    Popper,
    Switch,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleMap, Marker, useJsApiLoader, InfoBox } from '@react-google-maps/api'
import prueba from '../../../../../../assets/markers/branch_1.png'
import marker from '../../../../../../assets/markers/location_red.svg'
import marker_gray from '../../../../../../assets/markers/location_gray.svg'
import { PAYMENT_METHODS, PAYMENT_STATUSES, ROUTE_STATUSES } from '../../../../../../variables/config';
import { onGetJSONObj } from '../../../../../../shared/utility';

const containerStyle = {
    width: '100%',
    height: '350px'
};


const GOOGLE_API_KEY = 'AIzaSyCszA9IN38jH0_hWA5LR56dZDngbd90x1o'


const GoogleMapSection = ({routeorders, orders,  address, onUpdateMapPoint, onAddRouteorder, onDeleteRouteorder }) => {


    const classes = useStyles()

     const [center, setCenter] = useState({lat:19.4326, lng:-99.1332})
     const [position, setPosition] = useState({lat:19.4326, lng:-99.1332})
     const [zoom, setZoom] = useState(12)
     const googleMapRef = useRef()

     const [check, setCheck] = useState(false)

     const [open, setOpen] = useState(-1)
     const [open_route, setOpenRoute] = useState(-1)

     const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: GOOGLE_API_KEY // ,
        // ...otherOptions
    })

    useEffect(() => {
        window.scrollTo(0,0)
        console.log(googleMapRef.current)
    }, [])

    useEffect(() => {
        const onCalculatePoint = async() => {
            if(address){
                let _center = {
                    lat:parseFloat(address.latitude ? address.latitude : 19.4326),
                    lng:parseFloat(address.longitude ? address.longitude : -99.1332)
                }
                let _position = {
                    lat:parseFloat(address.latitude ? address.latitude : 19.4326),
                    lng:parseFloat(address.longitude ? address.longitude : -99.1332)
                }

                setCenter(_center)
                setPosition(_position)
            }
        }
        onCalculatePoint()
    }, [address])

   

    const options = { closeBoxURL: '', enableEventPropagation: true };

    const onLoad = infoBox => {
        //console.log('infoBox: ', infoBox)
    };

    let dir_orders = []


    let pointers = []
    let pending_pointers = []

    dir_orders = orders.map(el => {
        const _address = onGetJSONObj(el?.address_data)
        //console.log(_address)
        return {...el, ..._address}
    })

    //console.log(orders)
    //console.log(dir_orders)

    if(routeorders && routeorders.length){
        pointers = routeorders.map((item, key) => {
            const {latitude, longitude, id_route_order, order_id} = item

            let repetido = false
            let repetidos = []

            for (var j = 0; j < routeorders.length; j++) {
                if (routeorders[key].latitude === routeorders[j].latitude && routeorders[key].longitude === routeorders[j].longitude && key !== j) { //revisamos que i sea diferente de j, para que no compare el mismo elemento exacto.
                    repetido= true;
                    repetidos.push(routeorders[j])
                }
            }
                
            
            


            //console.log(repetidos)

            if(!latitude || !longitude) return null
            if(repetido){
                return (
                    <div>
                        {open_route === key ? 
                        <InfoBox
                            onLoad={onLoad}
                            options={options}
                            position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                            >
                            <div className={classes.info_box}>
                                <div style={{position:'absolute', top:8, right:8, cursor:'pointer'}} onClick={()=>setOpenRoute(-1)} >
                                    <Icon style={{fontSize:10}} >clear</Icon>
                                </div>
                                <Typography variant='caption' color='primary' style={{marginBottom:8}} >Órdenes</Typography>
                                <Grid container spacing={2}>
                                    {repetidos.map((el)=> 
                                    <Grid item xs={12}>
                                        <div className={classes.order_container}  >
                                            <Typography variant='caption'># {el.order_id}</Typography>
                                            <IconButton
                                                className={classes.delete_button}
                                                onClick={() => onDeleteRouteorder(el.id_route_order)}
                                                size="large">
                                                <Icon style={{fontSize:10}}>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </Grid>)}
                                    <Grid item xs={12}>
                                        <div className={classes.order_container}  >
                                            <Typography variant='caption'># {order_id}</Typography>
                                            <IconButton
                                                className={classes.delete_button}
                                                onClick={() => onDeleteRouteorder(id_route_order)}
                                                size="large">
                                                <Icon style={{fontSize:10}}>delete</Icon>
                                            </IconButton>
                                        </div>
                                    </Grid>                             
                                </Grid>
                            </div>
                        </InfoBox> : null}
                        <Marker 
                            icon={{
                                url:marker,
                                scaledSize: new window.google.maps.Size(32, 37)
                            }}
                            //label={order_id.toString()}
                            label={(repetidos.length + 1).toString()}
                            position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                            onClick={() => setOpenRoute(key)}
                            //onClick={() => onPointSelected(id_route_order)}
                            //onClick={onOpenOptions}
                        />   
                    </div>
                );
            }else{
                return (
                    <div>
                        <Marker 
                            icon={{
                                url:marker,
                                scaledSize: new window.google.maps.Size(32, 37)
                            }}
                            label={order_id.toString()}
                            position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                            //onClick={() => onPointSelected(id_route_order)}
                            onClick={() => onDeleteRouteorder(id_route_order)}
                        />
                    </div>
                )
            }
        })
    }

    const onClickPrueba = () => {
        console.log('add order')
    }

    if(dir_orders && dir_orders.length){
        pending_pointers = dir_orders.map((item, key) => {
            const { latitude, longitude,  store_payment_type_id, payment_status_id, route_order_id, id_order, route_order_status_id} = item

        
            let disabled = false
            /* if(store_payment_type_id === PAYMENT_METHODS.CASH || store_payment_type_id === PAYMENT_METHODS.TPV ){
                disabled = false
            }else{
                if(payment_status_id !== PAYMENT_STATUSES.PAID ) disabled = true
            }

            if(route_order_id !== null){
                disabled = true
                if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled = false
            }else{
                disabled = false
            } */

            /* if(store_payment_type_id === PAYMENT_METHODS.MERCADO_PAGO || store_payment_type_id === PAYMENT_METHODS.CARD || store_payment_type_id === PAYMENT_METHODS.PAYPAL){
                disabled = true
            }else{
                disabled = false
                if(route_order_id !== null){
                    disabled=true
                    if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled = false
                }
            } */

            if(route_order_id !== null){
                disabled=true
                if(route_order_status_id === ROUTE_STATUSES.CANCELLED ) disabled = false
            }

            let repetido = false
            let repetidos = []

            for (var j = 0; j < dir_orders.length; j++) {
                if (dir_orders[key].latitude === dir_orders[j].latitude && dir_orders[key].longitude === dir_orders[j].longitude && key !== j) { //revisamos que i sea diferente de j, para que no compare el mismo elemento exacto.
                    repetido= true;
                    repetidos.push(dir_orders[j])
                }
            }
            

            if(!latitude || !longitude) return null
            if(repetido){
                return (
                    <div>
                        {open === key ? <InfoBox
                        onLoad={onLoad}
                        options={options}
                        position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                        >
                        <div className={classes.info_box}>
                            <div style={{position:'absolute', top:8, right:8, cursor:'pointer'}} onClick={()=>setOpen(-1)} >
                                <Icon style={{fontSize:10}} >clear</Icon>
                            </div>
                            <Typography variant='caption' color='primary' style={{marginBottom:8}} >Órdenes</Typography>
                            <Grid container >
                                {repetidos.map((el)=> 
                                    <Grid item xs={12}>
                                        <div className={classes.order_container}  >
                                            <Typography variant='caption' ># {el.id_order}</Typography>
                                            <IconButton
                                                className={classes.add_button}
                                                disabled={disabled}
                                                //onClick={!disabled ? onAddRouteorder(id_order) : null}
                                                onClick={() => onAddRouteorder(el.id_order)}
                                                size="large">
                                                <Icon style={{fontSize:10}}>add</Icon>
                                            </IconButton>
                                        </div>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <div className={classes.order_container}>
                                        <Typography variant='caption' ># {id_order}</Typography>
                                        <IconButton
                                            className={classes.add_button}
                                            disabled={disabled}
                                            //onClick={!disabled ? onAddRouteorder(id_order) : null}
                                            onClick={() => onAddRouteorder(id_order)}
                                            size="large">
                                            <Icon style={{fontSize:10}}>add</Icon>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        </InfoBox> : null}
                        <Marker 
                            icon={{
                                url:marker_gray,
                                scaledSize: new window.google.maps.Size(32, 37)
                            }}
                            label={(repetidos.length + 1).toString()}
                            position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                            onClick={() => setOpen(key)}
                            //onClick={onOpenOptions}
                        />   
                    </div>
                );
            }else{
                return (
                    <Marker 
                        icon={{
                            url:marker_gray,
                            scaledSize: new window.google.maps.Size(32, 37)
                        }}
                        label={id_order.toString()}
                        position={{lat:parseFloat(latitude), lng:parseFloat(longitude)}}
                        onClick={!disabled ? () => onAddRouteorder(id_order) : null}
                        //onClick={!disabled ? () => onPointSelected(id_order) : null}
                    />
                )
            }
        })
    }


    const google_map_content = 
        <GoogleMap 
            ref={googleMapRef}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            > 
            <>
                {check ? pointers : pending_pointers }
            </>
        </GoogleMap>

    const handleChange = (event) => {
        setCheck(event.target.checked);
    };

    console.log(isLoaded)

    return ( 
        <div>
            <Grid container justifyContent='center' alignItems='center' style={{marginBottom:4}} spacing={3} >
                <Grid item><Typography style={{color:'#6a6a6a'}} variant='body2' >Pedidos</Typography></Grid>
                <Grid item>
                    <Switch
                        color='primary'
                        checked={check}
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                </Grid>
                <Grid item><Typography variant='body2' color='primary'>Pedidos a entregar</Typography></Grid>
            </Grid>
            <Card style={{borderRadius:20}}>
                <div >
                    {isLoaded ? google_map_content : <CircularProgress size={54}/> }
                </div>
                
            </Card>
            
        </div>
     );
}

export default GoogleMapSection;


const useStyles = makeStyles(theme => ({
    info_box:{
        backgroundColor: 'white', 
        padding:'14px 10px 10px', 
        borderRadius:10, 
        position:'relative'
    },
    order_container:{
        display:'flex', 
        alignItems:'center',
        padding:'4px 0px',
        borderBottom:'1px solid #EAEAEA',
        justifyContent:'space-between'
    },
    add_button:{
        width:10, 
        height:10, 
        padding:6,
        marginLeft:8, 
        border:`1px solid ${theme.palette.primary.main}`, 
        color:theme.palette.primary.main,
        '&:disabled':{
            border:'1px solid #EAEAEA', 
            color:'#EAEAEA'
        }
        
    },
    delete_button:{
        //width:10, 
        //height:10,
        padding:6, 
        marginLeft:8, 
        border:`1px solid #D95D71`, 
        color:'#D95D71',
    }
}))