import { amber, blue, cyan, green, indigo, orange, purple, red, teal, pink, lime } from "@mui/material/colors";
import pending_icon from '../../assets/icons/statuses/pending.svg'
import assigned_icon from '../../assets/icons/statuses/assigned.svg'
import preparing_icon from '../../assets/icons/statuses/pending.svg'
import ready_icon from '../../assets/icons/statuses/ready.svg'
import intransit_icon from '../../assets/icons/statuses/intransit.svg'
import completed_icon from '../../assets/icons/statuses/completed.svg'
import cancel_icon from '../../assets/icons/statuses/cancel.svg'

//icons2
/* import pending_icon from '../../assets/icons/statuses2/pendiente.svg'
import assigned_icon from '../../assets/icons/statuses2/repartidor.svg'
import preparing_icon from '../../assets/icons/statuses2/return.svg'
import ready_icon from '../../assets/icons/statuses2/shopbag.svg'
import intransit_icon from '../../assets/icons/statuses2/carbon_delivery-truck.svg'
import completed_icon from '../../assets/icons/statuses2/ant-design_check-circle-outlined.svg'
import cancel_icon from '../../assets/icons/statuses2/cross.svg' */

//icons2
import preparing_icon2 from '../../assets/icons/statuses3/preparando.svg'
import ready_icon2 from '../../assets/icons/statuses3/bag.svg'
import completed_icon2 from '../../assets/icons/statuses3/check.svg'

import visa from '../../assets/cards/visa.png'
import mastercard from '../../assets/cards/mastercard.png'

//shipping types
import plane from '../../assets/icons/warehouses/plane.svg'
import local from '../../assets/icons/warehouses/local.svg'
import none from '../../assets/icons/warehouses/none.svg'
import truck from '../../assets/icons/warehouses/truck.svg'

//user types
import admin from '../../assets/icons/admin.svg'
import employee from '../../assets/icons/employee.svg'


export const catalogs = {
    genders:[{value:1, label:'Hombre'},{value:2, label:'Mujer'},{value:3, label:'Otro'},{value:4, label:'Desconocido'}],
    user_statuses:[
        {value:1, color:red[700], label:'No registrado'},
        {value:2, color:blue[700], label:'Invitado'},
        {value:3, color:green[700], label:'Registrado'},
    ],
    currency:[
        {value:1, label:'Dolar (USD)', code:'USD', text_id:301},
        {value:2, label:'Peso (MXN)', code:'MXN', text_id:302},
    ],
    countries:[
        {value:1, label:'México (+52)', phone_code:'+52', flag:'mexico_flag'},
    ],
    blood_types:[
        {value:1, label:'O+'}, {value:2, label:'A+'}, {value:3, label:'B+'}, {value:4, label:'AB+'}, 
        {value:5, label:'O-'}, {value:6, label:'A-'}, {value:7, label:'B-'}, {value:8, label:'AB-'}, 
    ],
    civil_statuses:[
        {value:1, label:'Ninguno'}, {value:2, label:'Soltero'}, {value:3, label:'Unión libre'}, {value:4, label:'Casado'}, 
        {value:5, label:'Separado'}, {value:6, label:'Divorciado'}, {value:7, label:'Viudo'} 
    ],
    scolarities:[
        {value:1, label:'Ninguna'}, {value:2, label:'Educación primaria'}, {value:3, label:'Educación secundaria'}, 
        {value:4, label:'Educación media superior'}, {value:5, label:'Educación superior'}, 
        {value:6, label:'Maestría'}, {value:7, label:'Doctorado'} 
    ],
    languages:[
        {value:1, label:'Español'}, {value:2, label:'Inglés'}
    ],
    specialities:[
        {value:1, label:'Dermatología'}, {value:2, label:'Medicina general'}, {value:3, label:'Nutrición'},
        {value:4, label:'Psicología'}
    ],
    shipping_types:[
        //{value:1, label:'Sí'}, {value:2, label:'No'}
        {value:1, label:'Ninguno', cost:0, icon:none}, 
        {value:2, label:'Celuenvío', cost:0, icon:truck}, 
        {value:3, label:'Local', cost:50, icon:local},
        {value:4, label:'Nacional', cost:100, icon:plane},
    ],
    payment_statuses:[
        {value:-1, color:amber[700],label:'Todos'},
        {value:1, color:amber[700],label:'Pendiente'},
        {value:2, color:orange[700],label:'Rechazado'},
        {value:3, color:teal[700],label:'Expirado'},
        {value:4, color:green[700],label:'Pagado'},
        {value:5, color:purple[700],label:'Reembolsado'},
        {value:6, color:purple[300],label:'Reembolso parcial'},
        {value:7, color:pink[700],label:'Contracargado'},
        {value:8, color:blue[700],label:'Pendiente de aprobación'},
        {value:9, color:red[700],label:'Cancelado'}   
    ],
    discount_types:[
        {value:1, label:'Descuento'},
        {value:2, label:'Paquete cxc'},
        {value:3, label:'Precio mayoreo'},
    ],
    order_statuses:[
        {value:-1, color:amber[700],label:'Todos', colorS:'#fff'},
        {value:1, icon:pending_icon, color:amber[700],label:'Pago Pendiente', colorS:'#ffa000'},
        {value:2, icon:preparing_icon, color:indigo[700],label:'En preparación', colorS:'#303f9f'},
        {value:3, icon:intransit_icon, color:cyan[700],label:'Enviado', colorS:'#0097a7'},
        {value:4, icon:completed_icon, color:green[700],label:'Entregado', colorS:'#388e3c'},
        {value:5, icon:cancel_icon, color:red[700],label:'Cancelado', colorS:'#d32f2f'},
        {value:6, icon:cancel_icon, color:orange[700],label:'Rechazado', colorS:'#f57c00'},
        {value:7, icon:pending_icon, color:lime[500],label:'Regresado', colorS:'#cddc39'},
        {value:8, icon:pending_icon, color:purple[700],label:'Reembolsado', colorS:'#7b1fa2'},
        {value:9, icon:pending_icon, color:cyan[900],label:'Error de envio', colorS:'#006064'},
        {value:10, icon:pending_icon, color:amber[900],label:'Error de pago', colorS:'#ff6f00'},
    ],
    route_statuses:[
        {value:1, icon:pending_icon, color:'#CBB792',label:'Pendiente', colorS:'#ffa000'},
        {value:2, icon:assigned_icon, color:teal[700],label:'Asignado', colorS:'#303f9f'},
        {value:3, icon:preparing_icon2, color:blue[700],label:'Preparando', colorS:'#0097a7'},
        {value:4, icon:ready_icon2, color:'#AF78AD',label:'Listo', colorS:'#388e3c'},
        {value:5, icon:intransit_icon, color:'#543081',label:'En transito', colorS:'#d32f2f'},
        {value:6, icon:completed_icon2, color:'#96AE76',label:'Completado', colorS:'#f57c00'},
        {value:7, icon:cancel_icon, color:red[500],label:'Cancelado', colorS:'#cddc39'},
    ],
    order_types:[
        {value:1, label:'Entrega a domicilio'},
        {value:2, label:'Entrega en sucursal'},
    ],
    payment_types:[
        {value:1, label:'Tarjeta de crédito/débito'},
        {value:2, label:'Paypal'},
        {value:3, label:'Mercado Pago'},
    ],
    store_payment_types:[
        {value:1, label:'Tarjeta de crédito/débito'},
        {value:2, label:'Paypal'},
        {value:3, label:'Mercado Pago'},
        {value:4, label:'Efectivo'},
        {value:5, label:'TPV'}
    ],
    order_levels:[
        {value:1, label:'1'}, {value:2, label:'2'}, {value:3, label:'3'}, {value:4, label:'4'}, {value:5, label:'5'},
        {value:6, label:'6'}, {value:7, label:'7'}, {value:8, label:'8'}, {value:9, label:'9'}, {value:10, label:'10'},
        {value:11, label:'11'}, {value:12, label:'12'}, {value:13, label:'13'}, {value:14, label:'14'}, {value:15, label:'15'},
        {value:16, label:'16'}, {value:17, label:'17'}, {value:18, label:'18'}, {value:19, label:'19'}, {value:20, label:'20'},
        {value:21, label:'21'}, {value:22, label:'22'}, {value:23, label:'23'}, {value:24, label:'24'}, {value:25, label:'25'},
        {value:26, label:'26'}, {value:27, label:'27'}, {value:28, label:'28'}, {value:29, label:'29'}, {value:30, label:'30'},
        {value:31, label:'31'}, {value:32, label:'32'}, {value:33, label:'33'}, {value:34, label:'34'}, {value:35, label:'35'},
        {value:36, label:'36'}, {value:37, label:'37'}, {value:38, label:'38'}, {value:39, label:'39'}, {value:40, label:'40'},
        {value:41, label:'41'}, {value:42, label:'42'}, {value:43, label:'43'}, {value:44, label:'44'}, {value:45, label:'45'},
        {value:46, label:'46'}, {value:47, label:'47'}, {value:48, label:'48'}, {value:49, label:'49'}, {value:50, label:'50'},
    ],
    general_statues:[
        {value:0, color:red[700],label:'Inactivo'},
        {value:1, color:green[700],label:'Activo'},
    ],
    route_order_issues:[
        {value:1, label:'Problema con la entrega'},
        {value:2, label:'Problema con el pago'},
    ],
    route_order_statuses:[
        {value:1, icon:pending_icon, color:amber[700],label:'Pendiente', colorS:'#ffa000'},
        {value:2, icon:intransit_icon, color:purple[700],label:'En transito', colorS:'#d32f2f'},
        {value:3, icon:ready_icon, color:indigo[700],label:'Camino a destino', colorS:'#388e3c'},
        {value:4, icon:completed_icon, color:green[700],label:'Entregado', colorS:'#f57c00'},
        {value:5, icon:cancel_icon, color:red[500],label:'Problema', colorS:'#cddc39'},
    ],
    roles:[
        {value:1, label:'Administrador'},
        {value:2, label:'Doctor'},
        {value:3, label:'Usuario'},
        {value:4, label:'Asistente de farmacia'},
    ],
    payment_cards:[
        {brand:'visa', logo:visa},
        {brand:'mastercad', logo:mastercard},
    ],
    code_discount_types:[
        {value:1, label:'Fijo'},
        {value:2, label:'Porcentual'},
    ],
    payment_concepts:[
        {value:1, label:'Cita en Celudoc - Especialidad Dermatología'},
        {value:2, label:'Cita en Celudoc - Especialidad Medicina Gener'},
        {value:3, label:'Cita en Celudoc - Especialidad Nutrición'},
        {value:4, label:'Cita en Celudoc - Especialidad Psicología'},
    ],
    warehouses_open:[
        {value:0, label:'Cerrado', color:'#D95D71'},
        {value:1, label:'Abierto', color:'#96AE76'},
    ],
    products_types:[
        {value:1, label:'Medicamento'},
        {value:2, label:'OTC'},
        {value:3, label:'Dispositivo'},
        {value:4, label:'Veterinario'},
        {value:5, label:'Otro'},
    ],
    menu_elements_types:[
        {value:1, label:'Categoría'},
        {value:2, label:'Sub categoría'},
        {value:3, label:'Segmento'},
        {value:4, label:'Sub segmento'},
    ],
   
        
}

export default catalogs