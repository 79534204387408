import React, { useState, useEffect } from 'react';
import { Grid, Icon, LinearProgress, Typography } from '@mui/material';
import useBatchSection from './useBatchSection';
import MonthFilter from '../../../../../components/Actions/MonthFilter';
import FilterButton from '../../../../../components/Actions/Buttons/FilterButton';
import RoundedButton from '../../../../../components/Actions/RoundedButton';
import { makeStyles } from '@mui/styles';
import MasterBatchTable from '../../components/MasterBatchTable';

const BatchSection = (props) => {

    const {actions, data, system} = useBatchSection({...props})
    const classes = useStyles()

    const {start_date, end_date, provider_id} = data.filter
    return ( 
        <div>

            <div className={classes.header}>
                <Grid container spacing={2} justifyContent='flex-end' alignItems='end'>
                    <Grid item>
                        <MonthFilter label='Inicio' value={start_date} onChange={(value)=>actions.onUpdateFilter(value,'start_date')} />
                    </Grid>
                    <Grid item>
                        <MonthFilter label='Fin' value={end_date} onChange={(value)=>actions.onUpdateFilter(value,'end_date')} />
                    </Grid>
                    <Grid item>
                        <FilterButton
                            label='Proveedor' 
                            options={data.providers} 
                            selected={provider_id} 
                            onChange={(val) => actions.onUpdateFilter(val, 'provider_id')}/>
                    </Grid>
                    <Grid item>
                        <RoundedButton size='small'
                            onClick={actions.onDownloadReport}
                        >
                            <Icon style={{marginRight:8}} >cloud_download</Icon>Reporte
                        </RoundedButton>
                    </Grid>
                    {/*<Grid item xs={12}>
                        <Searchbar />
    </Grid>*/}
                </Grid>
            </div>
            <div className={classes.main}>
                {(system.loading && system.ready)  && <LinearProgress />}
                <MasterBatchTable 
                    header={[
                        {id:1, label:'Fecha'},
                        {id:2, label:'No. Factura'},
                        {id:3, label:'SKU'},
                        {id:4, label:'Descripción'},
                        {id:5, label:'Cantidad'},
                        {id:6, label:'Lote'},
                        {id:7, label:'Proveedor'},
                        {id:8, label:'Fecha de vencimiento'},
                        {id:9, label:'Estatus'},
                    ]}
                    data={data.batches ?? []}
                    total={data.total}
                    tableFilter={data.pagination}
                    onUpdateTableFilter={actions.onUpdatePagination}
                />
            </div>

        </div>
     );
}

const useStyles = makeStyles(theme => ({
    header:{
        marginBottom:16,
       
    }
}))
 
export default BatchSection;