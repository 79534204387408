import { connect } from 'react-redux'
import Page from '../../../components/Structure/Page/Page'
import SimpleTable from './components/SimpleTable'
import AddUserModal from './modals/AddUserModal/AddUserModal'
import { translations as translations_esp } from '../../../texts/esp/users-translations'
import useUsers from './useUsers'
import { actionTypes } from '../../../store/actions'

const Users = props => {

    const content = translations_esp
    const { history, users_search, users_table, onUpdateSearch, onUpdateTable } = props
    const {system, modals, actions, view_data} = useUsers({content, history, users_search, users_table})

    /* //general
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])

    //modals
    const [addModal, setAddModal] = useState(false)

    //table
    const [totalUsers, setTotalUsers] = useState(10)
    const [tableFilter, setTableFilter] = useState({limit:10, offset:0, order:1, order_by:'id_user'})
    const [search, setSearch] = useState(null)


    useEffect(() => {
        const initModule = async() => {try {
            setLoading(true)
            const params = onGetParamsFromState(tableFilter, search)
            //console.log(params)
            const _users = await request_users(params)
            setUsers(_users.users)
            //console.log(_users.users)
            setTotalUsers(_users.count)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }}
        initModule()
    }, [search, tableFilter])

  
    const onSelectedItem = (item) => {
        const {id_user} = item
        history.push(`/users/${id_user}`)
    }

    const onChangeSearch = (val) => {
        setSearch(val)
        setTableFilter({...tableFilter, offset:0})
    }
  
    const onUpdateUsers = async() => {
        const params = onGetParamsFromState(tableFilter, search)
        const _users = await request_users(params)
        setUsers(_users.users)
        setTotalUsers(_users.count)
        setAddModal(false)
    } */

    return(
        <Page title={content.titles.users} >
            <AddUserModal content={content.modal} open={modals.add} onClose={() => actions.onChangeModalStatus('add', false)} 
                onRequestUsers={actions.onInitModule} history={history}/>
            <SimpleTable
                data={view_data.data} 
                roles={view_data.roles}
                search={users_search}
                tableFilter={users_table}
                total={view_data.total}
                loading={system.loading}
                onAddRegister={() => actions.onChangeModalStatus('add', true)}
                onChangeSearch={onUpdateSearch}
                onUpdateTableFilter={onUpdateTable}
                onSelectedItem={actions.onSelectItem}
                content={content.tables.users}
                />
        </Page>
    )
}


const mapStateToProps = state => {
    return{
        users_table: state.users_table,
        users_search: state.users_search
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onUpdateTable: (users_table) => dispatch({type: actionTypes.CHANGE_USERS_TABLE, users_table }),
        onUpdateSearch: (users_search) => dispatch({type: actionTypes.CHANGE_USERS_SEARCH, users_search }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)