import React from 'react'
import {
    Button,
    Card,
    CircularProgress,
    Grid,
    Icon,
    IconButton,
    LinearProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import DisplayText from '../../../../../../components/Texts/DisplayText'
import SearchBar from '../../../../../../components/Actions/SearchBar/SearchBar'
import empty from '../../../../../../assets/empty.png'
import { tableStyles } from '../../../../../../styles/tableStyles'
import NewActionBar from '../../../../../../components/Actions/NewActionBar'
import DisplayIconData from '../../../../../../components/Structure/DisplayData/DisplayIconData'
import UserAvatarName from '../../../../../../components/Structure/DisplayData/UserAvatarName'

const useStyles = makeStyles(theme => ({
    quantity_container:{
        background:'#F7F8FC', 
        borderRadius:24, 
        padding:6, 
        display:'flex',
        alignItems:'center',
        justifyContent:'space-between',
        width:200
    },
    quantity_button:{
        color:theme.palette.primary.main,
        border:`1.2px solid ${theme.palette.primary.main}`,
        width:20,
        height:20,
        '&:disabled':{
            border:`1.2px solid rgba(0,0,0, 0.54)`,
        }
    }
    
}))

const SimpleTable = props => {

    const classes = tableStyles()
    const local_classes = useStyles()
    const { content, data, onAddRegister, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, onDeleteItem, loading, onChangeAvailabity } = props

    const header = content.header

    

    
    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container justifyContent='flex-end' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item>
                                <SearchBar value={search} onChange={onChangeSearch} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter} 
                                label={content.search_bar_label}/>
                            </Grid>
                            <Grid item><IconButton className={classes.button} onClick={onAddRegister} size="large"><Icon>add</Icon></IconButton></Grid>
                        </Grid>
                    </Grid>          
                </Grid>
            </div>
            {loading ? (
                <Grid container justifyContent='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Card className={classes.paper}>
                {data && data.length ? (
                    <div >
                        <TableContainer className={classes.table_container}>
                            <Table stickyHeader className={classes.table}>
                                {header ? (
                                    <TableHead>
                                        <TableRow>
                                            {header.map(item=>{
                                                return(
                                                    <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                        <Typography style={{fontWeight:400}} >{item.label}</Typography>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    </TableHead>
                                ):null}
                                <TableBody>
                                    {data.map((item,key) => {
                                        const { id_inventory, product_id, product, available, location, image_web  } = item

                                        return (
                                            <TableRow key={key.toString()} className={classes.no_click_row} >
                                                <TableCell className={classes.cell} style={{borderRadius:'16px 0px 0px 16px'}}><DisplayText variant='body2' >{product_id}</DisplayText></TableCell>
                                                <TableCell className={classes.cell}><UserAvatarName variant='square' name={product} image={image_web} noimage={content.empty} /></TableCell>
                                                <TableCell className={classes.cell}>
                                                    <div >
                                                        <div className={local_classes.quantity_container}>
                                                            <IconButton
                                                                className={local_classes.quantity_button}
                                                                onClick={()=>onChangeAvailabity(item, 'add')}
                                                                size="large"><Icon>add</Icon></IconButton>
                                                            <div><DisplayText variant='body2' >{available}</DisplayText></div>
                                                            <IconButton
                                                                className={local_classes.quantity_button}
                                                                disabled={available <= 0}
                                                                onClick={()=>onChangeAvailabity(item, 'remove')}
                                                                size="large"><Icon>remove</Icon></IconButton>
                                                        </div>
                                                    </div>
                                                </TableCell> 
                                                <TableCell className={classes.cell}><DisplayText variant='body2' >{location}</DisplayText></TableCell>
                                                <TableCell className={classes.cell} style={{borderRadius:'0px 16px 16px 0px'}} >
                                                    <Grid container spacing={2} justifyContent='center' >
                                                        <Grid item>
                                                            <IconButton
                                                                className={classes.edit_button}
                                                                onClick={() => onSelectedItem(item)}
                                                                size="large"><Icon>edit</Icon></IconButton>
                                                        </Grid>
                                                        <Grid item>
                                                            <IconButton
                                                                className={classes.delete_button}
                                                                onClick={() => onDeleteItem(item)}
                                                                size="large"><Icon>delete</Icon></IconButton>
                                                        </Grid>
                                                    </Grid>                                           
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    
                ): null}
            </Card>
            {data && data.length ? <div className={classes.actions}>
                <NewActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading  ? (
                <div className={classes.nodata}>
                    <Grid container justifyContent='center' alignItems='center' direction='column'>
                        <Grid item>
                            <div className={classes.img_container}>
                                <img src={content.empty} alt='' className={classes.empty} />
                            </div>
                        </Grid>
                        <Grid item>
                            <DisplayText>{content.nodata}</DisplayText>
                        </Grid>
                    </Grid>
                </div>               
            ) : null}          
        </div>
    );
}

export default SimpleTable

const EnabledDisplayer = ({enabled}) =>{
    return(
        <div style={{display:'flex', alignItems:'center'}}>
            <div style={{width:8, height:8, marginRight:8, borderRadius:'50%', background:enabled ? '#96AE76' : '#D95D71'}}></div>
            <DisplayText>{enabled ? 'Sí' : 'No'}</DisplayText>
        </div>
    )
}