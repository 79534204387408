import React, {useState, useEffect} from 'react'
import {
    Card,
    ClickAwayListener,
    Divider,
    Grid,
    Grow,
    Icon,
    IconButton,
    Popper,
    Hidden,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DisplayText from '../../../../Texts/DisplayText';
import { blueGrey } from '@mui/material/colors';
import UserAvatar from '../../../Sidebar/components/UserAvatar';
import { onGetFullname } from '../../../../../shared/utility';
import { USER_TYPES } from '../../../../../variables/config'
import { translations as translations_esp } from '../../../../../texts/esp/topbar-translations'
import SVGIconOutlined from '../../../DisplayData/SVGIconOutlined';
import icon_celu from '../../../../../assets/icons/sidebar/celu_logo_white.svg'

const useStyles = makeStyles(theme=>({
    root:{},
    card:{
        borderRadius: 15,
        boxShadow: '0px 7px 15px rgba(184, 184, 184, 0.25)',
        width: 175,
        padding: '10px 0px'
    },
    menu:{
    },
    iconButton:{
        background:'transparent',
        color: blueGrey[700],
        width: 20,
        height: 20,
        '&:hover':{
            color:theme.palette.primary.main,
            background:'transparent',
        },
    },
    buttonRoot:{
        backgroundColor:'transparent',
        position: 'relative'
    },
    text:{
        marginLeft:10,
        marginRight:15,
    },
    userType:{
        fontSize:'0.75rem',
        color: '#7F7F7F'
    },
    adornmentContainer:{
        position: 'absolute',
        left: 32,
        top: 32,
        width: 22,
        height: 22,
        zIndex: 2,
        borderRadius: 25,
        background: theme.palette.primary.main
    },
    adornmentIcon:{
        width: 18,
        height: 18,
        margin: 2
    }

}))

const UserButtonAvatar = props => {

    const classes = useStyles();
    const content = translations_esp.user_button
    const {onItemSelected, isAuth, onLogOut, history, user} = props
    const [anchorEl, setAnchorEl] = useState(null)

    const [name, setName] = useState('')
    const [userType, setUserType] = useState('')

    useEffect(() => {
        const initModule = async() => {try {
            const last_name = onGetFullname(user.fathers_last_name, user.mothers_last_name)
            if(user.first_name !== null && last_name !== null){
                setName(user.first_name + ' ' + last_name)
            }
            if(user.business_name && user.business_name !== null){
                setName(user.business_name)
            }
            if(user.user_type_id === USER_TYPES.ADMIN) setUserType(content.admin)   
            if(user.user_type_id === USER_TYPES.DOCTOR) setUserType(content.doctor)
            if(user.user_type_id === USER_TYPES.PATIENT) setUserType(content.patient)
        } catch (error) {
            console.log(error)
        }}
        if(user) initModule()
    }, [user])


    const onInnerItemSelected = (identifier) => {
        setAnchorEl(null)
        if(identifier === 'logout'){
            onLogOut()
            return
        }
        if(onItemSelected) onItemSelected(identifier)
    }

    const buttonContent = <Card className={classes.card}>
        <div className={classes.menu}>
            <Grid container>
                <Grid item xs={12}>
                    <OptionComponent label={content.my_profile} icon={content.my_profile_icon} id='/profile' onItemSelected={onInnerItemSelected}/>
                </Grid>
                <Grid item xs={12}><Divider /></Grid>
                <Grid item xs={12}>
                    <OptionComponent label={content.log_out} icon={content.log_out_icon} id='logout' onItemSelected={onInnerItemSelected}/>
                </Grid>
            </Grid>
        </div>
    </Card>

    return (
        <div className={classes.root}>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement='bottom-end' disablePortal>
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Grow in={Boolean(anchorEl)} timeout={ {enter: 350, exit: 350} }>
                        {buttonContent}
                    </Grow>
                </ClickAwayListener>
            </Popper>
            <div className={classes.buttonRoot}>
                <div className={classes.adornmentContainer}><SVGIconOutlined className={classes.adornmentIcon} src={icon_celu} /></div>
                <Grid container alignItems='center'>
                    <Grid item>
                        <UserAvatar {...user} topbar={true} />
                    </Grid>
                    <Hidden mdDown>
                        <Grid item>
                            <div className={classes.text}>
                                <Typography variant='body2'>{name}</Typography>
                                <Typography className={classes.userType}>{userType}</Typography>
                            </div>
                        </Grid>
                    </Hidden>
                    <Grid item>
                            <IconButton
                                onClick={(e) => setAnchorEl(e.currentTarget)}
                                className={classes.iconButton}
                                size="large">
                                <Icon>expand_more</Icon>
                            </IconButton>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}

export default UserButtonAvatar

const useStyles4Option = makeStyles(theme=>({
    root:{
        padding: '15px 25px',
        transition:theme.transitions.create(['background'],{
            duration: theme.transitions.duration.standard,
            easing:theme.transitions.easing.easeIn
        }),
        '&:hover':{
            cursor:'pointer',
            background:blueGrey[50],
            transition:theme.transitions.create(['background'],{
                duration: theme.transitions.duration.standard,
                easing:theme.transitions.easing.easeOut
            }),
        }
    },
    icon:{color:theme.palette.primary.main}
}))

const OptionComponent = props => {

    const {id, icon, label, onItemSelected} = props
    const classes = useStyles4Option()

    return(
        <div className={classes.root} onClick={() => onItemSelected(id)}>
            <Grid container spacing={1} wrap='nowrap'>
                <Grid item><SVGIconOutlined size={20} src={icon} className={classes.icon}/></Grid>
                <Grid item><Typography variant='caption'>{label}</Typography></Grid>
            </Grid>
        </div>
    )
}