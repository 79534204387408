import { useState, useEffect } from 'react';
import {
	isFormValid,
	onGetErrorMessage,
	onGetFormData,
	onGetSelectedFormData,
	onInitForm,
    onInitEmptyForm,
	onSetErrorsToForm,
	onSetTokenSession,
	updateAuthorizationHeader,
	onGetImageURL2Blob,
	onGetFileExtension,
} from '../../../../shared/utility';
import { request_create_rolesetting } from '../requests';
import axios from 'axios';
import { private_server } from '../../../../variables/config';

const useAddRole = ({ language, content, onClose, onCloseImg, onUpdateCompleted}) => {
	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(form_data);
	const [img, setImg] = useState(null)

    //Cambiar
	const errors_txt = {INCOMPLETE_FORM: 'Llene los campos faltantes'}

	useEffect(() => {
		actions.onInitModule();
	}, []);

	const actions = {
		onInitModule: async () => {
			let _form = onInitEmptyForm(form, content.form);
			setForm(_form);
		},
		onChangeForm: (data) => {
			let temp = { ...form };
			const id = data.config.id;
			temp[id] = { ...data };
			if (error) setError(null);
			setForm(temp);
		},
		onKeyPress: (event) => {
			if (event.key === 'Enter') {
				actions.onSubmit();
			}
		},
		onSubmit: async () => {
			const errors = isFormValid(form);

			if (errors && errors.length) {
				console.log(errors);
				const _form = onSetErrorsToForm(form, errors);
				setForm(_form);
				setError(errors_txt.INCOMPLETE_FORM);
				return;
			}

			const data2send = onGetFormData(form);

			setSending(true);
            setLoading(true);

			try {
                const res = await request_create_rolesetting(data2send);
				const id_created = res.role_id
				await actions.onSubmitImage(id_created)
                setLoading(false);
                onUpdateCompleted();
                onClose();
            } catch (error) {
                console.log(error);
                setError(onGetErrorMessage(error));
                setLoading(false);
            }

			setSending(false);

		},
		onGetImgFile: async (file) =>{
			setImg(file)
			onCloseImg()
		}, 
		onSubmitImage: async (id) => {
			if (!img) {
				setError(content.error_msg);
				return;
			}
			setError(null);
			try {
				setLoading(true);
				const _file = await onGetImageURL2Blob(img);
				updateAuthorizationHeader(private_server);
				let response = await private_server.post(`/role/${id}/icon`, { type: onGetFileExtension(_file.name) });
				const options = { headers: { 'Content-Type': _file.type } };
				delete axios.defaults.headers.common['Authorization'];
				await axios.put(response.data.data.upload_url, _file, options);
			} catch (error) {
				console.log(error);
				setError(onGetErrorMessage(error));
			}
			setLoading(false);
		},
	};

	const system = { loading, sending, error };

	return { system, form, actions, img };
};

export default useAddRole;

const form_data = {
	name: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: true,
		isValid: false,
		config: {
			id: 'name',
			type: 'text',
			fullWidth: true,
			label: 'Nombre',
			helperText: 'Ingrese un nombre valido',
		},
		rules: {
			type: 'distance',
		},
	},
	description: {
		value: '',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'description',
			type: 'text',
			label: 'Descripción',
			helperText: 'Este campo debe tener al menos 1 caracter y máximo 255',
			fullWidth: true,
			multiline: true,
			rows: 4,
		},
		rules: {
			type: 'distance',
			min: 1,
			max: 255,
		},
	},
	color: {
		value: '#543081',
		error: false,
		isVisited: false,
		isRequired: false,
		isValid: false,
		config: {
			id: 'color',
		},
		rules: {
			type: 'color',
		},
	},
};
