import { Grid } from '@mui/material'
import { ColorPicker } from 'material-ui-color';
import React, {useState, useEffect} from 'react'
import ContainedButton from '../../../../../components/Actions/ContainedButton'
import InputForm from '../../../../../components/Forms/InputForm'
import SimpleModal from '../../../../../components/Overlays/SimpleModal'
import DisplayText from '../../../../../components/Texts/DisplayText'
import { isFormValid, onGetErrorMessage, onGetFormData, onInitEmptyForm, onSetErrorsToForm } from '../../../../../shared/utility'
import { request_create_tag, request_upload_tag_image } from '../requests'
import { translations as translations_esp } from '../../../../../texts/esp/tags-translations'
import ToolbarTable from '../../../../../components/Structure/Navigation/ToolbarTable';
import ImageContainer from '../components/ImageContainer';
import UploadImageModal from './UploadImageModal';

const AddTagModal = ({open, onClose, onUpdateCompleted}) => {

    const content = translations_esp.modal

    const default_color = '#ffffff'

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [color, setColor] = useState(default_color)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')
    const [disabled, setDisabled] = useState(false)

    //images
    const [image_modal, setImageModal] = useState(false)

    const [wdata_image, setwDataImage] = useState(null)
    const [wfile, setwFile] = useState(null)
    const [wcrop_image, setwCropImage] = useState(null)

    const [mdata_image, setmDataImage] = useState(null)
    const [mfile, setmFile] = useState(null)
    const [mcrop_image, setmCropImage] = useState(null)

    useEffect(() => {
        if (!open) {
            let _form = onInitEmptyForm(form, content.form)
            setForm(_form)
            setError(''); setLoading(false);
            setDisabled(false)
        }
    }, [open])


    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
       const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            console.log('hay errores')
            return
        } 

        //Extract data
        let data2send = onGetFormData(form)
        data2send.color = color 
        data2send.url = form.name.value.split(" ").join('-')
        //console.log(data2send)
        
        setLoading(true)
        setDisabled(true)
        try {
            const new_tag = await request_create_tag(data2send);
            console.log(new_tag)
            const id = new_tag.tag_id
            if(wdata_image && wfile){
                request_upload_tag_image(id, wfile, wdata_image)
            }
            if(mdata_image && mfile){
                request_upload_tag_image(id, mfile, mdata_image)
            }      
            setLoading(false)
            onUpdateCompleted(id)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
        setDisabled(false)

    }

    const onChangeColor = (data) =>{
        if(data && data.css){
            setColor(data.css.backgroundColor)
        }else{
            setColor(data)
        }    
        //console.log(data)
    }

    

    //image web - mobile
    const menu = content.menu
    const [image_view, setImageView] = useState(1)
    const [type, setType] = useState('web')

    const onChangeView = (id) => {
        if(id === 1) setType('web')
        if(id === 2) setType('mobile')
        setImageView(id)
    }

    let contentView = null

    switch(image_view){
        case 1:
            contentView = <ImageContainer image_temp={wcrop_image}  onClick={()=>setImageModal(true)}/>
            break
        case 2:
            contentView = <ImageContainer image_temp={mcrop_image}  onClick={()=>setImageModal(true)}/> 
            break
        default:
            break
    }

    const onUpdateDataImage = (data, _file, crop) =>{
        if(type === 'web'){
            setwDataImage(data)
            setwFile(_file)
            setwCropImage(crop)
        }
        if(type === 'mobile'){
            setmDataImage(data)
            setmFile(_file)
            setmCropImage(crop)
        }
        
    }

    return (
        <SimpleModal open={open} onClose={onClose}>
            <UploadImageModal  open={image_modal} onClose={() => setImageModal(false)} type={type} onUpdateDataImage={onUpdateDataImage}/>
            <DisplayText variant='h5' color='primary' style={{ fontWeight: 500 }}>{content.add_title}</DisplayText>
                <div style={{padding:'30px 0px 24px'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            {contentView}                            
                            <div style={{marginTop:12}}>
                                <ToolbarTable selected={image_view} menuOptions={menu} onChange={onChangeView} />
                            </div>
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} >
                            <InputForm data={form.description} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <DisplayText variant='body2' color='textSecondary'>{content.form.color.label}</DisplayText>
                            <ColorPicker value={color} disableAlpha onChange={onChangeColor} 
                                 disablePlainColor/>
                        </Grid>
                        {/* <Grid item xs={12} >
                            <InputForm data={form.url} onChange={onChange} />
                        </Grid> */}
                    </Grid>
                </div>
                <ContainedButton color='primary' fullWidth onClick={onSubmit} loading={loading} disabled={disabled}>
                    {content.add_button}
                </ContainedButton>
                <DisplayText color='error' align='center'>{error}</DisplayText>
        </SimpleModal>
    )

}


export default AddTagModal


const formData = {
    name: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'name',
            type: 'text',
            fullWidth: true,
            label: 'Nombre',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    description: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    },
    /* url: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'url',
            type: 'text',
            fullWidth: true,
            label: 'Url',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 500
        }
    }, */
    /* color: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'color',
            type: 'text',
            fullWidth: true,
            label: 'Color',
            helperText: 'Este campo debe tener al menos 1 caracter y máximo 255'
        },
        rules: {
            type: 'distance',
            min: 1, max: 255
        }
    }, */
}