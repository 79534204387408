import { Button, Grid, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import InputForm from "../../../../../../components/Forms/InputForm"
import { config_data } from "../../../../../../variables/config"


const Details = ({onChange, form, onUpdateLink}) => {

    const classes = useStyles() 

    return(
        <Grid container spacing={4} alignItems='flex-end' >
            <Grid item xs={12} style={{marginBottom:20}}>
                <Grid container alignItems='center'>
                    <Grid item ><Typography variant='subtitle1'>Detalles</Typography></Grid>
                    <Grid item xs><div style={{width:'98%', height:0.5, background:'#DCDCDC', marginLeft:16 }}></div></Grid>
                </Grid>
            </Grid>
              
            <Grid item xs={12} md={6}>
                <InputForm data={form.administration_route} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputForm data={form.weight} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6} wrap='nowrap' >
                <Typography style={{color:'#607d8b', fontSize:'0.75em', fontWeight:400}} >Dimensiones (h x w x l) (cm)</Typography>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item xs={2}><InputForm data={form.height} onChange={onChange} /></Grid>
                    <Grid item><Typography style={{color:'#607d8b', fontWeight:400}}>x</Typography></Grid>
                    <Grid item xs={2}><InputForm data={form.width} onChange={onChange} /></Grid>
                    <Grid item><Typography style={{color:'#607d8b', fontWeight:400}}>x</Typography></Grid>
                    <Grid item xs={2}><InputForm data={form.length} onChange={onChange} /></Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <InputForm data={form.sku} onChange={onChange} />
            </Grid>    
            <Grid item xs={12} >
                <InputForm data={form.cofepris} onChange={onChange} />
            </Grid>
            <Grid item xs={12} >
                <InputForm data={form.search_metadata} onChange={onChange} />
            </Grid>
            <Grid item xs={12} >
                <Grid container spacing={2} alignItems='center' wrap='nowrap' >
                    <Grid item xs><InputForm data={form.url} onChange={onChange} /></Grid>
                    <Grid item ><Button  className={classes.button_secondary} fullWidth onClick={onUpdateLink} >Autogenerar</Button></Grid>
                    <Grid item ><Button href={`${config_data.CELU_WEBSITE_URL}/${form.url.value}`} target='_blank' className={classes.button} fullWidth  >Visitar</Button></Grid>
                </Grid>                      
            </Grid>               
            <Grid item xs={12} >
                <InputForm data={form.search_string} onChange={onChange} />
            </Grid> 
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    button:{
        background:theme.palette.primary.main,
        color:'white',
        textTransform:'none',
        padding:'14px 24px',
        borderRadius:8,
        width:'100%',
        '&:hover':{
            background:theme.palette.primary.main
        }
    },
    button_secondary:{
        background:theme.palette.secondary.main,
        color:'white',
        textTransform:'none',
        padding:'14px 12px',
        borderRadius:8,
        width:'100%',
        '&:hover':{
            background:theme.palette.secondary.main
        }
    }
}))


export default Details